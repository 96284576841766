import React, { Component } from 'react'
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { RECAPTCHA_SITE_KEY } from '../../../config/constants/index';
import { startLoader, getInfoData, clearAdminError, getAllAdminUser, updateAdminUser, createAdminUser, deleteAdminUser, clearAdminUserErr } from '../../../redux/actions/adminAction';
import { ButtonElement, Spinner, Modal } from '../../UI//index';
import ErrorPopup from '../Popup/ErrorPopup';
import './adminUser.scss'

export class adminUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            updateObj: {
                password: Math.random(),
                userId: ""
            },
            saveUser: {
                userId: "",
                password: "",

            },
            allUsers: [],
            rePassword: "",
            success: false,
            errdata: [],
            message: "",
            captchaToken: "",
            confirmPwd: "",
            deleteConfirm: false,
            data: {},
            disabled: false,
            clickflag:false

        }
    }
    componentDidMount = async () => {
        await this.props.startLoader();
        await this.props.getAllAdminUser();
        if (this.props?.allAdminUsers) {
            this.setState({
                allUsers: this.props?.allAdminUsers
            })
        }
    }
    setFormValue = (value, field, number = false) => {
        const { updateObj } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            updateObj[field] = regex.test(value) || value === "" ? value : updateObj[field];
        } else {
            updateObj[field] = value;
        }
        this.setState({
            updateObj,
        });
    }

    setFormValue1 = (value, field, number = false) => {
        const regex = /[0-9\b]+$/;
        if (number) {
            field = regex.test(value) || value === "" ? value : field;
        } else {
            field = value;
        }
        this.setState({
            rePassword: field,
        });
    }
    setFormValue2 = (value, field, number = false) => {
        const regex = /[0-9\b]+$/;
        if (number) {
            field = regex.test(value) || value === "" ? value : field;
        } else {
            field = value;
        }

        this.setState({
            confirmPwd: field,
        });
    }
    setSaveFormValue = (value, field, number = false) => {
        const { saveUser } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            saveUser[field] = regex.test(value) || value === "" ? value : saveUser[field];
        } else {
            saveUser[field] = value;
        }
        this.setState({
            saveUser,
        });
    }
    updateUser = async (e, data,index) => {
        e.preventDefault();
        if (!this.state.clickflag) {
            this.setState({ clickflag: true })
            await this.recaptcha.execute();
            this.state.allUsers[index].isdisabled = true;
            const { updateObj, confirmPwd } = this.state;
            updateObj.password = updateObj.password !== "" ? updateObj.password : data.password
            updateObj.userId = data.login
            let pwd = updateObj.password
            if (confirmPwd !== pwd) {
                this.setState({
                    allUsers: this.state.allUsers,
                    success: true,
                    errdata: ["Password and Confirm Password does not match."],
                    message: [],
                    clickflag: false
                })

                return
            }
            await this.props.clearAdminUserErr()
            await this.props.startLoader();
            await this.props.updateAdminUser(updateObj)
            window.scrollTo(0, 0)
            if (this.props?.updatedAdminUser) {
                this.setState({
                    success: true,
                    message: this.props.updatedAdminUser?.message,
                    errdata: []
                })
            } else if (this.props?.updatedAdminUserError) {
                this.setState({
                    success: true,
                    message: [],
                    errdata: this.props.updatedAdminUserError
                })
            }
            this.setState({ clickflag: false })
        }

    }
    saveUser = async (e) => {
        const { saveUser, rePassword } = this.state
        this.setState({
            disabled: true
        })
        e.preventDefault()
        await this.recaptcha.execute();
        await this.props.clearAdminUserErr();
        let pwd = saveUser.password
        if (rePassword !== pwd) {
            this.setState({
                success: true,
                errdata: ["Password and Confirm Password does not match."],
                message: []
            })
            return
        }
        await this.props.startLoader();
        await this.props.createAdminUser(saveUser);
        window.scrollTo(0, 0);
        if (this.props?.createAdminUserRes) {
            this.setState({
                success: true,
                message: "Successfully Created User",
                errdata: [],
                rePassword: ""
            })
        } else if (this.props?.createAdminUserError) {
            this.setState({
                success: true,
                message: [],
                errdata: this.props.createAdminUserError,
                rePassword: ""
            })
        }
        this.setState({
            saveUser: {
                userId: "",
                password: "",

            },
            rePassword: "",
            confirmPwd: ""
        })
    }
    DeleteUser = async (e, data) => {
        e.preventDefault()
        this.setState({
            deleteConfirm: true,
            data: data
        })

    }
    onResolved = async () => {
        const { updateObj, saveUser } = this.state;
        const response = await this.recaptcha.getResponse();
        saveUser.captchaToken = JSON.parse(JSON.stringify(response));
        updateObj.captchaToken = JSON.parse(JSON.stringify(response));;
        this.recaptcha.reset();
    }
    onClose = async () => {
        this.setState({
            success: false,
            disabled: false
        })
        await this.props.startLoader();
        await this.props.getAllAdminUser();
        this.setState({
            allUsers: this.props?.allAdminUsers
        })
    }
    resetPwd = async (e) => {
        e.preventDefault();
        const { updateObj } = this.state
        updateObj.password = ""
        this.setState({
            updateObj
        })
    }
    closeModal = async (e) => {
        this.setState({
            deleteConfirm: false
        })
    }
    onConfirmDelete = async (e) => {
        const { data } = this.state;
        this.setState({
            deleteConfirm: false
        })
        await this.props.startLoader();

        await this.props.deleteAdminUser(data.oid);
        if (this.props?.userDeleted) {
            this.setState({
                success: true,
                message: this.props?.userDeleted.message,
                errdata: [],

            })
        } else if (this.props?.userDeletedError) {
            this.setState({
                success: true,
                message: [],
                errdata: this.props.userDeletedError,

            })
        }
    }
    render() {
        const { saveUser, allUsers, success, errdata, message, rePassword, updateObj, deleteConfirm, disabled } = this.state
        const { loading } = this.props
        return (
            <div>
                {
                    success &&
                    <Modal
                        customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
                        onClick={this.onClose}
                        errorFlag={success}
                    >
                        <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
                    </Modal>
                }
                {
                    deleteConfirm &&
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    `Are you sure you want to delete user?`
                                }
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement> {" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onConfirmDelete(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                }
                <div>
                    {loading &&
                        <div className="text-center spinner">
                            <Spinner primary={true} />
                        </div>}
                </div>
                <div className='col-lg-12'>
                    <h3 className="heading">
                        Admin User
                    </h3>
                </div>
                <div className='container card'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 p-0 d-inline-block table-container">
                            <table className="table data-table table-responsive">
                                <thead className='table-header'>
                                    <tr>
                                        <th>Admin User</th>
                                        <th>Password</th>
                                        <th>Retype Password</th>
                                        <th>Last Login</th>
                                        <th>LastLogOff</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUsers.length > 0 && allUsers.map((data,index) => (
                                        <tr key={data.oid} >
                                            <td>
                                                <div>
                                                    <input
                                                        defaultValue={data.login}
                                                        placeholder="username"
                                                        type="text"
                                                        disabled={true}
                                                        className="search-input p-1  " />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        defaultValue={updateObj.password}
                                                        placeholder="Password"
                                                        onChange={(e) =>
                                                            this.setFormValue(e.target.value, "password")
                                                        }
                                                        type="password"
                                                        className="search-input p-1  " />
                                                </div>

                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        placeholder="Confirm Password"
                                                        onChange={(e) =>
                                                            this.setFormValue2(e.target.value, "confirmPwd")
                                                        }
                                                        type="password"
                                                        className="search-input p-1 " />
                                                </div>
                                            </td>
                                            <td>{new Date(data.createon).toLocaleString()}</td>
                                            <td>{new Date(data.updateon).toLocaleString()}</td>
                                            <td className='incrSize'>

                                                <div className="row d-inline-block">
                                                    <div className=' '>
                                                        <ButtonElement onClick={(e) => this.updateUser(e, data,index)}
                                                            type="submit"
                                                            variant="contained"
                                                            btnType="background-orange button-label"
                                                            className=" mb-2 "
                                                            disabled={data?.isdisabled}
                                                        >
                                                            <span className="button-label" >Update</span>
                                                        </ButtonElement>
                                                    </div>
                                                    <div className=''>
                                                        <ButtonElement onClick={(e) => this.DeleteUser(e, data)}
                                                            type="submit"
                                                            variant="contained"
                                                            btnType="background-orange button-label"

                                                        >
                                                            <span className="button-label" >Delete</span>
                                                        </ButtonElement>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr >
                                        <td>
                                            <input value={saveUser.userId}
                                                placeholder="username"
                                                onChange={(e) =>
                                                    this.setSaveFormValue(e.target.value, "userId")
                                                }
                                                type="text"
                                                className="search-input p-1 pl-4 w-100 " />
                                        </td>
                                        <td>
                                            <input value={saveUser.password}
                                                placeholder="password"

                                                onChange={(e) =>
                                                    this.setSaveFormValue(e.target.value, "password")
                                                }
                                                type="password"
                                                className="search-input p-1 pl-4 w-100 " />
                                        </td>
                                        <td>
                                            <input
                                                placeholder="Confirm Password"
                                                onChange={(e) =>
                                                    this.setFormValue1(e.target.value, "rePassword")
                                                }
                                                type="password"
                                                value={rePassword}
                                                className="search-input p-1 pl-4 w-100 " />
                                        </td>
                                        <td> <div className=''>
                                            <ButtonElement
                                                type="submit"
                                                variant="contained"
                                                btnType="background-orange button-label"
                                                disabled={disabled}
                                            >
                                                <span className="button-label" onClick={(e) => this.saveUser(e)}>Save </span>
                                            </ButtonElement>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Recaptcha
                        size="invisible"
                        ref={(ref) => (this.recaptcha = ref)}
                        onResolved={this.onResolved}
                        sitekey={RECAPTCHA_SITE_KEY}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    infoData: state.adminReducer.infoData,
    allAdminUsers: state.adminReducer.allAdminUsers,
    allAdminUserError: state.adminReducer.allAdminUserError,
    updatedAdminUser: state.adminReducer.updatedAdminUser,
    updatedAdminUserError: state.adminReducer.updatedAdminUserError,
    userDeletedError: state.adminReducer.userDeletedError,
    userDeleted: state.adminReducer.userDeleted,
    createAdminUserRes: state.adminReducer.createAdminUser,
    createAdminUserError: state.adminReducer.createAdminUserError
});

export default connect(mapDispatchStatetoProps, {
    startLoader,
    getInfoData,
    getAllAdminUser,
    clearAdminError,
    updateAdminUser,
    createAdminUser,
    deleteAdminUser,
    clearAdminUserErr
})(adminUser);
