import React from "react";
import DatePicker from "react-date-picker";
import { CalendarIcon } from "../../../assets/images/index";
import "./datePicker.scss";

const DatePickerElement = (props) => {
  return (
    <DatePicker
      onChange={props.onChange}
      className={props.className}
      clearIcon={null}
      minDate={props.minDate}
      maxDate={props.maxDate}
      format="dd-MM-y"
      calendarIcon={<img src={CalendarIcon} alt="calendarIcon" />}
      value={props.value}
      disabled={props.disabled}
      onFocus={e => e.target.blur()}
    />
  );
};

export default DatePickerElement;
