export const HERITAGE_DATE_INSTRUCTION = "Heritage photos are available from the year 1947.";
export const salutations = [
    {
      key: "MR",
      value: "MR",
    },
    {
      key: "MRS",
      value: "MRS",
    },
    {
      key: "MS",
      value: "MS",
    },
];

export const DOWNLOAD_HERITAGE_TITLE = "Download Heritage Photos"
export const DOCUMENT_ID_ERROR = "Document ID is required"
export const DOCUMENT_ID_CHAR_ERROR = "Invalid Document ID. Valid Document ID starts with alpha numeric character."
export const DOCUMENT_ID_PLACEHOLDER = "Document ID"
export const DOCUMENT_ID_LABEL = "Enter a Document ID :"
export const DOWNLOAD_BUTTON_VALUE = "DOWNLOAD"
export const SEARCH_BUTTON_VALUE = "SEARCH"
export const RESET_BUTTON_VALUE = "RESET" 
export const RECAPTCHA_ERROR = "Please complete the reCAPTCHA verification to proceed"