import React, { Component } from "react";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import { SelectElement, ButtonElement, Spinner, Modal } from "../../UI//index";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./announcement.scss";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import {
  getAnnouncements,
  getAnnouncementByLanguage,
  clear_Error,
} from "../../../redux/actions/applicationsAction";
import {
  getInfoForAnnouncement,
  startLoader,
} from "../../../redux/actions/adminAction";
import {
  updateAnnouncement,
  clearAdminError,
} from "../../../redux/actions/adminAction";
import { getUserDetails } from "../../../redux/actions/loginAction";
import ErrorPopup from "../Popup/ErrorPopup";

export class Announcement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      announcementData: {
        languageType: "en",
        announcement: "",
      },
      reset: false,
      announcementContent: EditorState.createEmpty(),
      languageArr: [],
      defaultlang: "en",
      message: "",
      errData: [],
      success: false,
      aKey: "aKey",
      disabled: false,
    };
  }
  async componentDidMount() {
    const { announcementData } = this.state;
    this.props.startLoader();
    if (!this.props?.tokens?.id_token) {
      await this.props.getUserDetails();
    }
    await this.props.getInfoForAnnouncement();
    let languageType =
      this.props &&
      this.props.getInfoDataForAnnouncement &&
      this.props.getInfoDataForAnnouncement.languages;
    let languages = languageType.map((language) => ({
      key: language.language,
      value: language.langCode,
    }));
    this.setState({
      languageArr: languages,
    });
    await this.props.getAnnouncementByLanguage({ language: "en" });
    let ann =
      this.props &&
      this.props.announceByLanguage &&
      this.props.announceByLanguage;
    let annvalue = ann[Object.keys(ann)[0]];
    if (
      this.props &&
      this.props.announceByLanguage &&
      this.props.announceByLanguage
    ) {
      announcementData.announcement = annvalue;
    }
    this.setState({
      announcementData,
    });
  }
  changeUnderlineFormat = (text, type) => {
    if (type == "get") {
      return text.replaceAll("<u>", "<ins>").replaceAll("</u>", "</ins>");
    } else {
      return text.replaceAll("<ins>", "<u>").replaceAll("</ins>", "</u>");
    }
  };

  onEditorStateChange = (content) => {
    const { announcementData } = this.state;
    announcementData.announcement = draftToHtml(
      convertToRaw(content.getCurrentContent())
    );

    this.setState({
      announcementData,
    });
  };
  onReset = async () => {
    const { announcementData } = this.state;
    announcementData.announcement = "<p></p>\n";
    this.setState({
      reset: true,
      aKey: Math.random(),
      announcementData,
      announcementContent: EditorState.createEmpty(),
    });
  };
  setFormValue = async (value, field, number = false) => {
    const { announcementData } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      announcementData[field] =
        regex.test(value) || value === "" ? value : announcementData[field];
    } else {
      announcementData[field] = value;
    }
    if (announcementData.announcement) {
      this.setState({
        announcementData: {
          announcement: "",
        },
      });
    }
    this.setState({
      reset: false,
    });
    await this.props.getAnnouncementByLanguage({ language: value });
    let ann = this.props && this.props.announceByLanguage;
    let annvalue = ann[Object.keys(ann)[0]];
    if (this.props && this.props.announceByLanguage) {
      announcementData.announcement = annvalue;
    }
    this.setState({
      announcementData,
    });
  };
  onSave = async (e) => {
    this.setState({
      disabled: true,
    });
    e.preventDefault();
    this.recaptcha.execute();
  };
  onResolved = async () => {
    const { announcementData } = this.state;
    const response = this.recaptcha.getResponse();
    announcementData.captchaToken = response;
    await this.props.startLoader();
    await this.props.clearAdminError();
    let updateObj = {
      announcement: this.changeUnderlineFormat(
        announcementData.announcement,
        "put"
      ),
      language: announcementData.languageType
        ? announcementData.languageType
        : "en",
      captchaToken: response,
    };
    this.props.startLoader();
    await this.props.updateAnnouncement(updateObj);
    if (this.props?.updatedAnnouncement) {
      this.setState({
        success: true,
        message: this.props.updatedAnnouncement.message,
        errdata: [],
      });
    } else if (this.props.updatedAnnouncementError) {
      this.setState({
        success: true,
        errdata: this.props.updatedAnnouncementError,
        message: "",
      });
    }
  };

  onClose = () => {
    this.setState({
      disabled: false,
      success: false,
    });
  };
  render() {
    const { loading } = this.props;
    const {
      announcementData,
      announcementContent,
      reset,
      languageArr,
      success,
      errdata,
      message,
      aKey,
      disabled,
    } = this.state;
    return (
      <>
        {success && (
          <Modal
            customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
            onClick={this.onClose}
            errorFlag={success}
          >
            <ErrorPopup
              onClose={this.onClose}
              msg={message}
              errData={errdata}
              {...this.props}
            />
          </Modal>
        )}
        {loading && (
          <div className="loader text-center ">
            <Spinner primary={true} />
          </div>
        )}
        <form className="">
          <h3 className="heading">Announcement</h3>
          <div className=" mr10">
            <label className="sortorder pt-3 pr-3 ">Select a Language :</label>
            <SelectElement
              className="col-md-3"
              options={languageArr}
              defaultValue={announcementData.languageType}
              onChange={(e) =>
                this.setFormValue(e.target.value, "languageType")
              }
            />
          </div>
          <div className="editor ml0" style={{ height: 400 }} key={aKey}>
            {announcementData && announcementData.announcement && !reset && (
              <Editor
                defaultEditorState={
                  announcementData
                    ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(announcementData.announcement)
                        )
                      )
                    : announcementContent
                }
                EditorState={announcementContent}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onEditorStateChange}
                editorStyle={{ height: "400px" }}
                toolbar={{
                  options: ["inline", "blockType", "fontSize", "list", "link"],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: { inDropdown: true },
                  blockType: {
                    inDropdown: true,
                    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                  },
                  // textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  // history: { inDropdown: true },
                }}
              />
            )}
            {reset && (
              <Editor
                defaultEditorState={announcementContent}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onEditorStateChange}
                editorStyle={{ height: "400px" }}
                toolbar={{
                  options: ["inline", "blockType", "fontSize", "list", "link"],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  blockType: {
                    inDropdown: true,
                    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                  },
                  list: { inDropdown: true },
                  // textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  // history: { inDropdown: true },
                }}
              />
            )}
          </div>
          <div>
            <div className="text-right pt-3 pb-5">
              <ButtonElement
                variant="contained"
                btnType="background-orange button-label"
                className="d-inline-block mr-2 float-right"
                onClick={this.onReset}
              >
                <span className="button-label">Reset</span>
              </ButtonElement>
              <ButtonElement
                type="submit"
                variant="contained"
                btnType="background-orange button-label"
                className="d-inline-block mr-2 float-right"
                onClick={(e) => this.onSave(e)}
                disabled={disabled}
              >
                <span className="button-label">Save</span>
              </ButtonElement>
            </div>
          </div>
        </form>
        <Recaptcha
          size="invisible"
          ref={(ref) => (this.recaptcha = ref)}
          onResolved={this.onResolved}
          sitekey={RECAPTCHA_SITE_KEY}
        />
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.adminReducer.isLoading,
  announcements: state.applicationReducer.announcements,
  announceByLanguage: state.applicationReducer.announceByLanguage,
  getInfoDataForAnnouncement: state.adminReducer.getInfoDataForAnnouncement,
  updatedAnnouncement: state.adminReducer.updatedAnnouncement,
  updatedAnnouncementError: state.adminReducer.updatedAnnouncementError,
  tokens: state.loginReducer.tokens,
});
export default connect(mapDispatchStatetoProps, {
  startLoader,
  getAnnouncements,
  getAnnouncementByLanguage,
  updateAnnouncement,
  getInfoForAnnouncement,
  getUserDetails,
  clear_Error,
  clearAdminError,
})(Announcement);
