import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonElement, Checkbox, Spinner } from "../UI/index";
import SubscriptionCard from "./subscriptionCard";
// import { permissions } from "../../helpers/common";
import { getSubscriptionPackages, startLoader,getCmpPreferenceList,  pageViewDatagtm,customViewDatagtm } from "../../redux/actions/applicationsAction";
import "./css/subscription.scss";
// import SearchResultNotFound from "../UI/Not Found/SearchResultNotFound";
import DOMPurify from 'dompurify';
import { getPageviewobj } from "../../helpers/common";
class Subscription extends Component {

  state = {
    selectedSub: {},
    purposes:{}
  }

  async componentDidMount() {
    this.props.startLoader();
   await this.props.getSubscriptionPackages();
    await this.props.getCmpPreferenceList();

    const { purposes } = this.state;
    for(let x of this.props.cmpPreferenceList){
      if(x.required){
        purposes[x.id] = true;
      }
    }
    this.setState({purposes});

    this.setPageViewcall(this.props.searchObject,{});
  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
      let url=window.location.href;
      let obj= getPageviewobj(url,"subscription","Subscription",user_status,"","","",id);
      this.props.pageViewDatagtm(obj,searchObject);
    }

  onSelectSubPlan = (selectedSub) => {this.setState({
    selectedSub
  })
  if(window.innerWidth > 767 ){
    window.scrollTo(0, 400);
  }
}

  onSelectPermission = (value, field) => {
    const { purposes } = this.state;
    purposes[field] = value;
    this.setState({purposes});
  }

  onSubmit = () => {
    const {selectedSub} = this.state;
    if (this.state.selectedSub.description) {
         let purposeData=[];
         for(let x in this.state.purposes){
           if(this.state.purposes[x]){
               purposeData.push({Id:x})
           }
         }
         let cmpUpsertBody= {
          "Language": "en-US",
          "Purposes":purposeData
        }
        selectedSub.cmpUpsertBody=cmpUpsertBody;
        this.setState({selectedSub});
        this.props.history.push({
        pathname: "/subscription-form",
        selectedSub: this.state.selectedSub
      })
    }
    else {
      window.scrollTo(0, 0);
      this.setState({
        selectSubErrorMessage: "Please select a subscription package."
      })
    }
  }




  render() {
    const { selectedSub, selectSubErrorMessage,purposes } = this.state;

    const { packages, loading ,cmpPreferenceList} = this.props;
    return (
      <div className="subscription-container">
        {loading || !packages ? (
          <div className="text-center" key="spineer">
            <Spinner primary={true} />
          </div>
        ) : (
            <><div className="subs-content" key="packagediv">
              <div className="col-md-10 m-auto pl-lg-5">
                <div className={selectSubErrorMessage ? "" : "mb-4 pkg-sub"}>Choose your subscription package:</div>
                <div className="col-md-12 col-12 mb-3 p-0">
                  {selectSubErrorMessage ? <div className=" pt-1 pb-4">
                    <h6 style={{color:"red"}}>
                      {selectSubErrorMessage}
                    </h6>
                  </div> : null}
                  <div className="col-md-12 col-12 m-auto">
                    {
                      packages.basePackages && Object.keys(packages.basePackages).map((sub,index) => (
                        <SubscriptionCard key={sub} subscription={packages.basePackages[sub]} selected={selectedSub.description === packages.basePackages[sub].description} onSelectSubPlan={this.onSelectSubPlan}  />
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
              <div className="mark-promo-container col-md-10 m-auto">
                <h3 className="heading">Marketing Promotion Materials</h3>
                {/* <h6 className="sub-heading">
                  I would like to receive marketing information from Singapore Press
                  Holdings Ltd and its related corporations ("SPH") or SPH's service
                  providers, on products or services offered by SPH or other parties:
               </h6> */}

                <div className="checkbox-container">
                  {cmpPreferenceList && cmpPreferenceList.map((item,index) => {
                      return(
                        <>
                        { item.content && item.description ? (
                           <>
                           <div className="d-flex "  key={index} >
                             {/* <div className="checkboxlevel">
                               <p>{item.order+ 1}.</p>
                             </div> */}
                             <div>
                              <Checkbox key={index} checked={purposes[item.id]} onChange={(e) =>{
                                !item.required &&
                                this.onSelectPermission(e.target.checked, item.id)}
                              } />
                              </div>
                              <div className="checkboxlevel">
                              {item.content && <div
                                dangerouslySetInnerHTML={{
                                  __html: `${DOMPurify.sanitize(item.content)}`,
                                }}
                              >
                              </div>
                              }
                                  </div>
                              </div>
                          </>
                        ):(
                          <>
                                {/* <div key={index}>
                                  {item.description && <Checkbox key={index} label={item.description} checked={selectedSub[item.id]} onChange={(e) =>
                                    this.onSelectPermission(e.target.checked, item.id)
                                  } />}
                                </div> */}
                                <div key={index}>
                                {!item.description && <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${DOMPurify.sanitize(item.content)}`,
                                  }}
                                >
                                </div>}
                                </div>
                           </>
                        )}



                      </>
                      )

                  })}

                </div>
                {/* <h6 className="tips">
                  (Please check or un-check as appropriate. Checking box 2 above
            supersedes checking box 1.){" "}
                </h6>
                <h6 className="tips">
                  By checking or leaving checked any of the boxes above, I agree that
                  SPH may collect, use and/or disclose my personal data for the above
                  purposes. Un-checking or leaving un-checked any of the boxes above
                  will not be treated as a withdrawal of any other consents I may have
                  previously provided to SPH for the above purposes.
                </h6> */}




                <div className="d-flex mb-4 mt-4">
                 {cmpPreferenceList && <ButtonElement
                    variant="contained"
                    btnType="background-orange"
                    className="mr-2 button-control next-button col-md-3 col-12"
                    onClick={this.onSubmit}
                  >
                    <span className="button-label">NEXT</span>
                  </ButtonElement>}
                </div>
              </div>
            </>)}
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.applicationReducer.isLoading,
  packages: state.applicationReducer.packages,
  cmpPreferenceList: state.applicationReducer.cmpPreferenceList
});

export default connect(mapDispatchStatetoProps, {
  getSubscriptionPackages,
  startLoader,
  getCmpPreferenceList,
  pageViewDatagtm,
  customViewDatagtm
})(Subscription);
