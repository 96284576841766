import React, { Component } from 'react'
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { RECAPTCHA_SITE_KEY } from '../../../../config/constants/index';
import "../CreateAccount/CreateAccount.scss"
import { startLoader, getSubscriptionStatus, updateSubscription, calculateFeeByPkg, calculateFeeBySubscriptionFee, clearAdminError, createUserSubscription } from '../../../../redux/actions/adminAction';
import { ButtonElement, DatePickerElement, Spinner, Modal } from '../../../UI//index';
import { getDate, getOneYearDate } from '../../../../helpers/common';
import { Checkbox, SelectElement } from '../../../UI//index';
import { debounce } from "lodash";
import 'react-toastify/dist/ReactToastify.css';
import ErrorPopup from '../../Popup/ErrorPopup';
import { startCase, camelCase } from 'lodash'
export class UserSubscription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      minDate: getDate(),
      fromDate: new Date(),
      toDate: getOneYearDate(),
      activeSubscription: [],
      subscriptionStatus: [],
      paymentModeArr: [],
      pkgDecription: "",
      subsciptionFlag: "",
      gst: 0,
      total: 0,
      success: false,
      errdata: [],
      failure: false,
      message: "",
      userId: 0,
      usersubscription: {
        // toDate: "",
        subscriptionFee: "",
        subStatus: "PENDING",
        userName: "",
        subscriptionId: 0,
        allowMagazineSearch: false,
        articleRetrievalFee: "",
        disallowArticle: false,
        disallowPdf: false,
        // payRef: "",
        // payment: 1,
        pkg: "none",
        sphPdfRetrievalFee: "",
        sphmPdfRetrievalFee: "",
        // fromDate: "",
        paymentInfoDTO: {
          paymentMode: 1,
          paymentRef: ""
        },
        dateRange: {
          requestDateFrom: "",
          requestDateTo: ""
        },
      },
      currentGst: 0,
      newSubscription: false,
      packageUpdate: "",
      disabledSelect: true,
      btnDisable: false
    }
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { usersubscription } = this.state;
    await this.props.startLoader();
    // await this.props.getInfoData();
    // await this.props.startLoader();
    await this.props.getSubscriptionStatus();

    let newSubscription = this.props.location?.state?.newSubscription;
    let packageUpdate = this.props.location?.state?.packageUpdate;
    if (!newSubscription && (packageUpdate && packageUpdate.length >= 0)) {
      this.setState({
        packageUpdate: packageUpdate,
        disabledSelect: false
      })
    } else {
      this.setState({
        packageUpdate: packageUpdate,
        disabledSelect: false
      })
    }
    if (newSubscription) {
      this.setState({
        disabledSelect: false
      })
    }
    this.setState({
      newSubscription: newSubscription,
      packageUpdate: packageUpdate
    })
    let basePackageVO = Object.keys(this.props?.infoData?.packages?.basePackageVO)
    let allSubscriptionPackage = this.props?.infoData?.packages?.basePackageVO
    let newSubscriptionArr = basePackageVO.map((subscriptionPackage) => {
      return ({ key: allSubscriptionPackage[subscriptionPackage].description, value: subscriptionPackage })
    })
    let subscription_Status = Object.keys(this.props.subscriptionStatus.status)
    let subscription_Status_Arr = [];
    subscription_Status_Arr = subscription_Status.map((status) => {
      return ({ key: startCase(camelCase(status)), value: status })
    })
    let paymentMode = Object.keys(this.props.infoData?.paymentType)
    let paymentModeArr = [];
    paymentModeArr = paymentMode.map((mode) => {
      return ({ key: this.props.infoData.paymentType[mode], value: mode })
    })
    this.setState({
      activeSubscription: newSubscriptionArr,
      subscriptionStatus: subscription_Status_Arr,
      paymentModeArr: paymentModeArr
    })
    if (this.props.location?.state?.subscriptionDetail) {
      let subscriptionDetail = this.props.location?.state?.subscriptionDetail;
      usersubscription.userName = subscriptionDetail.login;
      this.setState({
        userId: this.props.location?.state?.subscriptionDetail?.oid,
      })
    }
    if (this.props.location && this.props.location?.state?.extSubscription) {
      let extSubscription = this.props.location?.state?.extSubscription
      if (!this.props.location?.state?.newSubscription) {
        usersubscription.dateRange["requestDateTo"] = extSubscription.to;
        usersubscription.dateRange["requestDateFrom"] = extSubscription.from;
        usersubscription.pkg = extSubscription.subscriptionPackage;
        usersubscription.subStatus = extSubscription.status !== "NA" ? extSubscription.status : "PENDING";
        usersubscription.subscriptionId = extSubscription.subscriptionId;
        usersubscription.paymentInfoDTO["paymentMode"] = extSubscription.payment !== "NA" ? Number(extSubscription.payment) : 1
        usersubscription.paymentInfoDTO["paymentRef"] = extSubscription.paymentRefNo !== "NA" ? extSubscription.paymentRefNo : ""
        usersubscription.sphmPdfRetrievalFee = extSubscription.sphmPdfRetrievalFee === "NA" ? "" : extSubscription.sphmPdfRetrievalFee;
      }
    }
    let to_Date = !this.props.location?.state?.newSubscription ? new Date(this.props?.location?.state?.extSubscription?.to) : getOneYearDate();
    let from_Date = !this.props.location?.state?.newSubscription ? new Date(this.props?.location?.state?.extSubscription?.from) : new Date();
    let modifiedFromdate = new Date(from_Date.getTime() - (from_Date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];

    let modifiedTodate = new Date(to_Date.getTime() - (to_Date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
    usersubscription.dateRange["requestDateFrom"] = modifiedFromdate;
    usersubscription.dateRange["requestDateTo"] = modifiedTodate;
    let pkg = this.props?.location?.state?.extSubscription?.subscriptionPackage ? this.props?.location?.state?.extSubscription?.subscriptionPackage : "none"
    await this.props.startLoader();
    await this.props.calculateFeeByPkg({ pkg: pkg })
    let dataKey = pkg
    let pkgDecription = this.props?.pkgInfo?.message[dataKey]
    let userSubscription = this.props.location?.state?.extSubscription;
    let subFee = !userSubscription ? this.props?.pkgInfo?.subscription?.fee : userSubscription?.fee
    await this.props.startLoader();
    await this.props.calculateFeeBySubscriptionFee(subFee)
    usersubscription["subscriptionFee"] = subFee;
    usersubscription["articleRetrievalFee"] = !userSubscription ? this.props?.pkgInfo?.subscription?.priceArticle : userSubscription?.articleRetrievalFee;
    usersubscription["sphPdfRetrievalFee"] = !userSubscription ? this.props?.pkgInfo?.subscription?.pricePdf : userSubscription?.sphPdfRetrievalFee;
    // usersubscription["sphmPdfRetrievalFee"]=this.props?.pkgInfo?.subscription?.searchSphmpdf === "NA" ? "" : this.props?.pkgInfo?.subscription?.searchSphmpdf;

    this.setState({
      usersubscription
    })
    let gst = this.props?.calculateFeeRes?.subscription.gst;
    let total = this.props?.calculateFeeRes?.subscription.total;

    // let gst = this.props?.pkgInfo?.subscription?.gst
    // let total = this.props?.pkgInfo?.subscription?.total
    let disabledFileds = this.props?.pkgInfo?.subscription.disabledFields
    if (disabledFileds && disabledFileds.length > 0) {
      if (disabledFileds.includes("pricePdf")) {
        usersubscription["disallowPdf"] = true
      } else {
        usersubscription["disallowPdf"] = false
      }
      if (disabledFileds.includes("priceArticle")) {
        usersubscription["disallowArticle"] = true
      } else {
        usersubscription["disallowArticle"] = false

      }
    } else {
      if (this.props?.pkgInfo?.subscription?.priceArticle === "NA") {
        usersubscription["disallowArticle"] = true
      } else {
        usersubscription["disallowArticle"] = false
      }
      if (this.props?.pkgInfo?.subscription?.pricePdf === "NA") {
        usersubscription["disallowPdf"] = true
      } else {
        usersubscription["disallowPdf"] = false
      }
    }
    if (this.props?.infoData?.sysConfig) {
      this.setState({
        currentGst: this.props?.infoData?.sysConfig?.current_gst ? (Number(this.props?.infoData?.sysConfig?.current_gst) * 100).toFixed(0) : 0
      })
    }
    this.setState({
      // userId: this.props.location?.state?.subscriptionDetail?.oid,
      pkgDecription: pkgDecription,
      usersubscription,
      toDate: to_Date,
      fromDate: from_Date,
      gst: gst,
      total: total

    })

  }

  onSubmit = (e) => {
    const { newSubscription } = this.state

    this.setState({
      btnDisable: true
    })

    e.preventDefault();
    this.recaptcha.execute();
  }
  onResolved = async () => {
    const { usersubscription, newSubscription } = this.state
    const response = this.recaptcha.getResponse();
    usersubscription.captchaToken = response;
    let tempSubStatus = usersubscription.subStatus;
    usersubscription.subStatus = this.props?.subscriptionStatus?.status[usersubscription.subStatus];
    if (usersubscription["sphPdfRetrievalFee"] === "NA") {
      usersubscription["sphPdfRetrievalFee"] = 0
    }
    if (usersubscription["articleRetrievalFee"] === "NA") {
      usersubscription["articleRetrievalFee"] = 0
    }
    usersubscription.paymentInfoDTO["paymentRef"] = usersubscription.paymentInfoDTO["paymentRef"] ? usersubscription.paymentInfoDTO["paymentRef"].trim() : usersubscription.paymentInfoDTO.paymentRef

    if (!newSubscription) {
      await this.props.clearAdminError();
      this.props.startLoader();
      await this.props.updateSubscription(usersubscription)
      usersubscription.subStatus = tempSubStatus;
      this.setState({
        usersubscription
      })
      if (this.props?.userSubscriptionRes) {
        this.setState({
          success: true,
          message: this.props.userSubscriptionRes.message,
          errdata: ""
        })
      } else if (this.props.userSubscriptionResError) {
        this.setState({
          success: true,
          errdata: this.props.userSubscriptionResError,
          message: ""
        })
      }
    } else {
      let createObj = {
        "userId": usersubscription.userName,
        "paymentInfoDTO": {
          "paymentMode": usersubscription.paymentInfoDTO["paymentMode"],
          "paymentRef": usersubscription.paymentInfoDTO["paymentRef"]
        },
        "subscriptionInfoDTO": {
          "subscriptionId": 0,
          "subscriptionFee": usersubscription.subscriptionFee,
          "articleRetrievalFee": usersubscription.articleRetrievalFee,
          "sphPdfRetrievalFee": usersubscription.sphPdfRetrievalFee,
          "allowMagazineSearch": usersubscription.allowMagazineSearch,
          "sphmPdfRetrievalFee": usersubscription.sphmPdfRetrievalFee,
          "disallowArticle": usersubscription.disallowArticle,
          "disallowPdf": usersubscription.disallowPdf,
          "pkg": usersubscription.pkg,
          "subStatus": usersubscription.subStatus,
          "payment": usersubscription.paymentInfoDTO["paymentMode"],
          "payRef": usersubscription.paymentInfoDTO["paymentRef"],
          "captchaToken": usersubscription.captchaToken,
          // "fromDate": usersubscription.fromDate,
          // "toDate": usersubscription.toDate,
          "dateRange": {
            "requestDateFrom": usersubscription.dateRange["requestDateFrom"],
            "requestDateTo": usersubscription.dateRange["requestDateTo"]
          },

        }
      }
      await this.props.clearAdminError();
      this.props.startLoader();
      await this.props.createUserSubscription(createObj)
      // this.setState({
      //   btnDisable: true
      // })
      usersubscription.subStatus = tempSubStatus;
      if (this.props?.newUserSubscription) {
        this.setState({
          success: true,
          message: this.props.newUserSubscription.message,
          errdata: "",

        })
      } else if (this.props.newUserSubscriptionErr) {
        this.setState({
          success: true,
          errdata: this.props.newUserSubscriptionErr,
          message: ""
        })
      }
    }

  }

  onfromDateChange = (date) => {
    const { usersubscription } = this.state;
    let dt = new Date(date);
    let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
    usersubscription.dateRange["requestDateFrom"] = modifieddate;
    this.setState({
      usersubscription,
      fromDate: date
    });
  };

  ontoDateChange = (date) => {
    const { usersubscription } = this.state;
    let dt = new Date(date);
    let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];

    usersubscription.dateRange["requestDateTo"] = modifieddate;
    this.setState({
      usersubscription,
      toDate: date
    });
  };

  setPkg = async (value, field, number = false) => {
    const { usersubscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      usersubscription[field] = regex.test(value) || value === "" ? value : usersubscription[field];
    } else {
      usersubscription[field] = value;
    }
    this.setState({
      usersubscription,
    });
    await this.props.startLoader();
    await this.props.calculateFeeByPkg({ pkg: value })
    let dataKey = value;
    let pkgDecription = this.props?.pkgInfo?.message[dataKey]
    usersubscription["subscriptionFee"] = this.props?.pkgInfo?.subscription?.fee;
    usersubscription["articleRetrievalFee"] = this.props?.pkgInfo?.subscription?.priceArticle;
    usersubscription["sphPdfRetrievalFee"] = this.props?.pkgInfo?.subscription?.pricePdf;
    await this.props.calculateFeeBySubscriptionFee(usersubscription["subscriptionFee"])
    let gst = this.props?.calculateFeeRes?.subscription.gst;
    let total = this.props?.calculateFeeRes?.subscription.total;
    let disabledFileds = this.props?.pkgInfo?.subscription.disabledFields
    if (disabledFileds && disabledFileds.length > 0) {
      if (disabledFileds.includes("pricePdf")) {
        usersubscription["disallowPdf"] = true
      } else {
        usersubscription["disallowPdf"] = false
      }
      if (disabledFileds.includes("priceArticle")) {
        usersubscription["disallowArticle"] = true
      } else {
        usersubscription["disallowArticle"] = false

      }
    } else {
      if (this.props?.pkgInfo?.subscription?.priceArticle === "NA") {
        usersubscription["disallowArticle"] = true
      } else {
        usersubscription["disallowArticle"] = false
      }
      if (this.props?.pkgInfo?.subscription?.pricePdf === "NA") {
        usersubscription["disallowPdf"] = true
      } else {
        usersubscription["disallowPdf"] = false
      }
    }
    this.setState({
      pkgDecription: pkgDecription,
      usersubscription,
      gst: gst,
      total: total
    })
  }

  setSubscriptionChange = (value, field, number = false) => {
    const { usersubscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      usersubscription[field] = regex.test(value) || value === "" ? value : usersubscription[field];
    } else {
      if (!isNaN(value)) {
        usersubscription[field] = (value.toString().trim());
      }

      // usersubscription[field] = value;
    }
    this.setState({
      usersubscription,
    });
    this.calculateFee(value)
  }
  calculateFee = debounce(async (value) => {
    const { pkgDecription, usersubscription } = this.state;
    if (pkgDecription) {
      let str = pkgDecription;
      if (str.includes("Yearly subscription fee:")) {
        let part1 = str.split(":");
        let subSplit = part1[1].split(" ")
        let fStr = str.replace(subSplit[1], "$" + value)
        this.setState({
          pkgDecription: fStr
        })
      }
    }
    await this.props.calculateFeeBySubscriptionFee(value)
    let gst = this.props?.calculateFeeRes?.subscription.gst;
    let total = this.props?.calculateFeeRes?.subscription.total;
    this.setState({
      usersubscription,
      gst: gst,
      total: total
    })
  }, 500);
  onArticleRetrivalFeeChange = (value, field, number = false) => {
    const { usersubscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      usersubscription[field] = regex.test(value) || value === "" ? value : usersubscription[field];
    } else {
      if (!isNaN(value)) {
        usersubscription[field] = (value.toString().trim());
      }
    }
    this.setState({
      usersubscription,
    });
    this.updateArticleFee(value)
  }
  updateArticleFee = debounce(async (value) => {
    const { pkgDecription } = this.state;
    if (pkgDecription) {
      let str = pkgDecription;
      if (str.includes("Article retrieval fee:")) {
        let part1 = str.split(":");
        let subSplit = "";
        if (part1[0].includes("Article retrieval fee")) {
          subSplit = part1[1].split(" ")
        } else {
          subSplit = part1[2].split(" ")
        }
        let str1 = "Article retrieval fee: " + subSplit[1];
        let str2 = "Article retrieval fee: $" + value;
        let newStr = str.replace(str1, str2)
        this.setState({
          pkgDecription: newStr
        })
      }
    }
  }, 500);

  onChangeSphPdfRetrievalFee = (value, field, number = false) => {
    const { usersubscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      usersubscription[field] = regex.test(value) || value === "" ? value : usersubscription[field];
    } else {
      if (!isNaN(value)) {
        usersubscription[field] = (value.toString().trim());
      }

      // usersubscription[field] = value;
    }
    this.setState({
      usersubscription,
    });
    this.updatePDFREtrivaleFee(value)
  }
  updatePDFREtrivaleFee = debounce(async (value) => {
    const { pkgDecription } = this.state;
    if (pkgDecription) {
      let str = pkgDecription;
      if (str.includes("Full Page PDF retrieval fee:")) {
        let part1 = str.split(":");
        let subSplit = "";
        if (part1[1].includes("Full Page PDF retrieval fee")) {
          subSplit = part1[2].split(" ")
        } else {
          subSplit = part1[3].split(" ")
        }
        let str1 = "Full Page PDF retrieval fee: " + subSplit[1];
        let str2 = "Full Page PDF retrieval fee: $" + value;
        let newStr = str.replace(str1, str2)
        this.setState({
          pkgDecription: newStr
        })
      }
    }
  }, 500);

  setPaymentInfoDTO = (value, field, number = false) => {
    const { usersubscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      usersubscription.paymentInfoDTO[field] = regex.test(value) || value === "" ? value : usersubscription.paymentInfoDTO[field];
    } else {
      usersubscription.paymentInfoDTO[field] = value;
    }
    this.setState({
      usersubscription,
    });
  }
  setSubscriptionInfoDTO = (value, field, number = false) => {
    const { usersubscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      usersubscription[field] = regex.test(value) || value === "" ? value : usersubscription[field];
    } else {
      if (field == "sphmPdfRetrievalFee") {
        if (!isNaN(value)) {
          usersubscription[field] = (value.toString().trim());
        }
      } else {
        usersubscription[field] = value;
      }
    }
    this.setState({
      usersubscription,
    });
  }
  onClose = async (e) => {
    const { userId, usersubscription } = this.state;
    this.setState({
      success: false,
      btnDisable: false
    })
    if (this.props?.userSubscriptionRes?.message || this.props?.newUserSubscription?.message) {
      await this.props.history.push({
        pathname: '/admin/userdetail-subscription',
        searchObject: {
          userId: userId
        }
      })
    }
    // let tempSubStatus = usersubscription.subStatus;
    // usersubscription.subStatus = this.props?.subscriptionStatus?.status[usersubscription.subStatus];

  }
  onClosePopup = async () => {
    this.setState({
      success: false,
      btnDisable: false
    })
  }
  goback = async (e) => {
    e.preventDefault();
    const { userId } = this.state;
    await this.props.history.push({
      pathname: '/admin/userdetail-subscription',
      searchObject: {
        userId: userId
      }
    })
  }
  render() {
    const { activeSubscription, minDate, fromDate, toDate, paymentModeArr, subscriptionStatus, usersubscription, pkgDecription, gst, total, success, message, errdata, currentGst, newSubscription, packageUpdate, disabledSelect, btnDisable } = this.state;
    const { loading } = this.props;
    return (
      <>
        {
          success &&
          <Modal
            customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
            onClick={this.onClosePopup}
            errorFlag={success}
          >
            <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
          </Modal>
        }
        {loading && <div className="text-center loader">
          {/* <Spinner animation="border" variant="primary" /> */}
          <Spinner primary={true} />
        </div>}

        <div>
          <form className="container-fluid"
            noValidate onSubmit={(e) => this.onSubmit(e)}>
            <h2 className="heading heading-bold">User Subscription</h2>
            <div className='row'>
              <div className='col-lg-3 col-6 col-md-3 pb-2'><label>Username :</label>
              </div>
              <div className='col-md-6 col-6 col-md-4 pb-2'>
                <b>{usersubscription.userName}</b></div>
            </div>
            <div className='row '>
              <div className="col-lg-3 col-6 col-md-3 pb-2"><label>From<span className="requiredStar">*</span></label></div>
              <div className="col-lg-4 col-6 col-md-6 pb-2">
                <div className="pb-2 pt-2 dates">
                  <DatePickerElement
                    minDate={!newSubscription ? "" : new Date()}
                    onChange={this.onfromDateChange}
                    className="w-100"
                    value={fromDate}
                    // maxDate={new Date()}
                    disabled={!newSubscription ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-3 col-6 col-md-3 pb-2"><label>To<span className="requiredStar">*</span></label></div>
              <div className="col-lg-4 col-6 col-md-6 pb-2">
                <div className="pb-2 pt-2 dates">
                  <DatePickerElement
                    minDate={fromDate}
                    onChange={this.ontoDateChange}
                    className="w-100"
                    value={toDate}
                  // maxDate={new Date()}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-3 col-6 col-md-3 pb-2'><label className=''>Package<span className="requiredStar">*</span></label></div>
              <div className="col-lg-4 col-6 col-md-6 pb-2">
                {activeSubscription.length > 0 && <SelectElement
                  defaultValue={usersubscription.pkg}
                  options={activeSubscription}
                  disabled={disabledSelect}
                  className="p-1"
                  disablePkgName={packageUpdate}
                  // creatflag={newSubscription ? false : true}
                  onChange={(e) => this.setPkg(e.target.value, "pkg")}
                />}
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-3 col-md-3 pb-2"></div>
              <div className="col-lg-6  col-md-6 pb-2">
                {pkgDecription ? pkgDecription : ""}
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-3 col-6 col-md-3 pb-2">
                <label className=''>Subscription Fee <span className="requiredStar">*</span></label>
              </div>
              <div className="col-lg-4 col-6 col-md-6 ">
                <input
                  placeholder="Subscription Fee"
                  value={usersubscription.subscriptionFee}
                  onChange={(e) => this.setSubscriptionChange(e.target.value, "subscriptionFee")}
                  type="text"
                  className="p-1 pl-4 w-100"
                />
              </div>
              <div className="col-lg-5 col-6 col-md-4 pb-2">
                {/* <label></label> */}
                <span className=''>GST({currentGst}%):<b>${gst}</b></span><br />
                <span className=''>Total (fee+GST):<b>${total}</b></span>

              </div>
              {/* <div className="col-lg-3 col-6 col-md-4 pb-2">
                <label></label>
              </div> */}
            </div>
            <div className='row'>
              <div className="col-lg-3 col-6 col-md-4 pb-2">
                <label className=''>Article Retrival Fee <span className="requiredStar">*</span></label>
              </div>
              <div className="col-lg-4 col-6 col-md-4 pb-2">
                <input
                  placeholder="Article Retrival Fee"
                  value={usersubscription.articleRetrievalFee === "NA" ? "" : usersubscription.articleRetrievalFee}
                  onChange={(e) => this.onArticleRetrivalFeeChange(e.target.value, "articleRetrievalFee")}
                  type="text"
                  disabled={usersubscription.disallowArticle ? true : false}
                  className={!usersubscription.disallowArticle ? "p-1 pl-4 w-100" : "p-1 pl-4 w-100 disabled"}
                />
              </div>
              <div className="col-lg-4 col-md-4 pb-2 nl_article_access">
                <span className=''>
                  <Checkbox
                    checked={usersubscription.disallowArticle} onChange={(e) =>
                      this.setSubscriptionInfoDTO(e.target.checked, "disallowArticle")}
                  />
                  <span className="margin-left-35">Disallow Article Access </span>
                </span>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-3 col-6 col-md-4 pb-2">
                <label className=''>SPH PDF retrival Fee <span className="requiredStar">*</span></label>
              </div>
              <div className="col-lg-4 col-6 col-md-4 pb-2">
                <input
                  placeholder="SPH PDF retrival Fee"
                  value={usersubscription.sphPdfRetrievalFee === "NA" ? "" : usersubscription.sphPdfRetrievalFee}
                  onChange={(e) => this.onChangeSphPdfRetrievalFee(e.target.value, "sphPdfRetrievalFee")}
                  type="text"
                  disabled={usersubscription.disallowPdf ? true : false}
                  className={!usersubscription.disallowPdf ? "p-1 pl-4 w-100" : "p-1 pl-4 w-100 disabled"}
                />

              </div>
              <div className="col-lg-4 col-md-4 pb-2 nl_article_access">
                <span className=''>
                  <Checkbox
                    checked={usersubscription.disallowPdf} onChange={(e) =>
                      this.setSubscriptionInfoDTO(e.target.checked, "disallowPdf")}
                  />
                  <span className="margin-left-35">Disallow PDF Access </span>
                </span>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-3 col-6 col-md-4 pb-2">
                <label className=''>SPHM PDF retrival Fee <span className="requiredStar">*</span></label>
              </div>
              <div className="col-lg-4 col-6 col-md-4 pb-2">

                <input
                  placeholder="SPH Magazine retrival Fee"
                  value={usersubscription.sphmPdfRetrievalFee}
                  onChange={(e) => this.setSubscriptionInfoDTO(e.target.value, "sphmPdfRetrievalFee")}
                  type="text"
                  className={usersubscription.allowMagazineSearch ? "p-1 pl-4 w-100" : "p-1 pl-4 w-100 disabled"}
                  disabled={usersubscription.allowMagazineSearch ? false : true}
                />

              </div>
              <div className="col-lg-4 col-md-4 pb-2 nl_article_accesss">
                <span className=''>
                  <Checkbox
                    checked={usersubscription.allowMagazineSearch}
                    onChange={(e) => this.setSubscriptionInfoDTO(e.target.checked, "allowMagazineSearch")}
                  />
                  <span className="margin-left-35">Allow Magazine Search</span>
                </span>
              </div>
            </div>
            <div className='row'>
              <label className=' nl_status ' >Pay By <span className="requiredStar">*</span></label>
              {paymentModeArr && paymentModeArr.map((p) => (
                <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                  <Checkbox key={p.key} labelValue={p.value}
                    checked={Number(p.value) === usersubscription.paymentInfoDTO["paymentMode"]}
                    onChange={(e) =>
                      this.setPaymentInfoDTO(Number(p.value), "paymentMode")}
                  />
                  <span className="margin-left-35">{p.key} </span>
                </div>))}

            </div>
            <div className='row'>
              <div className="col-lg-3  pb-2 cheque-mt">
                <label className=' ' >Cheque/Purchase Order/Bank Draft No/Others </label>
              </div>
              <div className="col-lg-4  pb-2">
                <input
                  placeholder=""
                  value={usersubscription.paymentInfoDTO["paymentRef"]}
                  onChange={(e) => this.setPaymentInfoDTO((e.target.value), "paymentRef")}
                  type="text"
                  className="search-input p-1 pl-4 w-100 "
                />
              </div>
            </div>
            <div className='row'>
              <div> <label className=' nl_status' >Status <span className="requiredStar">*</span></label></div>
              {subscriptionStatus && subscriptionStatus.map((p) => (
                <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                  <Checkbox key={p.key} labelValue={p.value}
                    checked={p.value === usersubscription.subStatus}
                    onChange={(e) =>
                      this.setSubscriptionInfoDTO((p.value), "subStatus")}
                  />
                  <span className="margin-left-35">{p.key} </span>
                </div>))}
            </div>
            <div>
              <div className="text-right pt-3 pb-5">
                <ButtonElement
                  type="submit"
                  variant="contained"
                  btnType="background-orange button-label"
                  className="d-inline-block mr-2 "
                  disabled={btnDisable}
                >
                  <span className="button-label"> {!newSubscription ? "Update" : "Create"}</span>
                </ButtonElement>
                <ButtonElement
                  type="submit"
                  variant="contained"
                  btnType="background-orange button-label"
                  className="d-inline-block mr-2 float-right"
                  onClick={(e) => this.goback(e)}
                >
                  <span className="button-label">Back</span>
                </ButtonElement>


              </div>
            </div>
          </form>
          <Recaptcha
            size="invisible"
            ref={(ref) => (this.recaptcha = ref)}
            onResolved={this.onResolved}
            sitekey={RECAPTCHA_SITE_KEY}
          />
        </div>
      </>
    )
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.adminReducer.isLoading,
  infoData: state.adminReducer.infoData,
  subscriptionStatus: state.adminReducer.subscriptionStatus,
  pkgInfo: state.adminReducer.pkgInfo,
  calculateFeeRes: state.adminReducer.calculateFeeRes,
  calculateFeeResError: state.adminReducer.calculateFeeResError,
  userSubscriptionRes: state.adminReducer.userSubscriptionRes,
  userSubscriptionResError: state.adminReducer.userSubscriptionResError,
  newUserSubscription: state.adminReducer.newUserSubscription,
  newUserSubscriptionErr: state.adminReducer.newUserSubscriptionErr

});

export default connect(mapDispatchStatetoProps, {
  startLoader,
  // getInfoData,
  getSubscriptionStatus,
  updateSubscription,
  calculateFeeByPkg,
  calculateFeeBySubscriptionFee,
  clearAdminError,
  createUserSubscription
})(UserSubscription);
