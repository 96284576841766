import React, { Component } from "react";
import { connect } from "react-redux";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import { getPublications,getBadgeData,setSearchObject,getandSethidelinkflag,getsetadandAdvdata } from "../../../redux/actions/applicationsAction";
import {
  getUserPreferences,
  updateUserPreferences,
  startLoader,updateLoader
} from "../../../redux/actions/userAction";
import { createOptions, getCookies, getPageviewobj } from "../../../helpers/common";
import { ToggleSwitch, Spinner, ButtonElement,SearchCoumnselect, Modal } from "../../UI/index";
import "./css/preference.scss";
import {getUserDetails} from '../../../redux/actions/loginAction';
import {pageViewDatagtm,customViewDatagtm} from '../../../redux/actions/applicationsAction';
import { decodeToken } from "react-jwt";
import Popup from "../../Admin/Popup/Popup";
import RecaptchaWidget from "../../UI/Recaptcha/recaptcha";
class Preference extends Component {
  constructor(props) {
    super(props);
    this.recaptcha = React.createRef(null);
    this.state = {
      userPreference: {},
      selectedData:{},
      publications:{},
      pubListData:{},
      showTabs: [],
      captchaToken: "",
      captchaError: false
    }
  }

  async componentDidMount() {
    this.props.startLoader();
    // await this.props.getUserDetails();
    const id_token = getCookies("id_token");
    const myDecodedToken = decodeToken(id_token);
    this.state.showTabs = myDecodedToken?.user_info;
    this.setState({showTabs:this.state.showTabs})
    if (this.props.publications.length <= 0) {
      this.props.getPublications();
    }
    if(myDecodedToken?.user_info?.hide_links){
      this.props.getandSethidelinkflag(true);
    }

    await this.props.getUserPreferences(myDecodedToken?.sub);
    if (this.props.userPreference) {
      const {userPreference} =this.state;
      userPreference.highlightText=this.props.userPreference.highlightText;
      userPreference.payAlert=this.props.userPreference.payAlert;
      userPreference.pubList=this.props.userPreference.pubList;
      userPreference.intLang=this.props.userPreference.intLang;
      userPreference.searchLang=this.props.userPreference.searchLang;
      userPreference.userId=this.props.userPreference.userId;
      this.setState({
        userPreference
      })
    }
    this.setPageViewcall(this.props.searchObject,myDecodedToken);
  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
      let url=window.location.href;
      let obj= getPageviewobj(url,"user account","Preference",user_status,"","","",id);
      this.props.pageViewDatagtm(obj,searchObject);
    }

  onPayAlertChange = (event) => {
    const { userPreference } = this.state;
    userPreference.payAlert = event.target.checked;
    this.setPreference(userPreference);
  };

  setPreference = (userPreference) => {
    this.setState({
      userPreference
    })
  }

  onHighlighttextChange = (event) => {
    const { userPreference } = this.state;
    userPreference.highlightText = event.target.checked;
    this.setPreference(userPreference);
  };

  onLanguageChange = (event) => {
    const { userPreference } = this.state;
    userPreference.intLang = event.target.value;
    this.setPreference(userPreference);
  };

  onChange = (value, type, key) => {
    const { userPreference } = this.state;
    const length = userPreference[key] ? userPreference[key].split(',').length : 0;
    if (type.action === "remove-value") {
      userPreference[key] = userPreference[key].replace(`${type.removedValue.value.pubCode}`, "");
    }
    if (value) {
      var selOptions = value.map(a => a.value.pubCode).join(",");
      const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
      }
      const pubList = userPreference[key] ?
        (length > value.length) ? `${userPreference[key]},${selOptions}` : selOptions : selOptions;
      userPreference[key] = pubList.split(",").filter(onlyUnique).join(",");
    }
    this.setPreference(userPreference);
  };

  getRecaptchaToken = (value) => {
    this.setState({
      captchaToken: value,
      captchaError: false
    });
  }

  updatePreference = async (e) => {
    const { userPreference, captchaToken } = this.state;
    // e.preventDefault();
    userPreference.captchaToken = captchaToken;
    await  this.props.updateUserPreferences(userPreference);
    this.setState({
      captchaToken: "",
    });

  }

  onSubmit = async (e) => {
    e.preventDefault();
    if(this.state.captchaToken != ""){
      this.props.startLoader();
      this.props.updateLoader();
      this.updatePreference();
    }else{
      this.setState({
        captchaError: true,
      });
    }
  };
  publicationChange=(vals,index,checkedArray,badges)=>{
    const {selectedData,pubListData} = this.state;
    selectedData[index]=JSON.parse(JSON.stringify(vals));
    this.sendtosearch(selectedData,index);
    pubListData[index]={checkedArray:checkedArray,badges:badges};
    this.setState({selectedData,pubListData})

  }
  sendtosearch=(data,index)=>{
    const { userPreference} = this.state;

    let ddd=data
    let datas=[];
    for(let x=0;x<=this.props.publications.length-1;x++){
      if(ddd[x] && ddd[x].length > 0){
    for(let y of ddd[x]){

      datas= datas.concat(y)
    }
  }
  }
  userPreference.pubList=datas;

  this.setPreference(userPreference)
  }
  onclose=async()=>{
    const {userPreference} = this.state;
    this.props.startLoader();
    await this.props.getUserPreferences(userPreference.userId);
    this.props.updateUserPreferences("",true);
    //
    // let newBadgedatapublicationdata;
    // let newSectiondatalist;
    // // console.log(this.props.badgeData)
    // // debugger
    // if (userPreference?.pubList && userPreference.pubList.length > 0) {
    //   let pub=this.props.badgeData?.badgeData?.publication;
    //   let sec=this.props.badgeData?.sectionDatalist;
    //   for (let y of userPreference.pubList) {
    //     //====//
    //     let newd=[];
    //     for (let x of pub) {
    //         if(x !== y){
    //           newd.push(x);
    //         }
    //     }
    //     pub=newd;
    //     //====//
    //     let newsecd=[];
    //     for (let xz of sec) {
    //       if (xz.val !== y) {
    //         newsecd.push(xz)
    //       }
    //     }
    //     sec=newsecd;
    //   }
    //   newBadgedatapublicationdata=pub;
    //   newSectiondatalist=sec;

    //   let modifiedbadgedata=this.props.badgeData.badgeData;
    //   modifiedbadgedata.publication=newBadgedatapublicationdata;
    //   // console.log(modifiedbadgedata,newSectiondatalist);
    //   // debugger
    //  await this.props.getBadgeData({sectionDatalist:newSectiondatalist,badgeData:modifiedbadgedata});
    // }

    await this.props.getBadgeData({sectionDatalist:[],badgeData:{}});
    await this.props.getsetadandAdvdata([]);
    let searchobj=this.props.searchObject;
    delete searchobj.company;
    delete searchobj.section;
    if(searchobj?.publication){
      searchobj.publication=[];
    }
    await this.props.setSearchObject(searchobj)

  }
  render() {
    // const Languages = [{ key: "English", value: "en" }];
    const { publications, loading,updateuserPreference,hide_link_flag } = this.props;
    const { userPreference,pubListData,showTabs, captchaToken, captchaError} = this.state;
    let contentarray=[];
    // if(hide_link_flag){
       if(showTabs?.search_type?.includes("ad_search") && !showTabs?.search_type?.includes("general_search")){
        if(showTabs.content_allowed){
          contentarray=showTabs.content_allowed.split(",");
          contentarray=contentarray.map(elem=>{
           return elem.toLowerCase();
          })
         }
       }else{
          if(hide_link_flag){
            if(showTabs.content_allowed){
              contentarray=showTabs.content_allowed.split(",");
              contentarray=contentarray.map(elem=>{
               return elem.toLowerCase();
              })
             }
          }
       }

    // }
    return (
      <div className="user-preference">
         {updateuserPreference?.message && <Modal customClass={'forgot-password-modal'} onClick={() => this.onclose()}>
          <Popup titles={updateuserPreference.message} onClose={this.onclose} {...this.props} />
        </Modal>}
        <div className="user-preference-container ">
          <h4 className="heading">Preference</h4>
          <form
            className="container-fluid"
            noValidate
            onSubmit={(e) => this.onSubmit(e)}
          >
            <div className=" mb-5 pref-container">

              {loading ? (
                <div className="text-center">
                  <Spinner primary={true} />
                </div>
              ) : (
                <>
                  {/* <div className="col-md-3 align-top d-inline-block p-0">
                  <label>Interface language</label>
                  <SelectElement
                    defaultValue={userPreference.intLang}
                    options={Languages}
                    onChange={this.onLanguageChange}
                  />
                </div> */}
                <div className="bg-white pt-3 pb-3 pl-0 pr-0">
                  <div className="col-md-3 d-inline-block  ">
                    <label className="m-0">Payment Alert</label>
                    {this.props.userPreference && <ToggleSwitch
                      on={userPreference?.payAlert}
                      onChange={this.onPayAlertChange}
                      description="payment-alert"
                      key="payalrt"
                    />}
                  </div>
                  <div className="col-md-3 d-inline-block ">
                    <label className="m-0">Highlight Text</label>
                    {this.props.userPreference && <ToggleSwitch
                      on={userPreference?.highlightText}
                      onChange={this.onHighlighttextChange}
                      description="highlight-text"
                      key="higlt"
                    />}
                  </div>
                  </div>
                </>
              )}
            </div>
            <div className="bg-white pt-3 pb-3">
            <div className="col-md-12 col-12 pref-container">

              <h4 className="heading pb-2">Publication</h4>
              {loading || !userPreference ? (
                <div className="text-center">
                  <Spinner primary={true} />
                </div>
              ) : (
                <>
                  {publications.length
                    ? publications.map((publication,index) => {
                      const options = createOptions(
                        publication.extraData,
                        "publication",contentarray
                      );
                      if(options && options[0]?.value){
                      return (
                        <div
                          className="col-md-6 col-12 pl-0 mb-2"
                          key={publication.key}
                        >
                          <div className="dropdown w-100">
                            {/* <SearchSelect
                              value={getPublicationValues(userPreference.pubList, options)}
                              isClearable={false}
                              options={options}
                              onChange={(e, type) =>
                                this.onChange(e, type, "pubList")
                              }
                              placeholder={publication.key}
                            /> */}
                            <SearchCoumnselect id={index} key={index} placeholder={publication.key} options={options}
                                                    onChange={(vals, index, checkedArray, badges) => this.publicationChange(vals, index, checkedArray, badges)}
                                                    pubListData={pubListData[index]}  defaultvalues={this.props?.userPreference?.pubList} ispreference={true}
                                                    adsearch={showTabs?.search_type?.includes("ad_search") && !showTabs?.search_type?.includes("general_search") ? true:false}
                                                />
                          </div>
                        </div>
                      );
                          }
                    })
                    : null}
                </>
              )}
            </div>
            <div className="px-4 mb-2">
              <RecaptchaWidget getRecaptchaToken={(value)=>this.getRecaptchaToken(value)} token={captchaToken} error={captchaError}/>
            </div>
            <div className="px-4">
              <ButtonElement
                type="submit"
                variant="contained"
                btnType="background-orange"
                className="  col-3"
                // onClick={this.onResolved}
              >
                <span className="button-label">Update</span>
              </ButtonElement>
            </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  publications: state.applicationReducer.publications,
  contentTypes: state.applicationReducer.contentTypes,
  userPreference: state.userReducer.userPreference,
  updateuserPreference: state.userReducer.updateuserPreference,
  userPreference_error: state.userReducer.user_error,
  loading: state.userReducer.isLoading,
  userTokens: state.loginReducer.tokens,
  searchObject: state.applicationReducer.searchObject,
  hide_link_flag:state.applicationReducer.hide_link_flag,
  badgeData: state.applicationReducer.badgeData,
});

export default connect(mapDispatchStatetoProps, {
  getPublications,
  getUserPreferences,
  updateUserPreferences,
  startLoader,
  getUserDetails,
  getBadgeData,
  setSearchObject,
  getandSethidelinkflag,
  updateLoader,
  getsetadandAdvdata,
  pageViewDatagtm,
  customViewDatagtm
})(Preference);
