import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonElement, Checkbox, Selectlist, Modal, Spinner } from '../../UI/index';
import { getPackageConfiguration, createPackage, getPacakgeByCode, clearAdminError, startLoader, getUserType } from '../../../redux/actions/adminAction';
import './ConfigPackages.scss';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import ScrollToTop from '../../UI/ScrollToTop/ScrollToTop';

// Initialize state
const initialState = {
    submit: false,
    userPackage: '',
    isSuccess: '',
    sysConfig: {
        agencyRenewNotice: '',
        mustSubscribe: true,
    },
    configObj: {
        userPackage: ''
    },
    userType: [{
        fee: '',
        userType: '',
        userTypeTb: ''
    }],
    packageData: {
        userPackage: '',
        allowedContentType: '',
        description: '',
        pkgName: '',
        fee: '',
        period: '',
        periodUnit: '',
        priceArticle: '',
        pricePdf: '',
        renew: 'Y',
        searchService: 'general',
        searchTypeList: 'general_search',
        status: '1'
    },
    statusList: [],
    requestObject: {},
    pacakgeConfiguration: {},
    userTypeList: [],
    userPackageList: [],
    formDataObject: [],
    mustSubscribe: true,
    digitalTypes: [],
    searchType: [],
    searchName: [],
    message: '',
    periodArray: [{ key: 1, value: 1 }, { key: 2, value: 2 }, { key: 3, value: 3 }, { key: 4, value: 4 }, { key: 5, value: 5 }, { key: 6, value: 6 },
    { key: 7, value: 7 }, { key: 8, value: 8 }, { key: 9, value: 9 }, { key: 10, value: 10 }, { key: 11, value: 11 }, { key: 12, value: 12 }],
    periodUnitArray: [{ key: 'YEAR', value: 'year' }, { key: 'MONTH', value: 'month' }],
    renewArray: [{ key: "Yes", value: "Y" }, { key: "No", value: "N" }],
    isVisible: false,
    isDisable: false
}

class CreatePackage extends Component {
    state = initialState;
    componentDidMount = async () => {
        this.onReset();
        this.props.startLoader();
        await this.props.getUserType();
        if (this.props?.userTypes?.userTypes) {
            let userTypeKeys = Object.keys(this.props.userTypes.userTypes);
            let newUserTypesArr = userTypeKeys.map((userTypeName) => {
                return ({ value: userTypeName, key: this.props.userTypes.userTypes[userTypeName].userTypeName })
            })
            let temp = this.props?.userTypes?.packages;
            let packagesValues = Object.values(temp.basePackageVO);
            let packagesKeys = Object.keys(temp.basePackageVO);
            let newPackagesArr = packagesValues.map((p, i) => {
                return ({ key: p.description, value: packagesKeys[i] })
            });
            this.setState({
                userTypeList: newUserTypesArr,
                userPackageList: newPackagesArr
            })
        }
        // Read and set the digital type
        if (this.props?.userTypes?.pkgContType?.contentTypeResultVoMap) {
            let digitalTypesKeys = Object.keys(this.props?.userTypes?.pkgContType?.contentTypeResultVoMap);
            let dataObject = [];
            let { digitalTypes } = this.setState;
            for (let d of digitalTypesKeys) {
                let data = d.split('|');
                data.map(dt => {
                    if (isNaN(dt)) {
                        if (dt)
                            dataObject.push({ key: dt, value: dt, ischecked: false })
                    }
                    return dt;
                });
            }
            digitalTypes = dataObject
            this.setState({
                digitalTypes
            });
        }

        // Read and set the search type
        if (this.props?.userTypes?.searchType && this.props?.userTypes?.searchType.length > 0) {
            let searchTypeData = this.props?.userTypes?.searchType;
            let dataObj = []
            searchTypeData.map(function (s) {
                let obj = {
                    key: s.key,
                    value: s.value,
                }
                if (s.value === 'general_search')
                    obj.ischecked = true;
                else
                    obj.checked = false;
                dataObj.push(obj)
                return s;
            });
            this.setState({
                searchType: dataObj
            })
        }
        if (this.props?.userTypes?.searchName) {
            this.setState({
                searchName: this.props?.userTypes?.searchName
            })
        }
        // if (this.props?.userTypes?.periodUnit) {
        //     this.setState({
        //         periodUnitArray: this.props?.userTypes?.periodUnit
        //     })
        // }
        if (this.props?.userTypes?.pkgStatus) {
            this.setState({
                statusList: this.props?.userTypes?.pkgStatus
            })
        }
    }

    // Clear the inupt fields
    onReset = (e) => {
        let packageData = {
            userPackage: '',
            allowedContentType: '',
            description: '',
            pkgName: '',
            fee: '',
            period: '',
            periodUnit: '',
            priceArticle: '',
            pricePdf: '',
            renew: 'Y',
            searchService: 'general',
            searchTypeList: 'general_search',
            status: '1'

        }
        this.setState({ packageData });
        // Reset the degital type
        let resetDigitalType = this.state.digitalTypes.map(digitalType => {
            digitalType.ischecked = false;
            return digitalType;
        })

        // Reset the search type
        let resetSearchType = this.state.searchType.map(s => {
            if (s.value === 'general_search') {
                s.searchTypeList.push('general_search')
                s.ischecked = true;
            } else
                s.ischecked = false;
            return s;
        })
        this.setState({ searchType: resetSearchType, digitalTypes: resetDigitalType, packageData });
    }

    onSave = async (e) => {
        this.setState({
            isDisable: true
        })
        e.preventDefault()
        this.recaptcha.execute();
    }
    onResolved = async () => {
        const response = this.recaptcha.getResponse();
        await this.props.startLoader();
        let { packageData, digitalTypes, searchType } = this.state;
        let allowedContentTypeArray = digitalTypes.filter(d => {
            if (d.ischecked) {
                return d.value;
            }
        })
        let allowedContentType = allowedContentTypeArray.map(d => d.value);
        // Create package request body
        let requestObject = {
            "captchaToken": response,
            "allowedContentType": allowedContentType,
            "fee": packageData.fee,
            "pkgName": packageData.pkgName ? packageData.pkgName.trim() : packageData.pkgName,
            "period": packageData.period,
            "periodUnit": packageData.periodUnit,
            "pkgDescp": packageData.description ? packageData.description.trim() : packageData.description,
            "priceArticle": packageData.priceArticle,
            "pricePdf": packageData.pricePdf,
            "renew": packageData.renew,
            "searchService": packageData.searchService,
            "searchTypeList": searchType.searchTypeList,
            "status": packageData.status
        }

        await this.props.createPackage(requestObject);
        let msg = this.props?.createPackageResponse?.message || this.props?.createPackageError;

        let status = (this.props?.createPackageError && this.props?.createPackageError?.length > 0) ? "Error" : "Success"
        if (msg)
            this.setState({
                visibleAlertBox: true,
                message: msg,
                isSuccess: status
            });
        if (this.props?.createPackageResponse?.message) {
            // Clear input fields
            let packageData = {
                userPackage: '',
                allowedContentType: '',
                description: '',
                pkgName: '',
                fee: '',
                period: '',
                periodUnit: '',
                priceArticle: '',
                pricePdf: '',
                renew: 'Y',
                searchService: 'general',
                searchTypeList: 'general_search',
                status: '1'
            }
            this.setState({ packageData });
            await this.props.clearAdminError();

            let resetDigitalType = this.state.digitalTypes.map(digitalType => {
                digitalType.ischecked = false;
                return digitalType;
            })
            let resetSearchType = this.state.searchType.map(s => {
                if (s.value === 'general_search') {
                    s.searchTypeList.push('general_search')
                    s.ischecked = true;
                }
                else
                    s.ischecked = false;
                return s;
            })
            this.setState({ searchType: resetSearchType, digitalTypes: resetDigitalType, packageData });
        }
        await this.props.clearAdminError();
        window.scrollTo(0, 0);
    }
    setFormValue = async (value, field, number = false) => {
        let {  packageData } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            packageData[field] = regex.test(value) || value === "" ? value : packageData[field];
        } else {
            if (field == "priceArticle" || field == "pricePdf" || field == "fee" || field == "period") {
                if (!isNaN(value)) {
                    packageData[field] = value;
                }
            }
            else
                packageData[field] = value;
        }
        this.setState({
            packageData,
            isVisible: true
        });
    }

    onCategory = async (e) => {
        this.props.history.push('/admin/subscriptionCategory');
    }
    closeModal = async (e) => {
        this.setState({
            isCloseAgencyPublisher: false,
            visibleAlertBox: false,
            isDisable: false
        })
    }
    setFormSelectListValue = (data, field) => {
        let { digitalTypes } = this.state;
        let digitalTypesArr = [];
        for (let d in data) {
            if (data[d])
                digitalTypesArr.push(d);
        }
        digitalTypes[field] = digitalTypesArr;
        this.setState({
            digitalTypes
        })
    }
    setFormSelectListSearchTypeValue = (data, field) => {
        let { searchType } = this.state;
        let searchTypeArr = [];
        for (let d in data) {
            if (data[d])
                searchTypeArr.push(d);
        }
        searchType[field] = searchTypeArr;
        this.setState({
            searchType
        })
    }
    render() {
        let { digitalTypes, isSuccess, isDisable, statusList, searchType, searchName, renewArray, periodUnitArray, message, packageData, visibleAlertBox } = this.state;
        let { loading } = this.props;
        return (
            <>
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    isSuccess == "Success" ? <span className='suc'>{isSuccess}</span> : <span className='err'>{isSuccess}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>

                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}

                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }

                <div className='card'>
                    <div className='top10'></div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className="heading">
                                New Package
                            </h3>
                        </div>
                    </div>
                    <hr />
                    <div className=''>
                        <div className='card'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    {
                                        digitalTypes && digitalTypes.length > 0 &&
                                        <Selectlist list={digitalTypes} label='Allowed content type:'
                                            ischecked={true}
                                            onChange={(e) =>
                                                this.setFormSelectListValue(e.selectedSub, "allowedContentType")}></Selectlist>
                                    }
                                </div>
                            </div>
                            <div className='margin-top-15'></div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    {/* <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Search Type List </label> */}
                                    {
                                        searchType && searchType.length > 0 &&
                                        <Selectlist list={searchType} label='Search Type List:' ischecked={true}
                                            onChange={(e) =>
                                                this.setFormSelectListSearchTypeValue(e.selectedSub, "searchTypeList")}></Selectlist>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Package Name:<span className='err'>*</span> </label>
                                    <input
                                        placeholder="Package Name"
                                        value={packageData.pkgName}
                                        onChange={(e) => this.setFormValue(e.target.value, "pkgName")}
                                        type="text"
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />
                                </div>
                            </div>
                            <div className='row mtop-10'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Description:<span className='err'>*</span> </label>
                                    <input
                                        placeholder="Description"
                                        value={packageData.description}
                                        onChange={(e) => this.setFormValue(e.target.value, "description")}
                                        type="text"
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />
                                </div>
                            </div>
                            <div className='row mtop-10'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Fee:<span className='err'>*</span> </label>
                                    <input
                                        placeholder="Fee"
                                        value={packageData.fee}
                                        onChange={(e) => this.setFormValue(e.target.value, "fee")}
                                        onPaste={(e)=>{e.preventDefault(); return false}}
                                        type="text"
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />
                                </div>
                            </div>
                            <div className='row mtop-10'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Period:<span className='err'>*</span>  </label>
                                    <input
                                        placeholder="Period"
                                        value={packageData.period}
                                        onChange={(e) => this.setFormValue(e.target.value, "period")}
                                        onPaste={(e)=>{e.preventDefault(); return false}}
                                        type="text"
                                        maxLength="2"
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />
                                </div>
                            </div>

                            <div className='row mtop-10'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Price Article: </label>
                                    <input
                                        placeholder="Price Article"
                                        value={packageData.priceArticle}
                                        onChange={(e) => this.setFormValue(e.target.value, "priceArticle")}
                                        onPaste={(e)=>{e.preventDefault(); return false}}
                                        type="text"
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />
                                </div>
                            </div>
                            <div className='row mtop-10'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Price Pdf: </label>
                                    <input
                                        placeholder="Price Pdf"
                                        value={packageData.pricePdf}
                                        onChange={(e) => this.setFormValue(e.target.value, "pricePdf")}
                                        onPaste={(e)=>{e.preventDefault(); return false}}
                                        type="text"
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3' >Period Unit:<span className='err'>*</span> </label>
                                    {periodUnitArray && periodUnitArray.length > 0 && periodUnitArray.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value}
                                                checked={p.value == (packageData?.periodUnit)}
                                                onChange={(e) => this.setFormValue(e.target.getAttribute("label"), "periodUnit")} />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>))
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3' >Renew: </label>
                                    {renewArray && renewArray.length > 0 && renewArray.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value}
                                                checked={p.value == (packageData?.renew ? packageData?.renew : 'Y')}
                                                onChange={(e) => this.setFormValue(e.target.getAttribute("label"), "renew")} />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>))
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3' >Search Service: </label>
                                    {searchName && searchName.length > 0 && searchName.map((p) => (
                                        <div className="d-inline-block pr-3 pl-3 pb-1 ">
                                            <Checkbox key={p.key} labelValue={p.value}
                                                checked={p.value == (packageData?.searchService ? packageData?.searchService : 'general')}
                                                onChange={(e) => this.setFormValue(e.target.getAttribute("label"), "searchService")} />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>))
                                    }
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-lg-12">
                                    <label className="float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3">Status: </label>
                                    {statusList && statusList.length > 0 && statusList.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value}
                                                checked={p.value == (packageData?.status ? parseInt(packageData.status) : 1)}
                                                onChange={(e) => this.setFormValue(e.target.getAttribute("label"), "status")} />
                                            <span className="margin-left-35">{p.key} </span>

                                        </div>))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block float-right mr-2"
                                    onClick={(e) => this.onReset(e)}
                                >
                                    <span className="button-label">Reset</span>
                                </ButtonElement>
                                {" "}
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block float-right mr-2"
                                    onClick={(e) => this.onSave(e)}
                                    disabled={isDisable}

                                >
                                    <span className="button-label">Save</span>
                                </ButtonElement>
                            </div>

                        </div>
                        <div className='top10'></div>
                    </div>
                    <div className='top10'></div>
                    <div>
                        <Recaptcha
                            size="invisible"
                            ref={(ref) => (this.recaptcha = ref)}
                            onResolved={this.onResolved}
                            sitekey={RECAPTCHA_SITE_KEY}
                        />
                    </div>

                </div>
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    userTypes: state.adminReducer.userTypes,
    userTypesError: state.adminReducer.userTypesError,
    getPackageByCodeResponse: state.adminReducer.getPackageByCodeResponse,
    getPackageByCodeError: state.adminReducer.getPackageByCodeError,
    createPackageResponse: state.adminReducer.createPackageResponse,
    createPackageError: state.adminReducer.createPackageError,
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getPackageConfiguration,
    getPacakgeByCode,
    getUserType,
    clearAdminError,
    createPackage
})(CreatePackage);
