import React from "react";
import { Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./navigation/Routes";
import { Routesadmin } from "./navigation/Routesadmin";
import store from "./redux/store";
import "./index.scss"

const App = () => {
  //  console.log(process.env.REACT_APP_ISADMIN);
   const isprocessAdmin=JSON.parse(process.env.REACT_APP_ISADMIN);
   if(isprocessAdmin){

    return (
      <Provider store={store}>
        <Router>
          <Switch>

        <Route component={Routesadmin} />
          </Switch>
        </Router>
      </Provider>
    );
   }else{

    return (
      <Provider store={store}>
        <Router>
          <Switch>
           <Route component={Routes} />

          </Switch>
        </Router>
      </Provider>
    );
   }

};

export default App;
