import React, { Component } from "react";
import { connect } from "react-redux";
// import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { createOptions, getContentData, getCustomviewobj, getDate, getPageviewobj, reverseDate } from "../../helpers/common";
import {
    ButtonElement,
    DatePickerElement, SearchCoumnselect, Spinner
} from "../UI/index";
import {
    getPublications,setsearchtype,customViewDatagtm,pageViewDatagtm,
    setContentType,getBadgeData,getsetadandAdvdata,setDurations,
    searchContent, getContentTypes, setDateObject,setSearchObject
} from "../../redux/actions/applicationsAction";
import {getUserDetails } from "../../redux/actions/loginAction";
import "./css/search.scss";
import { decodeToken } from "react-jwt";
class adSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: getDate(true, 2),
            endDate: new Date(),
            maxDate: new Date(),
            minDate: getDate(true,2),
            searchObject: {
                dateRange: {
                    fromDate: "",
                    toDate: "",
                },
                keywords: "",
                sortBy: "publicationdate",
                sortOrder: "desc",
                publication: [],
                pdfPageNo:"",
                pageSize: 10,
                sourceType: "ad_metadata",
                digitalType: "",

            },
            pubListData: {},
            inputShow: "",
            selectedData: {},
            publications: {},
            startLoading: false,
            userpubList:[],
        }
    }
    async componentDidMount() {
        if(!JSON.parse(process.env.REACT_APP_ISADMIN)){
            this.props.setsearchtype(false);
            await this.props.getUserDetails();
            const id_token = this.props?.userTokens?.id_token;
            const myDecodedToken = decodeToken(id_token);
            if(myDecodedToken){
                this.state.userpubList=myDecodedToken?.user_info;
                this.setState({userpubList:this.state.userpubList})
              }
            if(id_token && myDecodedToken.user_info?.search_type?.includes("ad_search")){
                const {pubListData, selectedData } = this.props.location;

        if(!this.props?.dateObject?.startDate || !this.props?.dateObject?.endDate){
            this.props.setDateObject({startDate:getDate(true, 2),endDate:new Date()})
          }
        if (this.props.searchObject) {
            for (let x in this.props.searchObject) {
                this.state.searchObject[x] = this.props.searchObject[x];
            }
            if(this.state.searchObject && this.state.searchObject.publication && this.state.searchObject.publication.length > 0){
                let publication=[...new Set(this.state.searchObject.publication)];
                this.state.searchObject.publication=publication;
            }else{
                this.state.searchObject.publication=myDecodedToken?.user_info?.pub_list;
            }
                this.state.section=[];
                this.state.advertiser=[];
                // delete this.state.searchObject?.pageNo;
                delete this.state.searchObject?.pdfPageNo;
                delete this.state.searchObject?.section;
                delete this.state.searchObject?.byline;
                delete this.state.searchObject?.caption;
                delete this.state.searchObject?.advertiser;
            this.setState({
                searchObject: this.state.searchObject,
                startDate: new Date(this.props.searchObject.dateRange.fromDate),
                endDate: new Date(this.props.searchObject.dateRange.toDate),
                // startDate: getStringtoDate(searchObject.dateRange.startDate),
                // endDate: getStringtoDate(searchObject.dateRange.endDate),

            });
            await this.props.setSearchObject(this.state.searchObject);
        } else {
            const { searchObject } = this.state;
            searchObject.publication=myDecodedToken?.user_info?.pub_list;
            let fromd = this.state.startDate.toLocaleDateString('en-GB');
            let tod = this.state.endDate.toLocaleDateString('en-GB');
            searchObject.dateRange["fromDate"] = reverseDate(fromd.replaceAll("/", "-"));
            searchObject.dateRange["toDate"] = reverseDate(tod.replaceAll("/", "-"));
            // delete searchObject.pageNo;
            delete searchObject.pdfPageNo;
            delete searchObject?.section;
            delete searchObject?.byline;
            delete searchObject?.caption;
            delete searchObject?.advertiser;
            this.setState({
                searchObject
            })
            await this.props.setSearchObject(searchObject);
        }
        if (pubListData) {
            for (let x in pubListData) {
                this.state.pubListData[x] = pubListData[x];
            }
            this.setState({ pubListData })
        }
        if (selectedData) {
            for (let x in selectedData) {
                this.state.selectedData[x] = selectedData[x];
            }
            this.setState({ selectedData })
        }
        await this.props.getPublications();
        this.setState({ publications: this.props.publications, startLoading: true });

            }else{
                this.props.history.push("/")
            }
            //gtm call
            this.setPageViewcall(this.props.searchObject,myDecodedToken);
        }
    }
    setPageViewcall=(searchObject,myDecodedToken)=>{
        let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
      let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
        let url=window.location.href;
        let obj= getPageviewobj(url,"ad search","",user_status,"","","",id);
        this.props.pageViewDatagtm(obj,searchObject);
      }
    publicationChange = (vals, index, checkedArray, badges) => {
        const { selectedData, pubListData } = this.state;
        selectedData[index] = JSON.parse(JSON.stringify(vals));
        this.sendtosearch(selectedData, index);
        pubListData[index] = { checkedArray: checkedArray, badges: badges };
        this.setState({ selectedData, pubListData })

    }
    sendtosearch = (data, index) => {
        const { searchObject } = this.state;

        let ddd = data
        let datas = [];
        for (let x = 0; x <= this.props.publications.length - 1; x++) {
            if (ddd[x] && ddd[x].length > 0) {
                for (let y of ddd[x]) {

                    datas = datas.concat(y)
                }
            }
        }
        searchObject.publication = datas;
        this.setSearchObject(searchObject)
    }
    setSearchObject = (searchObject) => {
        this.setState({
            searchObject
        })
    }

    onStartDateChange = (date) => {
        this.props.setDateObject({ startDate: date, endDate: this.props?.dateObject?.endDate })
        const { searchObject } = this.state;
        const { dateObject } = this.props;
        let dt = new Date(date);
        // let modifieddate= `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
        let modifieddate = dt.toLocaleDateString('en-GB');
        searchObject.dateRange["fromDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
        let modifieddate2 = dateObject?.endDate.toLocaleDateString('en-GB');
        searchObject.dateRange["toDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
        this.setState({
            startDate: date
        });

        this.setSearchObject(searchObject);

    };

    onEndDateChange = (date) => {
        this.props.setDateObject({ startDate: this.props?.dateObject?.startDate, endDate: date })
        const { searchObject } = this.state;
        const { dateObject } = this.props;
        let dt = new Date(date);
        let modifieddate = dt.toLocaleDateString('en-GB');
        searchObject.dateRange["toDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
        let modifieddate2 = dateObject?.startDate.toLocaleDateString('en-GB');
        searchObject.dateRange["fromDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
        this.setState({
            endDate: date
        });
        this.setSearchObject(searchObject);
    };
    onChange = (value, key) => {
        const { searchObject } = this.state;
        if (key === "section" || key === "advertiser") {

            searchObject[key] = [value];
        }else if(key=== "pdfPageNo"){
            let char=value.trim();
            let charval=char.charAt(0);
            if(charval==='-'){
              value=value.slice(1);
            }
            searchObject[key] = value.trim();
          }  else {

            searchObject[key] = value;
        }
        this.setState({
            searchObject
        });
    }

    onSearch = async () => {
        const { searchObject } = this.state;
        const { dateObject } = this.props;
        const { contentTypes } = this.props;
        const contentData = getContentData(contentTypes, this.props.digitalType);
        // await this.props.getSubDigitalType(contentData?.subCategories[0], 0);
        // const { extraData } = this.props.applicationReducer;
        // searchObject.digitalType = extraData.digitalType;
        // searchObject.sourceType = extraData.sourceType;
        // searchObject.contentType = this.props.contentType;
        let resfound = [];

        for (let x in searchObject) {
            if (x === "byline" || x === "section" || x === "caption") {
                if (x === "section") {
                    for (let y of x) {
                        if (searchObject[y])
                            resfound.push(searchObject[y]);
                    }
                } else {
                    if (searchObject[x])
                        resfound.push(searchObject[x])
                }
            }
        }
        searchObject.sourceType = "ad_metadata";
        searchObject.digitalType = "";
        searchObject.pageNo=1;
        let modifieddate = dateObject.startDate.toLocaleDateString('en-GB');
        searchObject.dateRange["fromDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
        let modifieddate2 = dateObject?.endDate.toLocaleDateString('en-GB');
        searchObject.dateRange["toDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
        // searchObject.keywords="";
        if(searchObject.pdfPageNo){
            searchObject.pdfPageNo= searchObject?.pdfPageNo && searchObject?.pdfPageNo?.trim();
        }
        if(searchObject?.advertiser?.length > 0)
        {searchObject.advertiser[0]= searchObject?.advertiser[0].trim();
            if(!searchObject.advertiser[0]){
                delete searchObject.advertiser;
            }
        }
        if(searchObject?.section?.length > 0){
             searchObject.section[0] = searchObject?.section[0].trim();
             if(!searchObject.section[0]){
                delete searchObject.section;
            }
        }
        searchObject.company=[];
        if(searchObject.keywords){
            searchObject.keywords.trim()
          }
          searchObject.sortBy= "publicationdate";
          searchObject.sortOrder="desc";
          this.props.setDurations("0");
        await this.props.setSearchObject(searchObject);
        await this.props.getBadgeData({ sectionDatalist: [], badgeData: {} });
        if(this.state.selectedData){
            let alldata=[]
            for(let y in this.state.selectedData){
              for(let x of this.state.selectedData[y]){
                  if(x && !this.state?.userpubList?.pub_list.includes(x)){
                    alldata.push(x);
                  }
              }
            }

            // this.setState({adandAdvbadges:alldata});
            this.props.getsetadandAdvdata(alldata);
          }
           //=====gtm call=====//

        this.customViewcall(searchObject, this.state.userpubList);
           //end gtm call
        this.props.history.push({
            pathname: '/search',
            searchObject: searchObject,
            resultfoundFor: resfound,
            pubListData: this.state.pubListData,
            selectedData: this.state.selectedData,
            showTabs: this.props.location.showTabs,
            adSearchFlag:true,
            notvabarcall:true
        })
        window.scrollTo(0,0);

    }
    customViewcall=(searchObject,token)=>{
        let user_status=token?.user_type ? token?.user_type:"" ;
        let id=token?.visitor_id ? token?.visitor_id : ""
      //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate);
        let objj= getCustomviewobj("ad search","click","","ad search","",user_status,"","","",id);
        this.props.customViewDatagtm(objj,searchObject)
      }

    render() {
        const { startDate, publications, startLoading, maxDate,userpubList } = this.state;
        const {dateObject} = this.props;
        let contentarray=[];

          if(userpubList.content_allowed){
           contentarray=userpubList.content_allowed.split(",");
           contentarray=contentarray.map(elem=>{
            return elem.toLowerCase();
           })
          }

        return (
            <>
                {!startLoading ? (
                    <div className="text-center pt-5 pb-5">
                        <Spinner primary={true} />
                    </div>
                ) : (
                          <form  className="" onSubmit={()=>this.onSearch()}>
                    <div className="col-12 col-md-12 pt-3 dashboard p-0 advanced-search-container">
                        <div className="col-12 col-md-11 offset-lg-1 p-3">

                            <div>
                                <h6>SELECT PUBLICATIONS</h6>
                                <div className=" row ">
                                    {publications.length
                                        ? publications.map((publication, index) => {
                                            const options = createOptions(
                                                publication.extraData,
                                                "publication",
                                                contentarray
                                            );
                                            if(options && options[0]?.value && !options[0].value.publications){
                                                // console.log(options[0].value.publications)
                                            return (
                                                <div
                                                    className="col-md-4  col-12 pl-0 mb-2"
                                                    key={publication.key}
                                                >
                                                    <div key={index} className="dropdown w-100">
                                                        <SearchCoumnselect id={index} key={index} placeholder={publication?.key} options={options} value=""
                                                            onChange={(vals, index, checkedArray, badges) => this.publicationChange(vals, index, checkedArray, badges)}
                                                            userPub_list={userpubList?.pub_list} defaultvalues={this.props.searchObject?.publication} adsearch={true}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                            }
                                        })
                                        : null}
                                </div>
                            </div>


                            <div>
                                <h6 className="pt-3">PUBLICATION DATE </h6>
                                <span className="toLabel d-block">FROM :</span>
                                <DatePickerElement
                                    // minDate={minDate}
                                    onChange={this.onStartDateChange}
                                    className="col-md-4 p-0"
                                    maxDate={maxDate}
                                    value={dateObject?.startDate}
                                />
                                <span className="toLabel d-block">To &nbsp; &nbsp;  :</span>
                                <DatePickerElement
                                    minDate={startDate}
                                    maxDate={maxDate}
                                    className="col-md-4 p-0"
                                    onChange={this.onEndDateChange}
                                    value={dateObject?.endDate}
                                />
                            </div>
                            <div className="pt-3">
                                <div className="search-container d-inline-bloc col-md-4 col-12 p-0 pb-3 ">
                                    <input
                                        name="keywords"
                                        type="text"
                                        placeholder="Search, 搜索, Carian, தேடல்"
                                        value={this.state.searchObject.keywords || ""}
                                        autoComplete="off"
                                        maxLength="240"
                                        className="search-input p-1 pl-4 w-100"
                                        onChange={(e) => this.onChange(e.target.value, "keywords")}
                                        />
                                        <FontAwesomeIcon icon={faSearch} className="search-icon-adsearch" />
                                        {this.state.searchObject.keywords && <FontAwesomeIcon icon={faTimes} className="cross-iconad" onClick={() => this.onChange("", "keywords")} />}
                                        {/* {!this.state.searchObject.keywords && <FontAwesomeIcon icon={faTimes} className="cross-icon" />} */}
                                    </div>
                                </div>

                            <div>
                                <div className="search-container d-inline-block col-md-4 col-12 p-0 pb-3">
                                    <input
                                        name="section"
                                        type="text"
                                        placeholder="Section"
                                        value={this.state.searchObject.section || ""}
                                        autoComplete="off"
                                        maxLength="240"
                                        className="search-input p-1 pl-4 w-100"

                                        onChange={(e) => this.onChange(e.target.value, "section")}
                                    />
                                    <FontAwesomeIcon icon={faSearch} className="search-icon-adsearch" />
                                </div>
                            </div>
                            <div>
                                <div className="search-container d-inline-block col-md-4 col-12 p-0 pb-3">
                                    <input
                                        name="advertiser"
                                        type="text"
                                        placeholder="Advertiser"
                                        value={this.state.searchObject.advertiser}
                                        autoComplete="off"
                                        maxLength="240"
                                        className="search-input p-1 pl-4 w-100"

                                        onChange={(e) => this.onChange(e.target.value, "advertiser")}
                                    />
                                    <FontAwesomeIcon icon={faSearch} className="search-icon-adsearch" />
                                </div>
                            </div>
                            <div>
                                <div className="search-container d-inline-block col-md-4 col-12 p-0 pb-3">
                                    <input
                                        name="pdfPageNo"
                                        type="text"
                                        placeholder="Page no."
                                        value={this.state.searchObject.pdfPageNo || ""}
                                        autoComplete="off"
                                        maxLength="240"
                                        className="search-input p-1 pl-4 w-100"

                                        onChange={(e) => this.onChange(e.target.value, "pdfPageNo")}
                                    />
                                    <FontAwesomeIcon icon={faSearch} className="search-icon-adsearch" />
                                </div>
                            </div>
                            <div className="search-button-container col-md-4 col-12 p-0 pt-4 d-inline-block">
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange"
                                    className="d-inline-block mr-2 w-100 search-button" onClick={() => this.onSearch()}
                                >
                                    Search
                                </ButtonElement>
                            </div>
                        </div>

                    </div>
                            </form>
                )

                }
            </>

        );

    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    publications: state.applicationReducer.publications,
    contentTypes: state.applicationReducer.contentTypes,
    digitalType: state.applicationReducer.digitalType,
    searchQueries: state.applicationReducer.searchQueries,
    dateObject: state.applicationReducer.dateObject,
    userTokens: state.loginReducer.tokens,
    searchObject: state.applicationReducer.searchObject,
});
export default connect(mapDispatchStatetoProps, {
    getPublications,
    setContentType,
    setSearchObject,
    getUserDetails,
    getContentTypes,
    searchContent,
    setDateObject,
    getBadgeData,
    getsetadandAdvdata,
    setsearchtype,
    customViewDatagtm,
    pageViewDatagtm,
    setDurations
})(adSearch)
