import React, { Component } from 'react';
import "./adminpanelstyle.scss";
import Accordianadmin from '../AccordianAdmin/Accordianadmin';
import { Route, Switch, Link } from "react-router-dom";
import Searchuseracount from '../UserManagement/Search-user-account/Searchuseracount';
import Userdetailsubcription from '../UserManagement/Userdetail-Subcription/userdetail-subcription';
import Announcement from '../AnnouncementManagment/Announcement';
import UserAccounts from '../UserManagement/Users/UserAccounts';
import ExternalAgency from '../AgencyManagement/Agency/ExternalAgency';
import CreateExternalAgency from '../AgencyManagement/Agency/CreateExternalAgency';
import AgencyPublisher from '../AgencyManagement/Agency/AgencyPublisher';
import CreateAgencyPublisher from '../AgencyManagement/Agency/CreateAgencyPublisher';
import ConfigPackages from '../SubscriptionManagement/ConfigPackages';
import AgencyUsages from '../AgencyBillingReport/AgencyUsages';
import ConfigAgencyPackage from '../AgencyManagement/Agency/ConfigAgencyPackage';
import AdminHome from '../Home/AdminHome';
import CreateAccount from '../UserManagement/CreateAccount/CreateAccount';
import UserSubscription from '../UserManagement/Userdetail-Subcription/UserSubscription';
import adminUser from '../SystemSetting/adminUser';
import externalSubscription from '../UserManagement/Userdetail-Subcription/externalSubscription';
import MailingList from '../SystemSetting/MailingList';
import AgencyRoyaltyReport from '../AgencyBillingReport/AgencyRoyaltyReport';
import Category from '../Category/Category';
import SubscriptionCategory from '../SubscriptionManagement/SubscriptionCategory';
import CreatePackage from '../SubscriptionManagement/CreatePackage';
import MailBlast from '../MailBlasting/MailBlast';
import Tokens from '../SystemSetting/Tokens';
import { AdminHamburger } from '../../../assets/images';
import DownloadHeritage from '../DownloadHeritage/DownloadHeritage';
import DeleteContent from '../DeleteContent/DeleteContent';
class Adminpanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidemenuFlag: false,
            toggleAdmin: false
        }
    }

    componentDidMount = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            await this.props.history.push("/home");
        } else {
            this.props.history.push('/admin/home');
        }
    }
    hidemenu = () => {
        this.setState({ hidemenuFlag: !this.state.hidemenuFlag });
    }
    onToggle = () => {
        if (window.innerWidth < 768) {
            this.setState({ toggleAdmin: !this.state.toggleAdmin });
        }
    }
    render() {
        const { hidemenuFlag, toggleAdmin } = this.state;
        const data1 = [];
        const data2 = [{ urls: "FindUser", pathname: "Find User", dataFlag: { isUsageDetails: false } }, { urls: "usageDetail", pathname: "Usage Details", dataFlag: { isUsageDetails: true } }];
        const data3 = [{ urls: "config-packages", pathname: "Config And Package" }, { urls: "newPackage", pathname: "Add New Package" }, { urls: "newCategory", pathname: "Add New Category" }];
        const data4 = [{ urls: "find-news", pathname: "Find News Agency/Publisher" }, { urls: "config-agency-packages", pathname: "Config Agency Packages" }];
        const data5 = [{ urls: "admin-user", pathname: 'Admin User' }, { urls: "mailing-list", pathname: "Mailing List" }, { urls: "developer-setting", pathname: "Developer Setting" }]
        const data6 = [{ urls: "agency-usages", pathname: 'Agency Usages' },
        { urls: "agency-royalty", pathname: "Agency Royalty" }]
        const data7 = [{ urls: "mail_blast", pathname: "Mail Blast" }]
        const data8 = []
        const data9 = [];
        return (<>
            <div className='toggleIcon p-2'>
                <img src={AdminHamburger} alt="no img" onClick={() => this.onToggle()} />
            </div>
            <div className="container-fluid">

                <div className="row">
                    {!toggleAdmin && <div className={hidemenuFlag ? "hidemenu" : "col-md-3 pt-0"}>
                        <div className='mt-10'>
                            <Link to="/admin/announcement">  <Accordianadmin key="1" toggle={this.onToggle} title="Announcement" data={data1} {...this.props} /></Link>
                            <Accordianadmin key="2" toggle={this.onToggle} title="User Management" data={data2} {...this.props} />
                            <Accordianadmin key="3" toggle={this.onToggle} title="Subscription Management" data={data3} {...this.props} />
                            <Accordianadmin key="4" toggle={this.onToggle} title="Agency Package Management" data={data4} {...this.props} />
                            <Accordianadmin key="6" toggle={this.onToggle} title="Agency Billing Report" data={data6} {...this.props} />
                            <Accordianadmin key="5" toggle={this.onToggle} title="System Setting" data={data5} {...this.props} />
                            <Accordianadmin key="7" toggle={this.onToggle} title="Mail Blasting" data={data7} {...this.props} />
                            <Link to="/admin/delete_content">  <Accordianadmin key="8" toggle={this.onToggle} title="Delete Content" data={data8} {...this.props} /></Link>
                            <Link to="/admin/download">  <Accordianadmin key="9" toggle={this.onToggle} title="Download Heritage Photos" data={data9} {...this.props} /></Link>
                        </div>
                    </div>}
                    <div className='line' onClick={() => this.hidemenu()}>
                        {/* {hidemenuFlag ? (<FontAwesomeIcon icon={faCaretRight} className='arrow' />) : (<FontAwesomeIcon icon={faCaretLeft} className='arrow' />)} */}
                    </div>
                    <div className={hidemenuFlag ? "col-md-11 p-0 " : "col-md-8 p-0 margin-left-10"}>
                        <Switch {...this.props}>
                            <Route path="/admin/home" component={AdminHome} />
                            <Route path="/admin/announcement" component={Announcement} />
                            <Route path="/admin/download" component={DownloadHeritage} />
                            <Route path="/admin/FindUser" key={Math.random()} component={Searchuseracount} />
                            {/* <Route path="/admin/FindUser"   key="findUser" component={Searchuseracount} /> */}
                            {/* <Route path="/admin/usageDetail" key="usagedetails" component={Searchuseracount} /> */}
                            <Route path="/admin/usageDetail" key={Math.random()} component={Searchuseracount} />
                            <Route path="/admin/user-account" component={UserAccounts} />
                            <Route path="/admin/userdetail-subscription" key={Math.random()} component={Userdetailsubcription} />
                            <Route path="/admin/config-agency-packages" key={Math.random()} component={ConfigAgencyPackage} />
                            <Route path="/admin/find-news" component={ExternalAgency} />
                            <Route path="/admin/admin-user" component={adminUser} />
                            <Route path="/admin/mailing-list" component={MailingList} />
                            <Route path="/admin/developer-setting" component={Tokens} />
                            {/* <Route path="/admin-panel/mailing-list" component={UserAccounts} /> */}
                            <Route path="/admin/external-agency" component={CreateExternalAgency} />
                            <Route path="/admin/agency-publisher" component={AgencyPublisher} />
                            <Route path="/admin/create-edit-agency-publisher" component={CreateAgencyPublisher} />
                            <Route path="/admin/agency-royalty" component={AgencyRoyaltyReport} />
                            <Route path="/admin/config-packages" component={ConfigPackages} />
                            <Route path="/admin/create-account" key={Math.random()} component={CreateAccount} />
                            <Route path="/admin/agency-usages" component={AgencyUsages} />
                            <Route path="/admin/userSubscription" component={UserSubscription} />
                            <Route path="/admin/externalSubscription" component={externalSubscription} />
                            <Route path='/admin/category' component={Category} />
                            <Route path='/admin/subscriptionCategory' component={SubscriptionCategory} />
                            <Route path='/admin/newCategory' component={Category} />
                            <Route path='/admin/newPackage' component={CreatePackage} />
                            <Route path='/admin/mail_blast' component={MailBlast} />
                            <Route path='/admin/delete_content' component={DeleteContent} />
                        </Switch>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default Adminpanel;
