import React from "react";
import { ButtonElement } from "../UI/index";
import { OrangeTick } from "../../assets/images";
import "./css/forgotPassword.scss";

const ResetPassword = (props) => {
  return (
    <div className="text-center forgot-container">
      <h3 className="login-heading mb-4 mt-4">Password Reset Request</h3>
      <img src={OrangeTick} className="icon-img pb-4" alt="orange-tick" />
      <p>
        An email will be sent to you. Please click on the validation link to
        reset your password.
      </p>
      <div className="text-center mt-4 mb-4">
        <ButtonElement
          variant="contained"
          btnType="background-orange"
          className="d-inline-block mr-2 reset-password"
          onClick={props.onClose}
        >
          close
        </ButtonElement>
      </div>
    </div>
  );
};

export default ResetPassword;
