import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonElement } from "../UI/index";
import { resetPassword } from "../../redux/actions/loginAction";
import { customViewDatagtm } from "../../redux/actions/applicationsAction";
import "./css/forgotPassword.scss";
import { getCustomviewobj } from "../../helpers/common";

class ForgotPassword extends Component {
  state = {
    email: "",
    isEmailReuiredMessage : false
  };

  setEmail = (e) =>
    this.setState({
      isEmailReuiredMessage:false,
      email: e.target.value.toString().trim(),
    });

  onResetPassword = async (e) => {
    e.preventDefault();

    var email = this.state.email.toString().trim()
    if( email !=='')
      {
        try {
        await this.props.resetPassword(this.state.email);

        } catch (error) {
          console.log("err",error)
        }

        if (!this.props.loginError) {
          this.props.onResetSuccess();
        }
        this.customViewcall(this.props.searchObject,{})

      }
    else
      this.setState({isEmailReuiredMessage:true})
  };

  customViewcall=(searchObject,token)=>{
    let user_status=token?.user_info?.user_type ? token?.user_info?.user_type:"" ;
    let id=token?.user_info?.visitor_id ? token?.user_info?.visitor_id : ""
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate)
    let objj= getCustomviewobj("reset password","click","","login","",user_status,"","","",id)
    this.props.customViewDatagtm(objj,searchObject)
  }
  render() {
    let {isEmailReuiredMessage} = this.state;
    return (
      <div className="col-md-10 m-auto forgot-container">
        <form  >
          <h3 className="login-heading mb-4 mt-5">FORGOT YOUR PASSWORD?</h3>
          <input
            className="form-control mb-3  inputControl"
            type="text"
            placeholder="Id/Email*"
            value={this.state.email}
            onChange={this.setEmail}
            required
            autoComplete="false"
          /> <span className="err">{
              isEmailReuiredMessage ? "Email Required":""
            }</span>
          <div className="text-center mt-5 mb-5">
            <ButtonElement
              variant="contained"
              btnType="background-orange"
              className="d-inline-block mr-2 reset-password"
              onClick={this.onResetPassword}
            >
              reset password
            </ButtonElement>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loginError: state.loginReducer.login_error,
  successResponse : state.loginReducer.successResponse,
  contentTypes: state.applicationReducer.contentTypes,
  searchObject:state.applicationReducer.searchObject
});

export default connect(mapDispatchStatetoProps, { resetPassword,customViewDatagtm })(
  ForgotPassword
);
