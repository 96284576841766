import React, { Component } from 'react';
import './other.css'
class contactUs extends Component {
    render() {
        return (
            <div className="aboutus" >
                <h3>Contact Us</h3>
               <p>If you have queries, please contact us at:</p>
               <div className='pl-5'>
                <p><strong>Helpdesk Hotline:</strong>	(65) 6319 2333</p>
                <strong>Fax:</strong>	(65) 6319 8259
                Mon - Fri, 9am -5pm
              <p><strong>Address:</strong>	Singapore Press Holdings Ltd.</p>
                1000 Toa Payoh North,
               Singapore 318994.
                <p><strong>Email:</strong>	archives@sph.com.sg</p>
                </div>
            </div>
        );
    }
}

export default contactUs;
