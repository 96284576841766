import React, { Component } from 'react';
import './agency.scss';
import { connect } from "react-redux";
import { ButtonElement, DataTable, Modal, Spinner } from '../../../UI/index';
import { getAgencyPublisherById, updateAgencyPublisherStatus, getAgencyPublisher, startLoader } from '../../../../redux/actions/adminAction';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../config/constants/index";

const initialState = {
    submit: false,
    message: '',
    isBack : false,
    agency: {
        publisher: '',
        description: '',
        status: '',
        createdOn: '',
        publisherCode: '',
        action: ''
    },
    agencyPublisherResult: [{
        publisher: '',
        description: '',
        status: '',
        createdOn: '',
        publisherCode: '',
        action: ''
    }],
    isEditAgency: false,
    isCloseAgencyPublisher: false,
    publisherName: '',
    agencyCode: ''
}

class AgencyPublisher extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);

        this.recaptcha = React.createRef(null);
    }
    componentDidMount = async () => {
        this.setState({
            visibleCreateAgencyForm: false,
            agencyCode: this.props.location.state.agencyCode
        })

        // For Edit Agency
        if (this.props.location.state.agencyPublisherData) {
            var result = [];
            for (let r of this.props.location.state.agencyPublisherData) {
                let action = (r.status == 'ACTIVE' || r.status == 'active') ? 'CLOSE' : 'ACTIVE';
                let publisherObj = {
                    action: <a className='decorline' onClick={() => this.onCloseAgencyPublisher(r)}>{action}</a>,
                    publisher: <a className='decorline' onClick={() => this.getAgencyPublisherById(r.id)}>{r.publisher}</a>,
                    // agencyCode: <a className='createAgency' onClick={()=>this.codeAge(a.agencyCode)}>{a.agencyCode}</a>,
                    description: r.description,
                    status: r.status == "ACTIVE" ? <span className='active'>{r.status}</span> : <span className='closeStatus'>{r.status}</span>,
                    createdOn: r.createdOn,
                    publisherCode: r.publisherCode
                }
                result.push(publisherObj)
            }

            this.setState({
                agencyPublisherResult: result
            })
        }
    }

    onCloseAgencyPublisher = (publisher) => {
        let agencyPublisher = {
            "agencyCode": publisher.agencyCode,
            "description": publisher.description,
            "id": publisher.id,
            "publisher": publisher.publisher,
            "status": publisher.status == "ACTIVE" ? 2 : 1
        }

        let flag = publisher.status == "ACTIVE" ? "close" : "active";

        this.setState({
            isCloseAgencyPublisher: true,
            publisherName: flag +" '"+ publisher.publisher,
            agency: agencyPublisher,
            agencyCode: publisher.agencyCode
        })
    }

    onConfirmDelete = async (e) => {
        e.preventDefault();
        this.recaptcha.execute();
    }

    onResolved = async (e) => {
        this.props.startLoader();
        const { agency } = this.state;
        const response = this.recaptcha.getResponse();
        agency.captchaToken = response;
        await this.props.updateAgencyPublisherStatus(agency);
        await this.props.getAgencyPublisher({ agencyCode: this.state.agencyCode });
        if (this.props?.getAgencyPublisherResult?.data) {
            var result = [];
            for (let r of this.props?.getAgencyPublisherResult?.data) {
                let action = (r.status == 'ACTIVE' || r.status == 'active') ? 'CLOSE' : 'ACTIVE';
                let publisherObj = {
                    action: <a className='decorline' onClick={() => this.onCloseAgencyPublisher(r)}>{action}</a>,
                    publisher: <a className='decorline' onClick={() => this.getAgencyPublisherById(r.id)}>{r.publisher}</a>,
                    // agencyCode: <a className='createAgency' onClick={()=>this.codeAge(a.agencyCode)}>{a.agencyCode}</a>,
                    description: r.description,
                    status: r.status == "ACTIVE" ? <span className='active'>{r.status}</span> : <span className='closeStatus'>{r.status}</span>,
                    createdOn: r.createdOn,
                    publisherCode: r.publisherCode
                }
                result.push(publisherObj)
            }
            this.setState({
                agencyPublisherResult: result
            })
        }
        this.closeModal();
        await this.props.clearAdminError();
    }
    onReset = (e) => {
        this.setState(initialState);
    }
    getAgencyPublisherById = async (id) => {
        await this.props.getAgencyPublisherById(id);
        this.props.history.push({
            pathname: "/admin/create-edit-agency-publisher",
            state: {
                isEditAgencyPublisher: true,
                agencyPublisherData: this.props.agencyPublisherByIdResult.data
            }
        })
    }
    onSave = (e) => {
        e.preventDefault()
        let agencyCode = this.state?.agencyCode || this.props.location.state?.agencyPublisherData[0]?.agencyCode
        this.props.history.push({
            pathname: '/admin/create-edit-agency-publisher',
            state: {
                agencyCode: agencyCode,
                agencyCodeId: this.props.location.state?.agencyPublisherData[0]?.id
            }
        });
    }
    onSearch = async (e) => {
        e.preventDefault()
        const searchData = {
            agency: this.state.agency.agency,
            agencyCode: this.state.agency.agencyCode,
            createDate: '',
            pageNo: 0,
            status: 1 //this.state.agency.status
        }
        await this.props.getAgency(searchData);
    }
    setFormValue = async (value, field, number = false) => {
        const { agency } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            agency[field] = regex.test(value) || value === "" ? value : agency[field];
        } else {
            agency[field] = value;
        }

        this.setState({
            agency
        });
    }
    onBack = async (e) => {
        this.props.history.push({
            pathname: "/admin/find-news",
            state: {
               isBack : true
            }
        });
        // this.props.history.push('/admin/find-news');
    }
    closeModal = async (e) => {
        this.setState({
            isCloseAgencyPublisher: false
        })
    }
    render() {
        const { loading } = this.props;
        const { isCloseAgencyPublisher, agencyPublisherResult, publisherName, message } = this.state;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }

                {isCloseAgencyPublisher && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    message ? message : `Do you want to ${publisherName}' agency?`
                                }
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement> {" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onConfirmDelete(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                )}
                <div className='container-fluid' key={Math.random()}>
                    <h3 className="heading">
                        Agency Publisher
                    </h3>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <ButtonElement
                                type="submit"
                                variant="contained"
                                btnType="background-orange button-label "
                                className="d-inline-block mr-2 margin-top10"
                                onClick={(e) => this.onSave(e)}
                            >
                                <span className="button-label">Create Agency Publisher</span>
                            </ButtonElement>
                            <ButtonElement
                                type="submit"
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 margin-top10"
                                onClick={(e) => this.onBack(e)}
                            >
                                <span className="button-label">Back</span>
                            </ButtonElement>
                        </div>
                    </div>
                    <div>
                        <div className='row' key={Math.random()}>
                            <div className="users-table overflowScroll-X">
                                {agencyPublisherResult && agencyPublisherResult[0] && <DataTable className="table-responsive" columns={agencyPublisherResult[0]} data={agencyPublisherResult} ></DataTable>}
                            </div>
                        </div>
                    </div>
                </div>
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    getAgencyPublisherById: state.adminReducer.getAgencyPublisherById,
    updatedAgencyPublisherStatusResult: state.adminReducer.updatedAgencyPublisherStatusResult,
    agencyPublisherByIdResult: state.adminReducer.agencyPublisherByIdResult,
    agencyPublisherResult: state.adminReducer.agencyPublisherResult,
    updatedAgencyPublisherStatusErrorResult: state.adminReducer.updatedAgencyPublisherStatusErrorResult,
    getAgencyPublisherResult: state.adminReducer.agencyPublisherResult

});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getAgencyPublisher,
    getAgencyPublisherById,
    updateAgencyPublisherStatus
})(AgencyPublisher);
