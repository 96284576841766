import React, { useState } from 'react';
import './accordianadmin.scss';
import { Link } from 'react-router-dom';
const Accordianadmin = (props) => {
  const [isOpen, setOpen] = useState(true);

  const toggle = () => {
    setOpen(!isOpen);
  }

  return (

    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={toggle}
      >
        {props.title}
      </div>
      <div className={`accordion-item ${isOpen ? "" : "collapsed"}`}>
        <div className="accordion-content"  >
          {props.data && props.data.map((x) => {
            return(
            <div key={x.pathname}>
              <Link onClick={props.toggle} to={{pathname:`${props.match.path}/${x.urls}`, state : { finduser: x.dataFlag }}} className='items' key={x.pathname} >{x.pathname}</Link>
            </div>
          )})}
        </div>
      </div>
    </div>
  );
};

export default Accordianadmin;
