import axios from "axios";

let headers = {
  "X-Frame-Options": "deny",
};
if (
  process.env.REACT_APP_ACTIVE_ENV == "LOCAL" &&
  process.env.REACT_APP_ADMIN_TOKEN_LOCAL != ""
) {
  headers = {
    "X-Frame-Options": "deny",
    Authorization: "Bearer " + process.env.REACT_APP_ADMIN_TOKEN_LOCAL,
  };
}

const HTTP = axios.create({
  withCredentials: true,
  "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_ORIGIN,
  baseURL: process.env.REACT_APP_DOMAIN,
  headers: headers,
});

export default HTTP;
