import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getIcon } from '../../../helpers/common';
const CustomSelect = (props) => {
    const [show, setShow] = useState(false);
    useEffect(()=>{
        var ignoreClickOnMeElement = document.getElementById(props.id);

        document.addEventListener('click', (event) => {
            const className = event.target.parentElement && event.target.parentElement.className ? event.target.parentElement.className : "";
            var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
            if (!isClickInsideElement && className !== "close") {
                //Do something click is outside specified element;
                setShow(false);
            }
        });
    },[])
    const showlist = (val) => {
        setShow(!show);
        if (val) {
            props.onChange(val)
        }
    }

        return (


                <div id={props.id}  className={props.class ? `${props.class} dropdown border border-dark w-100` :"dropdown border border-dark w-100 "} >
                    <div onClick={() => showlist()}>
                    <label  className="pt-2 pl-2 pr-2  w-100 bg-transparent"  style={{fontWeight:"600"}}>
                    {props?.defaultValue.key ? props?.defaultValue.key :props?.defaultValue }
                        <FontAwesomeIcon className='float-right' icon={faChevronDown} />
                    </label>
                    </div>
                    <div style={{backgroundColor:'#efefef'}} className={show ? "dropdown-menu  show w-100" : "dropdown-menu "} aria-labelledby="dropdownMenuButton "  >
                        {props?.options && props?.options.map((option, index) =>{
                            // console.log(option)
                                return(
                                    <>
                                    <div onClick={() => showlist(option.key)} className='d-flex flex-nowrap pl-1 dropdown-itemss hoveffect' >
                                    {option.key === 'All' ? (<img  src={getIcon("HOME black",false)} alt="logo"  className="logo pr-1 hoveffect" key={index+10} />):
                                    (<img  src={getIcon(option.key,true)} alt="logo"  className="logo pr-1 hoveffect" key={index+10} />)}
                                    {/* {option.key !== 'All' &&} */}
                                    <span  className="p-1 hoveffect"  key={index}>{option.key}</span>
                                    </div>
                                    </>
                                )}
                        )}


                    </div>
                </div>

        );


}

export default CustomSelect;
