import React, { Component, Fragment } from 'react';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './NotFound.scss';
import { decodeToken } from 'react-jwt';
import { connect } from 'react-redux';
import {pageViewDatagtm} from '../../../redux/actions/applicationsAction';
import { getPageviewobj } from '../../../helpers/common';

class SearchResultNotFound extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {


        const myDecodedToken = decodeToken(this.props?.tokens);
      this.setPageViewcall(this.props.searchObj,myDecodedToken);

    }
    setPageViewcall=(searchObject,myDecodedToken)=>{
        let digconfig;
        if(this.props?.digitalType?.extraData){
          digconfig=this.props?.digitalType?.extraData[0]?.digitalType;
        }else{digconfig=this.props?.digitalType};
        let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
        let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
        let obj= getPageviewobj("","search","",user_status,"","","",id,"No Result");
        this.props.pageViewDatagtm(obj,searchObject)
    }
    render() {
        const myDecodedToken = decodeToken(this.props?.tokens);
        const flag = myDecodedToken && myDecodedToken?.user_info?.pub_list;
        return (
            <Fragment>
                <div className='container'>


                    <div className='justify-content-center pt-5 d-flex'>
                        <span className="" >
                            <FontAwesomeIcon icon={faSearch} size='2x' />
                        </span>
                        <h4 className="errorpage d-inline-block">No result found for &nbsp; </h4>
                        {this.props.searchObj?.keywords && <h4 className='breakWord'>"{this.props?.searchObj?.keywords}"</h4>}
                        {this.props?.pdfpagenumber && <h4 className='breakWord'>&nbsp;"{this.props?.pdfpagenumber}"</h4>}
                        {this.props.searchObj?.caption && <h4 className='breakWord'>&nbsp;"{this.props?.searchObj?.caption}"</h4>}
                        {this.props.searchObj?.byline && <h4 className='breakWord'>&nbsp;"{this.props?.searchObj?.byline}"</h4>}
                        {this.props.searchObj?.advertiser && this.props.searchObj?.advertiser.length > 0 && <h4 className='breakWord'>&nbsp;"{this.props?.searchObj?.advertiser.toString()}"</h4>}
                        {/* {this.props.searchObj?.section && this.props.searchObj?.section.length > 0 && <h4 className='breakWord'>&nbsp;"{this.props?.searchObj?.section.toString()}"</h4>} */}

                    </div>
                    {/* <div className="btn-error button_custom" onClick={this.redirectToHome}>Return to Home</div> */}
                    <div className='col-md-8 offset-md-4 pt-5 pb-5'>

                        <p><strong>Suggestion :</strong></p>
                        {flag && flag.length > 0 && <p>Remove the preference option </p>}
                        <p>Check your spelling.</p>
                        <p>Try more general words.</p>
                        <p>Try different words that mean the same thing.</p>
                        <p>Use Fewer words</p>
                        {this.props.searchObj?.publication?.length > 0 && <p>Try remove publications</p>}
                        {this.props?.pdfpagenumber && <p>Check page no entered</p>}
                        {this.props.searchObj?.caption && <p>Check the caption entered</p>}
                        {this.props.searchObj?.byline && <p>Check byline entered</p>}
                        <p>Please note that the publications Nanyang Siang Pau (NYSP) and New Nation (NN) only support image search</p>
                    </div>
                </div>

            </Fragment >
        )
    }
}

// export default SearchResultNotFound;
const mapDispatchStatetoProps = (state) => ({
    ...state,
    userTokens: state.loginReducer.tokens,
    searchContents: state.applicationReducer.searchContent,
    digitalType: state.applicationReducer.digitalType,
    isLoading: state.applicationReducer.isLoading,
  });

  export default connect(mapDispatchStatetoProps, {
    pageViewDatagtm
  })(SearchResultNotFound);
