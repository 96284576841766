import React, { Component } from 'react';
import '../SelectList/selectlist.scss';
import { Checkbox } from '..';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { changeSelectedSub } from "../../../redux/actions/adminAction"
import { connect } from 'react-redux';
class Selectlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSub: {},
      toggle: true,
      fontname: faChevronDown
    }

  }
  componentDidMount = () => {
    const { selectedSub } = this.state;
    const val = this.props.list;

    // console.log("list", this.props.list)
    this.setState({
      selectedSub: {}
    })
    val.map(x => {
      if (x.ischecked) {
        selectedSub[x.value] = true;
      }
    })
    this.setState({
      selectedSub
    })
    this.props.onChange(this.state);
  }

  onSelectSubPlan = (selectedSub) => {
    if (!this.props?.resetFlag) {
      this.setState({
        selectedSub
      });
      // console.log(this.state)
      this.props.onChange(this.state)

    } else {
      this.props.onChange(selectedSub)

    }

  }

  onReset = () => {
    this.setState({
      selectedSub: {}
    });
  }

  onSelectPermission = (value, field, index) => {
    this.props.list[index].ischecked = value;
    // console.log("allvals",this.props.list);
    if (this.props?.resetFlag) {
      let obj = this.props.selectedSub;
      obj[field] = value;
      this.props.changeSelectedSub(obj)
      this.onSelectSubPlan(obj);

    } else {
      // debugger
      this.setState({
        selectedSub: {}
      })
      // this.props.list[index].ischecked = value;
      // console.log("allvals",this.props.list);
      const { selectedSub } = this.state;
      selectedSub[field] = value;
      this.onSelectSubPlan(selectedSub);
    }


  }

  togglearrow = (event) => {
    if (event) {
      this.setState({
        toggle: event,
        fontname: faChevronUp
      })
    } else {
      this.setState({
        toggle: event,
        fontname: faChevronDown
      })
    }
  }
  render() {
    const { list, label } = this.props;
    const { toggle, fontname } = this.state;
    return (
      <>
        <label className='checkboxlabel' onClick={() => this.togglearrow(!toggle)} >{label}</label>
        <FontAwesomeIcon icon={fontname} className="right" onClick={() => this.togglearrow(!toggle)} />
        <div className={`d-flex flex-wrap paddingBottom2px selectlist-item ${toggle ? "" : "collapsed"}`}>
          {list.map((p, index) => (
            <div className='flexcheckbox padding5px' key={p.key}>
              {/* <Checkbox label={p.key} checked={p.ischecked} key={Math.random()} onChange={(e) =>
                this.onSelectPermission(e.target.checked, p.value, index)
              }
              /> */}
              <Checkbox checked={p.ischecked} key={Math.random()} onChange={(e) =>
                this.onSelectPermission(e.target.checked, p.value, index)
              }
              />
              <span className="margin-left-35 capitalize">{p.key} </span>
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  selectedSub: state.adminReducer.selectedSub
});
export default connect(mapDispatchStatetoProps, {
  changeSelectedSub
})(Selectlist);
