import React, { Component } from 'react';
import './other.css';
import { pageViewDatagtm,customViewDatagtm} from "../../redux/actions/applicationsAction";
import {getUserDetails} from "../../redux/actions/loginAction";
import { connect } from 'react-redux';
import { getPageviewobj } from '../../helpers/common';
import { decodeToken } from 'react-jwt';
class aboutUs extends Component {
   async componentDidMount(){
    await this.props.getUserDetails();
    let myDecodedToken = decodeToken(this.props?.tokens?.id_token);
    this.setPageViewcall(this.props.searchObject,myDecodedToken);
    }
    setPageViewcall=(searchObject,myDecodedToken)=>{
        let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
        let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : "";
        let url=window.location.href;
        let obj= getPageviewobj(url,"about us","About Us",user_status,"","","",id);
        this.props.pageViewDatagtm(obj,searchObject);
      }
    render() {
        return (
            <div className=" container">
                <h3 className='pt-5 pb-3'>About Us</h3>
                <p>Newslink provides you with access to:</p>
                <ul style={{ paddingLeft: "5%" }}>
                    <li>
                        The Business Times
                    </li>
                    <li>
                        Berita Harian
                    </li>
                    <li>
                        The New Paper
                    </li>
                    <li>
                        Tabla!
                    </li>
                    <li>
                        Lianhe Zaobao
                    </li>
                    <li>
                        My Paper
                    </li>
                    <li>
                        Lianhe Wanbao
                    </li>
                    <li>
                        Shin Min Daily News
                    </li>
                    <li>
                        Friday Weekly
                    </li>
                    <li>
                        Thumbs Up
                    </li>
                    <li>
                        Thumbs Up Junior
                    </li>
                    <li>The Straits Times Education Programme</li>
                </ul>
                <p><strong>The Straits Times</strong></p>
                <p>Singapore's national daily, focusing on news and features of public interest and appealing to readers across a broad spectrum. It is also a paper of record. For articles from The Sunday Times, Digital Life, Mind Your Body and Urban, they will be available under The Straits Times.</p>

                <p><strong>The Business Times</strong></p>
                <p> A business newspaper, it provides exclusive reports, trend-spotting news, incisive analyses and commentaries that allow the reader to act, not react, both on a personal and a business level.</p>

                <p><strong>Berita Harian</strong></p>
                <p>Provides comprehensive news, information, valuable insights and coverage on issues and policies relevant to the Malay community. It caters to the needs of the Singapore Malays, communicating and interpreting news from a Malay perspective.</p>

                <p><strong>The New Paper</strong></p>
                <p> An afternoon-evening paper that focuses on the NEW (trends, people, products), NEWS (any information that is likely to be the talk of the town) and NEWSY (unique voice and flavor with emphasis on human interest, scoops and sports).It has pioneered a style of journalism that goes for sharp news angles, that are prickly yet meaty, and that put a human face to complicated issues of the day. From entrepreneurs in the news to ordinary folks to the New Face of the Year, the paper delivers sharp news seven days a week.')</p>

                <p><strong>Tabla!</strong></p>
                <p>English weekly catering to the local and expat Indian community. Infused with an editorial mix of Indian and local news, Tabla! also features indepth profiles of Indian high flyers, Bollywood and other entertainment insights, alongside fashion and cricket updates.</p>

                <p><strong>Lianhe Zaobao</strong></p>
                <p>Singapore''s national Chinese morning daily. It is influential in shaping opinions and reflects the thinking of the Chinese community. It is an authoritative source of news and views on China, Hong Kong and Taiwan.</p>

                <p><strong>My Paper</strong></p>
                <p>my paper, a compact-sized Chinese freesheet, is designed for bilingual young working adults. Its tagline My Life, My Thoughts, My Say encapsulates the editorial philosophy of reader empowerment and participation. Published from Tuesdays to Saturdays, my paper presents news and analyses in easily-digestible bite sizes and promises to keep readers in touch with the world in 20 minutes.</p>

                <p><strong>Lianhe Wanbao</strong></p>
                <p>An easy-to-read paper that provides the latest news on both local and international happenings, with a strong food and entertainment bias.</p>

                <p><strong>Shin Min Daily News</strong></p>
                <p>Provides quick news in a lively and interesting style, with emphasis on human-interest stories, soccer and entertainment.</p>

                <p><strong>Friday Weekly</strong></p>
                <p>It helps to cultivate the habit of reading Chinese newspapers among Secondary 1 - 4 students.</p>

                <p><strong>Thumbs Up</strong></p>
                <p>A tabloid- sized Chinese weekly targeting at primary 3-6 students. It present news to students in a perspective that their young mind can understand, so as to improve their general knowledge and Chinese language ability. Content is categorized into "3I" - Inform, Interact, Interest</p>

                <p><strong>Thumbs Up Junior</strong></p>
                <p>The 20-page, full-colour Thumbs Up Junior is published fortnightly for primary one and two students. It covers a wide range of content and makes learning of Chinese language a fun and lively experience. Content includes local and foreign news, human-interest stories, Chinese culture, quizzes and riddles, conversation practices and hanyu pinyin learning.</p>

                <p><strong>The Straits Times Education Programme</strong></p>
                <p>STep Basic - The Straits Times Education Programme features The Straits Times news stories that are turned into an authentic English Language resource for Primary school students. Tasks featured in each issue of STep include Comprehension, Grammar and Vocabulary Cloze and Scrapbooking activities.</p>
            </div>
        );
    }
}

// export default aboutUs;
const mapDispatchStatetoProps = (state) => ({
    ...state,
    tokens: state.loginReducer.tokens,
    searchObject: state.applicationReducer.searchObject,
});
export default connect(mapDispatchStatetoProps, {
    pageViewDatagtm,
    customViewDatagtm,
    getUserDetails
})(aboutUs)
