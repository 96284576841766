import React, { Component } from 'react';

class tnc extends Component {
    render() {
        return (
            <div className='pl-5 pr-5'>
                <h1>Terms and conditions</h1>
                <p>  Welcome to our Site.  Please read the following Website Terms and Conditions carefully (including our Privacy Policy) (collectively “Website Conditions”) before using the Site and/or the Services (both defined below), so that you are aware of your legal rights and obligations with respect to SPH Media Limited and/or its related entities, affiliates and subsidiaries, and their respective successors-in-title (individually and collectively, "SPH Media"). </p>

                <p>By your (or where Clause 1.1(b)(iii) applies, your child’s or ward’s) access of the Site and/or use of the Services, you hereby agree to be legally bound by this Agreement (as defined below).  If you do not accept this Agreement, please leave the Site and discontinue use of the Services immediately. </p>

                <p><strong>1.</strong>     Agreement</p>

                <p><strong>1.1</strong>              You hereby represent and warrant that:</p>

                <p><strong>(a)</strong>                you have read, agree and consent to this Agreement, including these Website Conditions and the terms of our Privacy Policy as amended from time to time, and the terms of the Privacy Policy are incorporated into this Agreement by reference;</p>

                <p><strong>(b) </strong>              you are at least 18 years old and have the necessary legal capacity, right, power and authority to agree to this Agreement and you are either;</p>

                <p><strong>(i)</strong>          accessing the Site, using the Services and contracting in your own personal capacity;</p>

                <p><strong>(ii)  </strong>              accessing the Site, using the Services and contracting on behalf of a corporate entity; or</p>

                <p><strong>(iii) </strong>          contracting as parent or guardian of a child or ward who is less than 18 years old and who is accessing the Site and using the Services, in which event you agree as his/her parent/guardian, both in your personal capacity and on behalf of your child/ward, to be bound by this Agreement and to be liable for your child’s or ward’s acts and omissions while accessing the Site and/or using the Services, and you also agree to ensure that your child or ward observes this Agreement;</p>

                <p><strong>(c)</strong>                you are authorised to bind the entity (yourself or your corporate entity) on whose behalf you are contracting and such entity agrees to be bound by this Agreement; and</p>

                <p><strong>(d)</strong>               all of the information provided by you to SPH Media (including without limitation personal particulars and contact information) is accurate and complete. If in connection with your use of the Site and/or Services, you provide the Personal Data of any third parties, you further warrant and represent that these third parties have also consented to the terms of the Privacy Policy, and to the collection, use and disclosure of their Personal Data in accordance with the aforesaid.</p>

                <p><strong>1.2</strong>              SPH Media reserves the right to change, modify, suspend or discontinue the whole or any portion of the Services or Site at any time.  SPH Media may also impose limits on certain features or restrict your access to parts or the entire Services or Site without notice or liability.</p>

                <p><strong>1.3</strong>              SPH Media may from time to time vary, replace, delete, supplement or amend this Agreement by posting the amended Agreement at the Site.  Any use of the Services after the amendment of this Agreement will be deemed to be acceptance of the amended Agreement by you.  If you do not agree to the amended Agreement, you have the right to close your Account and/or cease using the Services.</p>

                <p><strong>2.</strong>     Definitions</p>

                <p><strong>2.1</strong>              In this Agreement, the following definitions shall apply unless the context does not permit such application:</p>

                <p><strong>“Account” </strong>means a registered account of a Member opened under the Site or any App.</p>

                <p><strong>“Agreement” </strong>means the agreement formed by these Website Conditions and the Privacy Policy, and in the case of a Member, by these Website Conditions, the Privacy Policy and the Member Conditions.</p>

                <p><strong>“App”</strong> means any software or mobile application of SPH Media.</p>

                <p><strong>“Computer”</strong> means your computer, notebook computer, personal digital assistant, mobile phone or other electronic device used to access the Site or the Services. </p>

                <p><strong>“Content”</strong> means materials, information, news, advertisements, listings, data, input, text, songs, audio, video, pictures, graphics, software, blogs, webcasts, podcasts, broadcasts, messages, software, comments, suggestions, ideas and other content.</p>

                <p><strong>“Linked Sites”</strong> is defined in Clause 9.1.</p>

                <p><strong>“Member”</strong> means a registered member of the Site.</p>

                <p><strong>“Member Conditions”</strong> means the terms and conditions applicable to Members accessible here.</p>

                <p><strong>“Personal Data”</strong> means, data, whether true or not, about an individual who can be identified from that data or from that data and other information to which a party has or is likely to have access.</p>

                <p><strong>“Servers”</strong> means the computer software, systems and servers hosting, operating, managing, providing or contributing to the Site and the Services.</p>

                <p><strong>“Services”</strong> is defined in Clause 3.2.</p>

                <p><strong>“Site”</strong> means the SPH Media website and/or platform and includes but is not limited to the material and information contained in the website and/or platform, including any Content and RSS feeds, APIs, software code and scripts, and any other downloadable materials displayed or incorporated therein, as well as the Services offered thereon, as may be applicable.</p>

                <p><strong>"SPH Media Content"</strong> means all Content of SPH Media that is made available on or via the Site or a SPH Media website.</p>

                <p><strong>“Third Party Products” </strong>means products and services of third parties, including other users, advertised on or available at the Site or websites linked from the Site.</p>

                <p><strong>“Third Party User Content”</strong> means all User Content which is not created, transmitted, posted or uploaded by you.</p>

                <p><strong>“User Content” </strong>means all Content on the Site which is created, transmitted, posted or uploaded by a user of the Site.</p>

                <p><strong>2.2 </strong>             The words “include” and “including” shall not be construed as having any limiting effect.</p>

                <p><strong>2.3  </strong>            The headings in this Agreement do not have any legal effect nor shall they affect the construction of this Agreement in any way.</p>

                <p><strong>3.</strong>     Site and Services</p>

                <p><strong>3.1</strong>              The Site is owned and maintained by SPH Media.</p>

                <p><strong>3.2  </strong>            SPH Media may offer one or more of the following services on or through the Site (each a “Service” and collectively the “Services”):</p>

                <p><strong>(a)</strong>                access to a collection of information, news, data, text, listings, graphics, images, videos, audio files, podcasts, webcasts, software applications and other types of works, including any print, digitised or electronic newspapers, magazines, Apps or other content of SPH Media, whether through an online store or otherwise; search engines or tools;</p>

                <p><strong>(b)</strong>               a platform to create, upload and publicly make available personalised content;</p>

                <p><strong>(c)</strong>                an advertising and branding platform; </p>

                <p><strong>(d) </strong>              message boards, forums, blogs, communication tools;</p>

                <p><strong>(e)</strong>                a social networking platform;</p>

                <p><strong>(f) </strong>                email alerts; and</p>

                <p><strong>(g)</strong>                any other features, content or applications that SPH Media may offer on or through the Site from time to time in its sole and absolute discretion.</p>

                <p><strong>3.3</strong>              You acknowledge and agree that to access and use certain Services, you will be required to register as a Member and additionally shall be bound to strictly comply with the Member Conditions and/or such other terms and conditions as SPH Media may prescribe in addition to these Website Conditions and the other terms of this Agreement.</p>

                <p><strong>3.4 </strong>             From time to time SPH Media will run competitions, promotions and surveys at the Site.  These are subject to additional terms and conditions that will be made available at the time they are run.</p>

                <p><strong>4.</strong>     Content Use Conditions</p>

                <p><strong>4.1</strong>              You may not reproduce, modify, adapt, translate, publish, display, communicate, transmit, broadcast, podcast, webcast, distribute, sell, trade or exploit for any commercial or other purposes, any portion of, or any access to:</p>

                <p><strong>(a) </strong>               any Service;</p>

                <p><strong>(b)</strong>               the Site;</p>

                <p><strong>(c) </strong>               any SPH Media Content except, to the extent permitted, with the prior written consent of SPH Media or unless expressly permitted in this Agreement; or</p>

                <p><strong>(d)</strong>               any Third Party User Content except, to the extent permitted, with the prior written consent of SPH Media and the owner or licensee of the specific User Content.</p>

                <p><strong>4.2</strong>              Without prejudice to the generality of Clause 4.1, you agree not to reproduce, display or otherwise provide access to the Services, SPH Media Content, or Third Party User Content on another website or server, for example through framing, mirroring, linking, spidering, scraping or any other technological means (including any technology available in the future), without the prior written permission of SPH Media. </p>

                <p><strong>4.3</strong>              You may for your personal, non-commercial use:</p>

                <p><strong>(a)    </strong>            retrieve and display SPH Media Content on any compatible device owned by you;</p>

                <p><strong>(b)  </strong>             print a single copy of SPH Media Content on paper (but not photocopy them); and</p>

                <p><strong>(c)  </strong>              store such SPH Media Content in electronic form on disk or on a mobile device owned by you (but not on any server or other storage device connected to a network).</p>

                <p><strong>4.4 </strong>             All SPH Media Content are the copyrighted work of SPH Media or its content or software providers, and SPH Media reserves and retains all rights in the SPH Media Content.  Use of some SPH Media Content may be governed by the terms of an accompanying end user license agreement. </p>

                <p><strong>4.5 </strong>             You may not decompile, reverse engineer or otherwise attempt to discover the source code of any SPH Media Content available on the Site or through a Service except under the specific circumstances expressly permitted by law or SPH Media in writing.</p>

                <p><strong>4.6 </strong>         To the maximum extent permitted under applicable law, you irrevocably and unconditionally formally waive in each case for the benefit of each of SPH Media and all its related corporations as well as their respective successors-in-title, assigns, licensees and other designees, and shall not assert against any of them, any and all Moral Rights which may arise whether now or in the future in Singapore and anywhere else in the world, in relation to any work or materials created by you in the course of or in connection with this Agreement.</p>

                For the purposes of these Website Conditions, “Moral Rights” shall include without limitation any and all rights as defined in Part 7 of the Copyright Act 2021 (No. 22 of 2021 of Singapore), as well as other analogous rights arising under any laws anywhere in the world, whether now or in the future.

                <p><strong>5.</strong>     Intellectual Property</p>

                <p><strong>5.1   </strong>           The copyright, patents, trade marks, registered designs and all intellectual property rights in the Services, the Site, and all SPH Media Content, including without limitation the copyright in the compilation of all User Content, shall vest in and remain with SPH Media.</p>

                <p><strong>5.2</strong>              The trademarks, logos and service marks ("Marks") displayed on the Site are the property of SPH Media or other third parties, and all rights to the Marks are expressly reserved by SPH Media or relevant third parties.  You shall not use any Marks without the prior written consent of SPH Media or such third party.  SPH Media and its subsidiaries aggressively enforce their intellectual property rights to the fullest extent of the law.  The name of SPH Media or any other Marks may not be used in any way, including in any advertising or publicity, or as a hyperlink without prior written permission of SPH Media. </p>

                <p><strong>5.3   </strong>           The domain name on which the Site is hosted on is the sole property of SPH Media and you may not use or otherwise adopt a similar name for your own use.</p>

                <p><strong>5.4  </strong>            If you have any questions or concerns about this Agreement or any issues raised in this Agreement or on the Site, please contact us at sphcorp@sph.com.sg .</p>

                <p><strong>6.  </strong>   Online Conduct</p>

                <p><strong>6.1  </strong>            You hereby undertake:</p>

                <p><strong>(a)   </strong>             to comply with this Agreement, and such other notices or guidelines that may be posted on the Site by SPH Media from time to time (which are hereby incorporated by reference into this Agreement);</p>

                <p><strong>(b)  </strong>             not to use any Service or SPH Media Content for any unlawful purpose, and to comply with all applicable laws and regulations, including without limitation, copyright law, the Protection from Online Falsehoods and Manipulation Act 2019 (No. 18 of 2019) and the Protection from Harassment Act (Cap. 256A));</p>

                <p><strong>(c)  </strong>              not to hack into, interfere with, disrupt, disable, over-burden or otherwise impair the proper working of the Site, Services or Servers, which shall include but is not limited to denial-of-service attacks, spoof attacks, session hacking, sniffing, tampering, reverse engineering or reprogramming;</p>

                <p><strong>(d) </strong>              not to use any automated process, use any kind of scripting software or bots or service to access and/or use the Site and/or Services; and</p>

                <p><strong>(e)   </strong>             not to use the Account of another Member at any time, whether with or without his/her permission.</p>

                <p><strong>7.  </strong>   Disclaimers & Limitations</p>

                <p><strong>7.1    </strong>          While we make every effort to ensure that all SPH Media Content displayed on the Site is accurate and complete, we provide the SPH Media Content for informative purposes and on an ‘as is’, ‘as available’ basis only without warranties of any kind either express or implied.  To the fullest extent permissible pursuant to applicable law, SPH Media disclaims all warranties, express or implied, including, but not limited to, implied warranties of satisfactory quality, merchantability or fitness for a particular purpose, compliance with description, or the warranty of non-infringement.  Without limiting the foregoing, SPH Media does not warrant that the functions contained in or access to or use of the Site, Services, SPH Media Content or other content will be timely, secure, uninterrupted or error-free or without omission, that defects will be corrected, or that the Site, Services, SPH Media Content or the Servers are free of viruses or other harmful components, or that the download, installation or use of any SPH Media Content in or with any Computer will not affect the functionality or performance of the Computer.  SPH Media does not warrant or make any representations regarding the use or the results of the use of the SPH Media Content, the Services, the Site or the Servers in terms of their correctness, accuracy, completeness, reliability, or otherwise.  You agree that you (and not SPH Media) shall bear and assume all risk arising out of your use of the Site, Services, SPH Media Content or the Servers including, without limitation the entire cost of all necessary servicing, repair, or correction, including any defect, problem or damage in any Computer.  Without limiting the foregoing, you agree not to hold SPH Media liable for the loss of any of your User Content that is due to any circumstances beyond the control of SPH Media.</p>

                <p><strong>7.2 </strong>             The data and information made available on the Site are of a general nature and do not purport, and shall not in any way be deemed, to constitute an offer or provision of any professional or expert advice.  You should at all times consult a qualified expert or professional adviser to obtain advice and independent verification of the information and data contained herein before acting on it.  Any financial or investment information in the Site are for use in Singapore only and are intended to be for your general information only.  You should not rely upon such information in making any particular investment or other decision which should only be made after consulting with a fully qualified financial adviser.  Such information does not and is not intended to constitute any form of investment advice or any inducement, invitation or recommendation relating to any of the products listed or referred to.  Any arrangement made between you and a third party named on or linked to from these pages is at your sole risk and responsibility.  SPH Media does not sponsor, endorse or promote any financial products, services or information.</p>

                <p><strong>7.3 </strong>             You acknowledge that it is not SPH Media’s policy to exercise editorial control over, and to review, edit or amend any data, information, materials or contents of any User Content, posting, email or any information that may be inserted or made available on the Site by other users of the Services and that SPH Media does not endorse and shall not be responsible for any such content. </p>

                <p><strong>7.4</strong>              You acknowledge and agree that SPH Media does not endorse or recommend, is not an agent, reseller or distributor of, and has no control over Third Party Products, and SPH Media hereby expressly disclaims all liabilities and responsibilities arising in relation to any Third Party Products whether available or advertised via the Site or on Linked Sites.</p>

                <p><strong>7.5   </strong>           You agree that all statements, offers, information, opinions, materials, User Content, and Third Party Products, from other users and from advertisers and other third parties on the Site should be used, accepted and relied upon only with care and discretion and at your own risk, and SPH Media shall not be responsible for any loss, damage or liability incurred by you arising from such use or reliance.</p>

                <p><strong>7.6  </strong>            You also acknowledge and agree that some Services may enable other users to upload User Content to the Site, and that some of these may be offensive, annoying, unlawful, in breach of this Agreement, contain viruses or cause you damage.  While we may remove any such User Content brought to our notice at our sole and absolute discretion, you acknowledge and agree that we cannot be responsible or liable for any User Content, and you agree to exercise access and use User Content only at your own risk and with care and discretion.</p>

                <p><strong>7.7   </strong>           SPH Media may in our sole and absolute discretion, screen, delete and/or remove any Content made available on the Site, including where SPH Media receives a complaint from another user or a notice of intellectual property infringement or other direction for removal, or where in SPH Media’s sole and absolute opinion such Content is in breach of this Agreement, or is illegal or otherwise objectionable, or for any other reason as SPH Media may see fit. SPH Media may use automated systems that analyse any Content made available by you to help detect infringement and abuse, such as spam, malware, and illegal content. SPH Media may delete, archive, block or remove communications by you (including but not limited to feedback, postings, messages and/or chats).</p>

                <p><strong>7.8  </strong>            You agree that:</p>

                <p><strong>(a)  </strong>              SPH Media shall be entitled at any time, at its sole and absolute discretion and without prior notice, to add to, vary, terminate, withdraw or suspend the operation of the whole or any part or feature of the Site or Services without assigning any reason; and</p>

                <p><strong>(b) </strong>              access to or the operation of the Site, Servers and/or the Services may from time to time be interrupted or encounter technical or other problems and may not necessarily continue uninterrupted or without technical or other errors,

                    <span>and in any such event, SPH Media shall not be liable for any loss, liability or damage which may be incurred as a result.</span></p>

                <p><strong>7.9 </strong>             In no event shall SPH Media be liable to you for any damages, losses, expenses, liabilities under any causes of action (whether in contract or tort including, but not limited to negligence, or otherwise) caused through the use of, or the inability to use, the SPH Media Content, User Content, Services, Third Party Products, any Computers, the Site, or any other website.  In the event that SPH Media is liable for damages despite the foregoing provision, you agree that SPH Media’s aggregate liability to you for any and all causes of action in relation to the SPH Media Content, Services, Site, and the Agreement, shall not exceed in aggregate of S$50.00 or the total amount of fees and charges paid and/or payable by you for the Services to SPH Media for the one (1) month period immediately preceding the time such liability arose, whichever is lower.</p>

                <p><strong>7.10 </strong>          Under no circumstances, including, but not limited to, negligence, shall SPH Media be liable for any indirect, special, consequential, loss of profits, loss of business or incidental damages suffered by any party or that result from (A) this Agreement; or (B) the use of, or the inability to use, the SPH Media Content, Services, Site, or any other website, even if SPH Media or a SPH Media authorised representative has been advised of, or should have foreseen, the possibility of such damages. </p>

                <p><strong>7.11 </strong>          To the maximum extent permitted by applicable law, SPH Media disclaims all liability for any direct, incidental or consequential damage or loss suffered by you that may result from the collection, use or disclosure of your Personal Data, including but not limited to any loss of, or any inability to retrieve, any Personal Data, howsoever caused, or any inaccuracy in the Personal Data presented, used or transmitted.</p>

                <p><strong>7.12</strong>           You agree that the above exclusions and limitations of liability are reasonable and enable the Services and the SPH Media Content to be provided by SPH Media at either reasonable costs or no costs to you.</p>

                <p><strong>8. </strong>    Linked Sites</p>

                <p><strong>8.1  </strong>        SPH Media may provide links to other sites ("Linked Sites") that may be of relevance and interest to users.  SPH Media has no control over, and is not responsible for, the content on the Linked Sites or for any damage you may incur from the Linked Sites (including any virus, spyware, malware, worms, errors or damaging material contained in the Linked Sites) or the availability of any content on the Linked Sites, and you hereby irrevocably waive any claim against us with respect to the Linked Sites.</p>

                <p><strong>9.</strong>     Data Use & Privacy</p>

                <p><strong>9.1 </strong>             Please do not submit any personal information or data without first reading our Privacy Policy which explains our data use and privacy practices in detail.</p>

                <p><strong>9.2  </strong>            The foregoing consents provided by you in Clause 1 supplements but does not supersede nor replace any other consents you may have previously provided to SPH Media in respect of your Personal Data, and your consents herein are additional to any rights which we may have at law to collect, use or disclose your Personal Data.</p>

                <p><strong>9.3 </strong>         The Site may provide links to Linked Sites whose data protection and privacy practices may differ from those set forth here. We are not responsible for the content and privacy practices of these other websites and you agree to review and abide by the data protection and privacy notices of those sites.</p>

                <p><strong>9.4 </strong>         The Site uses cookies. A cookie is a small text file which is placed on your device whenever you run or use the Site. These cookies collect information about your use of the Site. We use cookies and other related technologies to facilitate your use of the Site and to improve your experience of the Site. You may change the settings on your device to block the use of cookies. However, if you do choose to block the cookies used in the Site, you may not be able to use certain features and functions of the Site. For more information about the cookies we use, please see our Cookie Policy. </p>

                <p><strong>10.</strong>     Termination</p>

                <p><strong>10.1  </strong>     You agree that SPH Media has the right in its sole and absolute discretion and without notice to restrict, suspend, or terminate your Account and/or your access to all or any part of the Site or Services, without assigning any reason.  Grounds for such termination may include, but are not limited to: (a) a breach of any of the terms under the Agreement; (b) violation of any applicable law; (c) fraudulent, harassing, defamatory, threatening or abusive behaviour; and/or (d) behaviour that is harmful to other users, third parties, or the business interests of SPH Media. Use of your Account, the Site and/or the Services for illegal, fraudulent, harassing, defamatory, threatening or abusive purposes may be referred to law enforcement authorities without notice to you.</p>

                <p><strong>10.2</strong>       Upon suspension or termination of your Account or your access to all or any part of the Site or Services,</p>

                <p><strong>(a)  </strong>         all rights and/or licences granted to you under this Agreement shall immediately terminate;</p>

                <p><strong>(b) </strong>          we may remove or discard from the Services any Content associated with your Account; and</p>

                <p><strong>(c) </strong>          you remain liable for all obligations you may have incurred under this Agreement.</p>

                <p><strong>11.</strong>     Notification of Infringement</p>

                <p><strong>11.1 </strong>          SPH Media reserves the right to investigate notices of copyright, trademark and other intellectual property infringement (“Infringement”) in respect of SPH Media Content, User Content and other material on the Site (“Infringing Material”) and take appropriate action.  If you believe that your work has been used or copied in a way that constitutes Infringement and such Infringement is occurring on the Site, please notify SPH Media in writing immediately in the form and containing the information prescribed by the Singapore Copyright Act (Cap. 63) (“Infringement Notice”).</p>

                <p><strong>11.2</strong>           All Infringement Notices shall be sent to SPH Media addressed as follows:</p>

                The Company Secretary
                1000 Toa Payoh North, News Centre, Singapore 318994
                +65 6319 6319
                +65 6319 8388
                sphlegal@sph.com.sg

                <p><strong>11.3 </strong>          SPH Media will duly consider all Infringement Notices submitted in the above manner.  In return, you agree that you shall not take any legal action or exercise any legal remedy you may have against SPH Media in respect of any Infringing Material, unless you have first given SPH Media the Infringement Notice and sufficient opportunity to remove the Infringing Material, and thereafter SPH Media refuses or fails to remove the Infringing Material within a reasonable time.  Where SPH Media removes the Infringing Material in response to your Infringement Notice, you agree not to exercise and you hereby waive, any right of action against SPH Media under applicable law which you may have in respect of any Infringing Material appearing on the Site prior to such removal by SPH Media.</p>

                <p><strong>11.4 </strong>          You acknowledge and agree that SPH Media has no control and cannot undertake responsibility or liability in respect of Infringing Material appearing on Linked Sites or other third party sites. </p>

                <p><strong>12.</strong>     Content</p>

                <p><strong>12.1 </strong>          SPH Media reserves the right to investigate notices of infringement under the Protection from Online Falsehoods and Manipulation Act 2019 (No. 18 of 2019), including without limitation the Code of Practice for Transparency of Online Political Advertisements and the Code of Practice for Preventing and Countering Abuse of Online Accounts (“Relevant Notices”) received in respect of SPH Media Content, User Content and other material on the Site and take such action as it considers appropriate to comply with the requirements of the said Act and/or Codes.</p>

                <p><strong>12.2 </strong>          If you believe that any SPH Media Content, User Content and other Material on the Site contravenes the said Act or any other written law, including without limitation where (a) there are online political advertisements that are undisclosed or miscategorised; (b) there is a suspected abuse of advertising tools by malicious actors; and/or (c) there are compromised end-user account(s) and/or account(s) that impersonate any "verified" accounts, you may notify SPH Media in writing by completing and submitting the form prescribed by SPH Media from time to time for such purposes, available here.</p>

                <p><strong>12.3 </strong>          If you detect any suspected vulnerabilities or weaknesses in the Services, the Site, systems, resources and/or processes which may potentially affect SPH Media's internet-accessible Services, notify us immediately or as soon as practicable at vul_disclosure@sph.com.sg. You will be prompted to submit a report after emailing us at vul_disclosure@sph.com.sg – kindly follow the instructions provided. SPH Media works with HackerOne, a security platform to receive vulnerability reports safely and securely.   This Clause 12.3 does not authorise or permit the taking of any action which may contravene applicable laws and regulations, including without limitation the Computer Misuse Act (Cap. 50A) and all of SPH Media's rights and remedies are expressly reserved. Without limiting the generality of the foregoing, any exploitation or testing of suspected vulnerabilities or gaining unauthorised access to any computer program or data is prohibited, and you agree and undertake not to:</p>

                <p><strong>(a) </strong>               publish or publicly disclose any suspected vulnerability to any third party;</p>

                <p><strong>(b)  </strong>             deploy destructive, disruptive or other unlawful means to detect vulnerabilities, including without limitation any attacks on physical security, social engineering, denial of service, brute force attacks; and</p>

                <p><strong>(c) </strong>               exploit, test or otherwise use any suspected vulnerability, including without limitation taking any step(s) to access, copy, create, delete, modify, manipulate or download any data or programme, build system backdoor(s), modify system configuration(s), facilitate or share system access.</p>

                <p><strong>13. </strong>    Jurisdictional Issues</p>

                <p><strong>13.1  </strong>         The Site is owned and operated by SPH Media in Singapore.  SPH Media makes no representation that the Contents of the Site are appropriate or available for use in your location.  Those who choose to access the Site from any location do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

                <p><strong>14. </strong>    Indemnity</p>

                <p><strong>14.1 </strong>          You agree to indemnify and hold SPH Media, and its subsidiaries, affiliates, officers, agents, partners, and employees, harmless from all claims, demands, actions, proceedings, liabilities (including statutory liability and liability to third parties), penalties, and costs (including without limitation, legal costs on a full indemnity basis), awards, losses and/or expenses, due to or arising out of:</p>

                <p><strong>(a) </strong>               any use of the Site or any Service;</p>

                <p><strong>(b)    </strong>           your connection to the Site;</p>

                <p><strong>(c)   </strong>             your breach of any terms and conditions of this Agreement;</p>

                <p><strong>(d)  </strong>             your violation of any rights of another person or entity; or</p>

                <p><strong>(e)  </strong>              your breach of any statutory requirement, duty or law.</p>

                <p><strong>15.</strong>     Severability</p>

                <p><strong>15.1 </strong>          If any provision of this Agreement is found to be illegal, void or unenforceable under any law that is applicable hereto or if any court of competent jurisdiction in a final decision so determines, this Agreement shall continue in force save that such provision shall be deemed to be deleted.</p>

                <p><strong>16.</strong>     Relationship of Parties</p>

                <p><strong>16.1 </strong>          Nothing in this Agreement shall constitute or be deemed to constitute an agency, partnership or joint venture between SPH Media and you and neither party shall have any authority to bind the other in any way.</p>

                <p><strong>17. </strong>    Waiver</p>

                <p><strong>17.1 </strong>          No waiver of any rights or remedies by SPH Media shall be effective unless made in writing and signed by an authorised representative of SPH Media.</p>

                <p><strong>17.2 </strong>          A failure by SPH Media to exercise or enforce any rights conferred upon it by this Agreement shall not be deemed to be a waiver or variation of any such rights or operate so as to bar the exercise or enforcement thereof at any subsequent time or times.</p>

                <p><strong>18.   </strong>  Assignment</p>

                <p><strong>18.1   </strong>        You may not assign this Agreement or any of its rights or obligations under these Agreement to any party.</p>

                <p><strong>18.2  </strong>         SPH Media may, by notice in writing posted on its website, assign all of its rights and interests relating to or in connection with this Agreement, the Site and/or Services to a wholly-owned subsidiary of SPH Media (the “Transferee”) without your consent.</p>

                <p><strong>18.3 </strong>          Without prejudice and in addition to the right under Clause 18.2 above, SPH Media may, by notice in writing posted on its website (the “Notification”), transfer and/or novate all of its rights, interests, obligations and liabilities relating to or in connection with this Agreement, the Site and/or Services to the Transferee, and you shall not withhold your consent thereto. For the avoidance of doubt, you hereby consent to (a) the assignment of all rights of SPH Media relating to or in connection with this Agreement, the Site and/or Services to the Transferee, (b) the assumption by the Transferee of all liabilities of SPH Media relating to or in connection with this Agreement, the Site and/or Services, (c) the release of all obligations of SPH Media relating to or in connection with this Agreement, the Site and/or Services, and (d) the Transferee taking the place of SPH Media as if it were named in all documents relating to or in connection with this Agreement, the Site and/or Services as a party thereto in place of SPH Media, in each case with effect from such effective date as may be informed in the Notification.</p>

                <p><strong>19.</strong>     Rights of Third Parties</p>

                <p><strong>19.1  </strong>         Except as provided for in Clause 14, a person or entity who is not a party to this Agreement shall have no right under the Contracts (Rights of Third Parties) Act (Cap. 53B) to enforce any term of this Agreement, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. </p>

                <p><strong>20. </strong>    Force Majeure</p>

                <p><strong>20.1 </strong>          No party shall be liable for any failure to perform its obligations under this Agreement if the failure results from a Force Majeure Event (defined below), provided always that whenever possible, the affected party will resume that obligation as soon as the Force Majeure Event occasioning the failure ceases or abates.</p>

                <p><strong>20.2   </strong>        For purposes of this Agreement, a “Force Majeure Event” is an event which is a circumstance or event beyond the reasonable control of a party which affects the general public in that party’s country or in the territory, and which results in the party being unable to observe or perform on time an obligation under this Agreement.  Such circumstance or event shall include industrial action or labour disputes, civil unrest, war or threat of war, criminal or terrorist acts, government action or regulation, telecommunication or utility failures, power outages, fire, explosion, natural physical disasters, epidemic, quarantine restrictions, and general failure of public transport.</p>

                <p><strong>21.</strong>     Governing Law & Jurisdiction</p>

                <p><strong>21.1 </strong>          This Agreement and all matters relating to your access to, or use of, the Site and the Services shall be governed by and construed in accordance with the laws of Singapore including without limitation the provisions of the Singapore Evidence Act (Chapter 97) and the Electronic Transactions Act (Cap. 88), without giving effect to any principles of conflicts of law.</p>

                <p><strong>21.2  </strong>         You hereby agree to submit to the non-exclusive jurisdiction of the Singapore courts.</p>

                <p><strong>22.   </strong>  Contact Us & Notice</p>

                <p><strong>22.1  </strong>         If you have any feedback or enquiries relating this the Site and/or the Services, and/or this Agreement, please contact us.</p>

                <p><strong>22.2  </strong>         All notices required or permitted to be given hereunder to SPH Media shall be in writing and in the English language and shall be sent by hand or by post to:</p>

                <p>Attn:</p>

                <p>The Company Secretary</p>
                <p>1000 Toa Payoh North, News Centre,</p>

                <p>Singapore 318994</p>

                <p>or, to such other address and person as SPH Media may hereafter specify to you by notice in writing expressed to be for the purposes of this clause.</p>

                <p>(Updated as of 21 March 2022)</p>
            </div>
        );
    }
}

export default tnc;
