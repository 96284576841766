import React, { Component } from "react";
import "./toggleswitch.scss";

class Toggleswitch extends Component {
  constructor() {
    super();
    this.toggleRef = React.createRef();
    this.state = {
      on: false,
    };
  }

  componentDidUpdate(prevProps, nextState) {
    // if (prevProps.count !== this.props.count) {
    //   this.setToggleState();
    // }
  }

  setToggleState = () => {
    if (this.props.on) {
      this.toggleRef.current.checked = true;
    } else if (!this.props.on) {
      this.toggleRef.current.nextSibling.classList.add("addToggleClass");
    }
  };

  handleChange = (event) => {
    let isChecked = event.target.checked;
    isChecked === true
      ? event.target.nextSibling.classList.contains("addToggleClass")
      : event.target.nextSibling.classList.remove("addToggleClass");
    this.setState({
      on: isChecked,
    });
    this.props.onChange(event);
  };

  clik =(event)=>{

   let isChecked = event.target.checked;
    isChecked === true
      ? event.target.nextSibling.classList.contains("addToggleClass")
      : event.target.nextSibling.classList.remove("addToggleClass");
    this.setState({
      on: isChecked,
    });
    this.props.onChange(event);
  }

  render() {
    return (
      <>
        <div id="outercontainer" className="custom-control mr-4 custom-switch">
          <input
            ref={this.toggleRef}
            type="checkbox"
            className="custom-control-input"
            id={this.props.description}
            value={this.props.description}
            // onChange={(e) => this.handleChange(e)}
            onClick={(e)=>this.clik(e)}
            defaultChecked={this.props.on}
          />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor={this.props.description}
            key={this.props.description}
          ></label>
        </div>
        <label className={`on-label mb-0 ${this.props.on === false ? "active" : "inactive"}`}>
          Off
        </label>
        <label className={`off-label mb-0 ${this.props.on === false ? "inactive" : "active"}`}>
          On
        </label>
      </>
    );
  }
}

export default Toggleswitch;
