
import FileSaver from 'file-saver';


const dowloadPdf = async (url) => {
    // http://s3-newslink.com:4566/s3-archival-newslink-assets-local/reports/2022/202232/tmpUser_Detail_Report_02_March_2022.pdf
    const fileUrl = url.split("/")
    const fileName = fileUrl[fileUrl.length - 1]

    FileSaver.saveAs(url, fileName)
}
export { dowloadPdf }
