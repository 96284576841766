const LOGIN_USER = "LOGIN_USER";
const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
const GET_PACKAGES = "GET_PACKAGES";
const GET_PACKAGES_ERROR = "GET_PACKAGES_ERROR";
const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
const GET_ANNOUNCEMENTS_ERROR = "GET_ANNOUNCEMENTS_ERROR";
const GET_CONTENT_TYPES = "GET_CONTENT_TYPES";
const GET_CONTENT_TYPES_ERROR = "GET_CONTENT_TYPES_ERROR";
const GET_PUBLICATIONS = "GET_PUBLICATIONS";
const GET_PUBLICATIONS_ERROR = "GET_PUBLICATIONS_ERROR";
const GET_TRANSACTION_STATUS = "GET_TRANSACTION_STATUS"
const GET_TOP_SEARCH_QUERIES = "GET_TOP_SEARCH_QUERIES";
const GET_TOP_SEARCH_QUERIES_ERROR = "GET_TOP_SEARCH_QUERIES_ERROR";
const SEARCH_CONTENT = "SEARCH_CONTENT";
const SEARCH_CONTENT_ERROR = "SEARCH_CONTENT_ERROR";
const SEARCH_AUTOCOMPLETE_CONTENT = "SEARCH_AUTOCOMPLETE_CONTENT";
const SEARCH_AUTOCOMPLETE_CONTENT_ERROR = "SEARCH_AUTOCOMPLETE_CONTENT_ERROR";
const ADMIN_SEARCH_CONTENT = "ADMIN_SEARCH_CONTENT";
const ADMIN_SEARCH_CONTENT_ERROR = "ADMIN_SEARCH_CONTENT_ERROR";
const GET_USER_TOKEN = "GET_USER_TOKEN";
const GET_USER_PREFERENCES = "GET_USER_PREFERENCES";
const GET_USER_PREFERENCES_ERROR = "GET_USER_PREFERENCES_ERROR";
const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES";
const UPDATE_USER_PREFERENCES_ERROR = "UPDATE_USER_PREFERENCES_ERROR";
const GET_USER_SUBSCRIPTION = "GET_USER_SUBSCRIPTION";
const GET_USER_SUBSCRIPTION_ERROR = "GET_USER_SUBSCRIPTION_ERROR";
const GET_USER_USAGE = "GET_USER_USAGE";
const GET_USER_USAGE_ERROR = "GET_USER_USAGE_ERROR";
const GET_USER_USAGE_SEARCH = "GET_USER_USAGE_SEARCH";
const GET_USER_USAGE_SEARCH_ERROR = "GET_USER_USAGE_SEARCH_ERROR";
const GET_USER_PROFILE = "GET_USER_PROFILE";
const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
const START_LOADER = "START_LOADER";
const START_LOGIN_LOADER = "START_LOGIN_LOADER";
const STOP_LOGIN_LOADER = "STOP_LOGIN_LOADER";
const CLEAR_ERROR = "CLEAR_ERROR";
const CLEAR__ERROR = "CLEAR__ERROR";
const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
const GET_PRELOADS = "GET_PRELOADS";
const SET_CONTENT_TYPE = "SET_CONTENT_TYPE";
const SET_REFERER = "SET_REFERER";
const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
const SET_SUBSCRIPTION_ERROR = "SET_SUBSCRIPTION_ERROR";
const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
const START_TRANS_LOADER = "START_TRANS_LOADER";
const STOP_TRANS_LOADER = "STOP_TRANS_LOADER";
const PAYMENT_STATUS = "PAYMENT_STATUS";
const PAYMENT_STATUS_ERROR = "PAYMENT_STATUS_ERROR";
const SEARCH_OBJECT = "SEARCH_OBJECT";
const BADGE_DATA = "BADGE_DATA";
const DATE_OBJECT = "DATE_OBJECT";
const SHOW_LOGIN_POPUP = "SHOW_LOGIN_POPUP";
const ACTIVATE_SUBSCRIPTION = "ACTIVATE_SUBSCRIPTION";
const ACTIVATE_SUBSCRIPTION_ERROR = "ACTIVATE_SUBSCRIPTION_ERROR";
const PAGE_NO="PAGE_NO";
const GENERATEKEY="GENERATEKEY";
const GENERATEKEY_ERROR="GENERATEKEY_ERROR";
const GET_TOKEN_FROM_SESSIONID="GET_TOKEN_FROM_SESSIONID";
const GET_TOKEN_FROM_SESSIONID_ERROR="GET_TOKEN_FROM_SESSIONID_ERROR";
const AD_AND_ADV_BADGE ="AD_AND_ADV_BADGE";
const HIDE_LINK_FLAG ="HIDE_LINK_FLAG";
const UPDATE_PREFERENCE_LOADER="UPDATE_PREFERENCE_LOADER";
const DURATIONS="DURATIONS";
const DOWNLOAD_BAR_INFO ="DOWNLOAD_BAR_INFO";
const SUB_OPTIONS_DIGITALTYPES="SUB_OPTIONS_DIGITALTYPES";
// ADMIN FLOW

const START_ADMIN_LOADER = "START_ADMIN_LOADER";
const GET_USER_LIST = "GET_USER_LIST";
const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";
const GET_ADMIN_PRELOADS = "GET_ADMIN_PRELOADS";
const GET_ADMIN_PRELOADS_ERROR = "GET_ADMIN_PRELOADS_ERROR";
const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT";
const UPDATE_ANNOUNCEMENT_ERROR = "UPDATE_ANNOUNCEMENT_ERROR";
const GET_HERITAGE_PHOTO = "GET_HERITAGE_PHOTO";
const GET_HERITAGE_PHOTO_ERROR = "GET_HERITAGE_PHOTO_ERROR";
const GET_USER_TYPE = "GET_USER_TYPE";
const GET_USER_TYPE_ERROR = "GET_USER_TYPE_ERROR";
const GET_ACCOUNT_STATUS = "GET_ACCOUNT_STATUS";
const GET_ACCOUNT_STATUS_ERROR = "GET_ACCOUNT_STATUS_ERROR";
const SUBSCRIPTION = "SUBSCRIPTION";
const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";
const GET_ANNOUNCEMENTS_BY_LANGUAGE = "GET_ANNOUNCEMENTS_BY_LANGUAGE";
const GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR = "GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR";
const FIND_USER = "FIND_USER";
const FIND_USER_ERROR = "FIND_USER_ERROR";
const LOGIN_ADMIN = "LOGIN_ADMIN";
const LOGIN_ADMIN_ERROR = "LOGIN_ADMIN_ERROR";
const CLEAR_ADMIN_ERROR = "CLEAR_ADMIN_ERROR";
const GET_SORT_BY_ERROR = "GET_SORT_BY_ERROR";
const GET_SORT_BY = "GET_SORT_BY";
const GET_INFO_DATA = "GET_INFO_DATA";
const GET_INFO_DATA_ERROR = "GET_INFO_DATA_ERROR";
const FIND_USAGE_DETAIL = "FIND_USAGE_DETAIL";
const FIND_USAGE_DETAIL_ERROR = "FIND_USAGE_DETAIL_ERROR";
const GET_INFO_FOR_ANNOUNCEMENT = "GET_INFO_FOR_ANNOUNCEMENT";
const GET_INFO_FOR_ANNOUNCEMENT_ERROR = "GET_INFO_FOR_ANNOUNCEMENT_ERROR";
// const SUSPEND_USER_ACCOUNT = "SUSPEND_USER_ACCOUNT";
// const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";
const CLEAR_ADMIN_LOGIN_ERROR = "CLEAR_ADMIN_LOGIN_ERROR";
const UPDATE_STATUS = "UPDATE_STATUS";
const CREATE_AGENCY = "CREATE_AGENCY";
const CREATE_AGENCY_ERROR = "CREATE_AGENCY_ERROR";
const GET_AGENCY = "GET_AGENCY";
const GET_AGENCY_ERROR = "GET_AGENCY_ERROR";
const GET_AGENCY_PUBLISHER = "GET_AGENCY_PUBLISHER";
const GET_AGENCY_PUBLISHER_ERROR = "GET_AGENCY_PUBLISHER_ERROR";
const GET_AGENCY_PUBLISHER_BY_ID = "GET_AGENCY_PUBLISHER_BY_ID";
const GET_AGENCY_PUBLISHER_BY_ID_ERROR = "GET_AGENCY_PUBLISHER_BY_ID_ERROR";
const CREATE_AGENCY_PUBLISHER = "CREATE_AGENCY_PUBLISHER";
const CREATE_AGENCY_PUBLISHER_ERROR = "CREATE_AGENCY_PUBLISHER_ERROR";
const UPDATE_AGENCY_PUBLISHER = "UPDATE_AGENCY_PUBLISHER";
const UPDATE_AGENCY_PUBLISHER_ERROR = "UPDATE_AGENCY_PUBLISHER_ERROR";
const UPDATE_AGENCY_PUBLISHER_STATUS = "UPDATE_AGENCY_PUBLISHER_STATUS";
const UPDATE_AGENCY_PUBLISHER_STATUS_ERROR = "UPDATE_AGENCY_PUBLISHER_STATUS_ERROR";
const UPDATE_AGENCY = "UPDATE_AGENCY";
const UPDATE_AGENCY_ERROR = "UPDATE_AGENCY_ERROR";
const CMP_PREFERENCE_LIST_ERROR = "CMP_PREFERENCE_LIST_ERROR";
const CMP_PREFERENCE_LIST = "CMP_PREFERENCE_LIST";
const GET_CONTENT_TYPES_ALLOWED = "GET_CONTENT_TYPES_ALLOWED";
const DOWNLOAD_DOC = "DOWNLOAD_DOC";
const DOWNLOAD_DOC_ERROR = "DOWNLOAD_DOC_ERROR";
const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";
const GET_SELECTED_ROWS = "GET_SELECTED_ROWS";
const GET_USERDETAIL_DATA_BY_USERID = "GET_USERDETAIL_DATA_BY_USERID";
const GET_USERDETAIL_DATA_BY_USERID_ERROR = "GET_USERDETAIL_DATA_BY_USERID_ERROR";
const UPDATE_USER_ACCOUNT_INFO = "UPDATE_USER_ACCOUNT_INFO";
const UPDATE_USER_ACCOUNT_INFO_ERROR = "UPDATE_USER_ACCOUNT_INFO_ERROR";
const GET_AGENCY_INFO = "GET_AGENCY_INFO";
const GET_AGENCY_INFO_ERROR = "GET_AGENCY_INFO_ERROR";
const GET_AGENCY_ROYALTY_REPORT = "GET_AGENCY_ROYALTY_REPORT";
const GET_AGENCY_ROYALTY_REPORT_ERROR = "GET_AGENCY_ROYALTY_REPORT_ERROR";
const GET_AGENCY_USAGE = "GET_AGENCY_USAGE";
const GET_AGENCY_USAGE_ERROR = "GET_AGENCY_USAGE_ERROR";
const GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION = "GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION";
const GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR = "GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR";
const UPDATE_AGENCY_PACKAGE = "UPDATE_AGENCY_PACKAGE";
const UPDATE_AGENCY_PACKAGE_ERROR = "UPDATE_AGENCY_PACKAGE_ERROR";

const CREATE_USER_ACCOUNT = "CREATE_USER_ACCOUNT";
const CREATE_USER_ACCOUNT_ERROR = "CREATE_USER_ACCOUNT_ERROR";
const SUBSCRIPTION_STATUS = "SUBSCRIPTION_STATUS";
const SUBSCRIPTION_STATUS_ERROR = "SUBSCRIPTION_STATUS_ERROR";
const CALCULATE_FEE_BY_PACKAGE = "CALCULATE_FEE_BY_PACKAGE";
const CALCULATE_FEE_BY_PACKAGE_ERROR = "CALCULATE_FEE_BY_PACKAGE_ERROR";
const CALCULATE_FEE_BY_SUBSCIPTION_FEE = "CALCULATE_FEE_BY_SUBSCIPTION_FEE";
const CALCULATE_FEE_BY_SUBSCIPTION_FEE_ERROR = "CALCULATE_FEE_BY_SUBSCIPTION_FEE_ERROR";
const USAGE_DETAIL_BY_ORDER_ID = "USAGE_DETAIL_BY_ORDER_ID";
const USAGE_DETAIL_BY_ORDER_ID_ERROR = "USAGE_DETAIL_BY_ORDER_ID_ERROR"
const UPDATE_SUBSCIPTION = "UPDATE_SUBSCIPTION";
const UPDATE_SUBSCIPTION_ERROR = "UPDATE_SUBSCIPTION_ERROR";
const STOP_ADMIN_LOADER = "STOP_ADMIN_LOADER";
const UPDATE_EXTERNAL_SUBSCRIPTION = "UPDATE_EXTERNAL_SUBSCRIPTION";
const UPDATE_EXTERNAL_SUBSCRIPTION_ERROR = "UPDATE_EXTERNAL_SUBSCRIPTION_ERROR";
const CREATE_EXTERNAL_SUBSCRIPTION = "CREATE_EXTERNAL_SUBSCRIPTION";
const CREATE_EXTERNAL_SUBSCRIPTION_ERROR = "CREATE_EXTERNAL_SUBSCRIPTION_ERROR";
const GET_PACKAGE_CONFIGURATION = "GET_PACKAGE_CONFIGURATION";
const GET_PACKAGE_CONFIGURATION_ERROR = "GET_PACKAGE_CONFIGURATION_ERROR";
const UPDATE_PACKAGES = "UPDATE_PACKAGES";
const UPDATE_PACKAGES_ERROR = "UPDATE_PACKAGES_ERROR";
const GET_ALL_ADMIN_USERS = "GET_ALL_ADMIN_USERS";
const GET_ALL_ADMIN_USERS_ERROR = "GET_ALL_ADMIN_USERS_ERROR";
const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";
const UPDATE_ADMIN_USER_ERROR = "UPDATE_ADMIN_USER_ERROR";
const GET_MAILINGLIST_RESPONSE = "GET_MAILINGLIST_RESPONSE";
const GET_MAILINGLIST_ERROR = "GET_MAILINGLIST_ERROR";
const UPDATE_MAILINGLIST_RESPONSE = "UPDATE_MAILINGLIST_RESPONSE";
const UPDATE_MAILINGLIST_ERROR = "UPDATE_MAILINGLIST_ERROR";
const CREATE_ADMIN_USER = "CREATE_ADMIN_USER";
const CREATE_ADMIN_USER_ERROR = "CREATE_ADMIN_USER_ERROR";
const DELETE_ADMIN_USER = "DELETE_ADMIN_USER";
const DELETE_ADMIN_USER_ERROR = "DELETE_ADMIN_USER_ERROR";
const DELETE_CONTENT = "DELETE_CONTENT";
const DELETE_CONTENT_ERROR = "DELETE_CONTENT_ERROR";
const CLEAR_ADMIN_USER_ERROR = "CLEAR_ADMIN_USER_ERROR";
const CLEAR_UPDATE_ADMIN_ACCOUNT_INFO = "CLEAR_UPDATE_ADMIN_ACCOUNT_INFO";
const SHOW_LOGOUT_POPUP = "SHOW_LOGOUT_POPUP";
const CLOSE_LOGOUT_POPUP = "CLOSE_LOGOUT_POPUP";
const ADD_CATEGORY_RESPONSE = "ADD_CATEGORY_RESPONSE";
const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";
const ADD_PACKAGE_USER_TYPE_RESPONSE = "ADD_PACKAGE_USER_TYPE_RESPONSE";
const ADD_PACKAGE_USER_TYPE_ERROR = "ADD_PACKAGE_USER_TYPE_ERROR";
const GET_PACKAGE_BY_CODE_RESPONSE = "GET_PACKAGE_BY_CODE_RESPONSE";
const GET_PACKAGE_BY_CODE_ERROR = "GET_PACKAGE_BY_CODE_ERROR";
const UPDATE_CONFIG_PACKAGE_RESPONSE = "UPDATE_CONFIG_PACKAGE_RESPONSE";
const UPDATE_CONFIG_PACKAGE_ERROR = "UPDATE_CONFIG_PACKAGE_ERROR";
const CREATE_PACKAGE_RESPONSE = "CREATE_PACKAGE_RESPONSE";
const CREATE_PACKAGE_ERROR = "CREATE_PACKAGE_ERROR";
const SEAMLESS_LOGIN_EMAIL = "SEAMLESS_LOGIN_EMAIL";
const SEAMLESS_LOGIN_EMAIL_ERROR = "SEAMLESS_LOGIN_EMAIL_ERROR";

const ENQUIRY_REQUEST_INFO = "ENQUIRY_REQUEST_INFO";
const ENQUIRY_REQUEST_INFO_ERROR = "ENQUIRY_REQUEST_INFO_ERROR";
const IMAGE_ENQUIRY_REQUEST = "IMAGE_ENQUIRY_REQUEST";
const IMAGE_ENQUIRY_REQUEST_ERROR = "IMAGE_ENQUIRY_REQUEST_ERROR";
const CLEAR_IMAGE_ENQUIRY_REQUEST = "CLEAR_IMAGE_ENQUIRY_REQUEST";
const GET_PUBLICATION = "GET_PUBLICATION";
const GET_PUBLICATION_ERROR = "GET_PUBLICATION_ERROR";
const FETCH_ACTIVE_USER = "FETCH_ACTIVE_USER";
const FETCH_ACTIVE_USER_ERROR = "FETCH_ACTIVE_USER_ERROR";
const SEND_BLAST_EMAIL = "SEND_BLAST_EMAIL";
const SEND_BLAST_EMAIL_ERROR = "SEND_BLAST_EMAIL_ERROR";
const REMOVE_TOKEN = "REMOVE_TOKEN";
const REMOVE_TOKEN_ERROR = "REMOVE_TOKEN_ERROR";
const REMOVE_TOKEN_BY_USERNAME = "REMOVE_TOKEN_BY_USERNAME";
const REMOVE_TOKEN_BY_USERNAME_ERROR = "REMOVE_TOKEN_BY_USERNAME_ERROR";
const UPDATE_SYS_CONFIG = "UPDATE_SYS_CONFIG";
const UPDATE_SYS_CONFIG_ERROR = "UPDATE_SYS_CONFIG_ERROR";
const DELETE_KEY = "DELETE_KEY";
const DELETE_KEY_ERROR = "DELETE_KEY_ERROR";
const DELETE_HASH_KEY = "DELETE_HASH_KEY";
const DELETE_HASH_KEY_ERROR = "DELETE_HASH_KEY_ERROR";
const CLEAR_SELECT_SUB = "CLEAR_SELECT_SUB";
const CREATE_NEW_SUBSCRIPTION = "CREATE_NEW_SUBSCRIPTION";
const CREATE_NEW_SUBSCRIPTION_ERROR = "CREATE_NEW_SUBSCRIPTION_ERROR";
const SYNC_AUTH_SUCCESS = "SYNC_AUTH_SUCCESS";
const SYNC_AUTH_ERROR = "SYNC_AUTH_ERROR";
const CLEAR_DEVSETTINGS = "CLEAR_DEVSETTINGS";
const DELETE_WILD_CARD_KEY = "DELETE_WILD_CARD_KEY";
const DELETE_WILD_CARD_KEY_ERROR = "DELETE_WILD_CARD_KEY_ERROR";
const LOGOUT_LOADER="LOGOUT_LOADER";
const PAGE_VIEW_DATA="PAGE_VIEW_DATA";
const CUSTOM_VIEW_DATA ='CUSTOM_VIEW_DATA';
export {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_PACKAGES,
  GET_PACKAGES_ERROR,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_ERROR,
  GET_CONTENT_TYPES,
  GET_CONTENT_TYPES_ERROR,
  GET_PUBLICATIONS,
  GET_PUBLICATIONS_ERROR,
  GET_TRANSACTION_STATUS,
  GET_TOP_SEARCH_QUERIES,
  GET_TOP_SEARCH_QUERIES_ERROR,
  GET_USER_TOKEN,
  GET_USER_PREFERENCES,
  GET_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES_ERROR,
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_ERROR,
  GET_USER_USAGE,
  GET_USER_USAGE_ERROR,
  GET_USER_USAGE_SEARCH,
  GET_USER_USAGE_SEARCH_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  START_LOADER,
  START_LOGIN_LOADER,
  CLEAR_LOGIN_ERROR,
  CLEAR__ERROR,
  CLEAR_ERROR,
  GET_PRELOADS,
  SET_CONTENT_TYPE,
  SET_REFERER,
  SET_SUBSCRIPTION,
  SEARCH_CONTENT,
  SEARCH_CONTENT_ERROR,
  SEARCH_AUTOCOMPLETE_CONTENT,
  SEARCH_AUTOCOMPLETE_CONTENT_ERROR,
  START_ADMIN_LOADER,
  GET_USER_LIST,
  GET_USER_LIST_ERROR,
  GET_ADMIN_PRELOADS,
  GET_ADMIN_PRELOADS_ERROR,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_ERROR,
  GET_HERITAGE_PHOTO,
  GET_HERITAGE_PHOTO_ERROR,
  GET_USER_TYPE,
  GET_USER_TYPE_ERROR,
  GET_ACCOUNT_STATUS,
  GET_ACCOUNT_STATUS_ERROR,
  SUBSCRIPTION,
  SUBSCRIPTION_ERROR,
  GET_ANNOUNCEMENTS_BY_LANGUAGE,
  GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR,
  FIND_USER,
  FIND_USER_ERROR,
  LOGIN_ADMIN,
  LOGIN_ADMIN_ERROR,
  CLEAR_ADMIN_ERROR,
  GET_SORT_BY_ERROR,
  GET_SORT_BY,
  GET_INFO_DATA,
  GET_INFO_DATA_ERROR,
  FIND_USAGE_DETAIL,
  FIND_USAGE_DETAIL_ERROR,
  GET_INFO_FOR_ANNOUNCEMENT,
  GET_INFO_FOR_ANNOUNCEMENT_ERROR,
  UPDATE_STATUS,
  UPDATE_STATUS_ERROR,
  CLEAR_ADMIN_LOGIN_ERROR,
  CREATE_AGENCY,
  CREATE_AGENCY_ERROR,
  UPDATE_AGENCY,
  UPDATE_AGENCY_ERROR,
  GET_AGENCY,
  GET_AGENCY_ERROR,
  GET_AGENCY_PUBLISHER,
  GET_AGENCY_PUBLISHER_ERROR,
  GET_AGENCY_PUBLISHER_BY_ID,
  GET_AGENCY_PUBLISHER_BY_ID_ERROR,
  CREATE_AGENCY_PUBLISHER,
  CREATE_AGENCY_PUBLISHER_ERROR,
  UPDATE_AGENCY_PUBLISHER,
  UPDATE_AGENCY_PUBLISHER_ERROR,
  UPDATE_AGENCY_PUBLISHER_STATUS,
  UPDATE_AGENCY_PUBLISHER_STATUS_ERROR,
  CMP_PREFERENCE_LIST,
  CMP_PREFERENCE_LIST_ERROR,
  GET_CONTENT_TYPES_ALLOWED,
  DOWNLOAD_DOC,
  DOWNLOAD_DOC_ERROR,
  SET_SEARCH_TYPE,
  GET_SELECTED_ROWS,
  GET_USERDETAIL_DATA_BY_USERID,
  GET_USERDETAIL_DATA_BY_USERID_ERROR,
  UPDATE_USER_ACCOUNT_INFO,
  UPDATE_USER_ACCOUNT_INFO_ERROR,
  GET_AGENCY_INFO,
  GET_AGENCY_INFO_ERROR,
  GET_AGENCY_ROYALTY_REPORT,
  GET_AGENCY_ROYALTY_REPORT_ERROR,
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_ERROR,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATUS_ERROR,
  CALCULATE_FEE_BY_PACKAGE,
  CALCULATE_FEE_BY_PACKAGE_ERROR,
  CALCULATE_FEE_BY_SUBSCIPTION_FEE,
  CALCULATE_FEE_BY_SUBSCIPTION_FEE_ERROR,
  GET_AGENCY_USAGE,
  GET_AGENCY_USAGE_ERROR,
  START_TRANS_LOADER,
  STOP_TRANS_LOADER,
  USAGE_DETAIL_BY_ORDER_ID,
  USAGE_DETAIL_BY_ORDER_ID_ERROR,
  UPDATE_SUBSCIPTION,
  UPDATE_SUBSCIPTION_ERROR,
  STOP_ADMIN_LOADER,
  GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION,
  GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR,
  UPDATE_AGENCY_PACKAGE_ERROR,
  UPDATE_AGENCY_PACKAGE,
  UPDATE_EXTERNAL_SUBSCRIPTION,
  UPDATE_EXTERNAL_SUBSCRIPTION_ERROR,
  CREATE_EXTERNAL_SUBSCRIPTION,
  CREATE_EXTERNAL_SUBSCRIPTION_ERROR,
  GET_PACKAGE_CONFIGURATION,
  GET_PACKAGE_CONFIGURATION_ERROR,
  UPDATE_PACKAGES,
  UPDATE_PACKAGES_ERROR,
  GET_ALL_ADMIN_USERS,
  GET_ALL_ADMIN_USERS_ERROR,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_ERROR,
  GET_MAILINGLIST_ERROR,
  GET_MAILINGLIST_RESPONSE,
  UPDATE_MAILINGLIST_ERROR,
  UPDATE_MAILINGLIST_RESPONSE,
  CREATE_ADMIN_USER,
  CREATE_ADMIN_USER_ERROR,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_ERROR,
  CLEAR_ADMIN_USER_ERROR,
  CLEAR_UPDATE_ADMIN_ACCOUNT_INFO,
  SHOW_LOGOUT_POPUP,
  CLOSE_LOGOUT_POPUP,
  ADD_CATEGORY_RESPONSE,
  ADD_CATEGORY_ERROR,
  ADD_PACKAGE_USER_TYPE_RESPONSE,
  ADD_PACKAGE_USER_TYPE_ERROR,
  GET_PACKAGE_BY_CODE_RESPONSE,
  GET_PACKAGE_BY_CODE_ERROR,
  UPDATE_CONFIG_PACKAGE_ERROR,
  UPDATE_CONFIG_PACKAGE_RESPONSE,
  CREATE_PACKAGE_ERROR,
  CREATE_PACKAGE_RESPONSE,
  PAYMENT_STATUS,
  PAYMENT_STATUS_ERROR,
  SEAMLESS_LOGIN_EMAIL,
  SEAMLESS_LOGIN_EMAIL_ERROR,
  ENQUIRY_REQUEST_INFO,
  ENQUIRY_REQUEST_INFO_ERROR,
  IMAGE_ENQUIRY_REQUEST,
  IMAGE_ENQUIRY_REQUEST_ERROR,
  CLEAR_IMAGE_ENQUIRY_REQUEST,
  GET_PUBLICATION,
  GET_PUBLICATION_ERROR,
  SEARCH_OBJECT,
  FETCH_ACTIVE_USER,
  FETCH_ACTIVE_USER_ERROR,
  SEND_BLAST_EMAIL,
  SEND_BLAST_EMAIL_ERROR,
  REMOVE_TOKEN,
  REMOVE_TOKEN_ERROR,
  REMOVE_TOKEN_BY_USERNAME,
  REMOVE_TOKEN_BY_USERNAME_ERROR,
  UPDATE_SYS_CONFIG,
  UPDATE_SYS_CONFIG_ERROR,
  BADGE_DATA,
  DELETE_KEY,
  DELETE_KEY_ERROR,
  DELETE_HASH_KEY_ERROR,
  DELETE_HASH_KEY,
  DATE_OBJECT,
  SHOW_LOGIN_POPUP,
  CLEAR_SELECT_SUB,
  ACTIVATE_SUBSCRIPTION,
  ACTIVATE_SUBSCRIPTION_ERROR,
  CREATE_NEW_SUBSCRIPTION,
  CREATE_NEW_SUBSCRIPTION_ERROR,
  PAGE_NO,
  GENERATEKEY,
  GENERATEKEY_ERROR,
  SET_SUBSCRIPTION_ERROR,
  SYNC_AUTH_ERROR,
  SYNC_AUTH_SUCCESS,
  CLEAR_DEVSETTINGS,
  DELETE_WILD_CARD_KEY,
  DELETE_WILD_CARD_KEY_ERROR,
  LOGOUT_LOADER,
  GET_TOKEN_FROM_SESSIONID,
  GET_TOKEN_FROM_SESSIONID_ERROR,
  AD_AND_ADV_BADGE,
  HIDE_LINK_FLAG,
  UPDATE_PREFERENCE_LOADER,
  STOP_LOGIN_LOADER,
  PAGE_VIEW_DATA,
  CUSTOM_VIEW_DATA,
  DURATIONS,
  DOWNLOAD_BAR_INFO,
  SUB_OPTIONS_DIGITALTYPES,
  DELETE_CONTENT,
  DELETE_CONTENT_ERROR,
  ADMIN_SEARCH_CONTENT,
  ADMIN_SEARCH_CONTENT_ERROR
};
