import React, { Fragment } from "react";
import Backdrop from "../Backdrop/backdrop";
import "./lightbox.scss";
import crossIcon from "../../../assets/images/icon-close.svg";

const Lightbox = ({ closeIcon = true, ...props }) => {
  const style = closeIcon ? {
    background: "#242f39"
  } : {
    background: "transparent"
  }
  return (
    <Fragment>
      <Backdrop clicked={props.closeModal}></Backdrop>
      <div
        className={`CustomModals ${props.customClass !== undefined ? props.customClass : ""
          }`}
        style={props.style}
      >
        <div className="close-icon-container" style={style}>
          <div className="close-icon">
            <img
              onClick={() => props.onClick()}
              src={crossIcon}
              className="icon-img"
              alt="crossIcon"
            />
          </div>
        </div>
        <div className="modal-content">{props.children}</div>
      </div>
    </Fragment>
  );
};

Lightbox.defaultProps = {
  closeIcon: true
}

export default Lightbox;
