import React, { Component } from 'react'
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { RECAPTCHA_SITE_KEY } from '../../../../config/constants/index';
import "../CreateAccount/CreateAccount.scss"
import { startLoader, getInfoData, getSubscriptionStatus, updateSubscription, calculateFeeByPkg, calculateFeeBySubscriptionFee, clearAdminError, updateExternalSubscription, createExternalSubscription } from '../../../../redux/actions/adminAction';
import { ButtonElement, DatePickerElement, Spinner, Modal } from '../../../UI//index';
import ErrorPopup from '../../Popup/ErrorPopup';
import { getDate, getOneYearDate } from '../../../../helpers/common';
import { Checkbox, SelectElement } from '../../../UI//index';
import { debounce, startCase, camelCase } from "lodash";
import 'react-toastify/dist/ReactToastify.css';
import './css/externalSubscription.scss'

export class externalSubscription extends Component {
    constructor(props) {
        super(props)

        this.state = {
            minDate: getDate(),
            from: new Date(),
            to: new Date(),
            activeSubscription: [],
            subscriptionStatus: [],
            paymentModeArr: [],
            pkgDecription: "",
            subsciptionFlag: "",
            disallowPdf: true,
            disallowArticle: true,
            gst: 0,
            total: 0,
            success: false,
            errdata: [],
            failure: false,
            message: "",
            externalSubscription: {
                // to: "",
                subscriptionFee: 0,
                status: "PENDING",
                userName: "",
                subscriptionId: 0,
                articleRetrievalFee: 0,
                // pay_ref: "",
                // payment: 1,
                externalPackage: "",
                pdfRetrievalFee: 0,
                // from: "",
                userType: "",
                externalAgency: "",
                gst: 0,
                userId: 0,
                dateRange: {
                    requestDateFrom: "",
                    requestDateTo: ""
                },
                paymentInfoDTO: {
                    paymentMode: 1,
                    paymentRef: ""
                }
            },
            newExtSub: false,
            externalAgencies: [],
            externalPackages: [],
            currentGst: 0,
            btnDisable: false
        }
    }

    componentDidMount = async () => {
        const { externalSubscription } = this.state;
        this.props.startLoader();
        let newSubscription = this.props.location?.state?.newExtSub;
        this.setState({
            newExtSub: newSubscription
        })
        if ((this.props.location && this.props.location?.state?.extSubscription) || this.props.location?.state?.subscriptionDetail) {

            let subscriptionDetail = this.props.location?.state?.subscriptionDetail
            let extSubscription = this.props.location?.state?.extSubscription
            externalSubscription.userName = subscriptionDetail.login;
            externalSubscription.userType = subscriptionDetail.type;
            externalSubscription.userId = subscriptionDetail.oid;
            externalSubscription.subscriptionId = extSubscription.subscriptionId;
            externalSubscription.status = (extSubscription.status);
            if (!newSubscription) {
                externalSubscription.dateRange["requestDateTo"] = extSubscription.to;
                externalSubscription.dateRange["requestDateFrom"] = extSubscription.from;
                externalSubscription.externalPackage = extSubscription.extSubPackage;
                externalSubscription.status = (extSubscription.status);
                externalSubscription.subscriptionFee = Number(extSubscription.fee);
                externalSubscription.externalAgency = extSubscription.agency;
                externalSubscription.paymentInfoDTO["paymentMode"] = Number(extSubscription.payBy)
                externalSubscription.paymentInfoDTO["paymentRef"] = extSubscription.paymentRefNo !== "NA" ? (extSubscription.paymentRefNo) : "";
                externalSubscription.articleRetrievalFee = extSubscription.articleRetrievalFee !== "NA" ? Number(extSubscription.articleRetrievalFee) : 0;
                externalSubscription.pdfRetrievalFee = Number(extSubscription.sphPdfRetrievalFee);
                externalSubscription.sphmPdfRetrievalFee = extSubscription.sphmPdfRetrievalFee !== "" || extSubscription.sphmPdfRetrievalFee !== "NA" ? Number(extSubscription.sphmPdfRetrievalFee) : 0;

                await this.props.calculateFeeBySubscriptionFee(extSubscription.fee)
                externalSubscription.gst = this.props?.calculateFeeRes?.subscription.gst;
                externalSubscription.total = this.props?.calculateFeeRes?.subscription.total;
            }


        }
        await this.props.getInfoData();
        await this.props.getSubscriptionStatus();
        let basePackageVO = Object.keys(this.props.infoData.packages.basePackageVO)
        let allSubscriptionPackage = this.props.infoData.packages.basePackageVO
        let newSubscriptionArr = basePackageVO.map((subscriptionPackage) => {
            return ({ key: allSubscriptionPackage[subscriptionPackage].description, value: subscriptionPackage })
        })

        let subscription_Status = Object.keys(this.props.subscriptionStatus.status)
        let subscription_Status_Arr = [];
        subscription_Status_Arr = subscription_Status.map((status) => {
            return ({ key: startCase(camelCase(status)), value: status })
        })
        let paymentMode = Object.keys(this.props.infoData?.paymentType)
        let paymentModeArr = [];
        paymentModeArr = paymentMode.map((mode) => {
            return ({ key: this.props.infoData.paymentType[mode], value: mode })
        })
        if (this.props?.infoData?.sysConfig) {
            this.setState({
                currentGst: this.props?.infoData?.sysConfig?.current_gst ? (Number(this.props?.infoData?.sysConfig?.current_gst) * 100).toFixed(0) : 0
            })
        }
        this.setState({
            activeSubscription: newSubscriptionArr,
            subscriptionStatus: subscription_Status_Arr,
            paymentModeArr: paymentModeArr

        })
        let to_Date = newSubscription ? getOneYearDate() : new Date(this.props?.location?.state?.extSubscription?.to);
        let from_Date = newSubscription ? new Date() : new Date(this.props?.location?.state?.extSubscription?.from);

        let modifiedFromdate = new Date(from_Date.getTime() - (from_Date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];

        let modifiedTodate = new Date(to_Date.getTime() - (to_Date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];

        externalSubscription.dateRange["requestDateTo"] = modifiedTodate;
        externalSubscription.dateRange["requestDateFrom"] = modifiedFromdate;

        let externalAgencyPackages = this.props.infoData?.externalAgencyPackages
        let externalAgencies = Object.keys(externalAgencyPackages).map(agency => ({ key: agency, value: agency }))
        let externalPackage = Object.keys(externalAgencyPackages).map(agency => ({ key: externalAgencyPackages[agency][0].description, value: externalAgencyPackages[agency][0].extPackage }))
        externalAgencies.unshift({
            key: 'Select Agency',
            value: ''
        })
        externalPackage.unshift({
            key: 'Select Package',
            value: ''
        })
        this.setState({
            externalSubscription,
            to: to_Date,
            from: from_Date,
            externalAgencies: externalAgencies,
            externalPackages: externalPackage
        })
    }

    onSubmit = (e) => {
        this.setState({
            btnDisable: true
        })

        e.preventDefault();
        this.recaptcha.execute();
    }
    onResolved = async () => {
        const { externalSubscription, newExtSub } = this.state
        const response = this.recaptcha.getResponse();
        externalSubscription.captchaToken = response;
        let tempExtSubStatus = externalSubscription.status
        externalSubscription.status = this.props.subscriptionStatus.status[externalSubscription.status]
        externalSubscription.paymentInfoDTO["paymentRef"] = externalSubscription.paymentInfoDTO["paymentRef"] ? externalSubscription.paymentInfoDTO["paymentRef"].trim() : externalSubscription.paymentInfoDTO.paymentRef
        this.props.startLoader();
        if (!newExtSub) {
            await this.props.clearAdminError();
            await this.props.startLoader();
            await this.props.updateExternalSubscription(externalSubscription)
            externalSubscription.status = tempExtSubStatus;
            this.setState({
                externalSubscription
            })
            window.scrollTo(0, 0)
            if (this.props?.updateExternalSubscriptionResponse) {
                this.setState({
                    success: true,
                    message: this.props.updateExternalSubscriptionResponse.message,
                    errdata: []
                })
            } else if (this.props.updateExternalSubscriptionResErrorMessages) {
                this.setState({
                    success: true,
                    errdata: this.props.updateExternalSubscriptionResErrorMessages,
                    message: ""
                })
            }

        } else {
            await this.props.clearAdminError();
            await this.props.startLoader();
            await this.props.createExternalSubscription(externalSubscription)
            externalSubscription.status = tempExtSubStatus;
            this.setState({
                externalSubscription
            })
            if (this.props?.createExternalSubscriptionRes) {
                this.setState({
                    success: true,
                    message: this.props.createExternalSubscriptionRes.message,
                    errdata: []
                })
            } else if (this.props?.createExternalSubscriptionErrorRes) {
                this.setState({
                    success: true,
                    errdata: this.props.createExternalSubscriptionErrorRes,
                    message: ""
                })
            }
        }

    }

    onfromChange = (date) => {
        const { externalSubscription } = this.state;
        let dt = new Date(date);
        let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        externalSubscription.dateRange["requestDateFrom"] = modifieddate;
        this.setState({
            externalSubscription,
            from: date
        });
    };

    ontoChange = (date) => {
        const { externalSubscription } = this.state;
        let dt = new Date(date);
        let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        externalSubscription.dateRange["requestDateTo"] = modifieddate;
        this.setState({
            externalSubscription,
            to: date
        });
    };

    setPkg = async (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription[field] = regex.test(value) || value === "" ? value : externalSubscription[field];
        } else {
            let agency = externalSubscription.externalAgency
            if (agency !== "") {
                if (this.props.infoData?.externalAgencyPackages[agency][0].extPackage === value) {
                    externalSubscription[field] = value;

                } else {
                    // externalSubscription["externalPackage"] = "";
                    alert("Please select correct package for agency")

                }
            }
            // else {
            //     alert("Please select agency 1st")
            // }
        }
        this.setState({
            externalSubscription,
        });
    }
    setAgency = async (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription[field] = regex.test(value) || value === "" ? value : externalSubscription[field];
        } else {

            let extPackage = externalSubscription.externalPackage
            if (extPackage !== "") {
                if (extPackage === this.props.infoData?.externalAgencyPackages[value][0].extPackage) {
                    externalSubscription[field] = value;
                } else {
                    alert("Please select correct agency for package")
                    externalSubscription["externalPackage"] = "";
                    externalSubscription["externalAgency"] = ""
                }
            } else {
                externalSubscription[field] = value;
            }
        }
        this.setState({
            externalSubscription,
        });

    }
    setSubscriptionChange = (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription[field] = regex.test(value) || value === "" ? value : externalSubscription[field];
        } else {
            externalSubscription[field] = value;
        }
        this.setState({
            externalSubscription,
        });
        this.calculateFee(value)
    }
    calculateFee = debounce(async (value) => {
        const { externalSubscription } = this.state;
        await this.props.calculateFeeBySubscriptionFee(value)
        externalSubscription.gst = this.props?.calculateFeeRes?.subscription.gst;
        externalSubscription.total = this.props?.calculateFeeRes?.subscription.total;
        this.setState({
            externalSubscription,
        })
    }, 500);
    onArticleRetrivalFeeChange = (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription[field] = regex.test(value) || value === "" ? value : externalSubscription[field];
        } else {
            externalSubscription[field] = value;
        }
        this.setState({
            externalSubscription,
        });
        this.updateArticleFee(value)
    }
    updateArticleFee = debounce(async (value) => {
        const { pkgDecription } = this.state;
        if (pkgDecription) {
            let str = pkgDecription;
            if (str.includes("Article retrieval fee:")) {
                let part1 = str.split(":");
                let subSplit = "";
                if (part1[0].includes("Article retrieval fee")) {
                    subSplit = part1[1].split(" ")
                } else {
                    subSplit = part1[2].split(" ")
                }
                let str1 = "Article retrieval fee: " + subSplit[1];
                let str2 = "Article retrieval fee: $" + value;
                let newStr = str.replace(str1, str2)
                this.setState({
                    pkgDecription: newStr
                })
            }
        }
    }, 500);

    onChangepdfRetrievalFee = (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription[field] = regex.test(value) || value === "" ? value : externalSubscription[field];
        } else {
            externalSubscription[field] = value;
        }
        this.setState({
            externalSubscription,
        });
        this.updatePDFREtrivaleFee(value)
    }
    updatePDFREtrivaleFee = debounce(async (value) => {
        const { pkgDecription } = this.state;
        if (pkgDecription) {
            let str = pkgDecription;
            if (str.includes("Full Page PDF retrieval fee:")) {
                let part1 = str.split(":");
                let subSplit = "";
                if (part1[1].includes("Full Page PDF retrieval fee")) {
                    subSplit = part1[2].split(" ")
                } else {
                    subSplit = part1[3].split(" ")
                }
                let str1 = "Full Page PDF retrieval fee: " + subSplit[1];
                let str2 = "Full Page PDF retrieval fee: $" + value;
                let newStr = str.replace(str1, str2)
                this.setState({
                    pkgDecription: newStr
                })
            }
        }
    }, 500);

    setPaymentInfoDTO = (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription.paymentInfoDTO[field] = regex.test(value) || value === "" ? value : externalSubscription.paymentInfoDTO[field];
        } else {
            externalSubscription.paymentInfoDTO[field] = value;
        }
        this.setState({
            externalSubscription,
        });
    }
    setBooleanValue1 = (value, field, number = false) => {
        let fvalue = value
        this.setState({
            disallowArticle: fvalue
        });
    }
    setBooleanValue = (value, field, number = false) => {
        let fvalue = value
        this.setState({
            disallowPdf: fvalue
        });
    }

    setSubscriptionInfoDTO = (value, field, number = false) => {
        const { externalSubscription } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalSubscription[field] = regex.test(value) || value === "" ? value : externalSubscription[field];
        } else {
            externalSubscription[field] = value;
        }
        this.setState({
            externalSubscription,
        });
    }
    onClose = async (e) => {
        const { externalSubscription } = this.state;
        this.setState({
            btnDisable: false

        })
        if (this.props?.updateExternalSubscriptionResponse?.message || this.props?.createExternalSubscriptionRes?.message) {
            this.setState({
                success: false
            })
            await this.props.history.push({
                pathname: '/admin/userdetail-subscription',
                searchObject: {
                    userId: externalSubscription.userId
                }
            })
        } else {
            this.setState({
                success: false
            })
        }
    }
    closePopup = async () => {
        this.setState({
            success: false,
            btnDisable: false
        })
    }
    goback = async (e) => {
        e.preventDefault();
        const { externalSubscription } = this.state;
        await this.props.history.push({
            pathname: '/admin/userdetail-subscription',
            searchObject: {
                userId: externalSubscription.userId
            }
        })
    }
    render() {
        const { from, to, newExtSub, subscriptionStatus, externalSubscription, success, message, errdata, disallowPdf, disallowArticle, externalAgencies, externalPackages, currentGst, paymentModeArr, btnDisable } = this.state;
        const { loading } = this.props;
        const canCreateExternal = this.props.location?.state?.canCreateExternal
        const canCreateInactiveExternal = this.props.location?.state?.canCreateInactiveExternal
        return (
            <>
                {
                    success &&
                    <Modal
                        customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
                        onClick={this.closePopup}
                        errorFlag={success}
                    >
                        <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
                    </Modal>
                }
                {loading && <div className="text-center loader">
                    {/* <Spinner animation="border" variant="primary" /> */}
                    <Spinner primary={true} />
                </div>}
                <div>
                    <form className="container-fluid card"
                        noValidate onSubmit={(e) => this.onSubmit(e)}>
                        <h2 className="heading heading-bold">External Subscription</h2>
                        <div className='row'>
                            <div className='col-md-3 col-6 col-md-4 pb-2'><label>Username <span className="requiredStar">*</span></label>
                            </div>
                            <div className='col-md-6 col-6 col-md-4 pb-2'>
                                <b>{externalSubscription.userName}</b></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-6 col-md-4 pb-2'><label>User Type <span className="requiredStar">*</span></label>
                            </div>
                            <div className='col-md-6 col-6 col-md-4 pb-2'>
                                <b>{externalSubscription.userType}</b></div>
                        </div>

                        <div className='row '>
                            <div className="col-lg-3 col-6 col-md-4 pb-2"><label>From <span className="requiredStar">*</span></label></div>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <div className="pb-2 pt-2 dates">
                                    <DatePickerElement
                                        minDate={!newExtSub ? "" : new Date()}
                                        onChange={this.onfromChange}
                                        className={!newExtSub ? "w-100 disabled" : "w-100 "}
                                        value={from}
                                        disabled={!newExtSub ? true : false}
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-3 col-6 col-md-4 pb-2"><label>To<span className="requiredStar">*</span></label></div>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <div className="pb-2 pt-2 dates">
                                    <DatePickerElement
                                        minDate={from}
                                        onChange={this.ontoChange}
                                        className="w-100"
                                        value={to}
                                    // maxDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>

                        {newExtSub && <><div className='row'>
                            <div className='col-lg-3 col-6 col-md-4 pb-2'><label className=''>External Agency <span className="requiredStar">*</span></label></div>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                {externalAgencies.length > 0 && <SelectElement
                                    defaultValue={externalSubscription.externalAgency}
                                    // value={externalSubscription.externalAgency}
                                    options={externalAgencies}
                                    className="p-1"
                                    onChange={(e) => this.setAgency(e.target.value, "externalAgency")}
                                />}
                            </div>
                        </div>
                            <div className='row'>
                                <div className='col-lg-3 col-6 col-md-4 pb-2'><label className=''>Package <span className="requiredStar">*</span></label></div>
                                <div className="col-lg-4 col-6 col-md-4 pb-2">


                                    {externalPackages.length > 0 && <SelectElement
                                        defaultValue={externalSubscription.externalPackage}
                                        options={externalPackages}
                                        className="p-1"
                                        onChange={(e) => this.setPkg(e.target.value, "externalPackage")}
                                    />}
                                </div>
                            </div>
                        </>}
                        {!newExtSub &&
                            <><div className='row'>
                                <div className="col-lg-3 col-6 col-md-4 pb-2">
                                    <label className=''>External Agency<span className="requiredStar">*</span></label>
                                </div>
                                <div className="col-lg-4 col-6 col-md-4 pb-2">
                                    <input
                                        placeholder="Article Retrival Fee"
                                        value={externalSubscription.externalAgency}
                                        type="text"
                                        disabled={true}
                                        className="p-1 pl-4 w-100 disabled "
                                    />
                                </div>
                            </div>
                                <div className='row'>
                                    <div className="col-lg-3 col-6 col-md-4 pb-2">
                                        <label className=''>Package<span className="requiredStar">*</span></label>
                                    </div>
                                    <div className="col-lg-4 col-6 col-md-4 pb-2">
                                        <input
                                            placeholder="Article Retrival Fee"
                                            value={externalSubscription.externalPackage}
                                            type="text"
                                            disabled={true}
                                            className="p-1 pl-4 w-100 disabled"
                                        />
                                    </div>
                                </div>
                            </>}
                        <div className='row'>
                            <div className="col-lg-3 col-6 col-md-4 pb-2">
                                <label className=''>Subscription Fee<span className="requiredStar">*</span></label>
                            </div>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <input
                                    placeholder="Subscription Fee"
                                    value={externalSubscription.subscriptionFee}
                                    onChange={(e) => this.setSubscriptionChange(Number(e.target.value), "subscriptionFee")}
                                    type="number"
                                    className="p-1  w-100"
                                    min="0"
                                    maxLength="6"
                                    required="true"
                                />
                            </div>
                            <div className="col-lg-5 col-6 col-md-4 pb-2">
                                {/* <label></label> */}
                                <span className=''>GST({currentGst}%):<b>${externalSubscription.gst}</b></span><br />
                                <span className=''>Total (fee+GST):<b>${externalSubscription.total}</b></span>

                            </div>
                            {/* <div className="col-lg-2 col-6 col-md-4 pb-2">
                                <span className=''>GST({currentGst}%):<b>${externalSubscription.gst}</b></span>
                            </div>
                            <span className=''>Total (fee+GST):<b>{externalSubscription.total}</b></span> */}
                        </div>
                        <div className='row'>
                            <div className="col-lg-3 col-6 col-md-4 pb-2">
                                <label className=''>Article Retrival Fee <span className="requiredStar">*</span></label>
                            </div>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <input
                                    placeholder="Article Retrival Fee"
                                    value={externalSubscription.articleRetrievalFee}
                                    onChange={(e) => this.onArticleRetrivalFeeChange(Number(e.target.value), "articleRetrievalFee")}
                                    type="number"
                                    min="0"
                                    disabled={disallowArticle}
                                    className={!disallowArticle ? "p-1 pl-4 w-100" : "p-1 pl-4 w-100 disabled"}
                                />
                            </div>
                            <div className="col-lg-5 col-md-4 pb-2 nl_article_access">
                                <span className=''>
                                    <Checkbox
                                        checked={disallowArticle} onChange={(e) =>
                                            this.setBooleanValue1(e.target.checked, "disallowArticle")}
                                    />
                                    <span className="margin-left-35">Disallow Pay per Use-Article </span>
                                </span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-3 col-6 col-md-4 pb-2">
                                <label className=''>SPH PDF Retrival Fee <span className="requiredStar">*</span></label>
                            </div>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <input
                                    placeholder="SPH PDF retrival Fee"
                                    value={externalSubscription.pdfRetrievalFee}
                                    onChange={(e) => this.onChangepdfRetrievalFee(Number(e.target.value), "pdfRetrievalFee")}
                                    type="number"
                                    disabled={disallowPdf}
                                    className={!disallowPdf ? "p-1 pl-4 w-100" : "p-1 pl-4 w-100 disabled"}
                                    min="0"
                                />
                            </div>
                            <div className="col-lg-5 col-md-4 pb-2 nl_article_access ">
                                <span className=''>
                                    <Checkbox
                                        checked={disallowPdf} onChange={(e) =>
                                            this.setBooleanValue(e.target.checked, "disallowPdf")}
                                    />
                                    <span className="margin-left-35">Disallow Pay per Use-Pdf Access-PDF Access </span>

                                </span>
                            </div>
                        </div>

                        <div className='row'>
                            <label className=' nl_status ' >Pay By<span className="requiredStar">*</span></label>
                            {paymentModeArr && paymentModeArr.map((p) => (
                                <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                                    <Checkbox key={p.key} labelValue={p.value}
                                        checked={Number(p.value) === externalSubscription.paymentInfoDTO["paymentMode"]}
                                        onChange={(e) =>
                                            this.setPaymentInfoDTO(Number(p.value), "paymentMode")}
                                    />
                                    <span className="margin-left-35">{p.key} </span>
                                </div>))}
                        </div>
                        <div className='row'>
                            <div className="col-lg-3  pb-2 cheque-mt">
                                <label className=' ' >Cheque/Purchase Order/Bank Draft No/Others </label>
                            </div>
                            <div className="col-lg-4  pb-2">
                                <input
                                    placeholder=""
                                    value={externalSubscription.paymentInfoDTO["paymentRef"]}
                                    onChange={(e) => this.setPaymentInfoDTO((e.target.value), "paymentRef")}
                                    type="text"
                                    className="search-input p-1 pl-4 w-100 "
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div> <label className=' nl_status' >Status <span className="requiredStar">*</span></label></div>
                            {subscriptionStatus && subscriptionStatus.filter(p => canCreateExternal || (p.key !== 'Active' || !canCreateInactiveExternal)).map((p) => (
                                <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                                    <Checkbox key={p.key} labelValue={p.value}
                                        checked={p.value === externalSubscription.status}
                                        onChange={(e) =>
                                            this.setSubscriptionInfoDTO(p.value, "status")}
                                    />
                                    <span className="margin-left-35">{p.key} </span>
                                </div>))}
                        </div>
                        <div>
                            <div className="text-right pt-3 pb-5">
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 "
                                    disabled={btnDisable}
                                >
                                    <span className="button-label">{!newExtSub ? "Update" : "Create"}</span>
                                </ButtonElement>

                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 "
                                    onClick={(e) => this.goback(e)}
                                >
                                    <span className="button-label">Back</span>
                                </ButtonElement>

                            </div>
                        </div>
                    </form>
                    <Recaptcha
                        size="invisible"
                        ref={(ref) => (this.recaptcha = ref)}
                        onResolved={this.onResolved}
                        sitekey={RECAPTCHA_SITE_KEY}
                    />
                </div>
            </>
        )
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    infoData: state.adminReducer.infoData,
    subscriptionStatus: state.adminReducer.subscriptionStatus,
    pkgInfo: state.adminReducer.pkgInfo,
    calculateFeeRes: state.adminReducer.calculateFeeRes,
    calculateFeeResError: state.adminReducer.calculateFeeResError,
    updateExternalSubscriptionResponse: state.adminReducer.updateExternalSubscriptionResponse,
    updateExternalSubscriptionResErrorMessages: state.adminReducer.updateExternalSubscriptionResErrorMessages,
    createExternalSubscriptionRes: state.adminReducer.createExternalSubscriptionRes,
    createExternalSubscriptionErrorRes: state.adminReducer.createExternalSubscriptionErrorRes
});

export default connect(mapDispatchStatetoProps, {
    startLoader,
    getInfoData,
    getSubscriptionStatus,
    updateSubscription,
    calculateFeeByPkg,
    calculateFeeBySubscriptionFee,
    clearAdminError,
    updateExternalSubscription,
    createExternalSubscription
})(externalSubscription);
