import React, { Component } from "react";
import { connect } from "react-redux";
import {
  userLogin,
  startLoader,
  generateKey,
} from "../../redux/actions/loginAction";
import { showLoginpopup } from "../../redux/actions/applicationsAction";
import reactReferer from "react-referer";
import { publicIp } from "public-ip";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Modal, Spinner } from "../UI";
import Popup from "../Admin/Popup/Popup";
import { getDomain } from "../../helpers/common";
class Seamless extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        userId: "",
        password: "",
        ipAddress: "",
      },
      referrer: reactReferer.referer(),
      submit: false,
      stopLoader: true,
    };
  }

  async componentDidMount() {
    const url = this.props.location.search.split("?");
    let keysvalues = url[1].split("&");
    let loginkey;
    for (let x of keysvalues) {
      if (x.includes("loginKey=")) {
        loginkey = x.split("loginKey=");
      }
    }
    const { userDetails, referrer } = this.state;
    userDetails.loginKey = loginkey[1];
    try {
      userDetails.ipAddress = await publicIp();
    } catch (error) {
      try {
        const res = await axios.get("https://api.ipify.org?format=json");
        if (res.data.ip) {
          userDetails.ipAddress = res.data.ip;
        }
      } catch (error) {
        userDetails.ipAddress = "";
      }
    }

    this.setState({
      userDetails,
    });
    if (referrer || userDetails.ipAddress) {
      this.onResolved();
    } else {
      this.setState({
        stopLoader: false,
      });
      await this.props.showLoginpopup(true);
    }
  }

  onResolved = async () => {
    this.props.startLoader();
    const { userDetails, referrer } = this.state;

    await this.props.generateKey(userDetails.ipAddress);
    if (this.props.generateKeys) {
      const refererUrl = getDomain(referrer);
      let toEncrypt;
      if (refererUrl && refererUrl[0] && userDetails.ipAddress) {
        toEncrypt = refererUrl[0] + "__" + userDetails.ipAddress;
      } else if (refererUrl && refererUrl[0] && !userDetails.ipAddress) {
        toEncrypt = refererUrl[0];
      } else if (userDetails.ipAddress && !refererUrl) {
        toEncrypt = userDetails.ipAddress;
      }
      var encrypted = CryptoJS.AES.encrypt(toEncrypt, this.props.generateKeys);
      let stringofenc = encrypted.toString();

      let baseString = window.btoa(stringofenc);

      await this.props.userLogin(
        { ip: userDetails?.ipAddress },
        userDetails?.loginKey,
        baseString
      );
      this.setState({
        stopLoader: false,
      });
      if (this.props?.successResponse) {
        localStorage.setItem("seamlessFlag", true);
        localStorage.setItem("token", "logged-in");

        this.props.history.push("/");
        window.location.reload();
      }
      if (this.props?.login_error) {
        this.props.history.push("/");
      }
    }
  };

  closeModal = async () => {
    this.props.history.push("/");
    window.location.reload();
  };

  render() {
    const { stopLoader } = this.state;
    return (
      <div style={{ height: "500px" }}>
        {stopLoader && <Spinner primary={true} transparent={true}></Spinner>}
        {this.props?.generateKeysError && (
          <Modal
            className="timout-window"
            customClass={"forgot-password-modal"}
            closeModal={this.iscloselogoutPopUp}
            onClick={() => this.closeModal()}
          >
            <Popup
              titles={this.props?.generateKeysError}
              onClose={this.closeModal}
              {...this.props}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  isLoading: state.loginReducer.isLoading,
  getIp: state.applicationReducer.getIp,
  generateKeys: state.loginReducer.generateKeys,
  successResponse: state.loginReducer.successResponse,
  generateKeysError: state.loginReducer.generateKeysError,
  login_error: state.loginReducer.login_error,
});
export default connect(mapDispatchStatetoProps, {
  userLogin,
  startLoader,
  generateKey,
  showLoginpopup,
})(Seamless);
