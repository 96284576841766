/* eslint-disable import/no-anonymous-default-export */
import {
    START_LOGIN_LOADER,
    LOGIN_USER,
    LOGIN_USER_ERROR,
    CLEAR_LOGIN_ERROR,
    GET_USER_TOKEN,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    SHOW_LOGOUT_POPUP,
    CLOSE_LOGOUT_POPUP,
    GENERATEKEY,
    GENERATEKEY_ERROR,
    LOGOUT_LOADER,
    STOP_LOGIN_LOADER
} from "../../config/constants/action-constants";
const initialState = {
    isLoading: false,
    logout_popup:false,
    isLogutLoader:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case START_LOGIN_LOADER:
            return {
                ...state,
                isLoading: true
            }
        case LOGIN_USER:
            return {
                ...state,
                successResponse: action.payload.data,
                statusLogin:action.payload.status,
                isLoading: false
            }
        case GET_USER_TOKEN:
            return {
                ...state,
                tokens: action.payload.data
            }
        case LOGIN_USER_ERROR:
            return {
                ...state,
                login_error: action.payload.data,
                error_for_okta: action.payload.data,
                isLoading: false
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                successResponse: action.payload.data,

                isLoading: false
            }
            case RESET_PASSWORD_ERROR:
                return {
                  ...state,
                  user_error: action.payload,
                  isLoading: false
                };
        case CLEAR_LOGIN_ERROR:
            return {
                login_error: null,
                isLoading: false,
            }
        case SHOW_LOGOUT_POPUP:
            return {
                logout_popup: true
            }
        case CLOSE_LOGOUT_POPUP:
            return {
                logout_popup: false
            }
        case GENERATEKEY:
            return {
                ...state,
                generateKeys: action.payload.data,
                isLoading: false
            }
        case GENERATEKEY_ERROR:
            return {
                ...state,
                generateKeysError: action.payload.data,
                isLoading: false
            }
        case LOGOUT_LOADER:
            return {
                ...state,
                isLogutLoader: action.payload.isLogutLoader,
            };
        case STOP_LOGIN_LOADER:
            return {
                ...state,
                isLoading:false
            }
        default:
            return {
                ...state,
            };
    }
}
