import React, { Component } from 'react';
import { ButtonElement, DataTable } from '../../../UI';
import "./userdetail-subscription.scss";
import "./css/viewSubscription.scss";
import { connect } from 'react-redux';
import { startLoader, getInfoData } from '../../../../redux/actions/adminAction';

class ViewSubscription extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subscription: [],
            extSubscription: [],
            activeSubscription: false,
            packageToUpdate: "",
            usageSummary: []
        }
    }

    componentDidMount = async () => {
        let userSubscriptionDetail = this.props && this.props.userSubscriptionDetail;
        let packageToUpdate = userSubscriptionDetail.canUpgrade ? userSubscriptionDetail.canUpgradeTo : "";
        let externalSubscription = this.props && this.props.userSubscriptionDetail.externalSubscription;
        let usageInfo = this.props && this.props.userSubscriptionDetail.usageSummary
        await this.props.startLoader()
        await this.props.getInfoData()
        if (this.props && this.props.userSubscriptionDetail.subscription) {
            let subscriptionArr = this.props && this.props.userSubscriptionDetail.subscription
            let data1 = []
            let activeSubscription = subscriptionArr.find(sub => {
                if (sub.status === "ACTIVE") {
                    return sub
                }
            })

            this.setState({
                activeSubscription: activeSubscription,
                packageToUpdate: packageToUpdate
            })
            for (let sub of subscriptionArr) {
                let actions = (userSubscriptionDetail.canCreateExternal && userSubscriptionDetail.canCreateInactiveExternal && (sub.status === "ACTIVE" || sub.status === "INACTIVE")) || (sub.status === "INACTIVE" && userSubscriptionDetail.canCreateInactiveExternal) || (sub.status === "ACTIVE" && userSubscriptionDetail.canCreateExternal) ? <span className="nl_link" onClick={() => this.handleExtSubscription(sub, true)}>New External Subscription </span> : ""
                let subPackage = new Date(new Date(sub.to).toDateString()) >= new Date(new Date().toDateString()) ? <span className='nl_link' onClick={() => this.handleUserSubscription(sub, false, "")} {...this.props}>{sub.subscriptionPkgName}</span> : sub.subscriptionPkgName
                let subscription = {
                    package: subPackage,
                    status: sub.status === "PENDING" ? <span className='pendingSub'>{sub.status}</span> : sub.status === "INACTIVE" ?
                        <span className='inactiveSub'>{sub.status}</span> : sub.status === "ACTIVE" ? <span className='activeSub'>{sub.status}</span> : sub.status === "CLOSE" ?
                            <span className='closeSub'>{sub.status}</span> : sub.status === "UPGRADED" ? <span className='upgraded'>{sub.status}</span> :
                                sub.status === "DELETED" ? <span className='closeSub'>{sub.status}</span> : sub.status === "SUSPENDED" ? <span className='suspended'>{sub.status}</span> : <span className=''>{sub.status}</span>,
                    from: sub.from,
                    to: sub.to,
                    "totalFee(fee + gst)": "$" + sub.total,
                    payBy: this.props?.infoData?.paymentType[sub.payment],
                    paymentRefNo: sub.paymentRefNo !== "null" ? sub.paymentRefNo : "NA",

                }
                if (actions) {
                    subscription.action = actions
                }
                data1.push(subscription)
            }
            var flag = false;
            for (let d of data1) {
                if (d.action) {
                    flag = true
                }
            }
            if (flag) {
                for (let d of data1) {
                    if (!d.action) {
                        d.action = " "
                    }
                }
            }

            this.setState({
                subscription: data1
            })
        }
        if (externalSubscription) {
            let data1 = [];
            for (let sub of externalSubscription) {
                // let subPackage = !(sub.status === "DELETED") && new Date(sub.to) >= new Date() ? < span className="nl_link" onClick={() => this.handleExtSubscription(sub, false)
                // } {...this.props}> {sub.extSubPkgName}</span > : sub.extSubPkgName
                let subPackage = < span className="nl_link" onClick={() => this.handleExtSubscription(sub, false)
                } {...this.props}> {sub.extSubPkgName}</span >
                let subscription = {
                    package: subPackage,
                    status: sub.status === "PENDING" ? <span className='pendingSub'>{sub.status}</span> : sub.status === "INACTIVE" ?
                        <span className='inactiveSub'>{sub.status}</span> : sub.status === "ACTIVE" ? <span className='activeSub'>{sub.status}</span> : sub.status === "CLOSE" ?
                            <span className='closeSub'>{sub.status}</span> : sub.status === "UPGRADED" ? <span className='upgraded'>{sub.status}</span> :
                                sub.status === "DELETED" ? <span className='closeSub'>{sub.status}</span> : sub.status === "SUSPENDED" ? <span className='suspended'>{sub.status}</span> : <span className=''>{sub.status}</span>,
                    from: sub.from,
                    to: sub.to,
                    fee: "$" + sub.fee,
                    payBy: this.props?.infoData?.paymentType[sub.payBy],
                    paymentRefNo: sub.paymentRefNo !== "null" ? sub.paymentRefNo : "NA"
                }
                data1.push(subscription)
            }
            this.setState({
                extSubscription: data1
            })
        }


        if (usageInfo && usageInfo.length > 0) {
            let data1 = [];
            for (let usage of usageInfo) {
                let usageDate = {
                    "Source Type": usage.sourceType,
                    "Agency": usage.agency,
                    "No.Of Access": usage.noOfAccess,
                    "Total amount(with gst)": usage.totalFee
                }
                data1.push(usageDate)
            }
            this.setState({
                usageSummary: data1
            })
        }
    }
    handleUserSubscription = async (userSub, flag, packageUpdate) => {
        this.props.history.push({
            pathname: "/admin/userSubscription",
            state: {
                extSubscription: userSub,
                newSubscription: flag,
                subscriptionDetail: this.props.userSubscriptionDetail.accountInfo,
                packageUpdate: packageUpdate

            }
        })
    }
    handleExtSubscription = async (extenalSub, flag) => {

        this.props.history.push({
            pathname: "/admin/externalSubscription",
            state: {
                newExtSub: flag,
                extSubscription: extenalSub,
                subscriptionDetail: this.props.userSubscriptionDetail.accountInfo,
                canCreateExternal: this.props.userSubscriptionDetail.canCreateExternal,
                canCreateInactiveExternal: this.props.userSubscriptionDetail.canCreateInactiveExternal
            }
        })
    }
    render() {
        const { subscription, extSubscription, activeSubscription, packageToUpdate, usageSummary } = this.state
        let packageUpdate = ""
        if (packageToUpdate !== "") {
            packageUpdate = packageToUpdate
        }

        let activeUserSubascription = activeSubscription ? activeSubscription : null
        const { userSubscriptionDetail } = this.props

        return (
            <div className="col-12 col-md-12 users-container">

                {subscription.length > 0 && <div>
                    <h3 className="heading">Subscriptions</h3>
                    {subscription.length > 0 && <div className="users-table" key={Math.random()}>
                        <DataTable className="table-responsive" checkbox={false} columns={subscription[0]} data={subscription} />
                    </div>}
                </div>}
                {userSubscriptionDetail.canCreate && <ButtonElement
                    type="button"
                    variant="contained"
                    btnType="background-orange button-label"
                    className="d-inline-block mr-2 mb-1 float-right"
                    // onClick={this.onSubmit}
                    onClick={() => this.handleUserSubscription(null, true, packageUpdate)}
                >
                    <span className="button-label">CREATE NEW SUBSCRIPTION</span>
                </ButtonElement>}
                {userSubscriptionDetail.canUpgrade && <ButtonElement
                    type="button"
                    variant="contained"
                    btnType="background-orange button-label"
                    className="d-inline-block mr-2 mb-1 float-right"
                    // onClick={this.onSubmit}
                    onClick={() => this.handleUserSubscription(activeUserSubascription, false, packageUpdate)}
                >
                    <span className="button-label">UPGRADE</span>
                </ButtonElement>}
                {
                    extSubscription.length > 0 && <div>
                        <h3 className="heading">External Subscription Info</h3>
                        <div className="users-table" key="extSub">
                            {extSubscription.length > 0 && <DataTable className="table-responsive" checkbox={false} columns={extSubscription[0]} data={extSubscription} />}
                        </div>
                    </div>
                }

                {
                    !this.props?.userSubscriptionDetail?.usageSummary[0]?.msg &&
                    <div>
                        <h3 className="heading">Usage Info</h3>
                        <div className="users-table">
                            {usageSummary && usageSummary.length > 0 && <DataTable className="table-responsive" checkbox={false} columns={usageSummary[0]} data={usageSummary} />}
                        </div>
                    </div>
                }
            </div >

        );
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    infoData: state.adminReducer.infoData
});

export default connect(mapDispatchStatetoProps, {
    startLoader,
    getInfoData,

})(ViewSubscription);
