import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPaymentstatus} from "../../redux/actions/applicationsAction";
import { ButtonElement, Modal } from '../UI';
import DOMPurify from 'dompurify';
import { OrangeTick } from '../../assets/images';
import './others.scss';
class PaymentStatus extends Component {
    constructor(props) {
        super(props);
        this.state= {
            closemodalpopup:true
        }
    }

    async componentDidMount(){
       let  txnid=localStorage.getItem("ipsTxnId")
       if(txnid){
           await this.props.getPaymentstatus(txnid);
        //    console.log(this.props.payment_status)
            if(this.props.payment_status){
            //   console.log(this.props.payment_status);
            //   this.props.history.push('/');
            localStorage.setItem("statusShown",true);
            }
       }else{
           this.props.history.push('/');
       }
        // console.log(this.props.payment_status)
    }
    closePopup =()=>{
        this.setState({
            closemodalpopup :false
        })
        this.props.history.push("/");
        localStorage.removeItem("ipsTxnId");
        localStorage.removeItem("statusShown");
    }
    render() {
        const {payment_status} = this.props;
        const  {closemodalpopup} = this.state;
        // const sp=payment_status?.status.split("Payment Success.");
        const heading =payment_status?.status.split("<p>");
        console.log(heading,payment_status?.status);
        let subdata;
        if(heading && heading[1]){
                subdata="<p>"+heading[1];
        }

        // console.log(sp);
        return (
            <div style={{height:"500px"}}>

                {payment_status && closemodalpopup &&
                 <Modal customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closePopup()}>
                    <h1 className='heading'>{heading[0]}</h1>
                 <img src={OrangeTick} className="icon-imgss pb-4 center" alt="orange-tick" />
                <div
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(subdata)}`,
                }} ></div>

                <div className="center">
                  <ButtonElement
                   variant="contained"
                   btnType="background-orange"
                   className="d-inline-block mr-2 search-button"
                   onClick={this.closePopup}
                      >
                    close
                  </ButtonElement>
                </div>

                </Modal>
            }
            </div>
        );
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    payment_status:state.applicationReducer.payment_status

  });

  export default connect(mapDispatchStatetoProps, {
    getPaymentstatus
  })(PaymentStatus);
