import React, { Component } from 'react';
import './agency.scss';
import { connect } from "react-redux";
import { ButtonElement, SelectElement, Modal, Spinner } from '../../../UI/index';
import { startLoader, getAgenciesDdl, updateAgencyPackage, getExternalPackageUpsellConfiguratoin, clearAdminError } from '../../../../redux/actions/adminAction';
import { getDate } from '../../../../helpers/common';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from '../../../../config/constants/index';

// Initialize state
const initialState = {
    submit: false,
    isVisible: false,
    visibleAlertBox: false,
    message: '',
    errorType: '',
    agencyCode: "",
    captchaToken: "",
    externalPackage: {
        royaltyPercentage: "",
        subscriptionFee: "",
        priceOfArticle: "",
        priceOfFullPagePDF: "",
        extPackage: ""
    },
    externalPackageFeeArray: [],
    externalPackageFee: {
        secondarySchool: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        company: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        university: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        advAgency: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        juniorCollege: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        polytechnic: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        overseasSubscriber: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        },
        annPublisher: {
            userType: "",
            userTypeDescription: "",
            fee: ""
        }
    },
    disabled:false
}

class ConfigAgencyPackage extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.recaptcha = React.createRef(null);
    }
    componentDidMount = async () => {
        let { externalPackageFee, agencyCode } = this.state;
        this.props.startLoader();
        await this.props.getAgenciesDdl();

        if (this.props?.agencyInfoResult?.agencies) {
            // Agency list
            var list = [];
            for (let a of this.props.agencyInfoResult.agencies) {
                list.push({
                    key: a.agency,
                    value: a.code,
                })
            }
            agencyCode = list[0].value;
            this.setState({
                agenciesList: list,
                agencyCode
            })
        }

        this.setState({
            minDate: getDate(),
            externalPackageFee
        })
    }

    onReset = (e) => {
        this.setState(initialState);
    }

    onSave = async (e) => {
        this.setState({
            disabled:true
        })
        e.preventDefault();
        this.recaptcha.execute();
    }

    setFormValue = (value, field, number = false) => {
        var { externalPackageFee, agencyCode } = this.state;
        const regex = /[0-9\b]+$/;
        if (field === "agencyCode") {
            agencyCode = value;
        }
        else {
            if (number) {
                externalPackageFee[field].fee = regex.test(value) || value === "" ? value : externalPackageFee[field];
            } else {
                if (field === "annPublisher" || field === "overseasSubscriber" || field === "polytechnic" || field === "juniorCollege" || field === "secondarySchool" || field === "advAgency" || field === "university" || field === "company") {
                    if (isNaN(value) === false) {
                        externalPackageFee[field].fee = value;
                    }
                }
                else {
                    externalPackageFee[field].fee = value;
                }
            }
        }

        this.setState({
            externalPackageFee,
            agencyCode
        });

    }
    setFormValue1 = async (e, i) => {
        let { externalPackageFeeArray } = this.state;
        if (isNaN(e.target.value) === false) {
            externalPackageFeeArray[i].fee = e.target.value.trim();
        }
        this.setState({
            externalPackageFeeArray
        });
    }
    setFormValueExternalPackage = (value, field, number = false) => {
        const { externalPackage } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            externalPackage[field] = regex.test(value) || value === "" ? value : externalPackage[field];
        } else {
            if (field === "priceOfArticle" || field === "priceOfFullPagePDF" || field === "subscriptionFee" || field === "royaltyPercentage") {
                if (isNaN(value) === false) {
                    externalPackage[field] = value.trim();
                }
            }
            else {
                if (isNaN(value) === false) {
                    externalPackage[field] = value.trim();
                }
            }
        }
        this.setState({
            externalPackage
        });
    }
    onBack = async (e) => {
        this.setState({
            isVisible: false
        })
    }

    onSubmitAgency = async (e) => {
        let { externalPackageFee, externalPackage, externalPackageFeeArray } = this.state;
        await this.props.clearAdminError();
        await this.props.getExternalPackageUpsellConfiguratoin(this.state.agencyCode);
        let responseMessage = this.props?.externalPackageUpsellConfigurationError;
        if (responseMessage) {
            this.setState({
                visibleAlertBox: true,
                message: responseMessage,
                errorType: "Error"
            })
        }
        let datasss = Object.keys(this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee);
        externalPackageFeeArray = [];
        for (let d of datasss) {
            let userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee[d].userType;
            let userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee[d].userTypeDescription;
            let fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee[d].fee;
            externalPackageFeeArray.push({
                userType: userType,
                userTypeDescription: userTypeDescription,
                fee: fee
            });
        }
        this.setState({
            externalPackageFeeArray
        })

        externalPackage.royaltyPercentage = this.props.externalPackageUpsellConfigurationResult?.data?.royaltyPercentage;
        externalPackageFee.annPublisher.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['ANN Publisher']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['ANN Publisher']?.userType : '';
        externalPackageFee.annPublisher.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['ANN Publisher']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['ANN Publisher']?.userTypeDescription : '';
        externalPackageFee.annPublisher.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['ANN Publisher']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['ANN Publisher']?.fee : '';

        externalPackageFee.company.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Company']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Company']?.userType : '';
        externalPackageFee.company.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Company']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Company']?.userTypeDescription : '';
        externalPackageFee.company.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Company']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Company']?.fee : '';

        externalPackageFee.advAgency.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Adv Agency']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Adv Agency']?.userType : '';
        externalPackageFee.advAgency.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Adv Agency']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Adv Agency']?.userTypeDescription : '';
        externalPackageFee.advAgency.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Adv Agency']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Adv Agency']?.fee : '';

        externalPackageFee.secondarySchool.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Secondary School']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Secondary School']?.userType : '';
        externalPackageFee.secondarySchool.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Secondary School']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Secondary School']?.userTypeDescription : '';
        externalPackageFee.secondarySchool.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Secondary School']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Secondary School']?.fee : '';

        externalPackageFee.juniorCollege.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Junior College']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Junior College']?.userType : '';
        externalPackageFee.juniorCollege.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Junior College']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Junior College']?.userTypeDescription : '';
        externalPackageFee.juniorCollege.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Junior College']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Junior College']?.fee : '';

        externalPackageFee.overseasSubscriber.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Overseas Subscriber']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Overseas Subscriber']?.userType : '';
        externalPackageFee.overseasSubscriber.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Overseas Subscriber']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Overseas Subscriber']?.userTypeDescription : '';
        externalPackageFee.overseasSubscriber.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Overseas Subscriber']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Overseas Subscriber']?.fee : '';

        externalPackageFee.polytechnic.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Polytechnic']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Polytechnic']?.userType : '';
        externalPackageFee.polytechnic.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Polytechnic']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Polytechnic']?.userTypeDescription : '';
        externalPackageFee.polytechnic.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Polytechnic']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['Polytechnic']?.fee : '';

        externalPackageFee.university.userType = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['University']?.userType ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['University']?.userType : '';
        externalPackageFee.university.userTypeDescription = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['University']?.userTypeDescription ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['University']?.userTypeDescription : '';
        externalPackageFee.university.fee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['University']?.fee > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackageFee['University']?.fee : '';

        externalPackage.subscriptionFee = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['fee']; //> -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['fee'] : '';
        externalPackage.extPackage = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['extPackage']; //> -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['extPackage'] : '';
        externalPackage.priceOfArticle = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['priceArticle']; // > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['priceArticle'] : '';
        externalPackage.priceOfFullPagePDF = this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['pricePdf']; // > -1 ? this.props.externalPackageUpsellConfigurationResult?.data?.externalPackage['pricePdf'] : '';

        // console.log("externalPackageFee ",externalPackageFee)
        // console.log("externalPackage ",externalPackage)
        this.setState({
            isVisible: true,
            externalPackageFee,
            externalPackage
        })
        await this.props.clearAdminError();
    }

    closeModal = async (e) => {
        this.setState({
            isVisible: this.state.errorType==="Error"?true:false,
            visibleAlertBox: false,
            disabled:false
        })
    }
    onResolved = async () => {
        this.props.startLoader();
        let { externalPackageFee, externalPackage, agencyCode, captchaToken, externalPackageFeeArray } = this.state;
        const response = this.recaptcha.getResponse();
        captchaToken = response;
        let externalPackageFeeKeys = Object.keys(externalPackageFee)
        // console.log("externalPackageFee : ",externalPackageFee);
        // console.log("externalPackageFeeKeys : ",externalPackageFeeKeys);
        var data = [];
        for (let obj of externalPackageFeeKeys) {
            let configData = {
                fee: externalPackageFee[obj].fee ? externalPackageFee[obj].fee : '',
                userType: externalPackageFee[obj].userType ? externalPackageFee[obj].userType : '', //externalPackageFee[obj].userType?obj:'',
                userTypeDescription: externalPackageFee[obj].userTypeDescription ? externalPackageFee[obj].userTypeDescription : ''
            }
            data.push(configData);
        }

        let object = {
            "agencyCode": agencyCode,
            "extPackage": externalPackage.extPackage,
            // "externalPackageFee": data,
            "externalPackageFee": externalPackageFeeArray,
            "fee": externalPackage.subscriptionFee,
            "priceArticle": externalPackage.priceOfArticle,
            "pricePdf": externalPackage.priceOfFullPagePDF,
            "royaltyPercentage": externalPackage.royaltyPercentage,
            "captchaToken": captchaToken
        }
        // console.log("obj", object);

        await this.props.updateAgencyPackage(object);
        var msg;
        var errType;


        if (this.props?.updateAgencyPackageResult?.data?.message) {
            msg = this.props.updateAgencyPackageResult?.data?.message;
            errType = "Success"
        }
        else {
            msg = this.props.updateAgencyPackageError?.errors || this.props.updateAgencyPackageError?.errors?.message || this.props.updateAgencyPackageError?.message;
            msg = msg ? msg : "Internal server error";
            errType = "Error"
        }
        this.setState({
            visibleAlertBox: true,
            message: msg,
            errorType: errType
        })
        await this.props.clearAdminError();
    }

    render() {
        let { agenciesList, externalPackageFeeArray, externalPackage, agencyCode, isVisible, errorType, visibleAlertBox, message,disabled} = this.state;
        const { loading } = this.props;

        return (
            <>
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType !== "Success" ? <span className='err'>{errorType}</span> : <span className='suc'>Success</span>
                                }
                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>

                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                <div className='container-fluid card'>
                    {
                        loading && <div className="text-center spinner">
                            <Spinner primary={true} />
                        </div>
                    }
                    {!isVisible &&
                        <div>
                            <div className='col-lg-12'>
                                <h3 className="heading ">
                                    Select News Agency
                                </h3>
                            </div>
                            <hr />
                            <div className='top10'></div>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='labels col-lg-4 col-md-12 paddingLeft' >News Agency Name : </label>
                                    {
                                        agenciesList && agenciesList.length > 0 && <SelectElement
                                            className='col-lg-6 col-md-12'
                                            options={agenciesList}
                                            defaultValue={agencyCode}
                                            onChange={(e) => this.setFormValue(e.target.value, "agencyCode")} />
                                    }
                                </div>
                            </div>
                            <div className='top10'></div>

                            <div className='row'>
                                <div className="col-lg-10 col-md-10 pt-3 pb-5">
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2 float-right"
                                        onClick={(e) => this.onSubmitAgency(e)}
                                    >
                                        <span className="button-label">Submit</span>
                                    </ButtonElement>
                                </div>
                            </div>
                        </div>
                    }
                    {isVisible && <div>
                        {/* <div className='container-fluid'>
                            <div className='col-lg-12'>
                                <h3 className="heading">
                                    {agencyCode} External Package Upsell Configuration
                                </h3>
                            </div>
                            <hr />
                            <form>
                                <div className='row card'>
                                    <div className='col-lg-6 top10'>
                                        <label className='labels paddingLeft  col-lg-6 col-sm-12 col-md-12 col-12' >Royalty Percentage : </label>
                                        <input
                                            value={externalPackage.royaltyPercentage}
                                            onChange={(e) => this.setFormValueExternalPackage(e.target.value, "royaltyPercentage")}
                                            type="text"
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                </div>
                                <div className='top10'></div>

                                <div className='row card'>
                                    <label className='labels col-lg-6 margin-top-15' >{agencyCode} Upsell 1 : </label>
                                    <hr />
                                    <div className='col-lg-6 top10'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Subscription Fee : </label>
                                        <input
                                            value={externalPackage.subscriptionFee}
                                            onChange={(e) => this.setFormValueExternalPackage(e.target.value, "subscriptionFee")}
                                            type="text"
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Secondary School : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.secondarySchool.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "secondarySchool")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Company : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.company.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "company")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >University : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.university.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "university")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Adv Agency : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.advAgency.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "advAgency")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Junior College : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.juniorCollege.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "juniorCollege")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Polytechnic : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.polytechnic.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "polytechnic")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Overseas Subscriber : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.overseasSubscriber.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "overseasSubscriber")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >ANN Publisher : </label>
                                        <input
                                            type="text"
                                            value={externalPackageFee.annPublisher.fee}
                                            onChange={(e) => this.setFormValue(e.target.value, "annPublisher")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Price of Article : </label>
                                        <input
                                            type="text"
                                            value={externalPackage.priceOfArticle}
                                            onChange={(e) => this.setFormValueExternalPackage(e.target.value, "priceOfArticle")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Price of Full Page PDF : </label>
                                        <input
                                            type="text"
                                            value={externalPackage.priceOfFullPagePDF}
                                            onChange={(e) => this.setFormValueExternalPackage(e.target.value, "priceOfFullPagePDF")}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                    <div className='top10'></div>
                                </div>
                                <div className='top10'></div>

                                <div className='row'>
                                    <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 "
                                            onClick={(e) => this.onSave(e)}
                                        >
                                            <span className="button-label">SUBMIT</span>
                                        </ButtonElement>
                                        {" "}
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 "
                                            onClick={(e) => this.onBack(e)}
                                        >
                                            <span className="button-label">BACK</span>
                                        </ButtonElement>
                                    </div>
                                </div>
                            </form>
                        </div> */}

                        <div className='container-fluid'>
                            <div className='col-lg-12'>
                                <h3 className="heading">
                                    {agencyCode} External Package Upsell Configuration
                                </h3>
                            </div>
                            <hr />
                            <div >
                                <div className='col-lg-6 top10'>
                                    <label className=' paddingLeft  col-lg-6 col-sm-12 col-md-12 col-12' >Royalty Percentage:<span className='err'>*</span></label>
                                    <input
                                        value={externalPackage.royaltyPercentage}
                                        onChange={(e) => this.setFormValueExternalPackage(e.target.value, "royaltyPercentage")}
                                        type="text"
                                        onPaste={(e)=>{e.preventDefault(); return false}}
                                        className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                    />
                                </div>
                            </div>
                            <div className='top10'></div>
                            <div className='col-lg-6 '>
                                <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Subscription Fee:<span className='err'>*</span></label>
                                <input
                                    value={externalPackage.subscriptionFee}
                                    onChange={(e) => this.setFormValueExternalPackage(e.target.value, "subscriptionFee")}
                                    onPaste={(e)=>{e.preventDefault(); return false}}
                                    type="text"
                                    className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                />
                            </div>
                            <div className='top10'></div>
                            {
                                externalPackageFeeArray && externalPackageFeeArray.length > 0 &&
                                externalPackageFeeArray.map((externalPackage, index) =>
                                    <div className='col-lg-6 top10' key={externalPackage.userType}>
                                        <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >{externalPackage.userTypeDescription}:</label>
                                        <input
                                            type="text"
                                            value={externalPackage.fee}
                                            onChange={(e) => this.setFormValue1(e, index)}
                                            onPaste={(e)=>{e.preventDefault(); return false}}
                                            className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                        />
                                    </div>
                                )
                            }
                            <div className='col-lg-6'>
                                <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Price of Article : </label>
                                <input
                                    type="text"
                                    value={externalPackage.priceOfArticle}
                                    onChange={(e) => this.setFormValueExternalPackage(e.target.value, "priceOfArticle")}
                                    onPaste={(e)=>{e.preventDefault(); return false}}
                                    className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                    readOnly={true}
                                    disabled={true}
                                />
                            </div>
                            <div className='col-lg-6'>
                                <label className='paddingLeft col-lg-6 col-sm-12 col-md-12 col-12' >Price of Full Page PDF : </label>
                                <input
                                    type="text"
                                    value={externalPackage.priceOfFullPagePDF}
                                    onChange={(e) => this.setFormValueExternalPackage(e.target.value, "priceOfFullPagePDF")}
                                    onPaste={(e)=>{e.preventDefault(); return false}}
                                    className="search-input p-1 col-lg-4 col-sm-12 col-md-12 col-12"
                                    readOnly={true}
                                    disabled={true}
                                />
                            </div>
                            <div className='top10'></div>
                            <div className='row'>
                                <div className="col-lg-12 col-md-12 pt-3 pb-5">

                                    {" "}
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2 float-right"
                                        onClick={(e) => this.onBack(e)}
                                    >
                                        <span className="button-label">BACK</span>
                                    </ButtonElement>
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2 float-right"
                                        onClick={(e) => this.onSave(e)}
                                        disabled={disabled}
                                    >
                                        <span className="button-label">SUBMIT</span>
                                    </ButtonElement>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    agencyInfoResult: state.adminReducer.agencyInfoResult,
    externalPackageUpsellConfigurationResult: state.adminReducer.externalPackageUpsellConfigurationResult,
    externalPackageUpsellConfigurationError: state.adminReducer.externalPackageUpsellConfigurationError,
    updateAgencyPackageResult: state.adminReducer.updateAgencyPackageResult,
    updateAgencyPackageError: state.adminReducer.updateAgencyPackageError,
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getExternalPackageUpsellConfiguratoin,
    getAgenciesDdl,
    updateAgencyPackage,
    clearAdminError
})(ConfigAgencyPackage);
