import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ButtonElement, DataTable, Spinner } from '../../UI/index';
import './css/users.scss';
import {getUserList} from '../../../redux/actions/adminAction';
const UsersData = [
  {"User Name": "nicola_lawrence_1", "Contact": "Nicola Lawrence", "Company": "SPH", "Email": "nlawrence1@gmail.com", "User Type": "SPH Magazine Staff", "Creation Date & time": "19/04/2019 15:02:44", "Status": "Active", "Active Subscription": "Premium Unlimited", "Actions": false}
,{"User Name": "nicola_lawrence_2", "Contact": "Nicola Lawrence", "Company": "SPH", "Email": "nlawrence2@gmail.com", "User Type": "SPH Magazine Staff", "Creation Date & time": "19/04/2019 15:02:44", "Status": "Active", "Active Subscription": "Premium Unlimited", "Actions": false}]
class Users extends Component {
  state = {
    loading: false,
    modal: false
  }

  async componentDidMount() {
    await this.props.getUserList();
    this.setState={
      loading:true
    }

  }

  render() {
    const {UsersDatadetails} =this.props;
    const { loading } = this.state;
    return (
      <div className="col-12 col-md-12 users-container">
        <div className="col-12 col-md-12 p-3">
          {loading ? (
            <div className="text-center pt-5 pb-5">
              <Spinner primary={true} />
            </div>
          ) :
            <div>
              <h2 className="heading">User Account</h2>
              <div className="action-container">
                <ButtonElement variant="outlined" btnType="transparent-black button-label" className="d-inline-block mr-3">
                  <span className="button-label">SUSPEND</span>

                </ButtonElement>
                <ButtonElement variant="outlined" btnType="transparent-black button-label" className="d-inline-block">
                  <span className="button-label">delete acc.</span>

                </ButtonElement>
              </div>
              <div className="users-table">
                <DataTable className="table-responsive" checkbox={true} columns={UsersData[0]} data={UsersData} />
                </div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  announcements: state.applicationReducer.announcements,
  contentTypes: state.applicationReducer.contentTypes,
  contentType: state.applicationReducer.contentType,
  UsersDatadetails:state.adminReducer.userList             //root reducer which get initalstatedata
});

export default connect(mapDispatchStatetoProps, {
  getUserList                                            //action from redux/adminActions.js
})(Users);
