import React from "react";
import "./checkbox.scss";

const Checkbox = (props) => {
  let cls = props.customClass ? "checkbox-element mt-1" : "checkbox-element mt-3";
  return (
    <label className={cls}>
      {props.label}
      <input type="checkbox" label={props.labelValue} checked={props.checked} onChange={(e) => props.onChange ? props.onChange(e) : () => { }} />
      <span className="checkmark"></span>
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false
}

export default Checkbox;
