import React, { Component } from "react";
import "./pagination.scss";

class Paginationnextpre extends Component {
  state = {
    currentpage: 1,
    totalpages: 0,
    currentpageSize: 10,
    pagesArray: [],
    totalcount: 0,
    from: 1,
    to: 10,
  };

  componentDidMount() {
    const { currentPage, total } = this.props;
    const { currentpageSize } = this.state;
    this.state.currentpage = currentPage;
    this.state.totalcount = total;
    this.setState({
      currentpage: this.state.currentpage,
      totalcount: this.state.totalcount,
    });

    let pageNumbers = [];
    if (total <= 10) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.totalcount / currentpageSize);
        i++
      ) {
        pageNumbers.push(i);
      }
    } else {
      if (total <= 100) {
        for (
          let i = 1;
          i <= Math.ceil(this.state.totalcount / currentpageSize);
          i++
        ) {
          pageNumbers.push(i);
        }
      }
      if (total > 100) {
        for (let i = 1; i <= Math.ceil(10); i++) {
          pageNumbers.push(i);
        }
      }
    }

    this.setState({
      pagesArray: pageNumbers,
      totalpages: Math.ceil(total / 10),
    });
  }
  componentDidUpdate() {
    const { currentpageSize } = this.state;
    if (this.props.total && this.props.total !== this.state.totalcount) {
      this.state.totalcount = this.props.total;
      let pageNumbers = [];
      if (this.state.totalcount <= 10) {
        for (
          let i = 1;
          i <= Math.ceil(this.state.totalcount / currentpageSize);
          i++
        ) {
          pageNumbers.push(i);
        }
      } else {
        if (this.state.totalcount <= 100) {
          for (
            let i = 1;
            i <= Math.ceil(this.state.totalcount / currentpageSize);
            i++
          ) {
            pageNumbers.push(i);
          }
        }
        if (this.state.totalcount > 100) {
          for (let i = 1; i <= Math.ceil(10); i++) {
            pageNumbers.push(i);
          }
        }
      }
      this.state.currentpage = 1;
      this.setState({
        totalcount: this.state.totalcount,
        pagesArray: pageNumbers,
        currentpage: this.state.currentpage,
        totalpages: Math.ceil(this.state.totalcount / 10),
      });
    }
  }

  nexfun = (start, to, too) => {
    if (this.state.totalpages >= to) {
      if (this.state.currentpage === 1) {
        start = 11;
        to = 20;
      }
      let pageNumbers = [];
      for (let i = start; i <= to; i++) {
        pageNumbers.push(i);
      }
      this.setState({
        pagesArray: pageNumbers,
        from: start,
        to: to,
        currentpage: start,
      });
      this.props.onPagination(start);
    }
    if (start <= this.state.totalpages && to > this.state.totalpages) {
      let pageNumbers = [];
      for (let i = start; i <= this.state.totalpages; i++) {
        pageNumbers.push(i);
      }
      this.setState({
        pagesArray: pageNumbers,
        from: start,
        to: to,
      });
      if (this.state.currentpage < start) {
        this.setState({
          currentpage: start,
        });

        this.props.onPagination(start);
      }
    }
  };
  prefun = (from, to) => {
    if (this.state.currentpage === 1) {
      from = -1;
      to = 10;
    }
    if (from >= 1) {
      let pageNumbers = [];
      for (let i = from; i <= to; i++) {
        pageNumbers.push(i);
      }
      this.setState({
        pagesArray: pageNumbers,
        from: from,
        to: to,
        currentpage: from,
      });
      this.props.onPagination(from);
    }
  };

  paginate = (pgnumber) => {
    this.setState({ currentpage: pgnumber });
    this.props.onPagination(pgnumber);
  };
  
  render() {
    const { currentpage, pagesArray, from, to } = this.state;
    return (
      <nav aria-label="Page navigation example justify-content-center">
        <ul className="pagination d-flex flex-wrap">
          <li className="page-item  cursor">
            <a
              className="page-link"
              onClick={() => this.prefun(from - 10, to - 10)}
            >
              Previous
            </a>
          </li>
          {pagesArray.map((number) => (
            <li
              key={number}
              className={
                number === currentpage
                  ? "page-item active cursor"
                  : "page-item cursor"
              }
            >
              <a onClick={() => this.paginate(number)} className={"page-link "}>
                {number}
              </a>
            </li>
          ))}
          <li className="page-item">
            <a
              className="page-link cursor"
              onClick={() => this.nexfun(from + 10, to + 10)}
            >
              Next
            </a>
          </li>
          <li className="page-item">
            <a className="page-link">{this.state.totalpages}</a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Paginationnextpre;
