import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonElement, Modal, Spinner, Checkbox, SelectElement } from '../../UI/index';
import { removeTokens, startLoader, removeTokenByUserName, deleteKeyCache, deleteHashKeyCache, updateAuthTable, clearDevSettings, deleteWildCardKeyCache } from '../../../redux/actions/adminAction';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import './adminUser.scss';
import { spaceValidation } from "../../../helpers";

// default state
const initialState = {
    tokenReponseMessage: false,
    tokenResponseBox: false,
    syncOauth : {
        users : 'admin'
    },
    requestObject: {
        userName: '',
        userId: '',
        devSetting: '',
        simpleKeyWildCard:''
    },
    requestSimpleKeyObject: {
        simpleKey: ''
    },
    requestHashKeyObject: {
        key: '',
        hashKey: ''
    },
    errorType: '',
    isVisibleTokenForm: false,
    isVisibleCacheForm: false,
    isVisibleSyncToAuthForm : false,
    dataObjectKey: '',
    deleteByUserName: false,
    deleteByUserId: false,
    visibleDeleteHashKeyCacheBox: false,
    visibleDeleteKeyCacheBox: false,
    visibleDeleteKeyWildCardCacheBox : false,
    developerSettings: [
        { key: 'Token', value: 'Token' },
        { key: 'Cache', value: 'Cache' },
        { key: 'Sync to Oauth', value : 'SyncToOauth'}
    ],
    userNameFlag: false,
    hashKeyFlag: false,
    simpleKeyFlag: false,
    simpleKeyWildCardFlag: false,
    hashFieldFlag: false,
    syncOauthAlertBox : false,
    message: '',
    userList: [{key: 'Admin', value:'admin'},{key: 'User', value:'user'}]
}
class Tokens extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
    }

    // onSave = async (e) => {
    //     e.preventDefault()
    //     this.recaptcha.execute();
    // }

    // Delete all token Confirm popup
    onDelete = async (e) => {
        this.setState({
            visibleAlertBox: true
        })
    }

    // Delete all token
    onAllTokensConfirmDelete = async (e) => {
        this.props.startLoader();
        this.closeModal();
        await this.props.removeTokens();
        let resp = this.props.removeTokenResponse || this.props.removeTokenError;
        this.setState({
            visibleAlertBox: false,
            tokenResponseBox: true,
            tokenReponseMessage: resp.message
        })
    }

    // Close modal
    closeModal = async (e) => {
        this.setState({
            visibleAlertBox: false,
            tokenResponseBox: false,
            visibleDeleteKeyCacheBox: false,
            visibleDeleteHashKeyCacheBox: false,
            visibleDeleteKeyWildCardCacheBox : false,
            syncOauthAlertBox : false,
            deleteByUserName: false
        })
    }

    // Delete cache by username confirm popup
    onDeleteByUserName = async (e) => {
        if(!spaceValidation(true,this.state.requestObject.userName)){
        if (this.state.requestObject.userName) {
            this.setState({
                deleteByUserName: true
            })
        } else {
            this.setState({
                userNameFlag: true
            })
        }
    }
    }
    onDeleteByUserId = async (e) => {
        this.setState({
            deleteByUserId: true
        })
    }
    // Delete key cache confirm popup
    onDeleteKeyCache = async (e) => {
        if (this.state.requestSimpleKeyObject.simpleKey) {
            this.setState({
                visibleDeleteKeyCacheBox: true
            })
        } else {
            this.setState({
                simpleKeyFlag: true
            })
        }
    }
    // Delete key by hash confirm popup
    onDeleteByHashKey = async (e) => {
        if (this.state.requestHashKeyObject.key && this.state.requestHashKeyObject.hashKey) {
            this.setState({
                visibleDeleteHashKeyCacheBox: true
            })
        } else {
            if (!this.state.requestHashKeyObject.key)
                this.setState({
                    hashKeyFlag: true
                })
            if (!this.state.requestHashKeyObject.hashKey)
                this.setState({
                    hashFieldFlag: true
                })
        }
    }
    onDeleteTokenByUserNameConfirm = async (e) => {
        if (this.state.requestObject.userName) {
            this.props.startLoader();
            this.closeModal();
            await this.props.removeTokenByUserName(this.state.requestObject.userName);
            let resp = this.props?.removeTokenByUserNameResponse?.message || this.props?.removeTokenByUserNameError?.toString();

            if (resp) {
                this.setState({
                    visibleAlertBox: false,
                    tokenResponseBox: true,
                    tokenReponseMessage: resp
                })
            }
        } else {
            this.setState({
                userNameFlag: true
            })
        }
    }
    onDeleteKeyCacheConfirm = async (e) => {
        let key=this.state.requestSimpleKeyObject.simpleKey.trim();
        if(key){
        this.props.startLoader();
        this.closeModal();
        await this.props.deleteKeyCache(key);
        let resp = this.props?.deleteKeyResponse?.message || this.props?.deleteKeyError?.errorType;
        if (resp) {
            this.setState({
                visibleDeleteKeyCacheBox: false,
                visibleAlertBox: false,
                tokenResponseBox: true,
                visibleDeleteHashKeyCacheBox: false,
                tokenReponseMessage: resp
            })
        }
    }
    }
    onDeleteKeyWildCardConfirm = async (e) => {
        let key=this.state.requestObject.simpleKeyWildCard.trim();
        if(key){
        this.props.startLoader();
        this.closeModal();

        await this.props.deleteWildCardKeyCache(key);
        let resp = this.props?.deleteWildcardKeyResponse?.message || this.props?.deleteWildcardKeyError?.errorType;
        if (resp) {
            this.setState({
                visibleDeleteKeyCacheBox: false,
                visibleAlertBox: false,
                tokenResponseBox: true,
                visibleDeleteHashKeyCacheBox: false,
                visibleDeleteKeyWildCardCacheBox: false,
                tokenReponseMessage: resp
            })
        }
    }
    }
    onDeleteHashKeyCacheConfirm = async (e) => {
        this.props.startLoader();
        this.closeModal();
        await this.props.deleteHashKeyCache(this.state.requestHashKeyObject);
        let resp = this.props?.deleteKeyResponse?.message || this.props?.deleteKeyError?.errorType;
        if (resp) {
            this.setState({
                visibleDeleteHashKeyCacheBox: false,
                visibleAlertBox: false,
                syncOauthAlertBox : false,
                tokenResponseBox: true,
                tokenReponseMessage: resp
            })
        }
    }
    onDeleteSimpleKeyWildCard = async(e) =>{
        if(this.state.requestObject.simpleKeyWildCard!==''){
            this.setState({
                visibleDeleteKeyWildCardCacheBox : true
            })
        } else{
            this.setState({
                simpleKeyWildCardFlag : true
            })
        }
    }
    setFormValue = async (val, field, number = false) => {
        const { requestObject, requestSimpleKeyObject } = this.state;
        const regex = /[0-9\b]+$/;
        let value=val.trim();
        if (field === "simpleKey") {
            if (number) {
                requestSimpleKeyObject[field] = regex.test(value) || value === "" ? value : requestSimpleKeyObject[field];
            } else {
                requestSimpleKeyObject[field] = value;
            }
        } else {
            if (number) {
                requestObject[field] = regex.test(value) || value === "" ? value : requestObject[field];
            } else {
                requestObject[field] = value;
            }
        }

        // if (value === "Token")
        //     this.setState({ isVisibleTokenForm: true, isVisibleCacheForm: false })

        // if (value === "Cache") {
        //     this.setState({ isVisibleCacheForm: true, isVisibleTokenForm: false })
        // }
        if (value === "Token")
            this.setState({ isVisibleTokenForm: true, isVisibleCacheForm: false, isVisibleSyncToAuthForm: false })
        if (value === "Cache") {
            this.setState({ isVisibleCacheForm: true, isVisibleTokenForm: false, isVisibleSyncToAuthForm: false })
        }
        if (value === "SyncToOauth") {
            this.setState({ isVisibleSyncToAuthForm: true, isVisibleTokenForm: false, isVisibleCacheForm: false})
        }
        this.setState({
            requestObject,
            userNameFlag: false,
            simpleKeyFlag: false,
            simpleKeyWildCardFlag : false
        });
    }

    setFormHashKeyValue = async (val, field, number = false) => {
        const { requestHashKeyObject } = this.state;
        const regex = /[0-9\b]+$/;
        let value=val.trim();
        if (number) {
            requestHashKeyObject[field] = regex.test(value) || value === "" ? value : requestHashKeyObject[field];
        } else {
            requestHashKeyObject[field] = value;
        }
        if (value === "Token")
            this.setState({ isVisibleTokenForm: true, isVisibleCacheForm: false, isVisibleSyncToAuthForm: false })
        if (value === "Cache") {
            this.setState({ isVisibleCacheForm: true, isVisibleTokenForm: false, isVisibleSyncToAuthForm: false })
        }
        if (value === "SyncToOauth") {
            this.setState({ isVisibleSyncToAuthForm: true, isVisibleTokenForm: false, isVisibleCacheForm: false})
        }
        this.setState({
            requestHashKeyObject,
            hashKeyFlag: false,
            hashFieldFlag: false
        });
    }

    setSyncOauthFormValue = (value, field, number = false) => {
        const { syncOauth } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            syncOauth[field] = regex.test(value) || value === "" ? value : syncOauth[field];
        } else {
            syncOauth[field] = value;
        }
            this.setState({
                syncOauth
            });
    }


    onSyncOauth = async (e) =>{
        e.preventDefault();
        await this.props.updateAuthTable(this.state.syncOauth.users);
        let resp = this.props?.syncAuthSucess?.message || this.props?.syncAuthError?.errors;
        if (resp) {
            this.setState({
                syncOauthAlertBox: true,
                message: resp,
                errorType : this.props?.syncAuthSucess?.message ? "Success" : "Error"
            })
        }
        // await this.props.clearDevSettings();
    }

    render() {
        const {
            message, syncOauthAlertBox,
            visibleAlertBox,
            tokenReponseMessage,
            tokenResponseBox,
            visibleDeleteKeyCacheBox,
            visibleDeleteKeyWildCardCacheBox,
            requestObject,
            developerSettings,
            isVisibleTokenForm,
            isVisibleCacheForm,
            isVisibleSyncToAuthForm, errorType,
            visibleDeleteHashKeyCacheBox, userList, syncOauth,
            userNameFlag, simpleKeyFlag, hashKeyFlag, hashFieldFlag, simpleKeyWildCardFlag,
            deleteByUserName } = this.state;
        let { loading } = this.props;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {syncOauthAlertBox && (
                   <Modal onClick={() => this.closeModal()} customClass="login-modal">
                   <div className="col-lg-12 col-md-12 pt-3 pb-5">
                       <h3>
                           {
                               errorType === "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                           }

                       </h3>
                       <div className='margin-left-20'>
                           <ul>
                               {
                                   message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                       <li>
                                           <h3>{e}</h3>
                                       </li>
                                   )) : <h3>{message}</h3>
                               }
                           </ul>
                       </div>
                       <ButtonElement
                           variant="contained"
                           btnType="background-orange button-label"
                           className="d-inline-block mr-2 float-right"
                           onClick={() => this.closeModal()}
                       >
                           <span className="button-label">Close</span>
                       </ButtonElement>
                   </div>
               </Modal>
                )}
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    message ? message : `Do you want to delete all tokens?`
                                }
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement>{" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onAllTokensConfirmDelete(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                )}
                {deleteByUserName && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                Do you want to delete token?
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onDeleteTokenByUserNameConfirm(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement> {" "}

                        </div>
                    </Modal>
                )}
                {tokenResponseBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    tokenReponseMessage
                                }
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                {visibleDeleteKeyCacheBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                Do you want to delete this cache?
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement>  {" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onDeleteKeyCacheConfirm(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                )}
                {visibleDeleteHashKeyCacheBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                Do you want to delete this cache?
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement>{" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onDeleteHashKeyCacheConfirm(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                )}

                {visibleDeleteKeyWildCardCacheBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                Do you want to delete this cache?
                            </h3>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement>  {" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onDeleteKeyWildCardConfirm(e)}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                )}
                <div className='row'>
                    <div className="col-lg-12">
                        <div><h3>Developer Setting: </h3></div>
                        {developerSettings.map((p) => (
                            <div className="col-md-4 d-inline-block p-0" key={p.key}>
                                <Checkbox key={p.key} labelValue={p.value} checked={requestObject.devSetting == p.value} onChange={(e) =>
                                    this.setFormValue(p.value, "devSetting")} />
                                <label className="margin-left-35">{p.key} </label>
                            </div>))}

                    </div>
                </div>

                {isVisibleTokenForm && (
                    <div>
                        <div className='padding10 card' >
                            <div className='col-lg-12'>
                                <div className='col-lg-12'>
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2 float-right"
                                        onClick={(e) => this.onDelete(e)}
                                    >
                                        <span className="button-label">DELETE ALL TOKENS</span>
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className='top10'></div>
                        </div>

                        <div className='padding10 card'>
                        <div className='row'>
                            <div className='col-lg-3'>
                            <label className='' > User name :
                                    <span className="err">*</span></label>
                            </div>
                            <div className='col-lg-6'>
                                <input
                                        defaultValue={requestObject.userName}
                                        id=''
                                        onChange={(e) => this.setFormValue(e.target.value, "userName")}
                                        type="text"
                                        className="search-input p-1 col-lg-6 col-md-8 col-sm-8 col-12 mb-2"
                                    /> <span className='err'>{
                                        userNameFlag ? "Username is required" : ''
                                    }</span>
                                    <span className="error-message">
                                            {spaceValidation(true,requestObject.userName) && "Remove space from the input"}
                                        </span>
                            </div>
                            <div className='col-lg-3'>
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2  "
                                        onClick={(e) => this.onDeleteByUserName(e)}>
                                        <span className="button-label">DELETE TOKEN</span>
                                    </ButtonElement>
                            </div>
                        </div>
                            {/* <div className='col-lg-12'>
                                <div className='col-lg-12'>
                                    <label className='paddingLeft col-lg-4 col-md-12 col-sm-12 col-12' > User name
                                    <span className="err">*</span>   {"  "} <span className='err'>{
                                        userNameFlag ? "Username is required" : ''
                                    }</span></label>

                                    <input
                                        defaultValue={requestObject.userName}
                                        id=''
                                        onChange={(e) => this.setFormValue(e.target.value, "userName")}
                                        type="text"
                                        className="search-input p-1 col-lg-6 col-md-8 col-sm-8 col-12"
                                    />
                                    <div className="pt-1 offset-md-4">
                                        <span className="error-message">
                                            {spaceValidation(true,requestObject.userName) && "Remove space from the input"}
                                        </span>
                                    </div>
                                    <div className='top10'></div>

                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2  "
                                        onClick={(e) => this.onDeleteByUserName(e)}
                                    >
                                        <span className="button-label">DELETE TOKEN</span>
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className='top10'></div> */}
                        </div>
                    </div>
                )}

                {isVisibleCacheForm && (<div>
                    <div className='padding10 card' >
                        <div className='col-lg-12'>
                            <div className='col-lg-12'>
                                <label className='paddingLeft col-lg-6 col-md-12 col-sm-12 col-12' > Delete Simple Key<span className="err">*</span> {"  "} <span className='err'>{
                                    simpleKeyFlag ? "Simple key is required." : ''
                                }</span> </label>
                                <input
                                    placeholder="Key"
                                    onChange={(e) => this.setFormValue(e.target.value, "simpleKey")}
                                    type="text"
                                    className="search-input p-1 col-lg-4 col-md-8 col-sm-8 col-12"
                                /> {"  "}
                        <div className='top10'></div>

                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 float-right"
                                    onClick={(e) => this.onDeleteKeyCache(e)}
                                >
                                    <span className="button-label">DELETE</span>
                                </ButtonElement>
                            </div>
                        </div>
                        <div className='top10'></div>
                    </div>

                    <div className='padding10 card' >
                        <div className='col-lg-12'>
                            <div className='col-lg-12'>
                                <label className='paddingLeft col-lg-6 col-md-12 col-sm-12 col-12' > Delete Simple Key With Wild Card<span className="err">*</span> {"  "}
                                <span className='err'>{
                                        simpleKeyWildCardFlag ? "Key is required" : ''
                                    }</span> </label>
                                <input
                                    placeholder="Key"
                                    onChange={(e) => this.setFormValue(e.target.value, "simpleKeyWildCard")}
                                    type="text"
                                    className="search-input p-1 col-lg-4 col-md-8 col-sm-8 col-12"
                                /> {"  "}
                        <div className='top10'></div>

                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 float-right"
                                    onClick={(e) => this.onDeleteSimpleKeyWildCard(e)}
                                >
                                    <span className="button-label">DELETE</span>
                                </ButtonElement>
                            </div>
                        </div>
                        <div className='top10'></div>
                    </div>

                    <div className='padding10 card' >
                        <div className='col-lg-12'>
                            <div className='col-lg-12'>
                                <label className='paddingLeft col-lg-12 col-md-12 col-sm-12 col-12' > Delete Hash Key <span className="err">*</span> {"  "}
                                     {" "}

                                </label>
                            </div>
                            <div className='col-lg-12 marginTop-10'>
                                <input
                                    placeholder="Key"
                                    id=''
                                    onChange={(e) => this.setFormHashKeyValue(e.target.value, "key")}
                                    type="text"
                                    className="search-input p-1 col-lg-3 col-md-3 col-sm-3 col-12"
                                /> {"  "} <span className='err'>{
                                    hashKeyFlag ? "Hash key is required." : ''
                                }</span>
                            </div>

                            <div className='col-lg-12 marginTop-10'>
                                <input
                                    placeholder="Field"
                                    id=''
                                    onChange={(e) => this.setFormHashKeyValue(e.target.value, "hashKey")}
                                    type="text"
                                    className="search-input p-1 col-lg-3 col-md-3 col-sm-3 mb-2 col-12"
                                /> {"  "} <span className='err'>{
                                    hashFieldFlag ? "Field is required." : ''
                                }</span>
                            </div>
                        <div className='top10'></div>

                            <div className='col-lg-12 marginTop-10'>
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 float-right"
                                    onClick={(e) => this.onDeleteByHashKey(e)}
                                >
                                    <span className="button-label">DELETE</span>
                                </ButtonElement>
                            </div>
                        </div>
                        <div className='top10'></div>
                    </div>

                </div>
                )}

                {
                    isVisibleSyncToAuthForm && (
                        <div className='padding10 card' >
                        <div className='col-lg-12'>
                            <div className='col-lg-12'>
                                <label className='paddingLeft col-lg-6 col-md-12 col-sm-12 col-12' > User : {"  "}</label>

                                {
                                        userList && userList.length > 0 && <SelectElement
                                            className='col-lg-6'
                                            options={userList}
                                            defaultValue={syncOauth.users}
                                            onChange={(e) => this.setSyncOauthFormValue(e.target.value, "users")} />
                                    }
                        <div className='top10'></div>

                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 float-right"
                                    onClick={(e) => this.onSyncOauth(e)}
                                >
                                    <span className="button-label">Submit</span>
                                </ButtonElement>
                            </div>
                        </div>
                        <div className='top10'></div>
                    </div>
                    )
                }
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>
        )
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    removeTokenResponse: state.adminReducer.removeTokenResponse,
    removeTokenError: state.adminReducer.removeTokenError,
    removeTokenByUserNameError: state.adminReducer.removeTokenByUserNameError,
    removeTokenByUserNameResponse: state.adminReducer.removeTokenByUserNameResponse,
    deleteKeyResponse: state.adminReducer.deleteKeyResponse,
    deleteKeyError: state.adminReducer.deleteKeyError,
    deleteHashKeyError: state.adminReducer.deleteHashKeyError,
    deleteHashKeyResponse: state.adminReducer.deleteHashKeyResponse,
    syncAuthSucess : state.adminReducer.syncAuthSucess,
    syncAuthError : state.adminReducer.syncAuthError,
    deleteWildcardKeyResponse: state.adminReducer.deleteWildcardKeyResponse,
    deleteWildcardKeyError: state.adminReducer.deleteWildcardKeyError
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    removeTokens,
    removeTokenByUserName,
    deleteKeyCache,
    deleteHashKeyCache,
    updateAuthTable,
    clearDevSettings,
    deleteWildCardKeyCache
})(Tokens);
