import React from "react";
import { ButtonElement } from "../UI/index";
import "./css/subscriptioncard.scss";
const SubscriptionCard = (props) => {
    const { subscription } = props;
    return (
        <div className="col-md-6 col-12 h-100 mb-5 text-center d-inline-block container">
            {subscription.description === 'Premium' ?
                <div className="preferred">

                      <div>  Preferred</div>

                </div> : ""}
            <div className="sub-pack-container ">
                <div className="sub-fee subfeees">
                   <span className="dollorcard">$</span>{subscription.fee}<span className="fee-yr" >/<sub >yr</sub></span>
                </div>
                <div className="sub-type">
                    <h4 className="">{subscription.description}</h4>
                </div>
                <hr />
                <div className="col-12 col-md-12 pb-3">
                    <h6 className="sub-text">Article retrieval fee:</h6>
                    <span className=" font-weight-bolder sub-price"> {subscription["priceArticle"] !== "NA" ? `$${subscription["priceArticle"]}` : subscription["priceArticle"]}</span>
                </div>
                <div className="col-12 col-md-12 pb-3">
                    <h6 className="sub-text">Full Page PDF retrieval fee:</h6>
                    <span className=" font-weight-bolder"> {subscription["pricePdf"] !== "NA" ? `$${subscription["pricePdf"]}` : subscription["pricePdf"]}</span>
                </div>
                <ButtonElement
                    variant="contained"
                    className="d-inline-block select-button"
                    onClick={() => props.onSelectSubPlan(subscription)}
                >
                    <span className="button-label">{props.selected ? "SELECTED" : "SELECT"}</span>
                </ButtonElement>
            </div>
        </div>
    )
}

export default SubscriptionCard;
