import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import Recaptcha from "react-google-invisible-recaptcha";
import { Checkbox, ButtonElement, Spinner, SelectElement, Modal } from "../UI/index";
import { RECAPTCHA_SITE_KEY } from "../../config/constants/index";
import { getPreloads, startLoader, addSubscription, clear_Error, pageViewDatagtm,customViewDatagtm } from "../../redux/actions/applicationsAction";
import { salutations, extractTelCodes, paymentTypes, getDate, spaceValidation, getPageviewobj } from "../../helpers/common";
import "./css/subscriptionForm.scss";
import AutoSubmitForm from "./hiddenForm";
import RecaptchaWidget from "../UI/Recaptcha/recaptcha";


class SubscriptionForm extends Component {
  state = {
    subscription: {
      address: "",
      company: "",
      country: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      paymentMode: "1",
      phone: "",
      phoneCountryCode: "",
      pkg: "",
      postalCode: "",
      salutation: "",
      userId: ""
    },
    countryCode: "",
    invalidPassword: "",
    confirmPassword: "",
    submit: false,
    chequePaymentStatus: false,
    success_msg: "",
    openerrorModal: false,
    captchaToken: "",
    captchaError: false
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    // await this.props.getPreloads();
    const { selectedSub } = this.props.location;
    const { subscription } = this.state;
    const { codes = [], regions = [] } = extractTelCodes(this.props.telCodes, true);
    subscription.pkg = selectedSub?.description.toLowerCase();
    subscription.salutation = salutations[0].key;
    // subscription.country = regions[0].value;
    // subscription.phoneCountryCode = codes[0].telephoneCode;
    for (let x of regions) {
      if (x.key.includes("Singapore")) {
        subscription.country = x.value;
      }
    }
    let cntrycde;
    for (let x of codes) {
      if (x.key.includes("Singapore")) {
        subscription.phoneCountryCode = x.telephoneCode;
        cntrycde = x.value
      }
    }
    this.setState({
      selectedSub,
      subscription,
      confirmPassword: "",
      countryCode: cntrycde
    })
    !selectedSub && this.props.history.push("/subscription");
    this.setPageViewcall(this.props.searchObject,{});
  }

  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
      let url=window.location.href;
      let obj= getPageviewobj(url,"subscription-form","Subscription Form",user_status,"","","",id);
      this.props.pageViewDatagtm(obj,searchObject);
    }

  onBack = () => {
    this.props.history.push({
      pathname: "/subscription",
      selectedSub: this.props.location.selectedSub
    })
  }

  subscriptionCallback = (subscriptionData) => {
    if (subscriptionData.message) {
      this.setState({
        chequePaymentStatus: true,
        success_msg: subscriptionData.message
      })
    } else {
      const { ipsRequest, ipsTxnId } = subscriptionData;

      localStorage.setItem("ipsTxnId", ipsTxnId);
      const data = {};
      Object.keys(ipsRequest).map((key) => {
        data[key.toLowerCase()] = ipsRequest[key];
        return ipsRequest[key];
      })
      const ipsUrl = data.cybersource_url;
      delete data.cybersource_url;
      this.setState({
        ipsUrl,
        ipsRequest: data
      })
    }

  }

  setSubscriptionDetails = (value, field, number = false) => {
    const { subscription } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      subscription[field] = regex.test(value) || value === "" ? value : subscription[field];
    } else {
      subscription[field] = value;
    }
    this.setState({
      subscription,
    });
  }
  trimmer = (field) => {
    const { subscription } = this.state;
    subscription[field] = subscription[field].trim();
    this.setState({
      subscription,
    });
  }
  setCountryandPhonecountrycode = (value, field) => {
    const { subscription } = this.state;
    const { telCodes } = this.props;
    subscription[field] = value;
    // const { codes = [], regions = [] } = extractTelCodes(this.props.telCodes,true);
    let tempCountryCode
    for (let x in telCodes?.countryTelCode) {

      if (x === value) {
        tempCountryCode = telCodes?.countryTelCode[x].countryTel;
        subscription.phoneCountryCode = telCodes?.countryTelCode[x].telephoneCode;
      }

    }
    this.setState({
      subscription, countryCode: tempCountryCode
    })
  }

  verifyPassword = (password) => {
    const { subscription } = this.state;
    if (password !== subscription.password) {
      this.setState({
        invalidPassword: "Password and confirm password should be same."
      })
    }
    else {
      this.setState({
        invalidPassword: ""
      })
    }
    this.setState({
      confirmPassword: password
    })
  }

  onResolved1 = async () => {
    const { subscription, selectedSub } = this.state;
    const captchaToken = this.recaptcha.getResponse();
    const data = {
      admin: false,
      captchaToken: captchaToken,
      consentL1: selectedSub.consentL1,
      consentL2: selectedSub.consentL2,
      accountInfoDTO: {
        "ipAddress": this.props.IP,
        "password": subscription.password,
        "userId": subscription.userId
      },
      subscriptionInfoDTO: {
        articleRetrievalFee: selectedSub.priceArticle,
        endDate: getDate(false, 1).toLocaleDateString('en-GB').replaceAll("/", "-"),
        pdfRetrievalFee: selectedSub.pricePdf,
        pkg: selectedSub.description.toLowerCase(),
        startDate: (new Date()).toLocaleDateString('en-GB').replaceAll("/", "-"),
        subscriptionFee: selectedSub.fee
      },
      paymentInfoDTO: {
        paymentMode: subscription.paymentType,
        paymentRef: subscription?.paymentRef
      },
      basicInfoDTO: {
        "address": subscription.address,
        "company": subscription.company,
        "country": subscription.country,
        "email": subscription.email,
        "firstName": subscription.firstName,
        "lastName": subscription.lastName,
        "phone": subscription.phone,
        "phoneCountryCode": subscription.phoneCountryCode,
        "postalCode": subscription.postalCode,
        "salutation": subscription.salutation,

      },
    }
    await this.props.addSubscription(data, this.subscriptionCallback);
    if (this.props.error) {
      // this.recaptcha.reset();
    }
  }

  getRecaptchaToken = (value) => {
    this.setState({
      captchaToken: value,
      captchaError: false
    });
  }

  onSubmit = async (e) => {
    const { subscription, confirmPassword, captchaToken } = this.state;
    e.preventDefault();
    const validation = () => {
      const errors = ReactDOM.findDOMNode(
        this.refs.subsform
      ).getElementsByClassName("error-message");
      const error = [];
      for (let item of errors) {
        if (item.innerHTML !== "") {
          error.push(item);
        }
      }
      if (subscription.password === confirmPassword
      ) {
        if(captchaToken!=""){
          this.props.startLoader();
          this.register()
        }
        else{
          this.setState({
            captchaError: true,
          });
        }
      } else {
        if (confirmPassword !== subscription.password) {
          this.setState({
            invalidPassword: "Password and confirm password should be same."
          })
        }
        window.scrollTo(0, 0);

      }
    };

    this.setState(
      {
        submit: true,
      }, validation);
  };

  register = async () => {
    const { subscription, selectedSub, captchaToken } = this.state;
    await this.props.clear_Error()
    let data = {};
    for (let x in subscription) {
      if (x === "paymentMode") {
        data[x] = parseInt(subscription[x]);
      } else {
        data[x] = subscription[x];
      }

    }
    data["captchaToken"] = captchaToken;
    data["cmpUpsertBody"] = selectedSub.cmpUpsertBody;


    await this.props.addSubscription(data, this.subscriptionCallback);


    if (this.props?.addSubscriptionerror) {


      if (this.props?.addSubscriptionerror.length > 0 && this.props?.addSubscriptionerror[0] !== null) {
        this.setState({
          openerrorModal: true
        })
      }
      this.setState({
        captchaToken: "",
      });
      this.setState({ subscription })
    }

  }

  closePopup = () => {
    this.setState({
      chequePaymentStatus: false
    })
    this.props.history.push("/");
  }


  setPhonecode = (value, field) => {
    const { subscription } = this.state;
    const { codes = [] } = extractTelCodes(this.props.telCodes, true);
    for (let x of codes) {
      if (x.value === value) {
        subscription.phoneCountryCode = x.telephoneCode
      }
    }
    this.setState({
      subscription, countryCode: value
    })
  }
  closeModal = () => {
    this.setState({
      openerrorModal: false
    })
  }

  render() {
    const { loading, telCodes } = this.props;
    const { subscription, submit, invalidPassword, ipsRequest, ipsUrl, chequePaymentStatus, success_msg, countryCode, confirmPassword, openerrorModal, captchaToken, captchaError } = this.state;
    const { codes = [], regions = [] } = extractTelCodes(telCodes, true);
    const { selectedSub } = this.props.location;
    const isdo2 = isNaN(parseFloat(selectedSub?.pricePdf));
    const isdo1 = isNaN(parseFloat(selectedSub?.priceArticle));
    // console.log(isdo1,selectedSub?.pricePdf,isdo2,selectedSub?.priceArticle );
    return (
      <>
        {chequePaymentStatus && <div style={{ height: "500px" }}>

          {chequePaymentStatus && <Modal customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closePopup()}>
            <div
            >
              {success_msg}
            </div>
          </Modal>}
        </div>}
        <div className="subscription-form-container" ref="subsform">
          <div className="subs-form">
            {openerrorModal && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closeModal()}>
              <h3> <span style={{ color: "red" }}>Error</span></h3>
              <ul>
                {this.props?.addSubscriptionerror.map((item, index) => {
                  return (
                    <li key={index}>{item}</li>
                  )
                })}
              </ul>
            </Modal>}
            <div className="col-md-10 m-auto p-0 pb-5">
              {loading ? <>
                <Spinner primary={true} />
                {this.state.ipsRequest ? <AutoSubmitForm actionUrl={ipsUrl} params={ipsRequest} /> : null}
              </>
                : <form
                  className="container-fluid"
                  noValidate
                  onSubmit={(e) => this.onSubmit(e)}
                >
                  <h1 className="mb-4 lable-xs">Subscription form</h1>
                  <div className="col-12 col-md-6 pr-5 pb-3 d-inline-block align-top">
                    <div className="col-md-12 d-flex p-0 form-control-content">
                      <label className="pr-2 pt-1 ">
                        UserId/Email<span>*</span>
                      </label>
                      <input className="form-control border-0" autoComplete="false"
                        value={subscription.userId}
                        onBlur={() => this.trimmer("userId")}
                        onChange={(e) =>
                          this.setSubscriptionDetails(e.target.value, "userId")
                        } />
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, subscription.userId)} */}
                        {spaceValidation(submit, subscription.userId.trim()) && "Remove space from the userId"}
                      </span>
                    </div>
                    <div className="col-md-12 d-flex p-0 pt-3 form-control-content">
                      <label className="pr-2 pt-1 ">Company<span>*</span></label>
                      <input className="form-control border-0" autoComplete="false"
                        onBlur={() => this.trimmer("company")} onChange={(e) =>
                          this.setSubscriptionDetails(e.target.value, "company")
                        } value={subscription.company} />
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, subscription.company)} */}
                      </span>
                    </div>
                    <div className="col-md-12 d-flex p-0 pt-3 form-control-content">
                      <label className="pr-2 pt-1 ">Password<span>*</span></label>
                      <input type="password"
                        className="form-control border-0"
                        autoComplete="false"
                        onChange={(e) =>
                          this.setSubscriptionDetails(e.target.value, "password")
                        } value={subscription.password} />
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, subscription.password)} */}
                      </span>
                    </div>
                    <div className="col-md-12 d-flex flex-nowrap p-0 pt-3 form-control-content">
                      <div> <label className=" pt-1 ">
                        Confirm password<span>*</span>
                      </label>
                      </div>
                      <div className="inputdown">
                        <input type="password"
                          className="form-control border-0 pb-0"
                          autoComplete="false" value={confirmPassword}
                          onChange={(e) => this.verifyPassword(e.target.value)} />
                      </div>
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {invalidPassword}
                      </span>
                    </div>
                    <div className="col-md-12 d-flex flex-nowrap p-0 pt-3 form-control-content">
                      <div> <label className=" p-0 ">
                        Email for notification<span>*</span>
                      </label></div>
                      <div className="inputdown"> <input className="form-control border-0" autoComplete="false" onChange={(e) =>
                        this.setSubscriptionDetails(e.target.value, "email")
                      } value={subscription.email} onBlur={() => this.trimmer("email")} /></div>
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, subscription.email, false, false, 0, "", true)} */}
                        {spaceValidation(submit, subscription.email.trim()) && "Remove space from the email"}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-inline-block">
                    <div className="col-md-12 d-flex p-0">
                      <div className="col-md-2 d-inline-block p-0 col-4 mr-2">
                        <SelectElement
                          defaultValue={subscription.salutation}
                          // className="p-1 select-control w-100"
                          className="white p-2"
                          options={salutations}
                          onChange={(e) =>
                            this.setSubscriptionDetails(e.target.value, "salutation")
                          }
                        />
                        <div className="pt-1 ">
                          <span className="error-message">
                            {/* {validationRules(submit, subscription.salutation)} */}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-10 p-0 ">
                        <div className="form-control-content">
                          <input className="form-control border-0" placeholder="First Name*" autoComplete="false"
                            onBlur={() => this.trimmer("firstName")} onChange={(e) =>
                              this.setSubscriptionDetails(e.target.value, "firstName")} value={subscription.firstName} />
                        </div>
                        <div className="pt-1 ">
                          <span className="error-message">
                            {/* {validationRules(submit, subscription.firstName)} */}
                            {/* {spaceValidation(submit,subscription.firstName) && "Remove space from the input"} */}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex p-0 pt-3">
                      <div className="col-md-2 d-inline-block col-4 mr-2">
                        <SelectElement
                          defaultValue={salutations[0].key}
                          className="d-none white p-2"
                          options={salutations}
                        />
                      </div>
                      <div className="col-md-10 p-0 ">
                        <div className="form-control-content">
                          <input className="form-control border-0" placeholder="Last Name*" autoComplete="false"
                            onBlur={() => this.trimmer("lastName")} onChange={(e) =>
                              this.setSubscriptionDetails(e.target.value, "lastName")
                            } value={subscription.lastName} />
                        </div>
                        <div className="pt-1">
                          <span className="error-message">
                            {/* {validationRules(submit, subscription.lastName)} */}
                            {/* {spaceValidation(submit,subscription.lastName) && "Remove space from the input"} */}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex p-0 pt-3 form-control-content">
                      <label className="pr-2 pt-1 ">Address</label>
                      <input className="form-control border-0" autoComplete="false"
                        onBlur={() => this.trimmer("address")} onChange={(e) =>
                          this.setSubscriptionDetails(e.target.value, "address")
                        } value={subscription.address} />
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, subscription.address)} */}
                        {/* {spaceValidation(submit,subscription.address) && "Remove space from the input"} */}
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-nowrap p-0 pt-3 form-control-content">
                      <div>
                        <label className="pr-1 pt-1 ">
                          Postal code<span>*</span>
                        </label>
                      </div>
                      <div className="inputdown1">
                        <input className="form-control border-0" autoComplete="false" onChange={(e) =>
                          this.setSubscriptionDetails(e.target.value, "postalCode")
                        } value={subscription.postalCode} onBlur={() => this.trimmer("postalCode")} />
                      </div>
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(
                          submit,
                          subscription.postalCode,
                          false,
                          true,
                          6
                        )} */}
                      </span>
                    </div>
                    <div className="col-md-12 d-flex p-0 pt-3">
                      <SelectElement
                        defaultValue={subscription.country}
                        className="p-1"
                        onChange={(e) =>
                          this.setCountryandPhonecountrycode(e.target.value, "country")
                        }
                        options={regions}
                      />
                    </div>
                    <div className="col-md-12 d-flex p-0 pt-3  form-control-content">
                      <label className="  pt-1 ">
                        Telephone<span>*</span>
                      </label>
                      <div >
                        <SelectElement
                          defaultValue={countryCode}
                          keyName="country"
                          // value="telephoneCode"
                          className=" d-inline-block  white"
                          options={codes}
                          onChange={(e) =>
                            this.setPhonecode(e.target.value, "phoneCountryCode")
                          }
                        />
                        <input
                          className="form-control d-inline-block form-control-content"
                          autoComplete="off"
                          maxLength="8"
                          value={subscription.phone} onBlur={() => this.trimmer("phone")}
                          placeholder="Phone no."
                          onChange={(e) =>
                            this.setSubscriptionDetails(e.target.value, "phone", true)
                          }
                        />
                      </div>
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, subscription.phone, false, true, 8)} */}
                        {/* {validationRules(submit, subscription.phoneCountryCode, true, false, 0, "Country code")} */}
                      </span>
                    </div>
                    <div className="col-md-12 d-inline p-0 pt-3">
                      <Checkbox checked />
                      <span className="ml-5 ">I have read and agree to the <b>Terms of Service</b>.</span>
                    </div>
                    <div className="order-summary-section">
                      <h3 className="mb-4 mt-4 summary-heading">Order Summary</h3>
                      <div className="summary-card ">
                        {/* <div className="col-md-12 m-auto p-0"> */}
                        <div className="col-md-7 col-6 d-inline-block p-0">
                          <span className="plan-name float-left">{selectedSub ? selectedSub.description : ""} </span>
                        </div>
                        <div className="col-md-5 col-6 d-inline-block p-0">
                          <span className="plan-price float-right">
                            <b>
                              <sup>
                                $
                              </sup>
                              {selectedSub ? selectedSub.fee : ""}
                            </b>
                            /<sub>yr</sub>
                          </span>
                        </div>
                        <hr />
                        <div className="col-md-10 col-10 d-inline-block p-0">
                          <h5 className="fee-header">Article retrieval fee:</h5>

                        </div>
                        <div className="col-md-2 col-2 d-inline-block p-0">
                          <h5 className="fee align-top">
                            <b>{!isdo1 && `$`}{selectedSub ? selectedSub.priceArticle : ""}</b>
                          </h5>

                        </div>
                        <div className="col-md-10 col-10 d-inline-block p-0">
                          <h5 className="fee-header">
                            Full Page PDF retrieval fee:
                          </h5>
                        </div>
                        <div className="col-md-2 col-2 d-inline-block p-0">
                          <h5 className="fee">
                            <b>{!isdo2 && `$`}{selectedSub ? selectedSub.pricePdf : ""}</b>
                          </h5>

                        </div>
                        {/* </div> */}
                      </div>
                      <div className="payment-type pb-4 pt-3">
                        <h6 className="payment-heading font-weight-bold">Payment type</h6>
                        {paymentTypes.map((pType) => (
                          <div className="col-md-6 d-inline-block p-0" key={pType.key}>
                            {/* label={pType.key}  */}
                            <Checkbox labelValue={pType.value} checked={subscription.paymentMode === pType.value} onChange={(e) =>
                              this.setSubscriptionDetails(e.target.getAttribute("label"), "paymentMode")
                            } />
                            <span className="margin-left-35 d-block">{pType.key} </span>
                          </div>
                        ))}
                        <div className="pt-1">
                          <span className="error-message">
                            {/* {validationRules(submit, subscription.paymentMode) !== "" ? "Please select payment type" : ""} */}
                          </span>
                        </div>
                      </div>
                      <>
                        <RecaptchaWidget getRecaptchaToken={(value)=>this.getRecaptchaToken(value)} token={captchaToken} error={captchaError}/>
                      </>
                      <div className="d-flex justify-content-end">
                        <ButtonElement
                          variant="contained"
                          btnType="transparent-black button-label"
                          className=" mr-2"
                          onClick={this.onBack}
                        >
                          <span className="button-label">BACK</span>
                        </ButtonElement>
                        <ButtonElement
                          type="submit"
                          variant="contained"
                          btnType="background-orange button-label"
                          className=" mr-1"
                          onClick={this.onSubmit}
                        >
                          <span className="button-label">SUBMIT</span>
                        </ButtonElement>
                      </div>
                    </div>
                  </div>
                </form>}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.applicationReducer.isLoading,
  telCodes: state.applicationReducer.telCodes,
  error: state.applicationReducer.error,
  referer: state.applicationReducer.referer,
  IP: state.applicationReducer.IP,
  addSubscriptionerror: state.applicationReducer.addSubscriptionerror,
  searchObject:state.applicationReducer.searchObject,
});

export default connect(mapDispatchStatetoProps, {
  getPreloads,
  startLoader,
  addSubscription,
  clear_Error,
  pageViewDatagtm,
  customViewDatagtm
})(SubscriptionForm);
