

import React, { useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function ScrollableTabsButtonAuto(props) {
  const [value, setValue] = React.useState(props.subDigitalTypeIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.tabChange(newValue,props.options?.subCategories[newValue]);
  };

  return (
    <>
   {props?.options && props?.options?.subCategories && props?.options?.subCategories.length > 0  && <Box sx={{ maxWidth: { xs: 320, sm: 480,lg:"100%"}, bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {/* <Tab label="ALL" />
        <Tab label="Heritage Photos" /> */}

       { props.options?.subCategories.map((item, index)=>{
              return (
                <Tab key={index} label={item} />
              )
        })
        }



      </Tabs>
    </Box>

  }
  </>
  );
}
