import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import reactReferer from "react-referer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { getContentData, getStringtoDate, getCookies, reverseDate, getPageviewobj, getCustomviewobj } from "../../helpers/common";
import { ButtonElement, Spinner, Modal, CustomSelect } from "../UI/index";
import { decodeToken } from "react-jwt";
import {
  getAnnouncements,
  getContentTypes,pageViewDatagtm,getSubDigitalType,
  setContentType,setsearchtype,customViewDatagtm,
  searchContent, startLoader, startTransLoader,setDurations,getdownloadBarInfo,
  stopTransLoader, setSearchObject,setDateObject,showLoginpopup,searchAutocomplete, getBadgeData,getsetadandAdvdata
} from "../../redux/actions/applicationsAction";
import SearchData from "./searchData";
import "./css/searchData.scss";
import { getUserDetails } from "../../redux/actions/loginAction";
import SearchResultNotFound from "../UI/Not Found/SearchResultNotFound";
import Popup from "../Admin/Popup/Popup";
import { downloaddoc } from "../../redux/actions/userAction";
import { debounce } from "lodash";
import SuggestionInput from "../UI/InputwithSuggestion/suggestionInput";



class SearchResponse extends Component {
  state = {

    searchObject: {
      dateRange: {
        fromDate: "",
        toDate: "",
      },
      keywords: "",
      // pageNo: 1,
      publication: [],
      pageSize: 10,
      digitalType: "",
      sortBy: "publicationdate",
      sortOrder: "desc",
      sourceType: "",
      advertiser: ""
    },
    pubListData: {},
    startDate: new Date(),
    endDate: new Date(),
    // minDate: getDate(true, 2),
    referer: reactReferer.referer(),
    digiType: "",
    resultfoundFor: [],
    openPopup: false,
    startLoading: true,
    multiplecallflag:false,
    tokenDetails:{},
    paymentAlert:false,
    paymentdownloadobj:{},
    progressbar:false
  };

  async componentDidMount() {
    const {searchObject, pubListData, resultfoundFor} = this.props?.location;
    const { digitalType } = this.props;

    await this.props.getUserDetails();
    const id_token = this.props?.userTokens?.id_token;
    const myDecodedToken = decodeToken(id_token);
    if (searchObject) {
      this.setState({
        keywords:searchObject.keywords,
        tokenDetails:myDecodedToken
      })
      for (let x in searchObject) {
        if(searchObject[x]){
          this.state.searchObject[x] = searchObject[x];
        }
      }

      if(id_token){
        let pubdata = myDecodedToken?.user_info?.pub_list;
        if(pubdata){
          searchObject.publication = searchObject?.publication.concat(pubdata);
        }
        let publication=[...new Set(searchObject?.publication)];
        searchObject.publication=publication;
      }
      if (pubListData) {
        for (let x in pubListData) {
          if(pubListData[x]){
            this.state.pubListData[x] = pubListData[x];
          }
        }

        this.setState({
          pubListData,
          resultfoundFor: [...this.state?.resultfoundFor, resultfoundFor]
        })
      }
      this.setState({
        searchObject,
        startDate: getStringtoDate(searchObject?.dateRange?.fromDate),
        endDate: getStringtoDate(searchObject?.dateRange?.toDate),
        digiType: digitalType,
        startLoading:true
      });
      await this.props.startLoader();
      let data = JSON.parse(JSON.stringify(this.state?.searchObject));
      if (this.state?.searchObject?.pageNo) {
        data.pageNo = parseInt(data?.pageNo)
      }
      this.props.setSearchObject(data);


      await this.props.searchContent(data);

      this.setState({
        startLoading:false
      })
    }
    else {
      if (this.props?.userTokens?.id_token) {
          if(this.props?.searchObject?.dateRange){
            this.props.setSearchObject(this.props?.searchObject);
            this.setState({ startLoading: false })
          }else{this.props.history.push("/");}
      } else {
        if(this.props?.searchObject?.dateRange){
          this.props.setSearchObject(this.props?.searchObject);
          this.setState({ startLoading: false })
        }else{this.props.history.push("/");}

      }
      if(this.props?.searchObject?.keywords){
        this.setState({
          keywords:this.props?.searchObject?.keywords
        })
      }
    }

    let obj =searchObject;
    if(searchObject===undefined){
      obj=this.props?.searchObject;
    }

    if(obj && obj.digitalType==="" && obj.sourceType==="ad_metadata"){
      this.props.setsearchtype(false);
    }else{
      this.props.setsearchtype(true);
    }
    this.props.setSearchObject(obj);
    this.setState({multiplecallflag:true});


  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let obj= getPageviewobj("",this.props.digitalType.toLowerCase(),"",user_status,"","","",id);
    this.props.pageViewDatagtm(obj,searchObject)
  }
  onStartDateChange = (date) => {
    this.props.setDateObject({startDate:date,endDate:this.props?.dateObject?.endDate})

    const { searchObject } = this.state;
    let dt = new Date(date);
    // let modifieddate= `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
    let modifieddate = dt.toLocaleDateString('en-GB');
    searchObject.dateRange["fromDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = this.props?.dateObject?.endDate.toLocaleDateString('en-GB');
    searchObject.dateRange["toDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.setState({
      searchObject,
      startDate: date
    });
    this.props.setSearchObject(searchObject)
  };

  onEndDateChange = (date) => {
    this.props.setDateObject({startDate:this.props?.dateObject?.startDate,endDate:date})
    const { searchObject } = this.state;
    let dt = new Date(date);
    let modifieddate = dt.toLocaleDateString('en-GB');
    searchObject.dateRange["toDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = this.props?.dateObject?.startDate.toLocaleDateString('en-GB');
    searchObject.dateRange["fromDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.setState({
      searchObject,
      endDate: date
    });

    this.props.setSearchObject(searchObject)
  };

  onPaginate = async (page) => {
    this.props.startTransLoader();
    const { searchObject } = this.props;
    searchObject.pageNo = page;
    // searchObject.sortBy= "publicationdate";
    // searchObject.sortOrder="desc";
    // this.props.setDurations("0");
    await this.props.searchContent(searchObject)
    this.setState({
      searchObject
    })

    await this.props.setSearchObject(searchObject);
    //gtm call//
    // this.customViewcall(searchObject,this.state.tokenDetails)
  }

  onChange = (value, key) => {
    const { searchObject } = this.props;
    searchObject[key] = value;
    this.setState({
      searchObject
    });


  }

  setContentType = async (contentType) => {
    await this.props.startLoader();
    this.setState({ digiType: contentType });
    const { contentTypes ,searchObject} = this.props;
    const contentData = getContentData(contentTypes, contentType);

    await this.props.setContentType({
      digitalType: contentType,
      extraData: contentData.extraData[0]
    });



      searchObject.digitalType = this.state.searchObject.digitalType = contentData.extraData[0].digitalType;
      searchObject.sourceType = this.state.searchObject.sourceType = contentData.extraData[0].sourceType;
      if(contentData.extraData[0].digitalType==="all"){
        searchObject.sourceType = this.state.searchObject.sourceType="all"
       }
       searchObject.sortBy= "publicationdate";
       searchObject.sortOrder="desc";

      if(this.props.subDigitalType==="HERITAGE"){
         await this.props.getSubDigitalType("ALL", 0);
         searchObject.section = [];
         searchObject.publication = [];
         this.props.getBadgeData({ sectionDatalist: [], badgeData: {} })
      }

      let data=searchObject;

      this.props.setDurations("0")
      this.setState({searchObject:data});
      await  this.props.setSearchObject(data);
      await this.props.startLoader();
      await this.props.searchContent(data);

      //gtm custom call

      this.customViewcall(data,this.state.tokenDetails)


  }

  onSearch = async () => {
    const { searchObject } = this.props;
    const { extraData } = this.props.applicationReducer;
    const {keywords} = this.state;
    searchObject.keywords=keywords && keywords.trim();
    searchObject.digitalType = extraData.digitalType;
    searchObject.sourceType = extraData.sourceType;
    if(searchObject.pdfPageNo){
      delete searchObject.pageNo
    }
    if (extraData.digitalType === "all") {
      searchObject.sourceType = "all"
    }

    if(searchObject && searchObject.publication && searchObject.publication.length > 0){
      let publication=[...new Set(searchObject.publication)];
      searchObject.publication=publication;
    }

    this.setState({ searchObject,
      keywords:keywords,
    })

    this.props.startLoader();
    const { contentTypes } = this.props;
    const contentData = getContentData(contentTypes, this.state.digiType);

    // await this.props.setContentType({
    //   digitalType: this.state.digiType,
    //   extraData: contentData.extraData[0],

    // });
    this.state.resultfoundFor = [];
    this.setState({ resultfoundFor: this.state.resultfoundFor })
    await this.props.startLoader();
    let data = JSON.parse(JSON.stringify(this.state.searchObject));
    if (this.state.searchObject.pageNo) {
      data.pageNo = parseInt(data.pageNo)
    }

    if(this.props.subDigitalType==="HERITAGE"){
      await this.props.getSubDigitalType("ALL", 0);
      data.section = searchObject.section = [];
      data.publication =searchObject.publication = [];
      this.props.getBadgeData({ sectionDatalist: [], badgeData: {} })
    }

    data.keywords= this.state.searchObject?.keywords
    searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.props.setDurations("0")
    //  if(this.props?.location?.showTabs?.pub_list){
    //   data.publication= data.publication.concat(this.props.location.showTabs?.pub_list)
    // }
    // if ((this.props.subDigitalType && this.props.subDigitalType !== "ALL") && data?.section && data?.section?.length > 0) {
    //   let sectionForHeritage = data.section;
    //   let newVals = sectionForHeritage.filter((val) => { return val !== this.props.subDigitalType });
    //   data.section = newVals
    // }

    await this.props.searchContent(data);
    this.props.setSearchObject(data)
    // gtm call custom
    this.customViewcall(data,this.state.tokenDetails)
  }


  searchfromchild = async (searchObject, badges, badgeData) => {
    if (badges) {
      this.state.sectionDatalist = badges;
      this.state.badgeData = badgeData;

    }
    await this.props.startLoader();
    let data = JSON.parse(JSON.stringify(searchObject));
    for (let x in badgeData) {
      if (badgeData[x].length > 0) {
        data[x] = [];
        for (let y of badgeData[x]) {
          if (y) {
            data[x].push(y)
          }
        }
      }
    }
    if (this.state?.searchObject?.pageNo) {
      data.pageNo = parseInt(data?.pageNo)
    }
    const id_token = this.props?.userTokens?.id_token;
    const myDecodedToken = decodeToken(id_token);
    let pubdata = myDecodedToken?.user_info?.pub_list;
    if(pubdata){
      data.publication = data?.publication.concat(pubdata);
    }
    let publication=[...new Set(data?.publication)];
    data.publication=publication;

    await this.props.searchContent(data);
    this.props.setSearchObject(data)
    //gtm call custom
    this.customViewcall(data,this.state.tokenDetails)
  }


  openArticle = async (data, file,frompaymentBox) => {
    // let dowloadbarobjs=this.props.downloadBarInfo?.data[0] ? this.props.downloadBarInfo.data[0] :[];
    // let isthere=dowloadbarobjs.filter((item)=>item.downloadId ==='Photo.jpeg');

    // if(!isthere || dowloadbarobjs.length===0){


    //   let rnndn=Math.random();
    //   let obj={
    //     name: `Photo${rnndn}`,
    //     downloadId: `Photo.jpeg`,
    //     thumb: "https://images.unsplash.com/photo-1604264849633-67b1ea2ce0a4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80 750w",
    //     file:"https://images.unsplash.com/photo-1604264849633-67b1ea2ce0a4?rnd=" + rnndn,
    //     filename: `Photo${rnndn}`,
    //     complete:false
    //  }
    //  let dowloadobj=this.props.downloadBarInfo.data[0] ?this.props.downloadBarInfo.data[0] :[];
    //  dowloadobj.push(obj)
    //  this.props.getdownloadBarInfo(true,dowloadobj);


    // }

    const { searchObject } = this.props;
    this.setState({
      progressbar:true
    })

    const isloggedin = localStorage.getItem("token");
    if (!this.props.userTokens) {
      //  await this.props.getUserDetails();
    }
    // const {id_token} = this.props.userTokens;
    const id_token = getCookies("id_token");
    const myDecodedToken = decodeToken(id_token);
    if(!id_token || !isloggedin){
      this.showlogin();
       //gtm call
       this.setPageViewcallforlogin(searchObject,{},"login","Login",`/search/virtual/login`)
    }else{
      // let iskeyWord;
      let key;

        if(searchObject?.keywords && myDecodedToken?.user_info?.highlight_text){
          let trimword=searchObject.keywords.trim();
          // iskeyWord=trimword.split(" ");
        // if(iskeyWord.length ===1){
        //       key=iskeyWord[0];
        // }
        key=trimword;
      }

    await this.props.startTransLoader();
     const {setSearchtype} = this.props;

      if (file) {
        let dowloadbarobj = this.props.downloadBarInfo.data[0] ? this.props.downloadBarInfo.data[0] : [];
        // let isthere = dowloadobj.filter((item) => item.downloadId !== data.pdfDocumentId);
        // if (!isthere.length > 0 || dowloadobj.length > 0) {
          let downloadObj = {
            "digitalType": !setSearchtype ? "" : "pdf",
            "documentId": data.pdfDocumentId,
            "sourceType": searchObject.sourceType,
            "keywords": "",
          }
          if(!this.props.setSearchtype){
            downloadObj.chargeForAdDownload= frompaymentBox ? true : false;
          }
          if (searchObject.digitalType === "article" && searchObject.sourceType === "article") {
            downloadObj.sourceType = "fullpagepdf";
          }
          if (searchObject.digitalType === "picture") {
            downloadObj.sourceType = "fullpagepdf";
          }
          if (searchObject.digitalType === "all") {
            if (data?.digitaltype === "picture" || data?.digitaltype === "article") {
              downloadObj.sourceType = "fullpagepdf";
            } else {
              downloadObj.sourceType = data.sourcetype;
            }
          }
          await this.props.startTransLoader();
          await this.props.downloaddoc(downloadObj);
          if (this.props?.downloaddata === "open alert") {
            this.showPayAlert(data, file);
            await this.props.stopTransLoader();
          }else if(this.props?.downloaddata === "open dialbox"){
            this.setState({ openPopup: true });
            await this.props.stopTransLoader();
          }
           else
            if (this.props?.downloaddata?.singleDocument?.pdfPath) {
              // this.customViewcall(searchObject,this.state.tokenDetails,this.props?.downloaddata?.singleDocument.publicationdate);
              var urlpdf = this.props?.downloaddata?.singleDocument?.pdfPath;
              if (urlpdf) {
                let rndm=Math.random();
                let obj = {
                  name: this.props?.downloaddata?.singleDocument?._id + rndm,
                  downloadId: data.pdfDocumentId,
                  file: this.props?.downloaddata?.singleDocument?.pdfPath,
                  filename: `${this.props?.downloaddata?.singleDocument?._id + rndm}.pdf`,
                  complete:false
                }
                dowloadbarobj.push(obj);
                this.props.getdownloadBarInfo(true, dowloadbarobj);

                // FileSaver.saveAs(urlpdf, `${this.props?.downloaddata?.singleDocument?._id}.pdf`,this.dd());

                // var link = document.createElement('a');
                // link.href = urlpdf;
                // link.target="_blank";
                // link.download=this.props?.downloaddata?.singleDocument?._id ? `${this.props?.downloaddata?.singleDocument?._id}.pdf`:"document.pdf";
                // // link.setAttribute('download', `${this.props?.downloaddata?.singleDocument?._id}.pdf`); //or any other extension
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);
              }
              await this.props.stopTransLoader();
            } else {

              await this.props.stopTransLoader();
            }
        // }
      } else {

      // this.state.startLoading=true;
      let downloadObj ={
        "digitalType": searchObject.digitalType,
        "documentId": data.documentid,
        "sourceType": searchObject.sourceType,
        "keywords":key ? key : ""
      }
      if(!this.props.setSearchtype){
        downloadObj.chargeForAdDownload= frompaymentBox ? true : false;
      }

      if (searchObject.digitalType === "article"  && searchObject.sourceType === "article") {
        downloadObj.sourceType = "article"
      }
      if(searchObject.digitalType === "all"){
        downloadObj.digitalType=data.digitaltype;
        downloadObj.sourceType=data.sourcetype;
      }
      await this.props.downloaddoc(downloadObj)

      if (this.props?.downloaddata) {
        // this.customViewcall(searchObject,this.state.tokenDetails,this.props?.downloaddata?.singleDocument.publicationdate);
        this.state.startLoading = true;
        this.props.history.push({
          pathname: '/articleDetails',
          data: this.props.downloaddata,
          state:searchObject
        })
        this.setState({ startLoading: false })
      }
      this.props.stopTransLoader();
    }

  }
  }

  setPageViewcallforlogin=(searchObject,myDecodedToken,lavel2,title,urladd)=>{
   let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
   let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : "";
   let url=window.location.origin+urladd
   let obj= getPageviewobj(url,lavel2,title,user_status,"","","",id);
   this.props.pageViewDatagtm(obj,searchObject)
 }
  closepopUp = () => {
    this.setState({ openPopup: false });
    this.props.downloaddoc("",true)
  }
  showlogin =() =>{
    this.props.showLoginpopup(true);
  }
  showPayAlert=(data,file)=>{
     this.setState({paymentAlert:true,
      paymentdownloadobj:{data:data,file:file}
    });

  }
  closePaymentAlert=()=>{
    this.setState({paymentAlert:false});
  }
  proceedDownload=()=>{
    const {paymentdownloadobj}= this.state;
    this.setState({paymentAlert:false});
    this.openArticle(paymentdownloadobj.data,paymentdownloadobj.file,true)
  }
  dd =() =>{

    this.props.stopTransLoader();
  }

  onChange2 = async(value,obj,enterflag, key) => {
    const { searchObject } = this.props;
    if(this.state.multiplecallflag){
    this.setState({
      keywords:value
    });
    if(key==="keywords"){
      let strings=value.trim();
      if((searchObject?.sourceType==="article" || searchObject?.sourceType==="ext_article" || searchObject?.sourceType==="online_article") && strings.length > 3){
      this.onChangeSuggestion(strings);
      }
      else{
        this.props.searchAutocomplete("",true);
        if(enterflag){
          this.onChangeEnters();
        }

     }
    }
    if(obj){
      await this.props.startLoader();
      obj.pageNo=1;
      searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.props.setDurations("0")
     await this.props.searchContent(obj);
    }
  }
  }
  onChangeSuggestion= debounce((val) => {
    const { searchObject } = this.props;
    this.props.searchAutocomplete({
      "digitalType": searchObject.digitalType,
      "keywords": val,
      "sourceType": searchObject.sourceType,
      "dateRange":{fromDate:searchObject?.dateRange?.fromDate,
        toDate:searchObject?.dateRange?.toDate}
    })
  }, 500);

  onClearSearch=async()=>{
   const {searchObject} = this.props;
   searchObject.keywords="";
   searchObject.pageNo=1;
   this.props.setSearchObject(searchObject);
   await this.props.startLoader();
   searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.props.setDurations("0")
   await this.props.searchContent(searchObject);
   // gtm call
   this.customViewcall(searchObject,this.state.tokenDetails)
  }
  onChangeEnters=async ()=>{
    const { searchObject } = this.props;
    // alert(this.state.keywords);
    if((searchObject?.sourceType==="article" || searchObject?.sourceType==="ext_article" || searchObject?.sourceType==="online_article")){
      }
      else{
    if(this.state.keywords){
      let obj=this.props.searchObject;
      obj.keywords=this.state.keywords.trim();
      obj.pageNo=1;
      obj.sortBy= "publicationdate";
      obj.sortOrder="desc";
    this.props.setDurations("0")
      await this.props.setSearchObject(obj);
      await this.props.startLoader();
      await this.props.searchContent(this.props.searchObject);
      //gtm call
      this.customViewcall(this.props.searchObject,this.state.tokenDetails)
    }
    document.removeEventListener("keyup",console.log(""));
  }
  }

  customViewcall=(searchObject,token,pudate)=>{
    let user_status=token?.user_info?.user_type ? token?.user_info?.user_type:"" ;
    let id=token?.user_info?.visitor_id ? token?.user_info?.visitor_id : "";
    let eventlabel=this.props?.searchContents?.totalCount === 0 ? "not found" : ""
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate)
    let objj= getCustomviewobj("search","click","","search",eventlabel,user_status,"","", pudate ? pudate : "",id)
    this.props.customViewDatagtm(objj,searchObject)
  }

  render() {
    const { contentTypes, digitalType, isLoading } = this.props;
    const { selectedData } = this.props.location;
    const { searchObject, pubListData, resultfoundFor, startLoading,keywords } = this.state;
    // const islogin = localStorage.getItem("token");
    // const isseamlesslogin= localStorage.getItem("seamlessFlag");

    return (
      <>
        {startLoading ? (
          <div className="text-center pt-5 pb-5 tranparentscreen2 ">
            <Spinner  primary={true} transparent={true} />
          </div>
        ) : (
          <div className="col-12 col-md-12 pt-2 dashboard search-response p-0 stickbutton">
            {this.props.setSearchtype && <>  <div className="col-12 col-md-11 offset-lg-1 pb-1 pt-5 ">

              <div className="textcenterclass ">
                {/* <div className="search-container d-inline-block col-md-4 col-12 p-0 pb-3">
                  <input
                    name="search-text"
                    type="text"
                    placeholder="Search, 搜索, Carian, தேடல்"
                    value={this.props.searchObject?.keywords}
                    autoComplete="off"
                    maxLength="240"
                    className="search-input p-1 pl-4 w-100"
                    aria-label="Search"
                    onChange={(e) => this.onChange2(e.target.value, "keywords")}
                  />
                  <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </div> */}
                <div className="d-inline-block sgtinput">
                 <SuggestionInput placeholder="Search, 搜索, Carian, தேடல்" value={keywords}  onClear={()=>this.onClearSearch()}
              onChange={(e,obj,enterflag) => this.onChange2(e, obj,enterflag,"keywords")} id={"one"} suggesterData={this.props.autoComplete}
              // searchObject={this.props?.searchObject}
              onChangeEnter={()=>this.onChangeEnters()}
              ></SuggestionInput>  <span className="searchiconcls"><FontAwesomeIcon icon={faSearch} className="search-icon" onClick={()=>this.onSearch()} /></span>
              </div>
                <div className="col-md-2 content-types col-12  pl-3 pr-3 pb-3 mobileContentTypes">
                  {contentTypes !== undefined && contentTypes.length > 0 && (
                    // <SelectElement
                    //   defaultValue={digitalType || contentTypes[0].key}
                    //   onChange={(e) =>
                    //     this.setContentType(e.target.value)
                    //   }
                    //   options={contentTypes}
                    // />
                    <CustomSelect options={contentTypes}  defaultValue={digitalType || contentTypes[0].key} id="selecticondrop"
                onChange={(e) =>
                 this.setContentType(e)
               }
                ></CustomSelect>
                  )}
                </div>
                <div className="search-button-container simple-search col-md-2 col-12 mobileContentTypes">
                  <ButtonElement
                    variant="contained"
                    btnType="background-orange"
                    className="d-inline-block mr-2 search-button" onClick={this.onSearch}
                  >
                    Search
                  </ButtonElement>
                </div>
                <div className="search-button-container text-center col-md-4 col-12 d-inline-block">
                  {<><Link
                    to={
                      {
                        pathname: "advanced-search",
                        searchObject: this.props?.searchObject,
                        pubListData: pubListData ? pubListData : {},
                        selectedData: selectedData ? selectedData : {}
                      }
                    }
                    className="font-medium advanced-search-text"
                  >
                    Advanced Search &nbsp;
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="lg"
                      className="search-icon"
                    />
                  </Link></>} &nbsp;  &nbsp;

                </div>
                {/* {!this.props.setSearchtype && <div className="search-button-container text-center col-md-4 col-12 d-inline-block">
           <Link
                to={
                  {
                    pathname: "ad-search",
                    searchObject: this.state.searchObject,
                    pubListData:pubListData?pubListData : {},
                    selectedData:selectedData?selectedData:{},
                    showTabs: this.props.location.showTabs,
                  }
                }
                className="font-medium advanced-search-text"
              >
                Ad Search  &nbsp;  &nbsp;
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size="lg"
                  className="search-icon"
                />
              </Link>

            </div>} */}

              </div>
            </div></>}
            {/* {isLoading  ? (
          <div className="text-center pt-5 pb-5">
            <Spinner primary={true} />
          </div>
        ) : ( */}
            <div className="search-data-container">
              {isLoading &&
                <div className="text-center pt-5 pb-5 tranparentscreen2">
                  <Spinner primary={true} transparent={true} />
                </div>
              }
              {/* { this.props.searchContent.totalCount=0 ||  !this.props.searchContents.content && !isLoading  &&
           <SearchResultNotFound  keywords={searchObject.keywords}></SearchResultNotFound>
          } */}

              {this.props?.searchContents?.totalCount === 0 && !startLoading &&
                <SearchResultNotFound keywords="Result Not Found" tokens={this.props?.userTokens?.id_token} pdfpagenumber={this.props.searchObject.pdfPageNo} searchObj={this.props.searchObject} ></SearchResultNotFound>
              }
              {this.props?.searchContents?.content && this.props?.searchContents?.content.length > 0 &&
                <SearchData searchObject={searchObject} onPaginate={this.onPaginate} pubListData={pubListData} resultfoundFor={resultfoundFor ? resultfoundFor : ""}
                  searchfromchild={this.searchfromchild} sectionDatalist={this.state?.sectionDatalist} badgeData={this.state?.badgeData} openArticle={this.openArticle}{...this.props}
                />}
            </div>
            {/* )}  */}

            <>
              {this.state.openPopup && <Modal
                // customClass={`${showinfoScreen ? "login-modal" : "forgot-password-modal"}`}
                customClass={'forgot-password-modal'}
                closeModal={this.closepopUp}
                // loading={showinfoScreen}
                onClick={() => this.closepopUp()}
              >
                {(this.props?.downloaddata ==="open dialbox") && <>
                <div className="col-lg-12 col-md-12 p2-3 overflow-auto">
                      <h4 className="m-0 fontsizeHtag" >{this.props.dowloadPopUpMsg}</h4><br />
                      <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closepopUp()}
                            >
                                <span className="button-label-block btnpopup">CLOSE</span>
                            </ButtonElement>
                </div>
                </>}
                {/* {(!islogin || !isseamlesslogin) && <Popup titles="Please login to access" onClose={this.closepopUp} {...this.props} />} */}


              </Modal>}
            </>
            <>
              {this.state.paymentAlert &&  <Modal onClick={() => this.closePaymentAlert()} customClass={'forgot-password-modal'} >
                <div className="col-lg-12 col-md-12 p2-3 overflow-auto">
                      {/* <p className="m-0">nlink-web-11.servers.asia1.com.sg:28086 says</p><br/> */}
                      <p className="m-0">Notice :</p><br/>
                      <p className="m-0">The Newslink PDF service is solely in respect of SPH Media content</p>
                      <p className="m-0">ie. articles, pictures and the other materials created </p>
                      <p className="m-0">and owned by SPH Media. </p>
                      <p className="m-0">No representation is given in respect of all other content.</p><br/>

                      <p className="m-0"><b>{this.props.dowloadPopUpMsg}</b></p>
                      <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block  "
                                onClick={() => this.closePaymentAlert()}
                            >
                                <span className="button-label-block btnpopup">Cancel</span>
                            </ButtonElement> {" "}
                      <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.proceedDownload()}
                            >
                                <span className="button-label-block btnpopup">OK</span>
                            </ButtonElement>
                </div>

              </Modal>}
            </>
          </div>
        )

        }


      </>

    );

  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  announcements: state.applicationReducer.announcements,
  contentTypes: state.applicationReducer.contentTypes,
  contentType: state.applicationReducer.contentType,
  searchContents: state.applicationReducer.searchContent,
  digitalType: state.applicationReducer.digitalType,
  isLoading: state.applicationReducer.isLoading,
  downloaddata: state.userReducer.downloaddata,
  setSearchtype: state.applicationReducer.setSearchtype,
  userTokens: state.loginReducer.tokens,
  searchObject:state.applicationReducer.searchObject,
  autoComplete:state.applicationReducer.autoComplete,
  dateObject: state.applicationReducer.dateObject,
  downloadBarInfo:state.applicationReducer.downloadBarInfo,
  dowloadPopUpMsg:state.userReducer.dowloadPopUpMsg,
  subDigitalType:state.applicationReducer.subDigitalType
});

export default connect(mapDispatchStatetoProps, {
  getAnnouncements,
  getContentTypes,
  setContentType,
  searchContent,
  startLoader,
  getUserDetails,
  downloaddoc,
  startTransLoader,
  stopTransLoader,
  setSearchObject,
  setDateObject,
  showLoginpopup,
  searchAutocomplete,
  setsearchtype,
  pageViewDatagtm,
  customViewDatagtm,
  setDurations,
  getdownloadBarInfo,
  getSubDigitalType,
  getBadgeData,
  getsetadandAdvdata
})(SearchResponse);
