import React, { Component } from 'react';
import './accordian.css';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const parse = require('html-react-parser');

class Accordian extends Component {
    constructor() {
        super();
        this.state={
            isActive:false,
            toggleobj:{},
            isActive2:false
        }
    }

    setIsActive=()=>{
        this.setState({
            isActive:!this.state.isActive
        })
    }

    setIsActive2=(flag,index)=>{
    //    console.log(flag,index);
       this.state.toggleobj[index]=flag
       this.setState({
           isActive2:flag,
           toggleobj: this.state.toggleobj
       })
    }

    render() {
        const { isActive2, isActive,toggleobj } = this.state;
        const { data } = this.props;
        return (
            // <div className='container'>
                <div className="accordion_item row">
                    <div className="accordion_title col-10 pt-3" onClick={() => this.setIsActive(!isActive)}>
                        <div className={isActive ? 'labels' :'labelss'}>{this.props.title}</div>
                    </div>
                    <div className='col-2 text-center pt-3'style={{float:'right'}} onClick={() => this.setIsActive(!isActive)}>{isActive ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</div>
                    {isActive && <>

                        {this.props && this.props.data.map((dt, index) => {


                            if(dt.question){

                                return (
                                    <div className="accordion_content accordion_item ">
                                    <div className="row " key={index}>
                                        <div className="accordion_title col-10  pt-3" onClick={() => this.setIsActive2(!isActive2, index)}>
                                            <div className='labelss'>{dt.question}</div>
                                        </div>
                                        <div className='col-2  pt-3' onClick={() => this.setIsActive2(!isActive2,index)}>{toggleobj[index] ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</div>
                                        {toggleobj[index] && <div className="col-10 accordion_content ">
                                            {parse(dt.answer)}
                                        </div>
                                        }
                                    </div>
                                </div>
                                )
                            }
                            if(dt.link){
                                return (
                                    <div className="accordion_content accordion_item ">
                                    <div className="row pt-3" key={index}>
                                        <div className="accordion_title col-10" >
                                            <a href={dt.link} target="_blank">{dt.link_label}</a>
                                        </div>
                                    </div>
                                    </div>
                                )
                            }


                        })}


                       </>}

             </div>
        );
    }
}

export default Accordian;
