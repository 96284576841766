import React, { Component } from "react";
import { connect } from "react-redux";
import reactReferer from "react-referer";
import { getIcon } from "../../../helpers/common";
import "./css/contentType.scss";
import DOMPurify from 'dompurify';
class Article extends Component {
  state = {
    referer: reactReferer.referer(),
  };

  async componentDidMount() {
  }
  goArticleDetails=(data)=>{
      this.props.history.push({
        pathname: '/articleDetails',
        data: data,
      })
  }
  onClick=(data,file) =>{
    if(this.props.passArticle){
      this.props.passArticle(data,file)
    }
  }



  render() {
    const {data,imageflag,ispdf,iconflag}=this.props;
    let pgno;
    if (data?.pageno === data?.endpageno) {
      pgno = data.pageno;
    } else {
      pgno = `${data.pageno}-${data.endpageno}`;
    }
    return (
      <div className="pb-2 container">
      {data && <div className="article-container  row ">
      <div className="col-md-1  ">
      <img  src={getIcon(iconflag, true)}  alt="logo" className="" />
      </div>
         {ispdf && imageflag && data.thumbnailPath &&<div className="col-md-3  col-12 hideimg ">
            <img src={data.thumbnailPath} className="h-90 w-100 imagess" alt="article" />
          </div>}

          <div className={this.props.digitalType==="ANN" ? " col-11 d-inline-block":"col-md-8 col-12 d-inline-block"}>
            {/* <h6 style={{cursor:"pointer"}} onClick={()=>this.onClick(data)}>{data.headline_en}</h6> */}
            <div style={{cursor:"pointer"}} onClick={()=>this.onClick(data)}
               key={data.documentid}
               className="headline_en"
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(data.headline_en)}`,
                }}
              ></div>
            {/* <p>{data.teaser}</p> */}
           <div
               className="reshape"
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(data.teaser)}`,
                }}
              ></div>
            <p className="by" >
              {data.byline_en && <span>  By: {data.byline_en} &nbsp;</span>}
               {data?.publicationName && <span>{data?.publicationName}</span> }
              {data.pagename && <span>&nbsp;({data.pagename})</span>}
               &nbsp; {data?.date}/{data?.month}/{data?.year}</p>
            {ispdf && imageflag && data.pageno && <div className="pageName" ><img
              src={getIcon("PDF", true)}
              alt="logo" height="20px"
              className="logo d-inline-block"
            /> <span onClick={()=>this.onClick(data,"pdf")} style={{cursor:"pointer"}} data-documentid={data.documentid}>Page {pgno}</span>
            </div>}
          </div>
        </div>}
        <div className="pt-1">
        <hr />
        </div>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  contentType: state.applicationReducer.contentType,
  digitalType:state.applicationReducer.digitalType

});

export default connect(mapDispatchStatetoProps, {})(Article);
