import React, { Component } from "react";
import { connect } from "react-redux";
import "./userlist.scss";
import {
  ButtonElement,
  DataTable,
  Paginationnextpre,
  Spinner,
  Modal,
} from "../../../UI/index";
import {
  getUserList,
  updateStatus,
  startLoader,
} from "../../../../redux/actions/adminAction";
import { getUserDetails } from "../../../../redux/actions/loginAction";
import {
  findUser,
  findUsageDetails,
  clearAdminError,
  getUsageDetailByOrderId,
} from "../../../../redux/actions/adminAction";
// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "../../Popup/Popup";
import UsageDetailsPopup from "./UsageDetailsPopup";
import ErrorPopup from "../../Popup/ErrorPopup";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../config/constants/index";
import { debounce } from "lodash";

const Actions = [{ actions: ["Edit Subscription", "Suspended", "Delete"] }];
const Actions_usage = [{ actions: ["Detail"] }];

export class UserAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loading: false,
      modal: false,
      UsersDetailsData: [],
      statusUpdate: {
        status: "",
        userIds: [],
      },
      currentPage: 1,
      selectAll: false,
      updateStatusArr: [],
      totalCount: null,
      searchObj: null,
      isUsageDetails: null,
      specificSelect: [],
      detail: true,
      selectedRow: {},
      filteredUsageDetail: [],
      usageDetailByOrderId: {},
      message: "",
      errData: [],
      success: false,
      userData: [],
      userID: 0,
      actionConfirmPopup: false,
      actiontype: "",
      row: "",
      index: "",
      actionFlag: "",
      msgStatus: "",
    };
  }
  async componentDidMount() {
    const { filteredUsageDetail } = this.state;
    // this.props.startLoader();
    // await this.props.getUserDetails()
    if (this.props?.location && this.props.location.useraccountData) {
      var userArr = [];
      if (this.props?.location?.isUsageDetails) {
        const usage_data = this.props.location.useraccountData;
        this.parseUsageDetailData(usage_data);
      } else {
        const User_Data = this.props?.location?.useraccountData?.userInfo;
        this.setState({
          userID:
            this.props.userDetails &&
            this.props?.userDetails?.userInfo[0]?.userId,
        });
        userArr = this.parseUserDetail(User_Data, false);
      }
      this.setState({
        UsersDetailsData: this.props?.location?.isUsageDetails
          ? filteredUsageDetail
          : userArr,
        totalCount: this.props.location.totalCount,
        searchObj: this.props.location && this.props.location.searchObj,
        isUsageDetails: this.props.location.isUsageDetails,
        currentPage: 1,
      });
    }
  }
  handleUser = (usage) => {
    this.props.history.push({
      pathname: "/admin/userdetail-subscription",
      searchObject: usage,
    });
  };

  actionFromChild = async (actiontype, row, index) => {
    // const { statusUpdate, searchObj, currentPage } = this.state
    if (actiontype === "Delete" || actiontype === "Suspended") {
      let status =
        actiontype === "Delete"
          ? "DELETED"
          : actiontype === "Suspended"
          ? "SUSPENDED"
          : "";
      let arr = [];
      if (this.props.userDetails?.userInfo[index].status === status) {
      } else {
        this.setState({
          actionConfirmPopup: true,
          actiontype: actiontype,
          row: row,
          index: index,
          msgStatus:
            actiontype === "Suspended"
              ? "'Suspend' this user?"
              : "'Delete' this user?",
        });
        // arr.push(this.props.userDetails?.userInfo[index].userId)
        // statusUpdate.status = status
        // statusUpdate.userIds = arr
        // await this.recaptcha.execute();

        // this.setState({
        //     statusUpdate
        // })
        // await this.props.startLoader();
        // await this.props.clearAdminError();
        // await this.props.updateStatus(statusUpdate);
        // if (this.props.updatedStatusRes?.message) {
        //     this.setState({
        //         success: true,
        //         message: this.props.updatedStatusRes?.message,
        //         errdata: []
        //     })
        //     searchObj.pageNumber = currentPage;
        //     await this.props.findUser(searchObj);
        //     let data = this.props.userDetails && this.props.userDetails.userInfo
        //     this.parseUserDetail(data, true)
        // } else if (this.props.updatedStatusErrorRes?.length >= 0) {
        //     this.setState({
        //         success: true,
        //         errdata: this.props.updatedStatusErrorRes,
        //         message: []
        //     })
        // }
      }
    }
    if (actiontype === "Edit Subscription") {
      await this.props.history.push({
        pathname: "/admin/userdetail-subscription",
        searchObject: this.props.userDetails?.userInfo[index],
        // index: index
      });
    }
    if (actiontype === "Detail") {
      await this.props.getUsageDetailByOrderId(
        Number(
          this.props.usageDetailsData &&
            this.props.usageDetailsData.usageDetails[index].orderId
        )
      );
      let row_detail = row;
      let usageDetailByOrderId = this.props?.usageDetailByOrderId;
      this.setState({
        selectedRow: row_detail,
        usageDetailByOrderId: usageDetailByOrderId,
        detail: false,
      });
    }
  };
  onAction = async (action) => {
    let msg =
      action === "DELETED" ? "'Delete' these users?" : "'Suspend' these users?";

    this.setState({
      actionFlag: action,
      msgStatus: msg,
      actionConfirmPopup: true,
    });
  };
  onConfirm = async (e) => {
    if (!this.state.actionFlag) {
      const { statusUpdate, searchObj, currentPage, actiontype, index } =
        this.state;
      let status =
        actiontype === "Delete"
          ? "DELETED"
          : actiontype === "Suspended"
          ? "SUSPENDED"
          : "";
      let arr = [];
      arr.push(this.props.userDetails?.userInfo[index].userId);
      statusUpdate.status = status;
      statusUpdate.userIds = arr;
      this.setState({
        statusUpdate,
        actionConfirmPopup: false,
        actiontype: "",
        row: "",
        index: "",
        msgStatus: "",
      });
      await this.recaptcha.execute();
      await this.props.clearAdminError();
      await this.props.startLoader();
      await this.props.updateStatus(statusUpdate);

      if (this.props.updatedStatusRes?.message) {
        this.setState({
          success: true,
          message: this.props.updatedStatusRes?.message,
          errdata: [],
        });
        searchObj.pageNumber = currentPage;

        await this.props.findUser(searchObj);

        let data = this.props.userDetails && this.props.userDetails.userInfo;
        this.parseUserDetail(data, true);
      } else if (this.props.updatedStatusErrorRes?.length >= 0) {
        this.setState({
          success: true,
          errdata: this.props.updatedStatusErrorRes,
          message: [],
        });
      }
      this.setState({
        actionConfirmPopup: false,
      });
    } else {
      this.updateUserAccountStatus(this.state.actionFlag);
    }
  };
  checkboxActionsFormChild = (e, index, row, selectAll, childprops) => {
    let updateedArr = [];
    const { updateStatusArr } = this.state;

    if (selectAll) {
      updateedArr = this.props.userDetails?.userInfo.map((user) => user.userId);
      this.setState({
        updateStatusArr: updateedArr,
      });
    } else {
      if (e.target.checked === true) {
        this.setState({
          updateStatusArr: [
            ...updateStatusArr,
            this.props?.userDetails?.userInfo[index]?.userId,
          ],
        });
      } else {
        if (index < 0) {
          this.setState({
            updateStatusArr: [],
          });
        } else {
          let selectedrows = updateStatusArr.filter(
            (selectedRow) =>
              selectedRow !== this.props.userDetails?.userInfo[index].userId
          );
          this.setState({
            updateStatusArr: selectedrows,
          });
        }
      }
    }
  };
  onPaginate = async (page) => {
    const { searchObj, isUsageDetails } = this.state;
    this.setState({
      currentPage: page,
    });

    searchObj.pageNumber = page;
    if (!isUsageDetails) {
      console.log("find user", this.props);
      this.props.startLoader();
      await this.props.findUser(searchObj);
      let data = this.props.userDetails && this.props.userDetails.userInfo;
      this.parseUserDetail(data, false);
    } else {
      await this.props.clearAdminError();
      this.props.startLoader();
      await this.props.findUsageDetails(searchObj);

      let usagedetails = [];

      console.log("useage detail", this.props);
      usagedetails =
        this.props.usageDetailsData && this.props.usageDetailsData.usageDetails;
      this.parseUsageDetailData(usagedetails);
    }
    searchObj.pageNumber = 1;
    this.setState({
      searchObj,
    });
  };
  parseUserDetail = (User_Data, flag) => {
    let userArr = [];

    for (let user of User_Data) {
      let obj1 = {
        // userId: user.userId,
        "Login/Username": user.login,
        "User status":
          user.status === "PENDING" ? (
            <span className="pendingSub">{user.status}</span>
          ) : user.status === "ACTIVE" ? (
            <span className="activeSub">{user.status}</span>
          ) : user.status === "SUSPENDED" ? (
            <span className="suspended">{user.status}</span>
          ) : (
            <span className="Deleted">{user.status}</span>
          ),
        company: user.company,
        contact: user.contact,
        "create Date": user.createOn,
        email: user.email,
        externalPackage: user.externalPackage,
        newsLinkPackage: user.newsLinkPackage,
        // status: user.status,
        // userId: user.userId,
        // "ACTIVE": 2,
        // "SUSPENDED": 3,
        // "PENDING": 1,
        // "DELETED": 4

        // accessCount: user.accessCount,
        usertype: user.usertype,
      };

      userArr.push(obj1);
    }

    this.setState({
      userData: userArr,
      UsersDetailsData: userArr,
      userID: !flag ? User_Data[0]?.userId : Date.now(),
    });
    return userArr;
  };
  parseUsageDetailData = (usage_data) => {
    let usageArr = [];
    for (let usage of usage_data) {
      let obj = {
        userName: (
          <span className="decorline" onClick={() => this.handleUser(usage)}>
            {usage.userName}
          </span>
        ),
        organization: usage.organization,
        // orderId: usage.orderId,
        email: usage.email,
        address: usage.address,
        telNo: usage.telNo,
        ip: usage.ip,
        createOn: usage.createOn,
        agency: usage.agency,
        digitalType: usage.digitalType,
        pub: usage.publication,
        docId: usage.docId,
        digitalItem: usage.digitalItem,
        "Amt($)": usage.amount,
        "Gst($)": usage.gst,
        "Total($)": usage.total,
      };
      usageArr.push(obj);
    }
    this.setState({
      filteredUsageDetail: usageArr,
    });
  };
  updateUserAccountStatus = debounce(async (status) => {
    const { statusUpdate, searchObj, currentPage, updateStatusArr } =
      this.state;
    statusUpdate.status = status;
    this.setState({
      actionConfirmPopup: false,
    });
    await this.recaptcha.execute();
    await this.props.startLoader();
    statusUpdate.userIds = updateStatusArr;
    await this.props.clearAdminError();
    await this.props.startLoader();
    await this.props.updateStatus(statusUpdate);

    if (this.props.updatedStatusRes?.message) {
      this.setState({
        actionFlag: "",
        success: true,
        message: this.props.updatedStatusRes?.message,
        errdata: [],
        msgStatus: "",
      });
      searchObj.pageNumber = currentPage;
      await this.props.findUser(searchObj);
      let data = this.props.userDetails && this.props.userDetails.userInfo;
      this.parseUserDetail(data, true);
      this.setState({
        updateStatusArr: [],
      });
    } else if (this.props.updatedStatusErrorRes?.length >= 0) {
      this.setState({
        actionFlag: "",
        success: true,
        errdata: this.props.updatedStatusErrorRes,
        message: [],
      });
    }
  }, 800);

  onResolved = async (status) => {
    const { statusUpdate } = this.state;
    const response = this.recaptcha.getResponse();
    statusUpdate.captchaToken = response;
  };
  onclosepopup = () => {
    this.setState({
      detail: true,
    });
  };
  onClose = () => {
    this.setState({
      success: false,
      actionConfirmPopup: false,
      actiontype: "",
      row: "",
      index: "",
    });
  };
  render() {
    const { loading } = this.props;
    let {
      UsersDetailsData,
      currentPage,
      msgStatus,
      actionConfirmPopup,
      totalCount,
      success,
      errdata,
      message,
      detail,
      selectedRow,
      filteredUsageDetail,
      userID,
      updateStatusArr,
    } = this.state;
    // const { updatedStatusRes } = this.props

    // const isEmpty = Object.values([
    //     // loginError,
    //     // user_error,
    //     // error
    // ]).some((x) => x);

    return (
      <>
        {loading && (
          <div className="text-center spinner">
            {/* <Spinner animation="border" variant="primary" /> */}
            <Spinner primary={true} />
          </div>
        )}
        {success && (
          <Modal
            customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
            onClick={this.onClose}
            // loading={showinfoScreen}
            errorFlag={success}
          >
            <ErrorPopup
              onClose={this.onClose}
              msg={message}
              errData={errdata}
              {...this.props}
            />
          </Modal>
        )}
        {!detail && (
          <div className="main-navbar-container">
            <UsageDetailsPopup
              customClass={`${
                !detail ? "detail-modal" : "forgot-password-modal"
              }`}
              closeModal={this.onclosepopup}
              loading={detail}
              rowData={selectedRow}
              usageDetailByOrderId={this.props?.usageDetailByOrderId}
            >
              <Popup
                titles="User Subscription"
                onClose={this.onclosepopup}
                {...this.props}
              />
            </UsageDetailsPopup>
          </div>
        )}

        {/* //// */}

        {actionConfirmPopup && (
          <Modal onClick={() => this.onClose()} customClass="login-modal">
            <div className="col-lg-12 col-md-12 pt-3 pb-5">
              <h3>{`Do you want to ${msgStatus}`}</h3>
              <ButtonElement
                variant="contained"
                btnType="background-orange button-label float-right"
                className="d-inline-block mr-2 "
                onClick={() => this.onClose()}
              >
                <span className="button-label">Cancel</span>
              </ButtonElement>{" "}
              <ButtonElement
                variant="contained"
                btnType="background-orange button-label float-right"
                className="d-inline-block mr-2 "
                onClick={(e) => this.onConfirm(e)}
              >
                <span className="button-label">Ok</span>
              </ButtonElement>
            </div>
          </Modal>
        )}

        {/* ////// */}

        <div className="col-12 col-md-12 users-container">
          {/* <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={isEmpty}
                        autoHideDuration={4000}
                    // onClose={this.clearError}
                    >
                        {!updatedStatusRes ? (
                            <Alert severity="error">
                                {
                                    <p>error</p>
                                }
                            </Alert>
                        ) : (
                            <Alert severity="success">{updatedStatusRes.message}</Alert>
                        )}
                    </Snackbar> */}
          <div className="col-12 col-md-12 p-3">
            {/* {loading && <div className="text-center pt-5 pb-5 loader">
                            <Spinner primary={true} />
                        </div>
                        } */}

            <div>
              <h2 className="heading">
                {!this.props?.location?.isUsageDetails
                  ? "User Account"
                  : "Usage Details"}
              </h2>
              {!this.props.location.isUsageDetails && (
                <div className="action-container">
                  {/* <ButtonElement variant="outlined" btnType="transparent-black button-label" disabled={updateStatusArr && updateStatusArr.length <= 0}
                                onClick={() => this.updateUserAccountStatus("SUSPENDED")} className="d-inline-block mr-3">
                                    <span className="button-label" >SUSPEND</span>
                                </ButtonElement> */}
                  <ButtonElement
                    variant="outlined"
                    btnType="transparent-black button-label"
                    disabled={updateStatusArr && updateStatusArr.length <= 0}
                    onClick={() => this.onAction("SUSPENDED")}
                    className="d-inline-block mr-3"
                  >
                    <span className="button-label">SUSPEND</span>
                  </ButtonElement>
                  <ButtonElement
                    variant="outlined"
                    btnType="transparent-black button-label"
                    disabled={updateStatusArr && updateStatusArr.length <= 0}
                    className="d-inline-block"
                    onClick={() => this.onAction("DELETED")}
                  >
                    <span className="button-label">delete acc.</span>
                  </ButtonElement>
                </div>
              )}
              {(filteredUsageDetail.length || UsersDetailsData.length) &&
                totalCount && (
                  <Paginationnextpre
                    numPagination={true}
                    currentPage={currentPage}
                    currentPageSize={10}
                    total={totalCount}
                    onPagination={this.onPaginate}
                  />
                )}
              {(filteredUsageDetail && filteredUsageDetail.length > 0) ||
              (UsersDetailsData && UsersDetailsData.length > 0) ? (
                <div
                  className="users-table"
                  key={
                    !this.props?.location?.isUsageDetails ? userID : Date.now()
                  }
                >
                  <DataTable
                    className="table-responsive"
                    checkbox={
                      !this.props?.location?.isUsageDetails ? true : false
                    }
                    actionflag={true}
                    actiondata={
                      !this.props?.location?.isUsageDetails
                        ? Actions
                        : Actions_usage
                    }
                    columns={
                      !this.props?.location?.isUsageDetails
                        ? UsersDetailsData[0]
                        : filteredUsageDetail[0]
                    }
                    data={
                      !this.props?.location?.isUsageDetails
                        ? UsersDetailsData
                        : filteredUsageDetail
                    }
                    actionFunction={this.actionFromChild}
                    checkboxAction={this.checkboxActionsFormChild}
                  />
                </div>
              ) : (
                <h5 className="text-center pb-2 pt-2">No data found.</h5>
              )}
            </div>
            <Recaptcha
              size="invisible"
              ref={(ref) => (this.recaptcha = ref)}
              onResolved={this.onResolved}
              sitekey={RECAPTCHA_SITE_KEY}
            />
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.adminReducer.isLoading,
  announcements: state.applicationReducer.announcements,
  contentTypes: state.applicationReducer.contentTypes,
  contentType: state.applicationReducer.contentType,
  UsersDatadetails: state.adminReducer.userList, //root reducer which get initalstatedata
  updatedStatusRes: state.adminReducer.updatedStatusRes,
  findUser_error: state.adminReducer.findUser_error,
  userDetails: state.adminReducer.userDetails,
  usageDetailsData: state.adminReducer.usageDetailsData,
  updatedStatusErrorRes: state.adminReducer.updatedStatusErrorRes,
  usageDetailByOrderId: state.adminReducer.usageDetailByOrderId,
  // usageDetailsResError: state.adminReducer.usageDetailsResError
});

export default connect(mapDispatchStatetoProps, {
  startLoader,
  getUserList,
  updateStatus,
  getUserDetails,
  findUser,
  findUsageDetails,
  clearAdminError,
  getUsageDetailByOrderId,
})(UserAccounts);
