import React, { Component } from "react";
import { capitalize } from "lodash";
import Checkbox from "../Checkbox/checkbox";
import "./css/dataTable.scss";
import ReactTooltip from 'react-tooltip';
import {
  editIcon,
  deleteIcon,
  suspendIcon
} from "../../../assets/images"
// import { sendflag } from '../../../redux/actions/adminAction';
// import { connect } from 'react-redux';

class DataTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataFromProps: [],
      selectall: false,
      // newData: []
    }
  }

  async componentDidMount() {


    const { data } = await this.props

    this.setState({
      dataFromProps: data,
    })

  }
  onSelect = (e, rownumber, row) => {
    const isChecked = e.target.checked;
    const { dataFromProps } = this.state;
    this.setState(prevState => ({
      dataFromProps: prevState.dataFromProps.map((x, index) =>
        index === rownumber ? { ...x, isChecked: isChecked } : x),
      selectall: false
    }))
    // if (isChecked) {
    //   this.props.sendflag(isChecked)
    // }
    this.props.checkboxAction(e, rownumber, row, false, dataFromProps)
  }
  selectAll = (e) => {
    const isChecked = e.target.checked;
    const { dataFromProps } = this.props;
    this.setState(prevState => ({
      dataFromProps: prevState.dataFromProps.map(row => row ? { ...row, isChecked: isChecked } : row),
      selectall: isChecked
    }))
    this.props.checkboxAction(e, -1, {}, isChecked, dataFromProps)
  }


  render() {

    const filterColumns = (column) => {
      return Object.keys(column).map((col) => {
        const split = col.split(/(?=[A-Z])/);
        return capitalize(split.join(" "));
      });
    };
    const { columns, checkbox, actionflag, actiondata, bgFlag } = this.props;
    const { dataFromProps, selectall } = this.state;
    let dataColumns = filterColumns(columns);
    return (
      <div className="col-md-12 p-0 d-inline-block table-container tablenl">
        <table className={`data-table table-responsive ${!bgFlag ? "table" : "table tablebg"} `}>
          {/* <table className="table data-table"> */}
          <tbody className="table-body">
            <tr className={!bgFlag ? "table-header" : "table-header-chngbg"}>
              {
                checkbox ? <th><Checkbox customClass={true}
                  label=""
                  checked={selectall}
                  onChange={(e) => this.selectAll(e)}
                /> </th> : null
              }
              {
                actionflag ? <th>Actions</th> : null
              }
              {
                dataColumns.map((col) => (

                  col === "Is checked" ? null :
                    <th key={col}>{col}</th>
                ))
              }



            </tr>
            {dataFromProps
              ? dataFromProps.map((row, index) => (
                <tr key={index} className={row.isChecked ? "selected" : ""}>
                  {
                    checkbox ? <td><Checkbox customClass={true}
                      label=""
                      checked={row.isChecked}
                      onChange={(e) =>
                        this.onSelect(e, index, row)
                      }
                    /> </td> : null
                  }
                  {
                    actionflag ? <td className='w-auto nl-pointer actions'>
                      {actiondata.length > 0 && actiondata[0].actions.map((x) => (
                        x === "Edit Subscription" ? <><img src={editIcon} alt="" data-tip="Edit Subscription" onClick={() => this.props.actionFunction(x, row, index)} className="actionLogo decorline p-1" /><ReactTooltip /></>
                          :
                          x === "Suspended" ? <><img alt="" src={suspendIcon} data-tip="SUSPEND" onClick={() => this.props.actionFunction(x, row, index)} className="actionLogo decorline p-1" /> <ReactTooltip /></> :
                            x === "Delete" ? <> <img alt="" src={deleteIcon} data-tip="DELETE" onClick={() => this.props.actionFunction(x, row, index)} className="actionLogo decorline p-1" /><ReactTooltip /></> :
                              <a href
                                key={x} onClick={() => this.props.actionFunction(x, row, index)} className='decorline p-1'>{x}</a>

                      ))}
                    </td> : null
                  }
                  {
                    Object.keys(row).map((key) => (
                      key === "isChecked" ? null : //className={key === "description" ? "wrapWord" : key === "createOn" ? "whitespace" : ""}
                        <td key={key} className={key === "description" ? "descriptionWrap" : key === "createOn" ? "whitespace" : ""} >
                          {row[key]}
                        </td>
                    ))
                  }


                </tr>
              ))
              : ""}
          </tbody>
        </table>
      </div>
    );
  }
}

DataTable.defaultProps = {
  checkbox: false
}

// const mapDispatchStatetoProps = (state) => ({
//   ...state,
// });

// export default connect(mapDispatchStatetoProps, {
//   sendflag
// })(DataTable);
export default DataTable;
