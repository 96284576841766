import React from 'react'
import spinnerPrimary from './Spinner_Primary.svg'
import spinnerSecondary from './Spinner_Secondary.svg'
import './spinner.scss';

const Spinner = (props) => {
  return (
    <div className="text-center centerspin">
      <img
        src={props.primary ? spinnerPrimary : spinnerSecondary}
        style={{ width: '150px', margin: 'auto', display: 'block'}}
        alt='loading...' className={props.transparent ? 'transparnt':""}
      />
    </div>
  )
}

export default Spinner
