/* eslint-disable import/no-anonymous-default-export */
import {
  START_ADMIN_LOADER,
  GET_USER_LIST,
  GET_USER_LIST_ERROR,
  GET_ADMIN_PRELOADS,
  UPDATE_ANNOUNCEMENT,
  GET_HERITAGE_PHOTO,
  GET_USER_TYPE,
  GET_USER_TYPE_ERROR,
  GET_ACCOUNT_STATUS,
  SUBSCRIPTION,
  FIND_USER,
  FIND_USER_ERROR,
  CLEAR_ADMIN_ERROR,
  CLEAR_DEVSETTINGS,
  GET_SORT_BY,
  GET_INFO_DATA,
  FIND_USAGE_DETAIL,
  GET_INFO_FOR_ANNOUNCEMENT,
  FIND_USAGE_DETAIL_ERROR,
  LOGIN_ADMIN_ERROR,
  LOGIN_ADMIN,
  CLEAR_ADMIN_LOGIN_ERROR,
  UPDATE_STATUS,
  CREATE_AGENCY,
  CREATE_AGENCY_ERROR,
  GET_AGENCY,
  GET_AGENCY_PUBLISHER,
  GET_AGENCY_PUBLISHER_BY_ID,
  GET_AGENCY_PUBLISHER_ERROR,
  CREATE_AGENCY_PUBLISHER,
  CREATE_AGENCY_PUBLISHER_ERROR,
  UPDATE_AGENCY_PUBLISHER,
  UPDATE_AGENCY_PUBLISHER_ERROR,
  UPDATE_AGENCY_PUBLISHER_STATUS,
  UPDATE_AGENCY_PUBLISHER_STATUS_ERROR,
  UPDATE_AGENCY,
  UPDATE_AGENCY_ERROR,
  GET_USERDETAIL_DATA_BY_USERID,
  UPDATE_USER_ACCOUNT_INFO,
  UPDATE_USER_ACCOUNT_INFO_ERROR,
  UPDATE_STATUS_ERROR,
  GET_AGENCY_INFO,
  // GET_AGENCY_INFO_ERROR,
  GET_AGENCY_ROYALTY_REPORT,
  GET_AGENCY_ROYALTY_REPORT_ERROR,
  GET_AGENCY_ERROR,
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_ERROR,
  SUBSCRIPTION_STATUS,
  CALCULATE_FEE_BY_PACKAGE,
  CALCULATE_FEE_BY_SUBSCIPTION_FEE,
  GET_AGENCY_USAGE,
  GET_AGENCY_USAGE_ERROR,
  USAGE_DETAIL_BY_ORDER_ID,
  USAGE_DETAIL_BY_ORDER_ID_ERROR,
  UPDATE_SUBSCIPTION,
  UPDATE_SUBSCIPTION_ERROR,
  STOP_ADMIN_LOADER,
  GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION,
  GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR,
  UPDATE_AGENCY_PACKAGE,
  UPDATE_AGENCY_PACKAGE_ERROR,
  UPDATE_EXTERNAL_SUBSCRIPTION,
  UPDATE_EXTERNAL_SUBSCRIPTION_ERROR,
  CREATE_EXTERNAL_SUBSCRIPTION,
  CREATE_EXTERNAL_SUBSCRIPTION_ERROR,
  UPDATE_ANNOUNCEMENT_ERROR,
  GET_HERITAGE_PHOTO_ERROR,
  GET_PACKAGE_CONFIGURATION,
  GET_PACKAGE_CONFIGURATION_ERROR,
  GET_USERDETAIL_DATA_BY_USERID_ERROR,
  UPDATE_PACKAGES,
  UPDATE_PACKAGES_ERROR,
  GET_ALL_ADMIN_USERS,
  GET_ALL_ADMIN_USERS_ERROR,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_ERROR,
  GET_MAILINGLIST_ERROR,
  GET_MAILINGLIST_RESPONSE,
  UPDATE_MAILINGLIST_ERROR,
  UPDATE_MAILINGLIST_RESPONSE,
  CREATE_ADMIN_USER,
  CREATE_ADMIN_USER_ERROR,
  DELETE_ADMIN_USER,
  DELETE_CONTENT,
  DELETE_ADMIN_USER_ERROR,
  DELETE_CONTENT_ERROR,
  ADMIN_SEARCH_CONTENT,
  ADMIN_SEARCH_CONTENT_ERROR,
  CLEAR_ADMIN_USER_ERROR,
  CLEAR_UPDATE_ADMIN_ACCOUNT_INFO,
  ADD_CATEGORY_RESPONSE,
  ADD_CATEGORY_ERROR,
  ADD_PACKAGE_USER_TYPE_RESPONSE,
  ADD_PACKAGE_USER_TYPE_ERROR,
  GET_PACKAGE_BY_CODE_RESPONSE,
  GET_PACKAGE_BY_CODE_ERROR,
  UPDATE_CONFIG_PACKAGE_ERROR,
  UPDATE_CONFIG_PACKAGE_RESPONSE,
  CREATE_PACKAGE_ERROR,
  CREATE_PACKAGE_RESPONSE,
  SEAMLESS_LOGIN_EMAIL,
  SEAMLESS_LOGIN_EMAIL_ERROR,
  FETCH_ACTIVE_USER,
  FETCH_ACTIVE_USER_ERROR,
  SEND_BLAST_EMAIL,
  SEND_BLAST_EMAIL_ERROR,
  REMOVE_TOKEN,
  REMOVE_TOKEN_ERROR,
  REMOVE_TOKEN_BY_USERNAME,
  REMOVE_TOKEN_BY_USERNAME_ERROR,
  UPDATE_SYS_CONFIG,
  UPDATE_SYS_CONFIG_ERROR,
  DELETE_KEY,
  DELETE_KEY_ERROR,
  DELETE_HASH_KEY_ERROR,
  DELETE_HASH_KEY,
  CLEAR_SELECT_SUB,
  CREATE_NEW_SUBSCRIPTION,
  CREATE_NEW_SUBSCRIPTION_ERROR,
  SYNC_AUTH_ERROR,
  SYNC_AUTH_SUCCESS,
  DELETE_WILD_CARD_KEY,
  DELETE_WILD_CARD_KEY_ERROR
} from "../../config/constants/action-constants";

const initialState = {
  loading: false,
  userList: [],
  searchUserAcc: [],
  accountInfo: [],
  selectedSub: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case START_ADMIN_LOADER:
      return {
        ...state,
        isLoading: true
      }
    case GET_USER_LIST:
      return {
        ...state,
        isLoading: false,
        userList: action.payload.data
        // announcements: '<ul class="square"><li><span style="font-family: Arial, sans-serif; font-size: 9pt;"><span style="font-family: Helv, sans-serif; font-size: 10pt;"><span style="font-family: Arial, sans-serif;"><p><span style="font-size: 9pt;">1. For new subscribers who want to sign up for the new Newslink 2.0,please click on above top left hand corner tab for enquiry on the various packages :-&nbsp;<b><span style="color: rgb(24, 115, 165); font-size: 10pt;"><a href="http://newslink.sg/user/RegisterRequest.action?register1=&amp;_sourcePage=%2FWEB-INF%2Fjsp%2Fuser%2Fhome.jsp" target="_blank">Not a member yet? Sign up now!</a></span></b></span></p></span></span></span></li><li><span style="font-family: Arial, sans-serif; font-size: 12px;">2.&nbsp;</span><span style="color: rgb(34, 34, 34); font-family: sans-serif; font-size: small;">Classified &amp; Recruitment full page PDFs from the English, Chinese &amp; Malay newspapers are now available for retrieval by advertising agencies.</span><span style="font-size: 14.4px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></li></ul>',
      };
    case GET_USER_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case GET_ADMIN_PRELOADS:
      return {
        ...state,
        telCodes: action.payload.data,
        isLoading: false
      }
    case UPDATE_ANNOUNCEMENT:
      return {
        ...state,
        updatedAnnouncement: action.payload.data,
        isLoading: false
      }
    case UPDATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        updatedAnnouncementError: action.payload.errors,
        isLoading: false
      }
    case GET_HERITAGE_PHOTO:
      return {
        ...state,
        heritagePhoto: action.payload.data,
        isLoading: false
      }
    case GET_HERITAGE_PHOTO_ERROR:
      return {
        ...state,
        heritagePhotoError: action.payload,
        isLoading: false
      }
    case GET_USER_TYPE:
      return {
        ...state,
        userTypes: action.payload.data,
        isLoading: false
      }
    case GET_USER_TYPE_ERROR:
      return {
        ...state,
        userTypesError: action.payload.errors,
        isLoading: false
      }
    case GET_ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: action.payload.data
      }
    case SUBSCRIPTION:
      return {
        ...state,
        subscriptionPackage: action.payload.data
      }
    case FIND_USER:
      return {
        ...state,
        userDetails: action.payload.data,
        isLoading: false
      }
    case FIND_USER_ERROR:
      return {
        ...state,
        findUser_error: action.payload,
        isLoading: false
      }
    case FIND_USAGE_DETAIL_ERROR:
      return {
        ...state,
        findUsageDetails_error: action.payload,
        isLoading: false
      }
    case CLEAR_ADMIN_ERROR:
      return {
        ...state,
        findUser_error: null,
        userDetails: null,
        isLoading: false,
        findUsageDetails_error: null,
        accounInfoErrRes: null,
        updatedStatusRes: null,
        accounInfoRes: null,
        updateAccountInfoError: null,
        createUserAccountRes: null,
        createUserAccountResError: null,
        agencyResult: null,
        agencyErrorResult: null,
        agencyResponseData: null,
        agencyErrorResponseData: null,
        updatedAgencySuccessResponse: null,
        updatedAgencyErrorResponse: null,
        agencyUsageResult: null,
        agencyUsageErrorResponse: null,
        agencyRoyaltyReportList: null,
        agencyRoyaltyReportErrorList: null,
        agencyPublisherResult: null,
        userSubscriptionRes: null,
        userSubscriptionResError: null,
        updateAgencyPackageError: null,
        updateAgencyPackageResult: null,
        agencyPublisherResponse: null,
        agencyPublisherErrorResponse: null,
        agencyPublisherUpdatedResponse: null,
        agencyPublisherUpdateErrorResponse: null,
        updatedAgencyPublisherStatusResult: null,
        updatedAgencyPublisherStatusErrorResult: null,
        updateExternalSubscriptionResponse: null,
        updateExternalSubscriptionResErrorMessages: null,
        createExternalSubscriptionErrorRes: null,
        createExternalSubscriptionRes: null,
        updatedAnnouncement: null,
        updatedAnnouncementError: null,
        heritagePhoto: null,
        heritagePhotoError: null,
        usageDetailsData: null,
        // usageDetailsResError: null,
        userDetailsByUserId: null,
        userDetailsByUserIdError: null,
        updatePackagesResult: null,
        updatePackagesError: null,
        loading: false,
        getMailingListResult: null,
        getMailingListError: null,
        updateMailinglistResult: null,
        updateMailinglistError: null,
        addCategoryError: null,
        addCategoryResponse: null,
        updateConfigPackageError: null,
        updateConfigPackageResponse: null,
        createPackageResponse: null,
        createPackageError: null,
        addPackageUserTypeError: null,
        addPackageUserTypeResponse: null,
        externalPackageUpsellConfigurationResult: null,
        externalPackageUpsellConfigurationError: null,
        seamlessLoginRes: null,
        seamlessLoginErr: null,
        sendEmail: null,
        sendEmailError: null,
        updatedSysConfigRes: null,
        updatedSysConfigErr: null,
        newUserSubscription: null,
        newUserSubscriptionErr: null,
        // syncAuthSucess: null,
        // syncAuthError: null
      }
    case CLEAR_DEVSETTINGS: return {
      syncAuthSucess: null,
      syncAuthError: null
    }
    case CLEAR_ADMIN_USER_ERROR:
      return {
        ...state,
        updatedAdminUser: null,
        updatedAdminUserError: null,
        createAdminUser: null,
        createAdminUserError: null,
        deletedAdminUser: null,
        deletedAdminUserError: null
      }
    case CLEAR_UPDATE_ADMIN_ACCOUNT_INFO:
      return {
        ...state,
        accounInfoRes: null,
        updateAccountInfoError: null,
      }
    case GET_SORT_BY:
      return {
        ...state,
        sortByData: action.payload.data,
        isLoading: false
      }
    case GET_INFO_DATA: return {
      ...state,
      infoData: action.payload.data,
      isLoading: false
    }
    case FIND_USAGE_DETAIL:
      return {
        ...state,
        usageDetailsData: action.payload.data,
        isLoading: false
      }
    // case FIND_USAGE_DETAIL_ERROR:
    //   return {
    //     ...state,
    //     usageDetailsResError: action.payload.errors,
    //     isLoading: false
    //   }
    case GET_INFO_FOR_ANNOUNCEMENT:
      return {
        ...state,
        getInfoDataForAnnouncement: action.payload.data,
        isLoading: false
      }
    case LOGIN_ADMIN:
      return {
        ...state,
        adminLoginSuccess: action.payload.data.message,
        isLoading: false
      }
    case LOGIN_ADMIN_ERROR:
      return {
        ...state,
        admin_login_error: action.payload,
        isLoading: false
      }
    case CLEAR_ADMIN_LOGIN_ERROR:
      return {
        ...state,
        admin_login_error: null,
        isLoading: false
      }
    case UPDATE_STATUS:
      return {
        ...state,
        updatedStatusRes: action.payload.data,
        isLoading: false
      }
    case CREATE_AGENCY:
      return {
        ...state,
        agencyResponseData: action.payload.data,
        isLoading: false
      }
    case CREATE_AGENCY_ERROR:
      return {
        ...state,
        agencyErrorResponseData: action.payload,
        isLoading: false
      }

    case GET_AGENCY_USAGE:
      return {
        ...state,
        agencyUsageResult: action.payload.data,
        isLoading: false
      }
    case GET_AGENCY_USAGE_ERROR:
      return {
        ...state,
        agencyUsageErrorResponse: action.payload,
        isLoading: false
      }
    case GET_AGENCY_PUBLISHER:
      return {
        ...state,
        agencyPublisherResult: action.payload,
        isLoading: false
      }
    case GET_AGENCY_PUBLISHER_BY_ID:
      return {
        ...state,
        agencyPublisherByIdResult: action.payload,
        isLoading: false
      }
    case CREATE_AGENCY_PUBLISHER:
      return {
        ...state,
        agencyPublisherResponse: action.payload,
        isLoading: false
      }
    case CREATE_AGENCY_PUBLISHER_ERROR:
      return {
        ...state,
        agencyPublisherErrorResponse: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY_PUBLISHER:
      return {
        ...state,
        agencyPublisherUpdatedResponse: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY_PUBLISHER_ERROR:
      return {
        ...state,
        agencyPublisherUpdateErrorResponse: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY_PUBLISHER_STATUS:
      return {
        ...state,
        updatedAgencyPublisherStatusResult: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY_PUBLISHER_STATUS_ERROR:
      return {
        ...state,
        updatedAgencyPublisherStatusErrorResult: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY:
      return {
        ...state,
        updatedAgencySuccessResponse: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY_ERROR:
      return {
        ...state,
        updatedAgencyErrorResponse: action.payload,
        isLoading: false
      }
    case UPDATE_STATUS_ERROR:
      return {
        ...state,
        updatedStatusErrorRes: action.payload,
        isLoading: false
      }
    case GET_USERDETAIL_DATA_BY_USERID:
      return {
        ...state,
        userDetailsByUserId: action.payload.data,
        isLoading: false
      }
    case GET_USERDETAIL_DATA_BY_USERID_ERROR:
      return {
        ...state,
        userDetailsByUserIdError: action.payload,
        isLoading: false
      }
    case GET_AGENCY_INFO:
      return {
        ...state,
        agencyInfoResult: action.payload.data,
        isLoading: false
      }
    case GET_AGENCY_ROYALTY_REPORT:
      return {
        ...state,
        agencyRoyaltyReportList: action.payload.data,
        isLoading: false
      }
    case GET_AGENCY_ROYALTY_REPORT_ERROR:
      return {
        ...state,
        agencyRoyaltyReportErrorList: action.payload,
        isLoading: false
      }
    case UPDATE_USER_ACCOUNT_INFO:
      return {
        ...state,
        accounInfoRes: action.payload.data,
        isLoading: false
      }
    case UPDATE_USER_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        updateAccountInfoError: action.payload,
        isLoading: false
      }
    case CREATE_USER_ACCOUNT:
      return {
        ...state,
        createUserAccountRes: action.payload.data,
        isLoading: false
      }
    case CREATE_USER_ACCOUNT_ERROR:
      return {
        ...state,
        createUserAccountResError: action.payload,
        isLoading: false
      }
    case SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionStatus: action.payload.data,
        isLoading: false
      }
    case CALCULATE_FEE_BY_PACKAGE:
      return {
        ...state,
        pkgInfo: action.payload.data,
        isLoading: false
      }
    case CALCULATE_FEE_BY_SUBSCIPTION_FEE:
      return {
        ...state,
        calculateFeeRes: action.payload.data,
        isLoading: false
      }
    // case GET_AGENCY_USAGE:
    //   return {
    //     ...state,
    //     agencyUsageResult: action.payload.data,
    //     isLoading: false
    //   }

    case GET_AGENCY:
      return {
        ...state,
        agencyResult: action.payload.data,
        isLoading: false
      }
    case GET_AGENCY_ERROR:
      return {
        ...state,
        agencyErrorResult: action.payload.errors,
        isLoading: false
      }
    case GET_AGENCY_PUBLISHER_ERROR:
      return {
        ...state,
        getAgencyPublisherError: action.payload,
        isLoading: false
      }
    case USAGE_DETAIL_BY_ORDER_ID:
      return {
        ...state,
        usageDetailByOrderId: action.payload.data,
        isLoading: false
      }
    case USAGE_DETAIL_BY_ORDER_ID_ERROR:
      return {
        ...state,
        usageDetailByOrderIdError: action.payload,
        isLoading: false
      }
    case UPDATE_SUBSCIPTION:
      return {
        ...state,
        userSubscriptionRes: action.payload.data,
        isLoading: false
      }
    case UPDATE_SUBSCIPTION_ERROR:
      return {
        ...state,
        userSubscriptionResError: action.payload,
        isLoading: false
      }
    case GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION:
      return {
        ...state,
        externalPackageUpsellConfigurationResult: action.payload,
        isLoading: false
      }
    case GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR:
      return {
        ...state,
        externalPackageUpsellConfigurationError: action.payload.errors,
        isLoading: false
      }
    case UPDATE_AGENCY_PACKAGE_ERROR:
      return {
        ...state,
        updateAgencyPackageError: action.payload,
        isLoading: false
      }
    case UPDATE_AGENCY_PACKAGE:
      return {
        ...state,
        updateAgencyPackageResult: action.payload,
        isLoading: false
      }
    case STOP_ADMIN_LOADER:
      return {
        ...state,
        isLoading: false
      }
    case UPDATE_EXTERNAL_SUBSCRIPTION:
      return {
        ...state,
        updateExternalSubscriptionResponse: action.payload.data,
        isLoading: false
      }
    case UPDATE_EXTERNAL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        updateExternalSubscriptionResErrorMessages: action.payload,
        isLoading: false
      }
    case CREATE_EXTERNAL_SUBSCRIPTION:
      return {
        ...state,
        createExternalSubscriptionRes: action.payload.data,
        isLoading: false
      }
    case CREATE_EXTERNAL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        createExternalSubscriptionErrorRes: action.payload,
        isLoading: false
      }
    case GET_PACKAGE_CONFIGURATION:
      return {
        ...state,
        getPackageConfigurationResult: action.payload,
        isLoading: false
      }
    case GET_PACKAGE_CONFIGURATION_ERROR:
      return {
        ...state,
        getPackageConfigurationError: action.payload,
        isLoading: false
      }
    case UPDATE_PACKAGES:
      return {
        ...state,
        updatePackagesResult: action.payload,
        isLoading: false
      }
    case UPDATE_PACKAGES_ERROR:
      return {
        ...state,
        updatePackagesError: action.payload,
        isLoading: false
      }
    case GET_ALL_ADMIN_USERS:
      return {
        ...state,
        allAdminUsers: action.payload.data,
        isLoading: false
      }
    case GET_ALL_ADMIN_USERS_ERROR:
      return {
        ...state,
        allAdminUserError: action.payload,
        isLoading: false
      }
    case UPDATE_ADMIN_USER:
      return {
        ...state,
        updatedAdminUser: action.payload.data,
        isLoading: false
      }

    case UPDATE_ADMIN_USER_ERROR:
      return {
        ...state,
        updatedAdminUserError: action.payload.errors,
        isLoading: false
      }
    case GET_MAILINGLIST_RESPONSE:
      return {
        ...state,
        getMailingListResult: action.payload.data,
        isLoading: false
      }
    case GET_MAILINGLIST_ERROR:
      return {
        ...state,
        getMailingListError: action.payload.errors,
        isLoading: false
      }

    case UPDATE_MAILINGLIST_RESPONSE:
      return {
        ...state,
        updateMailinglistResult: action.payload.data,
        isLoading: false
      }
    case UPDATE_MAILINGLIST_ERROR:
      return {
        ...state,
        updateMailinglistError: action.payload,
        isLoading: false
      }

    case CREATE_ADMIN_USER:
      return {
        ...state,
        createAdminUser: action.payload,
        isLoading: false
      }
    case CREATE_ADMIN_USER_ERROR:
      return {
        ...state,
        createAdminUserError: action.payload.errors,
        isLoading: false
      }
    case ADD_CATEGORY_RESPONSE:
      return {
        ...state,
        addCategoryResponse: action.payload,
        isLoading: false
      }

    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        addCategoryError: action.payload.errors,
        isLoading: false
      }

    case DELETE_ADMIN_USER:
      return {
        ...state,
        userDeleted: action.payload.data,
        isLoading: false
      }
    case DELETE_ADMIN_USER_ERROR:
      return {
        ...state,
        userDeletedError: action.payload.errors,
        isLoading: false
      }
    case DELETE_CONTENT:
      return {
        ...state,
        contentDeleted: action.payload.data,
        isLoading: false
      }
    case DELETE_CONTENT_ERROR:
      return {
        ...state,
        contentDeletedError: action.payload,
        isLoading: false
      }
    case ADMIN_SEARCH_CONTENT:
      return {
        ...state,
        adminSearchContent: action.payload.data,
        isLoading: false
      }
    case DELETE_CONTENT_ERROR:
      return {
        ...state,
        adminSearchContentError: action.payload.errors,
        isLoading: false
      }
    case ADD_PACKAGE_USER_TYPE_RESPONSE:
      return {
        ...state,
        addPackageUserTypeResponse: action.payload.data,
        isLoading: false
      }
    case ADD_PACKAGE_USER_TYPE_ERROR:
      return {
        ...state,
        addPackageUserTypeError: action.payload.errors,
        isLoading: false
      }
    case GET_PACKAGE_BY_CODE_RESPONSE:
      return {
        ...state,
        getPackageByCodeResponse: action.payload.data,
        isLoading: false
      }
    case GET_PACKAGE_BY_CODE_ERROR:
      return {
        ...state,
        getPackageByCodeError: action.payload.errors,
        isLoading: false
      }
    case UPDATE_CONFIG_PACKAGE_ERROR:
      return {
        ...state,
        updateConfigPackageError: action.payload.errors,
        isLoading: false
      }
    case UPDATE_CONFIG_PACKAGE_RESPONSE:
      return {
        ...state,
        updateConfigPackageResponse: action.payload.data,
        isLoading: false
      }
    case CREATE_PACKAGE_RESPONSE:
      return {
        ...state,
        createPackageResponse: action.payload.data,
        isLoading: false
      }
    case CREATE_PACKAGE_ERROR:
      return {
        ...state,
        createPackageError: action.payload.errors,
        isLoading: false
      }
    case SEAMLESS_LOGIN_EMAIL:
      return {
        ...state,
        seamlessLoginRes: action.payload.data,
        isLoading: false
      }
    case SEAMLESS_LOGIN_EMAIL_ERROR:
      return {
        ...state,
        seamlessLoginErr: action.payload.errors,
        isLoading: false
      }
    case FETCH_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload.data,
        isLoading: false
      }
    case FETCH_ACTIVE_USER_ERROR:
      return {
        ...state,
        activeUserError: action.payload.errors,
        isLoading: false
      }
    case SEND_BLAST_EMAIL:
      return {
        ...state,
        sendEmail: action.payload.data,
        isLoading: false
      }
    case SEND_BLAST_EMAIL_ERROR:
      return {
        ...state,
        sendEmailError: action.payload.errors,
        isLoading: false
      }

    case REMOVE_TOKEN:
      return {
        ...state,
        removeTokenResponse: action.payload.data,
        isLoading: false
      }
    case REMOVE_TOKEN_ERROR:
      return {
        ...state,
        removeTokenError: action.payload.errors,
        isLoading: false
      }
    case REMOVE_TOKEN_BY_USERNAME:
      return {
        ...state,
        removeTokenByUserNameResponse: action.payload.data,
        isLoading: false
      }
    case REMOVE_TOKEN_BY_USERNAME_ERROR:
      return {
        ...state,
        removeTokenByUserNameError: action.payload,
        isLoading: false
      }
    case UPDATE_SYS_CONFIG:
      return {
        ...state,
        updatedSysConfigRes: action.payload.data,
        isLoading: false
      }
    case UPDATE_SYS_CONFIG_ERROR:
      return {
        ...state,
        updatedSysConfigErr: action.payload.errors,
        isLoading: false
      }
    case DELETE_KEY:
      return {
        ...state,
        deleteKeyResponse: action.payload.data,
        isLoading: false
      }
    case DELETE_KEY_ERROR:
      return {
        ...state,
        deleteKeyError: action.payload.errors,
        isLoading: false
      }
    case DELETE_HASH_KEY:
      return {
        ...state,
        deleteHashKeyResponse: action.payload.data,
        isLoading: false
      }
    case DELETE_HASH_KEY_ERROR:
      return {
        ...state,
        deleteHashKeyError: action.payload.errors,
        isLoading: false
      }
    case CLEAR_SELECT_SUB:
      return {
        ...state,
        selectedSub: action.payload.data,
      }
    case CREATE_NEW_SUBSCRIPTION:
      return {
        ...state,
        newUserSubscription: action.payload.data,
        isLoading: false
      }

    case CREATE_NEW_SUBSCRIPTION_ERROR:
      return {
        ...state,
        newUserSubscriptionErr: action.payload.errors,
        isLoading: false
      }
    case SYNC_AUTH_SUCCESS:
      return {
        ...state,
        syncAuthSucess: action.payload.data,
        isLoading: false
      }
    case SYNC_AUTH_ERROR:
      return {
        ...state,
        syncAuthError: action.payload.errors,
        isLoading: false
      }
    case DELETE_WILD_CARD_KEY:
      return {
        ...state,
        deleteWildcardKeyResponse: action.payload.data,
        isLoading: false
      }
    case DELETE_WILD_CARD_KEY_ERROR:
      return {
        ...state,
        deleteWildcardKeyError: action.payload.errors,
        isLoading: false
      }


    default:
      return {
        ...state,
      };
  }
}
