import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import reactReferer from "react-referer";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  getDate,
  getContentData,
  reverseDate,
  getPageviewobj,
  getCustomviewobj,
} from "../../helpers/common";
import { ButtonElement, DatePickerElement, CustomSelect } from "../UI/index";
import {
  BHLogo,
  BTLogo,
  MPLogo,
  SMLogo,
  STLogo,
  TLLogo,
  TCLogo,
  TUJRLogo,
  TULogo,
  TMLogo,
  TNPLogo,
  WBLogo,
  ZBLogo,
  // ZBSGLogo,
  ZBBZLogo,
  ZBCLogo,
} from "../../assets/images/index";
import {
  getAnnouncements,
  getContentTypes,
  setContentType,
  searchContent,
  pageViewDatagtm,
  customViewDatagtm,
  searchAutocomplete,
  getsetadandAdvdata,
  setDurations,
  getAnnouncementByLanguage,
  getBadgeData,
  setSearchObject,
  setDateObject,
  getSubDigitalType,
} from "../../redux/actions/applicationsAction";
import "./css/dashboard.scss";
import { decodeToken } from "react-jwt";
import { getUserDetails } from "../../redux/actions/loginAction";
import SuggestionInput from "../UI/InputwithSuggestion/suggestionInput";
import { debounce } from "lodash";
class Dashboard extends Component {
  state = {
    startDate: getDate(true, 2),
    endDate: new Date(),
    maxDate: new Date(),
    searchObject: {
      dateRange: {
        fromDate: "",
        toDate: "",
      },
      keywords: "",
      sortBy: "publicationdate",
      sortOrder: "desc",
      publication: [],
      // pageNo: 1,
      pageSize: 10,
      sourceType: "",
    },
    minDate: getDate(true, 2),
    referer: reactReferer.referer(),
    showTabs: [],
    announcement: "",
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.props.searchAutocomplete("", true);
    const { searchObject, startDate, endDate } = this.state;
    let myDecodedToken;

    await this.props.getSubDigitalType("ALL", 0);

    // if(!this.props?.tokens?.id_token){
    await this.props.getUserDetails();
    // }

    myDecodedToken = decodeToken(this.props?.tokens?.id_token);
    if (myDecodedToken?.user_info?.is_seamless) {
      // this.props.history.push("/user-account");
    }
    if (
      myDecodedToken?.user_info?.search_type?.includes("ad_search") &&
      !myDecodedToken?.user_info?.search_type?.includes("general_search")
    ) {
      this.props.history.push("/user-account");
    }
    let fromd = startDate.toLocaleDateString("en-GB");
    let tod = endDate.toLocaleDateString("en-GB");
    this.props.setDateObject({
      startDate: getDate(true, 2),
      endDate: new Date(),
    });
    searchObject.publication =
      myDecodedToken &&
      myDecodedToken?.user_info &&
      myDecodedToken?.user_info?.pub_list
        ? myDecodedToken?.user_info?.pub_list
        : [];
    searchObject.dateRange["fromDate"] = reverseDate(
      fromd.replaceAll("/", "-")
    );
    searchObject.dateRange["toDate"] = reverseDate(tod.replaceAll("/", "-"));
    searchObject.keywords = "";
    searchObject.sortBy = "publicationdate";
    searchObject.sortOrder = "desc";
    this.props.setDurations("0");
    await this.props.searchAutocomplete("", true);
    // if(!this.props?.announcements){
    //   await this.props.getAnnouncements();
    // }
    if (!this.props?.announceByLanguage) {
      await this.props.getAnnouncementByLanguage({ language: "en" });
    }
    let ann = this.props && this.props.announceByLanguage;
    let annvalue = ann && ann[Object.keys(ann)[0]];

    this.setState({
      searchObject,
      showTabs: myDecodedToken?.user_info,
      announcement: annvalue,
    });

    await this.props.getsetadandAdvdata([]);
    await this.props.getBadgeData({ sectionDatalist: [], badgeData: {} });
    await this.props.setSearchObject(this.state.searchObject);
    const { contentTypes } = this.props;
    const contentData = getContentData(contentTypes, "All");
    await this.props.setContentType({
      digitalType: "All",
      extraData: contentData?.extraData[0],
    });
    this.setPageViewcall(searchObject, myDecodedToken);
  }
  setPageViewcall = (searchObject, myDecodedToken) => {
    let id = myDecodedToken?.user_info?.visitor_id
      ? myDecodedToken?.user_info?.visitor_id
      : "";
    let user_status =
      myDecodedToken && myDecodedToken?.user_info?.user_type
        ? myDecodedToken?.user_info?.user_type
        : "";
    let obj = getPageviewobj("", "home", "", user_status, "", "", "", id);
    this.props.pageViewDatagtm(obj, searchObject);
  };
  onStartDateChange = (date) => {
    const { searchObject } = this.state;
    let dt = new Date(date);
    let modifieddate = dt.toLocaleDateString("en-GB");
    searchObject.dateRange["fromDate"] = reverseDate(
      modifieddate.replaceAll("/", "-")
    );
    this.setState({
      searchObject,
      startDate: date,
    });
    this.props.setDateObject({
      startDate: date,
      endDate: this.props?.dateObject?.endDate,
    });
  };

  onEndDateChange = (date) => {
    const { searchObject } = this.state;
    let dt = new Date(date);
    let modifieddate = dt.toLocaleDateString("en-GB");
    searchObject.dateRange["toDate"] = reverseDate(
      modifieddate.replaceAll("/", "-")
    );
    this.setState({
      searchObject,
      endDate: date,
    });
    this.props.setDateObject({
      startDate: this.props?.dateObject?.startDate,
      endDate: date,
    });
  };

  onChange = (value, obj, enterflag, key) => {
    const { searchObject, showTabs } = this.state;
    searchObject[key] = value;
    this.setState({
      searchObject,
    });
    if (key === "keywords") {
      let strings = value.trim();
      if (
        (searchObject?.sourceType === "article" ||
          searchObject?.sourceType === "ext_article" ||
          searchObject?.sourceType === "online_article") &&
        strings.length > 3
      ) {
        this.onChangeSuggestion(strings);
      } else {
        this.props.searchAutocomplete("", true);
      }
    }
    if (obj) {
      this.customViewcall(obj, showTabs);
      this.props.history.push({
        pathname: "/search",
        searchObject: obj,
        showTabs: showTabs,
      });
    }
  };
  onChangeSuggestion = debounce((value) => {
    const { searchObject } = this.state;
    this.props.searchAutocomplete({
      digitalType: searchObject.digitalType,
      keywords: value,
      sourceType: searchObject.sourceType,
      dateRange: {
        fromDate: searchObject?.dateRange?.fromDate,
        toDate: searchObject?.dateRange?.toDate,
      },
    });
  }, 500);
  onChangeEnters = async () => {
    // alert(this.state.searchObject.keywords);
    const { searchObject } = this.state;
    if (
      searchObject?.sourceType === "article" ||
      searchObject?.sourceType === "ext_article" ||
      searchObject?.sourceType === "online_article"
    ) {
    } else {
      if (this.state.searchObject.keywords) {
        this.onSearch();
        this.props.searchAutocomplete("", true);
        document.removeEventListener("keyup", (event) => {});
      }
    }
  };
  setContentType = async (contentType) => {
    const { contentTypes } = this.props;
    const { searchObject } = this.state;
    const contentData = getContentData(contentTypes, contentType);
    await this.props.setContentType({
      digitalType: contentType,
      extraData: contentData.extraData[0],
    });
    this.state.searchObject.digitalType = contentData.extraData[0].digitalType;
    this.state.searchObject.sourceType = contentData.extraData[0].sourceType;
    this.setState({ searchObject });
    await this.props.setSearchObject(this.state.searchObject);
  };

  onSearch = async () => {
    this.props.getsetadandAdvdata([]);
    const { dateObject } = this.props;
    const { searchObject, showTabs } = this.state;
    const { extraData } = this.props.applicationReducer;
    searchObject.digitalType = extraData.digitalType;
    searchObject.fromHome = true;
    searchObject.sourceType = extraData.sourceType;
    searchObject.pageNo = 1;
    searchObject.keywords = searchObject.keywords.trim();
    let modifieddate = dateObject.startDate.toLocaleDateString("en-GB");
    searchObject.dateRange["fromDate"] = reverseDate(
      modifieddate.replaceAll("/", "-")
    );
    let modifieddate2 = dateObject?.endDate.toLocaleDateString("en-GB");
    searchObject.dateRange["toDate"] = reverseDate(
      modifieddate2.replaceAll("/", "-")
    );
    if (extraData.digitalType === "all") {
      searchObject.sourceType = "all";
    }
    if (searchObject.keywords) {
      searchObject.keywords.trim();
    }
    this.setState({
      clearSearch: true,
    });
    //gtm call
    this.customViewcall(searchObject, this.state.showTabs);
    //  this.setPageViewcall(searchObject,this.state.showTabs);
    //end gtm call

    this.props.history.push({
      pathname: "/search",
      searchObject: searchObject,
      showTabs: showTabs,
      notvabarcall: true,
    });
  };
  customViewcall = (searchObject, token) => {
    let user_status = token?.user_info?.user_type
      ? token?.user_info?.user_type
      : "";
    let id = token?.user_info?.visitor_id ? token?.user_info?.visitor_id : "";
    //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate)
    let objj = getCustomviewobj(
      "search",
      "click",
      "",
      "home",
      "",
      user_status,
      "",
      "",
      "",
      id
    );
    this.props.customViewDatagtm(objj, searchObject);
  };
  onClearSearch = () => {};
  render() {
    const Logos = [
      BHLogo,
      BTLogo,
      MPLogo,
      SMLogo,
      STLogo,
      TLLogo,
      TCLogo,
      TUJRLogo,
      TULogo,
      TMLogo,
      TNPLogo,
      WBLogo,
      ZBLogo,
      // ZBSGLogo,
      ZBBZLogo,
      ZBCLogo,
    ];

    const { contentTypes, digitalType, dateObject } = this.props;
    const { searchObject, minDate, maxDate, clearSearch } = this.state;
    return (
      <div className="col-12 col-md-12 pt-3 dashboard p-0">
        <div className="col-12 col-md-11 offset-lg-1 p-3">
          <form noValidate onSubmit={() => this.onSearch()}>
            <div className="datealign">
              <DatePickerElement
                // minDate={minDate}
                onChange={this.onStartDateChange}
                value={dateObject?.startDate}
                maxDate={maxDate}
              />
              <span className="toLabel p-3">To</span>
              <DatePickerElement
                minDate={minDate}
                onChange={this.onEndDateChange}
                value={dateObject?.endDate}
                maxDate={new Date()}
              />
            </div>
            <div className="pt-3">
              <div className="d-inline-block sgtinput">
                <SuggestionInput
                  placeholder="Search, 搜索, Carian, தேடல்"
                  value={searchObject.keywords ? searchObject.keywords : ""}
                  onClear={() => this.onClearSearch()}
                  onChange={(e, obj, enterflag) =>
                    this.onChange(e, obj, enterflag, "keywords")
                  }
                  id={"one"}
                  suggesterData={this.props.autoComplete}
                  clearSearch={clearSearch}
                  searchObject={this.props.searchObject}
                  onChangeEnter={() => this.onChangeEnters()}
                ></SuggestionInput>
              </div>
              <div className="mobileContentType col-md-2 d-inline-block col-12 p-0 pl-2 pr-1 pb-3">
                {contentTypes !== undefined && contentTypes.length > 0 && (
                  // <SelectElement
                  //   defaultValue={digitalType || contentTypes[0].key}
                  //   onChange={(e) =>
                  //     this.setContentType(e.target.value)
                  //   }
                  //   options={contentTypes}
                  //   contentallowed={showTabs?.hide_links && showTabs?.content_allowed ? showTabs.content_allowed: null}
                  // />

                  <CustomSelect
                    options={contentTypes}
                    defaultValue={digitalType || contentTypes[0].key}
                    id="selecticondrop"
                    onChange={(e) => this.setContentType(e)}
                  ></CustomSelect>
                )}
              </div>
              <div className="search-button-container col-md-2 col-sm-12 d-inline-block btnwdth btn-width">
                <ButtonElement
                  variant="contained"
                  btnType="background-orange"
                  className="d-inline-block mr-2 search-button btnwdth"
                  onClick={this.onSearch}
                >
                  Search
                </ButtonElement>
              </div>
              {
                <div className="search-button-container col-md-4 col-12 ad-search-link d-inline-block">
                  <Link
                    to={{
                      pathname: "advanced-search",
                      searchObject: searchObject,
                    }}
                    className="font-medium advanced-search-text"
                  >
                    Advanced Search &nbsp;
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="lg"
                      className="search-icon"
                    />
                  </Link>
                </div>
              }
            </div>
          </form>
        </div>
        <div className="dashboard-info-container">
          <div className="col-md-7 d-inline-block"></div>
          <div className="col-md-5 col-12 d-inline-block pb-5 pt-4">
            <div className="info-container p-4 border">
              <h1>Welcome to Newslink</h1>
              <h6 className="pt-2">
                Newslink is a subscription database that provides access to
                archived contents of newspaper and magazine titles published by
                SPH Media Limited and news articles from member newspapers of
                the Asia News Network (ANN).
              </h6>

              <h6 className="pt-2">
                SPH is Asia's leading media organisation, and ANN is an alliance
                of leading news organisations from South, Southeast and
                Northeast Asia.{" "}
              </h6>

              <h6 className="pt-2">
                Get quick access on Newslink to SPH's 19 influential newspapers
                from 1989 onwards, and ANN's 27 news media dated 2008 onwards.
              </h6>
            </div>
          </div>
        </div>
        <div className="announcements-container pt-4 pb-4">
          <div className="col-12 col-md-11 offset-lg-1">
            <h1 className="container-heading conterhead">Announcements</h1>
            <div
              className="col-12 content p-0"
              dangerouslySetInnerHTML={{
                __html: `${DOMPurify.sanitize(this.state.announcement)}`,
              }}
            ></div>
          </div>
        </div>
        <div className="applications-container pt-5 pb-5">
          <div className="col-12 col-md-12 p-0">
            <h3 className="container-heading conterhead text-center">
              Our Publications
            </h3>
            <div className="col-12 d-flex justify-content-center row">
              {Logos.map((logo) => (
                <div key={logo} className=" center  p-3 ">
                  {" "}
                  <img src={logo} alt={"logo"} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  announcements: state.applicationReducer.announcements,
  contentTypes: state.applicationReducer.contentTypes,
  digitalType: state.applicationReducer.digitalType,
  tokens: state.loginReducer.tokens,
  autoComplete: state.applicationReducer.autoComplete,
  announceByLanguage: state.applicationReducer.announceByLanguage,
  dateObject: state.applicationReducer.dateObject,
});

export default connect(mapDispatchStatetoProps, {
  getAnnouncements,
  getContentTypes,
  setContentType,
  searchContent,
  getUserDetails,
  searchAutocomplete,
  getAnnouncementByLanguage,
  getBadgeData,
  setSearchObject,
  setDateObject,
  getsetadandAdvdata,
  pageViewDatagtm,
  customViewDatagtm,
  setDurations,
  getSubDigitalType,
})(Dashboard);
