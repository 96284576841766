import React, { Component } from "react";
import {
  ButtonElement,
  Spinner,
  Modal,
  SearchPagination,
  DatePickerElement,
} from "../../UI/index";
import { connect } from "react-redux";
import {
  clearAdminError,
  startLoader,
  stopLoader,
  deleteContent,
} from "../../../redux/actions/adminAction";
import { getUserDetails } from "../../../redux/actions/loginAction";
import ErrorPopup from "../Popup/ErrorPopup";
import {
  DOCUMENT_ID_LABEL,
  DOCUMENT_ID_PLACEHOLDER,
  SEARCH_BUTTON_VALUE,
} from "../../../config/constants/generalConstants";
import { Radio } from "@material-ui/core";
import {
  getContentTypes,
  getPublications,
  clear_Error,
  searchContent,
  resetSearchContent,
} from "../../../redux/actions/applicationsAction";
import { getDate } from "../../../helpers/common";

export class DeleteContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resetable: false,
      errData: [],
      success: false,
      disabled: false,
      digitalTypes: [],
      publications: ["All"],
      keywords: "",
      publication: "All",
      pageNo: 1,
      digitalType: "All",
      nicaId: "",
      modal: false,
      deleteModal: false,
      endDate: new Date(),
      startDate: getDate(),
      minDate: getDate(),
      maxDate: new Date(),
      selected: { digitalType: "", sourceType: "", documentId: "", year: "" },
    };
  }
  async componentDidMount() {
    this.props.resetSearchContent();
    await this.props.getContentTypes();
    const digitalTypes = [];
    this.props.contentTypes.forEach((element) => {
      const object = {
        name: element.key,
        digitalType: element.extraData[0].digitalType,
        sourceType:
          element.extraData.length > 1
            ? "all"
            : element.extraData[0].sourceType,
      };
      digitalTypes.push(object);
    });

    this.setState({ digitalTypes: digitalTypes });

    const publications = [];
    await this.props.getPublications();
    this.props.publications.forEach((element) => {
      element.extraData.forEach((item) => {
        if (item.publications) {
          item.publications.forEach((item) => {
            if (!publications.includes(item.pubCode)) {
              publications.push(item.pubCode);
            }
          });
        }
        if (item.print) {
          item.print.forEach((item) => {
            if (!publications.includes(item.pubCode)) {
              publications.push(item.pubCode);
            }
          });
        }
        if (item.online) {
          item.online.forEach((item) => {
            if (!publications.includes(item.pubCode)) {
              publications.push(item.pubCode);
            }
          });
        }
      });
    });

    await this.props.stopLoader();
    this.setState({
      publications: [this.state.publications, ...publications.sort()],
    });
  }
  onChange = (feild, value) => {
    this.setState({ resetable: true });
    if (feild == "keywords") {
      this.setState({
        keywords: value.replace(/ +(?= )/g, ""),
      });
    }

    if (feild == "publication") {
      this.setState({
        publication: value,
      });
    }

    if (feild == "nicaId") {
      this.setState({
        nicaId: value.replace(/ +(?= )/g, ""),
      });
    }

    if (feild == "digitalType") {
      this.setState({
        digitalType: value,
      });
    }
  };

  onStartDateChange = (date) => {
    this.setState({
      startDate: date,
      minDate: date,
    });
  };

  onEndDateChange = (date) => {
    this.setState({
      endDate: date,
      maxDate: date,
    });
  };

  onPaginate = (page, flag) => {
    this.setState({
      pageNo: page,
    });

    this.fetchSearchContent(page);
  };

  onReset = (e) => {
    e.preventDefault();
    this.reset();
  };

  reset = async () => {
    this.setState({
      resetable: false,
      documentId: "",
      nicaId: "",
      publication: "All",
      keywords: "",
      digitalType: "All",
      endDate: new Date(),
      startDate: getDate(),
      minDate: getDate(),
      maxDate: new Date(),
      pageNo: 1,
      selected: { digitalType: "", sourceType: "", documentId: "", year: "" },
    });
    await this.props.resetSearchContent();
  };

  onSearch = (e) => {
    e.preventDefault();
    this.setState({
      pageNo: 1,
    });
    this.fetchSearchContent(1);
  };

  fetchSearchContent = async (pageNo) => {
    await this.props.startLoader();
    this.props.resetSearchContent();
    this.setState({ resetable: true });
    const {
      keywords,
      publication,
      digitalTypes,
      digitalType,
      nicaId,
      startDate,
      endDate,
    } = this.state;

    const digitalTypeObject = digitalTypes.filter((type) => {
      return type.name == digitalType;
    });
    let sDate = new Date(startDate);
    let sDateModified = new Date(
      sDate.getTime() - sDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    let eDate = new Date(endDate);
    let eDateModified = new Date(
      eDate.getTime() - eDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    let search_object = {
      keywords: keywords.trim(),
      sortBy: "publicationdate",
      sortOrder: "desc",
      publication: publication == "All" ? [] : [publication],
      pageSize: 10,
      fromHome: true,
      pageNo: pageNo,
      digitalType: digitalTypeObject[0].digitalType,
      sourceType:
        digitalTypeObject[0].sourceType == ""
          ? "all"
          : digitalTypeObject[0].sourceType,
      nicaId: nicaId.trim(),
      dateRange: {
        fromDate: sDateModified,
        toDate: eDateModified,
      },
    };

    await this.props.searchContent(search_object);
    this.setState({ resetable: false });
    await this.props.stopLoader();
  };

  onDelete = (e) => {
    e.preventDefault();
    this.setState({
      deleteModal: true,
    });
  };

  delete = async () => {
    await this.props.startLoader();
    this.setState({
      deleteModal: false,
    });
    const { selected } = this.state;
    await this.props.deleteContent(
      `${selected.digitalType}/${selected.sourceType}/${selected.documentId}/${selected.year}`
    );
    this.reset();

    if (this.props.contentDeletedError) {
      this.setState({
        success: true,
        errdata: [this.props.adminReducer.contentDeletedError],
        message: "Error",
      });
    } else {
      this.setState({
        success: true,
        errdata: [],
        message: "Document deleted successfully.",
      });
    }

    await this.props.stopLoader();
  };

  onClose = () => {
    this.setState({
      disabled: false,
      success: false,
    });
  };

  onSelection = (digitalType, sourceType, documentId, year) => {
    this.setState({
      selected: {
        digitalType: digitalType,
        sourceType: sourceType,
        documentId: documentId,
        year: year,
      },
    });
  };

  onDeleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  render() {
    const { searchContents, loading } = this.props;
    const {
      deleteModal,
      selected,
      startDate,
      endDate,
      minDate,
      maxDate,
      success,
      errdata,
      pageNo,
      nicaId,
      keywords,
      message,
      publication,
      publications,
      digitalType,
      digitalTypes,
      resetable,
    } = this.state;
    return (
      <>
        {success && (
          <Modal
            customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
            onClick={this.onClose}
            errorFlag={success}
          >
            <ErrorPopup
              onClose={this.onClose}
              msg={message}
              errData={errdata}
              {...this.props}
            />
          </Modal>
        )}
        {deleteModal && (
          <Modal
            onClick={() => this.onDeleteModalClose()}
            customClass="login-modal"
          >
            <div className="col-lg-12 col-md-12 pt-3 pb-5">
              <h3>{`Are you sure you want to delete the selected content?`}</h3>
              <ButtonElement
                variant="contained"
                btnType="background-orange button-label float-right"
                className="d-inline-block mr-2 "
                onClick={() => this.onDeleteModalClose()}
              >
                <span className="button-label">No</span>
              </ButtonElement>{" "}
              <ButtonElement
                variant="contained"
                btnType="background-orange button-label float-right"
                className="d-inline-block mr-2 "
                onClick={() => this.delete()}
              >
                <span className="button-label">Yes</span>
              </ButtonElement>
            </div>
          </Modal>
        )}
        {loading && (
          <div className="loader text-center ">
            <Spinner primary={true} />
          </div>
        )}
        <form className="p-4">
          <h3 className="heading">Delete Content</h3>
          <div className="row">
            <div className="col-4">
              <label className="sortorder pt-3 pr-3 ">
                {DOCUMENT_ID_LABEL}
              </label>
              <input
                placeholder={DOCUMENT_ID_PLACEHOLDER}
                onChange={(e) => this.onChange("nicaId", e.target.value)}
                type="text"
                value={nicaId}
                className="search-input p-1 pl-4 w-100 "
              />
            </div>
            <div className="col-4">
              <label className="sortorder pt-3 pr-3 ">
                Enter a search Keyword
              </label>
              <input
                placeholder="Search"
                value={keywords}
                onChange={(e) => this.onChange("keywords", e.target.value)}
                type="text"
                className="search-input p-1 pl-4 w-100 "
              />
            </div>
            <div className="col-4">
              <label className="sortorder pt-3 pr-3">
                Select a Content Type
              </label>
              <select
                placeholder={DOCUMENT_ID_PLACEHOLDER}
                value={digitalType}
                onChange={(e) => this.onChange("digitalType", e.target.value)}
                type="text"
                className="search-input p-1 pl-4 w-100 "
              >
                {digitalTypes.map((type, index) => {
                  return <option key={index}> {type.name} </option>;
                })}
              </select>
            </div>

            <div className="col-4">
              <label className="sortorder pt-3 pr-3 ">Enter a Start Date</label>
              <DatePickerElement
                onChange={this.onStartDateChange}
                className="w-100"
                value={startDate ? startDate : ""}
                maxDate={maxDate}
              />
            </div>
            <div className="col-4">
              <label className="sortorder pt-3 pr-3 ">Enter a End Date</label>
              <DatePickerElement
                onChange={this.onEndDateChange}
                className="w-100"
                value={endDate ? endDate : ""}
                minDate={minDate}
              />
            </div>
            <div className="col-4">
              <label className="sortorder pt-3 pr-3 ">
                Select a Publication
              </label>
              <select
                placeholder={DOCUMENT_ID_PLACEHOLDER}
                value={publication}
                onChange={(e) => this.onChange("publication", e.target.value)}
                type="text"
                className="search-input p-1 pl-4 w-100 "
              >
                {publications.map((pub) => {
                  return <option>{pub}</option>;
                })}
              </select>
            </div>
            <div className="col-12 pt-2">
              <ButtonElement
                type="submit"
                variant="contained"
                btnType="background-orange button-label"
                className="d-inline-block mr-2"
                onClick={(e) => this.onSearch(e)}
                disabled={
                  keywords.length > 0 || nicaId.length > 0 ? false : true
                }
              >
                <span className="button-label">{SEARCH_BUTTON_VALUE}</span>
              </ButtonElement>
              <ButtonElement
                type="submit"
                variant="contained"
                btnType="background-orange button-label"
                className="d-inline-block mr-2"
                onClick={(e) => this.onReset(e)}
                disabled={
                  keywords.length > 0 ||
                  nicaId.length > 0 ||
                  publication != "All" ||
                  digitalType != "All"
                    ? false
                    : true
                }
              >
                <span className="button-label">RESET</span>
              </ButtonElement>
              <p>
                * Documents are searched only from the last two years. For older
                records, please adjust the start date.
              </p>
            </div>
          </div>
          {searchContents && searchContents.totalCount > 0 && (
            <div className="row mt-4">
              <div className="col-12">
                <h4>
                  Search Results ({(pageNo - 1) * 12 + 1}-
                  {searchContents.totalCount > pageNo * 12
                    ? pageNo * 12
                    : searchContents.totalCount}{" "}
                  out of {searchContents.totalCount} result)
                </h4>
                <ButtonElement
                  type="submit"
                  variant="contained"
                  btnType="background-orange button-label"
                  className="d-inline-block mr-2"
                  onClick={(e) => this.onDelete(e)}
                  disabled={selected.documentId.length > 0 ? false : true}
                >
                  <span className="button-label">
                    {selected.documentId.length > 0
                      ? "DELETE SELECTED"
                      : "SELECT A CONTENT"}
                  </span>
                </ButtonElement>
              </div>
            </div>
          )}
          {searchContents && searchContents.totalCount == 0 && (
            <div className="row mt-4">
              <div className="col-12">
                <h4>No Results Found!</h4>
              </div>
            </div>
          )}
          {searchContents && (
            <div className="row m-0 mt-4">
              {searchContents.content &&
                searchContents.content.map((c, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <div
                        className="m-0 mb-2 p-2"
                        style={{
                          border: "1px solid gray",
                          borderRadius: 5,
                          fontSize: "13px",
                        }}
                      >
                        <div className="row">
                          <div className="col-6">
                            <Radio
                              className="m-0"
                              checked={selected.documentId == c.documentid}
                              onClick={() => {
                                this.onSelection(
                                  c.digitaltype,
                                  c.sourcetype,
                                  c.documentid,
                                  c.year
                                );
                              }}
                            />
                            <img
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                              src={c.thumbnailPath}
                              width="100"
                            />
                            <b>Page name</b> <br />
                            {c.pagename}
                            <br />
                            <b>Page No</b> <br />
                            {c.pageno}
                            <br />
                            <b>Digital Type</b> <br />
                            {c.digitaltype}
                            <br />
                          </div>
                          <div className="col-6">
                            <b>Headline</b> <br /> {c.headline_en}
                            <br />
                            <b>Publication</b> <br />
                            {c.publicationName}
                            <br />
                            <b>Pub date</b> <br />
                            {c.publicationdate}
                            <br />
                            <b>End Page No</b> <br />
                            {c.endpageno}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {searchContents && searchContents.totalCount > 0 && (
            <div className="row mt-3">
              <div className="col-12">
                <SearchPagination
                  numPagination={true}
                  currentPage={pageNo}
                  currentPageSize={10}
                  total={searchContents && searchContents.totalCount}
                  onPagination={(no) => this.onPaginate(no, true)}
                  searchObject={true}
                />
              </div>
            </div>
          )}
        </form>
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.adminReducer.isLoading,
  tokens: state.loginReducer.tokens,
  searchContents: state.applicationReducer.searchContent,
  contentTypes: state.applicationReducer.contentTypes,
  publications: state.applicationReducer.publications,
  contentDeletedError: state.adminReducer.contentDeletedError,
});
export default connect(mapDispatchStatetoProps, {
  startLoader,
  stopLoader,
  getUserDetails,
  clear_Error,
  clearAdminError,
  searchContent,
  deleteContent,
  getContentTypes,
  getPublications,
  resetSearchContent,
})(DeleteContent);
