import React, { Component } from "react";
import { ButtonElement } from "../../../components/UI/index";
import { OrangeTick } from "../../../assets/images";
import "./Popup.scss";

class Popup extends Component {
  onClose = async (e) => {
    e.preventDefault();
    await this.props.close();

  };

  render() {
    return (
      <div className="col-md-10 m-auto forgot-container">
        <form onSubmit={this.onClose}>
          <h3 className="login-heading mb-4 mt-5 ">{this.props.titles}</h3>
          <div className="text-center mt-5 mb-5">
            {this.props?.imgFlag && <img src={OrangeTick} className="icon-img pb-4" alt="orange-tick" />}
          </div>
          <div className="text-center mt-5 mb-5">

            <ButtonElement
              variant="contained"
              btnType="background-orange"
              className="d-inline-block mr-2 reset-password float-right"
              onClick={this.props.onClose}
            >
              close
            </ButtonElement>
          </div>
        </form>
      </div>
    );
  }
}


export default Popup
