import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './searchcolumn.css';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class SearchCoumnselect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openpop: false,
            objKeys: [],
            checkedArray: {},
            showBadges: []
        }

    }
    componentDidMount = async () => {
        const { checkedArray, showBadges } = this.state;
        const { options, pubListData, defaultvalues } = this.props;

        var ignoreClickOnMeElement = document.getElementById(this.props.id);
        document.addEventListener('click', (event) => {
            const className = event.target.parentElement && event.target.parentElement.className ? event.target.parentElement.className : "";
            var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
            if (!isClickInsideElement && className !== "close") {
                //Do something click is outside specified element;
                if (this.state.openpop) {
                    this.setState({ openpop: false })
                }
            }
        });
        this.state.objKeys = Object.keys(options[0].value);
        this.setState({
            objKeys: this.state.objKeys
        })
        if (defaultvalues) {
            // checkedArray[mainIndex][index2] = { pubCode: items.pubCode, mainIndex: mainIndex, index2: index2 };
            this.setDefaultvalues();
        } else {
            if (pubListData) {
                for (let x in pubListData["checkedArray"]) {
                    if (!checkedArray[0]) {
                        checkedArray[0] = []
                    }
                    checkedArray[x] = pubListData["checkedArray"][x]
                }
                for (let x in pubListData["badges"]) {
                    showBadges[x] = pubListData["badges"][x]
                }
                this.setState({ showBadges, checkedArray })
            }
        }

    }
    openPopover = (event) => {

        if (event.target.parentElement.className !== "close") {
            this.setState({ openpop: !this.state.openpop })
        }

    }
    checkOptions = (e, items, index2, mainIndex) => {

        const { checkedArray } = this.state;
        const isChecked = e.target.checked;
        if (!checkedArray[mainIndex]) {
            checkedArray[mainIndex] = [];
        }
        if (isChecked) {
            checkedArray[mainIndex][index2] = { pubCode: items.pubCode, mainIndex: mainIndex, index2: index2 };
            this.setState({
                checkedArray,
                showBadges: [...this.state.showBadges, { pubCode: items.pubCode, mainIndex: mainIndex, index2: index2 }]
            })
            let badges = [...this.state.showBadges, { pubCode: items.pubCode, mainIndex: mainIndex, index2: index2 }]
            this.sendToProps(badges);

        } else {
            this.removeCheckboxbadge(mainIndex, index2)
        }



    }
    removeCheckboxbadge = (mainIndex, index2) => {
        const { showBadges, checkedArray } = this.state;
        let idx;
        showBadges.filter((item, index) => {
            if (item && item.index2 === index2 && item.mainIndex === mainIndex) {
                idx = index
            }
        });
        showBadges[idx] = null;
        checkedArray[mainIndex][index2] = null;

        this.setState({ checkedArray, showBadges });
        this.sendToProps(showBadges);

    }
    sendToProps = (showBadges) => {
        const { checkedArray } = this.state;
        let datasend = []
        for (let x of showBadges) {
            if (x) {
                datasend.push(x.pubCode)
            }
        }
        this.props.onChange(JSON.parse(JSON.stringify(datasend)), this.props.id, checkedArray, JSON.parse(JSON.stringify(showBadges)))
    }

    setDefaultvalues = () => {
        // const  defaultvaluess=[ "BHO", "AR", "BM", "BT","TCO","HW","IC"]
        const { options,defaultvalues,userPub_list } = this.props;
        const { checkedArray, objKeys,showBadges } = this.state;
        let count=-1;
        for (let x of objKeys) {
              count++;
            if (options[0].value[x]) {
                for(let dfval of defaultvalues ){
                    if(Array.isArray(options[0].value[x])){
                    options[0].value[x].find((item,index)=>{
                        if(item.pubCode===dfval){

                            if (!checkedArray[count]) {
                                checkedArray[count] = [];
                            }
                           let codeMatch=userPub_list && userPub_list.find((xx)=>xx===item.pubCode)
                           if(codeMatch){
                            checkedArray[count][index] = { pubCode: item.pubCode, mainIndex: count, index2: index, isDefault:true };
                            this.state.showBadges.push({ pubCode: item.pubCode, mainIndex: count, index2: index ,isDefault:true});
                           }else{
                            checkedArray[count][index] = { pubCode: item.pubCode, mainIndex: count, index2: index };
                            this.state.showBadges.push({ pubCode: item.pubCode, mainIndex: count, index2: index});
                           }

                        }
                     });
                    }

                }
            }

        }
        this.setState({showBadges,checkedArray})
        this.sendToProps(showBadges);

    }
    render() {
        const { openpop, objKeys, checkedArray, showBadges } = this.state;
        const { options } = this.props;
        const opt = [];
        opt.push(options[0].value);
        let ischekbadges;
        for(let x of showBadges){
            if(x && (x !==null)){
                ischekbadges=true;
                break;
            }else{
                ischekbadges=false;
            }
        }
        // console.log(opt,adsearch)

        return (
            <div id={this.props.id} key={this.props.id} className=" container-fluid">
                {/* <a > */}

                {/* <input type="text" >

                            </input> */}
                <label className='pb-2 opacity-0'> {""} </label>
                <div key={this.props.id+"eew"} className={openpop ? "popover__wrappersc form-fieldnew d-flex  formfieldbrdr fieldfront":" popover__wrappersc form-fieldnew d-flex bd-highlight "} onClick={(e) => this.openPopover(e)}>

                 <div className='flex-grow-1'>
                    <>{!openpop && !ischekbadges &&this.props.placeholder}</>
                    <div key={this.props.id+"wee"} className={openpop ? "d-flex flex-wrap hight ":" d-flex flex-wrap  "} >
                        {
                          !openpop &&  showBadges && showBadges.map((item, index) => {
                                return (
                                    <>
                                        <div key={index+"pub"}  >
                                            {item && <>
                                                <span key={item+"pubcode"} className="badge badge-light"><span>{item.pubCode}</span>
                                                  {item.isDefault ?
                                                        (<span><span aria-hidden="true" style={{ fontSize: "20px" }} ></span></span>) :(
                                                             <span> <span aria-hidden="true" style={{ fontSize: "20px" }} onClick={() => this.removeCheckboxbadge(item.mainIndex, item.index2)}> &times;</span></span>
                                                             ) }

                                                </span> {item?.isDefault && <span>&nbsp;</span>}
                                            </>
                                            }

                                        </div>
                                    </>
                                )
                            })
                        }

                    </div></div>
                    {/* <FontAwesomeIcon icon={faAngleDown} className="search-icon"  /> */}
                    <div className='flex-shrink-1' key={this.props?.id+"font"}>
                    {/* <>{!openpop && !ischekbadges &&this.props.placeholder}</> */}
                        <FontAwesomeIcon className='' icon={faChevronDown} /></div>
                </div>

                {/* </a> */}

                <div key={this.props.id+"pop"} className={openpop ? " popover__contentnew openpopnew popupback" : " popover__contentnew nonopenpopnew"}>
                    {openpop && opt.map((d, index) => {
                        return (
                            <div className='cons' key={index}>

                                {objKeys && objKeys.map((datakeys, keys) => {
                                    if(datakeys !=="content_type"){
                                        if((this.props.adsearch && datakeys !=="online") || !this.props.adsearch){
                                    return (
                                        <div key={keys+"s"} className='p-1'>
                                            {d.header && Array.isArray(d[datakeys]) && d[datakeys].length >0 && <p className="popover__message ">{datakeys}</p>}

                                            {d[datakeys].length > 0 && d[datakeys].map((items, i) => {
                                                return (

                                                    <div key={i+"v"} className="form-check">
                                                        <input key={i+"dfd"} className="form-check-input" type="checkbox" disabled={checkedArray && checkedArray[keys] && checkedArray[keys][i] && checkedArray[keys][i]?.isDefault } defaultChecked={checkedArray && checkedArray[keys] && checkedArray[keys][i]} id="flexCheckDefault" onClick={(e) => this.checkOptions(e, items, i, keys)} />
                                                        <label key={i+"sa"} className="form-check-label" >
                                                            {items.publication}
                                                        </label>
                                                    </div>

                                                )
                                            })}

                                        </div>
                                    )}

                                }
                                })}
                            </div>

                        )
                    })}

                </div>
            </div>

        );
    }
}

export default SearchCoumnselect;
