import React, { Component } from "react";
import { connect } from "react-redux";

import { getCustomviewobj, getIcon, getPageviewobj } from "../../../helpers/common";
import "./css/articleDetails.scss";
import { downloaddoc, startLoader} from "../../../redux/actions/userAction";
import { setSearchObject,pageViewDatagtm,customViewDatagtm } from "../../../redux/actions/applicationsAction"
import { CustomArrows, Spinner } from "../../UI";
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { decodeToken } from "react-jwt";

class ArticleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokendecode:{}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // console.log( this.props?.downloaddata)

    let myDecodedToken;
    if (this.props && this.props?.downloaddata && this.props?.downloaddata?.singleDocument) {
       myDecodedToken = decodeToken(this.props?.tokens?.id_token);
       this.setPageViewcall(this.props.searchObject,myDecodedToken);
       this.setState({
        tokendecode:myDecodedToken
       })
    } else {
      this.props.history.push("/");
    }
    if(this.props.searchObject){
      this.props.setSearchObject(this.props.searchObject)
    }

  }

  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let docdetail=this.props?.downloaddata?.singleDocument;
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let url=window.location.href+`#${docdetail?.documentid}`;
    let obj= getPageviewobj(url,"articledetails",docdetail?.headline,user_status,"1",docdetail?.byline,docdetail?.publicationdate,id);
    this.props.pageViewDatagtm(obj,searchObject);
  }


  callRelatedArticle = async (data) => {

    await this.props.startLoader();
    await this.props.downloaddoc({
      "digitalType": data.digitaltype,
      "documentId": data.documentid,
      "sourceType": data.sourcetype,
      "keywords": ""
    })
    window.scrollTo(0, 0);
    this.customViewcall(this.props.searchObject,this.state.tokendecode)

  }
  customViewcall=(searchObject,token)=>{
    let docdetail=this.props?.downloaddata?.singleDocument;
    let user_status=token?.user_info?.user_type ? token?.user_info?.user_type:"" ;
    let id=token?.user_info?.visitor_id ? token?.user_info?.visitor_id : ""
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate);
    let objj= getCustomviewobj("articleDetails","click","","articleDetails","",user_status,"",docdetail?.byline,docdetail?.publicationdate,id);
    this.props.customViewDatagtm(objj,searchObject)
  }
 goback=()=>{

  this.props.history.goBack();
 }

  render() {
    const { isLoading } = this.props;
    let singleDocument = this.props?.downloaddata?.singleDocument;
    const similarDocument = this.props?.downloaddata?.similarDocument;
    const similarPhoto = this.props?.downloaddata?.similarPhoto;
    const newsPostLink = decodeURIComponent(this.props?.downloaddata?.newsPostLink);

    let bodyarticle = singleDocument?.bodyarticle.replace(/\n/g, '<p></p>')
    return (
      <div className="container">
        <div className="d-flex flex-nowrap">
          <div>
            <FontAwesomeIcon icon={faArrowLeft} size="2x" className="pt-3" onClick={()=>this.goback()} ></FontAwesomeIcon>
          </div>
          <div className="pb-2 col-md-12 col-12 p-0">

            {isLoading &&
              <div className="text-center pt-5 pb-5">
                <Spinner primary={true} />
              </div>
            }
            {!isLoading && <div className="col-10 col-md-8 m-auto article-details pt-3">
              {singleDocument && <>
                <img
                  src={getIcon("Articles", true)}
                  alt="logo"
                  className="logo d-inline-block"
                /> Article
                {/* <h2 className="article-headline pt-2">{singleDocument.headline}</h2> */}
                <div className="article-headline pt-2"
                  dangerouslySetInnerHTML={{
                    __html: `${DOMPurify.sanitize(singleDocument.headline)}`,
                  }}
                >
                </div>
                <div className="article-publication pt-2">
                  {singleDocument.section && <h6>Section:	{singleDocument.section}</h6>}
                  {singleDocument.byline && <h6>By:	{singleDocument.byline}</h6>}
                  {singleDocument.publication && <h6>Publication:	{singleDocument.publication} {singleDocument.date}/{singleDocument.month}/{singleDocument.year}</h6>}
                  {singleDocument && <h6>Page:	{singleDocument.pageno}</h6>}
                  {singleDocument.wordcount && <h6>No. of words:	{singleDocument.wordcount}</h6>}
                </div>
                <div className="article-content pt-5"
                  dangerouslySetInnerHTML={{
                    __html: `${DOMPurify.sanitize(bodyarticle)}`,
                  }}
                >
                </div>
              </>}
              <div className="enquiry pt-5">
                {/* <img className="d-inline-block mr-2 align-top"  src={newsPostLink} alt="news-post-logo" /> */}
                <a href={newsPostLink} target="blank" >
                  <img className="d-inline-block mr-2 align-top" src="../newspost.svg" alt="news-post-logo" />



                <h6 className="d-inline-block " style={{cursor:"pointer"}}>Article republication enquiry form</h6> </a>
              </div>
              <hr />
              {similarPhoto && similarPhoto.length > 0 && <><div className="similar-articles pt-2">
                <h1 className="heading">Related Images</h1>
                <div className="container d-flex">
                  {/* {similarPhoto && similarPhoto.length <=3 &&  similarPhoto.map((item,index)=>{
                 return (
            <div className="col-md-3 col-3 img-block" key={index}>
              <img src={item.thumbnailPath} width="200px" height="200px" alt="article" />
            </div>)
            })} */}
                </div>
                {similarPhoto && similarPhoto.length > 0 && <div className="pl-5 pr-5">
                  <CustomArrows images={similarPhoto} ></CustomArrows>
                </div>
                }
              </div>

                <hr /> </>}
              {similarDocument && <div className="similar-articles pt-2">
                <h1 className="heading">Similar articles</h1>
                {similarDocument.map((item, index) => {
                  return (
                    <li className="hoverlist" onClick={() => this.callRelatedArticle(item)}>{item.title}</li>
                  )
                })}

              </div>}
            </div>}
          </div>
        </div>
      </div>

    );
  }
}


const mapDispatchStatetoProps = (state) => ({
  ...state,
  digitalType: state.applicationReducer.digitalType,
  searchContents: state.applicationReducer.searchContent,
  isLoading: state.userReducer.isLoading,
  downloaddata: state.userReducer.downloaddata,
  searchObject:state.applicationReducer.searchObject,
  tokens: state.loginReducer.tokens,
});

export default connect(mapDispatchStatetoProps, {
  downloaddoc,
  startLoader,
  setSearchObject,
  pageViewDatagtm,
  customViewDatagtm
})(ArticleDetails);
