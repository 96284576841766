import React from "react";
import Button from "@material-ui/core/Button";
import "./button.scss";

const ButtonElement = (props) => {
  return (
    <Button
      type={props.type || "button"}
      variant={props.variant}
      className={`button-control ${props.btnType !== undefined && props.btnType} ${props.className
        } `}
      onClick={(e) => props.onClick ? props.onClick(e) : () => { }}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

export default ButtonElement;
