import React, { Component } from "react";
import { connect } from "react-redux";
import reactReferer from "react-referer";
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import { validationRules, formValidation } from "../../../helpers/common";
import { ButtonElement, Spinner } from "../../UI/index";
import { startLoader, userLogin } from "../../../redux/actions/loginAction";
import { adminLogin } from "../../../redux/actions/adminAction";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import "./adminlogin.scss";
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class adminlogin extends Component {
  constructor(props) {
    super(props);
    this.recaptcha = React.createRef(null);
    this.state = {
      userDetails: {
        userId: "",
        password: "",
      },
      referrer: reactReferer.referer(),
      submit: false,
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.history.push("/admin/home");
    }
  }

  onChange = (e, key) => {
    const { userDetails } = this.state;
    userDetails[key] = e.target.value;
    this.setState({
      userDetails,
    });
  };

  onResolved = async () => {
    const { userDetails } = this.state;
    const data = {
      password: userDetails.password,
      userId: userDetails.userId,
    };
    await this.props.adminLogin(data);
    if (this.props.admin_login_error) {
      this.recaptcha.reset();
    }
    const token = localStorage.getItem("token");
    if (token) {
      this.props.history.push("/admin/announcement");
      window.location.reload();
    }
  };

  onLogin = (e) => {
    e.preventDefault();
    const { userDetails } = this.state;
    this.setState({
      submit: true,
    });
    if (!formValidation(userDetails)) {
      this.props.startLoader();
      this.recaptcha.execute();
    }
  };
  loginwithOkta = ()=>{
    this.props.history.push("/home");
  }
  render() {
    const { loading, adminLoginSuccess, admin_login_error } = this.props;
    const { userDetails, submit, } = this.state;
    const isEmpty = Object.values([
      admin_login_error
    ]).some((x) => x);
    return (
      <>  <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={isEmpty}
        autoHideDuration={4000}
        onClose={this.clearError}
      >
        {!adminLoginSuccess ? (
          <Alert severity="error">
            {admin_login_error ? admin_login_error[0] : ""
            }
          </Alert>
        ) : (
          <Alert severity="success">{adminLoginSuccess}</Alert>
        )}
      </Snackbar>
        <div className="viewheight container-fluid">
          <div className=" p-2  m-auto login-container">
            {
              loading && <div className="text-center spinner">
                  <Spinner primary={true} />
              </div>
            }

            {/* {loading ? (
              <div className="text-center pt-5 pb-5">
                <Spinner primary={true} />
              </div>
            ) : (

            )} */}
            <>
              <button onClick={this.loginwithOkta()}>Login with Okta</button>
            </>
            <form
                className=""
                autoComplete="false"
                noValidate
                onSubmit={(e) => this.onLogin(e)}
              >
                <h3 className="login-heading mb-3">LOGIN</h3> <br />
                <p className="remindclass">use valid username and password to gain access to the administration portal</p>
                <input
                  className="form-control mb-3"
                  placeholder="Email*"
                  required
                  name="email"
                  autoComplete="email"
                  value={userDetails.userId}
                  onChange={(e) => this.onChange(e, "userId")}
                />
                <div className="pt-0">
                  <span className="error-message">
                    {validationRules(submit, userDetails.userId)}
                  </span>
                </div>
                <input
                  className="form-control mb-3"
                  type="password"
                  name="password"
                  placeholder="Password*"
                  required
                  autoComplete="user-password"
                  value={userDetails.password}
                  onChange={(e) => this.onChange(e, "password")}
                />
                <div className="pt-0">
                  <span className="error-message">
                    {validationRules(submit, userDetails.password)}
                  </span>
                </div>
                <div className="text-center mt-4">
                  <ButtonElement
                    type="submit"
                    variant="contained"
                    btnType="background-orange"
                    className="d-inline-block mr-2 login-button"
                  >
                    Log in
                  </ButtonElement>
                </div>
              </form>
            <Recaptcha
              size="invisible"
              ref={(ref) => (this.recaptcha = ref)}
              onResolved={this.onResolved}
              sitekey={RECAPTCHA_SITE_KEY}
            />
          </div>
        </div></>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.loginReducer.isLoading,
  contentTypes: state.applicationReducer.contentTypes,
  admin_login_error: state.adminReducer.admin_login_error,
  adminLoginSuccess: state.adminReducer.adminLoginSuccess,
});

export default connect(mapDispatchStatetoProps, {
  startLoader, userLogin,
  adminLogin
})(adminlogin);
