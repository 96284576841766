import React, { Component } from 'react';
import "./css/accountinfo.scss";
import { extractTelCodes, payalert } from "../../../../helpers/common";
import { Checkbox, SelectElement, ButtonElement, Spinner, Modal } from "../../../UI/index";
import { RECAPTCHA_SITE_KEY } from '../../../../config/constants';
import Recaptcha from "react-google-invisible-recaptcha";
import { getPreloads, startLoader, getInfoData, updateAccountInfo, clearAdminError, clearAdminAccountInfo, getUserDetailDataByuserId, seamlessLoginUrl } from '../../../../redux/actions/adminAction';
import { connect } from "react-redux";
import ErrorPopup from '../../Popup/ErrorPopup';
import { camelCase, startCase } from 'lodash';
import { salutations } from '../../../../config/constants/generalConstants';

const initialState = {
    showinfoScreen: false,
    subscriptionPopup: true,
    submit: false,
    accountinfostatus: [],
    userType: [],
    seamlesslogin_val: { key: 'seamless', value: 'seamlesslogin' },
    userAccount: {},
    status: -1,
    contactPerson: "",
    userDetail: {
        userAccountDTO: {
            httpReferRegx: "",
            ipAddressRange: "",
            login: "",
            seamless: false,
            status: "",
            type: "",
            hideLink: false,
            remark: "",
            password: "",
            retypePassword: ""
        },
        userProfileDTO: {
            address: "",
            company: "",
            email: "",
            phone: "",
            phoneCountryCode: "",
            postalCode: "",
            payAlert: false,
            firstName: "",
            lastName: "",
            country: "",
            salutation: "MR"
        }
    },
    success: false,
    errdata: [],
    message: "",
    hideLinkArr: [],
    reTypeErrMsg: false,
    disabled: false
}


class Accountinfo extends Component {
    state = initialState
    componentDidMount = async () => {
        await this.props.startLoader();
        const { userDetail } = this.state;
        let allUserType = await this.props.infoData.userTypes;
        await this.props.startLoader();
        await this.props.getInfoData();
        userDetail.userAccountDTO = {};
        userDetail.userProfileDTO = {}
        this.setState({
            userDetail
        })
        let userdetail_data = this.props && this.props?.userDetailData;

        if (userdetail_data.accountInfo) {
            userDetail.userAccountDTO.httpReferRegx = userdetail_data.accountInfo.httpReferRegx === "NA" ? "" : userdetail_data.accountInfo.httpReferRegx;
            userDetail.userAccountDTO.ipAddressRange = userdetail_data.accountInfo.ipAddressRange === "NA" ? "" : userdetail_data.accountInfo.ipAddressRange;
            userDetail.userAccountDTO.login = userdetail_data.accountInfo.login;
            userDetail.userAccountDTO.seamless = userdetail_data.accountInfo.seamless;
            userDetail.userAccountDTO.status = userdetail_data.accountInfo.status === "NA" ? "PENDING" : userdetail_data.accountInfo.status;
            userDetail.userAccountDTO.type = userdetail_data.accountInfo.type;

            userDetail.userAccountDTO.hideLink = userdetail_data.accountInfo.hideLink !== "NA" ? (userdetail_data.accountInfo.hideLink) : false;
            userDetail.userAccountDTO.remark = userdetail_data.accountInfo.remark !== "NA" ? userdetail_data.accountInfo.remark : "";
        }
        if (userdetail_data.basicInfo) {
            userDetail.userProfileDTO.email = userdetail_data.basicInfo.email !== "NA" ? userdetail_data.basicInfo.email : "";
            userDetail.userProfileDTO.address = userdetail_data.basicInfo.address !== 'NA' ? userdetail_data.basicInfo.address : "";
            userDetail.userProfileDTO.company = userdetail_data.basicInfo.company !== "NA" ? userdetail_data.basicInfo.company : "";
            userDetail.userProfileDTO.phone = userdetail_data.basicInfo.tel === "NA" || userdetail_data.basicInfo.tel === null ? "" : (userdetail_data.basicInfo.tel).toString();
            userDetail.userProfileDTO.phoneCountryCode = userdetail_data.basicInfo.phoneCountryCode === "" || userdetail_data.basicInfo.phoneCountryCode === "NA" || userdetail_data.basicInfo.phoneCountryCode === 0 ? 65 : (userdetail_data.basicInfo.phoneCountryCode);
            userDetail.userProfileDTO.postalCode = userdetail_data.basicInfo.postalCode === "NA" || userdetail_data.basicInfo.postalCode === null ? "" : (userdetail_data.basicInfo.postalCode).toString();
            userDetail.userProfileDTO.payAlert = userdetail_data.basicInfo.paymentAlert === "NA" || userdetail_data.basicInfo.paymentAlert == null ? false : (userdetail_data.basicInfo.paymentAlert);
            userDetail.userProfileDTO.country = userdetail_data.basicInfo.country;
            userDetail.userProfileDTO.salutation = userdetail_data.basicInfo.salutation ? userdetail_data.basicInfo.salutation : "";
        }

        let usertypeKeys = Object.keys(this.props.infoData.userTypes);
        let newUserTypesArr = [];
        if (userdetail_data && userdetail_data.accountInfo) {
            newUserTypesArr = usertypeKeys.map((userType) => ({ key: allUserType[userType].userTypeName, value: userType }))
            this.setState({
                userType: newUserTypesArr
            })
            let accountStatus = Object.keys(this.props?.infoData?.accountStatus)
            let newAccountstatusArr = [];
            newAccountstatusArr = accountStatus.map((status) => {
                return ({ key: startCase(camelCase(status)), value: status })
            })
            const userSelectedStatus = this.props && this.props.userdetail_data && this.props.userdetail_data.accountInfo.status
            this.setState({
                accountinfostatus: newAccountstatusArr,
                status: userSelectedStatus
            })
        }
        let hideLinkObj = Object.keys(this.props.infoData.hideLink)
        let hideLink = [];
        hideLink = hideLinkObj.map((hideLink) => {
            return ({ key: this.props.infoData.hideLink[hideLink], value: Boolean(Number(hideLink)) })
        })

        this.setState({
            userDetail,
            hideLinkArr: hideLink,
            contactPerson: userdetail_data.basicInfo.contactPerson

        })

    }
    setAccountInfoDetails = (value, field, number = false) => {
        const { userDetail } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            userDetail.userProfileDTO[field] = regex.test(value) || value === "" ? value : userDetail.userProfileDTO[field];
        } else {
            if (field === "postalCode" || field === "phone") {
                if (!isNaN(value)) {
                    userDetail.userProfileDTO[field] = (value.toString().trim());
                }
            } else
                userDetail.userProfileDTO[field] = value;
        }
        this.setState({
            userDetail,
        });
    }
    setAccountInfoDetails1 = (value, field, number = false) => {
        const { userDetail } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            userDetail.userAccountDTO[field] = regex.test(value) || value === "" ? value : userDetail.userAccountDTO[field];
        } else {
            if (field === "remark" && value.trim() === "") {
                userDetail.userAccountDTO[field] = value.trim();
            } else if (field === "seamless" && value === false) {
                userDetail.userAccountDTO.httpReferRegx = "";
                userDetail.userAccountDTO.ipAddressRange = ""
                userDetail.userAccountDTO[field] = value;
            } else if (field === "password" || field === "retypePassword") {
                userDetail.userAccountDTO[field] = value.trim();
            } else {
                userDetail.userAccountDTO[field] = value;
            }
        }
        this.setState({
            userDetail,
            reTypeErrMsg: false
        });

    }

    setcontactPerson = (value, field, number = false) => {
        let contactValue = value
        this.setState({
            contactPerson: contactValue
        });
    }

    onReset = (e) => {
        this.setState(initialState);
    }
    onSubmit = (e) => {
        this.setState({
            disabled: true
        })
        e.preventDefault();
        this.recaptcha.execute();
    }

    onResolved = async () => {
        const { userDetail, contactPerson } = this.state;
        const response = this.recaptcha.getResponse();
        userDetail.captchaToken = response;

        if (contactPerson) {
            let [fname, ...lname] = contactPerson.length > 0 ? (contactPerson).split(" ") : "";
            lname = lname.join(" ")
            userDetail.userProfileDTO.firstName = fname.length > 0 ? fname : "";
            userDetail.userProfileDTO.lastName = lname.length > 0 ? lname : "";
        }

        userDetail.userAccountDTO["status"] = this.props?.infoData?.accountStatus[userDetail.userAccountDTO.status];
        userDetail.userProfileDTO["phone"] = userDetail.userProfileDTO["phone"] === "" ? "" : (userDetail.userProfileDTO["phone"]);
        userDetail.userProfileDTO["postalCode"] = userDetail.userProfileDTO["postalCode"] === "" ? "" : (userDetail.userProfileDTO["postalCode"]);
        userDetail.userAccountDTO["password"] = userDetail.userAccountDTO["password"] !== "" ? (userDetail.userAccountDTO["password"]) : delete userDetail.userAccountDTO.password;
        userDetail.userAccountDTO["remark"] = userDetail.userAccountDTO["remark"] ? userDetail.userAccountDTO["remark"].trim() : userDetail.userAccountDTO["remark"];


        userDetail.userProfileDTO["address"] = userDetail.userProfileDTO["address"] ? userDetail.userProfileDTO["address"].trim() : userDetail.userProfileDTO["address"];
        userDetail.userProfileDTO["company"] = userDetail.userProfileDTO["company"] ? userDetail.userProfileDTO["company"].trim() : userDetail.userProfileDTO["company"];
        userDetail.userProfileDTO["email"] = userDetail.userProfileDTO["email"] ? userDetail.userProfileDTO["email"].trim() : userDetail.userProfileDTO["email"];
        userDetail.userProfileDTO["salutation"] = userDetail.userProfileDTO["salutation"] ? userDetail.userProfileDTO["salutation"].trim() : userDetail.userProfileDTO["salutation"];

        userDetail.userAccountDTO["httpReferRegx"] = userDetail.userAccountDTO["httpReferRegx"] ? userDetail.userAccountDTO["httpReferRegx"].trim() : userDetail.userAccountDTO["httpReferRegx"];
        userDetail.userAccountDTO["ipAddressRange"] = userDetail.userAccountDTO["ipAddressRange"] ? userDetail.userAccountDTO["ipAddressRange"].trim() : userDetail.userAccountDTO["ipAddressRange"]

        if (userDetail?.userAccountDTO["password"] !== "" && userDetail?.userAccountDTO["password"] !== userDetail?.userAccountDTO["retypePassword"] && userDetail?.userAccountDTO["retypePassword"] !== "") {
            this.setState(
                {
                    showinfoScreen: true,
                    errdata: ["Password and Retype Password does not match"],
                    message: []
                }
            )
            return
        }
        await this.props.clearAdminAccountInfo()
        await this.props.startLoader();
        window.scrollTo(0, 0);
        await this.props.updateAccountInfo(userDetail);
        if (this.props && this.props.accounInfoRes) {
            this.setState(
                {
                    showinfoScreen: true,
                    message: "Successfully Updated User Details",
                    errdata: []
                }
            )

        } else if (this.props && this.props?.updateAccountInfoError) {
            this.setState(
                {
                    showinfoScreen: true,
                    errdata: this.props?.updateAccountInfoError,
                    message: []
                }
            )
        }
    }
    onClose = async () => {
        const { userDetail } = this.state;
        this.setState({
            disabled: false
        })
        if (this.props?.updateAccountInfoError && this.props?.updateAccountInfoError.length > 0) {
            this.setState({
                showinfoScreen: false,
            })
        } else {
            if (this.props && this.props.accounInfoRes == null) {
                userDetail.userAccountDTO["password"] = "";
                userDetail.userAccountDTO["retypePassword"] = "";
            }
            this.setState({
                showinfoScreen: false,
                userDetail
            })
            await this.props.history.push({
                pathname: '/admin/userdetail-subscription',
                searchObject: {
                    userId: this.props && this.props.userDetailData.accountInfo.oid,
                },
            })
        }
    }
    onclosepopup = () => {
        this.setState({
            subscriptionPopup: true
        })
    }
    checkPassword = (e, field) => {

        if (e.target.value !== this.state.userDetail?.userAccountDTO?.password) {
            this.setState({
                reTypeErrMsg: true
            })
        }
    }
    emailSeamlessLoginUrl = async () => {
        this.setState({
            confirmModal: true
        })

    }
    onConfirmModal = async () => {
        const { userDetail } = this.state
        this.setState({
            confirmModal: false
        })
        await this.props.startLoader();
        await this.props.seamlessLoginUrl(userDetail.userAccountDTO.login);
        if (this.props && this.props.seamlessLoginRes) {
            this.setState(
                {
                    showinfoScreen: true,
                    message: this.props && this.props?.seamlessLoginRes?.message,
                    errdata: []
                }
            )

        } else if (this.props && this.props?.seamlessLoginErr) {
            this.setState(
                {
                    showinfoScreen: true,
                    errdata: this.props && this.props?.seamlessLoginErr,
                    message: []
                }
            )
        }
    }
    closeModal = () => {
        this.setState({
            confirmModal: false
        })
    }
    render() {
        const { loading, infoData } = this.props;
        const { showinfoScreen, errdata, message, userType, accountinfostatus, contactPerson, hideLinkArr, reTypeErrMsg, confirmModal, disabled } = this.state;
        const { codes = [], regions = [] } = extractTelCodes(infoData);
        const { userDetail } = this.state;
        return (
            <>
                {
                    showinfoScreen &&
                    <Modal
                        customClass={`${showinfoScreen ? "login-modal" : "forgot-password-modal"}`}
                        onClick={this.onClose}
                        errorFlag={showinfoScreen}
                    >
                        <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
                    </Modal>
                }
                {
                    confirmModal &&
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h4>Do you want to proceed with the seamless email?</h4>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">No</span>
                            </ButtonElement> {" "}
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label float-right"
                                className="d-inline-block mr-2 "
                                onClick={(e) => this.onConfirmModal(e)}
                            >
                                <span className="button-label">Yes</span>
                            </ButtonElement>

                        </div>
                    </Modal>
                }
                {loading && <div className="text-center spinner">
                    {/* <Spinner animation="border" variant="primary" /> */}
                    <Spinner primary={true} />
                </div>}
                <div >
                    <form>
                        <div className='row card'>
                            <div className='col-lg-12'>
                                <div> <label className='lab marginBottom' >User Type <span className="requiredStar">*</span></label></div>
                                <div className="d-flex flex-wrap p-1 selectlist-item" >
                                    {userType.length > 0 && userType.map((p) => (
                                        <div className='flexcheckbox padding5px' key={p.key}>
                                            <Checkbox key={p.key} labelValue={p.value} checked={(userDetail.userAccountDTO.type) === p.value}
                                                onChange={(e) =>
                                                    this.setAccountInfoDetails1(p.value, "type")}
                                            />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>
                                    ))}
                                </div>

                                <div> <label className='lab marginBottom top10' >Account Status <span className="requiredStar">*</span></label></div>
                                {accountinfostatus && accountinfostatus.map((p) => (
                                    <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                                        <Checkbox key={p.key} labelValue={p.value} checked={userDetail.userAccountDTO.status === (p.value)}
                                            onChange={(e) =>
                                                this.setAccountInfoDetails1(p.value, "status")}
                                        />
                                        <span className="margin-left-35">{p.key} </span>
                                    </div>))}
                            </div>
                        </div>
                        <div className='row card pt-2'>
                            <div className='col-lg-12 '>
                                <div className="row">
                                    <div className="col-md-6 top10">
                                        <label className='lab marginBottom' >Password </label>
                                        <input type="password"
                                            name="password"
                                            value={userDetail.userAccountDTO.password}
                                            placeholder="Enter password"
                                            className="search-input p-1 pl-4 w-100"
                                            onChange={(e) => this.setAccountInfoDetails1(e.target.value, 'password')}
                                        />
                                    </div>

                                    <div className="col-md-6 top10">
                                        <label className='lab marginBottom' >Re-type Password</label>
                                        <input type="password"
                                            name="re_password"
                                            placeholder="Enter password"
                                            value={userDetail.userAccountDTO.retypePassword}
                                            onChange={(e) => this.setAccountInfoDetails1(e.target.value, 'retypePassword')}
                                            // onBlur={(e) => this.checkPassword(e, 'retypePassword')}
                                            className="search-input p-1 pl-4 w-100"
                                        />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                    </div>
                                    <div className="col-md-6 ">
                                        {reTypeErrMsg ? <span className='err'>Password not mathed</span> : ''}
                                    </div>
                                </div>
                                {/* <div> <label className='lab ' >Referal Url Pattern <span className="requiredStar">*</span></label></div> */}
                                <div className="row pt-2">
                                    <div className="col-lg-6 col-md-6 top10">
                                        <label className='lab marginBottom' >Referal Url Pattern <span className="requiredStar">*</span></label>
                                        <input
                                            placeholder="Referal url pattern"
                                            value={userDetail.userAccountDTO.httpReferRegx}
                                            onChange={(e) => this.setAccountInfoDetails1(e.target.value, 'httpReferRegx')}
                                            type="text"
                                            className={userDetail.userAccountDTO.seamless ? "search-input p-1 pl-4 w-100 " : "search-input p-1 pl-4 w-100 disabled"}
                                            name="referal"
                                            autoComplete="false"
                                            disabled={userDetail.userAccountDTO.seamless ? false : true}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 top10 top-padding" style={{ marginTop: "-12px" }}>
                                        <Checkbox checked={userDetail.userAccountDTO.seamless} onChange={(e) =>
                                            this.setAccountInfoDetails1(e.target.checked, "seamless")}
                                        />
                                        <span className="margin-left-35">Seamless</span>
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-md-6 top10">
                                        <label className='lab marginBottom' >Ip Address or Ip Range </label>
                                        <input
                                            className={userDetail.userAccountDTO.seamless ? "search-input p-1 pl-4 w-100 " : "search-input p-1 pl-4 w-100 disabled"}
                                            name="ipAddressRange"
                                            autoComplete="false"
                                            value={userDetail.userAccountDTO.ipAddressRange}
                                            placeholder="Ip Address Range"
                                            disabled={userDetail.userAccountDTO.seamless ? false : true}
                                            onChange={(e) => this.setAccountInfoDetails1(e.target.value, 'ipAddressRange')}
                                        />
                                    </div>

                                    <div className="col-md-6 top10">
                                        <label className='lab marginBottom' >Hide Link <span className="requiredStar">*</span></label>
                                        <SelectElement
                                            defaultValue={userDetail.userAccountDTO.hideLink}
                                            options={hideLinkArr}
                                            className="p-1"
                                            onChange={(e) => this.setAccountInfoDetails1((e.target.value), "hideLink")}
                                        />
                                    </div>
                                </div>

                                <h4 className="heading">Contact Info</h4>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label className="pt-2 ">Contact Person <span className="requiredStar">*</span></label>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-2'>
                                            <SelectElement
                                                defaultValue={userDetail.userProfileDTO.salutation}
                                                className="white p-2"
                                                options={salutations}
                                                key="salutation"
                                                onChange={(e) =>
                                                this.setAccountInfoDetails(e.target.value, "salutation")
                                                }
                                            />
                                            </div>
                                            <div className='col-lg-9 col-md-10'>
                                                <input
                                                    className="search-input p-1 pl-4 w-100 "
                                                    name="contactperson"
                                                    autoComplete="false"
                                                    value={contactPerson}
                                                    placeholder="contact person"
                                                    type="text"
                                                    onChange={(e) => this.setcontactPerson(e.target.value, "contactPerson")}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <label className="pt-2 ">Email<span className="requiredStar">*</span></label>
                                        <input
                                            className="search-input p-1 pl-4 w-100 "
                                            name="company"
                                            autoComplete="false"
                                            type="text"
                                            value={userDetail.userProfileDTO.email}
                                            onChange={(e) => this.setAccountInfoDetails(e.target.value, "email")}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 '>
                                        <label className="pt-2 ">Telephone<span className="requiredStar">*</span></label>
                                        <SelectElement
                                            defaultValue={!userDetail.userProfileDTO.phoneCountryCode ? 65 : userDetail.userProfileDTO.phoneCountryCode}
                                            className="search-input"
                                            options={codes}
                                            onChange={(e) =>
                                                this.setAccountInfoDetails(Number(e.target.value), "phoneCountryCode")
                                            }
                                        />
                                    </div>

                                    <div className='col-lg-6 col-md-6 '>
                                        <label className="pt-2 ">Phone<span className="requiredStar">*</span></label>
                                        <input
                                            className="search-input p-1 pl-4 w-100 "
                                            name="phone"
                                            autoComplete="false"
                                            type="text"
                                            value={userDetail.userProfileDTO.phone}
                                            onChange={(e) => this.setAccountInfoDetails((e.target.value), "phone")}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label className="pt-2 ">Company<span className="requiredStar">*</span></label>
                                        <input
                                            className="search-input p-1 pl-4 w-100 "
                                            name="company"
                                            type="text"
                                            autoComplete="false"
                                            value={userDetail.userProfileDTO.company}
                                            onChange={(e) => this.setAccountInfoDetails(e.target.value, "company")}
                                        />
                                    </div>

                                    <div className="col-md-6  pb-3">
                                        <label className="pt-2 "> Country/Region<span className="requiredStar">*</span></label>
                                        <SelectElement
                                            defaultValue={userDetail.userProfileDTO.country}
                                            options={regions}
                                            className="p-1"
                                            onChange={(e) => this.setAccountInfoDetails(e.target.value, "country")}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label className="pt-2 ">Address<span className="requiredStar">*</span></label>
                                        <input
                                            className="search-input p-1 pl-4 w-100 "
                                            name="company"
                                            autoComplete="false"
                                            type="text"
                                            value={userDetail.userProfileDTO.address}
                                            onChange={(e) => this.setAccountInfoDetails(e.target.value, "address")}
                                        />

                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label className="pt-2 ">Postal Code<span className="requiredStar">*</span></label>
                                        <input
                                            className="search-input p-1 pl-4 w-100 "
                                            name="postalcode"
                                            autoComplete="false"
                                            type="text"
                                            value={userDetail.userProfileDTO.postalCode}
                                            onChange={(e) => this.setAccountInfoDetails((e.target.value), 'postalCode')}
                                        />

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-6 col-md-6">
                                        <label className="pt-2 ">Remark</label>
                                        <textarea
                                            className="search-input p-1 pl-4 w-100 "
                                            name="remark"
                                            autoComplete="false"
                                            type="text"
                                            placeholder="Remark"
                                            value={userDetail.userAccountDTO.remark}
                                            onChange={(e) => this.setAccountInfoDetails1((e.target.value), 'remark')}

                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-8 d-flex ">
                                        <label className="pt-3 pr- form-control-label">Payment Alert<span className="requiredStar">*</span></label>
                                        {payalert.length > 0 && payalert.map((p) => (
                                            <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                                                <Checkbox key={p.key} labelValue={p.value} checked={userDetail.userProfileDTO.payAlert === (p.value)}
                                                    onChange={(e) =>
                                                        this.setAccountInfoDetails((p.value), "payAlert")}
                                                />
                                                <span className="margin-left-35">{p.key} </span>
                                            </div>))}
                                    </div>
                                </div>
                                <span className='mailInfo'>Note: *Only when on selecting seamless and then clicking update changes will take effect</span>

                                <div>
                                    <div className="text-right pt-3 pb-5 button-res">
                                        {this.props?.userDetailData?.accountInfo?.seamless ? <ButtonElement
                                            onClick={() => this.emailSeamlessLoginUrl()}
                                            type="button"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 mb-2 float-right"
                                        >
                                            <span className="button-label" >EMAIL SEAMLESS LOGIN URL</span>
                                        </ButtonElement> : null}
                                        <ButtonElement
                                            type="button"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 mb-1 float-right"
                                            onClick={this.onSubmit}
                                            disabled={disabled}
                                        >
                                            <span className="button-label">UPDATE</span>
                                        </ButtonElement>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>
                    <Recaptcha
                        size="invisible"
                        ref={(ref) => (this.recaptcha = ref)}
                        onResolved={this.onResolved}
                        sitekey={RECAPTCHA_SITE_KEY}
                    />
                </div>
            </>
        );
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    // telCodes: state.adminReducer.telCodes,
    infoData: state.adminReducer.infoData,
    // userDetailsByUserId: state.adminReducer.userDetailsByUserId,
    accounInfoRes: state.adminReducer.accounInfoRes,
    updateAccountInfoError: state.adminReducer.updateAccountInfoError,
    seamlessLoginRes: state.adminReducer.seamlessLoginRes,
    seamlessLoginErr: state.adminReducer.seamlessLoginErr
});
export default connect(mapDispatchStatetoProps, {
    getPreloads,
    startLoader,
    getInfoData,
    updateAccountInfo,
    clearAdminError,
    clearAdminAccountInfo,
    getUserDetailDataByuserId,
    seamlessLoginUrl
})(Accountinfo)
