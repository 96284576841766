import React, { Component } from 'react';
import { ButtonElement, Modal, Spinner } from '../UI';
import DOMPurify from "dompurify";
import { connect } from 'react-redux';
import {getActiveSubscription,showLoginpopup} from "../../redux/actions/applicationsAction";
import './others.scss';
import { OrangeTick } from '../../assets/images';
class ActivateSubscription extends Component {
    constructor(props) {
        super(props);
        this.state= {
            closemodalpopup:true
        }
    }
    async componentDidMount(){
        // console.log(this.props.match.params.key);
       await this.props.getActiveSubscription(this.props?.match?.params?.key);
        // console.log(this.props.activateSubcription);
    }

    closePopup =(flag)=>{
        this.setState({
            closemodalpopup :false
        })
        if(flag){
            this.props.showLoginpopup(true);
        }else{
            this.props.history.push("/");
        }
    }
    render() {
        const  {closemodalpopup} = this.state;
        const { activateSubcription } = this.props;
        return (
            <div style={{height:"60vh"}} >
                <div className="text-center">
                {!activateSubcription &&<Spinner primary={true} transparent={true} ></Spinner>}
                </div>
            {
            activateSubcription && closemodalpopup &&
             <Modal customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closePopup(false)}>
            <div
            dangerouslySetInnerHTML={{
              __html: `${DOMPurify.sanitize(activateSubcription)}`,
            }} ></div>
            <img src={OrangeTick} className="icon-imgss pb-4 center" alt="orange-tick" />
             <div className="center buttonlongmobile" >
                  <ButtonElement
                   variant="contained"
                   btnType="background-orange"
                   className="d-inline-block mr-2 search-button buttonlongmobile"
                   onClick={()=>this.closePopup(true)}
                      >
                    Login
                  </ButtonElement>
                </div>
            </Modal>}

        </div>
        );
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    activateSubcription:state.applicationReducer.activateSubcription

  });

  export default connect(mapDispatchStatetoProps, {
    getActiveSubscription,
    showLoginpopup
  })(ActivateSubscription);
