import React, { Component } from "react";
import "./pagination.scss";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
class Pagination extends Component {
  pages = [10, 20, 30, 40, 50];
  state = {
    currentPage: 1,
    currentPageSize: 10,
    totalPages: 1
  };

  componentDidMount() {
    const pageSize = Math.round(this.props.total / this.props.currentPageSize);
    this.setState({
      currentPage: this.props.currentPage,
      currentPageSize: this.props.currentPageSize,
      totalPages: pageSize < 1 ? 1 : pageSize
    });
  }

  componentDidUpdate() {
    if (this.props.currentPage && this.props.currentPage !== this.state.currentPage) {
      this.setState({
        currentPage: this.props.currentPage,
        totalPages: this.props.total / this.props.currentPageSize
      });
    }
    if (
      this.props.currentPageSize &&
      this.props.currentPageSize !== this.state.currentPageSize
    ) {
      this.setState({
        currentPageSize: this.props.currentPageSize,
        totalPages: this.props.total / this.props.currentPageSize
      });
    }
  }

  onPgnoClick = async (e, ele) => {
    this.props.onPagination(ele - 1);
    this.setState({
      currentPage: ele,
    });
  };

  onClick = async (e, type) => {
    if (type === "prev") {
      if (this.state.currentPage > 1) {
        const currentPage = this.state.currentPage - 1;
        this.props.onPagination(currentPage - 1);
        await this.setState({
          currentPage: this.state.currentPage - 1,
        });
      }
    }

    if (type === "next") {
      if (this.state.currentPage < this.state.totalPages) {

        const currentPage = this.state.currentPage + 1;
        this.props.onPagination(currentPage - 1);
        await this.setState({
          currentPage: this.state.currentPage + 1,
        });
      }
    }
  };

  onPageSizeChange = (e) => {
    this.setState({
      currentPageSize: e.target.value,
    });
    this.props.onPageSizeChange(e.target.value);
  };

  fetchPageNumbers = () => {
    const { totalPages, currentPage } = this.state;
    const pageNeighbours = 1;

    const totalNumbers = pageNeighbours * 2 + 2;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];
      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;
      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;
      pages = range(startPage, endPage);
      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;
      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
      } else if (leftSpill && rightSpill) {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    const { total, numPagination = false } = this.props;
    const { currentPage, totalPages } = this.state;
    const pagesArray = this.fetchPageNumbers();

    return (
      <div className="custom_pagination">
        <div className="col-md-12 p-0">
          {numPagination ? null : <div className="d-inline-block col-md-6 p-0 float-left">
            <h2 className="mt-1">Total: {total}</h2>
          </div>}
          <div className={`d-inline-block ${numPagination ? "col-md-12 p-0" : "col-md-6"}`}>
            <button
              onClick={(e) => {
                this.onClick(e, "next");
              }}
              className={`custom_pagination__left  float-right ${numPagination ? 'font-large' : ''}`}
              disabled={currentPage === totalPages}
            >&gt;</button>
            {numPagination ?
              <div className="d-inline-block float-right page-number">
                {pagesArray.map((ele, index) => {
                  let pgno = ele
                  if (pgno === LEFT_PAGE)
                    return (
                      <button key={index} onClick={(e) => {
                        this.onPgnoClick(e, this.state.currentPage - 3)
                      }} className={`custom_pagination__pgno ${this.state.currentPage === ele ? 'active' : ''}`}>
                        {/* ... */}
                        &laquo;
                      </button>
                    );

                  if (pgno === RIGHT_PAGE)
                    return (
                      <button key={index} onClick={(e) => {
                        this.onPgnoClick(e, this.state.currentPage + 3)
                      }} className={`custom_pagination__pgno ${this.state.currentPage === ele ? 'active' : ''}`}>
                        {/* ... */}
                        &raquo;
                      </button>
                    );
                  return <button key={index} onClick={(e, ele = pgno) => { this.onPgnoClick(e, ele) }} className={`custom_pagination__pgno ${this.state.currentPage === ele ? 'active' : ''}`}>{ele}</button>
                })}
              </div> : null}
            <button
              disabled={currentPage === 1}
              onClick={(e) => {
                this.onClick(e, "prev");
              }}
              className={`custom_pagination__left  float-right ${numPagination ? 'font-large' : ''}`}
            >&lt;</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Pagination;
