import React, { Component } from "react";
import { connect } from "react-redux";
import reactReferer from "react-referer";
import { getIcon } from "../../../helpers/common";
import "./css/contentType.scss";
import { downloaddoc } from '../../../redux/actions/userAction';
import { IconComment } from "../../../assets/images/index";
import DOMPurify from "dompurify";


class Image extends Component {
  state = {
    referer: reactReferer.referer(),
  };

  onClick = async (data, file) => {
    // const { extraData } = this.props.applicationReducer;
    // await this.props.downloaddoc({
    //   "digitalType": extraData.digitalType,
    //   "documentId": data.documentid,
    //   "sourceType": extraData.sourceType
    //   })
    if (this.props.onClick) {
      this.props.onClick(data);
    }
  }
  onClick2 = (data, file) => {
    if (this.props.passArticle) {
      this.props.passArticle(data, file)
    }
  }
  pictureEnquiryForm = async (e) => {

    const { data } = this.props


    localStorage.setItem("imageData", JSON.stringify(data))
    // await this.props.history.push({
    //   pathname: '/enquiry',
    //   dataInfo: data
    // }, "_blank")
    window.open("/enquiry","_blank")
  }
  render() {
    const { data,ispdf,iconflag } = this.props
    const pageName=data?.pagename ? data?.pagename.toLowerCase() : "";
    return (
      <>
     {this.props.digitalType !=="All" ? (<div className="pb-2 col-md-4 d-inline-block col-12">
        {data && <div className="image-container" >
          <div className="d-flex image pb-1" style={{ cursor: "pointer" }} onClick={() => this.onClick(data)}>
            <img src={data.thumbnailPath} className="col-12 p-0" alt="imageItem" />
          </div>
          {/* <h6 className="m-0 p-0 ellipses">{data.caption_en}</h6> */}
          <div className="m-1 p-0 ellipses fontlikeh6"
                     dangerouslySetInnerHTML={{
                      __html: `${DOMPurify.sanitize(data.caption_en)}`,
                    }}
                  ></div>
          <p className="m-1 by">{data.byline_en && <span>  By: {data.byline_en} <br /></span> }
                  {data.publicationName && <span>{data.publicationName}<br  className="hidethebr" /></span>}
                  {data.pagename && <span>({data.pagename})</span>} <br className="hidethebr" />
                  {data?.date}/{data?.month}/{data?.year}</p>
          <div className="row">
           {pageName !=="heritage" ?  (<>
            {!data.pdfDocumentId ? (<div className="col-md-6 pageName2 pb-2" >
              <img
                src={getIcon("PDF", true)}
                alt="logo"
                className="logo d-inline-block"
              /> Page {data.pageno}
            </div>) : (
              <>
              {ispdf && <div className="col-6 pageName pb-2 pr-0" >
                <img
                  src={getIcon("PDF", true)}
                  alt="logo" height="20px"
                  className="logo d-inline-block"
                /> <span onClick={() => this.onClick2(data, "file")} style={{ cursor: "pointer" }} data-documentid={data.pdfDocumentId}> Page {data.pageno}</span>
              </div>}
              </>
            )}
            </>) :(<div className="col-md-6 pageName2 pb-2" ></div> )}
            <div className={ispdf ? " col-6" :" offset-md-6 col-md-6"}>
              <span className="enquiry" onClick={(e) => this.pictureEnquiryForm(e)}  ><img src={IconComment} alt="img" /></span>
            </div>
          </div>


        </div>}
        <hr />
      </div>) : (
        <>
         <div className="pb-2 container article-container">
                <div className="row ">
                <div className="col-md-1  ">
      <img  src={getIcon(iconflag, true)}  alt="logo" className="" />
      </div>
      {/* {data.thumbnailPath} */}
                <div className="col-md-3 col-12  " style={{ cursor: "pointer" }} onClick={() => this.onClick(data)}>
                  <img src={data.thumbnailPath} className="  imagess center" alt="images" />
                </div>
                <div className="col-md-8 col-12 d-inline-block">
                {/* <h6 className="m-0 p-0 ellipsesforall">{data.caption_en}</h6> */}
                  <div className="m-1 p-0 ellipsesforall fontlikeh6"
                     dangerouslySetInnerHTML={{
                      __html: `${DOMPurify.sanitize(data.caption_en)}`,
                    }}
                  ></div>
                  <p className="m-1 by">{data.byline_en && <span>  By: {data.byline_en} <br /></span>}
                    {data.publicationName && <span>{data.publicationName}<br /></span>}
                    {data.pagename && <span>({data.pagename})</span>} <br />
                    {data?.date}/{data?.month}/{data?.year}</p>
                  <div className="row">
                  { pageName !=="heritage" ? ( <>
                    {!data.pdfDocumentId ? (<div className="col-md-6 pageName2 pb-2" >
                      <img
                        src={getIcon("Images", true)}
                        alt="logo"
                        className="logo d-inline-block"
                      /> Page {data.pageno}
                    </div>) : (
                      <>
                        {ispdf && <div className="col-md-6 pageName pb-2" >
                          <img
                            src={getIcon("PDF", true)}
                            alt="logo" height="20px"
                            className="logo d-inline-block"
                          /> <span onClick={() => this.onClick2(data, "file")} style={{ cursor: "pointer" }} data-documentid={data.pdfDocumentId}> Page {data.pageno}</span>
                        </div>}
                      </>
                    )}
                     </>) :(<div className="col-md-6 pageName2 pb-2" ></div> )}
                    <div className={ispdf ? " col-md-6" : " offset-md-6 col-md-6"}>
                      <span className="enquiry" onClick={(e) => this.pictureEnquiryForm(e)}  ><img src={IconComment} alt="img" /></span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <hr />

        </>
      ) }
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  contentType: state.applicationReducer.contentType,
  digitalType:state.applicationReducer.digitalType
});

export default connect(mapDispatchStatetoProps, {
  downloaddoc
})(Image);
