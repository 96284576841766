import axios from "axios";
import store from "../store";
import { openLogout_popup } from "./loginAction";

let headers = {
  "X-Frame-Options": "deny",
};
if (
  process.env.REACT_APP_ACTIVE_ENV == "LOCAL" &&
  process.env.REACT_APP_ADMIN_TOKEN_LOCAL != ""
) {
  headers = {
    "X-Frame-Options": "deny",
    Authorization: "Bearer " + process.env.REACT_APP_ADMIN_TOKEN_LOCAL,
  };
}

const axiosInstance = axios.create({
  withCredentials: true,
  "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_ORIGIN,
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
  "Access-Control-Allow-Headers": "X-Requested-With,content-type",
  "Access-Control-Allow-Credentials": true,
  baseURL: process.env.REACT_APP_DOMAIN,
  headers: headers,
});

axiosInstance.interceptors.request.use(
  (req) => {
    // Add configurations here
    // console.log(req)
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      return res;
    }
  },
  (err) => {
    // console.log("asios response", JSON.stringify(err));
    let error = JSON.stringify(err);
    if (error.includes("401")) {
      store.dispatch(openLogout_popup());
      // localStorage.removeItem("token")
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export default axiosInstance;
