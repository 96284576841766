import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from 'react-router-dom';



ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
  <App />
</BrowserRouter>
</React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
