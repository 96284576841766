import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable, Spinner } from "../../UI/index";
// import Spinner from "react-bootstrap/Spinner";
import {
  getUserSubscription,
  startLoader,
} from "../../../redux/actions/userAction";
import "./css/subscription.scss";
import { getUserDetails } from '../../../redux/actions/loginAction';
import { decodeToken } from "react-jwt";
import { getCookies, getPageviewobj } from "../../../helpers/common";
import {pageViewDatagtm,customViewDatagtm} from '../../../redux/actions/applicationsAction';
class Subscription extends Component {
  async componentDidMount() {
    this.props.startLoader();
    // await this.props.getUserDetails();
    const id_token = getCookies("id_token");
    const myDecodedToken = decodeToken(id_token);
    await this.props.getUserSubscription(myDecodedToken?.sub);
    this.setPageViewcall(this.props.searchObject,myDecodedToken);
  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
      let url=window.location.href;
      let obj= getPageviewobj(url,"user account","Subscription Plan",user_status,"","","",id);
      this.props.pageViewDatagtm(obj,searchObject);
    }
  render() {
    const { subscriptionDetails, loading } = this.props;
    const { currentPlan, pastPlans } = subscriptionDetails;
    const planshow = currentPlan?.plan.split(" ");
    let plusflag;
    let plantosplit;
    if (planshow) {
      for (let x of planshow) {
        if (x === "plus") {
          plusflag = true;
        }
      }
    }
    if (plusflag) {
      plantosplit = currentPlan?.plan.split("plus");
    }
    let partoneplan = plantosplit && plantosplit[0].split(" ");


    return (
      <div className="subs-details">
        <div className="subscription-plan">
          <h1 className="heading pb-2">Your current subscription plan</h1>
          {loading ? (
            <div className="text-center">
              {/* <Spinner animation="border" variant="primary" /> */}
              <Spinner primary={true} />
            </div>
          ) : (
            <>
              {currentPlan ? (
                <div className="sub-display d-flex col-md-12">
                  <div className="sub-data col-md-12">
                    <div className="col-md-6 col-12 d-inline-block  ">
                      {plusflag && plantosplit ? (<><div className=" d-inline-block p-0"><h1 className="sub-name m-0">
                        <span className="">{partoneplan && partoneplan.map((itemss, indexx) => {
                          if (itemss === "Unlimited") {
                            return (<span className="extras unlimitescss">&nbsp;{itemss}</span>)
                          } else {
                            return (itemss)
                          }
                        })}
                        </span>
                      </h1>
                      </div>
                        <div className="">
                          <label className="ccs">plus {plantosplit[1]}</label>
                        </div>
                      </>) : (
                        <>
                          <div className="col-md-6 col-12 d-inline-block p-0">
                            <h1 className="sub-name m-0">
                              <span className="">{planshow && planshow.map((itemses, indexex) => {
                                if (itemses === "Unlimited") {
                                  return (<span className="extras unlimitescss">&nbsp;{itemses}</span>)
                                } else {
                                  return (itemses)
                                }
                              })}
                              </span>
                            </h1>
                          </div>
                        </>
                      )}

                      {/* <span className="sub-name">Premium </span>
                      <span className="sub-type">Unlimited</span>
                   <label className="extras">plus {plantosplit[1]}</label>

                  */}
                    </div>
                    <div className="col-md-6 col-12 d-inline-block p-0">
                      <h2 className={plusflag && plantosplit ? "sub-amount-content  mb-3" : "sub-amount-content  mb-0"}>
                        <span className="sub-amount">
                          <sup className="supclasscss">$ </sup>
                          {currentPlan.totalFee.split("$")[1]}
                        </span>
                        <sub className="subclasscss">/yr</sub>
                      </h2>
                    </div>
                    <hr className="hr-line" />

                    <div className="row">
                      <div className="col-md-9">
                        <div className=" ">
                          <label className="extras">
                            Article retrieval fee:{" "}<span className="tohidespan">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                          </label>
                          <span className="fees inlinedivs freefl">{currentPlan.articleFee}</span>
                        </div>
                        <div className="">
                          <label className="extras">
                            Full Page PDF retrieval fee:{" "}
                          </label>
                          <span className="fees inlinedivs">{currentPlan.pdfFee}</span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-inline-block ">
                          <label className="extras"> Expiry-date:</label>
                          <h1 className="fees inlinedivs">{currentPlan.expiryDate}</h1>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              ) : (
                <h5 className="text-center pb-2 pt-2">No Plan found.</h5>
              )}
            </>
          )}
        </div>
        <div className="past-subscription-plan pb-5">
          <h1 className="heading pb-2">Past subscription plans</h1>
          {loading ? (
            <div className="text-center">
              {/* <Spinner animation="border" variant="primary" /> */}
              <Spinner primary={true} />
            </div>
          ) : (
            <>
              {pastPlans && pastPlans.length ? (
                <DataTable bgFlag={true} columns={pastPlans[0]} data={pastPlans} />
              ) : (
                <h5 className="text-center pb-2 pt-2">
                  No Past subscription plans found.
                </h5>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  subscriptionDetails: state.userReducer.subscriptions,
  loading: state.userReducer.isLoading,
  userTokens: state.loginReducer.tokens,
});

export default connect(mapDispatchStatetoProps, {
  getUserSubscription,
  startLoader,
  getUserDetails,
  pageViewDatagtm,
  customViewDatagtm
})(Subscription);
