import axios from "./axios";
import useAxios from "./axiosUse";
import {
  START_ADMIN_LOADER,
  GET_USER_LIST,
  GET_USER_LIST_ERROR,
  GET_ADMIN_PRELOADS,
  GET_ADMIN_PRELOADS_ERROR,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_ERROR,
  GET_HERITAGE_PHOTO,
  GET_HERITAGE_PHOTO_ERROR,
  GET_USER_TYPE,
  GET_USER_TYPE_ERROR,
  GET_ACCOUNT_STATUS,
  GET_ACCOUNT_STATUS_ERROR,
  SUBSCRIPTION,
  SUBSCRIPTION_ERROR,
  FIND_USER,
  FIND_USER_ERROR,
  LOGIN_ADMIN,
  LOGIN_ADMIN_ERROR,
  CLEAR_ADMIN_ERROR,
  GET_SORT_BY,
  GET_SORT_BY_ERROR,
  GET_INFO_DATA,
  GET_INFO_DATA_ERROR,
  FIND_USAGE_DETAIL,
  FIND_USAGE_DETAIL_ERROR,
  GET_INFO_FOR_ANNOUNCEMENT,
  GET_INFO_FOR_ANNOUNCEMENT_ERROR,
  CLEAR_ADMIN_LOGIN_ERROR,
  UPDATE_STATUS,
  UPDATE_STATUS_ERROR,
  CREATE_AGENCY,
  CREATE_AGENCY_ERROR,
  GET_AGENCY,
  GET_AGENCY_ERROR,
  GET_AGENCY_PUBLISHER,
  GET_AGENCY_PUBLISHER_ERROR,
  GET_AGENCY_PUBLISHER_BY_ID,
  GET_AGENCY_PUBLISHER_BY_ID_ERROR,
  CREATE_AGENCY_PUBLISHER,
  CREATE_AGENCY_PUBLISHER_ERROR,
  UPDATE_AGENCY_PUBLISHER,
  UPDATE_AGENCY_PUBLISHER_ERROR,
  UPDATE_AGENCY_PUBLISHER_STATUS,
  UPDATE_AGENCY_PUBLISHER_STATUS_ERROR,
  UPDATE_AGENCY,
  UPDATE_AGENCY_ERROR,
  GET_USERDETAIL_DATA_BY_USERID,
  GET_USERDETAIL_DATA_BY_USERID_ERROR,
  UPDATE_USER_ACCOUNT_INFO,
  UPDATE_USER_ACCOUNT_INFO_ERROR,
  GET_AGENCY_INFO,
  GET_AGENCY_INFO_ERROR,
  GET_AGENCY_ROYALTY_REPORT,
  GET_AGENCY_ROYALTY_REPORT_ERROR,
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_ERROR,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATUS_ERROR,
  CALCULATE_FEE_BY_PACKAGE,
  CALCULATE_FEE_BY_PACKAGE_ERROR,
  CALCULATE_FEE_BY_SUBSCIPTION_FEE,
  CALCULATE_FEE_BY_SUBSCIPTION_FEE_ERROR,
  GET_AGENCY_USAGE,
  GET_AGENCY_USAGE_ERROR,
  USAGE_DETAIL_BY_ORDER_ID,
  USAGE_DETAIL_BY_ORDER_ID_ERROR,
  UPDATE_SUBSCIPTION,
  UPDATE_SUBSCIPTION_ERROR,
  STOP_ADMIN_LOADER,
  GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION,
  GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR,
  UPDATE_AGENCY_PACKAGE_ERROR,
  UPDATE_AGENCY_PACKAGE,
  UPDATE_EXTERNAL_SUBSCRIPTION,
  UPDATE_EXTERNAL_SUBSCRIPTION_ERROR,
  CREATE_EXTERNAL_SUBSCRIPTION,
  CREATE_EXTERNAL_SUBSCRIPTION_ERROR,
  GET_PACKAGE_CONFIGURATION,
  GET_PACKAGE_CONFIGURATION_ERROR,
  UPDATE_PACKAGES,
  UPDATE_PACKAGES_ERROR,
  GET_ALL_ADMIN_USERS,
  GET_ALL_ADMIN_USERS_ERROR,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_ERROR,
  GET_MAILINGLIST_RESPONSE,
  GET_MAILINGLIST_ERROR,
  UPDATE_MAILINGLIST_ERROR,
  UPDATE_MAILINGLIST_RESPONSE,
  CREATE_ADMIN_USER,
  CREATE_ADMIN_USER_ERROR,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_ERROR,
  DELETE_CONTENT,
  DELETE_CONTENT_ERROR,
  ADMIN_SEARCH_CONTENT,
  ADMIN_SEARCH_CONTENT_ERROR,
  CLEAR_ADMIN_USER_ERROR,
  CLEAR_UPDATE_ADMIN_ACCOUNT_INFO,
  ADD_CATEGORY_RESPONSE,
  ADD_CATEGORY_ERROR,
  ADD_PACKAGE_USER_TYPE_RESPONSE,
  ADD_PACKAGE_USER_TYPE_ERROR,
  GET_PACKAGE_BY_CODE_RESPONSE,
  GET_PACKAGE_BY_CODE_ERROR,
  UPDATE_CONFIG_PACKAGE_ERROR,
  UPDATE_CONFIG_PACKAGE_RESPONSE,
  CREATE_PACKAGE_ERROR,
  CREATE_PACKAGE_RESPONSE,
  SEAMLESS_LOGIN_EMAIL,
  SEAMLESS_LOGIN_EMAIL_ERROR,
  FETCH_ACTIVE_USER,
  FETCH_ACTIVE_USER_ERROR,
  SEND_BLAST_EMAIL,
  SEND_BLAST_EMAIL_ERROR,
  REMOVE_TOKEN,
  REMOVE_TOKEN_ERROR,
  REMOVE_TOKEN_BY_USERNAME,
  REMOVE_TOKEN_BY_USERNAME_ERROR,
  UPDATE_SYS_CONFIG,
  UPDATE_SYS_CONFIG_ERROR,
  DELETE_KEY,
  DELETE_KEY_ERROR,
  CLEAR_SELECT_SUB,
  CREATE_NEW_SUBSCRIPTION,
  CREATE_NEW_SUBSCRIPTION_ERROR,
  SYNC_AUTH_ERROR,
  SYNC_AUTH_SUCCESS,
  CLEAR_DEVSETTINGS,
  DELETE_WILD_CARD_KEY,
  DELETE_WILD_CARD_KEY_ERROR
} from "../../config/constants/action-constants";
import axiosInstance from "./axiosResp";
// const baseHomeUrl = "http://localhost:5000";

const getUserList = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_USER_BASE_URL}/userList`, data);
    dispatch({
      type: GET_USER_LIST,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_LIST_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const startLoader = () => async (dispatch) => {
  dispatch({
    type: START_ADMIN_LOADER,
  });
};
const stopLoader = () => async (dispatch) => {
  dispatch({
    type: STOP_ADMIN_LOADER,
  });
};

const getPreloads = () => async (dispatch) => {//done
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/countryTelCode`);

    dispatch({
      type: GET_ADMIN_PRELOADS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_ADMIN_PRELOADS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const updateAnnouncement = (announcement) => async (dispatch) => {//done
  try {
    const res = await axiosInstance.put(
      `${process.env.REACT_APP_ADMIN_BASE_URL}/updateAnnouncement`,
      announcement
    );
    dispatch({
      type: UPDATE_ANNOUNCEMENT,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ANNOUNCEMENT_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
};

const getHeritagePhoto = (documentId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_SEARCH_ADMIN_BASE_URL}/heritage-images/${documentId}`
    );
    dispatch({
      type: GET_HERITAGE_PHOTO,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_HERITAGE_PHOTO_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};

const adminSearchContent = (searchData) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_SEARCH_BASE_URL}/searchsvc`, searchData
    );
    dispatch({
      type: ADMIN_SEARCH_CONTENT,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: ADMIN_SEARCH_CONTENT_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};

const resetHeritagePhoto = () => (dispatch) => {
  dispatch({
    type: GET_HERITAGE_PHOTO,
    payload: { data: null, isLoading: false },
  });
  dispatch({
    type: GET_HERITAGE_PHOTO_ERROR,
    payload: null
  });
};
const getUserType = () => async (dispatch) => {//done
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/user_type,user_packages,digital_types,search_name,search_type,pkg_content_type,period_unit,pkg_status,pkg_config_sys`);

    dispatch({
      type: GET_USER_TYPE,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_TYPE_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getAccountStatus = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/account_status`);
    dispatch({
      type: GET_ACCOUNT_STATUS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_ACCOUNT_STATUS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getSubscriptions = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/subscription`);
    dispatch({
      type: SUBSCRIPTION,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const findUser = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/findUser`, data);
    dispatch({
      type: FIND_USER,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: FIND_USER_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const adminLogin = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_HOME_BASE_URL}/login/admin`, data, {
      withCredentials: true,
    });
    if (res.data.errors) {
      dispatch({
        type: LOGIN_ADMIN_ERROR,
        payload: res.data.errors[0]
      });
    }
    else {
      localStorage.setItem("token", "logged-in");
      dispatch({
        type: LOGIN_ADMIN,
        payload: { data: res.data, isLoading: false },
      });
    }
  } catch (err) {
    dispatch({
      type: LOGIN_ADMIN_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const clearAdminError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADMIN_ERROR,
  });
}
const clearDevSettings = () => (dispatch) => {
  dispatch({
    type: CLEAR_DEVSETTINGS,
  });
}
const changeSelectedSub = (data) => (dispatch) => {
  //
  dispatch({
    type: CLEAR_SELECT_SUB,
    payload: { data: data },
  });
}
const clearAdminUserErr = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADMIN_USER_ERROR,
  });
}
const getSortBy = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/sortBy`);
    dispatch({
      type: GET_SORT_BY,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_SORT_BY_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getInfoData = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/account_status,user_packages,user_type,sortBy,agencies,digital_types,hideLink,payment,external_agency_packages,sysConfig,mailingList,loginTypes,digital_types_admin,countryTelCode `);
    dispatch({
      type: GET_INFO_DATA,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: GET_INFO_DATA_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const findUsageDetails = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/findUsageDetails`, data);
    dispatch({
      type: FIND_USAGE_DETAIL,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: FIND_USAGE_DETAIL_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

// /getAnnouncementInfo
const getInfoForAnnouncement = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/language`);
    dispatch({
      type: GET_INFO_FOR_ANNOUNCEMENT,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_INFO_FOR_ANNOUNCEMENT_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};

const clearAdminLoginError = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ADMIN_LOGIN_ERROR,
  });
}
const clearAdminAccountInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_UPDATE_ADMIN_ACCOUNT_INFO,
  });
}

const updateStatus = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/updateStatus`, data);
    dispatch({
      type: UPDATE_STATUS,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_STATUS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const createAgency = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/createAgency`, data);
    dispatch({
      type: CREATE_AGENCY,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: CREATE_AGENCY_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const updateAgency = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/updateAgency`, data);
    dispatch({
      type: UPDATE_AGENCY,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_AGENCY_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getAgency = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/getAgency`, data);
    dispatch({
      type: GET_AGENCY,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_AGENCY_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
};
const getAgencyPublisher = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyPublisher/${data.agencyCode}`);
    dispatch({
      type: GET_AGENCY_PUBLISHER,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_AGENCY_PUBLISHER_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
};
const getAgencyPublisherById = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyPublisherId/${data}`);
    dispatch({
      type: GET_AGENCY_PUBLISHER_BY_ID,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_AGENCY_PUBLISHER_BY_ID_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
};
const createAgencyPublisher = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyPublisher`, data);
    dispatch({
      type: CREATE_AGENCY_PUBLISHER,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: CREATE_AGENCY_PUBLISHER_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const updateAgencyPublisher = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyPublisher`, data);
    dispatch({
      type: UPDATE_AGENCY_PUBLISHER,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_AGENCY_PUBLISHER_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const getUserDetailDataByuserId = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/userDetails/${data.userId}`, data);
    dispatch({
      type: GET_USERDETAIL_DATA_BY_USERID,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: GET_USERDETAIL_DATA_BY_USERID_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const updateAgencyPublisherStatus = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyPublisherStatus`, data);


    dispatch({
      type: UPDATE_AGENCY_PUBLISHER_STATUS,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_AGENCY_PUBLISHER_STATUS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const updateAccountInfo = (data) => async (dispatch) => {

  try {
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/userDetails`, data);

    dispatch({
      type: UPDATE_USER_ACCOUNT_INFO,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {

    dispatch({
      type: UPDATE_USER_ACCOUNT_INFO_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getAgenciesDdl = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/agencies,operation,yearRange,quarters,quarterMonths,user_type,user_packages,pkg_status,report_type,sort_order,find_sort_by`);
    dispatch({
      type: GET_AGENCY_INFO,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_AGENCY_INFO_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};
const getAgencyRoyaltyReport = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {

    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyRoyalty`, data);


    dispatch({
      type: GET_AGENCY_ROYALTY_REPORT,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: GET_AGENCY_ROYALTY_REPORT_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const createUserAccount = (data) => async (dispatch) => {
  // let axios = useAxios(true);

  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/createUser`, data);
    //
    dispatch({
      type: CREATE_USER_ACCOUNT,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {

    //
    dispatch({
      type: CREATE_USER_ACCOUNT_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getSubscriptionStatus = () => async (dispatch) => {
  // let axios = useAxios(true);

  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/subscription_status`);
    dispatch({
      type: SUBSCRIPTION_STATUS,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: SUBSCRIPTION_STATUS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const calculateFeeByPkg = (data) => async (dispatch) => {
  // let axios = useAxios(true);

  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/calculatePkgFee?pkg=${data.pkg}`, data);
    //
    dispatch({
      type: CALCULATE_FEE_BY_PACKAGE,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: CALCULATE_FEE_BY_PACKAGE_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const calculateFeeBySubscriptionFee = (data) => async (dispatch) => {
  // let axios = useAxios(true);

  //
  try {
    let fee = Number(data);
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/calculatePkgFee?fee=${fee}`);
    //
    dispatch({
      type: CALCULATE_FEE_BY_SUBSCIPTION_FEE,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: CALCULATE_FEE_BY_SUBSCIPTION_FEE_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const agencyUsage = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/agencyUsage`, data);

    //;

    dispatch({
      type: GET_AGENCY_USAGE,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {

    dispatch({
      type: GET_AGENCY_USAGE_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}

const getUsageDetailByOrderId = (orderId) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/orderDetail/${orderId}`);


    dispatch({
      type: USAGE_DETAIL_BY_ORDER_ID,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: USAGE_DETAIL_BY_ORDER_ID_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const updateSubscription = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/userSubscription`, data);


    dispatch({
      type: UPDATE_SUBSCIPTION,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_SUBSCIPTION_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getExternalPackageUpsellConfiguratoin = (agencyCode) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/getAgencyPackage/${agencyCode}`);

    dispatch({
      type: GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: GET_EXTERNAL_PACKAGE_UPSELL_CONFIGURATION_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const updateAgencyPackage = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/updateAgencyPackage`, data);


    dispatch({
      type: UPDATE_AGENCY_PACKAGE,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {

    dispatch({
      type: UPDATE_AGENCY_PACKAGE_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const updateExternalSubscription = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/externalSubscription`, data);


    dispatch({
      type: UPDATE_EXTERNAL_SUBSCRIPTION,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_EXTERNAL_SUBSCRIPTION_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const createExternalSubscription = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/externalSubscription`, data);


    dispatch({
      type: CREATE_EXTERNAL_SUBSCRIPTION,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: CREATE_EXTERNAL_SUBSCRIPTION_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const getPackageConfiguration = (agencyCode) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/user_packages,sysConfig`);

    dispatch({
      type: GET_PACKAGE_CONFIGURATION,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: GET_PACKAGE_CONFIGURATION_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const updatePackagesConfiguration = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/package`, data);


    dispatch({
      type: UPDATE_PACKAGES,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_PACKAGES_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const getAllAdminUser = () => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/getAllAdminUsers`);

    dispatch({
      type: GET_ALL_ADMIN_USERS,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: GET_ALL_ADMIN_USERS_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const updateAdminUser = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    //;
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/updateAdminUser`, data);

    dispatch({
      type: UPDATE_ADMIN_USER,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_ADMIN_USER_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const createAdminUser = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    // ;
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/createAdminUser`, data);

    dispatch({
      type: CREATE_ADMIN_USER,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: CREATE_ADMIN_USER_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const deleteAdminUser = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    // ;
    const res = await axiosInstance.delete(`${process.env.REACT_APP_ADMIN_BASE_URL}/deleteAdminUser/${data}`);

    dispatch({
      type: DELETE_ADMIN_USER,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: DELETE_ADMIN_USER_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}

const deleteContent = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    // ;
    const res = await axiosInstance.delete(`${process.env.REACT_APP_SEARCH_ADMIN_BASE_URL}/document/${data}`);

    dispatch({
      type: DELETE_CONTENT,
      payload: { data: res.data, isLoading: false }
    })
    dispatch({
      type: DELETE_CONTENT_ERROR,
      payload: null
    });
  } catch (err) {
    dispatch({
      type: DELETE_CONTENT_ERROR,
      payload: err.response ? err.response.errors? err.response.errors[0]: "Error in deleting the document" : "API Failed"
    });
  }
}
const getMailingList = (data) => async (dispatch) => {
  try {
    //
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/mailingList`);

    dispatch({
      type: GET_MAILINGLIST_RESPONSE,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_MAILINGLIST_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};
const updateMailingList = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/updateMailingList`, data);

    dispatch({
      type: UPDATE_MAILINGLIST_RESPONSE,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: UPDATE_MAILINGLIST_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const createCategory = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/packageUserType`, data)

    dispatch({
      type: ADD_CATEGORY_RESPONSE,
      payload: { data: res.data, isLoading: false }
    });
  } catch (err) {
    dispatch({
      type: ADD_CATEGORY_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const createPackageUserType = (data) => async (dispatch) => {
  // let axios = useAxios(true);
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/addPkgUserType`, data)

    dispatch({
      type: ADD_PACKAGE_USER_TYPE_RESPONSE,
      payload: { data: res.data, isLoading: false }
    });
  } catch (err) {
    dispatch({
      type: ADD_PACKAGE_USER_TYPE_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const getPacakgeByCode = (data) => async (dispatch) => {
  let axios = useAxios(true);

  try {

    const res = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/package/${data}`);

    dispatch({
      type: GET_PACKAGE_BY_CODE_RESPONSE,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_PACKAGE_BY_CODE_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
};

const updateConfigPackage = (data) => async (dispatch) => {

  try {
    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/package`, data)

    dispatch({
      type: UPDATE_CONFIG_PACKAGE_RESPONSE,
      payload: { data: res.data, isLoading: false }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_CONFIG_PACKAGE_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const createPackage = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/package`, data)

    dispatch({
      type: CREATE_PACKAGE_RESPONSE,
      payload: { data: res.data, isLoading: false }
    });
  } catch (err) {
    dispatch({
      type: CREATE_PACKAGE_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}

const seamlessLoginUrl = (data) => async (dispatch) => {
  try {
    //
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/seamlessLoginEmail/${data}`)

    dispatch({
      type: SEAMLESS_LOGIN_EMAIL,
      payload: { data: res.data, isLoading: false }
    });

  } catch (err) {
    dispatch({
      type: SEAMLESS_LOGIN_EMAIL_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const fetchActiveUser = (data) => async (dispatch) => {
  try {
    //
    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/getEmailDetails`, data)

    dispatch({
      type: FETCH_ACTIVE_USER,
      payload: { data: res.data, isLoading: false }
    });

  } catch (err) {
    dispatch({
      type: FETCH_ACTIVE_USER_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const sendBlastEmail = (data) => async (dispatch) => {
  try {

    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/sendBulkEmail`, data)

    dispatch({
      type: SEND_BLAST_EMAIL,
      payload: { data: res, isLoading: false }
    });

  } catch (err) {
    dispatch({
      type: SEND_BLAST_EMAIL_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const removeTokens = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_ADMIN_BASE_URL}/deleteTokens`);
    dispatch({
      type: REMOVE_TOKEN,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: REMOVE_TOKEN_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const removeTokenByUserName = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_ADMIN_BASE_URL}/deleteUserToken/${data}`);
    dispatch({
      type: REMOVE_TOKEN_BY_USERNAME,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: REMOVE_TOKEN_BY_USERNAME_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}


const updateSysConfig = (data) => async (dispatch) => {
  try {

    const res = await axiosInstance.put(`${process.env.REACT_APP_ADMIN_BASE_URL}/sysConfig`, data)

    dispatch({
      type: UPDATE_SYS_CONFIG,
      payload: { data: res.data, isLoading: false }
    });

  } catch (err) {
    dispatch({
      type: UPDATE_SYS_CONFIG_ERROR,
      payload: err.response ? err.response.data : "API Failed"

    });
  }
}
const deleteKeyCache = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_ADMIN_BASE_URL}/deleteKeyCache/${data}`);
    dispatch({
      type: DELETE_KEY,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: DELETE_KEY_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const deleteHashKeyCache = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_ADMIN_BASE_URL}/deleteHashKeyCache/${data.key}/${data.hashKey}`);
    dispatch({
      type: DELETE_KEY,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: DELETE_KEY_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const createUserSubscription = (data) => async (dispatch) => {
  try {

    const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_BASE_URL}/createNewSubscription`, data)

    dispatch({
      type: CREATE_NEW_SUBSCRIPTION,
      payload: { data: res.data, isLoading: false }
    });

  } catch (err) {
    dispatch({
      type: CREATE_NEW_SUBSCRIPTION_ERROR,
      payload: err.response ? err.response.data : "API Failed"
    });
  }
}
const updateAuthTable = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_ADMIN_BASE_URL}/updateAuthTable/${data}`);
    dispatch({
      type: SYNC_AUTH_SUCCESS,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: SYNC_AUTH_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}
const deleteWildCardKeyCache = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_ADMIN_BASE_URL}/deleteWildcardCache/${data}`);
    dispatch({
      type: DELETE_WILD_CARD_KEY,
      payload: { data: res.data, isLoading: false }
    })
  } catch (err) {
    dispatch({
      type: DELETE_WILD_CARD_KEY_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }

}
export {
  startLoader,
  getUserList,
  getPreloads,
  updateAnnouncement,
  getUserType,
  getAccountStatus,
  getSubscriptions,
  findUser,
  adminLogin,
  clearAdminError,
  getSortBy,
  getInfoData,
  findUsageDetails,
  getInfoForAnnouncement,
  updateStatus,
  clearAdminLoginError,
  createAgency,
  getAgency,
  getAgencyPublisher,
  getAgencyPublisherById,
  createAgencyPublisher,
  updateAgencyPublisher,
  updateAgencyPublisherStatus,
  updateAgency,
  getUserDetailDataByuserId,
  updateAccountInfo,
  getAgenciesDdl,
  getAgencyRoyaltyReport,
  createUserAccount,
  getSubscriptionStatus,
  calculateFeeByPkg,
  calculateFeeBySubscriptionFee,
  agencyUsage,
  getUsageDetailByOrderId,
  updateSubscription,
  stopLoader,
  getExternalPackageUpsellConfiguratoin,
  updateAgencyPackage,
  updateExternalSubscription,
  createExternalSubscription,
  getPackageConfiguration,
  updatePackagesConfiguration,
  getAllAdminUser,
  updateAdminUser,
  getMailingList,
  updateMailingList,
  createAdminUser,
  deleteAdminUser,
  clearAdminUserErr,
  clearAdminAccountInfo,
  createCategory,
  createPackageUserType,
  getPacakgeByCode,
  updateConfigPackage,
  createPackage,
  seamlessLoginUrl,
  fetchActiveUser,
  sendBlastEmail,
  removeTokens,
  removeTokenByUserName,
  updateSysConfig,
  deleteKeyCache,
  deleteHashKeyCache,
  changeSelectedSub,
  createUserSubscription,
  updateAuthTable,
  clearDevSettings,
  deleteWildCardKeyCache,
  getHeritagePhoto,
  resetHeritagePhoto,
  deleteContent,
  adminSearchContent
};
