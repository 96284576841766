import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonElement, SelectElement, Modal, Spinner } from '../../UI/index';
import { getUserType, startLoader, clearAdminError, createPackageUserType } from '../../../redux/actions/adminAction';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import "./ConfigPackages.scss";

const initialState = {
    submit: false,
    category: {
        pkg: '',
        userType: ''
    },
    userTypeList: [],
    userPackageList: [],
    isEditAgency: false,
    isVisible: false,
    message: '',
    errorType: '',
    btnDisable: false
}

class SubscriptionCategory extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
        this.recaptcha2 = React.createRef(null);
    }
    componentDidMount = async () => {
        this.props.startLoader();
        await this.props.getUserType();
        if (this.props?.userTypes?.userTypes) {
            let userTypeKeys = Object.keys(this.props.userTypes.userTypes);
            let newUserTypesArr = userTypeKeys.map((userTypeName) => {
                return ({ value: userTypeName, key: this.props.userTypes.userTypes[userTypeName].userTypeName })
            })
            let temp = this.props?.userTypes?.packages;
            let packagesValues = Object.values(temp.basePackageVO);
            let packagesKeys = Object.keys(temp.basePackageVO);

            let newPackagesArr = packagesValues.map((p, i) => {
                return ({ key: p.pkgName, value: packagesKeys[i] })
            });

            this.setState({
                userTypeList: newUserTypesArr,
                userPackageList: newPackagesArr,
                category: {
                    pkg: newPackagesArr[0].value,
                    userType: newUserTypesArr[0].value
                }
            })
        }
    }
    onReset = (e) => {
        this.setState(initialState);
    }

    onSave = async (e) => {
        this.setState({
            btnDisable: true
        })
        e.preventDefault()
        this.recaptcha.execute();
    }

    onResolved = async () => {
        this.props.startLoader();
        const response = this.recaptcha.getResponse();
        const { category } = this.state
        category.captchaToken = response;
        await this.props.createPackageUserType(category);
        let responseMessage = this.props?.addPackageUserTypeResponse?.message || this.props?.addPackageUserTypeError;
        let errorType = '';
        if (this.props?.addPackageUserTypeError)
            errorType = "Error";
        else
            errorType = "Success";
        if (responseMessage)
            this.setState({
                isVisible: true,
                message: responseMessage,
                errorType: errorType
            })
        await this.props.clearAdminError();
    }

    setFormValue = async (value, field, number = false) => {
        const { category } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            category[field] = regex.test(value) || value === "" ? value : category[field];
        } else {
            category[field] = value;
        }

        this.setState({
            category
        });
    }
    onBack = async (e) => {
        this.props.history.push({
            pathname: '/admin/config-packages'
        });
    }
    closeModal = async () => {
        this.setState({
            isVisible: false,
            btnDisable: false
        });
    }
    render() {
        const { loading } = this.props;
        const { userPackageList, userTypeList, category, errorType, isVisible, message, btnDisable } = this.state;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {isVisible && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType === "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                <div className="container-fluid card margin-left-10">
                    <form className=''>
                        <div className='row top10'>
                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <label>Category : </label>
                                {
                                    userTypeList && userTypeList.length > 0 && <SelectElement
                                        className='col-lg-6'
                                        options={userTypeList}
                                        defaultValue={category.userType}
                                        onChange={(e) => this.setFormValue(e.target.value, "userType")} />
                                }
                            </div>

                            <div className="col-lg-4 col-6 col-md-4 pb-2">
                                <label>Package : </label>
                                {
                                    userPackageList && userPackageList.length > 0 && <SelectElement
                                        className='col-lg-6'
                                        options={userPackageList}
                                        defaultValue={category.pkg}
                                        onChange={(e) => this.setFormValue(e.target.value, "pkg")} />
                                }

                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 "
                                    onClick={(e) => this.onSave(e)}
                                    disabled={btnDisable}
                                >
                                    <span className="button-label">Save</span>
                                </ButtonElement>
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 "
                                    onClick={(e) => this.onBack(e)}
                                >
                                    <span className="button-label">Back</span>
                                </ButtonElement>
                            </div>
                        </div>
                    </form>
                </div>
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha2 = ref)}
                    onResolved={this.onResolved2}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    userTypes: state.adminReducer.userTypes,
    userTypesError: state.adminReducer.userTypesError,
    addPackageUserTypeResponse: state.adminReducer.addPackageUserTypeResponse,
    addPackageUserTypeError: state.adminReducer.addPackageUserTypeError
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getUserType,
    createPackageUserType,
    clearAdminError
})(SubscriptionCategory);
