import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { adminLogin } from '../../../redux/actions/adminAction';
import { decodeToken } from 'react-jwt';
import { Modal, Spinner } from '../../UI';
import Popup from '../Popup/Popup';

class Home extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }
componentDidMount=async()=>{
  const oktaflag =JSON.parse(localStorage.getItem("okta-token-storage"));
  const decodeOkta=decodeToken(oktaflag?.idToken?.idToken);
  if(this.props.authState?.isAuthenticated && oktaflag?.idToken){
    let obj={
      "sub": decodeOkta.sub,
      "name": decodeOkta.name,
      "email": decodeOkta.email,
      "preferred_username": decodeOkta.preferred_username,
      "aud": decodeOkta.aud,
      "iat": decodeOkta.iat,
      "exp": decodeOkta.exp,
      "auth_time": decodeOkta.auth_time
    }
   await this.props.adminLogin(obj);
  //  debugger
    if(this.props.admin_login_error){
      // await this.props.oktaAuth.signOut();
    }else{
      this.props.history.push("/admin/home");
      window.location.reload();
    }
  }
}
  async login() {
    await this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {
    await this.props.oktaAuth.signOut();
  }
  openAdminpannel  =()=>{
    this.props.history.push("/admin/announcement");
  }


  render() {
    let body = null;
    if (this.props.authState?.isAuthenticated) {
      body = (
        <div className="" style={{height:"65vh"}}>
          <div className='pt-3'>
         {!this.props.admin_login_error &&<Spinner primary={true}></Spinner>}
         {this.props.admin_login_error  && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.logout()}>
          <Popup titles={this.props.admin_login_error} onClose={this.logout} {...this.props} />
        </Modal>}
          </div>

        </div>
      );
    } else {
      body = (
        <div className="Buttons">
          <button onClick={this.login}>Login</button>
        </div>
      );
    }

    return (
      <div className="App">
        <header className="App-header">
          {body}
        </header>
      </div>
    );
  }
};

const mapDispatchStatetoProps = (state) => ({
  ...state,
  admin_login_error: state.adminReducer.admin_login_error,
});
export default connect(mapDispatchStatetoProps, {
  adminLogin,
})(withOktaAuth(Home));
