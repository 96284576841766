import React, { Component } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
// import 'react-bootstrap-tagsinput/dist/index.css'
import '../../../../node_modules/react-bootstrap-tagsinput/dist/index.css'
// import Box from '@material-ui/core/Box';
// import TextField from "@material-ui/core/TextField";
import "./MailBlast.scss";
import { startLoader, getInfoData, fetchActiveUser, sendBlastEmail, clearAdminError, changeSelectedSub } from '../../../redux/actions/adminAction';
import { ButtonElement, Modal, Selectlist, Spinner } from '../../UI/index';
import { connect } from 'react-redux';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import ErrorPopup from '../Popup/ErrorPopup';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
    EditorState,
    convertToRaw,
} from "draft-js";

export class MailBlast extends Component {
    constructor(props) {
        super(props)

        this.state = {
            arr: [],
            obj: {
                // title: "",
                // emailList: [],
                // emailContent: "",
                bcc: [],
                emailSubject: "",
                emailBody: ""
            },
            emailList: [],
            userTypes: [],
            userType: [],
            tags: [],
            message: "",
            errData: [],
            success: false,
            defaultEditor: EditorState.createEmpty(),
            aKey: 'aKey',
            disabled: false,
            submitFlagofmultipleclick: false

        }
    }

    setValue = (value) => {
        const { obj } = this.state;
        let uniqueEmailList = [...new Set(value.values)]

        this.setState({
            emailList: uniqueEmailList,

        })
        if (uniqueEmailList && uniqueEmailList.length > 0) {
            let emailsList = window.btoa(JSON.stringify(uniqueEmailList))
            obj.bcc = emailsList;
        } else {
            obj.bcc = ""
        }

        this.setState({
            obj
        })
    }


    handleAddition = (tag) => {

        this.setState({
            tags: tag
        })
    };
    setFormValue = (value, field, number = false) => {
        const { obj } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            obj[field] = regex.test(value) || value === "" ? value : obj[field];
        } else {
            obj[field] = value;
        }
        this.setState({
            obj,
        });
    }
    componentDidMount = async () => {
        await this.props.getInfoData();

        let usertypeKeys = Object.keys(this.props.infoData.userTypes);
        let allUserType = this.props.infoData.userTypes;


        let newUserTypesArr = usertypeKeys.map((usertype) => {
            return ({ key: allUserType[usertype].userTypeName, value: usertype, ischecked: false })
        })
        this.setState({
            userType: newUserTypesArr,
        })
    }
    setFormValue1 = (value, field) => {

        let userTypeArr = [];

        for (let v in this.props.selectedSub) {
            if (this.props.selectedSub[v]) {
                userTypeArr.push(v)
            }
        }

        this.setState({
            userTypes: userTypeArr
        })
        userTypeArr = []

    }
    fetchUser = async (e) => {
        e.preventDefault()

        const { obj, userTypes } = this.state;


        await this.props.fetchActiveUser({ types: userTypes });

        let users;
        if (this.props?.activeUser) {
            users = this.props?.activeUser.map(user => (
                user.user_email
            ))

        } else if (this.props?.activeUserError) {
            this.setState({
                success: true,
                errdata: this.props.activeUserError,
                message: ""
            })
        }

        let uniqueUserEmail = [...new Set(users)]
        if (uniqueUserEmail && uniqueUserEmail.length > 0) {
            try {
                let emailsList = window.btoa(JSON.stringify(uniqueUserEmail))
                obj.bcc = emailsList;
            } catch (err) {
                console.log("error while encrypting ")
            }
        } else {
            obj.bcc = ""
        }


        this.setState({
            emailList: uniqueUserEmail,
            obj,
        })


    }
    onEditorStateChange = (content) => {
        const { obj } = this.state
        obj.emailBody = draftToHtml(
            convertToRaw(content.getCurrentContent()))
        this.setState({
            obj,
        });
    };
    onReset = async (e) => {
        e.preventDefault();
        const { obj, userType } = this.state;
        obj.emailSubject = "";
        obj.bcc = "";
        obj.emailBody = "";
        await this.props.changeSelectedSub({})
        let userType1 = userType.map(type => {
            if (type.ischecked === true) {
                type.ischecked = false
            }
            return type
        })

        this.setState({
            obj,
            aKey: Math.random(),
            userTypes: [],
            emailList: [],
            userType: userType1,
            defaultEditor: EditorState.createEmpty()

        })
    }

    submit = (e) => {
        if (!this.state.submitFlagofmultipleclick) {
            this.setState({
                disabled: true,
                submitFlagofmultipleclick: true
            })
            e.preventDefault();
            this.recaptcha.execute();
        }
    }
    onResolved = async () => {
        const { obj } = this.state;
        const captchaToken = this.recaptcha.getResponse();
        obj.captchaToken = captchaToken
        await this.props.startLoader();
        obj.emailBody = obj.emailBody.replaceAll("&nbsp;","")
        if ((obj.emailBody) === "<p></p>\n") {
            obj.emailBody = ""
        }
        await this.props.sendBlastEmail(obj);
        if (this.props?.sendEmailError) {
            this.setState({
                success: true,
                errdata: this.props?.sendEmailError,
                message: ""
            })
        } else if (this.props?.sendEmail && this.props?.sendEmail?.status === 200) {
            this.setState({
                success: true,
                errdata: [],
                message: "Email Send Successfully!!"
            })

        }

        await this.props.clearAdminError();
        this.setState({ submitFlagofmultipleclick: false });
        window.scrollTo(0, 0);
    }
    onClose = async () => {
        this.setState({
            success: false,
            disabled: false
        })
    }
    render() {

        const { obj, userType, success, errdata, message, userTypes, emailList, defaultEditor, aKey, disabled } = this.state;
        const { loading } = this.props;

        return (
            <>
                {loading &&
                    <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {
                    success &&
                    <Modal
                        customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
                        onClick={this.onClose}
                        // loading={showinfoScreen}
                        errorFlag={success}
                    >
                        <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
                    </Modal>
                }

                <div className='container-fluid mt-10'>
                    <h3 className='heading'>Mail Blast</h3>

                    <div>
                        {
                            userTypes && userTypes.length > 0 &&
                            <div className='text-right'>
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 "
                                    onClick={(e) => this.fetchUser(e)}
                                // onClick={(e) => this.onSave(e)}
                                >
                                    <span className="button-label" >FETCH</span>
                                </ButtonElement>
                            </div>
                        }
                    </div>
                    <div className='row card p-1'>
                        <div className='col-lg-12 ' >
                            {userType && userType.length > 0 && <Selectlist list={userType} resetFlag={true} label='User Type'
                                onChange={(e) => this.setFormValue1(e.selectedSub, "userTypes")}
                                reset={(e) => this.reset(e)}
                            ></Selectlist>}
                        </div>
                    </div>
                    <label className='mt-2'>Email Subject</label>

                    <input
                        className="search-input p-1  w-100 "
                        name="Email Title"
                        autoComplete="false"
                        value={obj.emailSubject}
                        placeholder="Email Subject"
                        onChange={(e) => this.setFormValue(e.target.value, "emailSubject")}
                    />
                    <div className='mt-2'>
                        <label>Email Address</label><br />
                        <span className='mailInfo'>Note: *Please press enter after each email address</span>
                        <div className='group'>
                            <InputTags id="emails" className='pb-2' values={emailList} onTags={(value) => this.setValue(value)} />
                        </div>

                    </div>

                    <div >
                        <label className='mt-2'>Email Body</label>

                    </div>

                    <div className='editor' key={aKey}>
                        <Editor
                            defaultEditorState={defaultEditor}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'list','link'],
                                inline: { inDropdown: false, options: ["bold", "italic","underline"] },
                                list: { inDropdown: true },
                                blockType: { inDropdown: true, options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"] },
                                // textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                // history: { inDropdown: true },
                            }}
                        />
                    </div>
                    <div className='mb-2 mt-2 text-right'>
                        <ButtonElement
                            type="submit"
                            variant="contained"
                            btnType="background-orange button-label"
                            className="d-inline-block mr-2"
                            onClick={(e) => this.submit(e)}
                            disabled={disabled}
                        >
                            <span className="button-label">SUBMIT</span>
                        </ButtonElement>
                        <ButtonElement
                            variant="contained"
                            btnType="background-orange button-label"
                            className="d-inline-block mr-2 "
                            onClick={(e) => this.onReset(e)}
                        >
                            <span className="button-label">Reset</span>
                        </ButtonElement>


                    </div>
                    <Recaptcha
                        size="invisible"
                        ref={(ref) => (this.recaptcha = ref)}
                        onResolved={this.onResolved}
                        sitekey={RECAPTCHA_SITE_KEY}
                    />
                </div>

            </>


        )
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    infoData: state.adminReducer.infoData,
    activeUser: state.adminReducer.activeUser,
    activeUserError: state.adminReducer.activeUserError,
    sendEmail: state.adminReducer.sendEmail,
    sendEmailError: state.adminReducer.sendEmailError,
    selectedSub: state.adminReducer.selectedSub

});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getInfoData,
    fetchActiveUser,
    sendBlastEmail,
    clearAdminError,
    changeSelectedSub
})(MailBlast);
