import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonElement, Checkbox, Modal, Spinner } from '../../../UI/index';
import { startLoader, createAgency, getAgency, updateAgency, clearAdminError } from '../../../../redux/actions/adminAction';
import { status } from '../../../../helpers/common';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../config/constants/index";

var initialState = {
    submit: false,
    agency: {
        agency: '',
        agencyCode: '',
        description: '',
        contactPerson: '',
        contactEmail: '',
        embargoPeriod: '',
        id: '',
        captchaToken: ''
    },
    agencyKey: '',
    agencySearchResult: [],
    addAgencyTitle: 'Add External News Agency',
    editAgencyTitle: 'Edit External News Agency',
    isEditAgency: false,
    visibleAlertBox: false,
    message: '',
    errorType: '',
    agencyResetFlag: '',
    isDisable: false,
    disabled: false
}

class CreateExternalAgency extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
        this.recaptcha2 = React.createRef(null);
    }
    componentDidMount = async () => {
        let resetObj = {
            agency: '',
            agencyCode: '',
            description: '',
            contactPerson: '',
            contactEmail: '',
            embargoPeriod: '',
        }
        this.setState({
            agency: resetObj,
            agencyKey: Math.random()
        })

        this.setState({
            visibleCreateAgencyForm: false
        })
        // For Edit Agency
        if (this.props?.location?.state?.agencyData) {
            this.setState({
                agency: {
                    agency: '',
                    agencyCode: '',
                    description: '',
                    contactPerson: '',
                    contactEmail: '',
                    embargoPeriod: ''
                },
                isEditAgency: true
            })
            let agencyObj = {
                agency: this.props.location.state.agencyData.agency,
                agencyCode: this.props.location.state.agencyData.agencyCode,
                description: this.props.location.state.agencyData.description,
                contactPerson: this.props.location.state.agencyData.contactPerson,
                contactEmail: this.props.location.state.agencyData.contactEmail,
                embargoPeriod: this.props.location.state.agencyData.embargoPeriod,
                id: this.props.location?.state?.agencyData.agencyId,
                status: this.props.location.state.agencyData.status === "ACTIVE" ? 1 : 2,
            }
            this.setState({
                agency: agencyObj
            })
        }
    }
    onReset = (e) => {
        let resetObj = {
            agency: '',
            agencyCode: '',
            description: '',
            contactPerson: '',
            contactEmail: '',
            embargoPeriod: '',
        }
        this.setState({
            agency: resetObj,
            agencyKey: Math.random()
        })
    }

    onResolved2 = async () => {
        this.setState({
            submit: true,
            visibleAlertBox: false,
            errorType: '',
        });
        this.props.startLoader();
        const { agency } = this.state;
        const response = this.recaptcha2.getResponse();
        let requestObj = {
            agency: agency.agency.trim(),
            agencyCode: agency.agencyCode.trim(),
            description: agency.description.trim(),
            contactPerson: agency.contactPerson.trim(),
            contactEmail: agency.contactEmail.trim(),
            embargoPeriod: agency.embargoPeriod,
            captchaToken: response,
            id: agency.id,
            status: agency.status
        }
        await this.props.updateAgency(requestObj)
        let responseMessage = this.props?.updatedAgencySuccessResponse?.data?.message || this.props?.updatedAgencyErrorResponse;
        let errorType = '';
        if (this.props?.updatedAgencyErrorResponse) {
            errorType = "Error";
            this.setState({
                visibleAlertBox: true,
                message: responseMessage,
                errorType: errorType
            })
        }

        if (this.props?.updatedAgencySuccessResponse?.data?.message) {
            errorType = "Success";
            this.setState({
                visibleAlertBox: true,
                message: responseMessage,
                errorType: errorType
            })
        }

        await this.props.clearAdminError();
    }
    onResolved = async () => {
        const response = this.recaptcha.getResponse();
        this.props.startLoader();
        this.setState({
            submit: true,
            visibleAlertBox: false,
            errorType: '',
        });
        const { agency } = this.state;
        let requestObj = {
            agency: agency.agency.trim(),
            description: agency.description.trim(),
            contactPerson: agency.contactPerson.trim(),
            contactEmail: agency.contactEmail.trim(),
            embargoPeriod: agency.embargoPeriod,
            captchaToken: response,
            status: 1
        }

        await this.props.createAgency(requestObj)
        let responseMessage = this.props?.agencyResponseData?.message || this.props?.agencyErrorResponseData;
        let errorType = '';
        if (this.props?.agencyErrorResponseData) {
            errorType = "Error";
            this.setState({
                visibleAlertBox: true,
                message: responseMessage,
                errorType: errorType
            })
            errorType = "";
            await this.props.clearAdminError();
        }
        if (this.props?.agencyResponseData) {
            errorType = "Success";
            let resetObj = {
                agency: '',
                agencyCode: '',
                description: '',
                contactPerson: '',
                contactEmail: '',
                embargoPeriod: '',
            }
            this.setState({
                agency: resetObj,
                agencyKey: Math.random()
            })

            this.setState({
                visibleAlertBox: true,
                message: responseMessage,
                errorType: errorType
            })
            errorType = "";
            await this.props.clearAdminError();
        }
    }

    onSave = async (e) => {
        this.setState({
            isDisable: true
        })
        e.preventDefault()
        this.recaptcha.execute();
    }

    onUpdate = async (e) => {
        this.setState({
            disabled: true
        })
        e.preventDefault()
        this.recaptcha2.execute();
    }

    onSearch = async (e) => {
        e.preventDefault()
        const searchData = {
            agency: this.state.agency.agency,
            agencyCode: this.state.agency.agencyCode,
            createDate: '',
            pageNo: 0,
            status: 1 //this.state.agency.status
        }

        await this.props.getAgency(searchData);
        this.setState({
            agencySearchResult: this.props.getAgencyResult.data.externalAgencies
        })
    }
    setFormValue = async (value, field, number = false) => {
        const { agency } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            agency[field] = regex.test(value) || value === "" ? value : agency[field];
        } else {
            if (field === "embargoPeriod") {
                if (isNaN(value) == false && !value.toString().includes('.') && value > 0) {
                    agency[field] = value;
                }
                if (value.toString() == '')
                    agency[field] = value;
            }
            else
                agency[field] = value;
        }

        this.setState({
            agency
        });

    }
    onBack = async (e) => {
        this.setState({
            isEditAgency: false
        })
        this.props.history.push({
            pathname: "/admin/find-news",
            state: {
                pageName: "createAgency"
            }
        })
    }
    closeModal = async (e) => {
        let { isEditAgency, errorType } = this.state;
        this.setState({
            disabled: false
        })
        if (errorType === "Success") {
            this.setState(initialState);
            if (isEditAgency) {
                this.props.history.push('/admin/find-news');
            }
        }
        this.setState({
            visibleAlertBox: false,
            isDisable: false
        })
    }
    render() {
        const { loading } = this.props;
        const { agency, isDisable, agencyKey, errorType, addAgencyTitle, editAgencyTitle, isEditAgency, visibleAlertBox, message, agencyResetFlag, disabled } = this.state;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType === "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : message
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                {!isEditAgency ?

                    <div id="createExternalAgencyForm" className="card padding10" key={agencyKey}>
                        <form className=''>
                            <h3 className="heading">
                                {
                                    addAgencyTitle
                                }
                            </h3>
                            <div className='row'>
                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Agency Name:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Agency name"
                                        value={agency.agency}
                                        onChange={(e) => this.setFormValue(e.target.value, "agency")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Description:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Description"
                                        value={agency.description}
                                        onChange={(e) => this.setFormValue(e.target.value, "description")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Contact Person:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Contact Person"
                                        value={agency.contactPerson}
                                        onChange={(e) => this.setFormValue(e.target.value, "contactPerson")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Contact Email:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Contact Email"
                                        value={agency.contactEmail}
                                        onChange={(e) => this.setFormValue(e.target.value, "contactEmail")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Embargo Period:</label>
                                    <input
                                        placeholder="Months"
                                        value={agency.embargoPeriod}
                                        onChange={(e) => this.setFormValue(e.target.value, "embargoPeriod")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                    <div className='float-right'>
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 mt-10"
                                            onClick={(e) => this.onSave(e)}
                                            disabled={isDisable}
                                        >
                                            <span className="button-label">Submit</span>
                                        </ButtonElement>

                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 mt-10"
                                            onClick={(e) => this.onBack(e)}
                                        >
                                            <span className="button-label">Back</span>
                                        </ButtonElement>

                                        <ButtonElement
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 mt-10"
                                            onClick={() => this.onReset()}
                                        >
                                            <span className="button-label">Reset</span>
                                        </ButtonElement>

                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                    :
                    <div id="editExternalAgencyForm" className="card padding10">
                        <form className='' >
                            <h3 className="heading">
                                {
                                    editAgencyTitle
                                }
                            </h3>
                            <div className='row' >
                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Agency Name:<span className='err'>*</span></label>
                                    <input key={agencyResetFlag}
                                        placeholder="Agency name"
                                        value={agency.agency}
                                        readonly="readonly"
                                        onChange={(e) => this.setFormValue(e.target.value, "agency")}
                                        type="text"
                                        className="search-input p-1 w-100 disabledTextBox"
                                    />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Agency Code:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Agency code"
                                        value={agency.agencyCode}
                                        onChange={(e) => this.setFormValue(e.target.value, "agencyCode")}
                                        type="text"
                                        readonly="readonly"
                                        className="search-input p-1 w-100 disabledTextBox"
                                    />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Description:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Description"
                                        value={agency.description}
                                        onChange={(e) => this.setFormValue(e.target.value, "description")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Contact Person:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Contact Person"
                                        value={agency.contactPerson}
                                        onChange={(e) => this.setFormValue(e.target.value, "contactPerson")}
                                        type="text"
                                        className="search-input p-1  w-100"
                                    />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                    <label>Contact Email:<span className='err'>*</span></label>
                                    <input
                                        placeholder="Contact Email"
                                        value={agency.contactEmail}
                                        onChange={(e) => this.setFormValue(e.target.value, "contactEmail")}
                                        type="text"
                                        className="search-input p-1  w-100"
                                    />
                                </div>
                                <div className="col-lg-3 col-sm-12 col-md-3 pb-2">
                                    <label>Embargo Period:</label>
                                    <input
                                        placeholder="Months"
                                        value={agency.embargoPeriod}
                                        onChange={(e) => this.setFormValue(e.target.value, "embargoPeriod")}
                                        type="text"
                                        className="search-input p-1 w-100"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12">
                                    <div><label>Validation Status: <span className='err'>*</span></label></div>
                                    {status.map((p) => (
                                        <div className="col-md-6 d-inline-block p-0" key={p.key}>
                                            <Checkbox key={p.key} labelValue={p.value} checked={parseInt(agency.status) === parseInt(p.value)} onChange={(e) =>
                                                this.setFormValue(e.target.getAttribute("label"), "status")} />
                                            <span className="margin-left-35">{p.key} </span>

                                        </div>))}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 col-md-12 pt-3 pb-5">

                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2  float-right"
                                        onClick={(e) => this.onBack(e)}
                                    >
                                        <span className="button-label">Back</span>
                                    </ButtonElement>
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2  float-right"
                                        onClick={(e) => this.onUpdate(e)}
                                        disabled={disabled}
                                    >
                                        <span className="button-label">Update</span>
                                    </ButtonElement>
                                </div>

                            </div>
                        </form>
                    </div>
                }
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha2 = ref)}
                    onResolved={this.onResolved2}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>

        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    getAgencyResult: state.adminReducer.agencyResult,
    agencyResponseData: state.adminReducer.agencyResponseData,
    agencyErrorResponseData: state.adminReducer.agencyErrorResponseData,
    updatedAgencySuccessResponse: state.adminReducer.updatedAgencySuccessResponse,
    updatedAgencyErrorResponse: state.adminReducer.updatedAgencyErrorResponse
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    createAgency,
    updateAgency,
    getAgency,
    clearAdminError
})(CreateExternalAgency);
