import React, { Component, Fragment } from 'react'
import Backdrop from "../../../UI/Backdrop/backdrop";
import "./css/UsageDetailPopup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export class UsageDetailsPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            usageDetail: null,
            usageDetailByOrderId: null
        }

    }
    componentDidMount = async () => {
        if (this.props && this.props.rowData) {
            this.setState({
                usageDetail: this.props.rowData
            })
        }
        if (this.props && this.props.usageDetailByOrderId) {
            this.setState({
                usageDetailByOrderId: this.props.usageDetailByOrderId
            })
        }

    }
    render(closeIcon = true) {
        const { usageDetail, usageDetailByOrderId } = this.state;
        // let createOn = usageDetailByOrderId?.createOn

        const style = this.props.closeIcon ? {
            backgroundColor: "black"
        } : {
            backgroundColor: "black"
        }

        return (
            <Fragment>
                <Backdrop clicked={this.props.closeModal}></Backdrop>
                <div
                    className={`CustomModal ${this.props.customClass !== undefined ? this.props.customClass : ""
                        }`}
                    style={this.props.style}
                >
                    <div className="close-icon-container" style={style} >
                        <div className="close-icon">
                            {/* <img
                onClick={() => this.props.loading ? () => { } : this.props.closeModal()}
                src={crossIcon}
                className="icon-img"
                alt="crossIcon"
              /> */}
                            <FontAwesomeIcon icon={faTimes} className="icon-img" size="lg"
                                onClick={() => this.props.loading ? () => { } : this.props.closeModal()}
                            />
                        </div>
                    </div>
                    <div className="modal-content">
                        <h3 className='pb-2'>User Subscription</h3>
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-3 col-2">
                                <p className='dheader'>ORDER ID:</p>
                            </div>
                            <div className=' col-lg-4 col-md-4 col-sm-3 col-4'>
                                <p>{usageDetailByOrderId?.orderId} </p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>CREATED ON:</p>
                            </div>
                            <div className='col-3'>
                                <p>{usageDetailByOrderId?.createOn ? (new Date(usageDetailByOrderId?.createOn).toLocaleString("it-IT")).replaceAll(",", " at:") : ""} </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 ">
                                <p className='dheader'>USER:</p>
                            </div>
                            <div className='col-4'>
                                <p className='demail'>{usageDetailByOrderId?.user} </p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>EMAIL:</p>
                            </div>
                            <div className='col-3'>
                                <p className='demail'>{usageDetail?.email} </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2 ">
                                <p className='dheader'>COMPANY:</p>
                            </div>
                            <div className='col-4'>
                                <p className='demail'>{usageDetail?.organization} </p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>ADDRESS:</p>
                            </div>
                            <div className='col-3'>
                                <p>{usageDetail?.address}  </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 ">
                                <p className='dheader'>PUBLICATION INFO:</p>
                            </div>
                            <div className='col-4'>
                                <p className='demail'>{usageDetailByOrderId?.publicationInfo}</p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>PUBLICATION DATE:</p>
                            </div>
                            <div className='col-3'>
                                <p>{(usageDetailByOrderId?.publicationDate ?  (new Date(usageDetailByOrderId?.createOn).toLocaleString("it-IT")).replaceAll(",", " at:")  : "")} </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 ">
                                <p className='dheader'>SECTION:</p>
                            </div>
                            <div className='col-4'>
                                <p>{usageDetailByOrderId?.section} </p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>HEADLINE:</p>
                            </div>
                            <div className='col-3'>
                                <p>{usageDetailByOrderId?.headline} </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2 ">
                                <p className='dheader'>UNIT PRICE:</p>
                            </div>
                            <div className='col-4'>
                                <p>${usageDetailByOrderId?.unitPrice} </p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>TEL:</p>
                            </div>
                            <div className='col-3'>
                                <p>{usageDetail?.telNo} </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2 ">
                                <p className='dheader'>  GST(7%)</p>
                            </div>
                            <div className='col-4'>
                                <p>${usageDetailByOrderId?.gst} </p>
                            </div>
                            <div className="col-3 ">
                                <p className='dheader'>  TOTAL</p>
                            </div>
                            <div className='col-3'>
                                <p>${usageDetailByOrderId?.total} </p>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

UsageDetailsPopup.defaultProps = {
    closeIcon: true
}
export default UsageDetailsPopup
