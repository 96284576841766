import React, { Component } from 'react';
import { ButtonElement, Spinner, Modal } from '../../UI//index';
import { connect } from 'react-redux';
import {
    clear_Error,getdownloadBarInfo
} from "../../../redux/actions/applicationsAction";
import { startLoader } from "../../../redux/actions/adminAction"
import {
    clearAdminError,
    getHeritagePhoto,
    resetHeritagePhoto
} from "../../../redux/actions/adminAction"
import { getUserDetails } from "../../../redux/actions/loginAction"
import ErrorPopup from '../Popup/ErrorPopup';
import { DOCUMENT_ID_ERROR, DOCUMENT_ID_LABEL, DOCUMENT_ID_PLACEHOLDER, DOWNLOAD_BUTTON_VALUE, DOWNLOAD_HERITAGE_TITLE, RESET_BUTTON_VALUE, DOCUMENT_ID_CHAR_ERROR } from '../../../config/constants/generalConstants';

export class DownloadHeritage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            documentId: "",
            reset: false,
            errData: [],
            success: false,
            progressbar:false,
            disabled: false,
        }
    }
    async componentDidMount() {
        this.props.resetHeritagePhoto()
    }
    onChange = (value) => {
        this.setState({
            documentId: value.trim()
        })    
    }

    onReset = async () => {
        this.setState({
            reset: true,
            disabled: false,
            documentId: ""
        })
    }
    
    onDownload = async (e) => {
        e.preventDefault()
        const { documentId } = this.state
        if(documentId.length>0){
            if (documentId.charAt(0).match(/[a-zA-Z0-9]/)) {
                this.setState({
                    disabled: true
                })
    
            
                await this.props.startLoader();
                await this.props.getHeritagePhoto(documentId);
                
                if (this.props?.adminReducer?.heritagePhoto) {
                    await this.download(this.props.adminReducer.heritagePhoto.downloadUrl)
                } else if (this.props?.adminReducer?.heritagePhotoError) {
                    this.setState({
                        success: true,
                        errdata: this.props.adminReducer.heritagePhotoError,
                        message: ""
                    })
                }
                this.props.resetHeritagePhoto()
            
            }
            else{
                this.setState({
                    success: true,
                    errdata: [DOCUMENT_ID_CHAR_ERROR],
                    message: ""
                })
            }
        }
        else{
            this.setState({
                success: true,
                errdata: [DOCUMENT_ID_ERROR],
                message: ""
            })
        }
        
    }

    download = async (url) => {
        let downloadObj = this.props.downloadBarInfo.data[0] ? this.props.downloadBarInfo.data[0] : [];

        const backslashSplitedArray = url.split('/')
        const questionMarkSplitedArray = backslashSplitedArray.length >=7 ? backslashSplitedArray[7].split('?') : []
        const filename = questionMarkSplitedArray.length > 0 ? questionMarkSplitedArray[0] : ""

        let fileObj = {
            name: filename,
            downloadId: this.state.documentId,
            file: url,
            filename: filename,
            complete:false
        }

        downloadObj.push(fileObj)
        await this.props.getdownloadBarInfo(true, downloadObj);
        
        this.setState({disabled: false})
    }

    onClose = () => {
        this.setState({
            disabled: false,
            success: false
        })
    }
    render() {
        const { loading } = this.props
        const { success, errdata, disabled, documentId, message} = this.state
        return <>
            {
                success &&
                <Modal
                    customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
                    onClick={this.onClose}
                    errorFlag={success}
                >
                    <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
                </Modal>
            }
            {loading && <div className="loader text-center ">
                <Spinner primary={true} />
            </div>
            }
            <form className=''>
                <h3 className="heading">{DOWNLOAD_HERITAGE_TITLE}</h3>
                <div className=' mr10'>
                    <label className='sortorder pt-3 pr-3 '>{DOCUMENT_ID_LABEL}</label>
                    <input
                        placeholder={DOCUMENT_ID_PLACEHOLDER}
                        value={documentId}
                        onChange={(e) =>
                            this.onChange(e.target.value)
                        }
                        type="text"
                        className="search-input p-1 pl-4 w-100 "
                    />
                </div>
                <div>
                    <div className="text-right pt-3 pb-5">

                        <ButtonElement
                            variant="contained"
                            btnType="background-orange button-label"
                            className="d-inline-block mr-2 float-right"
                            onClick={this.onReset}
                        >
                            <span className="button-label">{RESET_BUTTON_VALUE}</span>
                        </ButtonElement>
                        <ButtonElement
                            type="submit"
                            variant="contained"
                            btnType="background-orange button-label"
                            className="d-inline-block mr-2 float-right"
                            onClick={(e) => this.onDownload(e)}
                            disabled={disabled}
                        >
                            <span className="button-label">{DOWNLOAD_BUTTON_VALUE}</span>
                        </ButtonElement>
                    </div>
                </div>
            </form>
            
        </>;
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    downloadBarInfo:state.applicationReducer.downloadBarInfo,
    tokens: state.loginReducer.tokens,
})
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getUserDetails,
    clear_Error,
    clearAdminError,
    getHeritagePhoto,
    resetHeritagePhoto,
    getdownloadBarInfo
})(DownloadHeritage);
