/* eslint-disable import/no-anonymous-default-export */
import {
  GET_PACKAGES,
  // GET_PACKAGES_ERROR,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_ERROR,
  GET_TOP_SEARCH_QUERIES,
  GET_TOP_SEARCH_QUERIES_ERROR,
  GET_CONTENT_TYPES,
  GET_CONTENT_TYPES_ERROR,
  GET_PUBLICATIONS,
  GET_PUBLICATIONS_ERROR,
  GET_PRELOADS,
  CLEAR__ERROR,
  START_LOADER,
  SET_CONTENT_TYPE,
  SET_REFERER,
  SET_SUBSCRIPTION,
  SEARCH_CONTENT,
  SEARCH_CONTENT_ERROR,
  SEARCH_AUTOCOMPLETE_CONTENT,
  SEARCH_AUTOCOMPLETE_CONTENT_ERROR,
  GET_ANNOUNCEMENTS_BY_LANGUAGE,
  CMP_PREFERENCE_LIST,
  CMP_PREFERENCE_LIST_ERROR,
  GET_CONTENT_TYPES_ALLOWED,
  SET_SEARCH_TYPE,
  START_TRANS_LOADER,
  STOP_TRANS_LOADER,
  GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR,
  PAYMENT_STATUS,
  PAYMENT_STATUS_ERROR,
  SEARCH_OBJECT,
  BADGE_DATA,
  DATE_OBJECT,
  SHOW_LOGIN_POPUP,
  ACTIVATE_SUBSCRIPTION,
  ACTIVATE_SUBSCRIPTION_ERROR,
  PAGE_NO,
  SET_SUBSCRIPTION_ERROR,
  GET_TOKEN_FROM_SESSIONID,
  GET_TOKEN_FROM_SESSIONID_ERROR,
  AD_AND_ADV_BADGE,
  HIDE_LINK_FLAG,
  PAGE_VIEW_DATA,
  CUSTOM_VIEW_DATA,
  DURATIONS,
  DOWNLOAD_BAR_INFO,
  SUB_OPTIONS_DIGITALTYPES
} from "../../config/constants/action-constants";
import { filterData, filterData_contentAllowed } from "../../helpers/common";
const initialState = {
  announcements: "",
  contentTypes: [],
  publications: [],
  digitalType: "All",
  extraData: {
    digitalType: "all",
    sourceType: ""
  },
  searchQueries: {},
  searchContent: [],
  autoComplete: {},
  setSearchtype: true,
  badgeData:{sectionDatalist:[],badgeData:{}},
  showloginpopup:false,
  pagenoObject:{from:1,to:10,pagesArray:[]},
  hide_link_flag:false,
  Duration:"0",
  downloadBarInfo:{flag:true,data:[],show:false},
  subDigitalTypeIndex:0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case START_LOADER:
      return {
        ...state,
        isLoading: true
      }
    case GET_ANNOUNCEMENTS:
      // console.log(action.payload.data)
      return {
        ...state,
        isLoading: false,
        announcements: action.payload.data.announcement,
        // announcements: '<ul class="square"><li><span style="font-family: Arial, sans-serif; font-size: 9pt;"><span style="font-family: Helv, sans-serif; font-size: 10pt;"><span style="font-family: Arial, sans-serif;"><p><span style="font-size: 9pt;">1. For new subscribers who want to sign up for the new Newslink 2.0,please click on above top left hand corner tab for enquiry on the various packages :-&nbsp;<b><span style="color: rgb(24, 115, 165); font-size: 10pt;"><a href="http://newslink.sg/user/RegisterRequest.action?register1=&amp;_sourcePage=%2FWEB-INF%2Fjsp%2Fuser%2Fhome.jsp" target="_blank">Not a member yet? Sign up now!</a></span></b></span></p></span></span></span></li><li><span style="font-family: Arial, sans-serif; font-size: 12px;">2.&nbsp;</span><span style="color: rgb(34, 34, 34); font-family: sans-serif; font-size: small;">Classified &amp; Recruitment full page PDFs from the English, Chinese &amp; Malay newspapers are now available for retrieval by advertising agencies.</span><span style="font-size: 14.4px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></li></ul>',
      };
    case GET_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case GET_CONTENT_TYPES:
      const contentTypes = filterData(action.payload.data.digitalTypes.contentTypeResultVoMap);
      return {
        ...state,
        isLoading: false,
        contentTypes,
      };

    case GET_CONTENT_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_TOP_SEARCH_QUERIES:
      return {
        ...state,
        isLoading: false,
        searchQueries: action.payload.data,
      };

    case GET_TOP_SEARCH_QUERIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_PUBLICATIONS:
      const publications = filterData(action.payload.data.publication.publicationVOMap);
      return {
        ...state,
        isLoading: false,
        publications,
      };
    case GET_PUBLICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEARCH_AUTOCOMPLETE_CONTENT:
      return {
        ...state,
        autoComplete: action.payload.data
      };
    case SEARCH_AUTOCOMPLETE_CONTENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEARCH_CONTENT:
      return {
        ...state,
        isLoading: false,
        isTransLoader: false,
        searchContent: action.payload.data,
      };
    case SEARCH_CONTENT_ERROR:
      return {
        ...state,
        isLoading: false,
        isTransLoader: false,
        error: action.payload,
      };
    case GET_PRELOADS:
      return {
        ...state,
        telCodes: action.payload.data
      }
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload.data,
        isLoading: false
      }
    case SET_CONTENT_TYPE:
      return {
        ...state,
        digitalType: action.payload.digitalType,
        extraData: action.payload.extraData
      }
    case SET_REFERER:
      return {
        ...state,
        referer: action.payload.referer,
        IP: action.payload.IP
      }
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload.data,
        addSubscriptionerror:null
      }
    case CLEAR__ERROR:
      return {
        ...state,
        error: null,
        announceByLanguageError: null,
        announceByLanguage: null
      }
    case GET_ANNOUNCEMENTS_BY_LANGUAGE:
      return {
        ...state,
        announceByLanguage: action.payload.data.announcement
      }
    case GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR:
      return {
        ...state,
        announceByLanguageError: action.payload
      }

    case CMP_PREFERENCE_LIST:
      return {
        ...state,
        isLoading: false,
        cmpPreferenceList: action.payload.data
      };
    case CMP_PREFERENCE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_CONTENT_TYPES_ALLOWED:
      const cont = filterData_contentAllowed(action.payload.data.digitalTypes.contentTypeResultVoMap, action.payload.content_allowed,action.payload.is_seamless);
      return {
        ...state,
        isLoading: false,
        contentTypes: cont,
      };

    case SET_SEARCH_TYPE:
      return {
        ...state,
        setSearchtype: action.payload.data

      };
    case START_TRANS_LOADER:
      return {
        ...state,
        isTransLoader: true
      }
    case STOP_TRANS_LOADER:
      return {
        ...state,
        isTransLoader: false
      }
    case PAYMENT_STATUS:
      // console.log(action.payload)
      return {
        ...state,
       payment_status:action.payload.data
      }
    case PAYMENT_STATUS_ERROR:
      return {
        ...state,
        error: action.payload.data,
      }
    case SEARCH_OBJECT:
      return {
        ...state,
        searchObject: action.payload.data,
        isLoading:false
      }
    case BADGE_DATA:
      return {
        ...state,
        badgeData:action.payload.data
      }
      case DATE_OBJECT:
      return {
        ...state,
        dateObject:action.payload.data
      }
      case SHOW_LOGIN_POPUP:
        return {
          ...state,
          showloginpopup:action.payload
        }
    case ACTIVATE_SUBSCRIPTION:
      return {
        ...state,
        activateSubcription: action.payload.data,
        isLoading:false
      }
    case ACTIVATE_SUBSCRIPTION_ERROR:
        return {
          ...state,
          isLoading: false,
          activateSubcription: action.payload,
        };
    case PAGE_NO:
      return {
        ...state,
        pagenoObject:action.payload
      }
      case SET_SUBSCRIPTION_ERROR:
        return {
          ...state,
          isLoading: false,
          addSubscriptionerror: action.payload,
        };
     case GET_TOKEN_FROM_SESSIONID:
        return {
          ...state,
          gettokenfromsessionID: action.payload.data,
          isLoading: false,
        }
     case GET_TOKEN_FROM_SESSIONID_ERROR:
        return {
          ...state,
          error: action.payload,
          isLoading: false
        }
      case AD_AND_ADV_BADGE:
        return {
         ...state,
         adandAdvbadges:action.payload.data,
        }
      case HIDE_LINK_FLAG:
        return {
          ...state,
          hide_link_flag:action.payload
        }
      case PAGE_VIEW_DATA:
        return {
          ...state,
          pageviewData:action.payload
        }
      case CUSTOM_VIEW_DATA:
        return{
          ...state,
          customviewData:action.payload
        }
      case DURATIONS:
        return{
          ...state,
          Duration:action.payload
        }
      case DOWNLOAD_BAR_INFO:
        return {
          ...state,
          downloadBarInfo:action.payload
        }
      case SUB_OPTIONS_DIGITALTYPES:
        return {
          ...state,
          subDigitalType:action.payload.value ? action.payload.value : "",
          subDigitalTypeIndex:action.payload.index
        }
    default:
      return {
        ...state,
      };
  }
}
