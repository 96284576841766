import React, { useState,useEffect } from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from 'dompurify';
import "./inputsuggestion.scss";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
const SuggestionInput = (props) => {
    const [openpop, setopenpop] = useState(false);
    const [listindex,setListindex]= useState(0);
    const [sent,setSent]= useState(false);

    const toggle = (item,clickflag,index) => {
        setopenpop(false)
        var temp = document.createElement("div");
        temp.innerHTML = item;
        var textval = temp.innerText ? temp.innerText : temp.textContent;
        if(!props.value && !clickflag){
            textval=""
        }
        let t=textval.trim();
        let sentcallflag=false;
        if(sent){
            sentcallflag=true;
            // console.log("sent",openpop)
            let obj=props.searchObject;
            obj.keywords=t ? t : "";
            props.onChange(t,obj)
        }else if(index >=0){
            // console.log("index")
            let obj=props.searchObject;
            obj.keywords= obj && t ? t : "";
            props.onChange(t,obj)
        }
        else{
            if(!sentcallflag){
                // console.log("rikama",openpop)
                props.onChange(t,"")
            }

        }
    }
    const clearValue =()=>{
        props.onChange("");
        props.onClear("");
       }
    useEffect(()=>{
        var ignoreClickOnMeElement = document.getElementById(props.id);
        document.addEventListener('click', (event) => {
            const className = event.target.parentElement && event.target.parentElement.className ? event.target.parentElement.className : "";
            var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
            if (!isClickInsideElement && className !== "close") {
                //Do something click is outside specified element;
                    setopenpop(false);
            }
            if(event.target.className==="hoveffect"){
                // toggle(event.target.innerHTML,true)
            }

        });

        document.getElementById('suggestioninput').addEventListener('keyup', (event) => {
               hovereffectonList(event.keyCode);

        })


    },[])

    useEffect(()=>{
        if(props.suggesterData && props.suggesterData[listindex]){
            toggle(props.suggesterData[listindex])
        }
        setSent(false);
    },[sent])
    useEffect(()=>{

        if( listindex >= props.suggesterData.length){
            setListindex(0);
        }
        if(listindex <=0 ){
            setListindex(0)
        }
    },[listindex])
   const hovereffectonList = (key)=>{

         if(key===38  ){
            setListindex(precount  => precount-1);

         }
         if(key===40 ){
            setListindex(precount  => precount+1)
         }
         if(key===13){
            setSent(true);
            props.onChangeEnter();
            document.getElementById('suggestioninput').removeEventListener("keyup",console.log(""));
            document.getElementById('suggestioninput').blur();
         }
           //backspace   space      left        right
        //  if(key===32 || key===8 || key===37 || key===39){
        //     setListindex(0);
        //  }
   }

    const sendtoProps = (e) => {
        setListindex(0);
        setopenpop(true);
        props.onChange(e.target.value)
  }
    return (
        <>
            <div id={props.id} className="search-container2 d-inline-block suggestwdth">
                <input
                    name="search-text"
                    type="text"
                    placeholder={props.placeholder}
                    value={props?.value}
                    autoComplete="off"
                    maxLength="240"
                    className="search-input2 "
                    aria-label="Search"
                    id='suggestioninput'
                    // onChange={(e) => props.onChange ? props.onChange(e.target.value) : () => { }}
                    onChange={(e)=>sendtoProps(e)}
                    // onClick={(e)=>openPopover(e)}
                />
                <span className='hidecrossicon'>{!props?.value && <FontAwesomeIcon icon={faSearch} className="cross-icons" />}
                {props?.value && <FontAwesomeIcon icon={faTimes} className="cross-icon" onClick={()=>clearValue()} />}
                </span>
            </div>

            {props?.value?.length > 3 && props.suggesterData.length > 0 && <div key={props.id + 22}  className={openpop ? " popover__content openpop" : " popover__content nonopenpop"}>
               <div className=''>
            {props.suggesterData.length > 0 && props.suggesterData.map((item, index) => (

                <div className={listindex===index ? 'selectlist hoveffect' :'hoveffect'} dangerouslySetInnerHTML={{
                    __html: `${DOMPurify.sanitize(item)}`,

                }} key={index} onClick={() => toggle(item,false,index)} >

                </div>
            ))}
            </div>
             </div> }

        </>
    );
};

// export default SuggestionInput;

const mapDispatchStatetoProps = (state) => ({
    ...state,
    searchObject: state.applicationReducer.searchObject,
  });

  export default connect(mapDispatchStatetoProps, {

  })(SuggestionInput);
