import axios from "axios";
import { isExpired } from "react-jwt";
import { getCookies } from "../../helpers/common";
import store from "../store";
import { logout } from "./loginAction";

const useAxios = (admin) => {
  let access_token = getCookies("access_token", admin);
  let id_token = getCookies("id_token", admin);
  let refresh_token = getCookies("refresh_token", admin);
  let headers = {
    "X-Frame-Options": "deny",
  };
  if (
    process.env.REACT_APP_ACTIVE_ENV == "LOCAL" &&
    process.env.REACT_APP_ADMIN_TOKEN_LOCAL != ""
  ) {
    headers = {
      "X-Frame-Options": "deny",
      Authorization: "Bearer " + process.env.REACT_APP_ADMIN_TOKEN_LOCAL,
    };
  }
  const axiosInstance = axios.create({
    withCredentials: true,
    "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_ORIGIN,
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
    "Access-Control-Allow-Headers": "X-Requested-With,content-type",
    "Access-Control-Allow-Credentials": true,
    baseURL: process.env.REACT_APP_DOMAIN,
    headers: headers,
  });

  axiosInstance.interceptors.request.use(async (req) => {
    if (access_token) {
      // const user = decodeToken(access_token);
      // const refresht = decodeToken(refresh_token);

      const isE = isExpired(refresh_token);
      const isExpire = isExpired(access_token);
      if (!isExpire && access_token) {
        return req;
      }
      if (isE && refresh_token) {
        localStorage.clear();
        window.location.reload();
        document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `id_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `refresh_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        store.dispatch(logout());
      }

      try {
        document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `id_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `refresh_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        if (admin) {
          await axios.post(
            `${process.env.REACT_APP_HOME_BASE_URL}/refreshToken`,
            {
              accessToken: access_token,
              refreshToken: refresh_token,
              admin: admin,
            }
          );
        } else {
          await axios.post(
            `${process.env.REACT_APP_HOME_BASE_URL}/refreshToken`,
            {
              accessToken: access_token,
              refreshToken: refresh_token,
              idToken: id_token,
            }
          );
        }

        access_token = "";
        refresh_token = "";
        id_token = "";
        return req;
      } catch (error) {
        console.log(error);
      }
    }
    return req;
  });

  return axiosInstance;
};
export default useAxios;
