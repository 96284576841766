import { useState, useEffect } from 'react';
// import './App.css';

function InternetConnection() {
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);

    };

  }, [isOnline]);

  return (
    <div className={!isOnline ? 'isonline anim' :"d-none"}>
       <center> <div className={!isOnline ? "alert alert-danger alert-custom m-fadeIn m-fadeOut margin-top-55px": "alert alert-danger alert-custom  m-fadeOut margin-top-55px"} role="alert">
          <b>You are disconnected from network...!!!</b>
        </div></center>
    </div>
  );
}

export default InternetConnection;
