import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonElement, Checkbox, SelectElement, DataTable, Modal, Spinner } from '../../UI/index';
import { getAgenciesDdl, getAgencyRoyaltyReport, clearAdminError, startLoader } from '../../../redux/actions/adminAction';
import { dowloadPdf } from '../../../helpers/pdfDowload';

const initialState = {
    submit: false,
    agenciesList: [{
        key: '',
        value: ''
    }],
    operationList: [{
        key: '',
        value: ''
    }],
    years: [{
        key: '',
        value: ''
    }],
    quarters: [{
        key: '',
        value: ''
    }],
    quarterMonthsFrom: [{
        key: '',
        value: ''
    }],
    quarterMonthsTo: [{
        key: '',
        value: ''
    }],
    sortBy: [{
        'key': 'ASC',
        'value': 'asc',
    }, {
        'key': 'DESC',
        'value': 'desc',
    }],
    agencyRoyalty: {
        agencyCode: 'ANN',
        year: '',
        quarter: 0,
        fromMonth: 0,
        toMonth: 0,
        operation: 'royaltyReport',
        sortBy: "Publisher",
        sortOrder: 'asc'
    },
    agencyReportList: [],
    visibleAlertBox: false,
    errorType: '',
    message: '',
    headerTitle: '',
    disabled: false,
    multipleClick: false
}

class AgencyRoyaltyReport extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
    }

    componentDidMount = async () => {
        this.setState({
            visibleCreateAgencyForm: false
        })
        await this.props.startLoader();
        await this.props.getAgenciesDdl();
        // get the agency data
        if (this.props?.agencyInfoResult?.agencies) {
            let { agencyRoyalty } = this.state;
            var list = [];
            for (let a of this.props.agencyInfoResult.agencies) {
                list.push({
                    key: a.agency,
                    value: a.code,
                })
            }
            var yearRangeList = [];
            if (this.props?.agencyInfoResult?.yearRange) {
                // Year dropdown logic
                this.setState({
                    years: this.props?.agencyInfoResult?.yearRange
                });
                var ageResult = Object.keys(this.state.years);
                yearRangeList = ageResult.map(function (v) {
                    let obj = {
                        key: v,
                        value: v
                    }
                    return obj;
                });

                // Quarters dropdown logic
                var quartersResult = Object.keys(this.props?.agencyInfoResult?.quarters);
                var cnt = 0;
                var quartesList = quartersResult.map(function (v) {
                    let obj = {
                        key: v,
                        value: cnt
                    }
                    cnt++;
                    return obj;
                });
                var quarterMonthsResult = Object.keys(this.props?.agencyInfoResult?.quarterMonths[0]);
                var quarterMonthsFromList = quarterMonthsResult.map(function (v) {
                    let obj = {
                        key: v,
                        value: v
                    }
                    return obj;
                });

                quarterMonthsResult = Object.keys(this.props?.agencyInfoResult?.quarterMonths[1]);
                var quarterMonthsToList = quarterMonthsResult.map(function (v) {
                    let obj = {
                        key: v,
                        value: v
                    }
                    return obj;
                });
                let year = yearRangeList[0]["key"].split("-")
                agencyRoyalty.fromMonth = quarterMonthsFromList[0]["key"] + " " + year[0];
                // agencyRoyalty.toMonth = quarterMonthsToList[0]["key"] + " " + year[1];
                agencyRoyalty.toMonth = quarterMonthsToList[0]["key"] + " " + year[0];

                agencyRoyalty.year = yearRangeList[0].key;
                agencyRoyalty.quarter = quartesList[0].value;
                this.setState({
                    years: yearRangeList,
                    quarters: quartesList,
                    quarterMonthsFrom: quarterMonthsFromList,
                    quarterMonthsTo: quarterMonthsToList,
                    agencyRoyalty
                });
            }

            var opList = [{
                "key": "Download Royalty Report",
                "value": this.props.agencyInfoResult.operation["Download Royalty Report"]
            }, {
                "key": "View Quarterly Fee",
                "value": this.props.agencyInfoResult.operation["View Quarterly Fee"]
            }]
            this.setState({
                agenciesList: list,
                operationList: opList
            })
        }
    }
    // Reset/clear the data
    onReset = (e) => {
        let year = this.state.years[0].key.split("-")
        let agencyRoyalty1 = {
            agencyCode: 'ANN',
            year: this.state.years[0].key,
            quarter: this.state.quarters[0].value,
            fromMonth: this.state.quarterMonthsFrom[0].key + " " + year[0],
            toMonth: this.state.quarterMonthsTo[0].key + " " + year[1],
            operation: 'royaltyReport',
            sortBy: "Publisher",
            sortOrder: 'asc'
        }
        this.setState({ agencyRoyalty: agencyRoyalty1 });
    }

    onSave = async (e) => {
        e.preventDefault();
        this.setState({
            disabled: true
        })
        // if (!this.state.multipleClick) {
        this.setState({
            agencyRoyalty: this.state.agencyRoyalty,
            // multipleClick: true
        })
        await this.props.clearAdminError();
        this.props.startLoader();
        await this.props.getAgencyRoyaltyReport(this.state.agencyRoyalty);
        if (this.props?.agencyRoyaltyReportErrorList && this.props?.agencyRoyaltyReportErrorList.length > 0) {
            this.setState({
                visibleAlertBox: true,
                message: this.props?.agencyRoyaltyReportErrorList,
                errorType: "Error"
            })
        }
        if (this.props?.agencyRoyaltyReportList?.allUrlList && this.props?.agencyRoyaltyReportList?.allUrlList.length > 0) {
            this.setState({
                visibleAlertBox: true,
                message: this.props?.agencyRoyaltyReportList?.message,
                errorType: "Success",

            })
            dowloadPdf(this.props?.agencyRoyaltyReportList?.allUrlList[0])
        }

        let agencyRoyaltyReportListArr = []
        let agencyRoyaltyReportList = this.props && this.props?.agencyRoyaltyReportList;
        if (agencyRoyaltyReportList && Array.isArray(agencyRoyaltyReportList)) {
            for (let agencyRoyalty of agencyRoyaltyReportList) {
                let obj = {
                    "end date": agencyRoyalty.end_date,
                    "ext package": agencyRoyalty.pkg_name,
                    "fee": agencyRoyalty.fee,
                    "id": agencyRoyalty.id,
                    "no of qtrs": agencyRoyalty.no_of_qtrs,
                    "qtrly fee": agencyRoyalty.qtrly_fee,
                    "start date": agencyRoyalty.start_date,
                    "status": agencyRoyalty.status == "ACTIVE" ? <span className='active'>{agencyRoyalty.status}</span> : agencyRoyalty.status,
                    "user id": agencyRoyalty.user_id,
                }
                agencyRoyaltyReportListArr.push(obj)
            }
            this.setState({
                agencyReportList: agencyRoyaltyReportListArr,
                disabled: false
            })
        }
        agencyRoyaltyReportListArr = [];
        // this.setState({
        //     multipleClick: true,
        // })
        // }
    }

    setFormValue = async (value, field, number = false) => {
        const { agencyRoyalty, quarters } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            agencyRoyalty[field] = regex.test(value) || value === "" ? value : agencyRoyalty[field];
        } else {
            agencyRoyalty[field] = value;
        }
        this.setState({
            agencyRoyalty,
            agencyReportList: []
        });
        if (field == "quarter") {
            let { agencyRoyalty } = this.state;
            let year = agencyRoyalty.year.split("-")
            if (value == "0") {
                agencyRoyalty.fromMonth = "September " + year[0];
                agencyRoyalty.toMonth = "November " + year[0];
                this.setState({
                    agencyRoyalty
                })
            }
            if (value == "1") {
                agencyRoyalty.fromMonth = "December " + year[0];
                agencyRoyalty.toMonth = "February " + year[1];
                this.setState({
                    agencyRoyalty
                })
            }
            if (value == "2") {
                agencyRoyalty.fromMonth = "March " + year[1];
                agencyRoyalty.toMonth = "May " + year[1];
                this.setState({
                    agencyRoyalty
                })
            }

            if (value == "3") {
                agencyRoyalty.fromMonth = "June " + year[1];
                agencyRoyalty.toMonth = "August " + year[1];
                this.setState({
                    agencyRoyalty
                })
            }
        }
        if (field == "year") {
            let year = agencyRoyalty.year.split("-")
            agencyRoyalty.fromMonth = "September " + year[0];
            agencyRoyalty.toMonth = "November " + year[0];
            agencyRoyalty.quarter = quarters[0].value;
            this.setState({
                agencyRoyalty
            })
        }

    }

    closeModal = async (e) => {
        this.setState({
            isCloseAgencyPublisher: false,
            visibleAlertBox: false,
            disabled: false
        })
    }
    render() {
        const { agenciesList, operationList, sortBy, message, agencyReportList, agencyRoyalty,
            years, quarters, visibleAlertBox, errorType, disabled } = this.state;
        const { loading } = this.props;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {
                    visibleAlertBox &&
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType === "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }
                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) :
                                            <li>{message}</li>
                                    }
                                </ul>
                            </div>

                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>

                }
                <div className='container-fluid' >
                    <div>
                        <div className='card padding10'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h3 className="heading">
                                        Quarterly Agency Royalty Report
                                    </h3>
                                </div>
                            </div>
                            <hr />
                            <div className='top10'></div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='paddingLeft col-lg-3 col-md-12 col-sm-12 col-12' >Month to Report : Year</label>
                                    {
                                        years && years.length > 0 && <SelectElement
                                            className='col-lg-3  col-md-12 col-sm-12 col-12'
                                            options={years}
                                            defaultValue={agencyRoyalty.year}
                                            onChange={(e) => this.setFormValue(e.target.value, "year")} />
                                    }

                                </div>
                            </div>
                            <div className='top10'></div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='paddingLeft col-lg-3 col-md-12 col-sm-12 col-12' >Quarter : </label>
                                    {
                                        quarters && quarters.length > 0 && <SelectElement
                                            className='col-lg-3  col-md-12 col-sm-12 col-12'
                                            options={quarters}
                                            defaultValue={agencyRoyalty.quarter}
                                            onChange={(e) => this.setFormValue(Number(e.target.value), "quarter")} />
                                    }

                                </div>
                            </div>
                            <div className='top10'></div>
                            <div className='row'>
                                <div className='col-lg-12 '>
                                    <label className='paddingLeft paddingLeft col-lg-3 col-md-12 col-sm-12 col-12' >From: </label>
                                    <input
                                        type="text"
                                        readOnly
                                        value={agencyRoyalty.fromMonth}
                                        onChange={(e) => this.setFormValue(e.target.value, "fromMonth")}
                                        className="col-lg-3  col-md-12 col-sm-12 col-12 p-1 disabledTextBox"
                                    />
                                </div>
                            </div>
                            <div className='top10'></div>

                            <div className='row'>
                                <div className='col-lg-12 '>
                                    <label className='paddingLeft col-lg-3 col-md-12 col-sm-12 col-12' >To :</label>
                                    <input
                                        type="text"
                                        readOnly
                                        value={agencyRoyalty.toMonth}
                                        onChange={(e) => this.setFormValue(e.target.value, "toMonth")}
                                        className="col-lg-3  col-md-12 col-sm-12 col-12 p-1 disabledTextBox"
                                    />
                                </div>
                            </div>
                            <div className='top10'></div>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='paddingLeft col-lg-3  col-md-12 col-sm-12 col-12' >Agency : </label>
                                    <SelectElement
                                        className='col-lg-3 col-md-12 col-sm-12 col-12'
                                        options={agenciesList}
                                        defaultValue={agencyRoyalty.agencyCode}
                                        onChange={(e) => this.setFormValue(e.target.value, "agencyCode")} />
                                </div>

                            </div>
                            <div className='top10'></div>
                            <div className='row'>
                                <div className='col-lg-12 '>
                                    <label className='paddingLeft paddingTop col-lg-3 col-md-12 col-sm-12 col-12' >Sort By :</label>
                                    <input
                                        type="text"
                                        readonly="true"
                                        disabled="true"
                                        value="Publisher"
                                        className="col-lg-3  col-md-12 col-sm-12 col-12 p-1 disabledTextBox"
                                    />
                                </div>
                            </div>
                            <div className='top10'></div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className="float-left paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3">Operation : </label>
                                    {operationList.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value} checked={p.value == (agencyRoyalty.operation ? agencyRoyalty.operation : 'royaltyReport')} onChange={(e) =>
                                                this.setFormValue(e.target.getAttribute("label"), "operation")} />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>))
                                    }
                                </div>
                            </div>
                            <div className='top10 clear-float'></div>
                            <div className='top10'></div>

                            <div className='row'>
                                <div className="col-lg-12">
                                    <label className="float-left paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3">Sort Order :</label>
                                    {sortBy.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value} checked={p.value == (agencyRoyalty.sortOrder ? agencyRoyalty.sortOrder : 'asc')} onChange={(e) =>
                                                this.setFormValue(e.target.getAttribute("label"), "sortOrder")} />
                                            <span className="margin-left-35">{p.key.toUpperCase()} </span>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                            <div className='top10 clear-float'></div>

                            <div className='row'>
                                <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                    <div className='float-right'>
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 top-10"
                                            onClick={(e) => this.onSave(e)}
                                            disabled={disabled}
                                        >
                                            <span className="button-label">Submit</span>
                                        </ButtonElement>
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2  top-10"
                                            onClick={(e) => this.onReset(e)}
                                        >
                                            <span className="button-label  ">Reset</span>
                                        </ButtonElement>


                                    </div>

                                </div>

                            </div>

                            <div className='top10'></div>
                            <div className="">
                                <div className="" key={Date.now()} >
                                    {agencyReportList && agencyReportList.length > 0 && <DataTable className="" checkbox={false} columns={agencyReportList[0]} data={agencyReportList} ></DataTable>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    agencyInfoResult: state.adminReducer.agencyInfoResult,
    agencyRoyaltyReportList: state.adminReducer.agencyRoyaltyReportList,
    agencyRoyaltyReportErrorList: state.adminReducer.agencyRoyaltyReportErrorList,
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getAgenciesDdl,
    getAgencyRoyaltyReport,
    clearAdminError
})(AgencyRoyaltyReport);
