import React, { Component } from 'react';
import Recaptcha from "react-google-recaptcha"
import { RECAPTCHA_SITE_KEY_V2 } from '../../../config/constants';
import { RECAPTCHA_ERROR } from '../../../config/constants/generalConstants';


class RecaptchaWidget extends Component {
    constructor(props) {
        super(props);
        this.recaptcha = React.createRef(null);
        this.captchaChange = this.captchaChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.token=="" && prevProps.token !== this.props.token){
            this.recaptcha.reset();
        }
    }
    captchaChange = async () => {
        const captchaToken =  this.recaptcha.getValue();
        this.props.getRecaptchaToken(captchaToken)
    }

    render(){
        return (
            <div style={{ width: "100%", overflow: "hidden", padding: '5px 0px'}}>
                <Recaptcha
                    size="normal"
                    ref={(ref) => (this.recaptcha = ref)}
                    onChange={this.captchaChange}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                />
                {
                    this.props.error == true && <p style={{color: 'red', marginTop: "5px"}}>{RECAPTCHA_ERROR}</p>
                }
            </div>
            
        );
    }
    
}

export default RecaptchaWidget
