import React from "react";
import "./select.scss";

const SelectElement = (props) => {

  return (
    <select
      value={props.defaultValue}
      disabled={props.disabled ? props.disabled : false}
      className={`p-2 select-control w-100 ${props.className ? props.className : ""
        }`}
      onChange={(e) => (props.onChange ? props.onChange(e) : {})}
    >
      {props.options.map((option) => (
        <option
          key={props.keyName ? option[props.keyName] : option.key}
          value={props.value ? option[props.value] : option.value}
          disabled={props.disablePkgName ? option.value !== props.disablePkgName : false}
        >
          {props.value ? option[props.value] : option.key}
        </option>
      ))}
    </select>
  );
};

export default SelectElement;
