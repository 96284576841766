import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonElement, Modal, Spinner } from '../../UI/index';
import { clearAdminError, startLoader, getMailingList, updateMailingList } from '../../../redux/actions/adminAction';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import './adminUser.scss';
const initialState = {
    submit: false,
    mailingList: [],
    disabledFields: [],
    status: '',
    requestObject: {
        aliasEmail: '',
        captchaToken: '',
        membersEmail: ''
    },
    dataObjectKey: '',
    clickflagmailinglist:false
}
class MailingList extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
    }
    componentDidMount = async () => {
        this.setState({
            visibleCreateAgencyForm: false
        })
        this.props.startLoader();
        await this.props.getMailingList();
        // Get the mails
        if (this.props?.getMailingListResult) {
            let mailingListResult = this.props?.getMailingListResult?.mailingList;
            let data = Object.keys(mailingListResult);
            var malingListObject = [];
            data.map(function(d, i) {
                let obj = {
                    name: mailingListResult[d].name,
                    membersEmail: mailingListResult[d].membersEmail,
                    aliasEmail: mailingListResult[d].aliasEmail
                }
                malingListObject.push(obj)
                return d;
            })
            this.setState({
                mailingList: malingListObject
            })
        }
    }

    onSave = async (e, i) => {
        if(!this.state.clickflagmailinglist){
            this.setState({clickflagmailinglist:true})

        e.preventDefault()
        this.setState({
            index: i
        })
        this.recaptcha.execute(i);

    }
    }
    onResolved = async (i) => {
        this.setState({
            visibleAlertBox: false,
            message: '',
            status : ''
        });
        const response = this.recaptcha.getResponse();
        this.props.startLoader();
        let { requestObject, mailingList,index } = this.state;
        requestObject.aliasEmail = mailingList[index].aliasEmail;
        requestObject.membersEmail = mailingList[index].membersEmail ? mailingList[index].membersEmail.trim() : mailingList[index].membersEmail;
        requestObject.captchaToken = response;
        await this.props.updateMailingList(requestObject);
        let msg = this.props?.updateMailinglistResult?.message || this.props?.updateMailinglistError?.errors;
        let status = this.props?.updateMailinglistError?.errors ? "Error" : "Success";
        if(msg){
            this.setState({
                visibleAlertBox: true,
                message: msg,
                status
            });
        }
        await this.props.clearAdminError();
        this.setState({clickflagmailinglist:false})
    }
    closeModal = async (e) => {
        this.setState({
            visibleAlertBox: false,
        })
    }
    setFormValue = async (e, i) => {
        let { mailingList, requestObject } = this.state;
        mailingList[i].membersEmail = e.target.value.trim();
        requestObject = {
            aliasEmail: e.target.id,
            membersEmail: e.target.value.trim()
        }
        this.setState({
            mailingList,
            requestObject
        });
    }

    render() {
        const {
            message,
            visibleAlertBox,
            status,
            mailingList } = this.state;
        let { loading } = this.props;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    status === "Success" ? <span className='suc'>{status}</span> : <span className='err'>{status}</span>
                                }
                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : message
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}

                <div className='padding10 card' >
                    <div className='col-lg-12'>
                        <h3 className="heading">
                            Mailing List
                        </h3>
                    </div>
                    <div className='top10'></div>

                    <span>
                        Use ; to split the multiple email addresses.
                    </span>
                    <hr />
                    <div className='top10'></div>
                    {
                        mailingList &&
                        mailingList.length > 0 &&
                        mailingList.map((m, i) =>
                            <div className="row margin-top-10">
                                <div className='col-lg-12 '>
                                    <label className='paddingLeft col-lg-4 col-md-12 col-sm-12 col-12' >{m.name} </label>
                                    <input
                                        value={mailingList[i].membersEmail}
                                        id={m.aliasEmail}
                                        onChange={(e) => this.setFormValue(e, i)}
                                        type="text"
                                        className="search-input p-1 col-lg-6 col-md-12 col-sm-12 col-12 bottom10"
                                    />
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2 margin2px "
                                        onClick={(e) => this.onSave(e, i)}
                                    >
                                        <span className="button-label">Update</span>
                                    </ButtonElement>
                                </div>
                                <div className='top10'></div>
                            </div>
                        )
                    }

                </div>

                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>
        )
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    getMailingListResult: state.adminReducer.getMailingListResult,
    updateMailinglistResult: state.adminReducer.updateMailinglistResult,
    updateMailinglistError: state.adminReducer.updateMailinglistError
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getMailingList,
    clearAdminError,
    updateMailingList
})(MailingList);
