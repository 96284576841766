import React, { Component } from 'react'
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { RECAPTCHA_SITE_KEY } from '../../../../config/constants/index';
import "../CreateAccount/CreateAccount.scss"
import { startLoader, getInfoData, getPreloads, createUserAccount, getSubscriptionStatus, calculateFeeByPkg, calculateFeeBySubscriptionFee, clearAdminError } from '../../../../redux/actions/adminAction';
import { ButtonElement, DatePickerElement, Spinner, Modal } from '../../../UI//index';
import { getDate, extractTelCodes, getOneYearDate } from '../../../../helpers/common';
import { Checkbox, SelectElement } from '../../../UI//index';
import { debounce } from "lodash";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPopup from '../../Popup/ErrorPopup';
import { salutations } from '../../../../config/constants/generalConstants';

var initialState = {
  userType: [],
  accountinfostatus: [],
  Account: {},
  activeSubscription: [],
  minDate: getDate(),
  fromDate: new Date(),
  toDate: getOneYearDate(),
  hideLinkArr: [],
  subscriptionStatus: [],
  pkgDecription: "",
  gst: 0,
  total: 0,
  paymentModeArr: [],
  success: false,
  errdata: [],
  message: "",
  CreateAccount: {
    accountInfoDTO: {
      hideLink: false,
      password: "",
      ipAddress: "",
      httpReferRegx: "",
      seamless: false,
      userId: "",
      userType: "",
      accountStatus: 2,
      remark: ""
    },
    basicInfoDTO: {
      address: "",
      company: "",
      country: "SG",
      email: "",
      contactPerson: "",
      salutation: "MR",
      tel: "",
      telCode: 65,
      postalCode: "",
    },
    captchaToken: "",
    sendValidationEmail: false,

    paymentInfoDTO: {
      paymentMode: 1,
      paymentRef: ""
    },
    subscriptionInfoDTO: {
      articleRetrievalFee: "",
      // toDate: "",
      pkg: "none",
      // fromDate: "",
      dateRange: {
        requestDateFrom: "",
        requestDateTo: ""
      },
      subscriptionFee: 0,
      allowMagazineSearch: false,
      disallowArticle: false,
      disallowPdf: false,
      sphPdfRetrievalFee: '',
      sphmPdfRetrievalFee: 0,
      subStatus: 3,
      captchaToken: ""
    }
  },
  currentGst: 0,
  btnDisable: false,
  sendBtnDisable: false
}

export class CreateAccount extends Component {
  state = initialState
  componentDidMount = async () => {
    const { CreateAccount } = this.state;
    await this.props.startLoader();
    //loading telcodes and country code
    // await this.props.getPreloads();
    // await this.props.startLoader();
    //retreviewing  required info
    await this.props.getInfoData();
    await this.props.startLoader();
    //get all subscription status
    await this.props.getSubscriptionStatus();
    await this.props.startLoader();
    let usertypeKeys = Object.keys(this.props?.infoData?.userTypes);
    let allUserType = this.props.infoData.userTypes;
    //created array of usertype in key  and value format
    let newUserTypesArr = usertypeKeys.map((usertype) => ({ key: allUserType[usertype].userTypeName, value: usertype }))
    let accountStatus = Object.keys(this.props.infoData.accountStatus)
    let newAccountstatusArr = [];
    //created array of account status in key and value format
    newAccountstatusArr = accountStatus.map((status) => ({ key: status, value: this.props.infoData.accountStatus[status] }))
    let basePackageVO = Object.keys(this.props.infoData.packages.basePackageVO)
    let allSubscriptionPackage = this.props.infoData.packages.basePackageVO
    //created array of active subscription in key value formate
    let newSubscriptionArr = basePackageVO.map((subscriptionPackage) => ({ key: allSubscriptionPackage[subscriptionPackage].description, value: subscriptionPackage }))
    let hideLinkObj = Object.keys(this.props.infoData.hideLink)
    let hideLink = [];
    // array of hidelink with key and value
    hideLink = hideLinkObj.map((hideLink) => ({ key: this.props.infoData.hideLink[hideLink], value: hideLink }))
    let subscription_Status = Object.keys(this.props.subscriptionStatus.status)
    let subscription_Status_Arr = [];

    subscription_Status_Arr = subscription_Status.map((status) => ({ key: status, value: this.props.subscriptionStatus.status[status] }))
    // await this.props.startLoader();
    await this.props.calculateFeeByPkg({ pkg: "none" })
    let pkgDecription = this.props?.pkgInfo?.message?.none
    CreateAccount.subscriptionInfoDTO["subscriptionFee"] = this.props?.pkgInfo?.subscription?.fee
    CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = this.props?.pkgInfo?.subscription?.priceArticle;
    // CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = this.props?.pkgInfo?.subscription?.pricePdf;
    CreateAccount.subscriptionInfoDTO["pkg"] = "none";
    let disabledFileds = this.props?.pkgInfo?.subscription?.disabledFields

    if (disabledFileds && disabledFileds.length > 0) {
      if (disabledFileds.includes("pricePdf")) {
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = false
      }
      if (disabledFileds.includes("priceArticle")) {
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = false

      }
    } else {
      if (this.props?.pkgInfo?.subscription?.priceArticle === "NA") {
        // CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = 0
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = false
      }
      if (this.props?.pkgInfo?.subscription?.pricePdf === "NA") {
        // CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = 0
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = false
      }
    }
    //converting date in date format
    let from = new Date()
    let to = getOneYearDate();

    let modifiedFromdate = new Date(from.getTime() - (from.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];

    let modifiedTodate = new Date(to.getTime() - (to.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];

    CreateAccount.subscriptionInfoDTO.dateRange["requestDateFrom"] = modifiedFromdate;
    CreateAccount.subscriptionInfoDTO.dateRange["requestDateTo"] = modifiedTodate;
    let paymentMode = Object.keys(this.props.infoData?.paymentType)
    let paymentModeArr = [];
    //array of paymentMode in key ,value format
    paymentModeArr = paymentMode.map((mode) => ({ key: this.props.infoData.paymentType[mode], value: mode }))
    if (this.props?.infoData?.sysConfig) {
      this.setState({
        currentGst: this.props?.infoData?.sysConfig?.current_gst ? (Number(this.props?.infoData?.sysConfig?.current_gst) * 100).toFixed(0) : 0
      })
    }
    this.setState({
      userType: newUserTypesArr,
      accountinfostatus: newAccountstatusArr,
      activeSubscription: newSubscriptionArr,
      hideLinkArr: hideLink,
      subscriptionStatus: subscription_Status_Arr,
      pkgDecription: pkgDecription,
      paymentModeArr: paymentModeArr,
      gst: this.props?.pkgInfo?.subscription?.gst,
      total: this.props?.pkgInfo?.subscription?.total,
      CreateAccount,
      fromDate: from,
      toDate: to
    })
    this.onReset();

  }
  //create account
  onSubmit = (e) => {
    this.setState({
      btnDisable: true,

    })
    e.preventDefault();
    this.recaptcha.execute();
  }
  createAndSendEmail = (e) => {
    this.setState({
      sendBtnDisable: true
    })
    e.preventDefault();
    const { CreateAccount } = this.state
    CreateAccount.sendValidationEmail = true;
    this.setState({
      CreateAccount
    })
    this.recaptcha.execute();
  }
  onResolved = async () => {
    const { CreateAccount } = this.state
    const response = this.recaptcha.getResponse();
    CreateAccount.captchaToken = response;
    CreateAccount.subscriptionInfoDTO["captchaToken"] = response;
    if (CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] === "NA") {
      CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = 0
    }
    if (CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] === "NA") {
      CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = 0
    }

    CreateAccount.basicInfoDTO["tel"] = CreateAccount.basicInfoDTO["tel"] === "" ? 0 : Number(CreateAccount.basicInfoDTO["tel"]);
    CreateAccount.basicInfoDTO["postalCode"] = CreateAccount.basicInfoDTO["postalCode"] === "" ? 0 : Number(CreateAccount.basicInfoDTO["postalCode"]);
    CreateAccount.basicInfoDTO["contactPerson"] = CreateAccount.basicInfoDTO["contactPerson"] ? CreateAccount.basicInfoDTO["contactPerson"].trim() : CreateAccount.basicInfoDTO["contactPerson"];
    CreateAccount.basicInfoDTO["salutation"] = CreateAccount.basicInfoDTO["salutation"] ? CreateAccount.basicInfoDTO["salutation"] : "MR";

    CreateAccount.basicInfoDTO["address"] = CreateAccount.basicInfoDTO["address"] ? CreateAccount.basicInfoDTO["address"].trim() : CreateAccount.basicInfoDTO["address"];
    CreateAccount.basicInfoDTO["company"] = CreateAccount.basicInfoDTO["company"] ? CreateAccount.basicInfoDTO["company"].trim() : CreateAccount.basicInfoDTO["company"];
    CreateAccount.basicInfoDTO["country"] = CreateAccount.basicInfoDTO["country"] ? CreateAccount.basicInfoDTO["country"].trim() : CreateAccount.basicInfoDTO["country"];
    CreateAccount.basicInfoDTO["email"] = CreateAccount.basicInfoDTO["email"] ? CreateAccount.basicInfoDTO["email"].trim() : CreateAccount.basicInfoDTO["email"];



    CreateAccount.paymentInfoDTO["paymentRef"] = CreateAccount.paymentInfoDTO["paymentRef"] === "" ? "" : (CreateAccount.paymentInfoDTO["paymentRef"]);

    CreateAccount.accountInfoDTO["ipAddress"] = CreateAccount.accountInfoDTO["ipAddress"] ? CreateAccount.accountInfoDTO["ipAddress"].trim() : CreateAccount.basicInfoDTO["ipAddress"];
    CreateAccount.accountInfoDTO["httpReferRegx"] = CreateAccount.accountInfoDTO["httpReferRegx"] ? CreateAccount.accountInfoDTO["httpReferRegx"].trim() : CreateAccount.basicInfoDTO["httpReferRegx"];
    CreateAccount.accountInfoDTO["remark"] = CreateAccount.accountInfoDTO["remark"] ? CreateAccount.accountInfoDTO["remark"].trim() : CreateAccount.basicInfoDTO["remark"];

    CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] ? CreateAccount.subscriptionInfoDTO["articleRetrievalFee"].trim() : CreateAccount.subscriptionInfoDTO["articleRetrievalFee"];
    CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] ? CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"].trim() : CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"];


    await this.props.clearAdminError();
    await this.props.startLoader();
    await this.props.createUserAccount(CreateAccount);
    if (this.props.createUserAccountRes?.message) {
      this.setState({
        success: true,
        message: this.props.createUserAccountRes?.message,
        errdata: []
      })
    } else if (this.props?.createUserAccountResError?.length > 0) {
      this.setState({
        success: true,
        errdata: this.props?.createUserAccountResError,
        message: []
      })
    }

  }
  setFormValueForAccountInfoDTO = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.accountInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.accountInfoDTO[field];
    } else {
      //removing white space from field value
      if (field === "remark" && value.trim() == "") {
        CreateAccount.accountInfoDTO[field] = value.trim();
      }
      //clearing ip address and httpRreferRegx when seamless is false
      else if (field === "seamless" && value === false) {
        CreateAccount.accountInfoDTO.httpReferRegx = "";
        CreateAccount.accountInfoDTO.ipAddress = ""
        CreateAccount.accountInfoDTO[field] = value;
      }
      else {
        CreateAccount.accountInfoDTO[field] = value;
      }
    }
    this.setState({
      CreateAccount,
    });
  }
  setBasicInfoDTO = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.basicInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.basicInfoDTO[field];
    } else {
      if (field === "tel" || field === "postalCode") {
        if (!isNaN(value)) {
          CreateAccount.basicInfoDTO[field] = (value.toString().trim());
        }
      } else {
        CreateAccount.basicInfoDTO[field] = value;
      }
    }
    this.setState({
      CreateAccount,
    });
  }

  setSubscriptionInfoDTO = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.subscriptionInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.subscriptionInfoDTO[field];
    } else {
      CreateAccount.subscriptionInfoDTO[field] = value;
    }
    this.setState({
      CreateAccount,
    });
  }

  setSubscriptionChange = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.subscriptionInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.subscriptionInfoDTO[field];
    } else {
      CreateAccount.subscriptionInfoDTO[field] = value;
    }
    this.setState({
      CreateAccount,
    });
    this.calculateFee(value)
  }
  calculateFee = debounce(async (value) => {
    const { pkgDecription } = this.state;
    if (pkgDecription) {
      let str = pkgDecription;
      if (str.includes("Yearly subscription fee:")) {
        let part1 = str.split(":");
        let subSplit = part1[1].split(" ")
        let fStr = str.replace(subSplit[1], "$" + value)
        this.setState({
          pkgDecription: fStr
        })
      }
    }
    await this.props.calculateFeeBySubscriptionFee(value)
    this.setState({
      gst: this.props?.calculateFeeRes?.subscription.gst,
      total: this.props?.calculateFeeRes?.subscription.total
    })
  }, 500);

  onArticleRetrivalFeeChange = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.subscriptionInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.subscriptionInfoDTO[field];
    } else {
      CreateAccount.subscriptionInfoDTO[field] = value;
    }
    this.setState({
      CreateAccount,
    });
    this.updateArticleFee(value)
  }
  updateArticleFee = debounce(async (value) => {
    const { pkgDecription } = this.state;
    if (pkgDecription) {
      let str = pkgDecription;
      if (str.includes("Article retrieval fee:")) {
        let part1 = str.split(":");
        let subSplit = "";
        if (part1[0].includes("Article retrieval fee")) {
          subSplit = part1[1].split(" ")
        } else {
          subSplit = part1[2].split(" ")
        }
        let str1 = "Article retrieval fee: " + subSplit[1];
        let str2 = "Article retrieval fee: $" + value;
        let newStr = str.replace(str1, str2)
        this.setState({
          pkgDecription: newStr
        })
      }
    }
  }, 500);

  onChangeSphPdfRetrievalFee = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.subscriptionInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.subscriptionInfoDTO[field];
    } else {
      CreateAccount.subscriptionInfoDTO[field] = value;
    }
    this.setState({
      CreateAccount,
    });
    this.updatePDFREtrivaleFee(value)
  }
  updatePDFREtrivaleFee = debounce(async (value) => {
    const { pkgDecription } = this.state;
    if (pkgDecription) {
      let str = pkgDecription;
      if (str.includes("Full Page PDF retrieval fee:")) {
        let part1 = str.split(":");
        let subSplit = "";
        if (part1[1].includes("Full Page PDF retrieval fee")) {
          subSplit = part1[2].split(" ")
        } else {
          subSplit = part1[3].split(" ")
        }
        let str1 = "Full Page PDF retrieval fee: " + subSplit[1];
        let str2 = "Full Page PDF retrieval fee: $" + value;
        let newStr = str.replace(str1, str2)
        this.setState({
          pkgDecription: newStr
        })
      }
    }
  }, 500);
  setPkgSubscriptionInfoDTO = async (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.subscriptionInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.subscriptionInfoDTO[field];
    } else {
      CreateAccount.subscriptionInfoDTO[field] = value;
    }
    this.setState({
      CreateAccount,
    });
    await this.props.startLoader();
    await this.props.calculateFeeByPkg({ pkg: value })
    let dataKey = value;
    let pkgDecription = this.props?.pkgInfo?.message[dataKey]
    CreateAccount.subscriptionInfoDTO["subscriptionFee"] = this.props?.pkgInfo?.subscription?.fee;
    CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = this.props?.pkgInfo?.subscription?.priceArticle;
    CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = this.props?.pkgInfo?.subscription?.pricePdf;
    CreateAccount.subscriptionInfoDTO.dateRange["requestDateFrom"] = (this.props?.pkgInfo?.subscription?.subFrom);
    CreateAccount.subscriptionInfoDTO.dateRange["requestDateTo"] = (this.props?.pkgInfo?.subscription?.subTo);
    let disabledFileds = this.props?.pkgInfo?.subscription.disabledFields
    if (disabledFileds && disabledFileds.length > 0) {

      if (disabledFileds.includes("pricePdf")) {
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = false
      }
      if (disabledFileds.includes("priceArticle")) {
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = false

      }
    } else {

      if (this.props?.pkgInfo?.subscription?.priceArticle === "NA") {
        // CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = 0
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowArticle"] = false
      }
      if (this.props?.pkgInfo?.subscription?.pricePdf === "NA") {
        // CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = 0
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = true
      } else {
        CreateAccount.subscriptionInfoDTO["disallowPdf"] = false
      }
    }

    this.setState({
      pkgDecription: pkgDecription,
      CreateAccount,
      gst: this.props?.pkgInfo?.subscription?.gst,
      total: this.props?.pkgInfo?.subscription?.total,
      fromDate: new Date(this.props?.pkgInfo?.subscription?.subFrom),
      toDate: new Date(this.props?.pkgInfo?.subscription?.subTo)
    })
  }
  setPaymentInfoDTO = (value, field, number = false) => {
    const { CreateAccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      CreateAccount.paymentInfoDTO[field] = regex.test(value) || value === "" ? value : CreateAccount.paymentInfoDTO[field];
    } else {
      if (field === "paymentRef") {
        if (!isNaN(value)) {
          CreateAccount.paymentInfoDTO[field] = ((value.toString()).trim());
        }
      } else
        CreateAccount.paymentInfoDTO[field] = value;
    }
    this.setState({
      CreateAccount,
    });
  }

  //date code started
  onfromDateChange = (date) => {
    const { CreateAccount } = this.state;
    let dt = new Date(date);
    let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
    CreateAccount.subscriptionInfoDTO.dateRange["requestDateFrom"] = modifieddate;
    this.setState({
      CreateAccount,
      fromDate: date
    });
  };
  setfromdate = (useraccount) => {
    this.setState({
      useraccount
    })
  }
  ontoDateChange = (date) => {
    const { CreateAccount } = this.state;
    let dt = new Date(date);
    let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
    CreateAccount.subscriptionInfoDTO.dateRange["requestDateTo"] = modifieddate;
    this.setState({
      CreateAccount,
      toDate: date
    });
  };
  settodate = (useraccount) => {
    this.setState({
      useraccount
    })
  }
  onReset = async (e) => {
    const { CreateAccount } = this.state;

    let from = new Date()
    let to = getOneYearDate();
    await this.props.startLoader()
    let modifiedFromdate = new Date(from.getTime() - (from.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];

    let modifiedTodate = new Date(to.getTime() - (to.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
    CreateAccount.subscriptionInfoDTO["pkg"] = "none"
    await this.props.calculateFeeByPkg({ pkg: "none" })
    let pkgDecription = this.props?.pkgInfo?.message?.none
    let fee = this.props?.pkgInfo?.subscription?.fee
    let articlePrice = this.props?.pkgInfo?.subscription?.priceArticle;
    let pdfPrice = this.props?.pkgInfo?.subscription?.pricePdf
    CreateAccount.subscriptionInfoDTO["subscriptionFee"] = fee
    CreateAccount.subscriptionInfoDTO["articleRetrievalFee"] = articlePrice;
    CreateAccount.subscriptionInfoDTO["sphPdfRetrievalFee"] = pdfPrice;
    this.setState({
      CreateAccount,
      pkgDecription: pkgDecription,
      gst: this.props?.pkgInfo?.subscription?.gst,
      total: this.props?.pkgInfo?.subscription?.total,
    })
    let initalAccount = {
      accountInfoDTO: {
        hideLink: false,
        password: "",
        ipAddress: "",
        httpReferRegx: "",
        seamless: false,
        userId: "",
        userType: "",
        accountStatus: 2,
        remark: ""
      },
      basicInfoDTO: {
        address: "",
        company: "",
        country: "SG",
        email: "",
        contactPerson: "",
        salutation:"MR",
        tel: "",
        telCode: 65,
        postalCode: "",
      },
      captchaToken: "",
      sendValidationEmail: false,

      paymentInfoDTO: {
        paymentMode: 1,
        paymentRef: ""
      },
      subscriptionInfoDTO: {
        articleRetrievalFee: articlePrice,
        // toDate: modifiedTodate,
        pkg: "none",
        // fromDate: modifiedFromdate,
        subscriptionFee: fee,
        allowMagazineSearch: false,
        disallowArticle: false,
        disallowPdf: false,
        sphPdfRetrievalFee: pdfPrice,
        sphmPdfRetrievalFee: 0,
        subStatus: 3,
        captchaToken: "",
        dateRange: {
          requestDateFrom: modifiedFromdate,
          requestDateTo: modifiedTodate
        },
      }
    }
    this.setState({
      CreateAccount: initalAccount,
      // gst: 0,
      // total: 0,
    }
    );

  }
  onClose = () => {
    this.setState({
      btnDisable: false,
      sendBtnDisable: false
    })
    if (this.props?.createUserAccountRes?.message) {
      // this.onReset()

    }
    if (this.props?.createUserAccountRes?.message) {

      this.setState({
        success: false
      })
      this.props.history.push({
        pathname: '/admin/FindUser',
        state: {
          finduser: {
            isUsageDetails: false

          }
        }
      })
    } else {
      this.setState({
        success: false
      })
    }


  }
  render() {
    const { userType, accountinfostatus, activeSubscription,
      minDate, currentGst,
      CreateAccount, fromDate, success, message, errdata, toDate, hideLinkArr, subscriptionStatus, pkgDecription, gst, total, paymentModeArr, btnDisable, sendBtnDisable } = this.state;
    const { telCodes, loading,infoData } = this.props;
    const { codes = [], regions = [] } = extractTelCodes(infoData);
    return (
      <div className='main-content'>
        {
          success &&
          <Modal
            customClass={`${success ? "create-modal" : "forgot-password-modal"}`}
            onClick={this.onClose}
            errorFlag={success}
          >
            <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
          </Modal>
        }
        {loading && <div className="text-center spinner">
          <Spinner primary={true} />
        </div>
        }
        <>
          <form className="container-fluid"
            noValidate
          >
            <div className='row card p-3'>
              <h2 className="heading">Account Info</h2>
              <div className='row '>
                <div className="col-lg-6 col-md-6  pb-2">
                  <label className='pr-2 pt-1 form-control-label '>UserId/Email<span className="requiredStar">*</span></label>
                  <input
                    placeholder="Email"
                    value={CreateAccount.accountInfoDTO.userId}
                    onChange={(e) => this.setFormValueForAccountInfoDTO((e.target.value).toString().trim(), "userId")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />

                  <div className="pt-1">
                    <span className="error-message">
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6  pb-2">
                  <label className='pr-2 pt-1 form-control-label '>Password <span className="requiredStar">*</span></label>
                  <input
                    placeholder="Password"
                    value={CreateAccount.accountInfoDTO.password}
                    onChange={(e) => this.setFormValueForAccountInfoDTO((e.target.value).toString().trim(), "password")}
                    type="password"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>

              </div>
              <div className='row'>
                <div> <label className='nl_status ' >Type <span className="requiredStar">*</span></label></div>
                <div className="d-flex flex-wrap p-2  selectlist-item" >
                  {userType.length > 0 && userType.map((p) => (
                    <div className="flexcheckbox padding5px" key={p.key}>
                      <Checkbox key={p.key} labelValue={p.value} checked={p.value === CreateAccount.accountInfoDTO.userType}
                        onChange={(e) =>
                          this.setFormValueForAccountInfoDTO(p.value, "userType")}
                      />
                      <span className="margin-left-35">{p.key} </span>

                    </div>))}
                </div>
              </div>

              <div className='row'>
                <div> <label className='nl_status' > Account Status <span className="requiredStar">*</span></label></div>
                {accountinfostatus && accountinfostatus.map((p) => (
                  <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                    <Checkbox key={p.key} labelValue={p.value} checked={p.value === CreateAccount.accountInfoDTO.accountStatus}
                      onChange={(e) =>
                        this.setFormValueForAccountInfoDTO(Number(p.value), "accountStatus")}
                    />
                    <span className="margin-left-35">{p.key} </span>
                  </div>))}
              </div>
              <div className='row'>
                <div className="col-lg-4  col-md-6 col-sm-12 pb-2">
                  <label className=''>Referrer URL Pattern <span className="requiredStar">*</span></label>
                  <input
                    type="text"
                    placeholder="Referrer URL Pattern"
                    value={CreateAccount.accountInfoDTO.httpReferRegx}
                    onChange={(e) => this.setFormValueForAccountInfoDTO(e.target.value, "httpReferRegx")}
                    className="search-input p-1 pl-4 w-100 "
                    disabled={CreateAccount.accountInfoDTO.seamless ? false : true}
                  />

                </div>
                <div className="col-lg-4 col-12 col-md-6 col-sm-12 pb-2 mt-20">

                  <Checkbox checked={CreateAccount.accountInfoDTO.seamless} onChange={(e) =>
                    this.setFormValueForAccountInfoDTO(e.target.checked, "seamless")}
                  />
                  <span className="margin-left-35">Seamless Login</span>

                </div>

              </div>
              <div className='row'>
                <div className="col-lg-4 col-12 col-md-6 pb-2 margintop ">
                  <label className=' ' >Ip Address or Ip Range</label>
                  <input
                    type="text"
                    placeholder="Ip Address Range"
                    value={CreateAccount.accountInfoDTO.ipAddress}
                    onChange={(e) => this.setFormValueForAccountInfoDTO(e.target.value, "ipAddress")}
                    className="search-input p-1 pl-4 w-100 "
                    name="ipAddress"
                    autoComplete="false"
                    disabled={CreateAccount.accountInfoDTO.seamless ? false : true}
                  />
                </div>
                <div className="col-lg-4 col-12 col-md-6 pb-2">
                  <label className='' >Hide Link <span className="requiredStar">*</span></label>
                  <SelectElement
                    options={hideLinkArr}
                    className="p-1"
                    onChange={(e) => this.setFormValueForAccountInfoDTO(Boolean(Number(e.target.value)), "hideLink")}
                  />
                </div>
                <div className="col-lg-4 col-12 col-md-6 pb-2">
                  <label className='activeSubscription ' >Remark </label>
                  <textarea
                    type="text"
                    placeholder="Remark"
                    value={CreateAccount.accountInfoDTO.remark}
                    onChange={(e) => this.setFormValueForAccountInfoDTO(e.target.value, "remark")}
                    className="search-input p-1 pl-4 w-100 "
                    name="Remark"
                    autoComplete="false"
                  />
                </div>
              </div>
            </div>
            <div className='row card p-3'>
              <h2 className="heading">Basic Info</h2>
              <div className='row'>
                <div className="col-lg-6 col-12 col-md-6 pb-2">
                  <label className=''>Contact Person<span className="requiredStar">*</span></label>
                  <div className='row'>
                    <div className='col-lg-3 col-md-2'>
                      <SelectElement
                        defaultValue={CreateAccount.basicInfoDTO.salutation}
                        className="white p-2"
                        options={salutations}
                        key="salutation"
                        onChange={(e) =>
                          this.setBasicInfoDTO(e.target.value, "salutation")
                        }
                      />
                    </div>
                    <div className='col-lg-9 col-md-10'>
                      <input
                        type="text"
                        placeholder="Contact Person"
                        value={CreateAccount.basicInfoDTO.contactPerson}
                        onChange={(e) => this.setBasicInfoDTO(e.target.value, "contactPerson")}
                        className="search-input p-1 pl-4 w-100 "
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 col-md-6 pb-2">
                  <label className='activeSubscription pr-1'>Email(for notification)<span className="requiredStar">*</span></label>
                  <input
                    placeholder="Email For Notification"
                    value={CreateAccount.basicInfoDTO.email}
                    onChange={(e) => this.setBasicInfoDTO((e.target.value).toString(), "email")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />

                </div>
              </div>
              <div className='row'>
                <div className="col-lg-6 col-12 col-md-6 pb-2 pt-1">
                  <label className=''>Company <span className="requiredStar">*</span></label>
                  <input
                    placeholder="Company"
                    value={CreateAccount.basicInfoDTO.company}
                    onChange={(e) => this.setBasicInfoDTO((e.target.value).toString(), "company")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
                <div className="col-lg-6 col-12 col-md-6 pb-2">
                  <label className=''>Address<span className="requiredStar">*</span></label>
                  <input
                    placeholder="Address"
                    value={CreateAccount.basicInfoDTO.address}
                    onChange={(e) => this.setBasicInfoDTO((e.target.value).toString(), "address")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-6 col-12 col-md-6 col-md-4 ">
                  <label className="pt-2 ">Telphone <span className="requiredStar">*</span></label>
                  <SelectElement
                    defaultValue={CreateAccount.basicInfoDTO.telCode}
                    className=""
                    options={codes}
                    onChange={(e) =>
                      this.setBasicInfoDTO(Number(e.target.value), "telCode")
                    }
                  />
                </div>
                <div className="col-lg-6 col-12 col-md-6 pb-2">
                  <label className='pt-2'>Tel <span className="requiredStar">*</span></label>
                  <input
                    placeholder="Tel"
                    value={CreateAccount.basicInfoDTO.tel}
                    onChange={(e) => this.setBasicInfoDTO((e.target.value), "tel")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-6 col-12 col-md-6">
                  <label className=" ">Country/Region <span className="requiredStar">*</span></label>
                  <div>
                    <SelectElement
                      defaultValue={CreateAccount.basicInfoDTO.country}
                      options={regions}
                      className="p-1"
                      onChange={(e) => this.setBasicInfoDTO((e.target.value).toString(), "country")}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12 col-md-6 pb-2">
                  <label className=''>Postal Code <span className="requiredStar">*</span></label>
                  <input
                    placeholder="Postal Code"
                    value={CreateAccount.basicInfoDTO.postalCode}
                    onChange={(e) => this.setBasicInfoDTO((e.target.value), "postalCode")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
              </div>
            </div>
            <div className='row card p-3'>
              <h2 className="heading">Subscription Info</h2>
              <div className='row'>
                <div className="col-lg-4 col-12 col-md-4 pb-2">
                  <label className='activeSubscription'>Active Subscription<span className="requiredStar">*</span></label>
                  {activeSubscription.length > 0 && <SelectElement
                    defaultValue={CreateAccount.subscriptionInfoDTO.pkg}
                    options={activeSubscription}
                    className="p-1"
                    onChange={(e) => this.setPkgSubscriptionInfoDTO(e.target.value, "pkg")}
                  />}
                </div>

                <div className="col-lg-4 col-12 col-md-4 pb-2">
                  <div className='basisflex'>
                    <span className="toLabel pb-4 labels">FROM <span className="requiredStar">*</span></span>
                    <div className="pb-2 pt-2 dates">
                      <DatePickerElement
                        minDate={new Date()}
                        onChange={this.onfromDateChange}
                        className="w-100"
                        value={fromDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 col-md-4 pb-2">
                  <div className='basisflex'>
                    <span className="toLabel pb-4 labels">TO <span className="requiredStar">*</span></span>
                    <div className="pb-2 pt-2 dates">
                      <DatePickerElement
                        minDate={fromDate}
                        onChange={this.ontoDateChange}
                        className="w-100"
                        value={toDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-9  pb-2">
                  {pkgDecription ? pkgDecription : ""}
                </div>
              </div>
              <div className='row pb-2'>
                <div className="col-lg-4 col-12 col-md-4 ">
                  <label className=''>Subscription Fee<span className="requiredStar">*</span></label>
                  <input
                    placeholder="Subscription Fee"
                    value={CreateAccount.subscriptionInfoDTO.subscriptionFee}
                    onChange={(e) => this.setSubscriptionChange(Number(e.target.value), "subscriptionFee")}
                    type="Number"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
                <div className="col-lg-4 col-6 col-md-4 font-1">
                  <label></label>
                  <div className='mt-10'>GST({currentGst}%):<b>${gst}</b></div>
                </div>
                <div className="col-lg-4 col-6 col-md-4 font-1">
                  <label></label>
                  <div className='mt-10'>Total(fee+GST):<b>${total}</b></div>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-4 col-6 col-md-4 ">
                  <label className=''>Article Retrival Fee <span className="requiredStar">*</span></label>
                  <input
                    placeholder="Article Retrival Fee"
                    value={CreateAccount.subscriptionInfoDTO.articleRetrievalFee === "NA" ? "" : CreateAccount.subscriptionInfoDTO.articleRetrievalFee}
                    onChange={(e) => this.onArticleRetrivalFeeChange((e.target.value), "articleRetrievalFee")}
                    type="text"
                    disabled={CreateAccount.subscriptionInfoDTO.disallowArticle ? true : false}
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
                <div className="col-lg-8 col-6 col-md-6 pb-2">
                  <span className='nl_magazine_search'>
                    <Checkbox checked={CreateAccount.subscriptionInfoDTO.disallowArticle} onChange={(e) =>
                      this.setSubscriptionInfoDTO(e.target.checked, "disallowArticle")}
                    />
                    <span className="margin-left-35">Disallow Article Access </span>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-4 col-6 col-md-4 col-sm-6 pb-2">
                  <label className=''>SPH PDF retrival Fee <span className="requiredStar">*</span></label>
                  <input
                    placeholder="SPH PDF retrival Fee"
                    value={CreateAccount.subscriptionInfoDTO.sphPdfRetrievalFee === "NA" ? "" : CreateAccount.subscriptionInfoDTO.sphPdfRetrievalFee}
                    onChange={(e) => this.onChangeSphPdfRetrievalFee(e.target.value, "sphPdfRetrievalFee")}
                    type="text"
                    disabled={CreateAccount.subscriptionInfoDTO.disallowPdf ? true : false}
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
                <div className="col-lg-4 col-6 col-md-6  col-sm-6 pb-2">
                  <span className='nl_magazine_search'>
                    <Checkbox checked={CreateAccount.subscriptionInfoDTO.disallowPdf} onChange={(e) =>
                      this.setSubscriptionInfoDTO(e.target.checked, "disallowPdf")}
                    />
                    <span className="margin-left-35">Disallow PDF Access </span>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-4 col-6 col-md-4 pb-2">
                  <label className=''>SPHM PDF retrival Fee<span className="requiredStar">*</span></label>
                  <input
                    placeholder="SPHM PDF retrival Fee"
                    value={CreateAccount.subscriptionInfoDTO.sphmPdfRetrievalFee}
                    onChange={(e) => this.setSubscriptionInfoDTO(Number(e.target.value), "sphmPdfRetrievalFee")}
                    type="Number"
                    className="search-input p-1 pl-4 w-100 "
                    disabled={CreateAccount.subscriptionInfoDTO.allowMagazineSearch ? false : true}
                  />
                </div>
                <div className="col-lg-4 col-6 col-md-6 pb-2">
                  <span className='nl_magazine_search'>
                    <Checkbox checked={CreateAccount.subscriptionInfoDTO.allowMagazineSearch} onChange={(e) =>
                      this.setSubscriptionInfoDTO(e.target.checked, "allowMagazineSearch")}
                    />
                    <span className="margin-left-35">Allow Magazine Search </span>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div> <label className=' nl_status' >Status <span className="requiredStar">*</span></label></div>
                {subscriptionStatus && subscriptionStatus.map((p) => (
                  <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                    <Checkbox key={p.key} labelValue={p.value} checked={p.value === CreateAccount.subscriptionInfoDTO.subStatus}
                      onChange={(e) =>
                        this.setSubscriptionInfoDTO(p.value, "subStatus")}
                    />
                    <span className="margin-left-35">{p.key} </span>

                  </div>))}
              </div>
            </div>
            <div className='row card p-3'>
              <h2 className="heading">Payment Info</h2>
              <div className='row'>

                <label className=' nl_status ' >Pay By <span className="requiredStar">*</span></label>
                {paymentModeArr && paymentModeArr.map((p) => (
                  <div className=" d-inline-block pr-3 pl-3 pb-2" key={p.key}>
                    <Checkbox key={p.key} labelValue={p.value} checked={Number(p.value) === CreateAccount.paymentInfoDTO.paymentMode}
                      onChange={(e) =>
                        this.setPaymentInfoDTO(Number(p.value), "paymentMode")}
                    />
                    <span className="margin-left-35">{p.key} </span>
                  </div>))}

              </div>
              <div className='row'>
                <div className="col-lg-4  pb-2 mr-2">
                  <label className=' ' >Cheque/Purchase Order/Bank Draft No/Others</label>
                </div>
                <div className="col-lg-4  pb-2">
                  <input
                    placeholder=""
                    value={CreateAccount.paymentInfoDTO.paymentRef}
                    onChange={(e) => this.setPaymentInfoDTO((e.target.value), "paymentRef")}
                    type="text"
                    className="search-input p-1 pl-4 w-100 "
                  />
                </div>
              </div>
              <div >
                <div className="text-right pt-3 pb-5 button-res">

                  <ButtonElement
                    type="submit"
                    variant="contained"
                    btnType="background-orange button-label"
                    className="d-inline-block mr-2  mb-2"
                    onClick={(e) => this.onSubmit(e)}
                    disabled={btnDisable}
                  >
                    <span className="button-label">CREATE</span>
                  </ButtonElement>

                  <ButtonElement
                    type="submit"
                    variant="contained"
                    btnType="background-orange button-label"
                    className="d-inline-block mr-2  mb-2"
                    onClick={(e) => this.createAndSendEmail(e)}
                    disabled={sendBtnDisable}
                  >
                    <span className="button-label">CREATE & SEND VALIDATION EMAIL</span>
                  </ButtonElement>
                  <ButtonElement
                    variant="contained"
                    btnType="background-orange button-label"
                    className="d-inline-block mr-2 mb-2"
                    onClick={() => this.onReset()}
                  >
                    <span className="button-label">Reset</span>
                  </ButtonElement>
                </div>
              </div>
            </div>

          </form>
          <Recaptcha
            size="invisible"
            ref={(ref) => (this.recaptcha = ref)}
            onResolved={this.onResolved}
            sitekey={RECAPTCHA_SITE_KEY}
          />
          <ToastContainer />
        </>
      </div>
    )
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.adminReducer.isLoading,
  infoData: state.adminReducer.infoData,
  telCodes: state.adminReducer.telCodes,
  createUserAccountRes: state.adminReducer.createUserAccountRes,
  subscriptionStatus: state.adminReducer.subscriptionStatus,
  pkgInfo: state.adminReducer.pkgInfo,
  calculateFeeRes: state.adminReducer.calculateFeeRes,
  calculateFeeResError: state.adminReducer.calculateFeeResError,
  createUserAccountResError: state.adminReducer.createUserAccountResError
});

export default connect(mapDispatchStatetoProps, {
  startLoader,
  getInfoData,
  getPreloads,
  createUserAccount,
  getSubscriptionStatus,
  calculateFeeByPkg,
  calculateFeeBySubscriptionFee,
  clearAdminError
})(CreateAccount);
