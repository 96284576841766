import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable, DatePickerElement, Spinner, Paginationnextpre } from "../../UI/index";
// import Spinner from "react-bootstrap/Spinner";
import { getCookies, getDate, getPageviewobj, reverseDate } from "../../../helpers/common";
import { getUserUsage, getUserUsageSearch, startLoader } from "../../../redux/actions/userAction";
import { getUserDetails } from '../../../redux/actions/loginAction';
import { pageViewDatagtm } from '../../../redux/actions/applicationsAction';
import "./css/usage.scss";
import { decodeToken } from "react-jwt";

class Usage extends Component {
  state = {
    startDate: getDate(true,2),
    endDate: new Date(),
    maxDate: new Date(),
    currentPage: 1,
    minDate: getDate(true, 2),
    username: "",
  };

  async componentDidMount() {
    // await this.props.getUserDetails()
    const id_token = getCookies("id_token");
    const myDecodedToken = decodeToken(id_token);
    this.props.startLoader();
    this.setState({
      username: myDecodedToken?.sub
    })
    this.props.getUserUsage(myDecodedToken?.sub);
    let endD = this.state.endDate.toLocaleDateString('en-GB');
    let startd = this.state.startDate.toLocaleDateString('en-GB');
    this.props.getUserUsageSearch({
      startDate: reverseDate(startd.replaceAll("/", "-")),
      endDate: reverseDate(endD.replaceAll("/", "-")),
      userId: myDecodedToken?.sub
    });
    this.setPageViewcall(this.props.searchObject,myDecodedToken);
}
setPageViewcall=(searchObject,myDecodedToken)=>{
  let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
  let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let url=window.location.href;
    let obj= getPageviewobj(url,"user account","Usage",user_status,"","","",id);
    this.props.pageViewDatagtm(obj,searchObject);
  }

  onStartDateChange = (date) => {
    this.props.startLoader("second");
    this.setState({
      startDate: date
    });

    let startd = date.toLocaleDateString('en-GB');
    let endD = this.state.endDate.toLocaleDateString('en-GB')
    this.props.getUserUsageSearch({
      startDate: reverseDate(startd.replaceAll("/", "-")),
      endDate: reverseDate(endD.replaceAll("/", "-")),
      userId: this.state.username
    });
  };

  onEndDateChange = (date) => {
    this.props.startLoader("second");
    this.setState({
      endDate: date
    });
    let startd = this.state.startDate.toLocaleDateString('en-GB');
    let endD = date.toLocaleDateString('en-GB')
    this.props.getUserUsageSearch({
      startDate: reverseDate(startd.replaceAll("/", "-")),
      endDate: reverseDate(endD.replaceAll("/", "-")),
      userId: this.state.username
    });
  };

  onPaginate = async (pageNo) => {
    const { startDate, endDate } = this.state;
    await this.props.startLoader("second");
    this.setState({
      currentPage: pageNo
    })
    let startd = startDate.toLocaleDateString('en-GB');
    let endD = endDate.toLocaleDateString('en-GB')
    await this.props.getUserUsageSearch({
      startDate: reverseDate(startd.replaceAll("/", "-")),
      endDate: reverseDate(endD.replaceAll("/", "-")),
      userId: this.state.username
    }, pageNo);

  }

  render() {
    const { usageDetails, searchUsageHistory, isloading, userReducer } = this.props;
    const { startDate, endDate, currentPage, maxDate } = this.state;
    const isLoading2 = userReducer?.isLoading2

    return (
      <>
        <div className="usage-report">
          <div className="usage-container">
            <h4 className="heading">Usage</h4>
            {isloading ? (
              <div className="text-center">
                <Spinner primary={true} />
                {/* <Spinner animation="border" variant="primary" /> */}
              </div>
            ) : (
              <>
                {usageDetails && !usageDetails?.errors && !usageDetails[0]?.msg ? (
                  <DataTable bgFlag={true} columns={usageDetails[0]} data={usageDetails} />
                ) : (
                  <h5 className="text-center pb-2 pt-2">No data found.</h5>
                )}
              </>
            )}
          </div>
        </div>
        <div className="usage-report">
          <div className="usage-container">
            <div className="col-md-12 p-0 pb-3">
              <div className="col-md-6 d-inline-block col-12 p-0">
                <h4 className="heading">Access Date</h4>
              </div>
              <div className="col-md-6 d-inline-block col-12 p-0">
                <div className="toLabel d-sm-none">
                  &nbsp;&nbsp;FROM&nbsp;&nbsp;
                </div>
                <div className="col-md-5 d-inline-block pb-2 p-0 pt-2">
                  <DatePickerElement
                    onChange={this.onStartDateChange}
                    className="w-100"
                    // minDate={minDate}
                    maxDate={maxDate}
                    // calendarIcon={<img src={CalendarIcon} alt="calendarIcon" />}
                    value={startDate}
                  />
                </div>
                <div className="toLabel d-sm-inline-block">
                  &nbsp;&nbsp;TO &nbsp;&nbsp;
                </div>
                <div className="d-inline-block col-md-5 p-0 pb-2 pt-2">
                  <DatePickerElement
                    className="w-100"
                    minDate={startDate}
                    onChange={this.onEndDateChange}
                    value={endDate}
                    maxDate={maxDate}
                  />
                </div>
              </div>
            </div>
            {/* {searchUsageHistory && searchUsageHistory.usageDetail ? ( */}
            <>

              {searchUsageHistory && searchUsageHistory?.usageDetail &&
                <>
                  <h4 className="heading">Search Result</h4>

                  <Paginationnextpre
                    numPagination={true}
                    currentPage={currentPage}
                    currentPageSize={10}
                    total={searchUsageHistory && searchUsageHistory.totalCount}
                    onPagination={this.onPaginate}
                  /> </>}
              {isLoading2 ? (
                <div className="text-center">
                  <Spinner primary={true} />
                  {/* <Spinner animation="border" variant="primary" /> */}
                </div>
              ) : (
                <>
                  {
                    searchUsageHistory && searchUsageHistory?.usageDetail ? <>


                      <DataTable bgFlag={true} columns={searchUsageHistory?.usageDetail && searchUsageHistory?.usageDetail[0]} data={this.props.searchUsageHistory.usageDetail} />
                      {/* <Pagination
                      currentPage={this.state.currentPage}
                      currentPageSize={searchUsageHistory.size}
                      total={searchUsageHistory.totalCount}
                      onPagination={this.onPaginate} /> */}

                      {/* <Paginationnextpre
                          numPagination={true}
                          currentPage={currentPage}
                          currentPageSize={10}
                          total={searchUsageHistory && searchUsageHistory.totalCount}
                          onPagination={this.onPaginate}
                        /> */}

                    </> : <h5 className="text-center">{searchUsageHistory?.usageDetail && searchUsageHistory?.usageDetail[0]?.msg}</h5>
                  }

                </>)

              }

            </>


          </div>
        </div>
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  usageDetails: state.userReducer.usage,
  searchUsageHistory: state.userReducer.usageSearch,
  isloading: state.userReducer.isLoading,
  userTokens: state.loginReducer.tokens,
});

export default connect(mapDispatchStatetoProps, {
  getUserUsage,
  getUserUsageSearch,
  startLoader,
  getUserDetails,
  pageViewDatagtm
})(Usage);
