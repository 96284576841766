import React, { Component } from 'react'
import "./adminHome.scss"
export class AdminHome extends Component {
    render() {
        return (
            <div className='container adminHome'>
                <h4>Welcome Admin user</h4></div>
        )
    }
}

export default AdminHome
