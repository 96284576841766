import React, { Component } from 'react';
import './agency.scss';
import { connect } from "react-redux";
import { ButtonElement, Modal, Spinner } from '../../../UI/index';
import { updateAgencyPublisher, getAgencyPublisher, createAgencyPublisher, startLoader, clearAdminError } from '../../../../redux/actions/adminAction';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../config/constants/index";

const initialState = {
    submit: false,
    agency: {
        publisher: '',
        description: '',
        agencyCode: '',
        captchaToken: '',
        status: '',
        id: ''
    },
    agencyKey:'',
    agencySearchResult: [],
    isEditAgency: false,
    isVisible: false,
    message: '',
    errorType: '',
    isDisable: false,
    disabled:false
}

class CreateAgencyPublisher extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
        this.recaptcha2 = React.createRef(null);
    }
    componentDidMount = async () => {
        this.setState({
            visibleCreateAgencyForm: false,
        })
        if (this.props.location.state.agencyCode) {
            let obj = {
                id: this.props.location?.state?.agencyCodeId,
                agencyCode: this.props.location?.state?.agencyCode
            }
            this.setState({
                agency: obj
            });
        }

        // For Edit Agency
        if (this.props.location.state.agencyPublisherData) {
            // console.log("edit ",this.props.location.state.agencyPublisherData)
            this.setState({
                agency: {
                    publisher: '',
                    description: '',
                    agencyCode: '',
                    id: '',
                    status: ''
                },
                isEditAgency: true
            })
            let agencyObj = {
                publisher: this.props.location.state.agencyPublisherData.publisher,
                description: this.props.location.state.agencyPublisherData.description,
                status: this.props.location.state.agencyPublisherData.status === "ACTIVE" ? 1 : 2,
                id: this.props.location?.state?.agencyPublisherData?.id,
                agencyCode: this.props.location?.state?.agencyPublisherData?.agencyCode
            }
            this.setState({
                agency: agencyObj
            })
        }
    }

    onReset = (e) => {
        let ag = {
            publisher: '',
            description: '',
            captchaToken: '',
            status: '',
            id: this.props.location?.state?.agencyCodeId,
            agencyCode: this.props.location?.state?.agencyCode,

        }
        this.setState({ agency: ag, agencyKey:Math.random() });
    }

    onSave = async (e) => {
        this.setState({
            isDisable: true
        })
        e.preventDefault()
        this.recaptcha.execute();
    }

    onResolved = async () => {
        this.props.startLoader();
        const response = this.recaptcha.getResponse();
        const { agency } = this.state
        agency.status = 1;
        agency.captchaToken = response;
        agency.publisher = agency.publisher ? agency.publisher.toString().trim() : "";
        agency.description = agency.description ? agency.description.toString().trim() : "";
        await this.props.createAgencyPublisher(agency);
        // console.log("create agency ",agency);
        let responseMessage = this.props?.agencyPublisherResponse?.data?.message || this.props?.agencyPublisherErrorResponse;
        let errorType = '';

        if (this.props?.agencyPublisherErrorResponse)
            errorType = "Error";
        else
            errorType = "Success";

        this.setState({
            isVisible: true,
            message: responseMessage,
            errorType: errorType
        })
        await this.props.clearAdminError();
    }

    onUpdate = async (e) => {
        this.setState({
            disabled:true
        })
        e.preventDefault()
        this.recaptcha2.execute();
    }

    onResolved2 = async (e) => {
        this.props.startLoader();
        const response = this.recaptcha2.getResponse();
        const { agency } = this.state;
        agency.captchaToken = response;
        agency.publisher = agency.publisher ? agency.publisher.toString().trim() : agency.publisher;
        agency.description = agency.description ? agency.description.toString().trim() : agency.description;

        await this.props.updateAgencyPublisher(agency);
        let responseMessage = this.props?.agencyPublisherUpdatedResponse?.data?.message || this.props?.agencyPublisherUpdateErrorResponse;
        let errorType = '';
        if (this.props?.agencyPublisherUpdateErrorResponse)
            errorType = "Error";
        else if (this.props?.agencyPublisherUpdatedResponse)
            errorType = "Success";

        this.setState({
            isVisible: true,
            message: responseMessage,
            errorType
        })
        await this.props.clearAdminError();
    }

    getAgencyPublisher = async (code) => {
        await this.props.getAgencyPublisher({ agencyCode: code });
        this.props.history.push({
            pathname: '/admin/agency-publisher',
            state: {
                agencyPublisherData: this.props.location.state.agencyPublisherData,
                agencyCode: this.props.location.state.agencyCode
            }
        });
    }

    onSearch = async (e) => {
        e.preventDefault()
        const searchData = {
            agency: this.state.agency.agency,
            agencyCode: this.state.agency.agencyCode,
            createDate: '',
            pageNo: 0,
            status: 1 //this.state.agency.status
        }

        await this.props.getAgency(searchData);
        this.setState({
            agencySearchResult: this.props.getAgencyResult.data.externalAgencies
        })
    }
    setFormValue = async (value, field, number = false) => {
        const { agency } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            agency[field] = regex.test(value) || value === "" ? value : agency[field];
        } else {
            agency[field] = value;
        }

        this.setState({
            agency
        });

    }
    onBack = async (e) => {
        e.preventDefault();
        const { agency } = this.state;
        await this.props.getAgencyPublisher({ agencyCode: agency.agencyCode });
        this.props.history.push({
            pathname: '/admin/agency-publisher',
            state: {
                agencyPublisherData: this.props.getAgencyPublisherResult.data,
                agencyCode: agency.agencyCode
            }
        });
    }
    closeModal = async () => {
        const { agency } = this.state;
        this.setState({
            isVisible: false,
            isDisable: false,
            disabled:false
        });
        if(this.state.errorType === "Success") {
            await this.props.getAgencyPublisher({ agencyCode: agency.agencyCode });
            this.props.history.push({
                pathname: '/admin/agency-publisher',
                state: {
                    agencyPublisherData: this.props.getAgencyPublisherResult.data,
                    agencyCode: agency.agencyCode
                }
            });
        }
    }
    render() {
        const { loading } = this.props;
        const { agency, agencyKey, errorType, isVisible, message, isDisable ,disabled} = this.state;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {isVisible && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal" >
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType == "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                <div className="container-fluid card margin-left-10" key={agencyKey}>
                    <form className=''>
                        <h3 className="heading">
                            {this.props?.location?.state?.isEditAgencyPublisher ? "Edit" : "Add"} Agency Publisher
                        </h3>
                        <div className='row'>
                            <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                <label>Publisher Name</label><span className='err'>*</span>
                                {!this.props?.location?.state?.isEditAgencyPublisher &&
                                    <input
                                        placeholder="Publisher name"
                                        value={agency.publisher}
                                        onChange={(e) => this.setFormValue(e.target.value, "publisher")}
                                        type="text"
                                        className="search-input p-1 pl-4 w-100"
                                    />
                                }
                                {this.props?.location?.state?.isEditAgencyPublisher &&
                                    <input
                                        placeholder="Publisher name"
                                        readOnly="true"
                                        value={agency.publisher}
                                        onChange={(e) => this.setFormValue(e.target.value, "publisher")}
                                        type="text"
                                        className="search-input p-1 pl-4 w-100 disabledTextBox"
                                    />
                                }
                            </div>

                            <div className="col-lg-4  col-sm-12 col-md-4 pb-2">
                                <label>Description</label><span className='err'>*</span>
                                <input
                                    placeholder="Description"
                                    value={agency.description}
                                    onChange={(e) => this.setFormValue(e.target.value, "description")}
                                    type="text"
                                    className="search-input p-1 pl-4 w-100"
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                <div className='float-right'>

                                    {!this.props.location?.state?.isEditAgencyPublisher && (
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2"
                                            onClick={(e) => this.onSave(e)}
                                            disabled={isDisable}
                                        >
                                            <span className="button-label">Save</span>
                                        </ButtonElement>
                                    )}

                                    {this.props.location?.state?.isEditAgencyPublisher && (
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2"
                                            onClick={(e) => this.onUpdate(e)}
                                            disabled={disabled}
                                        >
                                            <span className="button-label">Update</span>
                                        </ButtonElement>
                                    )}
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label"
                                        className="d-inline-block mr-2"
                                        onClick={(e) => this.onBack(e)}
                                    >
                                        <span className="button-label">Back</span>
                                    </ButtonElement>
                                    {!this.props.location?.state?.isEditAgencyPublisher && (
                                        <ButtonElement
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 mt-10"
                                            onClick={this.onReset}
                                        >
                                            <span className="button-label">Reset</span>
                                        </ButtonElement>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha2 = ref)}
                    onResolved={this.onResolved2}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>

        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    getAgencyResult: state.adminReducer.agencyResult,
    agencyPublisherResponse: state.adminReducer.agencyPublisherResponse,
    agencyPublisherErrorResponse: state.adminReducer.agencyPublisherErrorResponse,
    agencyPublisherUpdateErrorResponse: state.adminReducer.agencyPublisherUpdateErrorResponse,
    agencyPublisherUpdatedResponse: state.adminReducer.agencyPublisherUpdatedResponse,
    getAgencyPublisherResult: state.adminReducer.agencyPublisherResult,
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    createAgencyPublisher,
    updateAgencyPublisher,
    getAgencyPublisher,
    clearAdminError
})(CreateAgencyPublisher);
