import React, { Component } from 'react';
import './agencyRoyalty.scss';
import { connect } from "react-redux";
import { ButtonElement, Checkbox, SelectElement, DatePickerElement, Selectlist, Modal, Spinner } from '../../UI/index';
import { getAgenciesDdl, getAgencyRoyaltyReport, getAgencyPublisher, agencyUsage, clearAdminError, startLoader } from '../../../redux/actions/adminAction';
import { getDate, format1 } from '../../../helpers/common';
import { dowloadPdf } from '../../../helpers/pdfDowload';

// Initialize state
const initialState = {
    submit: false,
    minDate: getDate(),
    fromDate: new Date(),
    createDate: "",
    toDate: "",
    isAgencyUsageTrackingHidden: false,
    visibleAlertBox: false,
    message: '',
    errorType: '',
    agencyUsage: {
        agencyCode: "",
        company: "",
        packages: [],
        reportType: "SUMMARY",
        requestDateFrom: "",
        requestDateTo: "",
        sortBy: "a.USER_ID",
        sortOrder: "asc",
        userTypes: [],
        type: [],
        username: "",
        publisherType: [],
        format: "pdf"
    },
    userType: [],
    packages: [{
        key: '',
        value: ''
    }],
    sortBy: [],
    sortOrder: [],
    reportType: [],
    publisher: [],
    testId: '1',
    disabled: false
}

class AgencyUsages extends Component {
    state = initialState;
    componentDidMount = async () => {
        let { toDate, createDate, agencyUsage } = this.state;
        agencyUsage.username = "";
        agencyUsage.company = "";
        this.setState({ agencyUsage });
        this.onReset();
        await this.props.startLoader();
        await this.props.getAgenciesDdl();
        if (this.props.agencyInfoResult && this.props.agencyInfoResult.userTypes) {
            // User Packages
            // User Type
            let type = this.props?.agencyInfoResult?.userTypes;
            let userTypeKeys = Object.keys(type);
            let allUserType = this.props?.agencyInfoResult?.userTypes;
            let newUserTypesArr = userTypeKeys.map((usertype) => {
                return ({ key: allUserType[usertype].userTypeName, value: usertype })
            })
            let temp = this.props?.agencyInfoResult?.packages;
            let packagesValues = Object.values(temp.basePackageVO);
            let packagesKeys = Object.keys(temp.basePackageVO);
            let newPackagesArr = packagesValues.map((p, i) => {
                return ({ key: p.description, value: packagesKeys[i] })
            })
            this.setState({
                userType: newUserTypesArr,
                packages: newPackagesArr
            })
        }
        if (this.props?.agencyInfoResult?.agencies) {
            // Agency list
            var list = [];
            for (let a of this.props.agencyInfoResult.agencies) {
                list.push({
                    key: a.agency,
                    value: a.code,
                })
            }
            agencyUsage.agencyCode = list[0].value;
            this.setState({
                agenciesList: list,
                agencyUsage
            })
        }
        if (this.props.agencyInfoResult.reportType) {
            // Report type
            this.setState({
                reportType: this.props.agencyInfoResult.reportType,
                sortOrder: this.props.agencyInfoResult.sortOrder,
                sortBy: this.props.agencyInfoResult.findSortBy
                // sortBy: this.props.agencyInfoResult.pkgStatus
            })
        }

        // Set toDate and fromDate
        // let dt = new Date(createDate);
        // let dt2 = new Date(toDate);
        // let dt1 = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}` // `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
        // let dt22 = `${dt2.getFullYear()}-${dt2.getMonth() + 1}-${dt2.getDate()}`//`${dt2.getDate()}/${dt2.getMonth()+1}/${dt2.getFullYear()}`;
        // agencyUsage.requestDateFrom = dt1;
        // agencyUsage.requestDateTo = dt22;
        this.setState({
            agencyUsage
        })
    }
    onReset = (e) => {
        this.setState(initialState);
    }

    onSave = async (e) => {
        e.preventDefault();
        this.setState({
            disabled: true
        })
        await this.props.startLoader();
        const { agencyUsage } = this.state;
        this.setState({
            agencyUsage
        })
        let requestDateFromList, requestDateFromYear, requestDateFromMonth, requestDateFromDate

        if (this.state.agencyUsage.requestDateFrom) {
            requestDateFromList = this.state.agencyUsage.requestDateFrom.split('-')
            requestDateFromYear = requestDateFromList[0];
            requestDateFromMonth = requestDateFromList[1].length > 1 ? requestDateFromList[1] + '' : '0' + requestDateFromList[1];
            requestDateFromDate = requestDateFromList[2].length > 1 ? requestDateFromList[2] + '' : '0' + requestDateFromList[2];

        }
        let requestDateToList, requestDateToYear, requestDateToMonth, requestDateToDate
        if (this.state.agencyUsage.requestDateTo) {
            requestDateToList = this.state.agencyUsage.requestDateTo.split('-')
            requestDateToYear = requestDateToList[0];
            requestDateToMonth = requestDateToList[1].length > 1 ? requestDateToList[1] + '' : '0' + requestDateToList[1];
            requestDateToDate = requestDateToList[2].length > 1 ? requestDateToList[2] + '' : '0' + requestDateToList[2];
        }


        // Request body
        let dataObj = {
            "agencyCode": agencyUsage.agencyCode,
            "company": this.state.agencyUsage.company,
            "format": agencyUsage.format,
            "packages": agencyUsage.packages,
            "publisherType": agencyUsage.publisherType,
            "reportType": this.state.agencyUsage.reportType,
            // "requestDateFrom": this.state.agencyUsage.requestDateFrom, //this.state.agencyUsage.requestDateFrom
            // "requestDateTo": this.state.agencyUsage.requestDateTo, //this.state.agencyUsage.requestDateTo,
            // "requestDateFrom": requestDateFromYear + "-" + requestDateFromMonth + "-" + requestDateFromDate,
            // "requestDateTo": requestDateToYear + "-" + requestDateToMonth + "-" + requestDateToDate,
            "sortBy": agencyUsage.sortBy,
            "sortOrder": agencyUsage.sortOrder,
            "type": agencyUsage.userTypes,
            "username": agencyUsage.username,
            "dateRange": {
                "requestDateFrom": agencyUsage.requestDateFrom ? requestDateFromYear + "-" + requestDateFromMonth + "-" + requestDateFromDate : "",
                "requestDateTo": agencyUsage.requestDateTo ? requestDateToYear + "-" + requestDateToMonth + "-" + requestDateToDate : ""
            },
        }
        await this.props.agencyUsage(dataObj);
        if (this.props?.agencyUsageResult?.allUrlList && this.props?.agencyUsageResult?.allUrlList.length > 0) {
            dowloadPdf(this.props?.agencyUsageResult?.allUrlList[0])
            this.setState({
                visibleAlertBox: true,
                message: this.props?.agencyUsageResult?.message,
                errorType: "Success"
            })
        }
        if (this.props?.agencyUsageErrorResponse) {
            if (this.props?.agencyUsageErrorResponse?.errors && this.props?.agencyUsageErrorResponse?.errors.length > 0) {
                this.setState({
                    visibleAlertBox: true,
                    testId: Math.random(),
                    message: this.props?.agencyUsageErrorResponse?.errors,
                    errorType: "Error"
                })
            }
        }
        await this.props.clearAdminError();
        window.scrollTo(0, 0);
    }

    setFormSelectListValue = (data, field) => {
        let { agencyUsage } = this.state;
        let packageArr = [];
        for (let d in data) {
            if (data[d])
                packageArr.push(d);
        }
        agencyUsage[field] = packageArr;
        this.setState({
            agencyUsage
        })
    }
    setFormValue = (value, field, number = false) => {
        const { agencyUsage } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            agencyUsage[field] = regex.test(value) || value === "" ? value : agencyUsage[field];
        } else {
            agencyUsage[field] = value;
        }
        if (field !== "userTypes" && field !== "packages") {
            this.setState({
                agencyUsage,
            });
        }
    }
    onBack = async (e) => {
        let { toDate, createDate, agencyUsage } = this.state;
        if (this.props.agencyInfoResult && this.props.agencyInfoResult.userTypes) {
            let type = this.props?.agencyInfoResult?.userTypes;
            let userTypeKeys = Object.keys(type);
            let allUserType = this.props?.agencyInfoResult?.userTypes;
            let newUserTypesArr = userTypeKeys.map((usertype) => {
                return ({ key: allUserType[usertype].userTypeName, value: usertype })
            })
            let temp = this.props?.agencyInfoResult?.packages;
            let packagesValues = Object.values(temp.basePackageVO);
            let packagesKeys = Object.keys(temp.basePackageVO);
            let newPackagesArr = packagesValues.map((p, i) => {
                return ({ key: p.description, value: packagesKeys[i] })
            })
            this.setState({
                userType: newUserTypesArr,
                packages: newPackagesArr
            })
        }
        if (this.props?.agencyInfoResult?.agencies) {
            var list = [];
            for (let a of this.props.agencyInfoResult.agencies) {
                list.push({
                    key: a.agency,
                    value: a.code,
                })
            }
            agencyUsage.agencyCode = list[0].value;
            this.setState({
                agenciesList: list,
                agencyUsage
            })
        }
        if (this.props.agencyInfoResult.reportType) {
            this.setState({
                reportType: this.props.agencyInfoResult.reportType,
                sortOrder: this.props.agencyInfoResult.sortOrder,
                sortBy: this.props.agencyInfoResult.findSortBy
                // sortBy: this.props.agencyInfoResult.pkgStatus
            })
        }

        // let dt = new Date(createDate);
        // let dt2 = new Date(toDate);
        // let dt1 = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}` // `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
        // let dt22 = `${dt2.getFullYear()}-${dt2.getMonth() + 1}-${dt2.getDate()}`//`${dt2.getDate()}/${dt2.getMonth()+1}/${dt2.getFullYear()}`;
        agencyUsage.requestDateFrom = "";
        agencyUsage.requestDateTo = "";

        this.setState({
            agencyUsage,
            createDate: "",
            toDate: ""

        })

        this.setState({
            isAgencyUsageTrackingHidden: false
        })
    }
    onSubmitAgency = async (e) => {
        const { agencyUsage } = this.state;
        agencyUsage.username = "";
        agencyUsage.company = "";
        this.setState({ agencyUsage });
        await this.props.startLoader();
        await this.props.getAgencyPublisher({ agencyCode: agencyUsage.agencyCode });
        var publisherResult = [];
        for (let obj of this.props?.agencyPublisherResult?.data) {
            let data = {
                key: obj.publisher,
                value: obj.publisherCode,
            }
            publisherResult.push(data);
        }
        agencyUsage.publisher = publisherResult;
        this.setState({
            agencyUsage,
            isAgencyUsageTrackingHidden: true,
            publisher: publisherResult
        })
        await this.props.clearAdminError();
    }

    closeModal = async (e) => {
        this.setState({
            visibleAlertBox: false,
            disabled: false
        })
    }

    onFromDateChange = (date) => {
        var { agencyUsage } = this.state;
        let dt = new Date(date);
        var DD = ((dt.getDate() + "").length < 2 ? '0' : '') + dt.getDate();
        var MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
        let modifiedDate = `${dt.getFullYear()}-${MM}-${DD}`;
        agencyUsage.requestDateFrom = modifiedDate;
        this.setState({
            createDate: date
        });
        this.setfromdate(agencyUsage);
    };
    onToDateChange = (date) => {
        var { agencyUsage } = this.state;
        let dt = new Date(date);
        var DD = ((dt.getDate() + "").length < 2 ? '0' : '') + dt.getDate();
        var MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
        let modifiedDate = `${dt.getFullYear()}-${MM}-${DD}`;
        agencyUsage.requestDateTo = modifiedDate;
        this.setState({
            toDate: date
        });
        this.setfromdate(agencyUsage);
    };
    setfromdate = (agencyUsage) => {
        this.setState({
            agencyUsage
        })
    }
    render() {
        const { loading } = this.props;
        const { agenciesList,
            userType,
            packages,
            sortOrder,
            sortBy,
            reportType,
            toDate, publisher, createDate,
            agencyUsage, isAgencyUsageTrackingHidden,
            errorType, visibleAlertBox, message,
            testId, disabled } = this.state;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType == "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : message
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                <div className='container-fluid card'>
                    {!isAgencyUsageTrackingHidden &&
                        <div>
                            <div className='col-lg-12'>
                                <h3 className="heading">
                                    Select News Agency
                                </h3>
                            </div>
                            <hr />
                            <div className='top10'></div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='paddingLeft col-lg-3' >Agency : </label>
                                    {
                                        agenciesList && agenciesList.length > 0 && <SelectElement
                                            className='col-lg-6'
                                            options={agenciesList}
                                            defaultValue={agencyUsage.agencyCode}
                                            onChange={(e) => this.setFormValue(e.target.value, "agencyCode")} />
                                    }
                                </div>
                            </div>

                            <div className='top10'></div>

                            <div className='row'>
                                <div className="col-lg-9 col-md-9 pt-3 pb-5">
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType="background-orange button-label float-right"
                                        className="d-inline-block mr-2"
                                        onClick={(e) => this.onSubmitAgency(e)}
                                    >
                                        <span className="button-label">Submit</span>
                                    </ButtonElement>
                                </div>
                            </div>
                        </div>
                    }
                    {isAgencyUsageTrackingHidden &&
                        <div className='container-fluid'>
                            <div className='col-lg-12'>
                                <h3 className="heading">
                                    {agencyUsage.agencyCode} Usage Tracking
                                </h3>
                            </div>
                            <hr />
                            <form>
                                <div className='row card'>
                                    <div className='col-lg-6 top10'>
                                        <label className='paddingLeft col-lg-6' >Email or Username : </label>
                                        <input
                                            value={agencyUsage.username}
                                            onChange={(e) => this.setFormValue(e.target.value, "username")}
                                            type="text"
                                            className="search-input p-1 w-50"
                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6' >Company : </label>
                                        <input
                                            type="text"
                                            value={agencyUsage.company}
                                            onChange={(e) => this.setFormValue(e.target.value, "company")}
                                            className="search-input p-1 w-50"
                                        />
                                    </div>
                                    <div className='top10'></div>
                                </div>
                                <div className='top10'></div>
                                <div className='row card'>
                                    <div className='col-lg-12 top10' key={testId}>
                                        {
                                            userType && userType.length > 0 &&
                                            <Selectlist list={userType} label='User Type' onChange={(e) =>
                                                this.setFormSelectListValue(e.selectedSub, "userTypes")}></Selectlist>
                                        }
                                    </div>
                                </div>
                                <div className='top10'></div>
                                <div className='row card'>
                                    <div className='col-lg-12 top10' key="packageNew">
                                        {packages && packages.length > 0 && <Selectlist list={packages} label="Packages" onChange={(e) =>
                                            this.setFormSelectListValue(e.selectedSub, "packages")}></Selectlist>}
                                    </div>
                                </div>
                                <div className='top10'></div>

                                <div className='row card'>
                                    <div className='col-lg-12 top10' key="publisherType">
                                        {publisher && publisher.length > 0 && <Selectlist list={publisher} label="Publisher" onChange={(e) =>
                                            this.setFormSelectListValue(e.selectedSub, "publisherType")}></Selectlist>}
                                    </div>
                                </div>
                                <div className='top10'></div>
                                <div className='top10'></div>
                                <div className='row card'>
                                    <label className='labels col' >Request Date : </label>
                                    <div className='col-sm-6'>
                                        <div className='col'>
                                            <label className='labels ' >From: </label>
                                        </div>
                                        <div className="col">
                                            <DatePickerElement
                                                onChange={this.onFromDateChange}
                                                className="w-100"
                                                value={createDate}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='col'>
                                            <label className='labels' >To: </label>
                                        </div>
                                        <div className="col">
                                            <DatePickerElement
                                                onChange={this.onToDateChange}
                                                className="w-100"
                                                value={toDate}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className='top10'></div>
                                </div>

                                <div className='top10'></div>
                                <div className='row card'>
                                    <div className='top10'></div>
                                    <div className='col-lg-6'>
                                        <label className='paddingLeft col-lg-6' >Sort By : </label>
                                        {
                                            sortBy && sortBy.length > 0 && <SelectElement
                                                className='col-lg-6'
                                                options={sortBy}
                                                defaultValue={agencyUsage.sortBy}
                                                onChange={(e) => this.setFormValue(e.target.value, "sortBy")}
                                            />
                                        }
                                    </div>
                                    <div className='top10'></div>
                                    <div className='col-lg-12'>
                                        <label className='paddingLeft col-lg-3' >Sort Order : </label>
                                        {
                                            sortOrder.map((p) => {
                                                return <div className="col-md-4 d-inline-block p-0" key={p.key}>
                                                    <Checkbox key={p.key} labelValue={p.value}
                                                        checked={p.value === (agencyUsage.sortOrder ? agencyUsage.sortOrder : 'asc')}
                                                        onChange={(e) => this.setFormValue(e.target.getAttribute("label"), "sortOrder")}
                                                    />
                                                    <span className="margin-left-35">{p.key} </span>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='top10'></div>
                                    <div className='col-lg-12'>
                                        <label className='paddingLeft col-lg-3' >Format : </label>
                                        {/* <div className=' d-inline-block p-0 col-lg-3' >
                                            <Checkbox key="pdf1" label="pdf" labelValue="pdf" checked="pdf" />
                                        </div> */}
                                        {format1.map((p) => (
                                            <div className="col-md-4 d-inline-block p-0 pr-3" key={p.key}>
                                                <Checkbox labelValue={p.value} checked={p.value == agencyUsage.format}
                                                    onChange={(e) => this.setFormValue(p.value, "format")}
                                                />
                                                <span className="margin-left-35">{p.key} </span>

                                            </div>))}
                                    </div>
                                    <div className='top10'></div>

                                    <div className='col-lg-12'>
                                        <label className='paddingLeft col-lg-3' >Report Type : </label>
                                        {reportType.map((p) => (
                                            <div className="col-md-4 d-inline-block p-0" key={p.key}>
                                                <Checkbox key={p.key} labelValue={p.value}
                                                    checked={p.value === (agencyUsage.reportType ? agencyUsage.reportType : 'SUMMARY')}
                                                    onChange={(e) => this.setFormValue(e.target.getAttribute("label"), "reportType")}
                                                />
                                                <span className="margin-left-35">{p.key} </span>
                                            </div>))
                                        }
                                    </div>
                                    <div className='top10'></div>

                                </div>
                                <div className='top10'></div>

                                <div className='row'>
                                    <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 float-right"
                                            onClick={(e) => this.onBack(e)}
                                        >
                                            <span className="button-label">BACK</span>
                                        </ButtonElement>
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 float-right"
                                            onClick={(e) => this.onSave(e)}
                                            disabled={disabled}
                                        >
                                            <span className="button-label">SUBMIT</span>
                                        </ButtonElement>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    agencyPublisherResult: state.adminReducer.agencyPublisherResult,
    agencyInfoResult: state.adminReducer.agencyInfoResult,
    agencyUsageResult: state.adminReducer.agencyUsageResult,
    agencyUsageErrorResponse: state.adminReducer.agencyUsageErrorResponse
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getAgenciesDdl,
    getAgencyRoyaltyReport,
    getAgencyPublisher,
    agencyUsage,
    clearAdminError
})(AgencyUsages);
