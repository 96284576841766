
import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonElement, Checkbox, Selectlist, SelectElement, Modal, Spinner } from '../../UI/index';
import { getPackageConfiguration, getPacakgeByCode, clearAdminError, startLoader, getUserType, updateConfigPackage, updateSysConfig } from '../../../redux/actions/adminAction';
import './ConfigPackages.scss';
import './scroll.scss';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import { subscriberArr } from "../../../helpers/index"
import ScrollToTop from '../../UI/ScrollToTop/ScrollToTop';
const initialState = {
    submit: false,
    userPackage: '',
    configObj: {
        userPackage: ''
    },
    userType: [],
    packageData: {
        userPackage: '',
        allowedContentType: [],
        description: '',
        fee: '',
        period: '',
        periodUnit: '',
        priceArticle: 0,
        pricePdf: '',
        renew: '',
        searchService: '',
        searchTypeList: '',
        status: ''
    },
    statusList: [],
    disabledFields: [],
    requestObject: {},
    pacakgeConfiguration: {},
    userTypeList: [],
    userPackageList: [],
    formDataObject: [],
    non_subscriber: true,
    digitalTypes: [],
    searchType: [],
    searchName: [],
    message: '',
    periodArray: [{ key: 1, value: 1 }, { key: 2, value: 2 }, { key: 3, value: 3 }, { key: 4, value: 4 }, { key: 5, value: 5 }, { key: 6, value: 6 },
    { key: 7, value: 7 }, { key: 8, value: 8 }, { key: 9, value: 9 }, { key: 10, value: 10 }, { key: 11, value: 11 }, { key: 12, value: 12 }],
    periodUnitArray: [],
    renewArray: [{ key: "Yes", value: "Y" }, { key: "No", value: "N" }],
    isVisible: false,
    configData: {
        renewNotice: "",
        non_subscriber: ""
    },
    adPackage: false,
    agentFreeDays: 0,
    sysConfig: {
        sysCfg: {
            renewNotice: '',
            mustSubscribe: "",
        },
        captchaToken: ""

    },
    enablePricePdf: false,
    enablePriceArticle : false,
    errorType: "",
    periodErr: false,
    articleKey:Math.random(),
    pdfKey:Math.random(),
    btnDisabled:false,
    disabled:false
}

class ConfigPackages extends Component {
    state = initialState;
    componentDidMount = async () => {
        this.props.startLoader();
        await this.props.getUserType();
        // http://localhost:8081/config/api/info/v1/getinfo/pkg_config_sys
        this.setState({
            userPackageList: []
        })
        if (this.props?.userTypes?.userTypes) {
            // Set the data for User package list
            let userTypeKeys = Object.keys(this.props.userTypes.userTypes);
            let newUserTypesArr = userTypeKeys.map((userTypeName) => {
                return ({ value: userTypeName, key: this.props.userTypes.userTypes[userTypeName].userTypeName })
            })
            let temp = this.props?.userTypes?.packages;
            let packagesValues = Object.values(temp.basePackageVO);
            let packagesKeys = Object.keys(temp.basePackageVO);
            let newPackagesArr = packagesValues.map((p, i) => {
                return ({ key: p.pkgName, value: packagesKeys[i] })
            });
            newPackagesArr.unshift({
                key: '--- Please Select ---',
                value: ''
            })
            this.setState({
                userTypeList: newUserTypesArr,
                userPackageList: newPackagesArr
            })
        }
        if (this.props?.userTypes?.sysConfig) {
            // Set the data fpr system configuration
            const { sysConfig } = this.state;
            sysConfig.sysCfg["renewNotice"] = this.props?.userTypes?.sysConfig.renew_notice_period;
            sysConfig.sysCfg["mustSubscribe"] = this.props?.userTypes?.sysConfig.non_subscriber;
            // let agentFreeDays = this.props?.userTypes?.sysConfig?.renew_notice_period
            this.setState({
                sysConfig,
                agentFreeDays: this.props?.userTypes?.sysConfig?.free_pdf_for_agencies
            })
        }
        if (this.props?.userTypes?.pkgContType?.contentTypeResultVoMap) {
            // Set the data for digita type
            let digitalTypesKeys = Object.keys(this.props?.userTypes?.pkgContType?.contentTypeResultVoMap);
            let dataObject = [];
            let { digitalTypes } = this.setState;
            for (let d of digitalTypesKeys) {
                let data = d.split('|');
                data.map(dt => {
                    if (isNaN(dt)) {
                        if (dt)
                            dataObject.push({ key: dt, value: dt, ischecked: false })
                    }
                });
            }
            digitalTypes = dataObject;
            this.setState({
                digitalTypes
            });
        }

        if (this.props?.userTypes?.searchType && this.props?.userTypes?.searchType.length > 0) {
            // Set the data for search type
            let searchTypeData = this.props?.userTypes?.searchType;
            let dataObj = []
            searchTypeData.map(s => {
                let obj = {
                    key: s.key,
                    value: s.value,
                }
                if (s.value === 'general_search')
                    obj.ischecked = true;
                if (s.value === 'ad_search')
                    obj.checked = true;
                dataObj.push(obj)
            });
            this.setState({
                searchType: dataObj
            })
        }

        if (this.props?.userTypes?.searchName) {
            // Set the data for search name
            this.setState({
                searchName: this.props?.userTypes?.searchName
            })
        }
        if (this.props?.userTypes?.periodUnit) {
            // Set the data for period unit
            this.setState({
                periodUnitArray: this.props?.userTypes?.periodUnit
            })
        }
        if (this.props?.userTypes?.pkgStatus) {
            this.setState({
                statusList: this.props?.userTypes?.pkgStatus
            })
        }
    }

    setPricePdf = (value, field, number = false) => {
        const regex = /[0-9\b]+$/;
        if (number) {
            field = regex.test(value) || value === "" ? value : field;
        } else {
            field = value;
        }
        this.setState({
            enablePricePdf: value,
            pdfKey:"pdf"+Math.random()
        })
    }
    onSave = async (e) => {
        e.preventDefault()
        this.setState({
            submit: false,
            disabled:true
        })
        this.recaptcha.execute();
    }
    onResolved = async () => {
        let { packageData, userType, digitalTypes, searchType, submit, agentFreeDays } = this.state;

        const response = this.recaptcha.getResponse();
        if (submit) {
            return response;
        }
        await this.props.startLoader();
        // Allowd content type
        let allowedContentTypeArray = digitalTypes.filter(d => {
            if (d.ischecked) {
                return d.value;
            }
        })

        let allowedContentType = allowedContentTypeArray.map(d => d.value);

        let searchTypeArray = searchType.filter(s => {
            if (s.ischecked) {
                return s.value;
            }
        })
        let stl = searchTypeArray.map(s => s.value);
        // Updated package request body
        let requestObject = {
            "captchaToken": response,
            "allowedContentType": allowedContentType,
            "fee": packageData.fee,
            "packageFeeConfig": userType,
            "period": packageData.period,
            "periodUnit": packageData.periodUnit,
            "pkgCode": packageData.pkgCode,
            "pkgDescp": packageData.description?packageData.description.toString().trim() : packageData.description,
            "priceArticle": packageData.priceArticle,
            "pricePdf": packageData.pricePdf,
            "renew": packageData.renew,
            "searchService": packageData.searchService,
            "searchTypeList": stl,
            "status": packageData.status
        }
        if (packageData.pkgCode === "ad") {
            requestObject["systemConfigMap"] = { agentFreeDays: agentFreeDays }
        }
        this.setState({
            errorType: ""
        })
        await this.props.updateConfigPackage(requestObject);
        let msg = this.props?.updateConfigPackageResponse?.message || this.props?.updateConfigPackageError;
        let status = this.props?.updateConfigPackageError ? "Error" : "Success"
        if(msg) {
            this.setState({
                visibleAlertBox: true,
                message: msg,
                errorType: status,
            });
            await this.props.clearAdminError();
            window.scrollTo(0, 0);
        }
    }
    setFormValueCheckBox = async (value, field, number = false) => {
        let { packageData } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            packageData[field] = regex.test(value) || value === "" ? value : packageData[field];
        } else {
            if(field==="priceArticle" || field==="pricePdf" || field ==="fee" || field ==="period")
            {
                if(!isNaN(value)){
                    packageData[field] = value.trim();
                }
            }
            else
            packageData[field] = value;
        }

        this.setState({
            packageData,
            isVisible: true
        });
    }
    setConfigdata = async (value, field, number = false) => {
        // await this.props.startLoader();
        let { sysConfig } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            sysConfig.sysCfg[field] = regex.test(value) || value === "" ? value : sysConfig.sysCfg[field];
        } else {
            if(field==="renewNotice")
            {
                if(!isNaN(value)){
                    sysConfig.sysCfg[field] = value.toString().trim();
                }
            }
            else
            sysConfig.sysCfg[field] = value;
        }
        this.setState({
            sysConfig
        })
    }
    setagentFreeDays = async (value, field, number = false) => {
        // await this.props.startLoader();
        const regex = /[0-9\b]+$/;
        if (number) {
            [field] = regex.test(value) || value === "" ? value : [field];
        } else {
            [field] = value;
        }
        this.setState({
            agentFreeDays: value
        })
    }

    setFormValue = async (value, field, number = false) => {
        await this.props.startLoader();
        let { configObj, formDataObject, userType, packageData, digitalTypes, searchName, searchType } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            packageData[field] = regex.test(value) || value === "" ? value : packageData[field];
        } else {
            packageData[field] = value;
        }

        this.setState({
            packageData,
            isVisible: true,
            formDataObject
        });
        this.setState({
            disabledFields: this.props?.userTypes?.packages?.basePackageVO[value]?.disabledFields
        })
        let dFields = this.props?.userTypes?.packages?.basePackageVO[value]?.disabledFields
        if ((dFields && dFields.length > 0 && dFields.includes("pricePdf"))) {
            this.setState({
                enablePricePdf: false
            })
        } else {
            this.setState({
                enablePricePdf: true
            })
        }

        await this.props.getPacakgeByCode(value);
        if (this.props?.getPackageByCodeResponse) {

            formDataObject = this.props?.getPackageByCodeResponse;
            if (formDataObject['userType'] && formDataObject['userType'].length > 0) {
                userType = formDataObject['userType']
                userType = userType.map((
                    {userTypeTb : pkg,
                    ...rest}
                )=>({
                    pkg,
                    ...rest
                }))
            } else {
                userType = []
            }
            let packageName = configObj.userPackage || packageData.userPackage
            if (packageName === "ad") {
                this.setState({
                    adPackage: true
                })
            } else {
                this.setState({
                    adPackage: false
                })
            }
            packageData = formDataObject[packageName];
            packageData.pricePdf = formDataObject[packageName].pricePdf === null ? "" : formDataObject[packageName].pricePdf
            packageData.priceArticle = formDataObject[packageName].priceArticle === null ? "" : formDataObject[packageName].priceArticle
            packageData.fee = formDataObject[packageName].fee === null ? "" : formDataObject[packageName].fee
            // packageData.period = formDataObject[packageName].period === null || formDataObject[packageName].period == "" ? "" : formDataObject[packageName].period
            packageData.period = (formDataObject[packageName].period && formDataObject[packageName].period != "") ? formDataObject[packageName].period : ""

            packageData.status = (formDataObject[packageName].status > -1 && formDataObject[packageName].status !== "") ? formDataObject[packageName].status : ""
            packageData.pkgDescp = (formDataObject[packageName].description && formDataObject[packageName].description !== "") ? formDataObject[packageName].description : ""
            packageData.pkgCode = (formDataObject[packageName].pkgCode && formDataObject[packageName].pkgCode !== "") ? formDataObject[packageName].pkgCode : ""

            packageData.userPackage = packageName;
            let contentType = packageData?.allowedContentType;
            if (contentType) {
                digitalTypes.map(digitalType => {
                    if (contentType.includes(digitalType.value)) {
                        digitalType.ischecked = true;
                        return digitalType;
                    }
                    else {
                        digitalType.ischecked = false;
                        return digitalType;
                    }
                })
            }

            if (packageData?.searchService) {
                let searchService = packageData?.searchService
                searchName.map(sName => {
                    if (searchService.includes(sName.value)) {
                        sName.ischecked = true;
                        return sName;
                    }
                    else {
                        sName.ischecked = false;
                        return sName;
                    }
                })
            }
            // if (packageData?.searchTypeList) {
                let searchService = packageData?.searchTypeList
                searchType.map(sName => {
                    if (searchService.includes(sName.value)) {
                        sName.ischecked = true;
                        return sName;
                    }
                    else {
                        sName.ischecked = false;
                        return sName;
                    }
                })
            this.setState({
                formDataObject,
                userType,
                packageData,
                digitalTypes,
                searchName,
                searchType
            });
        }
    }
    // User type input fields
    setUserTypeFormValue = async (e, i) => {
        let { userType } = this.state;
        if (isNaN(e.target.value)===false) {
            userType[i].fee = e.target.value?e.target.value.trim():e.target.value;
        }

        this.setState({
            userType
        });
    }
    // Redirect to subscription category
    onCategory = async (e) => {
        this.props.history.push('/admin/subscriptionCategory');
    }
    closeModal = async (e) => {
        this.setState({
            isCloseAgencyPublisher: false,
            visibleAlertBox: false,
            btnDisabled:false,
            disabled:false
        })
    }
    setFormSelectListValue = (data, field) => {
        let { digitalTypes } = this.state;
        let digitalTypesArr = [];
        for (let d in data) {
            if (data[d])
                digitalTypesArr.push(d);
        }
        digitalTypes[field] = digitalTypesArr;
        this.setState({
            digitalTypes
        })
    }
    setFormSelectListSearchTypeValue = (data, field) => {
        let { searchType } = this.state;
        let searchTypeArr = [];
        for (let d in data) {
            if (data[d])
                searchTypeArr.push(d);
        }
        searchType[field] = searchTypeArr;
        this.setState({
            searchType
        })
    }
    submit = async (e) => {
        e.preventDefault()
        const { sysConfig } = this.state
        this.setState({
            submit: true,
            btnDisabled:true
        })
        let captchaToken = await this.recaptcha.execute()
        sysConfig.captchaToken = captchaToken
        if (sysConfig.sysCfg["renewNotice"] === "") {
            this.setState({
                visibleAlertBox: true,
                message: "Renew Notice Period is required",
                errorType: "Error",
            });
            return
        }
        await this.props.updateSysConfig(sysConfig);
        var msg = this.props?.updatedSysConfigRes?.message || this.props?.updatedSysConfigErr;
        let status = (this.props?.updatedSysConfigErr || this.props?.updatedSysConfigRes?.error) ? "Error" : "Success"
        if(this.props?.updatedSysConfigRes?.error){
            msg = this.props?.updatedSysConfigRes?.message;
        }
        if(msg) {
            this.setState({
                visibleAlertBox: true,
                message: msg,
                errorType: status,
            });
        }
        await this.props.clearAdminError();
    }
    validationRules = (period, length) => {
        if (period.length > length) {
            return `Max ${length} digit allowed.`
        }
    }
    setPriceArticle = (value, field, number = false) => {
        const regex = /[0-9\b]+$/;
        if (number) {
            field = regex.test(value) || value === "" ? value : field;
        } else {
            field = value;
        }
        this.setState({
            enablePriceArticle: value,
            articleKey:"article"+Math.random()
        })
    }
    render() {
        let { digitalTypes, disabledFields, userPackageList, enablePriceArticle, statusList,
            isVisible, userType, searchType, searchName, renewArray, periodUnitArray, message,
            packageData, visibleAlertBox, errorType, configObj, formDataObject, adPackage,
            agentFreeDays, sysConfig, enablePricePdf,btnDisabled,disabled } = this.state;
        let { loading } = this.props;
        return (
            <>
                {visibleAlertBox && (
                    <Modal onClick={() => this.closeModal()}  customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType === "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>

                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}

                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }

                <div className='card margin-left-0' >
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className="heading">
                                Package Configuration
                            </h3>
                        </div>
                    </div>
                    <div className='row card m-1'>
                        <div className='col-lg-12 mt-1'>
                            <label className=' paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Renew Notice Period:<span className="err">*</span></label>
                            <input
                                placeholder="Renew Notice Period"
                                value={sysConfig.sysCfg["renewNotice"]}
                                // id={userType[i].userType}
                                // min="0"
                                // max="99"
                                // step="1"
                                type="text"
                                // maxLength="2"
                                onPaste={(e)=>{e.preventDefault(); return false}}
                                onChange={(e) => this.setConfigdata(e.target.value, "renewNotice")}
                                className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                            />&nbsp;Month(s)
                            {/* <div className="periodErr">
                                <span className="error-message">
                                    {this.validationRules(
                                        sysConfig.sysCfg["renewNotice"],
                                        2
                                    )}
                                </span>
                            </div> */}
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <label className="float-left  col-lg-3  col-md-12 col-sm-12 col-12 mt-3" >Non Subscriber:<span className="err">*</span> </label>
                                {subscriberArr && subscriberArr.length > 0 && subscriberArr.map((p) => (
                                    <div className="col-lg-4 float-left">
                                        <Checkbox key={p.key} labelValue={p.value}
                                            checked={p.value === sysConfig.sysCfg["mustSubscribe"]}
                                            onChange={(e) => this.setConfigdata(p.value, "mustSubscribe")} />
                                        <span className="margin-left-35">{p.key} </span>
                                    </div>))
                                }
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-lg-12'>
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange button-label"
                                    className="d-inline-block mr-2 float-right"
                                    onClick={(e) => this.submit(e)}
                                    disabled={btnDisabled}
                                >
                                    <span className="button-label">SUBMIT</span>
                                </ButtonElement>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-lg-12'>
                            <ButtonElement
                                type="submit"
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={(e) => this.onCategory(e)}
                            >
                                <span className="button-label">Add Category</span>
                            </ButtonElement>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-lg-6'>
                            <label className='paddingLeft col-lg-4' >User Package : </label>
                            {
                                userPackageList && userPackageList.length > 0 && <SelectElement
                                    className='col-lg-6'
                                    options={userPackageList}
                                    onChange={(e) => this.setFormValue(e.target.value, "userPackage")} />
                            }
                        </div>
                    </div>
                    <hr />

                    {isVisible && <div>
                        {
                            adPackage &&
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Free Pdf for Agencies: </label>
                                    <input
                                        placeholder="Free Pdf for Agencies"
                                        value={agentFreeDays}
                                        onChange={(e) => this.setagentFreeDays(e.target.value, "agentFreeDays")}
                                        type="number"
                                        // disabled={disabledFields && disabledFields.length > 0 && disabledFields.includes("pricePdf")}
                                        className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                    />&nbsp;Day(s)
                                </div>
                            </div>}
                            <div className='card padding10'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        {
                                            digitalTypes && digitalTypes.length > 0 &&
                                            <Selectlist list={digitalTypes} label='Allowed content type:'
                                                onChange={(e) =>
                                                    this.setFormSelectListValue(e.selectedSub, "allowedContentType")}></Selectlist>
                                        }
                                    </div>
                                </div>
                                <div className='top15'></div>

                                <div className='row'>
                                    <div className='col-lg-12'>
                                        {/* <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Search Type List: </label> */}
                                        {
                                            searchType && searchType.length > 0 &&
                                            <Selectlist list={searchType} label='Search Type List:'
                                                onChange={(e) =>
                                                    this.setFormSelectListSearchTypeValue(e.selectedSub, "searchTypeList")}></Selectlist>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='card padding10'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Description:<span className='err'>*</span></label>
                                        <input
                                            placeholder="Description"
                                            value={packageData.description ? packageData.description : formDataObject[configObj.userPackage]?.description}
                                            disabled={disabledFields && disabledFields.length > 0 && disabledFields.includes("description")}
                                            onChange={(e) => this.setFormValueCheckBox(e.target.value, "description")}
                                            type="text"
                                            className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='labels top15 paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Fee:<span className='err'>*</span> </label>
                                        <input
                                            placeholder="Fee"
                                            value={packageData.fee > -1 ? packageData.fee : formDataObject[configObj.userPackage]?.fee}
                                            disabled={disabledFields && disabledFields.length > 0 && disabledFields.includes("fee")}
                                            onChange={(e) => this.setFormValueCheckBox(e.target.value, "fee")}
                                            type="text"
                                            onPaste={(e)=>{e.preventDefault(); return false}}
                                            className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='labels top15 paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Period: <span className='err'>*</span></label>
                                        <input
                                            placeholder="Period"
                                            value={packageData.period}
                                            // value={packageData.period ? packageData.period : formDataObject[configObj.userPackage]?.period}
                                            onChange={(e) => this.setFormValueCheckBox(e.target.value, "period")}
                                            disabled={disabledFields && disabledFields.length > 0 && disabledFields.includes("period")}
                                            type="text"
                                            onPaste={(e)=>{e.preventDefault(); return false}}
                                            maxLength="2"
                                            className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                        />
                                        {/* <div className="periodErr">
                                            <span className="error-message">
                                                {this.validationRules(
                                                    packageData.period,
                                                    2
                                                )}
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='labels top15 paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Price Article: </label>
                                        <input
                                            placeholder="Price Article"
                                            value={packageData.priceArticle > -1 ? packageData.priceArticle : formDataObject[configObj.userPackage]?.priceArticle}
                                            onChange={(e) => this.setFormValueCheckBox(e.target.value, "priceArticle")}
                                            type="text"
                                            onPaste={(e)=>{e.preventDefault(); return false}}
                                            disabled={disabledFields && disabledFields.length > 0 && disabledFields.includes("priceArticle") && !enablePriceArticle}
                                            className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                            <label className='labels top15 paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Price Pdf: </label>
                                            <input
                                                placeholder="Price Pdf"
                                                value={packageData.pricePdf > -1 ? packageData.pricePdf : formDataObject[configObj.userPackage]?.pricePdf}
                                                onChange={(e) => this.setFormValueCheckBox(e.target.value, "pricePdf")}
                                                type="text"
                                                onPaste={(e)=>{e.preventDefault(); return false}}
                                                disabled={disabledFields && disabledFields.length > 0 && disabledFields.includes("pricePdf") && !enablePricePdf}
                                                className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                            />
                                        </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-lg-12'>
                                            <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >Price Pdf: </label>
                                            <Checkbox label="Enable Pdf Price" checked={enablePricePdf}
                                                onChange={(e) => this.setPricePdf(e.target.checked, "enablePricePdf")}
                                                className="col-lg-3 mt-2" id="enable-pdf-price"
                                            />
                                        </div>
                                </div> */}
                                <div className='row'>
                                <div className='col-lg-12'>
                                    <label className="float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3" >{" "} </label>

                                    {/* if({disabledFields && disabledFields.length > 0 && disabledFields.includes("priceArticle")}) */}
                                    {
                                            disabledFields && disabledFields.length > 0 && disabledFields.includes("priceArticle") ?
                                            <div className="col-lg-4 labels float-left"  key={this.state.articleKey}>
                                            <Checkbox checked={enablePriceArticle}
                                                onChange={(e) => this.setPriceArticle(e.target.checked, "enablePriceArticle")}
                                                className="col-lg-3 mt-2" id="enable-article-price"
                                            />
                                            <label className="margin-left-35">Enable Price Article </label>
                                        </div> : ""
                                    }

                                    {/* ({disabledFields && disabledFields.length > 0 && disabledFields.includes("pricePdf")})  */}

                                        {
                                            (disabledFields && disabledFields.length > 0 && disabledFields.includes("pricePdf")) ?
                                            <div className="col-lg-4 labels float-left">
                                            <Checkbox checked={enablePricePdf} key={this.state.pdfKey}
                                                onChange={(e) => this.setPricePdf(e.target.checked, "enablePricePdf")}
                                                className="col-lg-3 mt-2" id="enable-pdf-price"
                                            />
                                            <label className="margin-left-35">Enable Pdf Price </label>
                                        </div> : ""
                                        }




                                </div>
                            </div>
                                {/* <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='labels paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' > {' '} </label>
                                        {
                                        <div >
                                            <Checkbox label="Enable Pdf Price" checked={enablePricePdf}
                                                onChange={(e) => this.setPricePdf(e.target.checked, "enablePricePdf")}
                                                className="col-lg-3 mt-2" id="enable-pdf-price"
                                            />
                                        </div>
                                    }
                                    </div>
                                    </div> */}
                                    {/* {
                                        <div className="col-lg-3 col-4 col-md-4 ">
                                            <Checkbox label="Enable Pdf Price" checked={enablePricePdf}
                                                onChange={(e) => this.setPricePdf(e.target.checked, "enablePricePdf")}
                                                className="col-lg-3"
                                            />
                                        </div>
                                    } */}
                        </div>

                        <div className='card padding10'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className="float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3" >Period Unit: </label>
                                    {periodUnitArray && periodUnitArray.length > 0 && periodUnitArray.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value}
                                                checked={p.value == (packageData?.periodUnit)}
                                                onChange={(e) => this.setFormValueCheckBox(e.target.getAttribute("label"), "periodUnit")} />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>))
                                    }
                                </div>
                            </div>


                            <div className='row'>
                                <div className="col-lg-12">
                                    <label className="float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3">Status: </label>
                                    {
                                        statusList && statusList.length > 0 && statusList.map((p) => (
                                            <div className="col-lg-4 float-left">
                                                <Checkbox key={p.key} labelValue={p.value}
                                                    checked={p.value == (packageData?.status == 0 ? 0 : 1)}
                                                    onChange={(e) => this.setFormValueCheckBox(e.target.getAttribute("label"), "status")} />
                                                <span className="margin-left-35">{p.key} </span>
                                            </div>))
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className="float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3" >Renew: </label>
                                    {renewArray && renewArray.length > 0 && renewArray.map((p) => (
                                        <div className="col-lg-4 float-left">
                                            <Checkbox key={p.key} labelValue={p.value}
                                                checked={p.value == (packageData?.renew)}
                                                onChange={(e) => this.setFormValueCheckBox(e.target.getAttribute("label"), "renew")} />
                                            <span className="margin-left-35">{p.key} </span>

                                        </div>))
                                    }
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='float-left labels paddingLeft col-lg-3  col-md-12 col-sm-12 col-12 mt-3' >Search Service: </label>
                                    {
                                        searchName && searchName.length > 0 && searchName.map((p) => (
                                            <div className="d-inline-block pr-3 pl-3 pb-1">
                                                <Checkbox key={p.key} labelValue={p.value}
                                                    checked={p.value == (packageData?.searchService)}
                                                    onChange={(e) => this.setFormValueCheckBox(e.target.getAttribute("label"), "searchService")} />
                                                <span className="margin-left-35">{p.key} </span>
                                            </div>))
                                    }
                                </div>
                            </div>
                        </div>
                        {userType && userType.length > 0 &&<div className='card padding10'>
                            {
                                userType && userType.length > 0 &&
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >
                                            <h3>Category</h3>
                                        <hr></hr>
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                userType && userType.length > 0 && userType.map((u, i) =>
                                    <div className='row'>
                                        {i!==0?<div className='top15 blockdiv'>{" "}</div>:''}
                                        <div className='col-lg-12'>
                                            <label className='paddingLeft col-lg-3 col-md-12 col-sm-12 col-10' >{u.pkg}: </label>
                                            <input
                                                // placeholder={userType[i].userType}
                                                value={userType[i].fee}
                                                id={userType[i].userType}
                                                onChange={(e) => this.setUserTypeFormValue(e, i)}
                                                type="text"
                                                onPaste={(e)=>{e.preventDefault(); return false}}
                                                className="search-input p-1 top5 col-lg-3 col-md-10 col-sm-10 col-10"
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        }
                        <div className='top10'></div>
                        <div className='row'>
                            <div className="col-lg-12 col-md-12 pt-3 pb-5 margin-left-15">
                                <ButtonElement
                                    type="submit"
                                    variant="contained"
                                    btnType="background-orange float-right button-label"
                                    className="d-inline-block mr-2"
                                    onClick={(e) => this.onSave(e)}
                                    disabled={disabled}
                                >
                                    <span className="button-label">Update</span>
                                </ButtonElement>
                            </div>
                        </div>
                        <div className='top10'></div>
                    </div>
                    }
                    <div className='top10'></div>
                    <div>
                        <Recaptcha
                            size="invisible"
                            ref={(ref) => (this.recaptcha = ref)}
                            onResolved={this.onResolved}
                            sitekey={RECAPTCHA_SITE_KEY}
                        />
                    </div>

                </div>
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    userTypes: state.adminReducer.userTypes,
    userTypesError: state.adminReducer.userTypesError,
    getPackageByCodeResponse: state.adminReducer.getPackageByCodeResponse,
    getPackageByCodeError: state.adminReducer.getPackageByCodeError,
    updateConfigPackageResponse: state.adminReducer.updateConfigPackageResponse,
    updateConfigPackageError: state.adminReducer.updateConfigPackageError,
    updatedSysConfigRes: state.adminReducer.updatedSysConfigRes,
    updatedSysConfigErr: state.adminReducer.updatedSysConfigErr
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    getPackageConfiguration,
    getPacakgeByCode,
    getUserType,
    clearAdminError,
    updateConfigPackage,
    updateSysConfig
})(ConfigPackages);
