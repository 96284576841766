import React, { Component } from "react";
import { decodeToken } from "react-jwt";
import { Logo, RectangleIcon } from "../../assets/images/index";
import { getCookies } from "../../helpers/common";
import "./css/footer.scss";
import {  faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      togglegeneral: {
        toggle1: true, fontname1: faChevronUp,
        toggle2: true, fontname2: faChevronUp,
        toggle3: true, fontname3: faChevronUp,
      }
    }
  }

  componentDidMount() {
    let id_token = getCookies("id_token");
    const myDecodedToken = decodeToken(id_token);
    this.setState({
      user: myDecodedToken
    })
  }
  toggleGeneral = (flag, togglefield, fontfield) => {
    const { togglegeneral } = this.state;
    togglegeneral[togglefield] = flag;
    if (flag) {
      togglegeneral[fontfield] = faChevronUp
      this.setState({
        togglegeneral
      })
    } else {
      togglegeneral[fontfield] = faChevronUp
      this.setState({
        togglegeneral
      })
    }
    window.scrollTo(0, 0)
  }
  render() {
    const { user } = this.state;
    return (
      <div className="footer">
        {/* <div className="up-down-arrow">
          <FontAwesomeIcon icon={togglegeneral.fontname2} color="white" className="right" onClick={() => this.toggleGeneral(!togglegeneral.toggle2, 'toggle2', 'fontname2')} />
        </div> */}

        <div className="footer-black pb-4 pt-4">
          <div className="col-12 col-md-10 m-auto">
            <div className="col-md-12 p-0">
            <Link to={user?.user_info?.is_seamless === true ? "/user-account" : "/"} key="logo" >
              <img src={Logo} alt="logo"  className="mb-3 logos" />
              </Link>
            </div>
            <div className="col-md-6 p-0 footer-block d-inline-block align-top  ">
              <div className="col-md-4 p-0 d-inline-block">
                <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  <a href={user?.user_info?.is_seamless ? "/user-account" : "/"} className="d-inline-block ml-2 footer-links">
                    HOME
                  </a>
                </div>
                <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  <a href="/faq" className="d-inline-block ml-2 footer-links">
                    FAQ
                  </a>
                </div>
                <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  <a href="/aboutUs" className="d-inline-block ml-2 footer-links">
                    ABOUT US
                  </a>
                </div>
                {/* <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  <a href="https://www.imsph.sg/" target="_blank" className="d-inline-block ml-2 footer-links" rel="noreferrer">
                    ADVERTISE
                  </a>
                </div> */}
              </div>
              <div className="col-md-8 p-0 d-inline-block align-top">
                {/* <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  <a className="d-inline-block ml-2 footer-links cursor">
                    advanced search
                  </a>
                </div> */}
                <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  {/* <a href="https://corporate.sph.com.sg/legal/pdpa.html" target="_blank" rel="noreferer noreferrer" className="d-inline-block ml-2 footer-links">
                    data protection policy
                  </a> */}
                  <a href="https://www.sph.com.sg/legal/pdpa/" target="_blank" rel="noreferer noreferrer" className="d-inline-block ml-2 footer-links">
                      <span className="data-pro">data protection policy</span>
                  </a>

                </div>
                <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  {/* href="https://corporate.sph.com.sg/legal/website_tnc.html"  */}
                  <a href="https://www.sph.com.sg/legal/website_tnc/" target="_blank" rel="noreferer noreferrer" className="d-inline-block ml-2 footer-links">
                    terms & conditions
                  </a>
                </div>
                <div className="pt-2">
                  <img
                    src={RectangleIcon}
                    alt="logo"
                    className="d-inline-block"
                  />
                  <a href="https://www.imsph.sg/" target="_blank" rel="noreferer noreferrer" className="d-inline-block ml-2 footer-links">
                    Advertise
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 pl-md-3 d-inline-block">
              <div className="col-12 p-0">
                <img
                  src={RectangleIcon}
                  alt="logo"
                  className="d-inline-block"
                />
                <span className="d-inline-block ml-2 footer-links">
                  Contact
                </span>
                {/* <a href="/contactus" className="d-inline-block ml-2 footer-links">
                  Contact
                </a> */}
              </div>
              <div className="col-lg-6 col-md-6 p-0 d-inline-block helpdesk-section">
                <div className="pt-1 pb-1">
                  <h6 className="footer-links mb-0">HELPDESK:</h6>
                  <h6 className="footer-links mb-0 content">(65) 6319 2333</h6>
                </div>
                <div className="pt-1 pb-1">
                  <h6 className="footer-links mb-0">Fax:</h6>
                  <h6 className="footer-links mb-0 content">(65) 6319 8259</h6>
                </div>
                <div className="pt-1 pb-1">
                  <h6 className="footer-links mb-0">Email:</h6>
                  <h6 className="footer-links text-lowercase mb-0 emailfooter content ">
                    archives@sph.com.sg
                  </h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 pl-md-3 p-0 d-inline-block align-top address ">
                <div className="pt-1 pb-1 ">
                  <h6 className="footer-links mb-0">Address:</h6>
                  <h6 className="footer-links mb-0 text-capitalize content">
                    <span>SPH MEDIA LIMITED. </span>
                    <br />1000 Toa Payoh North, <br />Singapore
                    318994.
                    <br /><br />Mon-Fri,9am-5pm
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center p-2 copyright">
          COPYRIGHT © 2022 SPH MEDIA LIMITED. CO. REGN NO. 202120748H
          ALL RIGHTS RESERVED.
        </div>
      </div >
    );
  }
}

export default Footer;
