import React from "react";
import './maintenance.scss'
function Maintenance(){
    var date = new Date();
    date.setDate(date.getDate()+1)
    return(<>
            <div className="ftr"></div>

        <article className="articles">
            <h1 className="h11">We&rsquo;ll be back soon!</h1>
            <div>
                <h3>Newslink is temporarily down for Maintenance</h3>
                <p>We are performing scheduled maintenance. We should be back online by {date.toLocaleDateString()}.</p>
                <p>Sorry for the inconvenience caused. </p>
                <p>&mdash; The <b>NewsLink</b> Team</p>
            </div>
        </article>
    </>)
}

export default Maintenance
