import React, { Component } from "react";
import { connect } from "react-redux";
import "./pagination.scss";
import {
  setSearchObject,
  setPageNo,
} from "../../../redux/actions/applicationsAction";

class searchPagination extends Component {
  state = {
    currentpage: 1,
    totalpages: 0,
    currentpageSize: 10,
    pagesArray: [],
    totalcount: 0,
    from: 1,
    to: 10,
    incDec: 5,
  };

  componentDidMount() {
    const { total } = this.props;
    if (window.innerWidth <= 526) {
      this.state.currentpageSize = 4;
      this.state.incDec = 4;
      this.state.to = 4;
      this.setState({
        currentpageSize: this.state.currentpageSize,
        incDec: this.state.incDec,
        to: this.state.to,
      });
      // } else if (window.innerWidth <= 1132) {
      //     this.state.currentpageSize = 5
      //     this.state.incDec = 5
      //     this.state.to = 5
      //     this.setState({
      //         currentpageSize: this.state.currentpageSize,
      //         incDec: this.state.incDec,
      //         to: this.state.to

      //     })
    } else {
      this.state.currentpageSize = 10;
      this.state.incDec = 10;
      this.state.to = 10;
      this.setState({
        currentpageSize: this.state.currentpageSize,
        incDec: this.state.incDec,
        to: this.state.to,
      });
    }
    const { currentpageSize } = this.state;
    this.state.currentpage = this.props?.searchObject?.pageNo
      ? this.props?.searchObject?.pageNo
      : this.props?.currentPage
      ? this.props?.currentPage
      : 1;
    this.state.totalcount = total;
    this.setState({
      currentpage: this.state.currentpage,
      totalcount: this.state.totalcount,
    });

    let pageNumbers = [];
    if (total <= 10) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.totalcount / currentpageSize);
        i++
      ) {
        pageNumbers.push(i);
      }
    } else {
      if (total <= 100) {
        for (
          let i = 1;
          i <= Math.ceil(this.state.totalcount / currentpageSize);
          i++
        ) {
          pageNumbers.push(i);
        }
      }
      if (total > 100) {
        for (let i = 1; i <= Math.ceil(currentpageSize); i++) {
          pageNumbers.push(i);
        }
      }
    }

    this.setState({
      pagesArray: pageNumbers,
      totalpages: Math.ceil(total / 10),
    });
    if (this.state.currentpage === 1) {
      this.props.setPageNo({
        from: 1,
        to: this.state.to,
        pagesArray: pageNumbers,
      });
    } else {
      // this.props.setPageNo({from:this.props.pagesArray.from,to:this.props.pagesArray.to,pagesArray:pageNumbers});
    }
  }
  componentDidUpdate() {
    const { currentpageSize } = this.state;
    const { from, to } = this.props.pagenoObject;
    if (this.props.total && this.props.total !== this.state.totalcount) {
      this.state.totalcount = this.props.total;
      let pageNumbers = [];
      if (this.state.totalcount <= 10) {
        for (
          let i = 1;
          i <= Math.ceil(this.state.totalcount / currentpageSize);
          i++
        ) {
          pageNumbers.push(i);
        }
      } else {
        if (this.state.totalcount <= 100) {
          for (
            let i = 1;
            i <= Math.ceil(this.state.totalcount / currentpageSize);
            i++
          ) {
            pageNumbers.push(i);
          }
        }
        if (this.state.totalcount > 100) {
          for (let i = 1; i <= Math.ceil(currentpageSize); i++) {
            pageNumbers.push(i);
          }
        }
      }
      this.setState({
        totalcount: this.state.totalcount,
        pagesArray: pageNumbers,
        currentpage: this.state.currentpage,
        totalpages: Math.ceil(this.state.totalcount / 10),
      });
      this.props.setPageNo({ from: from, to: to, pagesArray: pageNumbers });
    }
  }

  nexfun = (start, to, too) => {
    if (this.state.totalpages >= to) {
      if (window.innerWidth <= 526) {
        if (this.state.currentpage === 1) {
          start = 5;
          to = 8;
        }
        // } else if (window.innerWidth < 1132) {
        //     if (this.state.currentpage === 1) {
        //         start = 6;
        //         to = 10;
        //     }
      } else {
        if (this.state.currentpage === 1) {
          start = 11;
          to = 20;
        }
      }

      let pageNumbers = [];
      for (let i = start; i <= to; i++) {
        pageNumbers.push(i);
      }
      this.setState({
        pagesArray: pageNumbers,
        from: start,
        to: to,
        currentpage: start,
      });
      this.props.setPageNo({ from: start, to: to, pagesArray: pageNumbers });
      this.props.onPagination(start);
    }
    if (start <= this.state.totalpages && to > this.state.totalpages) {
      let pageNumbers = [];
      for (let i = start; i <= this.state.totalpages; i++) {
        pageNumbers.push(i);
      }
      this.setState({
        pagesArray: pageNumbers,
        from: start,
        to: to,
      });
      this.props.setPageNo({ from: start, to: to, pagesArray: pageNumbers });
      if (this.state.currentpage < start) {
        this.setState({
          currentpage: start,
        });

        this.props.onPagination(start);
      }
    }
  };
  prefun = (from, to) => {
    if (window.innerWidth <= 526) {
      if (this.state.currentpage === 1) {
        from = -1;
        to = 4;
      }
      // } else if (window.innerWidth <= 1132) {
      //     if (this.state.currentpage === 1) {
      //         from = -1;
      //         to = 5;
      //     }
    } else {
      if (this.state.currentpage === 1) {
        from = -1;
        to = 10;
      }
    }

    if (from >= 1) {
      let pageNumbers = [];
      for (let i = from; i <= to; i++) {
        pageNumbers.push(i);
      }
      this.setState({
        pagesArray: pageNumbers,
        from: from,
        to: to,
        currentpage: from,
      });
      this.props.setPageNo({ from: from, to: to, pagesArray: pageNumbers });
      this.props.onPagination(from);
    }
  };
  paginate = (pgnumber) => {
    this.setState({ currentpage: pgnumber });
    this.props.onPagination(pgnumber);
  };
  render() {
    const { currentpage, incDec } = this.state;
    const { searchObject } = this.props;
    const { from, to, pagesArray } = this.props.pagenoObject;
    return (
      <nav aria-label="Page  navigation example ">
        <ul className="pagination d-flex flex-wrap">
          <li className="page-item cursor ">
            <a
              className="page-link contentadd"
              onClick={() => this.prefun(from - incDec, to - incDec)}
            ></a>
          </li>
          {pagesArray.map((number) => (
            <>
              {searchObject ? (
                <li
                  key={number}
                  className={
                    number === this.props.searchObject.pageNo
                      ? "page-item active cursor"
                      : "page-item cursor"
                  }
                >
                  <a
                    onClick={() => this.paginate(number)}
                    className={"page-link "}
                  >
                    {number}
                  </a>
                </li>
              ) : (
                <li
                  key={number}
                  className={
                    number === currentpage
                      ? "page-item active cursor"
                      : "page-item cursor"
                  }
                >
                  <a
                    onClick={() => this.paginate(number)}
                    className={"page-link  "}
                  >
                    {number}
                  </a>
                </li>
              )}
            </>
          ))}
          <li className="page-item">
            <a
              className="page-link cursor contentnext"
              onClick={() => this.nexfun(from + incDec, to + incDec)}
            ></a>
          </li>
          <li className="page-item">
            <a className="page-link">{this.state.totalpages}</a>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  searchObject: state.applicationReducer.searchObject,
  pagenoObject: state.applicationReducer.pagenoObject,
});

export default connect(mapDispatchStatetoProps, {
  setSearchObject,
  setPageNo,
})(searchPagination);
