import React, { Component } from "react";
import { connect } from "react-redux";
// import publicIp from "public-ip";
import reactReferer from "react-referer";
import { validationRules, formValidation, getCustomviewobj } from "../../helpers/common";
import { ButtonElement, Spinner } from "../UI/index";
import { Logo } from "../../assets/images/index";
import { startLoader, userLogin } from "../../redux/actions/loginAction";
import {customViewDatagtm} from "../../redux/actions/applicationsAction";
import "./css/login.scss";
import RecaptchaWidget from "../UI/Recaptcha/recaptcha";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        userId: "",
        password: "",
      },
      captchaToken: "",
      captchaError: false,
      referrer: reactReferer.referer(),
      submit: false,
    };
  }

  onChange = (e, key) => {
    const { userDetails } = this.state;
    userDetails[key] = e.target.value ? e.target.value.toString().trim() : e.target.value;
    this.setState({
      userDetails,
    });
  };

  getRecaptchaToken = (value) => {
    this.setState({
      captchaToken: value,
      captchaError: false
    });
  }

  submitForm = async () => {
    this.props.startLoader();
    const { userDetails, referrer, captchaToken } = this.state;
    const data = {
      captchaToken: captchaToken,
      ipAddress: "192.12.3.4",
      loginKey: null,
      password: userDetails.password,
      referrer: referrer ? referrer : "https://newslink.sg",
      userId: userDetails.userId,
    };
    await this.props.userLogin(data);
    if (this.props.loginError) {
      // console.log(this.props.loginError)
      this.customViewcall(this.props.searchObject,{},this.props.loginError)
      this.setState({
        captchaToken: "",
      });
    }
    const token = localStorage.getItem("token");
    // console.log(this.props.statusLogin,this.props.successResponse)
    if (token &&this.props.statusLogin && this.props.statusLogin===200) {
      this.customViewcall(this.props?.searchObject,{},this.props.successResponse?.message)
      this.props.onClose();
      this.props.history.push("/user-account");
      window.location.reload();
    }
  };

  customViewcall=(searchObject,token,eventLabel,pudate)=>{
    let user_status=token?.user_info?.user_type ? token?.user_info?.user_type:"" ;
    let id=token?.user_info?.visitor_id ? token?.user_info?.visitor_id : ""
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate)
    let objj= getCustomviewobj("login","click",eventLabel,"search","",user_status,"","", pudate ? pudate : "",id)
    this.props.customViewDatagtm(objj,searchObject)
  }
  
  onLogin = (e) => {
    //gtm call
    e.preventDefault();
    const { userDetails, captchaToken } = this.state;
    this.setState({
      submit: true,
    });
    if (!formValidation(userDetails)) {
      if(captchaToken!=""){
        this.props.startLoader();
        this.submitForm()
      }
      else{
        this.setState({
          captchaError: true,
        });
      }
      
    }
  };

  gotoSubscription = () =>{
    this.props.onClose();
    this.props.history.push("/subscription");
  }

  render() {
    const { onForgotPassword, loading } = this.props;
    const { userDetails, submit, captchaToken, captchaError } = this.state;
    return (
      <div className="col-md-8 p-0 m-auto login-container">
        <div className="text-center">
          <img src={Logo} alt="logo" className="mb-3 logo" />
        </div>
        {loading &&
          <div className="text-center pt-5 pb-5 spinner">
            <Spinner primary={true} />
          </div>
        }
        <form
            className="container-fluid"
            autoComplete="false"
            noValidate
            onSubmit={(e) => this.onLogin(e)}
          >
            <h3 className="login-heading mb-3">LOGIN</h3>
            <input
              className="form-control mb-3 inputControl"
              placeholder="Email*"
              required
              name="email"
              autoComplete="email"
              value={userDetails.userId}
              onChange={(e) => this.onChange(e, "userId")}
            />
            <div className="pt-0">
              <span className="error-message">
                {validationRules(submit, userDetails.userId)}
              </span>
            </div>
            <input
              className="form-control mb-3"
              type="password"
              name="password"
              placeholder="Password*"
              required
              autoComplete="user-password"
              value={userDetails.password}
              onChange={(e) => this.onChange(e, "password")}
            />
            <div className="pt-0">
              <span className="error-message">
                {validationRules(submit, userDetails.password)}
              </span>
            </div>

            <RecaptchaWidget getRecaptchaToken={(value)=>this.getRecaptchaToken(value)} token={captchaToken} error={captchaError}/>
            <h6 onClick={(e) => onForgotPassword(e)}>
              <span className="forgot-password">Forgot your password?</span>
            </h6>
            <div className="text-center mt-4">

              <ButtonElement
                type="submit"
                variant="contained"
                btnType="background-orange"
                className="d-inline-block mr-2 login-button"
              >
                Log in
              </ButtonElement>
            </div>
            <div className="text-center mt-4">
              <h6 className="frgotpwd">
                Don’t have an account?{" "}
                <span className="forgot-password" onClick={()=>this.gotoSubscription()}>Subscribe now!</span>
              </h6>
            </div>
          </form>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.loginReducer.isLoading,
  contentTypes: state.applicationReducer.contentTypes,
  loginError: state.loginReducer.login_error,
  succesresforlogin:state.loginReducer.succesresforlogin,
  statusLogin:state.loginReducer.statusLogin
});

export default connect(mapDispatchStatetoProps, { startLoader, userLogin,customViewDatagtm })(
  Login
);
