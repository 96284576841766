import React, { Component } from "react";
import "./css/searchuseraccount.scss";
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import {
  startLoader,
  getUserType,
  getAccountStatus,
  getSubscriptions,
  findUser,
  clearAdminError,
  getSortBy,
  getInfoData,
  findUsageDetails,
  stopLoader,
} from "../../../../redux/actions/adminAction";
import {
  ButtonElement,
  DatePickerElement,
  Spinner,
  Modal,
} from "../../../UI//index";
import { RECAPTCHA_SITE_KEY } from "../../../../config/constants/index";
import {
  validationRules,
  sortuser,
  format,
  getDate,
} from "../../../../helpers/common";
import { Checkbox, Selectlist, SelectElement } from "../../../UI//index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { getContentTypes } from "../../../../redux/actions/applicationsAction";
import { getUserDetails } from "../../../../redux/actions/loginAction";
import { dowloadPdf } from "../../../../helpers/pdfDowload";
import { Link } from "react-router-dom";
import ErrorPopup from "../../Popup/ErrorPopup";
import _ from "lodash";

const initialState = {
  submit: false,
  minDate: getDate(),
  requestDateFrom: "",
  requestDateTo: "",
  useraccount: {
    username: "",
    company: "",
    email: "",
    userTypes: [],
    accountStatus: [],
    activeSubscription: [],
    dateRange: {
      requestDateFrom: "",
      requestDateTo: "",
    },
    sortBy: "LOGIN",
    sortOrder: "asc",
    reportFormat: "HTML",
    agencyType: [],
    digitalTypes: [],
    pageNumber: 1,
    loginType: "ALL",
  },
  togglegeneral: {
    toggle1: true,
    fontname1: faChevronDown,
    toggle2: true,
    fontname2: faChevronDown,
    toggle3: true,
    fontname3: faChevronDown,
  },
  userType: [],
  accountStatus: [],
  activeSubscription: [],
  sortByArr: [],
  isUsageDetails: false,
  agenciesArr: [],
  digitalTypesArr: [],
  message: "",
  errData: [],
  loginTypeData: [],
  success: false,
  obj: {
    userTypeObj: {},
  },
  digitalTypes: [],
};

class Searchuseracount extends Component {
  state = initialState;
  async componentDidMount() {
    const { useraccount } = this.state;
    useraccount.username = "";
    useraccount.company = "";
    useraccount.email = "";
    useraccount.reportFormat = "HTML";
    useraccount.loginType = "ALL";
    useraccount.sortOrder = "asc";

    this.setState({
      useraccount,
    });
    await this.props.startLoader();
    await this.props.getUserDetails();
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.finduser.isUsageDetails
    ) {
      this.setState({
        isUsageDetails: true,
        requestDateFrom: getDate(),
        requestDateTo: new Date(),
      });
    }
    this.props.startLoader();
    await this.props.getInfoData();
    let loginType = (this.props?.infoData?.loginTypes).map((type) => {
      return { key: type, value: type };
    });
    let usertypeKeys = Object.keys(this.props.infoData.userTypes);
    let allUserType = this.props.infoData.userTypes;
    let newUserTypesArr = usertypeKeys.map((usertype) => {
      return { key: allUserType[usertype].userTypeName, value: usertype };
    });
    let accountStatus = Object.keys(this.props.infoData.accountStatus);
    let newAccountstatusArr = accountStatus.map((status) => {
      return {
        key: _.camelCase(status),
        value: this.props.infoData.accountStatus[status],
      };
    });
    let basePackageVO = Object.keys(this.props.infoData.packages.basePackageVO);
    let allSubscriptionPackage = this.props.infoData.packages.basePackageVO;
    let newSubscriptionArr = basePackageVO.map((subscriptionPackage) => {
      return {
        key: allSubscriptionPackage[subscriptionPackage].description,
        value: subscriptionPackage,
      };
    });
    let sortByList = "";
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.finduser.isUsageDetails
    ) {
      sortByList = this.props.infoData.sortBy.findUsageSortBy;
    } else {
      sortByList = this.props.infoData.sortBy.findUserSortBy;
    }
    let sortByKeys = Object.keys(sortByList);
    let sortByArr = sortByKeys.map((key) => ({
      key: key,
      value: sortByList[key],
    }));
    let agenciesArr = this.props.infoData.agencies.map((agency) => ({
      key: agency.code,
      value: agency.code,
    }));
    useraccount.sortBy = this.props.location.state.finduser.isUsageDetails
      ? "a.USER_ID"
      : "LOGIN";
    let digital_types_keys = Object.keys(this.props.infoData?.digitalTypeAdmin);
    let digitalTypesArr = digital_types_keys.map((key) => ({
      key: key,
      value: this.props.infoData?.digitalTypeAdmin[key],
    }));
    if (this.state.isUsageDetails) {
      let fromdt = getDate();
      let dt = new Date();
      let modifieddate = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];

      let modifieddate1 = new Date(
        fromdt.getTime() - fromdt.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      useraccount.dateRange["requestDateFrom"] = modifieddate1;
      useraccount.dateRange["requestDateTo"] = modifieddate;
    }

    this.setState({
      userType: newUserTypesArr,
      accountStatus: newAccountstatusArr,
      activeSubscription: newSubscriptionArr,
      sortByArr: sortByArr,
      agenciesArr: agenciesArr,
      loginTypeData: loginType,
      useraccount,
      digitalTypes: digitalTypesArr,
    });
  }

  toggleGeneral = (flag, togglefield, fontfield) => {
    const { togglegeneral } = this.state;
    togglegeneral[togglefield] = flag;
    if (flag) {
      togglegeneral[fontfield] = faChevronUp;
      this.setState({
        togglegeneral,
      });
    } else {
      togglegeneral[fontfield] = faChevronDown;
      this.setState({
        togglegeneral,
      });
    }
  };

  onReset = async (e) => {
    const {
      useraccount,
      activeSubscription,
      accountStatus,
      userType,
      obj,
      agenciesArr,
      isUsageDetails,
      digitalTypes,
    } = this.state;
    useraccount.username = "";
    useraccount.company = "";
    useraccount.email = "";
    if (isUsageDetails) {
      useraccount.sortBy = "a.USER_ID";
    } else {
      useraccount.sortBy = "LOGIN";
    }
    useraccount.sortOrder = "asc";
    useraccount.reportFormat = "HTML";
    useraccount.loginType = "ALL";

    this.state.userType.map((type) => {
      if (type.ischecked === true) {
        type.ischecked = false;
      }
      // return;
    });
    this.state.accountStatus.map((type) => {
      if (type.ischecked === true) {
        type.ischecked = false;
      }
      // return;
    });
    this.state.activeSubscription.map((type) => {
      if (type.ischecked === true) {
        type.ischecked = false;
      }
      // return;
    });
    digitalTypes.map((type) => {
      if (type.ischecked === true) {
        type.ischecked = false;
      }
      // return;
    });
    agenciesArr.map((agency) => {
      if (agency.ischecked === true) {
        agency.ischecked = false;
      }
      // return;
    });
    useraccount.userTypes = [];
    useraccount.accountStatus = [];
    useraccount.activeSubscription = [];
    useraccount.agencies = [];
    useraccount.agencyType = [];
    useraccount.digitalTypes = [];

    obj.userTypeObj = {};
    this.setState({
      useraccount,
      activeSubscription,
      accountStatus,
      userType,
      requestDateFrom: "",
      requestDateTo: "",
      obj,
    });
  };

  //date code started
  onfromDateChange = (date) => {
    const { useraccount } = this.state;
    let dt = new Date(date);
    let modifieddate = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    useraccount.dateRange["requestDateFrom"] = modifieddate;
    this.setState({
      useraccount,
      requestDateFrom: date,
    });
    console.log(this.state);
  };
  setfromdate = (useraccount) => {
    this.setState({
      useraccount,
    });
  };
  ontoDateChange = (date) => {
    const { useraccount } = this.state;
    let dt = new Date(date);
    let modifieddate = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    useraccount.dateRange["requestDateTo"] = modifieddate;
    this.setState({
      useraccount,
      requestDateTo: date,
    });
    console.log(this.state);
  };
  settodate = (useraccount) => {
    this.setState({
      useraccount,
    });
  };
  //date code ended
  setFormValue = (value, field, number = false) => {
    const { useraccount } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      useraccount[field] =
        regex.test(value) || value === "" ? value : useraccount[field];
    } else {
      useraccount[field] = value ? value.toString() : value;
    }
    this.setState({
      useraccount,
    });
  };
  setFormValue1 = (value, field) => {
    const { useraccount } = this.state;
    let userTypeArr = [];
    for (let v in value) {
      if (value[v]) {
        userTypeArr.push(v);
      }
    }
    useraccount[field] = userTypeArr;
    this.setState({
      useraccount,
    });
  };
  onResolved = async () => {
    const { useraccount, isUsageDetails } = this.state;
    const response = this.recaptcha.getResponse();
    useraccount.captchaToken = response;
    // let userTypeObj = obj.userTypeObj;
    this.props.startLoader();
    useraccount.dateRange["requestDateFrom"] =
      useraccount.dateRange["requestDateFrom"] === ""
        ? ""
        : useraccount.dateRange["requestDateFrom"];
    useraccount.dateRange["requestDateTo"] =
      useraccount.dateRange["requestDateTo"] === ""
        ? ""
        : useraccount.dateRange["requestDateTo"];
    useraccount.company = useraccount.company
      ? useraccount.company.trim()
      : useraccount.company.trim();
    useraccount.email = useraccount.email
      ? useraccount.email.trim()
      : useraccount.email.trim();
    useraccount.username = useraccount.username
      ? useraccount.username.trim()
      : useraccount.username.trim();

    this.setState({ useraccount });
    if (!isUsageDetails) {
      useraccount.agencies = [];
      useraccount.digitalTypes = [];
      this.setState({ useraccount });
      // await this.props.clearAdminError();
      await this.props.findUser(useraccount);

      if (this.props?.findUser_error) {
        await this.recaptcha.reset();
        let errdata = this.props.findUser_error.length
          ? this.props.findUser_error
          : ["api Failed"];
        this.setState({
          success: true,
          errdata: errdata,
          message: "",
        });
        await this.props.clearAdminError();
      } else if (this.props.userDetails && this.props.userDetails.userInfo) {
        this.props.history.push({
          pathname: "/admin/user-account",
          useraccountData: this.props.userDetails,
          isUsageDetails: false,
          totalCount: this.props.userDetails.totalCount,
          searchObj: useraccount,
        });
      } else if (
        this.props.userDetails &&
        this.props.userDetails.allUrlList &&
        this.props.userDetails.allUrlList.length > 0
      ) {
        await this.props.startLoader();

        dowloadPdf(this.props?.userDetails?.allUrlList[0]);
        if (this.props?.userDetails?.message) {
          this.setState({
            success: true,
            message: this.props?.userDetails?.message,
            errorType: "Success",
            errdata: [],
          });
        }

        await this.props.stopLoader();
        // await this.props.history.push({
        //     pathname: '/admin/FindUser',
        //     state: {
        //         finduser: {
        //             isUsageDetails: false

        //         }
        //     }
        // })
      }
      // await this.props.clearAdminError();
    } else {
      let searchUsagedetailsData = {
        agencyType: useraccount.agencyType,
        company: useraccount.company.trim(),
        digitalTypes: useraccount.digitalTypes,
        packages: useraccount.activeSubscription,
        reportFormat: useraccount.reportFormat,
        dateRange: {
          requestDateFrom: useraccount.dateRange["requestDateFrom"],
          requestDateTo: useraccount.dateRange["requestDateTo"],
        },

        sortBy:
          useraccount.sortBy !== "LOGIN" ? useraccount.sortBy : "a.USER_ID",
        sortOrder: useraccount.sortOrder,
        types: useraccount.userTypes,
        username: useraccount.username.trim(),
        pageNumber: useraccount.pageNumber,
        loginType: useraccount.loginType,
      };
      searchUsagedetailsData.captchaToken = response;
      this.props.startLoader();
      await this.props.findUsageDetails(searchUsagedetailsData);
      if (this.props.findUsageDetails_error) {
        this.recaptcha.reset();
        this.setState({
          success: true,
          errdata: this.props.findUsageDetails_error,
          message: "",
        });
        await this.props.clearAdminError();
      } else if (
        this.props.usageDetailsData &&
        this.props.usageDetailsData.usageDetails
      ) {
        this.props.history.push({
          pathname: "/admin/user-account",
          useraccountData: this.props.usageDetailsData.usageDetails,
          isUsageDetails: true,
          totalCount: this.props.usageDetailsData.totalCount,
          searchObj: searchUsagedetailsData,
        });
      } else if (
        this.props.usageDetailsData &&
        this.props.usageDetailsData.allUrlList &&
        this.props.usageDetailsData.allUrlList.length > 0
      ) {
        await this.props.startLoader();
        dowloadPdf(this.props?.usageDetailsData?.allUrlList[0]);
        this.setState({
          success: true,
          message: this.props?.usageDetailsData?.message,
          errorType: "Success",
          errdata: [],
        });
        await this.props.stopLoader();
      }
    }
    // useraccount.dateRange["requestDateFrom"] = ""
    // useraccount.dateRange["requestDateTo"] = ""
    // this.setState({
    //     useraccount
    // })
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.recaptcha.execute();
    window.scrollTo(0, 0);
  };
  onClose = () => {
    this.setState({
      success: false,
    });
  };

  render() {
    const { loading } = this.props;
    const {
      success,
      message,
      errdata,
      submit,
      useraccount,
      togglegeneral,
      userType,
      accountStatus,
      activeSubscription,
      requestDateFrom,
      requestDateTo,
      sortByArr,
      isUsageDetails,
      agenciesArr,
      loginTypeData,
      digitalTypes,
    } = this.state;
    return (
      <div className="">
        {success && (
          <Modal
            customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
            onClick={this.onClose}
            errorFlag={success}
          >
            <ErrorPopup
              onClose={this.onClose}
              msg={message}
              errData={errdata}
              {...this.props}
            />
          </Modal>
        )}
        {loading && (
          <div className="text-center spinner">
            {/* <Spinner animation="border" variant="primary" /> */}
            <Spinner primary={true} />
          </div>
        )}
        <>
          <div ref="userform ">
            <div className="main-content">
              {!isUsageDetails && (
                <div className="text-right pt-3 pr-2">
                  <Link
                    to="/admin/create-account"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <ButtonElement
                      type="submit"
                      variant="contained"
                      btnType="background-orange"
                      className=""
                    >
                      Create Account
                    </ButtonElement>
                  </Link>
                </div>
              )}
              <div>
                <form
                  className="container-fluid"
                  noValidate
                  onSubmit={(e) => this.onSubmit(e)}
                >
                  <h2 className="heading heading-bold">
                    {!isUsageDetails ? "Search User Account" : "Usage Details"}
                  </h2>
                  <div className="card card-margin-left-0px">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6  pb-2">
                        <label className="pr-2 pt-1 form-control-label ">
                          Username
                        </label>
                        <input
                          placeholder="Username"
                          value={useraccount.username}
                          onChange={(e) =>
                            this.setFormValue(e.target.value, "username")
                          }
                          type="text"
                          className="search-input p-1 pl-4 w-100 "
                        />
                      </div>
                      <div className="col-lg-6 col-md-6  pb-2">
                        <label className="pr-2 pt-1 form-control-label ">
                          Company
                        </label>
                        <input
                          placeholder="Company"
                          value={useraccount.company}
                          onChange={(e) =>
                            this.setFormValue(e.target.value, "company")
                          }
                          type="text"
                          className="search-input p-1 pl-4 w-100 "
                        />
                      </div>
                    </div>

                    {!isUsageDetails ? (
                      <>
                        <div className="row">
                          <div className="col-lg-6 col-md-6  pb-2">
                            <label className="pr-2 pt-1 form-control-label ">
                              Email(For Notification)
                            </label>
                            <input
                              placeholder="Email"
                              value={useraccount.email}
                              onChange={(e) =>
                                this.setFormValue(e.target.value, "email")
                              }
                              type="text"
                              className="search-input p-1 pl-4 w-100 "
                            />
                          </div>
                        </div>{" "}
                      </>
                    ) : null}
                  </div>
                  <div className="card card-margin-left-0px">
                    <div className="row  p-1">
                      <div className="col-lg-12 ">
                        <Selectlist
                          list={userType}
                          label="User Type"
                          onChange={(e) =>
                            this.setFormValue1(e.selectedSub, "userTypes")
                          }
                        ></Selectlist>
                      </div>
                    </div>
                  </div>

                  {!isUsageDetails && (
                    <div className="card card-margin-left-0px">
                      {" "}
                      <div className="row p-1">
                        <div className="col-lg-12 ">
                          {!isUsageDetails ? (
                            <>
                              <Selectlist
                                list={accountStatus}
                                label="Account Status"
                                onChange={(e) =>
                                  this.setFormValue1(
                                    e.selectedSub,
                                    "accountStatus"
                                  )
                                }
                              ></Selectlist>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="card card-margin-left-0px">
                    <div className="row p-1">
                      <div className="col-lg-12 ">
                        <Selectlist
                          list={activeSubscription}
                          label={
                            !isUsageDetails ? "Active Subscription" : "Packages"
                          }
                          onChange={(e) =>
                            this.setFormValue1(
                              e.selectedSub,
                              "activeSubscription"
                            )
                          }
                        ></Selectlist>
                      </div>
                    </div>
                  </div>

                  {isUsageDetails && (
                    <div className="card card-margin-left-0px">
                      <div className="row p-1">
                        <div className="col-lg-12 ">
                          {isUsageDetails ? (
                            <>
                              <Selectlist
                                list={agenciesArr}
                                label="Agency Type"
                                onChange={(e) =>
                                  this.setFormValue1(
                                    e.selectedSub,
                                    "agencyType"
                                  )
                                }
                              ></Selectlist>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}

                  {isUsageDetails && (
                    <div className="card card-margin-left-0px">
                      {" "}
                      <div className="row p-1">
                        <div className="col-lg-12 ">
                          {isUsageDetails ? (
                            <>
                              <Selectlist
                                list={digitalTypes}
                                label="Digital Type"
                                onChange={(e) =>
                                  this.setFormValue1(
                                    e.selectedSub,
                                    "digitalTypes"
                                  )
                                }
                              ></Selectlist>
                            </>
                          ) : null}
                          <div></div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card card-margin-left-0px">
                    <div className="row  p-1">
                      <div className="col-lg-12 ">
                        <label className="labels">
                          CREATION DATE (DD/MM/YY)
                        </label>
                        <FontAwesomeIcon
                          icon={togglegeneral.fontname1}
                          className="right"
                          onClick={() =>
                            this.toggleGeneral(
                              !togglegeneral.toggle1,
                              "toggle1",
                              "fontname1"
                            )
                          }
                        />
                        <div
                          className={`${
                            togglegeneral.toggle2 ? "" : "collapsed"
                          }`}
                        >
                          <div
                            className={`d-flex flex-wrap selectlist-item ${
                              togglegeneral.toggle1 ? "date-item" : "collapsed"
                            }`}
                          >
                            <div className="basisflex mr-3">
                              <span className="labels pb-4">FROM</span>
                              <div className="pb-2 pt-2 dates">
                                <DatePickerElement
                                  onChange={this.onfromDateChange}
                                  className="w-100"
                                  value={requestDateFrom ? requestDateFrom : ""}
                                  maxDate={new Date()}
                                />
                              </div>
                            </div>
                            <div className="basisflex">
                              <span className="labels pb-4">TO</span>
                              <div className="pb-2 pt-2 dates">
                                <DatePickerElement
                                  onChange={this.ontoDateChange}
                                  className="w-100"
                                  value={requestDateTo ? requestDateTo : ""}
                                  minDate={requestDateFrom}
                                  maxDate={new Date()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-margin-left-0px">
                    <div className="row">
                      {/* <div className='col-lg-12 '> */}
                      {/* <FontAwesomeIcon icon={togglegeneral.fontname2} className="right" onClick={() => this.toggleGeneral(!togglegeneral.toggle2, 'toggle2', 'fontname2')} /> */}
                      <div className="col-lg-4">
                        <label className="labels">SORT BY</label>
                        <div className={`d-flex flex-wrap  selectlist-item`}>
                          <SelectElement
                            className=""
                            options={sortByArr}
                            defaultValue={useraccount.sortBy}
                            onChange={(e) =>
                              this.setFormValue(e.target.value, "sortBy")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="sortorder-padding">
                          <label className="labels pr-2 sortorder-width">
                            Sort Order{" "}
                          </label>
                          {sortuser.map((p) => (
                            <div
                              className="d-inline-block sortorderp sort-margin-top"
                              key={p.key}
                            >
                              <Checkbox
                                key={p.key}
                                labelValue={p.value}
                                checked={useraccount.sortOrder === p.value}
                                onChange={(e) =>
                                  this.setFormValue(
                                    e.target.getAttribute("label"),
                                    "sortOrder"
                                  )
                                }
                              />
                              <span className="margin-left-35">{p.key} </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-lg-12'>
                                            <div >
                                                <label className='labels pr-2 '>Sort Order </label>

                                                {sortuser.map((p) => (
                                                    <div className="d-inline-block sortorderp" key={p.key}>
                                                        <Checkbox key={p.key} labelValue={p.value} checked={useraccount.sortOrder === p.value} onChange={(e) =>
                                                            this.setFormValue(e.target.getAttribute("label"), "sortOrder")} />
                                                        <span className="margin-left-35">{p.key} </span>
                                                    </div>))}
                                            </div>
                                        </div> */}

                    <div className="row">
                      <div className="col-lg-12 ">
                        <label className="labels sortOrder margin-top">
                          LOGIN TYPE{" "}
                        </label>
                        {loginTypeData.map((p) => (
                          <div
                            className="col-lg-3 d-inline-block p-0 pr-1"
                            key={p.key}
                          >
                            <Checkbox
                              key={p.key}
                              labelValue={p.value}
                              checked={useraccount.loginType === p.value}
                              onChange={(e) =>
                                this.setFormValue(
                                  e.target.getAttribute("label"),
                                  "loginType"
                                )
                              }
                            />
                            <span className="margin-left-35">{p.key} </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-12 format-mt">
                        <label className="labels sortOrder margin-top">
                          FORMAT <span style={{ paddingRight: "30px" }}></span>
                        </label>
                        {/* <FontAwesomeIcon icon={togglegeneral.fontname3} className="right" onClick={() => this.toggleGeneral(!togglegeneral.toggle3, 'toggle3', 'fontname3')} /> */}
                        {/* <div className={`d-flex flex-wrap selectlist-item ${togglegeneral.toggle3 ? "" : "collapsed"}`}> */}
                        {format.map((p) => (
                          <div
                            className="col-lg-3 d-inline-block p-0 pr-3 ws-nowrap"
                            key={p.key}
                          >
                            <Checkbox
                              key={p.key}
                              labelValue={p.value}
                              checked={useraccount.reportFormat === p.value}
                              onChange={(e) =>
                                this.setFormValue(
                                  e.target.getAttribute("label"),
                                  "reportFormat"
                                )
                              }
                            />
                            <span className="margin-left-35">{p.key} </span>
                          </div>
                        ))}
                        {/* </div> */}
                        <div className="pt-1">
                          <span className="error-message">
                            {validationRules(submit, useraccount.formattype) !==
                            ""
                              ? "Please select format type"
                              : ""}
                          </span>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {isUsageDetails && (
                    <span className="note">
                      NB : Please input Email/Usersername, Company and select
                      UserType/Package to search for Usage
                    </span>
                  )}
                  <div>
                    <div className="text-right pt-3 pb-5">
                      <ButtonElement
                        variant="contained"
                        btnType="background-orange button-label"
                        className="d-inline-block mr-2 float-right"
                        onClick={this.onReset}
                      >
                        <span className="button-label">Reset</span>
                      </ButtonElement>

                      <ButtonElement
                        type="submit"
                        variant="contained"
                        btnType="background-orange button-label"
                        className="d-inline-block mr-2 float-right"
                      >
                        <span className="button-label">Submit</span>
                      </ButtonElement>
                    </div>
                  </div>
                </form>
                <Recaptcha
                  size="invisible"
                  ref={(ref) => (this.recaptcha = ref)}
                  onResolved={this.onResolved}
                  sitekey={RECAPTCHA_SITE_KEY}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}
const mapDispatchStatetoProps = (state) => ({
  ...state,
  loading: state.adminReducer.isLoading,
  searchUserAcc: state.adminReducer.searchUserAcc,
  userTypes: state.adminReducer.userTypes,
  accountStatus: state.adminReducer.accountStatus,
  subscriptionPackage: state.adminReducer.subscriptionPackage,
  userDetails: state.adminReducer.userDetails,
  findUser_error: state.adminReducer.findUser_error,
  sortByData: state.adminReducer.sortByData,
  infoData: state.adminReducer.infoData,
  contentTypes: state.applicationReducer.contentTypes,
  digitalType: state.applicationReducer.digitalType,
  usageDetailsData: state.adminReducer.usageDetailsData,
  findUsageDetails_error: state.adminReducer.findUsageDetails_error,
});
export default connect(mapDispatchStatetoProps, {
  startLoader,
  getUserType,
  getAccountStatus,
  getSubscriptions,
  findUser,
  clearAdminError,
  getSortBy,
  getInfoData,
  findUsageDetails,
  getContentTypes,
  getUserDetails,
  stopLoader,
})(Searchuseracount);
