import * as Action_constants from './action-constants';
const RECAPTCHA_SITE_KEY = "6Lf6eL0ZAAAAAAYUUKkjVcm7YJSRNqLkoNrC5UQC";
const RECAPTCHA_SECRET_KEY = "6Lf6eL0ZAAAAAJDpwxeyNKvJdRHyZIb6dcV0IVEF";

const RECAPTCHA_SITE_KEY_V2 = "6LdC0XMlAAAAAKCSw46hzC-2fO_HIr2VS0C31dU1";

export {
    Action_constants,
    RECAPTCHA_SITE_KEY,
    RECAPTCHA_SECRET_KEY,
    RECAPTCHA_SITE_KEY_V2
}
