import React, { Component } from 'react';
import "./userdetail-subscription.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Accountinfo from './Accountinfo';
import ViewSubscription from './ViewSubscription';
import { Spinner, Modal } from "../../../UI/index";
import { getPreloads, startLoader, getInfoData, getUserDetailDataByuserId, clearAdminError } from '../../../../redux/actions/adminAction';
import { connect } from "react-redux";
import ErrorPopup from '../../Popup/ErrorPopup';
// import { create } from 'draft-js/lib/CharacterMetadata';


class Userdetailsubcription extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userDetail: {},
            success: false,
            errdata: [],
            failure: false,
            message: "",
        }
    }
    async componentDidMount() {
        this.props.startLoader();
        const { userdetailsbyuserid } = this.props
        if (this.props.location && this.props.location.searchObject) {
            this.setState({
                userDetail: this.props.location.searchObject
            })
            await this.props.clearAdminError();
            await this.props.startLoader();
            await this.props.getUserDetailDataByuserId({ userId: this.props.location.searchObject.userId });
            if (this.props.userDetailsByUserIdError) {
                this.setState({
                    success: true,
                    errdata: this.props.userDetailsByUserIdError,
                    message: ""
                })
            }

        }
    }
    onClose = () => {
        this.setState({
            success: false
        })
    }
    render() {
        const { userdetailsbyuserid, isLoading } = this.props
        const { success, errdata, failure, message } = this.state
        let createDate;
        let updateDate;
        if (userdetailsbyuserid && (userdetailsbyuserid.accountInfo.createOn)) {
            createDate =userdetailsbyuserid && userdetailsbyuserid.accountInfo.createOn !=='NA' ?  new Date(userdetailsbyuserid && (userdetailsbyuserid.accountInfo.createOn)).toLocaleString("it-IT").replaceAll(",", " at:") : userdetailsbyuserid.accountInfo.createOn;
        }
        if (userdetailsbyuserid && (userdetailsbyuserid.accountInfo.updateOn)) {
            updateDate =userdetailsbyuserid && userdetailsbyuserid.accountInfo.updateOn !=='NA' ? new Date(userdetailsbyuserid && (userdetailsbyuserid.accountInfo.updateOn)).toLocaleString("it-IT").replaceAll(",", " at:") : userdetailsbyuserid.accountInfo.updateOn;
        }

        return (
            <div className='container-fluid'>

                {isLoading ? (
                    <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                ) : <>
                    {
                        success &&
                        <Modal
                            customClass={`${success ? "login-modal" : "forgot-password-modal"}`}
                            onClick={this.onClose}
                            errorFlag={success}
                        >
                            <ErrorPopup onClose={this.onClose} msg={message} errData={errdata} {...this.props} />
                        </Modal>
                    }
                    {/* {loading && <div className="text-center loader">
                        <Spinner animation="border" variant="primary" />
                        <Spinner primary={true} />
                    </div>} */}
                    <h2 className="heading">User Details & Subscription</h2>
                    <p className='lab'>Username : <span className='names'>{userdetailsbyuserid && userdetailsbyuserid.accountInfo.login}</span></p>
                    <div className='row'>
                        <div className='col-md-6 lab'><p>Create on : <span>{createDate ? createDate : ""}</span></p> </div>
                        <div className='col-md-6 lab'> <p>Update on :<span>{updateDate ? updateDate : ""}</span></p> </div>
                    </div>

                    <Tabs key="tab">
                        <TabList >
                            <Tab ><h4 className="heading">Account Info</h4></Tab>
                            <Tab ><h4 className="heading">View Subscription</h4></Tab>
                        </TabList>
                        {
                            this.props && this.props.userdetailsbyuserid &&
                            <>
                                <TabPanel key="accountinfo">
                                    <Accountinfo userDetailData={this.props && this.props.userdetailsbyuserid} userId={this.props?.location?.searchObject?.userId} {...this.props} />
                                </TabPanel>
                                <TabPanel key="viewsubscription">
                                    <ViewSubscription userSubscriptionDetail={this.props && this.props.userdetailsbyuserid} {...this.props} />
                                </TabPanel>
                            </>}
                    </Tabs>
                </>}
            </div>

        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    telCodes: state.adminReducer.telCodes,
    infoData: state.adminReducer.infoData,
    userdetailsbyuserid: state.adminReducer.userDetailsByUserId,
    userDetailsByUserIdError: state.adminReducer.userDetailsByUserIdError
});
export default connect(mapDispatchStatetoProps, {
    getPreloads,
    startLoader,
    getInfoData,
    getUserDetailDataByuserId,
    clearAdminError
})(Userdetailsubcription)
