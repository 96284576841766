import axios from "./axios";
import {
  GET_PACKAGES,
  GET_PACKAGES_ERROR,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_ERROR,
  GET_CONTENT_TYPES,
  GET_CONTENT_TYPES_ERROR,
  GET_PUBLICATIONS,
  GET_PUBLICATIONS_ERROR,
  GET_PRELOADS,
  GET_TOP_SEARCH_QUERIES,
  GET_TOP_SEARCH_QUERIES_ERROR,
  SET_CONTENT_TYPE,
  START_LOADER,
  CLEAR__ERROR,
  SET_REFERER,
  SET_SUBSCRIPTION,
  GET_TRANSACTION_STATUS,
  SEARCH_CONTENT,
  SEARCH_CONTENT_ERROR,
  SEARCH_AUTOCOMPLETE_CONTENT,
  SEARCH_AUTOCOMPLETE_CONTENT_ERROR,
  GET_ANNOUNCEMENTS_BY_LANGUAGE,
  GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR,
  CMP_PREFERENCE_LIST,
  CMP_PREFERENCE_LIST_ERROR,
  GET_CONTENT_TYPES_ALLOWED,
  SET_SEARCH_TYPE,
  START_TRANS_LOADER,STOP_TRANS_LOADER,
  PAYMENT_STATUS,
  PAYMENT_STATUS_ERROR,
  SEARCH_OBJECT,
  BADGE_DATA,
  DATE_OBJECT,
  SHOW_LOGIN_POPUP,
  ACTIVATE_SUBSCRIPTION,
  ACTIVATE_SUBSCRIPTION_ERROR,
  PAGE_NO,
  SET_SUBSCRIPTION_ERROR,
  GET_TOKEN_FROM_SESSIONID,
  GET_TOKEN_FROM_SESSIONID_ERROR,
  AD_AND_ADV_BADGE,
  HIDE_LINK_FLAG,
  PAGE_VIEW_DATA,
  CUSTOM_VIEW_DATA,
  DURATIONS,
  DOWNLOAD_BAR_INFO,
  SUB_OPTIONS_DIGITALTYPES
} from "../../config/constants/action-constants";
// import useAxios from "./axiosUse";
// import { getCookies } from "../../helpers/common";
import axiosInstance from "./axiosResp";

// import Cookies from "js-cookie";
// const baseHomeUrl = process.env.REACT_APP_HOME_BASE_URL;
// const infoService = process.env.REACT_APP_INFO_BASE_URL;
// const searchService = process.env.REACT_APP_SEARCH_BASE_URL;
// const infoService = "http://localhost:8081/api/info/v1";
const baseHomeUrl = "http://localhost:5000";
  //  console.log(getCookies("access_token"));
const getAnnouncements = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/announcements`);
    // console.log("announcement", res);
    dispatch({
      type: GET_ANNOUNCEMENTS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_ANNOUNCEMENTS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};

const getAnnouncementByLanguage = (data) => async (dispatch) => {
  try {

    let langData = data;
    // console.log(langData)
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/announcements?language=${langData.language}`);
    // console.log("announcement", body);
    dispatch({
      type: GET_ANNOUNCEMENTS_BY_LANGUAGE,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_ANNOUNCEMENTS_BY_LANGUAGE_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};


const getContentTypes = (content_allowed,is_seamless) => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseHomeUrl}/digital_types`);
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/digital_types,countryTelCode`);
     if(content_allowed){
      dispatch({
        type: GET_CONTENT_TYPES_ALLOWED,
        payload: { data:{'digitalTypes':res.data.digitalTypes}, content_allowed:content_allowed,is_seamless:is_seamless,isLoading: false },
      });
      dispatch({
        type: GET_PRELOADS,
        payload: { data: {'countryTelCode':res.data.countryTelCode}, isLoading: false },
      });
     }  else{
       dispatch({

         type: GET_CONTENT_TYPES,
         payload: { data:{'digitalTypes':res.data.digitalTypes},isLoading: false },
       });
       dispatch({
        type: GET_PRELOADS,
        payload: { data: {'countryTelCode':res.data.countryTelCode}, isLoading: false },
      });
     }
  } catch (err) {
    // console.log("tuu", err);
    dispatch({
      type: GET_CONTENT_TYPES_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const getPublications = () => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseHomeUrl}/publication`);
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/publication`);
    dispatch({
      type: GET_PUBLICATIONS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_PUBLICATIONS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
};

const getPreloads = () => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseHomeUrl}/countryTelCode`);
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/countryTelCode`);
    // console.log("telcodes",res)
    dispatch({
      type: GET_PRELOADS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_PUBLICATIONS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const getTopSearchQueries = (searchData) => async (dispatch) => {
  try {
    // const res = await axios.post(`${baseHomeUrl}/topquery`, searchData);
    const res = await axios.post(`${process.env.REACT_APP_SEARCH_BASE_URL}/topquery`,searchData);
    dispatch({
      type: GET_TOP_SEARCH_QUERIES,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_TOP_SEARCH_QUERIES_ERROR,
      payload: err.response ? err.response.data.errors : "CORS Error"
    });
  }
}

const searchAutocomplete = (searchData,clear) => async (dispatch) => {
  if(!clear){
  try {
    // const res = await axios.post(`${baseHomeUrl}/completionSuggester`, searchData);
    const res = await axios.post(`${process.env.REACT_APP_SEARCH_BASE_URL}/completionSuggester`,searchData);
    dispatch({
      type: SEARCH_AUTOCOMPLETE_CONTENT,
      payload: { data: res.data},
    });
  } catch (err) {
    dispatch({
      type: SEARCH_AUTOCOMPLETE_CONTENT_ERROR,
      payload: err.response ? err.response.data.errors : "CORS Error"
    });
  }
}else{
  dispatch({
    type: SEARCH_AUTOCOMPLETE_CONTENT,
    payload: { data: [], isLoading: false },
  });
}
}

const searchContent = (searchData) => async (dispatch) => {
  try {
    // let ax=useAxios(false);
    // let id_token=getCookies("id_token");
    // const config = {
    //   headers: {
    //     IDTOKEN: id_token ? `${id_token}` : '',
    //   }
    // }
    const res = await axiosInstance.post(`${process.env.REACT_APP_SEARCH_BASE_URL}/searchsvc`, searchData);
    dispatch({
      type: SEARCH_CONTENT,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    if(err.response){
      // debugger;
      if(err.response.data.errorType==="400 BAD_REQUEST"){
        dispatch({
          type: SEARCH_CONTENT_ERROR,
          payload: err.response ? err.response.data.errors : "CORS Error" ,isLoading: false,isTransLoader: false
        });
        dispatch({
          type: SEARCH_CONTENT,
          payload: { data: {totalCount:0,aggregation:{}}, isLoading: false },
        });

      }else{
        dispatch({
          type: SEARCH_CONTENT_ERROR,
          payload: err.response ? err.response.data.errors : "CORS Error" ,isLoading: false,isTransLoader: false,dataflag:false
        });
      }
    }
    else{
      
    }
  }
}

const resetSearchContent = () => (dispatch) => {
  dispatch({
    type: SEARCH_CONTENT,
    payload: { data: null, isLoading: false },
  });
  dispatch({
    type: SEARCH_CONTENT_ERROR,
    payload: null
  });
};

const setContentType = (contentType) => async (dispatch) => {
  dispatch({
    type: SET_CONTENT_TYPE,
    payload: contentType
  });
}

const getSubscriptionPackages = () => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseHomeUrl}/web_packages`);
    const res = await axios.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/web_packages,countryTelcode`);
    console.log(res.data)
    dispatch({
      type: GET_PACKAGES,
      payload: { data: {'basePackages':res.data.basePackages}, isLoading: false },
    });
    dispatch({
      type: GET_PRELOADS,
      payload: { data: {'countryTelCode':res.data.countryTelCode}, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_PACKAGES_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const addSubscription = (data, callback) => async (dispatch) => {
  try {
    // const res = await axios.post(`${baseHomeUrl}/signUp`, data);
    const res = await axios.post(`${process.env.REACT_APP_HOME_BASE_URL}/signUp`, data);
    await callback(res.data);
    dispatch({
      type: SET_SUBSCRIPTION,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: SET_SUBSCRIPTION_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const getTransactionStatus = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${baseHomeUrl}/callback`, data);
    dispatch({
      type: GET_TRANSACTION_STATUS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_PUBLICATIONS_ERROR,
      payload: err.response ? err.response.data.errors : "API Failed"
    });
  }
}

const setRefererIP = (data) => async (dispatch) => {
  dispatch({
    type: SET_REFERER,
    payload: data
  });
}

const startLoader = () => async (dispatch) => {
  dispatch({
    type: START_LOADER,
    payload: {isLoading: true },
  });
};

const clear_Error = () => async (dispatch) => {
  dispatch({
    type: CLEAR__ERROR,
  });
}

const getCmpPreferenceList =() => async (dispatch) =>{
  try {
    // const res = await axios.get(`${baseHomeUrl}/cmpPreferenceList`);
    const res = await axios.get(`${process.env.REACT_APP_CMP_URL}/list`);
    dispatch({
      type: CMP_PREFERENCE_LIST,
      payload: {data:res.data}
    })
  } catch (err) {
    if (err.response.status === 500) {
      dispatch({
        type: CMP_PREFERENCE_LIST_ERROR,
        payload: { data: err.response ? err.response.data.errors : "API Failed" }
      });
    } else {
      dispatch({
        type: CMP_PREFERENCE_LIST_ERROR,
        payload: { data: err.response ? err.response.data.error : "API Failed" }
      });
    }
  }
}

const setsearchtype= (flag) =>async (dispatch) =>{
    dispatch({
      type:SET_SEARCH_TYPE,
      payload : {data:flag}
    })
}

const startTransLoader = () => async (dispatch) => {
  dispatch({
    type: START_TRANS_LOADER,
    payload: {isLoading: true },
  });
};

const stopTransLoader = () => async (dispatch) => {
  dispatch({
    type: STOP_TRANS_LOADER,
    payload: {isLoading: false },
  });
}
const getPaymentstatus = (transationId) => async (dispatch) => {
  const res = await axios.get(`${process.env.REACT_APP_IPS_URL}/${transationId}`);
  // debugger
  try {
    dispatch({
      type: PAYMENT_STATUS,
      payload: { data: res.data, isLoading: false },
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_STATUS_ERROR,
      payload: { data: res.data, isLoading: false },
    });
  }
}

const setSearchObject = (searchObject) =>async (dispatch) => {
  if(searchObject){
  try {
    // console.log(searchObject)
    dispatch({
      type: SEARCH_OBJECT,
      payload: { data: searchObject},
    });
  } catch (error) {
    // console.log(error)
  }
}
}

const getBadgeData = (data) =>async (dispatch) => {
  try {
    dispatch({
      type: BADGE_DATA,
      payload :{data: data}
    })
  }catch (error) {
    // console.log(error);
  }
}
const setDateObject = (dateObject) =>async (dispatch) => {
  // startDate
  // endDate
  let obj={}
  if(dateObject && dateObject?.startDate && dateObject?.endDate){
      if(new Date(dateObject?.startDate) > new Date(dateObject?.endDate)){
                // dateObject.endDate=dateObject.startDate;
                obj.startDate= dateObject.startDate;
                obj.endDate= dateObject.startDate
      }else{
        if(dateObject?.startDate && dateObject?.endDate){
          obj=dateObject;
        }
      }
  try {
    dispatch({
      type: DATE_OBJECT,
      payload: { data: obj},
    });
  } catch (error) {
    // console.log(error)
  }
}
}

const showLoginpopup =(flag)=>async (dispatch)=>{
   dispatch({
     type:SHOW_LOGIN_POPUP,
     payload:flag
   })
}
const getActiveSubscription =(key) =>async (dispatch)=>{
  const res = await axios.get(`${process.env.REACT_APP_HOME_BASE_URL}/activateAccount/${key}`);
  try {
    dispatch({
      type:ACTIVATE_SUBSCRIPTION,
      payload:{data:res.data?.message,isLoading:false}
    })
  } catch (err) {
    // console.log(err.response.status,err.response.data)
    if (err.response.status === 500) {
      dispatch({
        type: ACTIVATE_SUBSCRIPTION_ERROR,
        payload: { data: err.response ? err.response.data.errors : "API Failed",isLoading:false }
      });
    } else {
      dispatch({
        type: ACTIVATE_SUBSCRIPTION_ERROR,
        payload: { data: err.response ? err.response.data.error : "API Failed" ,isLoading:false}
      });
    }
  }

}

const setPageNo = (obj)=>(dispatch)=>{
  if(obj){
  dispatch({
    type:PAGE_NO,
    payload:obj
  })
}
}
const startsearchLoader =()=>(dispatch)=>{
  dispatch({
    type: START_LOADER,
    payload: {isLoading: true },
  });
}

const getTokenFromSessionId =(sessionid)=>async(dispatch)=>{

  console.log(sessionid)
  const res = await axios.get(`${process.env.REACT_APP_HOME_BASE_URL}/get-session?sessionId=${sessionid}`);
  console.log(res);
  try {
    dispatch({
      type: GET_TOKEN_FROM_SESSIONID,
      payload: { data: res.data?.message, isLoading: false }
    })
  } catch (err) {
    console.log(err)
    if (err.response.status === 500) {
      dispatch({
        type: GET_TOKEN_FROM_SESSIONID_ERROR,
        payload: { data: err.response ? err.response.data.errors : "API Failed", isLoading: false }
      });
    } else {

      dispatch({
        type: GET_TOKEN_FROM_SESSIONID_ERROR,
        payload: { data: err.response ? err.response.data.error : "API Failed", isLoading: false }
      });
    }
  }

}
const getsetadandAdvdata=(badge)=>(dispatch)=>{
  dispatch({
    type: AD_AND_ADV_BADGE,
    payload: {data:badge}
  });
}
const getandSethidelinkflag=(flag)=>(dispatch)=>{
  dispatch({
    type:HIDE_LINK_FLAG,
    payload :flag
  })
}
const pageViewDatagtm=(obj,searchObj)=>(dispatch)=>{
  let keywordsobj={};
  if(searchObj?.keywords){
    keywordsobj.term=searchObj?.keywords ? searchObj?.keywords : ""
  }
  if(searchObj?.caption?.length > 0){
    keywordsobj.caption=searchObj?.caption ? searchObj?.caption.toString() : "";
  }
  if(searchObj?.company?.length > 0){
    keywordsobj.company=searchObj?.company ? searchObj?.company.toString() : "";
  }
  if(searchObj?.pageNo){
    keywordsobj.page=searchObj?.pageNo;
  }
  if(searchObj?.byline){
    keywordsobj.by=searchObj?.byline;
  }
  if(searchObj?.section){
    keywordsobj.section=searchObj?.section ? searchObj?.section.toString() : "";
  }
  if(searchObj?.advertiser){
    keywordsobj.advertiser=searchObj?.advertiser ? searchObj?.advertiser.toString() : "";
  }

  // console.log(obj,searchObj);

  let keywordsobjj=Object.keys(keywordsobj).length===0 ? "" :JSON.stringify(keywordsobj);
  // console.log({
  //   'event': 'custom_pv', // Fixed, DO NOT CHANGE
  //   'level2': obj.level2,
  //   'url': obj.url,
  //   'title': obj.title,
  //   'search_date_from': searchObj?.dateRange?.fromDate ? searchObj?.dateRange?.fromDate :"",
  //   'search_date_to': searchObj?.dateRange?.toDate ? searchObj?.dateRange?.toDate : "",
  //   'publication': searchObj?.publication ?  searchObj?.publication.toString() : "",
  //   'company': searchObj?.company ? searchObj?.company.toString() : "",
  //   // 'section': searchObj?.section ? searchObj?.section.toString() : "",
  //   'search_keyword':keywordsobjj,
  //   'user_status': obj.user_status,
  //   'visitorcat': obj.visitorcat,
  //   'contenttype': obj.contentType,
  //   'author': obj.author,
  //   'pubdate': obj.pubdate,
  //   'at':obj?.at ? obj?.at : "",
  //   'has_search_results':obj.has_search_results
  // })
  // debugger
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'custom_pv', // Fixed, DO NOT CHANGE
    'level2': obj.level2,
    'url': obj.url,
    'title': obj.title,
    'search_date_from': searchObj?.dateRange?.fromDate,
    'search_date_to': searchObj?.dateRange?.toDate,
    'publication': searchObj?.publication ?  searchObj?.publication.toString() : "",
    'company': searchObj?.company ? searchObj?.company.toString() : "",
    'section': searchObj?.section ? searchObj?.section.toString() : "",
    'search_keyword': keywordsobjj,
    'user_status': obj.user_status,
    'visitorcat': obj.visitorcat,
    'contenttype': obj.contentType,
    'author': obj.author,
    'pubdate': obj.pubdate,
    'at':obj?.at ? obj?.at : "",
    'has_search_results':obj.has_search_results

  });

  dispatch({
    type: PAGE_VIEW_DATA,
    payload: obj
  })
}

const customViewDatagtm = (obj, searchObj) => async (dispatch) => {
  let keywordsobj={};
  if(searchObj?.keywords){
    keywordsobj.term=searchObj?.keywords ? searchObj?.keywords : ""
  }
  if(searchObj?.section?.length > 0){
    keywordsobj.section=searchObj?.section ? searchObj?.section.toString() : "";
  }
  if(searchObj?.company?.length > 0){
    keywordsobj.company=searchObj?.company ? searchObj?.company.toString() : "";
  }
  if(searchObj?.pageNo){
    keywordsobj.page=searchObj?.pageNo;
  }
  if(searchObj?.byline){
    keywordsobj.by=searchObj?.byline;
  }
  if(searchObj?.section){
    keywordsobj.section=searchObj?.section ? searchObj?.section.toString() : "";
  }
  if(searchObj?.advertiser){
    keywordsobj.advertiser=searchObj?.advertiser ? searchObj?.advertiser.toString() : "";
  }

  let keywordsobjj=Object.keys(keywordsobj).length===0 ? "" :JSON.stringify(keywordsobj);
  // console.log({
  //   'event': 'custom_event', // Fixed, DO NOT CHANGE
  //   'eventCategory': obj.eventCategory,
  //   'eventAction': obj.eventAction,
  //   'eventLabel': obj.eventLabel,
  //   'level2': obj.level2,
  //   'url': obj.url,
  //   'search_date_from': searchObj?.dateRange?.fromDate,
  //   'search_date_to': searchObj?.dateRange?.toDate,
  //   'publication': searchObj?.publication ? searchObj?.publication.toString() : "",
  //   'search_keyword': keywordsobjj,
  //   'user_status': obj.user_status,
  //   'visitorcat': obj.visitorcat,
  //   'contenttype': obj.contenttype,
  //   'author': obj.author,
  //   'pubdate': obj.pubdate,
  //   'at':obj?.at ? obj?.at : ""
  // })
  // debugger
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'custom_event', // Fixed, DO NOT CHANGE
    'eventCategory': obj.eventCategory,
    'eventAction': obj.eventAction,
    'eventLabel': obj.eventLabel,
    'level2': obj.level2,
    'url': obj.url,
    'search_date_from': searchObj?.dateRange?.fromDate,
    'search_date_to': searchObj?.dateRange?.toDate,
    'publication': searchObj?.publication ? searchObj?.publication.toString() : "",
    'search_keyword':keywordsobjj,
    'user_status': obj.user_status,
    'visitorcat': obj.visitorcat,
    'contenttype': obj.contenttype,
    'author': obj.author,
    'pubdate': obj.pubdate,
    'at':obj?.at ? obj?.at : ""
  });
  dispatch({
    type: CUSTOM_VIEW_DATA,
    payload: obj
  })
}
const setDurations=(duration)=>async(dispatch)=>{
  dispatch({
    type:DURATIONS,
    payload:duration
  })
}
const getdownloadBarInfo=(flag,data)=>async(dispatch)=>{
  let dddd= data.filter((item)=>item?.complete ===false);
  let obj={flag:flag,data:[data],show:dddd.length > 0 ? true : false}
   dispatch({
    type:DOWNLOAD_BAR_INFO,
    payload:obj
   })

}
const getSubDigitalType=(val,ind)=>async(dispatch)=>{
   dispatch({
    type:SUB_OPTIONS_DIGITALTYPES,
    payload:{value :val ,index :ind}
   })
}
export {
  startLoader,
  getAnnouncements,
  getContentTypes,
  getPublications,
  getPreloads,
  getTopSearchQueries,
  getSubscriptionPackages,
  setContentType,
  clear_Error,
  setRefererIP,
  addSubscription,
  searchAutocomplete,
  searchContent,
  getTransactionStatus,
  getAnnouncementByLanguage,
  getCmpPreferenceList,
  setsearchtype,
  startTransLoader,
  stopTransLoader,
  getPaymentstatus,
  setSearchObject,
  getBadgeData,
  setDateObject,
  showLoginpopup,
  getActiveSubscription,
  setPageNo,
  startsearchLoader,
  getTokenFromSessionId,
  getsetadandAdvdata,
  getandSethidelinkflag,
  pageViewDatagtm,
  customViewDatagtm,
  setDurations,
  getdownloadBarInfo,
  getSubDigitalType, 
  resetSearchContent
};
