import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import reactReferer from "react-referer";
import { decodeToken } from "react-jwt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import {
  Logo,
  UserIcon, adminusericon,
  AddUserIcon, menIcon,
  LoggedIn,
} from "../../assets/images/index";
import { getIcon, getContentData, reverseDate, getDate, removeAllCookies, getPageviewobj, getCustomviewobj } from "../../helpers/common";
import { ButtonElement, Modal, Spinner } from "../UI/index";
import { Login, ForgotPassword, ResetPassword } from "../Login/index";
import {
  getContentTypes,
  setContentType,getdownloadBarInfo,getSubDigitalType,
  clear_Error,getandSethidelinkflag,pageViewDatagtm,customViewDatagtm,
  setRefererIP, startsearchLoader,getsetadandAdvdata,setDurations,
  setsearchtype, searchContent, setSearchObject, getBadgeData, setDateObject, showLoginpopup
} from "../../redux/actions/applicationsAction";
import { clearLoginError, getUserDetails, logout, oncloseLogout_popup, startLoader } from "../../redux/actions/loginAction";
import { clearError } from "../../redux/actions/userAction";
import "./css/navbar.scss";
import Popup from "../Admin/Popup/Popup";
import LogoutPopup from "../Admin/Popup/LogoutPopup";
import ScrollToTop from "../UI/ScrollToTop/ScrollToTop";
import InternetConnection from "../../navigation/InternetConnection";
import Downloader from "../Downloader";
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
class MainNavbar extends Component {
  state = {
    modal: false,
    login: false,
    loggedIn: false,
    resetPassword: false,
    toggle: false,
    referer: reactReferer.referer(),
    isAdmin: false,
    iscloselogoutPopUp: false,
    onLogoutSuccessPopup: false,
    adminlogoutpopup: false,
    navexpnded: false,
    userlogoutpopup: false,
    user: {}

  };
  async componentDidMount() {
    if (this.props.location.pathname.includes("admin") || JSON.parse(process.env.REACT_APP_ISADMIN)) {
      const ls = localStorage.getItem("token");
      if (ls) {
        this.setState({
          isAdmin: true,
          loggedIn: true
        })
      } else {
        this.setState({
          isAdmin: true,
        })
      }
      this.props.setsearchtype(true);
    }
    if (!JSON.parse(process.env.REACT_APP_ISADMIN)) {

      // if(!this.props?.userTokens?.id_token){
      await this.props.getUserDetails();
    // }
      const tokls = localStorage.getItem("token");
      const toklseamless = localStorage.getItem("seamlessFlag");
      let id_token;
      if (tokls || toklseamless) {
        id_token = this.props?.userTokens?.id_token;
      }
      const accessTokenAdmin = this.props.userTokens?.accessTokenAdmin;
      if (accessTokenAdmin) {
        document.cookie = `accessTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }
      if (id_token) {
        if (this.props.userTokens) {
          const myDecodedToken = decodeToken(id_token);
          this.setState({
            user: myDecodedToken && myDecodedToken,
            loggedIn: true,
            isAdmin:false
          });
          if (myDecodedToken?.user_info.hide_links) {
             this.props.getandSethidelinkflag(true);
            await this.props.getContentTypes(myDecodedToken?.user_info?.content_allowed, myDecodedToken?.user_info.is_seamless);
          } else {
            await this.props.getContentTypes();
          }
          if (myDecodedToken.user_info?.search_type?.includes("ad_search") && !myDecodedToken.user_info?.search_type?.includes("general_search")) {
            this.props.setsearchtype(false);
          }
          // else {
          //   this.props.setsearchtype(true);
          // }
          if (myDecodedToken.user_info?.search_type?.includes("ad_search")) {
            if (this.props.location.pathname.includes("/ad-search")) {
              await this.props.setsearchtype(false);
            }
          }
        }
        else {
          localStorage.clear();
          this.props.history.push("/");
        }
      } else {
        await this.props.getContentTypes();
        // this.props.setsearchtype(true);
      }
      if (!this.props.referer) {
        this.props.setRefererIP({
          referer: reactReferer.referer(),
        });
      }

        const { contentTypes } = this.props;
        const contentData = getContentData(contentTypes, this.props.contentTypes[0]?.key);

        await this.props.setContentType({
          digitalType: this.props.contentTypes[0],
         extraData: contentData?.extraData[0]
         });
    }
    this.setState({
      iscloselogoutPopUp: this.props.logout_popup,
      login: true
    })
    const statusShown = localStorage.getItem("statusShown");
    if (statusShown) {
      localStorage.removeItem("ipsTxnId");
      localStorage.removeItem("statusShown");
    }


  }

  clearError = async () => {
    if (this.props?.error_for_okta && this.state.isAdmin) {
    } else {
      await this.props.clearError();
      await this.props.clear_Error();
      await this.props.clearLoginError();
    }
  }

  onClose = async () => {
      if(this.props.location?.pathname?.includes("activateAccount")){
        this.props.history.push('/')
      }
    !this.props.loading &&
      this.setState({
        modal: !this.state.modal,
        login: true,
        resetPassword: false,
      });
    await this.props.showLoginpopup(!this.props.showloginpopup);
    if(this.props.showloginpopup){
      this.setPageViewcallforlogin(this.props.searchObject,{},"login","Login",`/search/virtual/login`)
    }
  }
 setPageViewcallforlogin=(searchObject,myDecodedToken,lavel2,title,urladd)=>{

    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : "";
    let url=window.location.origin+urladd
    let obj= getPageviewobj(url,lavel2,title,user_status,"","","",id);
    this.props.pageViewDatagtm(obj,searchObject)
  }
  onChange = () =>{
    this.setState({
      login: false,
    });
    this.setPageViewcallforlogin(this.props.searchObject,{},"forgot password","Forgot Password",`/search/virtual/forgot-password`)

  }

  onResetPasswordSuccess = () =>
    this.setState({
      resetPassword: true,
    });

  gotoUserProfile = () => {
    this.props.history.push("/user-account")
    this.setState({
      toggle: false,
    });
  };
  clickUserProfile = () => {

    this.setState({
      toggle: false,
    });
  };
  onSubscription = () => this.props.history.push("/subscription");

  openMenu = () =>
    this.setState({
      toggle: !this.state.toggle,
    });

  setContentType = async (contentType) => {
    // this.props.getsetadandAdvdata([]);
    window.removeEventListener("keyup", (event) => {
      console.log("")
    })
    this.props.startsearchLoader();

    const { contentTypes } = this.props;
    const contentData = getContentData(contentTypes, contentType);
    await this.props.setContentType({
      digitalType: contentType,
      extraData: contentData.extraData[0]
    });
    if (window.innerWidth < 575) {
      this.setNavExpanded();
    }
    // if(!this.props?.userTokens?.id_token){
      await this.props.getUserDetails();
    // }
    const id_token = this.props?.userTokens?.id_token;
    const myDecodedToken = decodeToken(id_token);


    if (contentData.extraData[0].digitalType !== "all") {
      const { extraData, searchObject } = this.props.applicationReducer;
      if (searchObject?.pdfPageNo) {
        delete searchObject.pdfPageNo;
      }
      if (searchObject?.searchTerm) {
        delete searchObject.searchTerm;
      }

      let searchObjects = this.props?.searchObject ? this.props?.searchObject : {};
      searchObjects.digitalType = contentData.extraData[0].digitalType;
      searchObjects.sourceType = contentData.extraData[0].sourceType;


      if (!searchObject?.dateRange?.fromDate || !searchObject?.dateRange?.toDate ) {

        let startDate = getDate(true, 2);
        let endDate = new Date();
        let fromd = startDate.toLocaleDateString('en-GB');
        let tod = endDate.toLocaleDateString('en-GB');


        searchObjects.dateRange = {};
        searchObjects.dateRange["fromDate"] = reverseDate(fromd.replaceAll("/", "-"));
        searchObjects.dateRange["toDate"] = reverseDate(tod.replaceAll("/", "-"));


        this.props.setDateObject({ startDate: startDate, endDate: endDate });
      }

      searchObjects.pageNo = 1;
      searchObjects.sortBy= "publicationdate";
      searchObjects.sortOrder="desc";
      this.props.setDurations("0")
      if (searchObjects?.publication?.length > 0) {
        let publication = [...new Set(searchObjects.publication)];
        searchObjects.publication = publication;
      } else {
        searchObjects.publication = [];
        let pubdata = myDecodedToken?.user_info?.pub_list;
        if (pubdata) {
          searchObjects.publication = searchObjects.publication.concat(pubdata);
        }
        let publication = [...new Set(searchObjects.publication)];
        searchObjects.publication = publication;
      }

      // let pubdata = setPublistdata(myDecodedToken?.user_info?.pub_list, searchObject?.publication)
      // searchObjects.publication = pubdata;

      if(this.props.subDigitalType==="HERITAGE"){
        await this.props.getSubDigitalType("ALL", 0);
        searchObjects.section = [];
        searchObjects.publication = [];
        this.props.getBadgeData({ sectionDatalist: [], badgeData: {} })
      }


      if (extraData.digitalType !== "all") {
        if (this.props.history.location.pathname.includes("/search")) {
          await this.props.searchContent(searchObjects);
          this.setPageViewcall(searchObjects,myDecodedToken);
        } else {
          this.props.history.push({
            pathname: `/search`,
            searchObject: searchObjects,
            showTabs: myDecodedToken ? myDecodedToken?.user_info?.pub_list : [],
            notvabarcall:false
          })
          this.props.startsearchLoader();
          this.setPageViewcall(searchObjects,myDecodedToken);
        }
      }

      // await this.props.getBadgeData({ sectionDatalist: [], badgeData: {} });
    } else {
      this.props.history.push({
        pathname: `/`
      })
    }

  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : "";
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let url=window.location.href+`#${this.props.digitalType.toLowerCase()}`
    let obj= getPageviewobj(url,this.props.digitalType.toLowerCase(),"",user_status,"","","",id);
    this.props.pageViewDatagtm(obj,searchObject)
  }

  logout = () => {

    this.setState({
      loggedIn: false,
      userlogoutpopup: false,
      user: {}
    }, this.callBackforLogout);
  };

  callBackforLogout = async () => {
    if (!this.props.location.pathname.includes("admin") || !JSON.parse(process.env.REACT_APP_ISADMIN)) {
      this.setState({
        isAdmin: false
      })
      await this.props.logout();
      if (this.props?.successResponse?.status === 200) {
        this.props.getUserDetails(true);
        localStorage.removeItem("token");
        localStorage.removeItem("seamlessFlag");
        this.props.history.push("/");
        window.location.reload(true);
      } else {
        removeAllCookies();
        localStorage.removeItem("token");
        localStorage.removeItem("seamlessFlag");
        this.props.history.push("/");
        window.location.reload(true);
      }

    } else {
      this.setState({
        adminlogoutpopup: true
      })

    }
    this.customViewcall(this.props.searchObject,{})
  }
  customViewcall=(searchObject,token)=>{
    let user_status=token?.user_info?.user_type ? token?.user_info?.user_type:"" ;
    let id=token?.user_info?.visitor_id ? token?.user_info?.visitor_id : ""
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate)
    let objj= getCustomviewobj("logout","click","","login","",user_status,"","","",id)
    this.props.customViewDatagtm(objj,searchObject)
  }
  afterlogoout = async () => {

    const oktaflag = JSON.parse(localStorage.getItem("okta-token-storage"));
    if (!oktaflag?.idToken) {
      removeAllCookies(true);
      this.props.history.push("/");
      window.location.reload(true);
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("seamlessFlag");
      removeAllCookies(true);
      this.setState({
        adminlogoutpopup: false
      })
      await this.props.oktaAuth.signOut();
      await this.props.logout(true);
    }
    this.customViewcall(this.props.searchObject,{})

  }


  closeLogoutPopup = async () => {
    await this.props.oncloseLogout_popup();
    this.setState({
      iscloselogoutPopUp: this.props.logout_popup,
      user: {}
    }, this.callBackforCloserLogout)

  }
  callBackforCloserLogout = () => {
    if (!this.props.location.pathname.includes("admin") || !JSON.parse(process.env.REACT_APP_ISADMIN)) {
      this.props.logout();
      this.setState({
        isAdmin: false
      })
      if (this.props.successResponse === 200) {
        this.props.getUserDetails(true);
        removeAllCookies(false);
        localStorage.clear();
        this.props.history.push("/");
        window.location.reload(true);
      } else {
        removeAllCookies(false);
        localStorage.clear();
        this.props.history.push("/");
        window.location.reload(true);
      }
    } else {
      this.afterlogoout();
    }
  }
   gotoAdSearch = async(flag) => {
    if(flag !==this.props.setSearchtype){
      this.props.getsetadandAdvdata([]);
      const { contentTypes } = this.props;
      const contentData = getContentData(contentTypes, this.props?.contentTypes[0]?.key);
      await this.props.setContentType({
        digitalType: this.props.contentTypes[0],
       extraData: contentData?.extraData[0]
       });
      this.props.getBadgeData({ sectionDatalist: [], badgeData: {} });
      let searchobj=this.props.searchObject;
      if(searchobj?.publication){
      searchobj.publication=[];
      //to set prefernce value
      // if (this.props?.userTokens?.id_token) {
      //   const myDecodedToken = decodeToken(this.props?.userTokens?.id_token);
      //   let pubdata = myDecodedToken?.user_info?.pub_list;
      //   searchobj.publication = pubdata;
      // }
      //====//
      this.props.setSearchObject(searchobj);
    }
    }

    if (flag) {
      this.props.history.push("/advanced-search");
    } else {
      this.props.history.push({
        pathname: "/ad-search",
      });
    }
    this.props.setsearchtype(flag);
  }
  closeAdminLogoutPopup = () => {
    this.setState({
      adminlogoutpopup: false,
      loggedIn: true
    })
  }
  setNavExpanded() {
    this.setState({ navExpanded: !this.state.navExpanded });
  }
  closeUserLogoutPopup = () => {
    this.setState({
      userlogoutpopup: false
    })
  }
  openLogoutPopup = () => {
    this.setState({
      userlogoutpopup: true
    })

  }


  render() {
    const { login, user, loggedIn, resetPassword, isAdmin, navExpanded } = this.state;
    const {
      loginError,
      user_error,
      loading,
      successResponse,
      error,
      digitalType,
      contentTypes,
      adminLoginSuccess,
      admin_login_error, setSearchtype, isLoading, isLogutLoader,isLoadingPreference
    } = this.props;
    const isEmpty = Object.values([
      loginError,
      user_error,
      error
    ]).some((x) => x);
    const style = {
      display: this.state.toggle ? "block" : "none",
    };
    const showred = (this.props.location.pathname.includes("/articleDetails") || this.props.location.pathname.includes("/search") || this.props.location.pathname === "/") ? true : false;
    const isSeamlessFlag = localStorage.getItem("seamlessFlag") ? true : false;

    return (
      <div className="main-navbar-container" key="mainnavbars">
        {isLogutLoader && <Spinner primary={true} transparent={true} ></Spinner>}
        {isLoadingPreference &&  <div className="tranparentscreen"></div>}
        {this.state.userlogoutpopup && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closeUserLogoutPopup()} logoutClass={true}>
          <LogoutPopup titles="Do you want to logout ?" onProceed={this.logout} onClose={this.closeUserLogoutPopup}  {...this.props} />
        </Modal>}
        {isAdmin && <>
          {isLoading && <Spinner primary={true} transparent={true} ></Spinner>}
          {this.props?.successResponse?.status === 200 && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.afterlogoout()}>
            <Popup titles={this.props?.successResponse?.data.message} onClose={this.afterlogoout} {...this.props} />
          </Modal>}
          {this.props?.error_for_okta && this.props?.error_for_okta !== "unauthorized" && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.afterlogoout()}>
            <Popup titles={this.props?.error_for_okta} onClose={this.afterlogoout} {...this.props} />
          </Modal>}
          {this.state.adminlogoutpopup && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closeAdminLogoutPopup()} logoutClass={true}>
            <LogoutPopup titles="Do you want to logout ?" onProceed={this.afterlogoout} onClose={this.closeAdminLogoutPopup}  {...this.props} />
          </Modal>}
        </>}
        {this.props.logout_popup && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closeLogoutPopup()}>
          <Popup titles="Session time out" onClose={this.closeLogoutPopup} {...this.props} />
        </Modal>}
        {(!this.props?.error_for_okta !== "unauthorized" && !this.props.logout_popup) && <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={isEmpty}
          autoHideDuration={4000}
          onClose={this.clearError}
        >
          {!successResponse || !adminLoginSuccess ? (
            <Alert severity="error">
              {loginError || user_error ||
                admin_login_error ||
                error
              }
            </Alert>
          ) : (
            <Alert severity="success">{successResponse || adminLoginSuccess}</Alert>
          )}
        </Snackbar>}
        <div className="col-12 col-md-11 offset-lg-1 navbar-container p-0 pt-1 pb-1">
          <div className={isAdmin ? "d-inline-block col-md-6 col-sm-12" : "d-inline-block col-md-4 col-4"}>
            <Link to={user?.user_info?.is_seamless === true ? "/user-account" : "/"} key="logo" >
              {/* <Link to={"/"} key="logo" > */}

              <img src={Logo} alt="logo" className="logo" />
            </Link>

          </div>
          <div className={isAdmin ? "align-middle col-sm-12 col-md-5 d-inline-block ml-lg-n4 mt-2 " : "align-middle col-8 col-md-8 d-inline-block ml-lg-n4 mt-2"}>

            {!isAdmin && loggedIn &&
              <>
                {(user?.user_info?.hide_panel === false) && <ButtonElement
                  variant="outlined"
                  btnType="transparent-black button-label"
                  className="ml-2 userAction"
                  onClick={this.openLogoutPopup}
                >
                  <img src={LoggedIn} alt="logo" />
                </ButtonElement>}
                <ButtonElement
                  variant="outlined"
                  btnType="transparent-black button-label"
                  className={isAdmin ? "userAction" : "ml-2 userAction"}
                  onClick={this.gotoUserProfile}
                >
                  <img src={UserIcon} alt="logo" />
                </ButtonElement>

                <div className="icon-box">
                  <div className="arrow-box">
                    <span
                      className={isAdmin ? "arrow-span float-right mt-2" : "arrow-span float-right  ml-3 mr-2"}
                      onClick={this.openMenu}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>

                    {/* {<span className="float-right mr-0 userName">
                      <span className="chingle mr-0">Hi &nbsp;</span>
                      <b className="">{user && !isSeamlessFlag ? user.sub : "user"}</b>
                    </span>} */}
                    <div
                      className="arrow-dropdown-main-container"
                      style={style}
                    >
                      <div className="arrow-dropdown-sub-container hiinpopup"onClick={this.clickUserProfile}>
                      <div className="arrow-dropdown toshownameuser" >
                          <img src={adminusericon} alt="logo" className="icon-imgsss mr-2 d-inline" />
                          Hi &nbsp;<b>{user && !isSeamlessFlag ? user?.sub : "user"}</b>
                        </div>
                        <div  className="arrow-dropdown"  onClick={this.gotoUserProfile}>
                          <img src={UserIcon} alt="logo" className="mr-2" />
                          User Profile
                        </div>
                        {(user?.user_info?.hide_panel === false) && <div className="arrow-dropdown" onClick={this.logout}>
                          <img src={LoggedIn} alt="logo" className="mr-2" />
                          Log out
                        </div>}
                      </div>
                    </div>

                  </div>
                </div>
                <span className="userActionforhi"> <img src={adminusericon} alt="logo" className="icon-imgsss usericon" />Hi &nbsp;<b>{user && !isSeamlessFlag ? user?.sub : "user"}</b></span>
              </>
            }
            {!isAdmin && !loggedIn && <>

              {(this.props?.location?.pathname).includes("maintenance") ? (<></>) : (
                <>
                  <ButtonElement
                    variant="outlined"
                    btnType="transparent-black button-label"
                    className="d-inline-block float-right login-button"
                    onClick={this.onClose}
                  >
                    <img src={UserIcon} alt="logo" className="pr-1" />
                    <span className="button-label d-none">Login</span>
                  </ButtonElement>
                  <ButtonElement
                    variant="outlined"
                    btnType="transparent-orange button-label"
                    className="d-inline-block mr-2 float-right sign-in"
                    onClick={this.onSubscription}
                  >
                    <img src={AddUserIcon} alt="logo" className="pr-1" />
                    <span className="button-label d-none">Sign Up</span>
                  </ButtonElement>
                </>)
              }
            </>


            }

            {isAdmin && loggedIn ? (<>
              <div className="icon-box">
                <div className="arrow-box float-right fontforadwelcm">
                  {/* <span
                    className="arrow-span  mt-2"
                    onClick={this.openMenu}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span> */}
                  <span className="pr-1">
                    <img src={adminusericon} alt="logo" className="icon-imgsss fg " /></span>
                  <span className="mr-2 userName">
                    Welcome, Admin  <span className="fg">|</span>
                  </span>

                  <span className="arrow-dropdown-sub-container" style={{ cursor: "pointer" }}>
                    <span className="arrow-dropdown gf" onClick={this.logout}>
                      <img src={LoggedIn} alt="logo" className="" />

                    </span>
                    <span className="arrow-dropdown fg" style={{ textDecoration: "underline" }} onClick={this.logout}>
                      {/* <img src={adminusericon} alt="logo" className="" /> */}
                      Logout
                    </span>

                  </span>

                </div>
              </div>
            </>) : (null)
            }

          </div>
        </div>
        <div className={!isAdmin ? "lasses" : "lass"}></div>
        <div className={!isAdmin ? "navlinks-container" : "navlinks-container-admin"}>
          {
            !(this.props?.location?.pathname).includes("maintenance") ?
              <div className="col-12 col-md-11 offset-lg-1 mt-1 p-0">
                <Navbar
                  collapseOnSelect
                  expand={!isAdmin ? "sm" : ""}
                  variant={!isAdmin ? "dark" : ""}
                  className={!isAdmin ? "pl-1 p-md-0 " : ""}
                  onToggle={() => this.setNavExpanded()}
                  expanded={navExpanded}
                >

                  <Navbar.Toggle aria-controls="responsive-navbar-nav " />
                  {setSearchtype && <Navbar.Collapse key="coooo"
                    aria-controls="responsive-navbar-nav"
                    className="d-md-inline-block pt-1 "
                  >
                    <Nav >
                      {!isAdmin && contentTypes !== undefined && contentTypes.map((type, index) => {

                        return (
                          <>
                            {<Nav.Link
                              to={{ pathname: type.key === "All" && "/" }}

                              className={showred && digitalType === type.key ? "active" : ""}
                              key={type.key}
                              onClick={(e) => this.setContentType(type.key)}
                            >
                              <img
                                src={getIcon(type.key)}
                                alt="logo"
                                className="logo" key={index}
                              />
                              <span
                                className={`ml-2 navbar-text-heading ${type.key === "All" && "d-lg-none d-sm-none"
                                  }`}
                                data-typedata={
                                  this.props.contentTypes[type.extraData]
                                }
                                key={type}
                              >
                                {type.key}

                              </span>
                            </Nav.Link>}
                          </>
                        );

                      })}
                    </Nav>
                  </Navbar.Collapse>}
                  <Nav className="ml-auto navbrinline ">
                    {user?.user_info?.search_type?.includes("general_search") ? (
                      <>
                        <div className={setSearchtype ? "link-text d-inline-block  " : "d-inline-block  "}>
                          <Nav.Link href="" className={setSearchtype ? "link-text" : ""}
                            onClick={(e) => this.gotoAdSearch(true)} key="newslinksearch"
                          >
                            <span className={`ml-2 navbar-text-heading fgmen`}>
                              NewsLink
                            </span>
                            <span className={`  gfmen adcss`}>
                              <img src={menIcon} alt="logo" className="icon-imgsss" />
                            </span>
                          </Nav.Link>
                        </div>


                      </>
                    ) : (
                      ""
                    )}
                    {user?.user_info?.search_type?.includes("ad_search") ? (
                      <>
                        <div className={!setSearchtype ? "link-text d-inline-block " : "d-inline-block "}>
                          <Nav.Link href="" className={!setSearchtype ? "link-text" : ""}
                            onClick={(e) => this.gotoAdSearch(false)} key="adsearch"
                          >
                            <span className={`ml-2 navbar-text-heading  fgmen`}>
                              Ad Search
                            </span>
                            <span className={` gfmen adcss`}>
                              AD
                            </span>
                          </Nav.Link>
                        </div>{" "}

                      </>
                    ) : (
                      ""
                    )}
                  </Nav>
                </Navbar>
              </div> : null
          }
        </div>
        {this.props.showloginpopup && (
          <Modal
            customClass={`${login ? "login-modal forgot-password-modal" : "forgot-password-modal"}`}
            closeModal={this.onClose}
            loading={loading}
            onClick={() => this.onClose()}
          >
            {login ? (
              <Login
                onForgotPassword={this.onChange}
                onClose={this.onClose}
                {...this.props}
              />
            ) : (
              <>
                {resetPassword ? (
                  <ResetPassword onClose={this.onClose} {...this.props} />
                ) : (
                  <ForgotPassword
                    onResetSuccess={() => this.onResetPasswordSuccess()}
                    onClose={this.onClose}
                    {...this.props}
                  />
                )}
              </>
            )}
          </Modal>
        )}
         <div style={{zIndex:this.props.downloadBarInfo.flag ?'999999':'0'}} className={this.props.downloadBarInfo.flag ?"downloadbarmod p-0":"downloadbarmodhide p-0"} >
             {this.props?.downloadBarInfo?.show && this.props?.downloadBarInfo?.data[0].length > 0 && <Downloader files={this.props?.downloadBarInfo?.data[0]}  {...this.props}   />}
            </div>
        <ScrollToTop></ScrollToTop>
        <InternetConnection></InternetConnection>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  isLoading: state.loginReducer.isLoading,
  loginError: state.loginReducer.login_error,
  user_error: state.userReducer.user_error,
  error: state.applicationReducer.error,
  contentTypes: state.applicationReducer.contentTypes,
  digitalType: state.applicationReducer.digitalType,
  successResponse: state.loginReducer.successResponse,
  referer: state.applicationReducer.referer,
  IP: state.applicationReducer.IP,
  userTokens: state.loginReducer.tokens,
  adminLoginSuccess: state.adminReducer.adminLoginSuccess,
  admin_login_error: state.adminReducer.admin_login_error,
  setSearchtype: state.applicationReducer.setSearchtype,
  logout_popup: state.loginReducer.logout_popup,
  searchObject: state.applicationReducer.searchObject,
  error_for_okta: state.loginReducer.error_for_okta,
  showloginpopup: state.applicationReducer.showloginpopup,
  isLogutLoader: state.loginReducer.isLogutLoader,
  hide_link_flag:state.applicationReducer.hide_link_flag,
  isLoadingPreference:state.userReducer.isLoadingPreference,
  downloadBarInfo:state.applicationReducer.downloadBarInfo,
  subDigitalType:state.applicationReducer.subDigitalType
});

export default connect(mapDispatchStatetoProps, {
  getContentTypes,
  setContentType,
  setRefererIP,
  clearError,
  clear_Error,
  clearLoginError,
  getUserDetails,
  logout,
  setsearchtype,
  oncloseLogout_popup,
  searchContent,
  setSearchObject,
  getBadgeData,
  setDateObject,
  startLoader,
  showLoginpopup,
  startsearchLoader,
  getsetadandAdvdata,
  getandSethidelinkflag,
  pageViewDatagtm,
  customViewDatagtm,
  setDurations,
  getdownloadBarInfo,
  getSubDigitalType
})(MainNavbar);
