import React, { Component } from "react";
import { connect } from "react-redux";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  getPublications,setsearchtype,pageViewDatagtm,customViewDatagtm,getSubDigitalType,
  setContentType,getsetadandAdvdata,getandSethidelinkflag,setDurations,
  getTopSearchQueries, searchContent, getContentTypes, getBadgeData, setSearchObject, setDateObject
} from "../../redux/actions/applicationsAction";
import { createOptions, getDate, getContentData, reverseDate, getPublicationValues, getPageviewobj, getCustomviewobj } from "../../helpers/common";
import {
  SelectElement,
  ButtonElement,
  DatePickerElement, SearchCoumnselect, Spinner, CustomSelect, SearchSelect
} from "../UI/index";
import "./css/search.scss";
import { decodeToken } from "react-jwt";
import { getUserDetails } from "../../redux/actions/loginAction";
const Durations = [
  {
    key: "This month",
    sortOrder: "desc",
    sortBy: "publicationdate",
    value: "30"
  },
  {
    key: "This week",
    sortOrder: "desc",
    sortBy: "publicationdate",
    value: "7"
  },

];

class AdvancedSearch extends Component {
  state = {
    startDate: getDate(true, 2),
    endDate: new Date(),
    maxDate: new Date(),
    minDate: getDate(true, 2),
    searchObject: {
      dateRange: {
        fromDate: "",
        toDate: "",
      },
      keywords: "",
      sortBy: "publicationdate",
      sortOrder: "desc",
      publication: [],
      pdfPageNo:"",
      pageSize: 10,
      sourceType: "",
      digitalType: ""
    },
    pubListData: {},
    inputShow: "",
    selectedData: {},
    publications: {},
    showTabs: [],

  };

  async componentDidMount() {
    window.scroll(0,0);
    const { pubListData, selectedData } = this.props.location;
    const { searchObject } = this.props;
    const { digitalType } = this.props;

    // if(!this.props?.tokens?.id_token){
      await this.props.getUserDetails();
    // }
    this.props.setsearchtype(true);
    let myDecodedToken = decodeToken(this.props?.tokens?.id_token);

    if (myDecodedToken) {
      this.state.showTabs = myDecodedToken?.user_info;
      if(myDecodedToken?.user_info?.hide_links){
        this.props.getandSethidelinkflag(true);
      }
    }
    if (!this.props?.dateObject?.startDate || !this.props?.dateObject?.endDate) {
      this.props.setDateObject({ startDate: getDate(true, 2), endDate: new Date() })
    }
    if (searchObject) {
      for (let x in searchObject) {
        this.state.searchObject[x] = searchObject[x];
      }
      if (searchObject.publication.length > 0) {
        let publication = [...new Set(searchObject.publication)];
        searchObject.publication = publication;
      }else{
        if(myDecodedToken){
        searchObject.publication=myDecodedToken?.user_info?.pub_list;
         }
      }
      searchObject.keywords = searchObject?.keywords?.trim();
      // delete searchObject.pageNo;
      delete searchObject.pdfPageNo;
      delete searchObject.advertiser;
      delete searchObject.section;
      delete searchObject.caption;
      this.state.searchObject.pdfPageNo=""
      this.setState({
        searchObject: searchObject,
        // minDate: getDate(true,2),
        Duration: Durations[0].value,
        startDate: new Date(this.state.searchObject?.dateRange?.fromDate),
        endDate: new Date(this.state.searchObject?.dateRange?.toDate),
        inputShow: digitalType,
        showTabs: this.state.showTabs
      });
    }
    if (pubListData) {
      for (let x in pubListData) {
        this.state.pubListData[x] = pubListData[x];
      }
      this.setState({ pubListData })
    }
    if (selectedData) {
      for (let x in selectedData) {
        this.state.selectedData[x] = selectedData[x];
      }
      this.setState({ selectedData })
    }
    //only when reload advance page
    if (!searchObject) {
      if(myDecodedToken){

        this.state.searchObject.publication=myDecodedToken?.user_info?.pub_list;
        this.setState({
          searchObject: this.state.searchObject,
        })
      }

    }
    //===//
    await this.props.getPublications();

    this.setState({ publications: this.props.publications });
    const searchData = {
      period: Durations[0].value,
      sortOrder: "desc"
    }
    await this.props.getTopSearchQueries(searchData);
    this.setPageViewcall(searchObject,myDecodedToken);
  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let url=window.location.href;
    let obj= getPageviewobj(url,"advancedsearch","",user_status,"","","",id);
    this.props.pageViewDatagtm(obj,searchObject);
  }

  onStartDateChange = (date) => {
    this.props.setDateObject({ startDate: date, endDate: this.props?.dateObject?.endDate })

    const { dateObject } = this.props;
    let dt = new Date(date);
    // let modifieddate= `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
    let modifieddate = dt.toLocaleDateString('en-GB');
    this.state.searchObject.dateRange["fromDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = dateObject?.endDate.toLocaleDateString('en-GB');
    this.state.searchObject.dateRange["toDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    this.setState({
      searchObject: this.state.searchObject,
      startDate: date
    });
    // this.setSearchObject(searchObject);
  };

  onEndDateChange = (date) => {
    this.props.setDateObject({ startDate: this.props?.dateObject?.startDate, endDate: date })
    const { dateObject } = this.props;
    let dt = new Date(date);
    let modifieddate = dt.toLocaleDateString('en-GB');
    this.state.searchObject.dateRange["toDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = dateObject?.startDate.toLocaleDateString('en-GB');
    this.state.searchObject.dateRange["fromDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    this.setState({
      searchObject: this.state.searchObject,
      endDate: date
    });
    // this.setSearchObject(searchObject);
  };

  setContentType = async (contentType) => {
    const { contentTypes } = this.props;
    const { searchObject } = this.state;
    const contentData = getContentData(contentTypes, contentType);
    if (contentType === "Images") {
      delete searchObject["pdfPageNo"];
    }
    if (contentType === "All") {
      delete searchObject["pdfPageNo"];
      delete searchObject["byline"];
      delete searchObject["caption"];
    }
    if (contentType === "Articles") {
      delete searchObject["pdfPageNo"];
      delete searchObject["byline"];
      delete searchObject["caption"];
    }
    if (contentType === "Online") {
      delete searchObject["pdfPageNo"];
    }
    if (contentType === "ANN") {
      delete searchObject["pdfPageNo"];
    }
    if (contentType === "PDF" || contentType === "Magazine" || contentType === "Online" || contentType === "ANN") {
      delete searchObject["byline"];
      delete searchObject["caption"];
    }
    this.setState({ inputShow: contentType, searchObject })
    await this.props.setContentType({
      digitalType: contentType,
      extraData: contentData.extraData[0]
    });

  }

  setSearchObject = (searchObject) => {
    this.setState({
      searchObject
    })
    // this.props.setSearchObject(searchObject)
  }

  onChange = (value, key) => {
    const { searchObject } = this.state;
    if (key === "section") {

      searchObject[key] = [value];
    }else if(key=== "pdfPageNo"){
      let char=value.trim();
      let charval=char.charAt(0);
      if(charval==='-'){
        value=value.slice(1);
      }
      searchObject[key] = value.trim();
    } else {

      searchObject[key] = value;
    }
    this.setSearchObject(searchObject);
  };

  onSearch = async (keywords) => {
    const { dateObject } = this.props;
    const { searchObject } = this.state;
    const { extraData } = this.props.applicationReducer;
    const { contentTypes } = this.props;
    const contentData = getContentData(contentTypes, this.props.digitalType);
      await this.props.getSubDigitalType(contentData?.subCategories[0], 0);
    if (keywords) {
      searchObject.keywords = keywords.trim();
    }

    searchObject.digitalType = extraData.digitalType;
    searchObject.sourceType = extraData.sourceType;
    if (extraData.digitalType === "all") {
      searchObject.sourceType = "all";
    }
    // searchObject.contentType = this.props.contentType;
    let resfound = [];
    for (let x in searchObject) {
      if (x === "byline" || x === "section" || x === "caption") {
        if (x === "section") {
          for (let y of x) {
            if (searchObject[y])
              resfound.push(searchObject[y]);
          }
        } else {
          if (searchObject[x])
            resfound.push(searchObject[x])
        }
      }
    }

    // searchObject.section = [];
    searchObject.company = [];
    searchObject.pageNo = 1;
    // await this.props.searchContent(searchObject);
    let modifieddate = dateObject.startDate.toLocaleDateString('en-GB');
    searchObject.dateRange["fromDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = dateObject?.endDate.toLocaleDateString('en-GB');
    searchObject.dateRange["toDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    // if (searchObject.searchTerm) {
    //   searchObject.searchTerm = searchObject.searchTerm.trim();
    // }
    if (searchObject.byline) {
      searchObject.byline = searchObject.byline.trim();
    }
    if (searchObject.caption) {
      searchObject.caption = searchObject.caption.trim();
    }
    if (searchObject.pdfPageNo) {
      searchObject.pdfPageNo = searchObject.pdfPageNo.trim();
    }
    if (searchObject?.section?.length > 0) {
      searchObject.section[0] = searchObject?.section[0].trim();
      if (!searchObject.section[0]) {
        delete searchObject.section;
      }
    }
    if(searchObject.keywords){
      searchObject.keywords.trim()
    }
    searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.props.setDurations("0");
    this.sendtosearch(this.state.selectedData,true)
    this.setSearchObject(searchObject);
    // console.log(this.props.searchObject,this.state.pubListData,this.state.selectedData)
    if(this.state.selectedData){
      let alldata=[]
      for(let y in this.state.selectedData){
        for(let x of this.state.selectedData[y]){
          if(this.state?.showTabs && this.state?.showTabs?.pub_list && this.state?.showTabs?.pub_list.length > 0){
            if(x && !this.state?.showTabs?.pub_list.includes(x)){
              alldata.push(x);
            }
          }else{alldata.push(x)}
        }
      }
      // this.setState({adandAdvbadges:alldata});
      this.props.getsetadandAdvdata(alldata);
    }
       //=====gtm call=====//
      //  console.log(searchObject)
    this.customViewcall(searchObject,this.state.showTabs);
    //end gtm call
    this.props.history.push({
      pathname: '/search',
      searchObject: searchObject,
      resultfoundFor: resfound,
      pubListData: this.state.pubListData,
      selectedData: this.state.selectedData,
      showTabs: this.state.showTabs.length > 0 ? this.state.showTabs : [],
      notvabarcall:true
    })
    await this.props.getBadgeData({ sectionDatalist: [], badgeData: {} });

  };

  customViewcall=(searchObject,token)=>{
    let user_status=token?.user_type ? token?.user_type:"" ;
    let id=token?.visitor_id ? token?.visitor_id : "";
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate)
    let objj= getCustomviewobj("advanced search","click","","advanced search","",user_status,"","","",id)
    this.props.customViewDatagtm(objj,searchObject)
  }

  onDurationChange = async (period) => {
    const searchData = {
      period: period,
      sortOrder: "desc"
    }
    this.setState({
      Duration: period
    })
    await this.props.getTopSearchQueries(searchData);
  }

  onPublicationChange = (value, type, key, index) => {
    const { searchObject, pubListData } = this.state;
    const length = searchObject[key] ? searchObject[key].split(',').length : 0;
    if (type.action === "remove-value") {
      pubListData[index] = pubListData[index].replace(`${type.removedValue.value.pubCode}`, "");
      // searchObject[key] = searchObject[key].replace(`${type.removedValue.value.pubCode}`, "");
    }
    if (type.action === "clear") {
      pubListData[index] = ""
    }
    if (value) {
      var selOptions = value.map(a => a.value.pubCode).join(" ");
      // const onlyUnique = (value, index, self) => {
      //   return self.indexOf(value) === index;
      // }
      const pubList = searchObject[key] ?
        (length > value.length) ? `${searchObject[key]},${selOptions}` : selOptions : selOptions;
      // searchObject[key] = pubList.split(",").filter(onlyUnique).join(",");
      pubListData[index] = pubList;

    }
    this.setState({ pubListData });
    let data = [];
    for (let i = 0; i <= this.props.publications.length - 1; i++) {
      let d = [];
      if (pubListData[i] && pubListData[i].length > 0) {
        d = pubListData[i].split(" ");
        data = data.concat(d)
      }
    }
    searchObject.publication = data;
    this.setSearchObject(searchObject);
  };

  publicationChange = (vals, index, checkedArray, badges) => {
    const { selectedData, pubListData } = this.state;
    selectedData[index] = JSON.parse(JSON.stringify(vals));
    this.sendtosearch(selectedData);
    pubListData[index] = { checkedArray: checkedArray, badges: badges };
    this.setState({ selectedData, pubListData });
    // console.log(selectedData,pubListData)
  }
  sendtosearch = (data,flag) => {
    const { searchObject } = this.state;
    let ddd = data
    let datas = [];
    for (let x = 0; x <= this.props.publications.length - 1; x++) {
      if (ddd[x] && ddd[x].length > 0) {
        for (let y of ddd[x]) {
          datas = datas.concat(y)
        }
      }
    }
    if(flag){

      searchObject.publication = datas;
      this.setSearchObject(searchObject)
    }
  }
  clearchKeywords = () => {
    const { searchObject } = this.state;
    // searchObject.searchTerm = "";
    searchObject.keywords = "";
    this.setSearchObject(searchObject)
  }

  render() {
    // const Badges = {
    //   "Biden": 7,
    //   "Covid-19": 1,
    //   "Trump": 2,
    //   "Sustainable Fashion": 3,
    //   "Covid-19 Vaccines": 4,
    //   "Singapore vanishing trades": 5,
    //   " a library by migrant workers for users": 3
    // };

    const { contentTypes, digitalType, searchQueries, isLoading, dateObject ,hide_link_flag} = this.props;
    const {
      startDate,
      searchObject,
      Duration, inputShow, publications, showTabs, maxDate
    } = this.state;
    let contentarray=[];
      if(hide_link_flag){
        if(showTabs.content_allowed){
         contentarray=showTabs.content_allowed.split(",");
         contentarray=contentarray.map(elem=>{
          return elem.toLowerCase();
         })
        }
      }


    return (
      <>
        {isLoading ? (
          <div className="text-center pt-5 pb-5">
            <Spinner primary={true} transparent={true} />
          </div>
        ) : (<>
          <div className="col-12 col-md-12  advanced-search-container magic">
            <div className="col-12 col-md-11 offset-lg-1 ">
              <h1 className="headingadv pb-3">Advanced Search</h1>
              <div>
                <h6>SELECT PUBLICATIONS</h6>
                <div className=" row ">
                  {publications.length
                    ? publications.map((publication, index) => {
                      // console.log(publication.extraData[0].content_type,contentarray);
                      const options = createOptions(
                        publication.extraData,
                        "publication",
                        contentarray

                      );
                     if(options && options[0]?.value){
                      return (
                        <div
                          className="col-md-4  col-12 pl-0 mb-2"
                          key={publication.key}
                        >
                          <div key={index} className="dropdown w-100">
                            {/* <SearchSelect
                            value={getPublicationValues(pubListData[index], options, true)}
                            options={options}
                            onChange={(e, type) =>
                              this.onPublicationChange(e, type, "pubList",index)
                            }
                            placeholder={publication.key}
                          /> */}

                            <SearchCoumnselect id={index} key={index} placeholder={publication.key} options={options}
                              onChange={(vals, index, checkedArray, badges) => this.publicationChange(vals, index, checkedArray, badges)}
                              defaultvalues={searchObject?.publication} userPub_list={showTabs.pub_list}
                              adsearch={false}
                            />
                          </div>
                        </div>
                      );
                        }
                    })
                    : null}
                </div>
              </div>
              <form  className="container-fluid" onSubmit={()=>this.onSearch()}>
              <div className="date-container row">

                <div className="col-md-2 d-inline-block col-12 p-lg-0">
                  <h6 className="pt-3">PUBLICATION DATE (DD/MM/YY)</h6>
                  <span className="toLabel pb-4">FROM</span>
                  <div className="pb-2 pt-2">
                    <DatePickerElement
                      // minDate={minDate}
                      onChange={this.onStartDateChange}
                      className="w-100"
                      value={dateObject?.startDate}
                      maxDate={maxDate}
                    />
                  </div>
                  <span className="toLabel pb-4">TO</span>
                  <div className="pb-2 pt-2">
                    <DatePickerElement
                      minDate={startDate}
                      className="w-100"
                      onChange={this.onEndDateChange}
                      value={dateObject?.endDate}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="d-block  col-md-6 col-12 p-md-3 pl-md-5 ">
                  <div className="search-container col-md-12 col-12">
                    <h6 className="">SEARCH TERMS</h6>
                    <input
                      name="search-text"
                      type="text"
                      placeholder="Search, 搜索, Carian, தேடல்"
                      // value=""
                      autoComplete="off"
                      maxLength="240"
                      className="search-input p-1 pl-4 w-100"
                      aria-label="Search"
                      onChange={(e) =>
                        this.onChange(e.target.value, "keywords")
                      }
                      value={searchObject ? searchObject.keywords : ""}
                    />
                    <FontAwesomeIcon icon={faSearch} className="search-icon-advsearch" />
                    {searchObject.keywords && <FontAwesomeIcon icon={faTimes} className="cross-iconsadv" onClick={() => this.clearchKeywords()} />}
                    {!searchObject.keywords && <FontAwesomeIcon icon={faTimes} className="cross-icon" />}
                  </div>
                  <div className="search-container d-inline-block col-md-12 col-12">
                    {/* <h6 className=""></h6> */}
                    <input
                      name="section"
                      type="text"
                      placeholder="Section"
                      // value=""
                      autoComplete="off"
                      maxLength="240"
                      className="search-input p-1 pl-4 w-100"
                      aria-label="Search"
                      onChange={(e) =>
                        this.onChange(e.target.value, "section")
                      }
                      value={searchObject.section}
                    />
                       <FontAwesomeIcon icon={faSearch} className="search-icon-advsearch" />
                  </div>
                  {(inputShow && (inputShow === 'Articles' || inputShow === 'Images')) && <div className="search-container col-md-12 col-12">
                    <h6 className=""></h6>
                    <input
                      name="by"
                      type="text"
                      placeholder="By"
                      // value=""
                      autoComplete="off"
                      maxLength="240"
                      className="search-input p-1 pl-4 w-100"
                      aria-label="Search"
                      onChange={(e) =>
                        this.onChange(e.target.value, "byline")
                      }
                      value={searchObject.byline}
                    />
                     <FontAwesomeIcon icon={faSearch} className="search-icon-advsearch" />
                  </div>}
                  {inputShow && (inputShow === 'Images') && <div className="search-container col-md-12 col-12">
                    {/* <h6 className=""></h6> */}
                    <input
                      name="caption"
                      type="text"
                      placeholder="Caption"
                      // value=""
                      autoComplete="off"
                      maxLength="240"
                      className="search-input p-1 pl-4 w-100"
                      aria-label="Search"
                      onChange={(e) =>
                        this.onChange(e.target.value, "caption")
                      }
                      value={searchObject.caption}
                    />
                     <FontAwesomeIcon icon={faSearch} className="search-icon-advsearch" />
                  </div>}
                  {(inputShow && inputShow === 'Magazine' || inputShow === 'PDF') && <div className="search-container col-md-12 col-12">
                    <h6 className="">{""}</h6>
                    <input
                      name="pdfPageNo"
                      type="text"
                      placeholder="Page No"
                      // value=""
                      autoComplete="off"
                      maxLength="240"
                      className="search-input p-1 pl-4 w-100"
                      aria-label="Search"
                      onChange={(e) =>
                        this.onChange(e.target.value, "pdfPageNo")
                      }
                      value={searchObject.pdfPageNo}
                    />
                     <FontAwesomeIcon icon={faSearch} className="search-icon-advsearch" />

                  </div>}
                  <div className="search-button-container col-md-12 col-12 p-0 pt-4 d-inline-block hidebutton">
                    <ButtonElement
                      variant="contained"
                      btnType="background-orange"
                      className="d-inline-block mr-2 w-100 search-button" onClick={() => this.onSearch()}
                    >
                      Search
                    </ButtonElement>
                  </div>
                </div>
                {/* <div className="col-md-2 d-inline-block col-12 pt-5 align-top">
                  {contentTypes !== undefined && contentTypes.length > 0 && (
                    <SelectElement
                      defaultValue={digitalType || contentTypes[0].key}
                      onChange={(e) =>
                        this.setContentType(e.target.value)
                      }
                      options={contentTypes}
                    />

                  )}
                </div> */}
                <div className="col-md-3 d-inline-block col-12 pt-5 align-top content-types  ">
                {contentTypes !== undefined && contentTypes.length > 0 && (
                 <CustomSelect options={contentTypes}  defaultValue={digitalType} id="selecticondrop"
                     onChange={(e) =>
                      this.setContentType(e)
                    }
                     ></CustomSelect>
                     )}
                </div>
                <div className="search-button-container col-md-12 col-12 p-0 pt-4 d-inline-block hidebuttonsec">
                    <ButtonElement
                    key="cc"
                      variant="contained"
                      btnType="background-orange"
                      type="submit"
                      className="d-inline-block mr-2 w-100 search-button" onClick={() => this.onSearch()}
                    >
                      Search
                    </ButtonElement>
                  </div>
              </div>
                  </form>

            </div>
          </div>
          <div className="col-12 col-md-12 popular-search-container">
            <div className="col-12 col-md-11 offset-lg-1 ">

              <div className=" d-inline-block col-sm-4 col-md-2 paddropsm  float-md-right">
                  <SelectElement
                    defaultValue={Duration}
                    className="white fontcss "
                    options={Durations}
                    onChange={(e) => {
                      this.onDurationChange(e.target.value)
                    }}

                  />
                </div>
                <div className="float-left">
                <h1 className="headingadv pb-3 pt-3  ">
                  Popular Searches
                </h1>

                </div>


              <div className="col-md-12 col-12 p-0 d-flex flex-wrap">
                {searchQueries && Object.keys(searchQueries)
                  .map((badge) => {
                    let badgedata = badge.trim()
                    return (
                      <> {badgedata && <div className="badgecss" key={badge}>
                        <Badge className="mr-2 mb-3 mt-3 " style={{ cursor: "pointer" }} onClick={() => this.onSearch(badge)}>{badge}</Badge>
                      </div>
                      } </>
                    );
                  })}
              </div>
            </div>
          </div>
        </>)}

      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  publications: state.applicationReducer.publications,
  contentTypes: state.applicationReducer.contentTypes,
  digitalType: state.applicationReducer.digitalType,
  searchQueries: state.applicationReducer.searchQueries,
  tokens: state.loginReducer.tokens,
  searchObject: state.applicationReducer.searchObject,
  dateObject: state.applicationReducer.dateObject,
  hide_link_flag:state.applicationReducer.hide_link_flag
});
export default connect(mapDispatchStatetoProps, {
  getPublications,
  setContentType,
  getTopSearchQueries,
  getContentTypes,
  searchContent,
  getUserDetails,
  getBadgeData,
  setSearchObject,
  setDateObject,
  getsetadandAdvdata,
  getandSethidelinkflag,
  setsearchtype,
  pageViewDatagtm,
  customViewDatagtm,
  setDurations,
  getSubDigitalType
})(AdvancedSearch);
