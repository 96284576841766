import React, { Component } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { SearchPagination, Spinner, Lightbox } from "../UI/index";
import { FilterIcon, NewsPostLogo } from "../../assets/images/index";
import { Article, Image, Online, PDF } from "./ContentTypes/index"
import "./css/searchData.scss";
import SearchResultNotFound from "../UI/Not Found/SearchResultNotFound";
import { decodeToken } from "react-jwt";
import { getCookies, getCustomviewobj } from "../../helpers/common";
import {customViewDatagtm } from '../../redux/actions/applicationsAction';

class SearchContentDisplay extends Component {
  state = {
    currentPage: 1,
    filter: false,
    showContent: false,
    showModalsdata: {}
  }

  async componentDidMount() {


  }
  // artlicle = "article"
  // images ="graphics"
  // pdf ="fullpagepdf"
  // magazine="magazine"
  // online="online_article"
  // ann="ext_article"

  getComponent = (contentType, data, ispdf) => {
    if (contentType && data) {
      switch (data.sourcetype) {
        case "article":
          return <Article iconflag="Articles" key={data} data={data} imageflag={true} passArticle={this.passArticle} ispdf={ispdf} />;
        case "ext_article":
          return <Article iconflag="ANN" key={data} data={data} imageflag={false} passArticle={this.passArticle} ispdf={ispdf} />;
        case "graphics":
        case "photographics":
          return <Image iconflag="Images" key={data} data={data} onClick={this.onContentClick} passArticle={this.passArticle} {...this.props} ispdf={ispdf} />;
        case "fullpagepdf":
          return <PDF iconflag="PDF" key={data} data={data} passArticle={this.passArticle} ispdf={ispdf} />;
        case "magazine":
          return <PDF iconflag="Magazine" key={data} data={data} passArticle={this.passArticle} ispdf={ispdf} />;
        case "online_article":
          return <Online iconflag="Online" key={data} data={data} passArticle={this.passArticle} />;
        case "ad_metadata":
          return <PDF iconflag="PDF" key={data} flag={true} data={data} passArticle={this.passArticle} ispdf={true} />
        default:
          return <Article iconflag="Articles" key={data} data={data} imageflag={true} passArticle={this.passArticle} ispdf={ispdf} />;
      }
    }
  }

  onContentClick = (data) => {
    if (data) {
      this.setState({
        showContent: !this.state.showContent,
        showModalsdata: data
      })
            //===GTM CALL ===//
        const myDecodedTokend = decodeToken(this.props?.token?.id_token);
        this.customViewcall(this.props.searchObject,myDecodedTokend,data,"image popup");
             //===GTM CALL ===//
    }

  }
  closeModal = () => {
    this.setState({
      showContent: false,

    })
  }
  passArticle = (data, file) => {
    this.props.openArticle(data, file)
  }
  onPaginate = (page,flag) => {
    this.setState({
      currentPage: page
    })
    this.props.onPaginate(page);
  if(flag){

    window.scrollTo(0,0)
  }
  }

  onFilterClick = () => {
    this.props.onShowFilter();
  }
  componentDidUpdate() {

  }
  pictureEnquiryForm = async (e, data) => {
    const myDecodedTokend = decodeToken(this.props?.token?.id_token);

    localStorage.setItem("imageData", JSON.stringify(data))
    // await this.props.history.push({
    //   pathname: '/enquiry',
    //   dataInfo: data
    // }, "_blank")
    window.open("/enquiry", "_blank")

    // await this.props.history.push({
    //   pathname: '/enquiry',
    //   datainfo: data
    // })
    this.customViewcall(this.props.searchObject,myDecodedTokend,data,"image enquiry"," ");
  }
  customViewcall=(searchObject,token,data,eventCategory,url)=>{

    let user_status=token?.user_info?.user_type ? token?.user_info?.user_type:"" ;
    let id=token?.user_info?.visitor_id ? token?.user_info?.visitor_id : ""
  //  let obj= getCustomviewobj(eventCategory,eventAction,eventLabel,level2,url,user_status,contentType,author,pubdate);
    let objj= getCustomviewobj(eventCategory,"click",data.documentid,"images",url,user_status,"2",data?.byline_en,data?.publicationdate,id);
    this.props.customViewDatagtm(objj,searchObject)
  }
  render() {
    // const companies = ["Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top Stories", "Top of the news", "Top Stories", "Top Stories", "Top of the news"]
    const { currentPage, showContent, showModalsdata } = this.state;
    const { digitalType, searchContents } = this.props;
    let myDecodedTokend = decodeToken(getCookies("id_token"));
    let ispdf;
    if (myDecodedTokend?.user_info?.hide_links) {
      ispdf = myDecodedTokend?.user_info?.content_allowed.includes("PDF") ? true : false;
    } else {
      ispdf = true;
    }
    if (!myDecodedTokend) {
      ispdf = true;
    }
    return (
      <div className="col-12 col-md-12 pt-2 search-content-container p-0 align-top">
        {showContent ? <Lightbox
          customClass="content-modal"
          closeModal={this.closeModal}
          onClick={() => this.closeModal()}
          closeIcon={false}
        >
          <div className="col-md-12 col-12">
            <img src={showModalsdata?.imgPath} className="responsive w-100 p-0 " alt="not available" />
            <h5 className="m-0  byline pt-2">By: {showModalsdata?.byline_en}  (FRONT PAGE)  {showModalsdata.date}/{showModalsdata.month}/{showModalsdata.year}</h5>
            <div className="footer-content-form float-right ">
              <h4 className="d-inline-block byline mr-2 hoveronenquiry" onClick={(e) => this.pictureEnquiryForm(e, showModalsdata)}>Image enquiry form</h4>
              <img className="d-inline-block newspostlogo" src={NewsPostLogo} alt="news-post-logo" style={{ cursor: "pointer" }} onClick={(e) => this.pictureEnquiryForm(e, showModalsdata)} />
            </div>
          </div>
        </Lightbox> : null}

        <div className="pb-2">
          {!searchContents && !searchContents.totalCount &&
            <SearchResultNotFound keywords="Result Not Found" tokens={this.props?.userTokens?.id_token}></SearchResultNotFound>
          }
          {searchContents && searchContents.totalCount &&
            <div className="pagination-container ">

              <div className="pb-3 d-flex flex-nowrap">
                <div className="filter align-top col-2  p-0  " onClick={this.onFilterClick}>
                  <img src={FilterIcon} alt="filter" />
                </div>
                <div className="col-10 p-0">

                  <div className=" p-0 d-inline-block ">

                    <SearchPagination
                      numPagination={true}
                      currentPage={currentPage}
                      currentPageSize={10}
                      total={searchContents && searchContents.totalCount}
                      onPagination={this.onPaginate}
                      searchObject={true}
                    />
                    {/* <Pagination numPagination={true} currentPage={currentPage} currentPageSize={10} total={searchContents && searchContents.totalCount}onPagination={this.onPaginate} /> */}
                  </div>
                </div>

              </div>
              {this.props.isTransLoader &&
                <div className="tranparentscreen2" >
                  <Spinner primary={true} transparent={true} />
                </div>}
              {searchContents && searchContents.content.map((comp, index) => (
                <Fragment key={index}>
                  {this.getComponent(digitalType, comp, ispdf)}
                </Fragment>
              ))}
              <div className="pb-3">
              <div className="pb-3 d-flex flex-nowrap">
                <div className="filter align-top col-2  p-0  " onClick={this.onFilterClick}>
                  <img src={FilterIcon} alt="filter" />
                </div>
                <div className="col-10 p-0">
                  <div className=" p-0 d-inline-block ">

                    <SearchPagination
                      numPagination={true}
                      currentPage={currentPage}
                      currentPageSize={10}
                      total={searchContents && searchContents.totalCount}
                      onPagination={(pgeno)=>this.onPaginate(pgeno,true)}
                      searchObject={true}
                    />
                    {/* <Pagination numPagination={true} currentPage={currentPage} currentPageSize={10} total={searchContents && searchContents.totalCount}onPagination={this.onPaginate} /> */}
                  </div>
                </div>

              </div>

              {/* <Pagination
                numPagination={true}
                currentPage={currentPage}
                currentPageSize={10}
                total={searchContents && searchContents.totalCount}
                onPagination={this.onPaginate} /> */}
            </div>
            </div>}
        </div>

      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  digitalType: state.applicationReducer.digitalType,
  searchContents: state.applicationReducer.searchContent,
  isTransLoader: state.applicationReducer.isTransLoader,
  userTokens: state.applicationReducer.userTokens,
  token: state.loginReducer.tokens,
  searchObject: state.applicationReducer.searchObject,
});

export default connect(mapDispatchStatetoProps, {
  customViewDatagtm
})(SearchContentDisplay);
