import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonElement, DataTable, Modal, Spinner } from '../../UI/index';
import { createCategory, getUserType, startLoader, clearAdminError } from '../../../redux/actions/adminAction';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import './Category.scss';
import ScrollToTop from '../../UI/ScrollToTop/ScrollToTop';
const initialState = {
    submit: false,
    packageUserType: {
        userType: '',
        desc: ''
    },
    userTypeList: [],
    isEditAgency: false,
    isVisible: false,
    message: '',
    errorType: '',
    resetFlag: 'resetFlag',
    isDisable : false
}

class Category extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);

        this.recaptcha = React.createRef(null);
    }
    componentDidMount = async () => {
        this.onReset();
        await this.props.startLoader();
        await this.props.getUserType();
        if (this.props?.userTypes?.userTypes) {
            let userTypeKeys = Object.keys(this.props.userTypes.userTypes);
            let newUserTypesArr = userTypeKeys.map((userTypeName) => {
                return ({
                    category: this.props.userTypes.userTypes[userTypeName].userTypeName,
                    key: userTypeName,
                    description: this.props.userTypes.userTypes[userTypeName].description
                })
            })
            this.setState({
                userTypeList: newUserTypesArr
            })
        }
    }
    onReset = (e) => {
        let { packageUserType } = this.state;
        packageUserType.userType = null;
        packageUserType.desc = null;
        this.setState({ packageUserType: packageUserType, resetFlag: Math.random() });
    }

    onSave = async (e) => {
        this.setState({
            isDisable: true,
            message: '',
            errorType: ''
        })
        e.preventDefault()
        this.recaptcha.execute();
    }

    onResolved = async () => {
        this.props.startLoader();
        const response = this.recaptcha.getResponse();
        const { packageUserType } = this.state
        packageUserType.desc = packageUserType.desc ? packageUserType.desc.trim() : packageUserType.desc;
        packageUserType.userType = packageUserType.userType ? packageUserType.userType.trim() : packageUserType.userType;
        packageUserType.captchaToken = response;
        await this.props.createCategory(packageUserType);
        let responseMessage = this.props?.addCategoryResponse?.data?.message || this.props?.addCategoryError;
        let errorType = '';
        if (this.props?.addCategoryError)
            errorType = "Error";
        else
            errorType = "Success";
        if (responseMessage) {
            this.setState({
                isVisible: true,
                message: responseMessage,
                errorType: errorType
            })
            if (errorType === "Success") {
                packageUserType.userType = "";
                packageUserType.desc = "";
                this.setState({
                    packageUserType,
                    resetFlag: Math.random()
                })

            }
            await this.props.getUserType();
            if (this.props?.userTypes?.userTypes) {
                let { userTypeList } = this.state;
                userTypeList = [];
                let userTypeKeys = Object.keys(this.props.userTypes.userTypes);
                let newUserTypesArr = userTypeKeys.map((userTypeName) => {
                    return ({
                        category: this.props.userTypes.userTypes[userTypeName].userTypeName,
                        key: userTypeName,
                        description: this.props.userTypes.userTypes[userTypeName].description
                    })
                    // return ({ key: userTypeName, userType: this.props.userTypes.userTypes[userTypeName].userTypeName })
                })
                userTypeList = newUserTypesArr;
                this.setState({
                    userTypeList: userTypeList
                })
            }

            // await this.props.clearAdminError();
        }
        await this.props.clearAdminError();

    }

    setFormValue = async (value, field, number = false) => {
        const { packageUserType } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            packageUserType[field] = regex.test(value) || value === "" ? value : packageUserType[field];
        } else {
            packageUserType[field] = value;
        }
        this.setState({
            packageUserType
        });
    }
    onBack = async (e) => {
    }
    closeModal = async () => {
        this.setState({
            isVisible: false,
            isDisable: false,
            errorType : ''
        });
    }
    render() {
        const { loading } = this.props;
        const { errorType, isVisible, message, userTypeList, resetFlag, packageUserType, isDisable } = this.state;
        return (
            <>
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {isVisible && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType == "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Close</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                <div className='container-fluid'>
                <div className='card mr-ng' >
                    <div className="card margin-left-10" key={resetFlag}>
                        <form className=''>
                            <div className='row'>
                                <div className="col-lg-4 col-12 col-sm-12 col-md-4 pb-2">
                                    <label>Category:<span className="requiredStar">*</span></label>
                                    <input
                                        placeholder="Category"
                                        value={packageUserType.userType}
                                        onChange={(e) => this.setFormValue(e.target.value, "userType")}
                                        type="text"
                                        className="search-input p-1 pl-4 w-100"
                                    />
                                </div>

                                <div className="col-lg-4  col-12 col-sm-12 col-md-4 pb-2">
                                    <label>Description:<span className="requiredStar">*</span></label>
                                    <input
                                        placeholder="Description"
                                        value={packageUserType.desc}
                                        onChange={(e) => this.setFormValue(e.target.value, "desc")}
                                        type="text"
                                        className="search-input p-1 pl-4 w-100"
                                    />

                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-lg-12 col-md-12 pt-3 pb-5">
                                    <ButtonElement
                                        variant="contained"
                                        btnType="background-orange float-right button-label"
                                        className="d-inline-block mr-2 "
                                        onClick={() => this.onReset()}
                                    >
                                        <span className="button-label">Reset</span>
                                    </ButtonElement>
                                    {!this.props.location?.state?.isEditAgencyPublisher && (
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange float-right button-label"
                                            className="d-inline-block mr-2 "
                                            onClick={(e) => this.onSave(e)}
                                            disabled={isDisable}
                                        >
                                            <span className="button-label">Save</span>
                                        </ButtonElement>
                                    )}

                                    {this.props.location?.state?.isEditAgencyPublisher && (
                                        <ButtonElement
                                            type="submit"
                                            variant="contained"
                                            btnType="background-orange button-label"
                                            className="d-inline-block mr-2 float-right"
                                            onClick={(e) => this.onUpdate(e)}
                                        >
                                            <span className="button-label">Update</span>
                                        </ButtonElement>
                                    )}

                                </div>

                            </div>
                        </form>
                    </div>
                    <div className=' card'>
                        <div className="catgory" key={Math.random()}>
                            {userTypeList && userTypeList.length > 0 &&
                                <DataTable className="" checkbox={false} columns={userTypeList[0]} data={userTypeList} ></DataTable>}
                        </div>
                    </div>
                </div>
                </div>
                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />



            </>

        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    addCategoryResponse: state.adminReducer.addCategoryResponse,
    addCategoryError: state.adminReducer.addCategoryError,
    userTypes: state.adminReducer.userTypes,
    userTypesError: state.adminReducer.userTypesError
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    createCategory,
    getUserType,
    clearAdminError
})(Category);
