import React, { Component } from 'react';
import './agency.scss';
import { connect } from "react-redux";
import { DatePickerElement, ButtonElement, Checkbox, DataTable, Modal, Spinner } from '../../../UI/index';
import { startLoader, createAgency, getAgency, getAgencyPublisher, clearAdminError } from '../../../../redux/actions/adminAction';
import { status, getDate } from '../../../../helpers/common';
import Recaptcha from "react-google-invisible-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../config/constants/index";

// Initialize state
var initialState = {
    submit: false,
    minDate: getDate(true, 3),
    fromdate: new Date(),
    createDate: "",
    todate: new Date(),
    agency: {
        agency: '',
        agencyCode: '',
        description: '',
        createDate: "",
        pageNo: 0,
        status: 1,
        fromdate: new Date(),
        todate: new Date(),
        captchaToken: ''
    },
    agencySearchResult: [{
        agencyCode: '',
        agency: '',
        description: '',
        status: ''
    }],
    agencyPublisherResult: [],
    agencyData: {},
    visibleCreateAgencyForm: false,
    visibleSeachAgencyForm: true,
    visibleAgencyList: false,
    isEditAgency: false,
    errorMessage: '',
    isError: false,
    readonly: true,
    isDataAvailable: false,
    isRedirect: false,
    agencyKey: Math.random()
}

class ExternalAgency extends Component {
    state = initialState;
    constructor(props) {
        super(props);
        this.setState(initialState);
        this.recaptcha = React.createRef(null);
    }
    componentDidMount = async (e) => {
        this.setState({
            visibleCreateAgencyForm: false,
            agencyKey: Math.random()
        })
        if (this.props.location?.state?.isBack !== true) { // When page is create agency then clear the filter and states
            localStorage.removeItem("agencySearchObject");
        }
        if (this.props.location?.state?.pageName === "createAgency") { // When page is create agency then clear the filter and states
            localStorage.removeItem("agencySearchObject");
            this.onReset();
        }
        if (localStorage.getItem("agencySearchObject")) {
            let search = JSON.parse(localStorage.getItem("agencySearchObject"))
            this.setState({
                agency: {
                    agency: search.agency,
                    agencyCode: search.agencyCode,
                    // createDate: search.createDate,
                    pageNo: search.pageNo,
                    status: search.status
                },
                isRedirect: true
                // createDate: search.createDate,
            });
            localStorage.removeItem("agencySearchObject");
            this.onResolved();
        } else {
            let agency1 = {
                agency: '',
                agencyCode: '',
                description: '',
                createDate: '',
                maxDate: new Date(),
                minDate: new Date(),
                pageNo: 0,
                status: 1
            }

            this.setState({ agency: agency1, agencySearchResult: [] });
        }
    }
    componentWillUnmount() {
        if (this.props.history.location.pathname !== '/admin/external-agency' && this.props.history.location.pathname !== '/admin/agency-publisher')
            localStorage.removeItem("agencySearchObject");
    }
    onReset = (e) => {
        let agency1 = {
            agency: '',
            agencyCode: '',
            description: '',
            createDate: '',
            maxDate: new Date(),
            minDate: new Date(),
            pageNo: 0,
            status: 1
        }

        this.setState({ agency: agency1, createDate: '', agencySearchResult: [] });
    }
    //date code started
    onfromDateChange = (date) => {
        const { agency } = this.state;
        agency["fromdate"] = date;
        this.setfromdate(agency);
    };
    onCreateDateChange = (date) => {
        const { agency } = this.state;
        let dt = new Date(date);
        let modifieddate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        agency.createDate = modifieddate;
        this.setState({
            agency,
            createDate: date
        })
    };
    setCreateDate = (agency) => {
        this.setState({
            agency
        })
    }
    setfromdate = (agency) => {
        this.setState({
            agency,
            createDate: agency.createDate
        })
    }
    ontoDateChange = (date) => {
        const { agency } = this.state;
        agency["todate"] = date;
        this.setfromdate(agency);
    };
    settodate = (agency) => {
        this.setState({
            agency
        })
    }
    onSave = (e) => {
        this.props.history.push('/admin/external-agency')
    }
    onSearch = async (e) => {
        e.preventDefault();
        this.recaptcha.execute();
    }
    onResolved = async () => {
        await this.props.clearAdminError();
        this.props.startLoader();
        const response = this.recaptcha.getResponse();
        this.setState({
            submit: true,
        });
        // Seach agency request object
        let searchData = {
            agency: this.state.agency.agency.trim(),
            agencyCode: this.state.agency.agencyCode.trim(),
            createDate: this.state.agency.createDate,//this.state.agency.createDate ? moment(this.state.agency.createDate).format("yyyy-mm-dd"):'',
            pageNo: 0,
            status: this.state.agency.status,
            captchaToken: response
        }
        await this.props.getAgency(searchData);
        let agencyData = this.props?.getAgencyResult?.externalAgencies;
        var data = [];
        if (agencyData && agencyData.length > 0)
            this.setState({
                isDataAvailable: true
            })
        this.setState({
            agencySearchResult: data
        })

        delete searchData.captchaToken;
        localStorage.setItem("agencySearchObject", JSON.stringify(searchData))
        if (this.props?.getAgencyErrorResult && !this.state.isRedirect) {
            var msg = [];
            for (let errorMessage of this.props.getAgencyErrorResult) {
                msg.push(errorMessage)
            }
            this.setState({
                isError: true,
                errorMessage: msg
            })
        }

        if (agencyData && agencyData.length > 0) {
            // Agency list
            for (let a of agencyData) {
                let agency = {
                    agencyCode: <a className='decorline' onClick={() => this.getAgencyByAgnecyCode(a)}>{a.agencyCode}</a>,
                    // agencyCode: a.status == "ACTIVE" ? <a className='decorline' onClick={() => this.getAgencyByAgnecyCode(a)}>{a.agencyCode}</a> : a.agencyCode,
                    agency: a.agency,
                    description: a.description,
                    status: a.status == "ACTIVE" ? <span className='active'>{a.status}</span> : <span className='closeStatus'>{a.status}</span>,
                    '': <a className='decorline' onClick={() => this.getAgencyPublisher(a.agencyCode)}>View/Add Publishers</a>
                }
                data.push(agency)
            }
        }
        this.setState({
            agencySearchResult: data,
            visibleAgencyList: true,
            isRedirect: false
        })
    }

    // Get the agency data by agency code
    getAgencyByAgnecyCode = async (agencyObj) => {
        const searchData = {
            id: agencyObj.agencyId
        }

        await this.props.getAgency(searchData);
        this.props.history.push({
            pathname: "/admin/external-agency",
            state: {
                agencyData: agencyObj,
                isEditAgency: true
            }
        });
    }
    // Agency publisher list
    getAgencyPublisher = async (code) => {
        await this.props.getAgencyPublisher({ agencyCode: code });
        let searchData = {
            agency: this.state.agency.agency,
            agencyCode: this.state.agency.agencyCode,
            // createDate: this.state.agency.createDate,//this.state.agency.createDate ? moment(this.state.agency.createDate).format("yyyy-mm-dd"):'',
            pageNo: 0,
            status: this.state.agency.status
        }
        if (this.props?.getAgencyPublisherError) {
            var msg = [];
            for (let errorMessage of this.props.getAgencyPublisherError) {
                msg.push(errorMessage)
            }
            this.setState({
                isError: true,
                errorMessage: msg
            })
        }

        localStorage.setItem("agencySearchObject", JSON.stringify(searchData))
        this.props.history.push({
            pathname: '/admin/agency-publisher',
            state: {
                agencyPublisherData: this.props.getAgencyPublisherResult.data,
                agencyCode: code
            }
        });
    }
    setFormValue = async (value, field, number = false) => {
        const { agency } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            agency[field] = regex.test(value) || value === "" ? value : agency[field];
        } else {
            agency[field] = value;
        }
        this.setState({
            agency
        });
    }
    closeModal = async (e) => {
        this.setState({
            isError: false
        })
    }

    render() {
        const { loading } = this.props;
        const { createDate, agency,
            agencySearchResult, visibleSeachAgencyForm, visibleAgencyList,
            errorMessage, isError } = this.state;
        return (
            <>
                {isError && (
                    <Modal onClick={() => this.closeModal()} customClass="login-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                <span className='err'>Errors</span>
                            </h3>
                            <div className='margin-left-20'>
                                <ul>
                                    {
                                        errorMessage.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Cancel</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}

                {visibleSeachAgencyForm && (
                    <div className="container-fluid">
                        <div className='card mr-ng padding10'>
                            <form className=''>
                                <h3 className="heading">External News Agency</h3>
                                <div className='row'>
                                    <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                        <label>Agency Name:</label>
                                        <input
                                            placeholder="Agency name"
                                            value={agency.agency}
                                            onChange={(e) => this.setFormValue(e.target.value, "agency")}
                                            type="text"
                                            className="search-input p-1 w-100"
                                        />
                                    </div>

                                    <div className="col-lg-4 col-sm-12 col-md-4 pb-2">
                                        <label>Agency Code:</label>
                                        <input
                                            placeholder="Agency code"
                                            value={agency.agencyCode}
                                            onChange={(e) => this.setFormValue(e.target.value, "agencyCode")}
                                            type="text"
                                            className="search-input p-1 w-100"
                                        />
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className="col-lg-12">
                                        <div><label>Validation Status:<span className='err'>*</span> </label></div>
                                        {status.map((p) => (
                                            <div className="col-md-2 d-inline-block p-0" key={p.key}>
                                                <Checkbox key={p.key} labelValue={p.value} checked={agency.status ? parseInt(agency.status) === parseInt(p.value) : 1 === parseInt(p.value)} onChange={(e) =>
                                                    this.setFormValue(e.target.getAttribute("label"), "status")} />
                                                <span className="margin-left-35">{p.key} </span>
                                            </div>))}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-4 col-md-4">
                                        <label>Creation Date: </label>
                                        <div className='basisflex mr-3'>
                                            <span className="toLabel pb-4">FROM</span>
                                            <div className="pb-2 pt-2 dates">
                                                <DatePickerElement
                                                    // minDate={minDate}
                                                    maxDate={new Date()}
                                                    onChange={this.onCreateDateChange}
                                                    className="w-100"
                                                    // value={createDate}
                                                    value={createDate ? createDate : ""}
                                                // onChangeRaw={(e)=>this.test(e)}
                                                // onChange={(e) => this.setFormValue(e.target.value, "fromdate")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-12 col-md-12 pt-3 pb-5 button-res ">
                                        <div className="float-right">
                                            <ButtonElement
                                                type="submit"
                                                variant="contained"
                                                btnType="background-orange button-label"
                                                className="d-inline-block mr-2 mb-2 "
                                                onClick={(e) => this.onSearch(e)}
                                            >
                                                <span className="button-label">Submit</span>
                                            </ButtonElement>


                                            <ButtonElement
                                                type="submit"
                                                variant="contained"
                                                btnType="background-orange button-label"
                                                className="d-inline-block mr-2 mb-2 "
                                                onClick={(e) => this.onSave(e)}
                                            >
                                                <span className="button-label">CREATE</span>
                                            </ButtonElement>

                                            <ButtonElement
                                                variant="contained"
                                                btnType="background-orange button-label"
                                                className="d-inline-block mr-2 mb-2"
                                                onClick={this.onReset}
                                            >
                                                <span className="button-label">Reset</span>
                                            </ButtonElement>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                        {agencySearchResult && agencySearchResult.length > 0 &&
                            visibleAgencyList && (<div className="card margin-left-10">
                                <div className="users-table margin-top-15 agency">
                                    {agencySearchResult && agencySearchResult.length > 0 && <DataTable className="table-responsive" checkbox={false} columns={agencySearchResult[0]} data={agencySearchResult} ></DataTable>}
                                </div> </div>
                            )
                        }
                    </div>

                )}
                {
                    loading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }

                <Recaptcha
                    size="invisible"
                    ref={(ref) => (this.recaptcha = ref)}
                    onResolved={this.onResolved}
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </>
        );
    }
}
const mapDispatchStatetoProps = (state) => ({
    ...state,
    loading: state.adminReducer.isLoading,
    getAgencyResult: state.adminReducer.agencyResult,
    getAgencyErrorResult: state.adminReducer.agencyErrorResult,
    getAgencyPublisherResult: state.adminReducer.agencyPublisherResult,
    getAgencyPublisherError: state.adminReducer.getAgencyPublisherError
});
export default connect(mapDispatchStatetoProps, {
    startLoader,
    createAgency,
    getAgency,
    getAgencyPublisher,
    clearAdminError,
})(ExternalAgency);
