import DOMPurify from "dompurify";
import React, { Component } from "react";
import { connect } from "react-redux";
import reactReferer from "react-referer";
import { getIcon } from "../../../helpers/common";
import "./css/contentType.scss";

class Online extends Component {
  state = {
    referer: reactReferer.referer(),
  };

  async componentDidMount() {

  }
  onClick=(data,file) =>{
    if(this.props.passArticle){
      this.props.passArticle(data,file)
    }
  }

  render() {
    const {data,iconflag}=this.props
    return (
      <div className="container">
      <div className="pb-2">

        {data && <div className="online-container p-2 row" >
        <div className="col-1  ">
      <img  src={getIcon(iconflag, true)}  alt="logo" className="" />
      </div>
          {/* <h6 className="m-0" onClick={() => this.onClick(data)} style={{ cursor: "pointer" }}>{data.headline_en}</h6> */}
          <div className="col-11">
          <div style={{cursor:"pointer"}} onClick={()=>this.onClick(data)}
               key={data.documentid}
               className="headline_en"
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(data.headline_en)}`,
                }}
              ></div>
          {/* <p>{data.teaser}</p> */}
          <div
               className="reshape"
                dangerouslySetInnerHTML={{
                  __html: `${DOMPurify.sanitize(data.teaser)}`,
                }}
              ></div>
          <p className="mb-2 by">
                 {data.byline_en && <span>  By: {data.byline_en} &nbsp;</span>}
                 {data?.publicationName && <span>{data?.publicationName}&nbsp;</span> }
                 {data.pagename && <span>({data.pagename})</span>}  {data?.date}/{data?.month}/{data?.year}</p>
                 {/* <div className="pageName pb-1"  >
            <img
              src={getIcon("Online", true)}
              alt="logo"
              className="logo d-inline-block"
            />
          </div> */}
          </div>
        </div>}
        <hr />
      </div>
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  contentType: state.applicationReducer.contentType,
  digitalType:state.applicationReducer.digitalType
});

export default connect(mapDispatchStatetoProps, {})(Online);
