import React, { Component } from 'react';
import { Spinner } from '../UI';
import {getUserDetails} from '../../redux/actions/loginAction';
import {getTokenFromSessionId} from '../../redux/actions/applicationsAction';
import { connect } from 'react-redux';
class seamlesbybckend extends Component {
    async componentDidMount(){
      await this.props.getUserDetails();
      if(this.props?.userTokens?.id_token){
        localStorage.setItem("seamlessFlag",true);
        localStorage.setItem("token", "logged-in");
        this.props.history.push("/");
        window.location.reload();
    }else{
        const url = this.props.location.search.split("?");
        let sessionid=url[1].split("sessionId=");
        await this.props.getTokenFromSessionId(sessionid[1]);
        if(this.props?.gettokenfromsessionID){
            localStorage.setItem("seamlessFlag",true);
            localStorage.setItem("token", "logged-in");
            this.props.history.push("/");
            window.location.reload();
        }else{
            if(this.props?.error){
                this.props.history.push("/");
            }
        }
    }
    }
    render() {
        return (
            <div style={{height:"50vh"}}>
           {this.props.isLoading   &&  <Spinner primary={true} transparent={true} ></Spinner>}
            </div>
        );
    }
}

// export default seamlesbybckend;
const mapDispatchStatetoProps = (state) => ({
    ...state,
    isLoading:state.applicationReducer.isLoading,
    userTokens: state.loginReducer.tokens,
    gettokenfromsessionID:state.applicationReducer.gettokenfromsessionID,
    error:state.applicationReducer.error,
  });

  export default connect(mapDispatchStatetoProps, {
    getUserDetails,getTokenFromSessionId
  })(seamlesbybckend);
