import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import MainNavbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/footer";
import Dashboard from "../components/Dashboard/dashboard";
import Users from "../components/Admin/Users/users";
import AdvancedSearch from "../components/Search/advancedSearch";
import Subscription from "../components/Subscription/subscription";
import SubscriptionForm from "../components/Subscription/subscriptionForm";
import UserAccount from "../components/Profile/userAccount";
import IPSCallback from "../components/Subscription/IPSCallback";
import SearchResponse from "../components/Search/searchResponse";
import ArticleDetails from "../components/Search/ContentTypes/articleDetails";
// import Adminpanel from "../components/Admin/Admin-Panel/Adminpanel";
// import adminlogin from "../components/Admin/Admin-Login/adminlogin";
import adSearch from "../components/Search/adSearch";
import faq from "../components/Others/faq";
import aboutUs from "../components/Others/aboutUs";
import contactUs from "../components/Others/contactUs";
import tnc from "../components/Others/tnc";
import dataPolicy from "../components/Others/dataPolicy";
import PaymentStatus from "../components/Others/paymentStatus";
import enquiryRequest from "../components/Others/enquiryRequest";
import activateSubscription from "../components/Others/activateSubscription";
import Maintenance from "../components/Maintenance/Maintenance";
import Seamless from "../components/Others/seamless";
import seamlesbybckend from "../components/Others/seamlesbybckend";

export const Routes = (props) => {

  return (
    <Fragment>
      {!(props?.location?.pathname).includes("enquiry") ? <MainNavbar {...props} key="nabbar" /> : null}
      <Switch  {...props}>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/index.html" component={Dashboard} />
        <Route exact path="/advanced-search" component={AdvancedSearch} />
        <Route exact path="/ad-search" component={adSearch} />
        <Route exact path="/subscription" component={Subscription} />
        <Route exact path="/subscription-form" component={SubscriptionForm} />
        <Route exact path="/user-account" component={UserAccount} />
        <Route exact path="/ipscallback" component={IPSCallback} />
        <Route exact path="/search" component={SearchResponse} />
        <Route exact path="/articleDetails" component={ArticleDetails} />
        <Route exact path="/users" component={Users} />
        {/* <Route path="/admin/login" component={adminlogin} /> */}
        {/* <Route  path="/admin" component={Adminpanel} /> */}
        <Route exact path="/faq" component={faq} />
        <Route exact path="/aboutUs" component={aboutUs} />
        <Route exact path="/contactus" component={contactUs} />
        <Route exact path="/tnc" component={tnc} />
        <Route exact path="/dataprotectionpolicy" component={dataPolicy} />
        <Route exact path="/payment_status" component={PaymentStatus} />
        <Route exact path="/enquiry" component={enquiryRequest}{...props} />
        <Route exact path="/activateAccount/:key" component={activateSubscription} {...props} />
        <Route exact path="/maintenance" component={Maintenance} />
        <Route exact path="/user/Login.action" component={Seamless} {...props} />
        <Route exact path="/user/seamless" component={seamlesbybckend} {...props} />
      </Switch>
      {!(props?.location?.pathname).includes("enquiry")?<Footer {...props} />:null}
    </Fragment>
  );
};
