import React, { Component } from "react";
import Slider from "react-slick";

// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style, display: "block", background: "#242f39" }}
//             onClick={onClick}
//         />
//     );
// }

// function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style, display: "block", background: "#242f39" }}
//             onClick={onClick}
//         />
//     );
// }

export default class CustomArrows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {}
        };
    }
    componentDidMount() {

        if (document.getElementById("sider").clientWidth >= 550) {
            this.setState({
                settings: {
                    dots: true,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // nextArrow: <SampleNextArrow />,
                    // prevArrow: <SamplePrevArrow />
                }
            })
        }
        else if (document.getElementById("sider").clientWidth >= 650) {
            this.setState({
                settings: {
                    dots: true,
                    infinite: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    // nextArrow: <SampleNextArrow />,
                    // prevArrow: <SamplePrevArrow />
                }
            })
        }
        else if (document.getElementById("sider").clientWidth >= 750) {
            this.setState({
                settings: {
                    dots: true,
                    infinite: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    // nextArrow: <SampleNextArrow />,
                    // prevArrow: <SamplePrevArrow />
                }
            })
        }
        else {
            this.setState({
                settings: {
                    dots: true,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // nextArrow: <SampleNextArrow />,
                    // prevArrow: <SamplePrevArrow />
                }
            })
        }
    }
    render() {
        const { settings } = this.state;

        return (
            <div id="sider" style={{paddingBottom:"10px"}}>
                <Slider {...settings}>
                    {this.props?.images?.map((item, index) => {
                        return (
                            <div key={index}>
                                <img alt="noimg" src={item.thumbnailPath} style={{ width: "100%", height: "200px", objectFit: "contain" }} />
                            </div>
                        )
                    })}

                </Slider>
            </div>
        );
    }
}
