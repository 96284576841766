import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UserProfile from "./User Profile/userProfile";
import Subscription from "./Subscription/subscription";
import Usage from "./Usage/usage";
import Preference from "./Preference/preference";
import "./css/userAccount.scss";
import { getCookies, getPageviewobj } from "../../helpers/common";
import { decodeToken } from "react-jwt";
import {pageViewDatagtm,customViewDatagtm} from "../../redux/actions/applicationsAction";
import { connect } from "react-redux";

class UserAccount extends Component {
constructor(props) {
  super(props);
  this.state={
    tabdisabled:false,
    profilename:"",
  }
}

  async componentDidMount() {
    let idToken=getCookies("id_token");
    let profiledetails= decodeToken(idToken);
    const token = localStorage.getItem("token");
    if (!token) {
      await this.props.history.push("/");
    }
    const isSeamlessFlag=localStorage.getItem("seamlessFlag") ? true :false;
    if(profiledetails && profiledetails?.user_info){
      // console.log(profiledetails)
      this.setState({
        tabdisabled: profiledetails?.user_info?.hide_panel,
        profilename:isSeamlessFlag ? "user" : profiledetails?.sub
      })
    }

    // this.setPageViewcall(this.props.searchObject,profiledetails);
  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
      let url=window.location.href;
      let obj= getPageviewobj(url,"user account","User Profile",user_status,"","","",id);
      this.props.pageViewDatagtm(obj,searchObject);
    }

  render() {
    const {tabdisabled,profilename} = this.state;
    return (
      <div className="user-account-container col-12 col-md-11 offset-lg-1 p-0">
        <div className="col-12 p-0">
          <Tabs >
            <TabList  className="align-top col-md-2 p-0 tablist">
              {tabdisabled && <Tab disabled={tabdisabled} selectedClassName="react-tabs__tabs--disabled">User&nbsp;Profile</Tab>}
              {!tabdisabled && <Tab disabled={tabdisabled} >User&nbsp;Profile</Tab>}
              <Tab disabled={tabdisabled} >Subscription</Tab>
              <Tab disabled={tabdisabled} >Usage</Tab>
              <Tab disabled={tabdisabled} >Preference</Tab>
            </TabList>
            <div className="d-inline-block p-0 col-md-10">
              <div className="col-12 p-0 text-center  pt-2 pb-2">
                <h3 className="heading m-0 ">Welcome, <span className="breskk">{profilename}</span></h3>
              </div>
              <TabPanel >
                <UserProfile disabled={tabdisabled} />
                {/* UserProfile */}
              </TabPanel>
              <TabPanel>
                <Subscription />
                {/* Subscription */}
              </TabPanel>
              <TabPanel>
                <Usage />
                {/* Usage */}
              </TabPanel>
              <TabPanel>
                <Preference />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    );
  }
}

// export default UserAccount;
const mapDispatchStatetoProps = (state) => ({
  ...state,
  subscriptionDetails: state.userReducer.subscriptions,
  loading: state.userReducer.isLoading,
  userTokens: state.loginReducer.tokens,
});

export default connect(mapDispatchStatetoProps, {
  pageViewDatagtm,
  customViewDatagtm
})(UserAccount);
