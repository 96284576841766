import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ButtonElement, Modal, Spinner } from "../UI/index";
import { OrangeTick } from "../../assets/images";
import { getTransactionStatus } from '../../redux/actions/applicationsAction';
import "./css/signUp.scss";

class IPSCallback extends Component {
  state = {
    loading: true,
    modal: false
  }
  async componentDidMount() {
    if (localStorage.getItem("ipsTxnId")) {
      await this.props.getTransactionStatus(localStorage.getItem("ipsTxnId"));
      setTimeout(() => {
        this.setState({
          modal: true,
          loading: false
        })
      }, 5000);
    }
    else {
      this.props.history.push("/");
    }
  }

  onClose = () => {
    this.props.history.push("/");
  }
  render() {
    const { loading } = this.state;
    return (
      <div className="ipsDiv">
        {loading ? (
          <div className="text-center pt-5 pb-5">
            <Spinner primary={true} />
          </div>
        ) :
          <Modal
            customClass="login-modal"
            closeModal={this.onClose}
            loading={loading}
          >
            <div className="text-center sign-up-container">
              <h3 className="login-heading mb-4 mt-4">Thank you for signing up!</h3>
              <img src={OrangeTick} className="icon-img pb-4" alt="orange-tick" />
              <p>
                A validation email will be sent to you.
                Please click on the validation link in the email to activate your account.
              </p>
              <div className="text-center mt-4 mb-4">
                <ButtonElement
                  variant="contained"
                  btnType="background-orange"
                  className="d-inline-block mr-2 close-modal"
                  onClick={this.onClose}
                >
                  close
                </ButtonElement>
              </div>
            </div>
          </Modal>}
      </div>
    )
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  announcements: state.applicationReducer.announcements,
  contentTypes: state.applicationReducer.contentTypes,
  contentType: state.applicationReducer.contentType
});

export default connect(mapDispatchStatetoProps, {
  getTransactionStatus
})(IPSCallback);
