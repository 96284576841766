import axios from "./axios";
import { getCookies} from "../../helpers/common";
import {
  START_LOGIN_LOADER,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  CLEAR_LOGIN_ERROR,
  GET_USER_TOKEN,
  RESET_PASSWORD_SUCCESS,
  // RESET_PASSWORD_ERROR,
  SHOW_LOGOUT_POPUP,
  CLOSE_LOGOUT_POPUP,
  GENERATEKEY,
  GENERATEKEY_ERROR,
  LOGOUT_LOADER,
  STOP_LOGIN_LOADER
} from "../../config/constants/action-constants";
// import Cookies from "js-cookie";
// const baseHomeUrl = process.env.REACT_APP_HOME_BASE_URL;

// console.log(process.env.REACT_APP_HOME_BASE_URL)
// const baseHomeUrl = "http://localhost:5000";

const startLoader = () => async (dispatch) => {
  dispatch({
    type: START_LOGIN_LOADER,
  });
};

const stoploginLoader = () => async (dispatch) => {
  dispatch({
    type: STOP_LOGIN_LOADER,
  });
};

const userLogin = (data,loginkey,encryptkey) => async (dispatch) => {
  try {
    let res;
    if(loginkey && encryptkey){
       res = await axios.get(`${process.env.REACT_APP_HOME_BASE_URL}/login`, {
        withCredentials: true,params:{"loginKey":loginkey,"encKey":encryptkey},headers:{
          "Client-Ip":data.ip
        }
      });
    }else{
       res = await axios.post(`${process.env.REACT_APP_HOME_BASE_URL}/login`, data, {
        withCredentials: true,
      });
    }

    if (res.data.errors) {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload:{data: res.data.errors[0]}
      });
    }
    else {
      localStorage.setItem("token", "logged-in");
      dispatch({
        type: LOGIN_USER,
        payload: { data: res.data,status:res.status, isLoading: false },
      });
    }
  } catch (err) {
    dispatch({
      type: LOGIN_USER_ERROR,
      payload:{data: err.response ? err?.response?.data?.errors[0] : "API Failed"}
    });
  }
}

const resetPassword = (userId) => async (dispatch) => {
  try {
     await axios.post(`${process.env.REACT_APP_HOME_BASE_URL}/resetPassword/${userId}`);
// console.log("aaa reset pwd")
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: { data: "An email will be sent to you. Please click on the link in the email to reset your password." },
    });
  } catch (err) {
    dispatch({
      type: LOGIN_USER_ERROR,
      // type: RESET_PASSWORD_ERROR,
      payload:{data: err.response ? err.response.data.errors : "API Failed"}
    });
  }
}

const getUserDetails = (flag) => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseHomeUrl}/getToken`);
    let obj = {};
    if(flag){}else{
    obj.access_token = getCookies("access_token");
    obj.refresh_token = getCookies("refresh_token");
    obj.id_token = getCookies("id_token");
    obj.accessTokenAdmin=getCookies("accessTokenAdmin");
  }
    dispatch({
      type: GET_USER_TOKEN,
      // payload: { data: res.data },
      payload: { data: obj },
    });
  } catch (err) {
    dispatch({
      type: LOGIN_USER_ERROR,
      payload: {data:err.response ? err.response.data.errors : "API Failed"}
    });
  }
}

const logout = (isAdmin) => async (dispatch) => {
  dispatch({
    type: START_LOGIN_LOADER,
  });
  dispatch({
    type:LOGOUT_LOADER,
    payload: {isLogutLoader: true },
  })
  try {
    let obj = {
      idToken: getCookies("id_token"),
      accessToken: getCookies("access_token"),
      refreshToken: getCookies("refresh_token"),
    }
    let baseUrl = "";
    if (isAdmin) {
      obj.admin = isAdmin;
      baseUrl = `${process.env.REACT_APP_ADMIN_BASE_URL}/logout/admin`
    } else {
      baseUrl = `${process.env.REACT_APP_USER_BASE_URL}/logout`
    }

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${getCookies("access_token")}`,

    //   },
    // }
    // console.log("base url", baseUrl)
    const res = await axios.post(baseUrl, obj);

    // if (!isAdmin) {
    //   document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    //   document.cookie = `id_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    //   document.cookie = `refresh_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    // }
    // if (isAdmin) {
    //   document.cookie = `accessTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    //   document.cookie = `idTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    //   document.cookie = `refreshTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    // }
    // console.log(document.cookie)
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: { data: res, isLoading: false },
    });
    dispatch({
      type:LOGOUT_LOADER,
      payload: {isLogutLoader: false },
    })
  } catch (err) {
    // if (err.response.status == 500) {
    //   dispatch({
    //     type: LOGIN_USER_ERROR,
    //     payload: { data: err.response ? err.response.data.errors : "API Failed" }
    //   });
    // } else {
    //   dispatch({
    //     type: LOGIN_USER_ERROR,
    //     payload: { data: err.response ? err.response.data.error : "API Failed" }
    //   });
    // }
    dispatch({
      type:LOGOUT_LOADER,
      payload: {isLogutLoader: false },
    })
  }
}

const clearLoginError = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
  });
}

const openLogout_popup = () => async (dispatch) => {
  dispatch({
    type :SHOW_LOGOUT_POPUP,
    payload:{logout_popup: true },
  })
}
const oncloseLogout_popup = () => async (dispatch) => {
  dispatch({
    type :CLOSE_LOGOUT_POPUP,
    payload:{logout_popup: false},
  })
}

const generateKey=(ip)=>async(dispatch)=>{
  const config = {
    headers: {
      "Client-Ip": ip
    }
  }
  try {
    const res = await axios.get(`${process.env.REACT_APP_HOME_BASE_URL}/generateKey`,config);
    dispatch({
      type:GENERATEKEY,
      payload:{data:res.data?.message,isLoading:false}
    })
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 500) {
      dispatch({
        type: GENERATEKEY_ERROR,
        payload: { data: err?.response ? err?.response?.data?.errors : "API Failed",isLoading:false }
      });
    } else {
      dispatch({
        type: GENERATEKEY_ERROR,
        payload: { data: err.response ? err.response.data.error : "API Failed" ,isLoading:false}
      });
    }

  }


}

export {
  startLoader,
  userLogin,
  logout,
  getUserDetails,
  resetPassword,
  clearLoginError,
  openLogout_popup,
  oncloseLogout_popup,
  generateKey,
  stoploginLoader
}
