/* eslint-disable import/no-anonymous-default-export */
import {
  GET_USER_PREFERENCES,
  GET_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES_ERROR,
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_ERROR,
  GET_USER_USAGE,
  GET_USER_USAGE_ERROR,
  GET_USER_USAGE_SEARCH,
  GET_USER_USAGE_SEARCH_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_ERROR,
  START_LOADER,
  UPDATE_USER_PROFILE,
  CLEAR_ERROR,
  DOWNLOAD_DOC,
  DOWNLOAD_DOC_ERROR,
  ENQUIRY_REQUEST_INFO,
  ENQUIRY_REQUEST_INFO_ERROR,
  IMAGE_ENQUIRY_REQUEST,
  IMAGE_ENQUIRY_REQUEST_ERROR,
  CLEAR_IMAGE_ENQUIRY_REQUEST,
  GET_PUBLICATION,
  GET_PUBLICATION_ERROR,
  UPDATE_PREFERENCE_LOADER
} from "../../config/constants/action-constants";
const initialState = {
  userPreference: {},
  subscriptions: {
    currentPlan: null,
    pastPlans: [],
    usage: [],
    usageSearch: []
  },
  isLoading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    // case LOGIN_USER:
    //   return {
    //     ...state,
    //     loggedIn: action.payload.msg
    //   }
    // case LOGIN_USER_ERROR:
    //   return {
    //     ...state,
    //     login_error: action.payload
    //   }
    case START_LOADER:
      // console.log(action.payload)
      return {
        ...state,
        isLoading: action.payload.isLoading,
        isLoading2: action.payload.isLoading2
      }
    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.data,
        isLoading: false
      }
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false
      };
    case GET_USER_PREFERENCES:
      return {
        ...state,
        userPreference: action.payload.data,
        isLoading: false
      };
    case GET_USER_PREFERENCES_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false
      };
    case GET_USER_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: action.payload.data,
        isLoading: false
      };
    case GET_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false
      };
    case GET_USER_USAGE:
      return {
        ...state,
        usage: action.payload.data,
        usageSearch: [],
        isLoading: false
      };
    case GET_USER_USAGE_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false
      };
    case GET_USER_USAGE_SEARCH:
      return {
        ...state,
        usageSearch: action.payload.data,
        isLoading2: false
      };
    case GET_USER_USAGE_SEARCH_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false
      };
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        updateuserPreference: action.payload.data,
        isLoading: false,
        isLoadingPreference:false
      };
    case UPDATE_USER_PREFERENCES_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false,
        isLoadingPreference:false
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.data,
        updateuser_error:null,
        isLoading: false
      }
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updateuser_error: action.payload,
        isLoading: false
      };
    case CLEAR_ERROR:
      return {
        ...state,
        login_error: null,
        user_error: null,

      };
    case DOWNLOAD_DOC:
      // console.log(action.payload)
      return {
        ...state,
        downloaddata: action.payload.data,
        dowloadPopUpMsg:action.payload.msg,
        isLoading: false
      };
    case DOWNLOAD_DOC_ERROR:
      // console.log(JSON.parse(action.payload))
      return {
        ...state,
        isTransLoader: false,
        downloaddata: null,
        user_error: typeof action.payload == "string" ? JSON.parse(action.payload).errors : action.payload
      }
    case ENQUIRY_REQUEST_INFO:
      return {
        ...state,
        enquiryRequestInfo: action.payload.data,
        isLoading: false
      }
    case ENQUIRY_REQUEST_INFO_ERROR:
      return {
        ...state,
        user_error: action.payload,
        isLoading: false
      }
    case IMAGE_ENQUIRY_REQUEST:
      return {
        ...state,
        imageEnquiryRes: action.payload.data,
        isLoading: false
      }
    case IMAGE_ENQUIRY_REQUEST_ERROR:
      return {
        ...state,
        imageEnquiryErr: action.payload,
        isLoading: false
      }
    case CLEAR_IMAGE_ENQUIRY_REQUEST:
      return {
        ...state,
        imageEnquiryRes: null,
        imageEnquiryErr: null,
        user_error: null
      }
    case GET_PUBLICATION:
      return {
        ...state,
        publicationInfo: action.payload.data,
        isLoading: false
      }
    case GET_PUBLICATION_ERROR:
      return {
        ...state,
        publicationError: action.payload,
        isLoading: false
      }
    case UPDATE_PREFERENCE_LOADER:
    return {
      ...state,
      isLoadingPreference:true
    }
    default:
      return {
        ...state,
      };
  }
}
