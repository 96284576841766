import React, { Fragment,Component } from "react";
import { BrowserRouter as Router, Route, withRouter,Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import MainNavbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/footer";
import Users from "../components/Admin/Users/users";
import Adminpanel from "../components/Admin/Admin-Panel/Adminpanel";
import adminlogin from "../components/Admin/Admin-Login/adminlogin";
import faq from "../components/Others/faq";
import aboutUs from "../components/Others/aboutUs";
import contactUs from "../components/Others/contactUs";
import tnc from "../components/Others/tnc";
import dataPolicy from "../components/Others/dataPolicy";
import Home from "../components/Admin/Admin-Login/Home";

const oktaAuth = new OktaAuth({
  issuer: `https://${process.env.REACT_APP_ISSUER}`,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  pkce:true
});
export const Routesadmin = (props) => {
 const  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Fragment>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <MainNavbar {...props} oktaAuth={oktaAuth} />
      <Switch  {...props}>
        <SecureRoute  path="/admin" component={Adminpanel} />
        <Route exact path="/" component={adminlogin} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/faq" component={faq} />
        <Route exact path="/aboutUs" component={aboutUs} />
        <Route exact path="/contactus" component={contactUs} />
        <Route exact path="/tnc" component={tnc} />
        <Route exact path="/dataprotectionpolicy" component={dataPolicy} />
        <SecureRoute  path="/home" exact={true} component={Home}/>
        <Route path="/implicit/callback" component={LoginCallback}/>
      </Switch>

      </Security>
      <Footer {...props} />
    </Fragment>
  );
};

const AppWithRouterAccess = withRouter(Routesadmin);

class RouterApp extends Component {
  render() {
    return (<Router><AppWithRouterAccess/></Router>);
  }
}

export default RouterApp;
