// import SimpleCrypto from "simple-crypto-js";
import {
  ImageIcon,
  ImageBlack,
  ArticleIcon,
  ArticleBlack,
  MagazineIcon,
  MagazineBlack,
  OnlineIcon,
  OnlineBlack,
  PDFIcon,
  PDFBlack,
  ANNIcon,
  ANNBlack,
  HomeIcon,
  Homeblack
} from "../assets/images/index";

const getIcon = (type, black = false) => {
  const contentType = black ? `${type} black` : type
  switch (contentType) {
    case "ANN":
      return ANNIcon;
    case "Articles":
      return ArticleIcon;
    case "Images":
      return ImageIcon;
    case "Magazine":
      return MagazineIcon;
    case "Online":
      return OnlineIcon;
    case "PDF":
      return PDFIcon;
    case "ANN black":
      return ANNBlack;
    case "Articles black":
      return ArticleBlack;
    case "Images black":
      return ImageBlack;
    case "Magazine black":
      return MagazineBlack;
    case "Online black":
      return OnlineBlack;
    case "PDF black":
      return PDFBlack;
    case "HOME black":
      return Homeblack;
    default:
      return HomeIcon;
  }
};


const salutations = [
  // {
  //   key: "",
  //   value: "",
  // },
  {
    key: "MR",
    value: "MR",
  },
  {
    key: "MRS",
    value: "MRS",
  },
  {
    key: "MS",
    value: "MS",
  },
];
const filterData = (data,ispublication) => {
  const filteredData = Object.keys(data).map((type) => {
    const splitInput = type.split("|");
    if (splitInput[1] === "Images") {
      let l = [];
      let dataValues=data[type]?.contentTypes ? data[type].contentTypes :data[type];
      for (let x of dataValues) {
        x.sourceType = "all";
        l.push(x);
      }
      return {
        order: parseInt(splitInput[0]),
        key: splitInput[1],
        extraData: l,
        subCategories:data[type]?.subCategories
      };

    } else {
      let dataValues=data[type]?.contentTypes ? data[type].contentTypes :data[type];
      return {
        order: parseInt(splitInput[0]),
        key: splitInput[1],
        extraData: dataValues,
        subCategories:data[type]?.subCategories
      };
    }
  });


  return filteredData.sort((a, b) => a.order - b.order);
};

const filterData_contentAllowed = (data, content, is_seamless) => {
  const filteredData = Object.keys(data).map((type) => {
    const splitInput = type.split("|");
    if ((content && (content.includes(splitInput[1]) || splitInput[1] === "All"))) {
      // if (is_seamless && splitInput[1] === "All") {
      // } else {
      if (splitInput[1] === "Images") {
        let l = [];
        let dataValues=data[type]?.contentTypes ? data[type].contentTypes :data[type];
        for (let x of dataValues) {
          x.sourceType = "all";
          l.push(x);
        }
        return {
          order: parseInt(splitInput[0]),
          key: splitInput[1],
          extraData: l,
          subCategories:data[type].subCategories
        };

      } else {
        let dataValues=data[type]?.contentTypes ? data[type].contentTypes :data[type];
        return {
          order: parseInt(splitInput[0]),
          key: splitInput[1],
          extraData:dataValues,
          subCategories:data[type].subCategories
        };
      }
      // }
    } else {
      return null
    }
  });
  let newfilterdata = [];
  for (let x of filteredData) {
    if (x) {
      newfilterdata.push(x)
    }
  }

  return newfilterdata.sort((a, b) => a.order - b.order);
};

const createOptions = (optionData, key, hidelindata) => {
  if (hidelindata && hidelindata.length > 0) {
    for (let x of optionData[0]?.content_type) {
      for (let y of hidelindata) {
        //---
        if (x === y) {
          return optionData.map((option) => {
            return {
              value: option,
              label: option[key],
            };
          });
          break;
        }
        //--
      }
    }
  } else {
    return optionData.map((option) => {
      return {
        value: option,
        label: option[key],
      };
    });
  }

};

const spaceValidation = (submit, value) => {
  if (submit) {
    if (value) {
      return /\s/.test(value);
    } else {
      return false
    }
  }
}

const validationRules = (
  submit,
  value,
  isnull = false,
  isNumber = false,
  length = 0,
  label = "",
  isEmail = false
) => {
  if (submit) {
    if (isnull) {
      return value !== null ? "" : `Please select ${label}`;
    }
    if (isNumber) {
      return (value.length < length || value.length > length)
        ? `Please provide at least ${length} characters.`
        : "";
    }
    if (isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value) ? "" : "Please input valid email address";
    }
    return value !== "" ? "" : "Please input value";
  }
  return "";
};

const formValidation = (formDetails) => {
  return Object.values(formDetails).some((x) => x === "");
};

const getDate = (minDate = true, noOfYears = 2) => {
  let today = new Date();
  return minDate ? new Date(today.setDate(today.getDate() - 365 * noOfYears)) : new Date(today.setDate(today.getDate() + 365 * noOfYears));
};
const getOneYearDate = (minDate = true, noOfYears = 1) => {
  let today = new Date();
  return minDate ? new Date(today.setDate(today.getDate() + 365 * noOfYears)) : new Date(today.setDate(today.getDate() + 365 * noOfYears));
};

const getStringtoDate = (val) => {
  if (val) {
    let splitDate = val.split("/");
    let stdate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
    return stdate;
  }
}

const extractTelCodes = (telCodes, flag) => {
  // console.log("tecodes",telCodes.countryTelCode)
  if (telCodes && telCodes.countryTelCode) {
    const codes = Object.keys(telCodes.countryTelCode).map((option) => {

      if (flag) {
        return {
          key: telCodes.countryTelCode[option].countryTel,
          value: telCodes.countryTelCode[option].countryTel,
          telephoneCode: telCodes.countryTelCode[option].telephoneCode
        }
      } else {
        return {
          key: telCodes.countryTelCode[option].countryTel,
          value: telCodes.countryTelCode[option].telephoneCode,
        }
      }
    });

    const regions = Object.keys(telCodes.countryTelCode).map((option) => {
      return {
        key: telCodes.countryTelCode[option].country,
        value: option,
      };
    });

    return {
      codes, regions
    }
  }
  return {}
}

const getPublicationValues = (value, data, space = false) => {

  if (value) {
    const values = space ? value.split(" ") : value.split(",");
    return data.filter((option) => values.includes(option.value.pubCode));
  }
  return []
}



const paymentTypes = [{
  key: "Debit/Credit Card",
  value: "1"
}, {
  key: "Cheque",
  value: "2"
}];

const permissions = [{
  key: "via any form of communication, except using my Singapore telephone numbers that are registered with the Singapore Do Not Call Registry.",
  value: "consentL1"
}, {
  key: "via any form of communication, including using my Singapore telephone numbers regardless of any registration of the same with the Singapore Do Not Call Registry.",
  value: "consentL2"
}];

const getContentData = (contentTypes, contentType) => {
  return contentTypes.filter(content => content.key === contentType)[0];
}
// const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_CRYPTOKEY);


const userType = [{ key: 'Indivdual', value: 'Individual', ischecked: true }, { key: 'Company', value: 'Company' }, { key: 'Advertiser', value: 'Advertiser' }, { key: 'Ad Agency', value: 'Ad Agency' }, { key: 'Government', value: 'Government' },
{ key: 'Primary Schol', value: 'Primary School' }, { key: 'Junior College', value: 'Junior College' }, { key: 'Tertiary', value: 'Tertiary' }, { key: 'Polytechnic', value: 'Polytechnic' }, { key: 'SPH Staff', value: 'SPH Staff' }, { key: 'SPH Magazine Staff', value: 'SPH Magazine Staff' },
{ key: 'ANN Publiser', value: 'ANN Publisher', ischecked: true }, { key: 'ANN Overseas Sub', value: 'ANN Overseas Sub' }, { key: 'Non-ANN overseas Sub', value: 'Non-ANN overseas Sub' }
]

const sortBy = [{ key: 'username', value: 'User Name' }, { key: 'Company', value: 'Company' }, { key: 'Contact', value: 'Contact' }, { key: 'USERTYPE', value: 'usertype' }, { key: 'active package', value: 'active package' }]

const activeSubscription = [{ key: 'Individal', value: 'Individual' }, { key: 'Company', value: 'Company' }, { key: 'Advertiser', value: 'Advertiser' }, { key: 'Ad Agency', value: 'Ad Agency' }, { key: 'Government', value: 'Government' },
{ key: 'Primary School', value: 'Primary School' }, { key: 'Junior College', value: 'Junior College' }]

const sortuser = [{ key: 'ASC', value: 'asc', ischecked: true }, { key: 'DESC', value: 'desc' }]

const format = [{ key: "View HTML", value: 'HTML', ischecked: true }, { key: 'Download Pdf', value: 'pdf' }, { key: 'Download Excel', value: 'excel' }]
const format1 = [{ key: 'Download Pdf', value: 'pdf' }, { key: 'Download Excel', value: 'excel' }]

const accountinfostatus = [{ key: "Pending", value: 'Pending(waiting for validations)', ischecked: true }, { key: 'Suspended', value: "Suspended" },
{ key: 'Deleted', value: 'Deleted' }, { key: 'Active', value: 'Active' }]

const status = [{ key: 'Active', value: 1 }, { key: 'Close', value: 2 }]
const languageType = [{ key: 'English', value: 'En' }, { key: 'Chinese', value: 'Zh' }]
const agencyType = [{ key: 'SPH', value: 'SPH' }, { key: 'ANN', value: 'ANN' }, { key: 'SPHM', value: 'SPHM' }]
const digitalTypes = [{ key: 'Article', value: 'article' }, { key: 'PDF', value: 'pdf' }, { key: 'Picture', value: 'picture' }, { key: 'Others', value: 'ext_article' }]
const hideLink = [{ key: "No", value: false }, { key: "Yes", value: true }];
const payalert = [{ key: "on", value: true }, { key: "off", value: false }]
const PayBy = [{ key: "Credit Card", value: "1" }, { key: "Other Payment Method ", value: "2" }];
const loginType = [{ key: 'Seamless', value: "seamless" }, { key: 'Regular', value: "Regular" }, { key: 'all', value: "all" }]


const getCookies = (token, isadmin) => {
  let cook = document?.cookie?.split(";");
  // console.log(cook)
  if (token === "access_token") {
    if (cook) {
      for (let x of cook) {
        if (!isadmin && x.includes("access_token")) {
          let c = x.split("=");
          return c[1];
        }
        if (isadmin && x.includes("accessTokenAdmin")) {
          let c = x.split("=");
          return c[1];
        }
      }

    } else {
      return null;
    }
  }
  if (token === "id_token") {
    if (cook) {
      for (let x of cook) {
        if (!isadmin && x.includes("id_token")) {
          let c = x.split("=");
          return c[1];
        }

      }

    } else {
      return null;
    }
  }
  if (token === "refresh_token") {
    if (cook) {
      for (let x of cook) {
        if (!isadmin && x.includes("refresh_token")) {
          let c = x.split("=");
          return c[1];
        }
        if (isadmin && x.includes("refreshTokenAdmin")) {
          let c = x.split("=");
          return c[1];
        }
      }

    } else {
      return null;
    }
  }

}
const removeAllCookies = (isAdmin) => {
  if (!isAdmin) {
    document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `id_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `adminaccessToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `accessTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } else {
    document.cookie = `accessTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `idTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `refreshTokenAdmin=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `adminaccessToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  }
}
const reverseDate = (str) => {
  var splitString = str.split("-");
  var reverseArray = splitString.reverse();
  var joinArray = reverseArray.join("-");
  return joinArray;
}
const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => first.toLocaleUpperCase(locale) + rest.join('')

const devUrl = "https://api-dev.newslink.sg";
const prodUrl = "https://api.newslink.sg";

const setPublistdata = (pub_list, publication) => {
  if (pub_list && pub_list.length > 0) {
    let newArray = publication ? publication : [];
    for (let x of pub_list) {
      if (publication && publication.length > 0 && publication.includes(x)) {
      } else {
        newArray.push(x);
      }
    }
    return newArray;
  } else {
    return [];
  }
}
const getDomain = (url) => {
  if (url) {
    url = url.replace("http://", "");
    url = url.replace("https://", "");
    url = url.replace("www.", "");

    return url.split("/");
  }

}

const subscriberArr = [{
  key: "PPV",
  value: "false"
}, {
  key: "Require Subscriber",
  value: "true"
}];

const getPageviewobj = (url, pagename, articleTitle, userType, contentType, author, pubdate, visitor_id,hasresult) => {
  let islogin = localStorage.getItem("token");
  let obj = {
    level2: pagename,
    url: url ? url : window.location.href,
    title: articleTitle,
    user_status: userType,
    visitorcat: islogin ? "2" : "1",
    contentType: contentType,
    author: author,
    pubdate: pubdate,
    at: visitor_id,
    has_search_results:hasresult ? hasresult :""
  }
  return obj;
}

const getCustomviewobj = (eventCategory, eventAction, eventLabel, level2, url, user_status, contentType, author, pubdate, visitor_id) => {
  let islogin = localStorage.getItem("token");
  let obj = {
    'eventCategory': eventCategory,
    'eventAction': eventAction,
    'eventLabel': eventLabel ? eventLabel : "",
    'level2': level2,
    'url': url ? url : window.location.href,
    'user_status': user_status,
    'visitorcat': islogin ? "2" : "1",
    contenttype: contentType,
    'author': author ? author : "",
    'pubdate': pubdate ? pubdate : "",
    'at': visitor_id
  };

  return obj;
}
export {
  filterData,
  getIcon,
  createOptions,
  validationRules,
  getDate,
  // simpleCrypto,
  formValidation,
  salutations,
  paymentTypes,
  extractTelCodes,
  permissions,
  getContentData,
  getPublicationValues,
  userType,
  sortBy,
  activeSubscription,
  sortuser,
  format,
  accountinfostatus,
  getStringtoDate,
  languageType,
  agencyType,
  digitalTypes,
  status,
  filterData_contentAllowed,
  hideLink,
  payalert,
  PayBy,
  getCookies,
  reverseDate,
  removeAllCookies,
  capitalizeFirstLetter,
  getOneYearDate,
  devUrl,
  prodUrl,
  subscriberArr,
  setPublistdata,
  spaceValidation,
  format1,
  getDomain,
  loginType,
  getPageviewobj,
  getCustomviewobj
};
