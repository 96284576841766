import React, { Component } from 'react'
import { OrangeTick } from '../../../assets/images'
import { ButtonElement } from '../../UI'
import "./ErrorPopup.scss"
export class ErrorPopup extends Component {
    render() {
        return (

            <div className="col-lg-12 col-md-12 pt-3 pb-5">
                <h3>
                    {Array.isArray(this.props?.errData) && this.props?.errData?.length > 0 ? <span className='err'>Errors</span> : <span className='suc  '>Success</span>}
                </h3>
                <div>
                    <ul className='error-popup-ul'>

                        {
                            this.props?.errData?.length > 0 ? this.props?.errData.map(e => (
                                <li key={e}>
                                    {e}
                                </li>
                            )) : <>
                                <li className='listStyle '>{this.props?.msg}</li>
                                {/* <li className='listStyle  tick'>
                                    <img src={OrangeTick} className="mt-3"  width="50" height="50"alt="orange-tick" />
                                </li> */}




                            </>
                        }
                        {/* {this.props?.msg && <li>{this.props?.msg}</li>} */}
                    </ul>
                </div>
                <div className='btn-center'>
                    <ButtonElement
                        variant="contained"
                        btnType="background-orange button-label "
                        className="d-inline-block  "
                        onClick={() => this.props.onClose()}
                    >
                        <span className="">Close</span>
                    </ButtonElement>
                </div>

            </div>

        )
    }
}

export default ErrorPopup
