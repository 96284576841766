import React, { Component } from "react";
import "./other.css";
// import { faAngleDown, faArrowDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordian from "../UI/Accordian/accordian";
import {
  pageViewDatagtm,
  customViewDatagtm,
} from "../../redux/actions/applicationsAction";
import { getUserDetails } from "../../redux/actions/loginAction";
import { connect } from "react-redux";
import { getPageviewobj } from "../../helpers/common";
import { decodeToken } from "react-jwt";
const accordionData = [
  {
    "Newslink Content": [
      {
        question: "What is Newslink?",
        answer: `Newslink is an online newspaper archival service which offers archived news articles, photographs, infographics and PDF copies of the newspapers from 18 unique newspapers published by SPH Media Limited.`,
      },
      {
        question: "What are the newspapers currently archived in Newslink?",
        answer: `The newspapers archived in Newslink are The Straits Times (Including Digital Life, Mind Your Body, Urban, Little Red Dot and IN), The Sunday Times, The Business Times, The Business Times Weekend, The New Paper, The New Paper on Sunday, Tabla, Tamil Murasu, Berita Harian (including Gen G/I-Cube), Berita Minggu (Malay), Lianhe Zaobao, Lianhe Zaobao Sunday, My Paper (Chinese and English), Shin Min Daily News, Lianhe Wanbao, Friday Weekly (Chinese), Thumbs Up (Chinese), Thumbs Up Junior (Chinese) & STEP.`,
      },
      {
        question: "When are the newspapers uploaded onto Newslink?",
        answer: `
            <div>
           <p><strong><em>The Straits Times/ The Business Times</em></strong></p>
           <p>
               Weekend papers, available in Newslink 3 days later<br>
               Daily papers, available in Newslink 1 day later
           </p>
           <p><strong><em>The New Paper</em></strong></p>
           <p>
               Daily papers, available in Newslink 2 days later
           </p>
           <p><strong><em>Tamil Murasu</em></strong></p>
           <p>
               For Weekend papers - available in Newslink 3 days later<br>
               For Monday and Tuesday -  available in Newslink 2 days later<br>
               Wednesday-Friday -  available in Newslink 1 day later
           </p>
           <p><strong><em>Berita Harian/ Berita Minggu</em></strong></p>
           <p>
               For Weekend papers - available in Newslink 3 days later<br>
               For Monday and Tuesday - available in Newslink 2 days later<br>
               For Wednesday - Friday - available in Newslink 2 days later
           </p>
           <p><strong><em>Lianhe Zaobao/ Shin Min/ Lianhe Wanbao</em></strong></p>
           <p>
               For weekend publications - available in Newslink 5 days later<br>
               For daily publications - available in Newslink 3 days later
           </p>
           <p>
               Please note the following:<br>
               The days counted does not include the day of publication.<br>
               In light of COVID-19 situation, please note that the Classifieds/Recruitment pages will be available in Newslink 5 days after publication.
           </p>
        </div>
            `,
      },
      {
        question: "How far back is the coverage of Newslink?",
        answer: `<div>
            The English archive goes back to July 1989 while the Chinese archive starts from January 1994. The following newspaper titles and archived period include :-

            <p><strong><em>Publications/Archived Period</em></strong></p>
            <p>English Publications:-</p>
            <p>
            Straits Times (including IN, Little Red Dot, Digital Life, Urban, Mind Your Body) 1989<br>
            The Sunday Times 1989<br>
            The Business Times 1990<br>
            The Business Times Weekend 1990<br>
            The New Paper 1990<br>
            The New Paper on Sunday 1990<br>
            Tabla 2008<br>
            ThinkChina Online 2019
            </p>
            <p>Malay Publication:-</p>
            <p>
            Berita Harian (including Gen G and I-Cube)1991<br>
            Berita Minggu 1991
            </p>
            <p>Chinese Publications:-</p>
            <p>
            Lianhe Zaobao (including Zaobao Comma) 1994 <br>
            Lianhe Zaobao Sunday 1994<br>
            MyPaper (Chinese &amp; English) 2006<br>
            Lianhe Wanbao 1994<br>
            Shin Min 1994<br>
            Friday Weekly 1998<br>
            Thumbs Up 2008
            </p>

            <p><strong><em>PDFs</em></strong></p>
            <p>
            Straits Times Jan 2007<br>
            Asia Report (Straits Times) Mar/Apr 2015<br>
            Asian Insider (Straits Times) Apr 2020<br>
            Business Times Aug 2007<br>
            The SME Magazine (Business Times) May/Jun 2012<br>
	    The New Paper May 2015<br>
	    Tabla  Dec 2015<br>
	    Tamil Murasu Dec 2011<br>
	    MyPaper (English) Dec 2015<br>
	    MyPaper (Chinese) Nov 2015<br>
            Lianhe Zaobao Jan 2010<br>
            ZbBz (Lianhe Zaobao) Oct 2009 - Dec 2017<br>
	    Lianhe Wanbao Nov 2015<br>
	    Shin Min Nov 2015<br>
	    Berita Harian Dec 2015<br>
            Berita Harian's Gen G/I-Cube Jan 2010<br>
            Thumbs Up Junior 2012<br>
	    Thumbs Up Apr 2011<br>
            Straits Times Education Programme March 2011

            </p>
            <!--
            <p><strong><em>Research Institutes</em></strong></p>
            <p>
                Content from Research Institutes below are now available :-<br/>
                RSIS (Rajaratnam School of Int'l Studies) Nov'2013<br/>
                ISEAS (Yusof Ishak Institute) July'2012<br/>
                ISAS (Institute of South Asian Studies) 2012
            </p>-->

            <p><strong><em>External Publications</em></strong></p>
            <p>
                Asian News Network (ANN) daily bulletin of articles from member newspapers include :-<br>
                ANN Desk Sep'2011<br>
                Asia News Network Sep'2011<br>
                China Daily (China) Sep'2011<br>
                Dawn (Pakistan) Sep'2011<br>
                Eleven Media Group (Myanmar) Sep'2011<br>
                GoGo (Mongolia) Sep'2011<br>
                Kuensel (Bhutan) Sep'2011<br>
                Philippine Daily Inquirer (Philippines) Sep'2011<br>
                Rasmei Kampuchea Daily (Cambodia) Sep'2011<br>
                Sin Chew Daily (Malaysia) Sep'2011<br>
                The Brunei Times (Brunei) Sep'2011<br>
                The Cambodia Herald (Cambodia) Sep'2011<br>
                The China Post (Taiwan) Sep'2011<br>
                The Daily Star (Bangladesh) Sep'2011<br>
                The Island (Sri Lanka) Sep'2011<br>
                The Jakarta Post (Indonesia) Sep'2011<br>
                The Japan News (Japan) Sep'2011<br>
                The Kathmandu Post (Nepal) Sep'2011<br>
                The Korea Herald (South Korea) Sep'2011<br>
                The Nation (full content from Thailand) Sep'2011<br>
                The Star (Malaysia) Sep'2011<br>
                The Statesman (India) Sep'2011<br>
                Vientiane Times (Laos) Sep'2011<br>
                Vietnam News (Vietnam) Sep'2011<br>
                <br>
                External Publications (Full Content) :-<br>
                The Korea Times (Korea) Jan'2019<br>
            </p>

            <p><strong><em>SPH Magazines</em></strong></p>
            <p>
                Content from SPH Magazines below are now available :-<br>
                24:7<br>
                Cleo<br>
                Female<br>
                Female Brides<br>
                Game Axis<br>
                HWM<br>
                Her World<br>
                Her World Brides<br>
                Her World Brides Luxe<br>
                Home &amp; Décor<br>
                Harper's BAZAAR<br>
                Shape<br>
                Simply Her<br>
                Singapore Women's Weekly<br>
                The Peak<br>
                The Peak Selections Gourmet &amp; Travel<br>
                The Peak Selections Timepieces<br>
                Torque<br>
                Young Parents<br>
                Young Parents Pre-school Guide<br>
                Icon<br>
                Icon Moments<br>
                Icon Weddings<br>
                Nuyou<br>
                Nuyou Fashion Notebook<br>
                Nuyou Time<br>
            </p>
        </div>`,
      },
      {
        question: "What archived news is available for search in Newslink?",
        answer: `All news stories and information (majority of our newspaper content) written by SPH journalists and published in the abovementioned newspapers EXCEPT stories from news agencies such as Reuters, AP, AFP etc. Photographs, infographics and PDF copies of the newspapers are also available.`,
      },
      {
        question: "What types of information are unavailable in Newslink?",
        answer: `Classified advertisements, television listings, cinema movie session schedules, company announcements, Tenders & Notices, Obituaries and special marketing supplements are not archived in Newslink.`,
      },
      {
        question:
          "Does Newslink archive the Classified, Appointments & Notices section from The Straits Times?",
        answer: `Yes, but the archive is available to only accredited advertising agencies. Readers can download The Straits Times Classified Mobile App to access backdated issues up to seven days. Download the app from the Apple App Store or Google Play Store.`,
      },
      {
        question: "When is the content of Newslink updated?",
        answer: `News stories from the respective dailies are archived and updated on the next working day after publication, except for weekends (Saturday, Sunday & Monday issues will appear on Tuesday) or unless otherwise stated in Database Coverage.`,
      },
    ],

    Registration: [
      {
        question: "How can I access Newslink?",
        answer: `
            <div>
            You need to be a subscriber of Newslink in order to search and view the full text document.
            <br><br>
            New customers can register online, mail or fax (65 63198259) a completed Registration Form together with payment to:
            <br><br>
            Newslink Subscription,<br>
            Singapore Press Holdings Ltd,<br>
            Archive Business/Information Resource Centre,<br>
            1000 Toa Payoh North,<br>
            Singapore 318994.<br>
            </div>
            `,
      },
      {
        question: "When do I hear from you after I have registered?",
        answer: `Generally, it takes 3 working days to process and approve your subscription. We will notify you by email after your account has been successfully activated.`,
      },
      {
        question: "How would I be informed about the status of my application?",
        answer: `If you register online, you will get an automated email reply acknowledging receipt of your application. If you register by post, you will receive an email from us on receipt of your application form and after your account is successfully activated.`,
      },
      {
        question: "How can I receive my Newslink Login details?",
        answer: `The Newslink User ID and password will be delivered to you via the email stated in your application form.`,
      },
    ],
    "Subscription Plan and Packages": [
      {
        question: "What is the subscription plan for Newslink?",
        answer: `<div>
            The plan is subject to Goods and Services Tax (GST)* as follows:
            <br><br>
            Type of Plan<br>
            Pay-Per-Use<br>
            Subscription Fee† Per Year *S$900<br>
            Article retrieval fee: *S$3.00 <br>
            <br> <br>
            Premium Plan (to access PDF versions)<br>
            Pay-Per-Use<br>
            Subscription Fee† Per Year *S$1,200<br>
            Article retrieval fee: *S$3.00<br>
            Full Page PDF retrieval fee: *S$5.00<br>
            <br><br>
            * Subject to changes specified by SPH<br>
            † Payable in advance yearly<br>
        </div>`,
      },
      {
        question: "When will I be charged for retrieving an article?",
        answer: `Searching and browsing of headlines and first paragraphs in Newslink are free of charge. You will be only be charged an Article Retrieval fee of S$3 (subject to GST) for each full-text article retrieved, which you can view, print and save (limited to 1 copy) to your computer for reference. Please note that the S$3 fee is on top of the basic subscription charge of $900.

            For PDF copies, you will be charged Full Page PDF retrieval fee of S$5 (subject to GST) for each full page PDF viewed, which you can view, print and save (limited to 1 copy) to your computer for reference. Please note that the S$5 fee is on top of the basic subscription charge of $1,200 for the Premium Plan and S$3 full text article retrieval fee (if applicable).`,
      },
      {
        question:
          "Will I be charged repeatedly if I download the same article more than once in one day?",
        answer: `If you download the same article more than once in one day, you will still be charged.`,
      },
      {
        question: "Can I check the number of archived articles retrieved?",
        answer: `Yes, you can. To enquire about the number of articles retrieved and or the usage charges for a specified month, simply select the Month and Year in the "Billing Counter" (located at the bottom of every Newslink web page) and click on the 'Submit' button. This will show you the total usage incurred for the indicated period.

            For example, if you enquire on the 10th of June 2002, you will get usage charges from the 1st to the 9th of June.

            This function allows you to check the current month's usage i.e. from the 1st of the current month to the previous day and usage for up to the last three months.`,
      },
      {
        question:
          "Are there any other alternatives to accessing the archived news in Newslink besides subscribing to the service?",
        answer: `
            <div>
            Yes. For one-off usage, you may want to consider the following options to access Newslink:
            <p><strong><em>Option 1: Librarian Assisted Search</em></strong></p>
            <p>E-mail or fax your request with your particulars to our Information Resource Centre at 6319 8259.</p>
            <p>The charges are as follow:</p>
            Search fee: S$40 per hour (no pro-rating)<br>
            Print out (per article): S$3.00
            <p><strong><em>Option 2: One-Off Personal Search</em></strong></p>
            <p>Call our Information Resource Centre (IRC) at 6319 2333, from 9-6pm, Mon-Fri for an appointment to access Newslink.</p>
            <p>A short briefing/training on how to use Newslink will be conducted before the chargeable session commences.</p>
            <p>Charges are as follow:</p>
            Search fee for the 1st hr: S$15.00<br>
            Subsequent 1/2 hr: S$8.00<br>
            Print out (per article): S$3.00
            <p><strong><em>Option 3: Online Article Request</em></strong></p>
            <p>Call the Newslink hotline at 6319 2333 from 9am-5pm, Mon-Fri or use the Contact Us form to send us your search criteria.</p>
            <p>Charges are as follow:</p>
            Search fee : S$21.40 per article<br>
            Payable online by Mastercard or Visa Credit Cards<br>
            Article will be emailed to you.
        </div>
            `,
      },
    ],
    "Newslink Access": [
      // {
      //     question : "What do I need to use Newslink?",
      //     answer : "",
      // },
      {
        question: "Does Newslink allow concurrent access?",
        answer: `Yes, it allows for up to 3 concurrent users.`,
      },
      {
        question: "How can I apply for concurrent access in Newslink?",
        answer: `Multiple concurrent accesses are available under a different subscription plan. For details on this subscription plan, please Contact Us.
            `,
      },
      {
        question: "Can I allow a third party to use my ID and Password?",
        answer: `We do not recommend you do this. You are responsible for all usage incurred, including use of the account by any person authorized by you to use your ID and Password.`,
      },
      {
        question:
          "What should I do with regards to password problems i.e. forgot my password etc?",
        answer: `You can click on the link located on the left hand page "Forgot your password ? Click Here" to retrieve or change your password.`,
      },
    ],
    "Copyright, Reprints and Permission": [
      {
        question:
          "I missed an article that was recently featured in ST Interactive and it is now being archived. Is it possible for you to email me this article?",
        answer: `You can refer to the above section on how to retrieve articles without subscribing to Newslink`,
      },
      {
        question: "Can I redistribute the articles downloaded from Newslink?",
        answer: `Subscriber may download for personal and non-commercial use only. Copying or storing of any content for other than personal use is expressly prohibited without the prior written permission from Singapore Press Holdings.
            `,
      },
      {
        question:
          "As a Newslink subscriber, can I use the archived news stories on my website",
        answer: `For our copyright materials to appear on individual or corporate websites, we have flexible plans, which permit you to display our copyright content on your website. Copyright fee is S$600 (subject to GST) per article per year. However, pricing can vary depending on number of articles requested for and period of display. Please Contact Us for more details.`,
      },
      {
        question:
          "How can I acquire an electronic copy of the archived image of a published new story from Newslink?",
        answer: `All accompanying images archived in Newslink are thumbnails that cannot be blown up into a full-size picture and serves only as representation of the complete archived news story.

            SPH Media Limited reserves the copyright to all pictures taken by its staff photographers. Electronic copies of SPH photos are only supplied for the purpose of postings on individual or corporate websites and the reproduction fees starts from S$600 (subject to GST) per image per year. To order, please Contact Us, together with the headline, publication, published date and photo caption.
            `,
      },
      {
        question:
          "How do I request for reprints of articles from SPH newspapers?",
        answer: `Reprints of articles, which Singapore Press Holdings reserves the copyright to are available for purchase. To order a reprint, please call 6319 2333.`,
      },
      {
        question:
          "Do I need to seek permission for electronic reproduction of an article retrieved from Newslink on my website?",
        answer: `Yes. All articles retrieved from Newslink are for research purposes and restricted to personal use only. All copyright laws apply. If you want to republish an article in print or electronically, you must Contact Us to get permission from Singapore Press Holdings`,
      },
    ],
    "Newslink Search": [
      {
        question: "What are the methods used to conduct my search in Newslink?",
        answer: `You can search for archived articles in Newslink using a keyword, name or phrase under the "Search Terms" field or use a combination of the fields like "Headline", "Subject" etc, available in the Search Options page in Newslink.

            Please visit the Online User Guide to see examples on how to conduct an effective search.`,
      },
      {
        question:
          "What can I do if I require assistance or face any problems in my search with Newslink?",
        answer: `You can always refer to our Online User Guide. You may also call 6319 2333 or Contact Us should you require further assistance`,
      },
    ],
    "User Guide": [
      {
        link_label: " Newslink User Guide 2.0",
        link: ``,
      },
      {
        link_label: "Newslink User Guide 2.0 Chinese",
        link: ``,
      },
    ],

    Others: [
      {
        question: "What if I want to terminate my account?",
        answer: `
            <div>
            Please give at least 90 days' notice prior to your next expiry date to terminate the service. Your termination notice must be given in writing and should be addressed to:
            <br><br>
            Newslink Subscription<br>
            Singapore Press Holdings Ltd<br>
            Archive Business/Information Resource Centre,<br>
            1000 Toa Payoh North,<br>
            Singapore 318994<br>
        </div>
            `,
      },
    ],
  },
];
class faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      toggleinner: false,
      accordionData: accordionData,
    };
  }
  async componentDidMount() {
    let linkone =
      process.env.REACT_APP_ACTIVE_ENV === "LOCAL" ||
      process.env.REACT_APP_ACTIVE_ENV === "DEV"
        ? "https://assets-dev.newslink.sg/guide/Newslink2.0_User_Guide.pdf"
        : "https://assets.newslink.sg/guide/Newslink2.0_User_Guide.pdf";
    let linkchinese =
      process.env.REACT_APP_ACTIVE_ENV === "LOCAL" ||
      process.env.REACT_APP_ACTIVE_ENV === "DEV"
        ? "https://assets-dev.newslink.sg/guide/Newslink2.0_User_Guide_Chinese.pdf"
        : "https://assets.newslink.sg/guide/Newslink2.0_User_Guide_Chinese.pdf";

    this.state.accordionData[0]["User Guide"][0].link = linkone;
    this.state.accordionData[0]["User Guide"][1].link = linkchinese;
    this.setState({
      accordionData: this.state.accordionData,
    });
    console.log(this.state.accordionData[0]["User Guide"]);
    //   console.log(Object.keys(accordionData[0]));
    await this.props.getUserDetails();
    let myDecodedToken = decodeToken(this.props?.tokens?.id_token);
    this.setPageViewcall(this.props.searchObject, myDecodedToken);
  }
  setPageViewcall = (searchObject, myDecodedToken) => {
    let id = myDecodedToken?.user_info?.visitor_id
      ? myDecodedToken?.user_info?.visitor_id
      : "";
    let user_status =
      myDecodedToken && myDecodedToken?.user_info?.user_type
        ? myDecodedToken?.user_info?.user_type
        : "";
    let url = window.location.href;
    let obj = getPageviewobj(
      url,
      "faq",
      "Frequently Ask Questions",
      user_status,
      "",
      "",
      "",
      id
    );
    this.props.pageViewDatagtm(obj, searchObject);
  };
  toggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };
  togglein = () => {
    this.setState({
      toggleinner: !this.state.toggleinner,
    });
  };
  render() {
    const { accordionData } = this.state;
    const acc = Object.keys(accordionData[0]);
    // console.log(acc);
    return (
      <div className="container">
        <h3 className="pt-5 pb-3">Frequently Ask Questions</h3>
        <div className="accordion">
          {acc.map((data, index) => {
            return (
              <Accordian
                keys={index}
                data={accordionData[0][data]}
                title={data}
                content="dsdsdkddkjfkdjfkdj"
              />
            );
          })}
        </div>
      </div>
    );
  }
}

// export default faq;
const mapDispatchStatetoProps = (state) => ({
  ...state,
  tokens: state.loginReducer.tokens,
  searchObject: state.applicationReducer.searchObject,
});
export default connect(mapDispatchStatetoProps, {
  pageViewDatagtm,
  customViewDatagtm,
  getUserDetails,
})(faq);
