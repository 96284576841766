import React, { useEffect, useState } from "react";
import "./index.css";

import {getdownloadBarInfo} from '../../redux/actions/applicationsAction';
import store from "../../redux/store";
import Axios from "axios";

const Downloader = ({ files = [], remove }) => {
  let states = store.getState();

  remove=(e)=>{
    const state = store.getState();
    let flag = state.applicationReducer.downloadBarInfo.flag;
    let data = state.applicationReducer.downloadBarInfo.data[0];
    data.filter((item) => {if(item.filename === e){
            item.complete=true;
    }});

    store.dispatch(getdownloadBarInfo(flag, data));

  }

  return (

    <div className="downloader dsdsds">
      <div className="card ">
        <div className="card-header" >NewsLink Downloader</div>
        <ul className="list-group list-group-flush">
          {states.applicationReducer.downloadBarInfo.data[0].map((file, idx) =>{

            if(file?.complete){}else{
              return(
                <DownloadItem
                   id={idx}
                   key={idx}
                   removeFile={(e) => remove(e)}
                   {...file}
                 />
               )
            }

           }
          )}
        </ul>
      </div>
    </div>
  );
};

const DownloadItem = ({ name, file, filename, removeFile }) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
    if(file){
      Axios.create({
        withCredentials: true,
        'Access-Control-Allow-Origin': '*'
      })
    Axios.get(file, {
      responseType: "blob",
      ...options,

    }).then(function (response) {

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));
       setTimeout(() => {

        removeFile(filename);
       }, 1000);
    }).catch(errr=>{
      removeFile();
    });
}

  }, []);
  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)}MB`;

  return (
    <li className="list-group-item p-1" id={filename}>
      <div className="row">
        <div className="col-12 d-flex">
          <div className="d-inline text-truncate textelip">{name}</div>
          <div className="d-inline ml-1">
            <small className="textelip">
              { downloadInfo.loaded > 0 && (
                <>
                  <span className="text-success">
                    {formatBytes(downloadInfo.loaded)}
                  </span>
                  / {formatBytes(downloadInfo.total)}
                  {/* {formatBytes(downloadInfo.total)} */}
                </>
              )}

              {downloadInfo.loaded === 0 && <>Initializing...</>}
            </small>
          </div>
          {/* <div className="d-inline ml-2 ml-auto">
            {downloadInfo.completed && (
              <span className="text-success">
                Complete <FontAwesomeIcon icon={faCheckCircle} />
              </span>
            )}
          </div> */}
        </div>
        {/* <div className="col-12 mt-2">
          <ProgressBar
            variant="success"
            now={downloadInfo.progress}
            striped={true}
            label={`${downloadInfo.progress}%`}
          />
        </div> */}
      </div>
    </li>
  );
};

export default Downloader;
