import React, { Component } from 'react';

class dataPolicy extends Component {
    render() {
        return (
            <div className='pl-5 pr-5'>
                <h1>Data protection policy</h1>
                <p> SPH Media Limited and its related corporations (“SPH Media”) aim to comply with the requirements of the Personal Data Protection Act 2012 (“PDPA”) and respect your choices in respect of your personal data.</p>
                <p>The main purposes for which your personal data is collected, used or disclosed by SPH Media and its service providers in Singapore include providing you with our products and services, managing your subscriptions, renewals and your account, processing payments, addressing questions and feedback, improving our products and services, as well as where permitted under law, sending you marketing and promotional offers on products and services, and personalized content and advertising based on your preferences or demographics. </p>
                <p>In addition, if you have registered your telephone number with the national Do Not Call (DNC) registry, we will not send you promotional and marketing messages via your telephone number unless you have provided SPH Media consent to do so. </p>
                <p>SPH Media has a Data Protection Policy which provides more information about how we collect, use and disclose your personal data. Should you have any feedback or enquiries relating to your personal data or if you wish to stop receiving promotional or marketing messages from SPH Media, please contact:</p>
                <p>The Personal Data Protection Officer at the following addresses:</p>
                <p><strong>Name:</strong> Mr Colin Low, Chief Data Protection Officer</p>
                <p><strong>Email :</strong> dataprotection@sph.com.sg</p>
                <p><strong>Postal Address:</strong> 1000 Toa Payoh North, News Centre, Singapore 318994</p>
                <p><strong>Telephone Number : </strong> (65) 6319 6319 </p>
                <p>For more information about PDPA generally, please visit the Personal Data Protection Commission’s website at http://www.pdpc.gov.sg</p>
                <p>(Updated as of 1 October 2021.) </p>
            </div>
        );
    }
}

export default dataPolicy;
