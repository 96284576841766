import React, { Component } from "react";
import DOMPurify from 'dompurify';
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import Recaptcha from "react-google-invisible-recaptcha";
import {
  Checkbox,
  ButtonElement,
  Spinner,
  SelectElement,
  Modal,
} from "../../UI/index";
import {
  getUserProfile,
  startLoader,
  updateUserProfile,
} from "../../../redux/actions/userAction";
import { getUserDetails } from "../../../redux/actions/loginAction";
import { getPreloads,pageViewDatagtm,customViewDatagtm } from "../../../redux/actions/applicationsAction";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants/index";
import { salutations, extractTelCodes, getCookies, spaceValidation,getPageviewobj } from "../../../helpers/common";
import "./css/userProfile.scss";
import {decodeToken } from "react-jwt";
import Popup from "../../Admin/Popup/Popup";
import RecaptchaWidget from "../../UI/Recaptcha/recaptcha";
// const regions = [{
//   key: "Singapore", value: "SG"
// }]
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.recaptcha = React.createRef(null);
    this.state = {
      userProfile: {
        email:"",
        userId:"",
        company:"",
        type:"",
        phone:"",
        firstName:"",
        salutation:"",
        lastName:"",
        address:"",
        postalCode:"",
        country:"",

      },

      submit: false,
      purposeList:{},
      countryCode:"",
      openerrorModal:false,
      captchaToken: "",
      captchaError: false
    };
  }

  async componentDidMount() {
    const {purposeList} = this.state;
    let myDecodedToken;
    const id_token = getCookies("id_token");
    let profiledetails= decodeToken(id_token)
    if(profiledetails?.user_info?.hide_panel){
    }else{
    this.props.startLoader();
    // await this.props.getUserDetails();
    const id_token = getCookies("id_token");
     myDecodedToken = decodeToken(id_token);
      if(!this.props?.telCodes?.countryTelCode){

        // await this.props.getPreloads();
      }
    if(myDecodedToken?.sub){
      await this.props.getUserProfile(myDecodedToken?.sub);
      this.setDefaultvalues();
    }

    const { codes = [] } = extractTelCodes(this.props.telCodes,true);
    for (let x of codes){
      if(x.telephoneCode ===this.props?.userProfile?.phoneCountryCode){
        this.setState({
          countryCode:x.value
        })
      }
    }
    await this.setState({
      userProfile: this.props.userProfile,
      purposeList
    });
  }
  this.setPageViewcall(this.props.searchObject,myDecodedToken);
}
setPageViewcall=(searchObject,myDecodedToken)=>{
  let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
  let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let url=window.location.href;
    let obj= getPageviewobj(url,"user account","User Profile",user_status,"","","",id);
    this.props.pageViewDatagtm(obj,searchObject);
  }


  changeProfile = (value, field, number = false) => {
    const { userProfile } = this.state;
    const regex = /[0-9\b]+$/;
    if (number) {
      userProfile[field] = regex.test(value) || value === "" ? value : userProfile[field];
    } else {
      userProfile[field] = value;
    }
    this.setState({
      userProfile,
    });
  };

  trimmer = (field)=>{
    const { userProfile } = this.state;
    userProfile[field]= userProfile[field].trim();
    this.setState({
      userProfile,
    });
  }

  changePhonecountrycode =(value,field)=>{

    const { userProfile} = this.state;
    const { codes = [] } = extractTelCodes(this.props.telCodes,true);
      for(let x of codes){
        if(x.value===value){
          userProfile.phoneCountryCode=x.telephoneCode
        }
      }
    this.setState({
      userProfile,countryCode:value

    })

  }
  getRecaptchaToken = (value) => {
    this.setState({
      captchaToken: value,
      captchaError: false
    });
  }

  update = async (e) => {
    // e.preventDefault();
    const { userProfile,purposeList, captchaToken } = this.state;
    this.props.startLoader();
    let purposelist=[];
    for(let i in purposeList){
      for(let y of purposeList[i]){
        if(y){
          purposelist.push(y)
      }
      }
    }
    let data={};
     for(let x in userProfile){
       if(x !=="cmpPreferencePurposeList"){
         data[x]=userProfile[x];
       }
    }
    data["cmpUpsertBody"]= {
      "Language": "en-US",
      "Purposes":purposelist
    }

    data.captchaToken = captchaToken;
    await this.props.updateUserProfile(data);
    if (this.props?.userProfile_error?.length > 0 && this.props?.userProfile_error !=null) {
      this.setState({
        openerrorModal:true
      })
    }

    this.setState({
      captchaToken: "",
    });
  };

  onSubmit = async (e) => {
    const { userProfile, captchaToken } = this.state;
    if(!userProfile.salutation){
      userProfile.salutation=salutations[0].value
    }
    e.preventDefault();
    const validation = () => {
      const errors = ReactDOM.findDOMNode(
        this.refs.userform
      ).getElementsByClassName("error-message");
      const error = [];
      for (let item of errors) {
        if (item.innerHTML !== "") {
          error.push(item);
        }
      }
      if (true) {
        if(captchaToken!=""){
          this.props.startLoader();
          this.update();
        }else{
          this.setState({
            captchaError: true,
          });
        }
      } else {
        window.scrollTo(0, 0);
      }
    };
    this.setState(
      {
        submit: true,
      },
      validation
    );
  };

  setDefaultvalues=()=>{
    const {purposeList} = this.state;
    let cnt=-1;
    if(this.props?.userProfile?.cmpPreferencePurposeList){
      for(let x of this.props.userProfile.cmpPreferencePurposeList){
        cnt++;
        if(!purposeList[cnt]){
         purposeList[cnt]=[];
        }
       let cnt2=-1;
       if(x.contents){
           for(let y of x.contents){
            cnt2++;
            if(y.transactionStatus){
              purposeList[cnt][cnt2]={Id:y.id}
            }
           }
          }
      }
    }
  }
  changeCheckbox=(val,index,i,id)=>{
    const { purposeList } = this.state;
    if(val){
      if( !purposeList[index]){
        purposeList[index]=[]
      }
      purposeList[index][i]={Id:id};
    }else{
      delete  purposeList[index][i];
    }
    this.setState({purposeList})

  }
  onclose=async()=>{
    this.props.updateUserProfile("",true);
    this.props.startLoader();
    const id_token = getCookies("id_token");
    const myDecodedToken = decodeToken(id_token);
    if(!this.props?.telCodes?.countryTelCode){
      await this.props.getPreloads();
    }
    if(myDecodedToken?.sub){
      await this.props.getUserProfile(myDecodedToken?.sub);
      this.setDefaultvalues();
    }
     this.setState({
      userProfile: this.props.userProfile,

    });
  }
  closeModal=()=>{
    this.setState({
      openerrorModal:false
    })
  }
  render() {
    const { loading, telCodes,disabled } = this.props;
    const { userProfile, submit, purposeList,countryCode,openerrorModal, captchaToken, captchaError } = this.state;
    const { codes = [], regions = [] } = extractTelCodes(telCodes,true);
    return (
      <div className={!disabled &&"user-profile"} ref="userform" key="userprofile">
         {this.props?.userProfile?.message && <Modal customClass={'forgot-password-modal'} onClick={() => this.onclose()}>
          <Popup titles={this.props?.userProfile?.message} onClose={this.onclose} {...this.props} />
        </Modal>}
        {openerrorModal && <Modal className="timout-window" customClass={'forgot-password-modal'} closeModal={this.iscloselogoutPopUp} onClick={() => this.closeModal()}>
          <h3> <span style={{color:"red"}}>Error</span></h3>
          <ul>
            {this.props?.userProfile_error.map((item,index)=>{
              return(
                <li key={index}>{item}</li>
              )
            })}
          </ul>
        </Modal>}
         {disabled &&  <>
             <div style={{height:"90vh",backgroundColor:"#EFEFEF"}}>

             </div>
         </>}
      {!disabled &&  <>
        {loading || !userProfile ? (
          <div className="text-c pt-5 pb-5">
            <Spinner primary={true} />
          </div>
        ) : (
          <form
            className="container-fluid"
            noValidate

          >
            <div className="user-profile-form pb-5" key="serpp">
              <div className="col-12 col-md-12 p-0">
                <div className="col-12 col-md-6 p-0 align-top d-inline-block">
                  <div className="col-12 col-md-12 pb-2">
                    <label>Username</label>
                    <input
                      className="form-control"
                      required
                      autoComplete="off"
                      readOnly
                      placeholder=" Username"
                      value={userProfile.userId}
                    />
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, userProfile.userId)} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2">
                    <label>Company</label>
                    <input
                      className="form-control"
                      required
                      autoComplete="off"
                      placeholder=" Company"
                      value={userProfile.company}
                      onBlur={()=>this.trimmer("company")}
                      onChange={(e) =>
                        this.changeProfile(e.target.value, "company")
                      }
                    />
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, userProfile.company)} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2">
                    <label>Type</label>
                    <input
                      className="form-control"
                      autoComplete="off"
                      readOnly
                      placeholder=" Type"
                      value={userProfile.type}
                    />
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, userProfile.type)} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12  pb-2">
                    <label>Telephone</label>
                    <div className="col-12 col-md-12 p-0">
                      <div className="col-md-6 d-inline-block col-6 p-0 pr-1">
                      <SelectElement
                        defaultValue={countryCode}
                        keyName="country"
                        // value="telephoneCode"
                        className="p-2 white "
                        options={codes}
                        onChange={(e) =>
                          this.changePhonecountrycode(e.target.value, "phoneCountryCode")
                        }
                      />
                      </div>
                      <div className="col-md-6 d-inline-block col-6 p-0">
                      <input
                        className="form-control"
                        autoComplete="off"
                        placeholder=" Telephone"
                        value={userProfile.phone}
                        onBlur={()=>this.trimmer("phone")}
                        onChange={(e) =>
                          this.changeProfile(e.target.value, "phone", true)
                        }
                      />
                       </div>
                    </div>
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, userProfile.phone, false, true, 8)} */}
                        {/* {validationRules(submit, userProfile.phoneCountryCode, true, false, 0, "Country code")} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2">
                    <label>Email (for notification)</label>
                    <input
                      className="form-control"
                      autoComplete="off"
                      placeholder=" Email"
                      value={userProfile.email}
                      onBlur={()=>this.trimmer("email")}
                      onChange={(e) =>
                        this.changeProfile(e.target.value, "email")
                      }
                    />
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, userProfile.email, false, false, 0, "", true)} */}
                        {spaceValidation(submit,userProfile?.email.trim()) && "Remove space from the email"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-inline-block p-0">
                  <div className="col-12 col-md-12  pb-2">
                    <label>Name</label>
                    <div className="col-12 col-md-12 p-0">
                      <div className="col-md-3 d-inline-block col-4 p-0 pr-3">
                        <SelectElement
                          defaultValue={userProfile.salutation}
                          // className="p-1 select-control w-100"
                          className="white p-2"
                          options={salutations}
                          key="salutation"
                          onChange={(e) =>
                            this.changeProfile(e.target.value, "salutation")
                          }
                        />
                      </div>
                      <div className="col-md-9 d-inline-block col-8 p-0">
                        <input
                          className="form-control"
                          autoComplete="false"
                          placeholder="First Name"
                          value={userProfile.firstName}
                          onBlur={()=>this.trimmer("firstName")}
                          onChange={(e) =>
                            this.changeProfile(e.target.value, "firstName")
                          }
                        />

                      </div>
                    </div>
                    <div className="pt-1">
                    <span className="error-message">

                            {/* {validationRules(submit, userProfile.salutation)} */}
                          </span>
                          <span className="error-message pl-2">
                            {/* {validationRules(submit, userProfile.firstName)} */}

                          </span>

                        </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2 m-1">
                    {/* <label>&nbsp;</label> */}
                    <div className="col-12 col-md-12 p-0">
                      <div className="col-md-3 d-inline-block col-4 p-0 pr-2">
                        <SelectElement
                          defaultValue={salutations[0].key}
                          className="d-none white p-2"
                          options={salutations}
                          key="salut"
                        />
                      </div>
                      <div className="col-md-9 d-inline-block col-8 p-0">
                        <input
                          className="form-control"
                          autoComplete="false"
                          placeholder="Last Name"
                          value={userProfile.lastName}
                          onBlur={()=>this.trimmer("lastName")}
                          onChange={(e) =>
                            this.changeProfile(e.target.value, "lastName")
                          }
                        />
                        <div className="pt-1">
                          <span className="error-message">
                            {/* {validationRules(submit, userProfile.lastName)} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2">
                    <label>Address</label>
                    <input
                      className="form-control"
                      autoComplete="false"
                      placeholder="Address"
                      value={userProfile.address}
                      onBlur={()=>this.trimmer("address")}
                      onChange={(e) =>
                        this.changeProfile(e.target.value, "address")
                      }
                    />
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(submit, userProfile.address)} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2">
                    <label>Postal code</label>
                    <input
                      className="form-control"
                      autoComplete="false"
                      placeholder="Postal Code"
                      value={userProfile.postalCode}
                      onBlur={()=>this.trimmer("postalCode")}
                      onChange={(e) =>
                        this.changeProfile(e.target.value, "postalCode", true)
                      }
                    />
                    <div className="pt-1">
                      <span className="error-message">
                        {/* {validationRules(
                          submit,
                          userProfile.postalCode,
                          false,
                          true,
                          6
                        )} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 pb-2">
                    <label>&nbsp;</label>
                    <SelectElement
                      defaultValue={userProfile.country}
                      className="white"
                      onChange={(e) =>
                        this.changeProfile(e.target.value, "country")
                      }
                      options={regions}
                      key="country"

                    />
                  </div>
                  {/* <div className="col-12 col-md-12 pb-2">
                      <label>Password</label>
                      <input
                        className="form-control"
                        autoComplete="false"
                        placeholder="*********"
                        onChange={(e) =>
                          this.changeProfile(e.target.value, "password")
                        }
                      />
                      <div className="pt-1">
                        <span className="error-message">
                          {validationRules(submit, userProfile.password)}
                        </span>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            {/* <div className="marketing-promotion col-md-12 col-12 pb-5">
                <label className="heading">Marketing Promotion Material</label>
                <label className="heading-content">
                  I would like to receive marketing information from Singapore
                  Press Holdings Ltd and its related corporations ("SPH") or SPH's
                  service providers, on products or services:
                </label>
                <div className="checkbox-container">
                  <Checkbox
                    label="Offered by SPH, via any form of communication, except using my
              Singapore telephone numbers that are registered with the Singapore
              Do Not Call Registry."
                    checked={userProfile.consentSph}
                    onChange={(e) =>
                      this.changeProfile(e.target.checked, "consentSph")
                    }
                  />
                  <Checkbox
                    label="Offered by other parties, via any form of communication, except
              using my Singapore telephone numbers that are registered with the
              Singapore Do Not Call Registry."
                    checked={userProfile.consentThirdParty}
                    onChange={(e) =>
                      this.changeProfile(e.target.checked, "consentThirdParty")
                    }
                  />
                  <Checkbox
                    label="Offered by SPH, via any form of communication using my Singapore
              telephone numbers regardless of any registration of the same with
              the Singapore Do Not Call Registry."
                    checked={userProfile.consentSphTele}
                    onChange={(e) =>
                      this.changeProfile(e.target.checked, "consentSphTele")
                    }
                  />
                  <Checkbox
                    label="Offered by other parties, via any form of communication using my
              Singapore telephone numbers regardless of any registration of the
              same with the Singapore Do Not Call Registry."
                    checked={userProfile.consentThirdPartyTele}
                    onChange={(e) =>
                      this.changeProfile(e.target.checked, "consentThirdPartyTele")
                    }
                  />
                  <label>
                    (Please check or un-check as appropriate. Boxes 1 or 2 may
                    already be checked if we have collected your personal data for
                    such purposes before 2 July 2014)
                  </label>
                </div>
              </div> */}

          <div className="marketing-promotion col-md-12 col-12 pb-5">
                  <label className="heading">Marketing Promotion Material</label>
            {userProfile.cmpPreferencePurposeList && userProfile.cmpPreferencePurposeList.map((item, index) => {
              return (
               <>

                  <div className="heading-content" key={index}
                   dangerouslySetInnerHTML={{
                    __html: `${DOMPurify.sanitize(item.label)}`,
                  }}
                  >

                  </div>
                  <div className="checkbox-container">
                    {item.contents && item.contents.map((checks,i)=>{
                      return(
                        <>
                        <div className="display-flex flex-nowarp">
                        <Checkbox key={i}

                      checked={purposeList[index][i]}
                      onChange={(e) =>
                        this.changeCheckbox(e.target.checked,index,i,checks.id)
                      }
                    />
                    <label className="padinglabel">{ checks.description}</label>
                        </div>
                        </>

                      )
                    })}
                  </div>
                  </>
              )
            })
            }
            </div>

            <>
              <RecaptchaWidget getRecaptchaToken={(value)=>this.getRecaptchaToken(value)} token={captchaToken} error={captchaError}/>
            </>
            <div className="disclaimer">
              {/* <p>
                By checking or leaving checked any of the boxes above, I agree
                that SPH may collect, use and/or disclose my personal data in
                giving effect to the selections above. If I un-check or leave
                un-checked any of the boxes above, SPH will not send me (or in
                the case of un-checking, will cease sending me within 2 weeks)
                marketing information of the type or via the modes I have
                un-checked or left unchecked, unless I have separately provided
                other consents for SPH to do so.
              </p> */}
              <div className="d-flex mb-4 mt-4">
                <ButtonElement
                  type="submit"
                  variant="contained"
                  btnType="background-orange button-label"
                  className="mr-2 next-button col-md-3 col-12"
                  onClick={(e) => this.onSubmit(e)}
                >
                  <span className="button-label">Update</span>
                </ButtonElement>
              </div>
            </div>
          </form>
        )}
        </>
      }
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  userProfile: state.userReducer.userProfile,
  loading: state.userReducer.isLoading,
  userProfile_error: state.userReducer.updateuser_error,
  telCodes: state.applicationReducer.telCodes,
  userTokens: state.loginReducer.tokens,
});

export default connect(mapDispatchStatetoProps, {
  getPreloads,
  getUserProfile,
  startLoader,
  updateUserProfile,
  getUserDetails,
  pageViewDatagtm,
  customViewDatagtm
})(UserProfile);
