import React, { Component } from 'react'
import "./enquiryRequest.scss"
import { decodeToken } from "react-jwt";
import { getEnquiryRequestInfo, startLoader, imageEnquiryRequest, getUserProfile, clearEnquiryRequest, getPublicationName } from '../../redux/actions/userAction'
import { NewsPost } from "../../assets/images/index";
import { ButtonElement, Checkbox, Modal, Spinner } from '../UI';
import { connect } from 'react-redux';
import { RECAPTCHA_SITE_KEY } from "../../config/constants/index";
import Recaptcha from "react-google-invisible-recaptcha";
import { getUserDetails } from "../../redux/actions/loginAction";
import { getCookies } from "../../helpers/common";
import { debounce } from "lodash";
import DOMPurify from 'dompurify';
import RecaptchaWidget from "../UI/Recaptcha/recaptcha";


export class enquiryRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            purpose: [],
            receive_mode: [],
            gst: 0,
            unit_price: [],
            enquiryObj: {
                id: 0,
                userId: "",
                ip: "",
                newslinkPackage: "",
                sourceType: "",
                docId: "",
                digitalType: "",
                digitalItem: "",
                publication: "",
                publicationDate: "",
                publicationSection: "",
                orderFrom: "",
                type: "keep_sake",
                qty4R: 0,
                total4R: 0,
                qty5R: 0,
                total5R: 0,
                qty6R: 0,
                total6R: 0,
                qty8R: 0,
                total8R: 0,
                sizeSmallA1: "",
                qtySmallA1: 0,
                totalSmallA1: 0,
                sizeA1: "",
                qtyA1: 0,
                totalA1: 0,
                sizeBigA1: "",
                qtyBigA1: 0,
                totalBigA1: 0,
                subtotal: 0,
                postage: 0,
                reprint: 0,
                gst: 0,
                total: 0,
                salutation: "",
                name: "",
                email: "",
                phone: "",
                fax: "",
                company: "",
                address: "",
                country: "",
                postalCode: "",
                collectionMode: 1,
                remark: "",
                createon: "2022-05-10",
                captchaToken: ""
            },
            caption: "",
            reproductionPrice: 0,
            userProfile: {},
            isVisible: false,
            message: '',
            errorType: '',
            publicationName: "",
            disableButton: false,
            visibleButton: false,
            captchaToken: "",
            captchaError:false
        }
    }
    componentDidMount = async () => {
        await this.props.startLoader();
        await this.props.clearEnquiryRequest()
        window.scrollTo(0, 0)
        let dataInfo;
        if (localStorage.getItem("imageData")) {
            dataInfo = JSON.parse(localStorage.getItem("imageData"))
        }
        const { enquiryObj } = this.state;
        const ls = localStorage.getItem("token");
        if (localStorage.getItem("token")) {
            const id_token = getCookies("id_token");
            const myDecodedToken = decodeToken(id_token);
            await this.props.getUserProfile(myDecodedToken?.sub);
            this.setState({
                userProfile: this.props.userProfile,

            });
            if (this.props?.userProfile && myDecodedToken?.user_info.is_seamless != true) {
                enquiryObj.userId = this.props?.userProfile.userId;
                enquiryObj.name = this.props?.userProfile.firstName + "" + this.props?.userProfile.lastName;
                enquiryObj.email = this.props?.userProfile.email;
                enquiryObj.phone = this.props?.userProfile.phone;
                enquiryObj.company = this.props?.userProfile.company;
                enquiryObj.address = this.props?.userProfile.address;
                enquiryObj.country = this.props?.userProfile.country;
                enquiryObj.postalCode = this.props?.userProfile.postalCode;
                enquiryObj.remark = this.props?.userProfile.remark != "NA" ? this.props?.userProfile.remark : "";
            }
        }
        await this.props.startLoader();
        await this.props.getEnquiryRequestInfo()
        if (dataInfo) {
            await this.props.startLoader();
            await this.props.getPublicationName(dataInfo.publication);
            enquiryObj.publication = dataInfo.publication
            enquiryObj.docId = dataInfo.documentid;
            enquiryObj.publicationDate = dataInfo.publicationdate;
            enquiryObj.digitalItem = dataInfo.digitaltype
        }

        this.setState({
            enquiryObj,
            caption: dataInfo.caption_en,
            publicationName: this.props?.publicationInfo?.pubName[dataInfo.publication]
        })
        if (this.props?.enquiryRequestInfo?.pefTemplate?.purpose && this.props?.enquiryRequestInfo?.pefTemplate?.purpose.length > 0) {
            this.setState({
                visibleButton: true
            })
        }

        if (this.props?.enquiryRequestInfo?.pefTemplate?.purpose) {
            this.setState({
                purpose: this.props?.enquiryRequestInfo?.pefTemplate?.purpose,
                receive_mode: this.props?.enquiryRequestInfo?.pefTemplate?.receive_mode,
                gst: this.props?.enquiryRequestInfo?.pefTemplate?.gst,
                unit_price: this.props?.enquiryRequestInfo?.pefTemplate?.unit_price,
                reproductionPrice: Number(this.props?.enquiryRequestInfo?.pefTemplate?.picture_reprint_price),
                enquiryObj
            })
        }
    }

    getRecaptchaToken = (value) => {
        this.setState({
          captchaToken: value,
          captchaError: false
        });
    }

    imageInquiry = async (e) => {
        e.preventDefault();
        if(this.state.captchaToken!=""){
            this.submitInquiry();
        }else{
        this.setState({
          captchaError: true,
        });
      }
    }

    submitInquiry = debounce(async () => {
        const { enquiryObj,captchaToken } = this.state
        enquiryObj.captchaToken = captchaToken;
        await this.props.startLoader();
        enquiryObj.name = enquiryObj.name ? enquiryObj.name.trim() : enquiryObj.name;
        enquiryObj.email = enquiryObj.email ? enquiryObj.email.trim() : enquiryObj.email;
        enquiryObj.phone = enquiryObj.phone ? enquiryObj.phone.trim() : enquiryObj.phone;
        enquiryObj.company = enquiryObj.company ? enquiryObj.company.trim() : enquiryObj.company;
        enquiryObj.address = enquiryObj.address ? enquiryObj.address.trim() : enquiryObj.address;
        enquiryObj.postalCode = enquiryObj.postalCode ? enquiryObj.postalCode.trim() : enquiryObj.postalCode;
        enquiryObj.remark = enquiryObj.remark ? enquiryObj.remark.trim() : enquiryObj.remark;
        // enquiryObj.type = this.props?.enquiryRequestInfo?.pefTemplate?.purpose[0]?.value ? this.props?.enquiryRequestInfo?.pefTemplate?.purpose[0]?.value : "keep_sake";


        await this.props.imageEnquiryRequest(enquiryObj);
        if (this.props?.imageEnquiryRes?.message) {
            this.setState({
                disableButton: true
            })
        }
        let responseMessage = this.props?.imageEnquiryRes?.message || this.props?.imageEnquiryErr;
        let errorType = '';
        this.props?.imageEnquiryErr ? errorType = "Error" : errorType = "Success";;
        // if (this.props?.user_error)
        //     errorType = "Error";
        // else
        //     errorType = "Success";

        this.setState({
            isVisible: true,
            message: responseMessage,
            errorType: errorType
        })

        await this.props.clearEnquiryRequest()
        // this.setState({
        //     errorType: ""
        // })
        this.setState({
            captchaToken: "",
          });
    }, 600)
    setFormValue = async (value, field, number = false) => {
        const { enquiryObj } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            enquiryObj[field] = regex.test(value) || value === "" ? value : enquiryObj[field];
        } else {
            enquiryObj[field] = value;
        }

        this.setState({
            enquiryObj
        });
    }
    setFormTypeValue = async (value, field, number = false) => {
        const { enquiryObj, gst, reproductionPrice } = this.state;
        if (value !== "keep_sake") {
            enquiryObj["subtotal"] = reproductionPrice
            enquiryObj["gst"] = Number((enquiryObj.subtotal * gst).toFixed(2))
            enquiryObj["total"] = enquiryObj.subtotal + enquiryObj.gst
        }
        else {
            enquiryObj["subtotal"] = enquiryObj.total4R + enquiryObj.total5R + enquiryObj.total6R + enquiryObj.total8R
            enquiryObj["gst"] = Number((enquiryObj.subtotal * gst).toFixed(2))
            enquiryObj["total"] = enquiryObj.subtotal + enquiryObj.gst
        }
        const regex = /[0-9\b]+$/;
        if (number) {
            enquiryObj[field] = regex.test(value) || value === "" ? value : enquiryObj[field];
        } else {
            enquiryObj[field] = value;
        }

        this.setState({
            enquiryObj
        });
    }
    changeQty = async (value, field, key, unitPrice, number = false) => {
        const { enquiryObj, gst } = this.state;
        const regex = /[0-9\b]+$/;
        if (number) {
            enquiryObj[field] = regex.test(value) || value === "" ? value : enquiryObj[field];
        } else {
            if (isNaN(value) === false) {
                enquiryObj[field] = value;
                enquiryObj["total" + key] = value * unitPrice
                enquiryObj["subtotal"] = enquiryObj.total4R + enquiryObj.total5R + enquiryObj.total6R + enquiryObj.total8R
                enquiryObj["gst"] = Number((enquiryObj.subtotal * gst).toFixed(2))
                enquiryObj["total"] = enquiryObj.subtotal + enquiryObj.gst
            }


        }
        this.setState({
            enquiryObj
        });
    }
    closeModal = async () => {
        this.setState({
            isVisible: false
        });
    }

    render() {
        const { purpose, receive_mode, unit_price, gst, enquiryObj, caption, errorType, isVisible, message, publicationName, disableButton, visibleButton, captchaToken, captchaError } = this.state;
        const { isLoading } = this.props
        return (
            <div className='container mb-5 '>
                {
                    isLoading && <div className="text-center spinner">
                        <Spinner primary={true} />
                    </div>
                }
                {isVisible && (
                    <Modal onClick={() => this.closeModal()} customClass="enquiry-modal">
                        <div className="col-lg-12 col-md-12 pt-3 pb-5">
                            <h3>
                                {
                                    errorType == "Success" ? <span className='suc'>{errorType}</span> : <span className='err'>{errorType}</span>
                                }

                            </h3>
                            <div className='margin-left-20 errorMessage'>
                                <ul>
                                    {
                                        message && Array.isArray(message) && message.length > 0 ? message.map(e => (
                                            <li>
                                                {e}
                                            </li>
                                        )) : <li>{message}</li>
                                    }
                                </ul>
                            </div>
                            <ButtonElement
                                variant="contained"
                                btnType="background-orange button-label"
                                className="d-inline-block mr-2 float-right"
                                onClick={() => this.closeModal()}
                            >
                                <span className="button-label">Ok</span>
                            </ButtonElement>
                        </div>
                    </Modal>
                )}
                <div className='row'>
                    <div className="frame col-md-offset-3 col-md-12 col-xs-12 col-lg-6">
                        <form name="EnquiryPictureForm" onSubmit={(e) => this.imageInquiry(e)}>
                            <div className="frame-top  ">
                                <div className='row'>
                                    <div className="col-lg-5 col-md-4 col-sm-3 col-xs-5"><img alt="" className="img-responsive" src={NewsPost} width="190" height="142" /></div>
                                    <div className="col-lg-7 col-md-8 col-sm-9 col-xs-7"><h2 className='enquiryTitle'>Newslink Image Enquiry Form</h2></div>
                                </div>
                            </div>
                            <div className="frame-text col-lg-12">
                                <p style={{ "fontWeight": "bold", "paddingTop": "20px" }}>Please note: SPH copyright photos posted on Newslink is for reference.
                                    Only selected photos are available for sale and reproduction.</p>
                                <p><span style={{ color: "red" }}>*</span> Indicates a required field.</p>
                            </div>
                            <div className=" row">
                                <div className="sec-frame col-lg-12" style={{ padding: "0px !important" }}><div className="section section2">Contact Details</div></div>
                                <div className="label-text col-lg-12" ></div>
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable pl-30">Contact Person<span style={{ color: "red" }} >*</span> :</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12">
                                    <input className='inputbox' value={enquiryObj.name} id="name" name="name" type="text" onChange={(e) => this.setFormValue(e.target.value, "name")} />
                                </div>
                                <div className="label-text col-lg-12"></div>
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable">Email<span style={{ color: "red" }}>*</span> :</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12">
                                    <input className='inputbox' id="email" value={enquiryObj.email} name="email" type="text" onChange={(e) => this.setFormValue(e.target.value, "email")} />
                                </div>
                                <div className="label-text col-lg-12"></div>
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable">Tel<span style={{ color: "red" }} >*</span> :</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12">
                                    <input className='inputbox' id="phone" value={enquiryObj.phone} name="phone" type="text" onChange={(e) => this.setFormValue(e.target.value, "phone")} />
                                </div>
                                <div className="label-text col-lg-12"></div>
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable">Company<span style={{ color: "red" }}>*</span> :</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12">
                                    <input className='inputbox' id="company" value={enquiryObj.company} name="company" type="text" onChange={(e) => this.setFormValue(e.target.value, "company")} />
                                </div>
                                <div className="label-text col-lg-12"></div>
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable">Address<span style={{ color: "red" }}>*</span> :</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12">
                                    <input className='inputbox' id="address" value={enquiryObj.address} name="address" type="text" onChange={(e) => this.setFormValue(e.target.value, "address")} />
                                </div>
                                <div className="label-text col-lg-12"></div>
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable">Postal Code<span style={{ color: "red" }} >*</span> :</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12">
                                    <input className='inputbox' id="postalCode" value={enquiryObj.postalCode} name="postalCode" type="text" onChange={(e) => this.setFormValue(e.target.value, "postalCode")} />
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="sec-frame col-lg-12 " ><div className="section section1">Usage</div></div>
                            </div>
                            <div className="label-text row">
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable">Purpose of purchase:</div>
                                {purpose && purpose.length > 0 && purpose.map((p) => (
                                    <div className="col-lg-4 col-md-7 col-sm-4 col-xs-12" key={p.key}>
                                        <div className="label-radio " key={p.key}>
                                            <Checkbox key={p.key} labelValue={p.value} checked={p.value === enquiryObj.type}
                                                onChange={(e) => this.setFormTypeValue(p.value, "type")}
                                            />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>
                                    </div>

                                ))}
                            </div>
                            {enquiryObj.type == "keep_sake" ?
                                <div className="label-text col-lg-12" id="pefTalbe">
                                    <div className="label-text col-lg-12" id="pefpart">
                                        <div className="label-text row text-head" >
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">Size</div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">Qty</div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">Unit Price $</div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">Indicative Amount $</div>
                                        </div>
                                        {unit_price && unit_price.length > 0 && unit_price.map((p) => (
                                            <div className="label-text row" key={p.key}>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><label htmlFor="">{p.key}</label></div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <input id={p.key} name={p.key} min="0" value={enquiryObj["qty" + p.key]} maxLength="4" className="qty inputbox" type="text" onChange={(e) => this.changeQty(Number(e.target.value), "qty" + p.key, p.key, p.value)} />
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label htmlFor="unit4R" id={p.value}>{p.value}</label>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <input id={"total" + p.key} name={"total" + p.key} className="black11px inputbox" type="number" value={enquiryObj["total" + p.key]} disabled="disabled" size="10" />
                                                </div>
                                            </div>
                                        ))}


                                    </div>
                                    <div className="label-text row text-head" >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">Sub Total :</div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><input id="subtotal" name="subtotal" value={enquiryObj.subtotal} type="number" className='inputbox' disabled="disabled" size="10" /></div>
                                    </div>
                                    <div className="label-text row text-head" >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">GST({(gst * 100).toFixed(0)} %):</div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><input id="gst" name="gst" value={enquiryObj.gst} className="black11px inputbox" type="number" disabled="disabled" size="10" /></div>
                                    </div>
                                    <div className="label-text row text-head" >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">(Handling and postage fee to be advised) :</div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <strong>Total :</strong><input id="total" className='inputbox' name="total" value={enquiryObj.total} type="number" disabled="disabled" /></div>
                                    </div>
                                </div> : enquiryObj.type == "reprint" ? <div className="label-text col-lg-12" id="pefTalbe">
                                    <div className="label-text row text-head" >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">Reproduction Price :</div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><input id="subtotal" name="subtotal" type="number" value={enquiryObj.subtotal} disabled="disabled" className='inputbox' /></div>
                                    </div>
                                    <div className="label-text row text-head" >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">GST({(gst * 100).toFixed(0)}%):</div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <input id="gst" name="pef.gst" className="black11px inputbox" type="number" value={enquiryObj.gst} disabled="disabled" /></div>
                                    </div>
                                    <div className="label-text row text-head" >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">(Enquiry for reproduction in other media - fees to be advised) :</div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><strong>Total :</strong><input id="total" name="total" className='inputbox' value={enquiryObj.total} type="number" disabled="disabled" /></div>
                                    </div></div> : null}
                            <div className="row">
                                <div className="sec-frame col-lg-12" ><div className="section section1">Content License Request Form</div></div>
                            </div>
                            <div className="label-text row" >
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-4 elable col-4">Publication:</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-8 col-8" >{publicationName}</div>
                            </div>
                            <div className="label-text row">
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable col-4">Caption:</div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12 col-8">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `${DOMPurify.sanitize(caption)}`,
                                        }} ></div>
                                </div>
                            </div>

                            <div className="label-text row" >
                                <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 elable col-4"><label htmlFor="access">Access From:</label></div>
                                <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12 col-8"><strong>NEWSLINK</strong></div>
                            </div>
                            <div className="label-text row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 elable ">Receive Mode:</div>
                                {receive_mode && receive_mode.length && receive_mode.map((p) => (
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12  " key={p.key}>
                                        <div className="label-radio col-xs-12">
                                            <Checkbox key={p.key} labelValue={p.value} checked={p.value == enquiryObj.collectionMode}
                                                onChange={(e) => this.setFormValue(p.value, "collectionMode")} />
                                            <span className="margin-left-35">{p.key} </span>
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <div className=''>
                                <div className="row">
                                    <div className="sec-frame col-lg-12 " ><div className="section section4">Enquiry</div></div>
                                </div>
                                <div className='elable'>
                                    <div className="label-text row  " >
                                        <b>Note: </b>Payment to be made before order is processed
                                        <br /><b>For other prints of other sizes, please indicate in the enquiry section.</b>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 " >
                                        <textarea className='textarea-width' id="remark" cols="60" name="remark" rows="5" onChange={(e) => this.setFormValue(e.target.value, "remark")}></textarea>
                                    </div>
                                    {disableButton == false && (
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 " >
                                            <RecaptchaWidget getRecaptchaToken={(value)=>this.getRecaptchaToken(value)} token={captchaToken} error={captchaError}/>
                                        </div>
                                    )}
                                </div>
                                {visibleButton && <div className="btn-submit col-xs-12  mt-1 mb-2">
                                    <ButtonElement
                                        type="submit"
                                        variant="contained"
                                        btnType=" button-label"
                                        className={!disableButton ? "btn-color" : ""}
                                        disabled={disableButton}
                                    >
                                        <span className="button-label">SUBMIT</span>
                                    </ButtonElement>
                                </div>}
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchStatetoProps = (state) => ({
    ...state,
    isLoading: state.userReducer.isLoading,
    enquiryRequestInfo: state.userReducer.enquiryRequestInfo,
    imageEnquiryRes: state.userReducer.imageEnquiryRes,
    user_error: state.userReducer.user_error,
    tokens: state.loginReducer.tokens,
    userProfile: state.userReducer.userProfile,
    userProfile_error: state.userReducer.user_error,
    publicationInfo: state.userReducer.publicationInfo,
    imageEnquiryErr: state.userReducer.imageEnquiryErr
});

export default connect(mapDispatchStatetoProps, {
    startLoader,
    getEnquiryRequestInfo,
    imageEnquiryRequest,
    getUserDetails,
    getUserProfile,
    clearEnquiryRequest,
    getPublicationName
})(enquiryRequest);
