import useAxios from "./axios";

import {
  GET_USER_PREFERENCES,
  GET_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES_ERROR,
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_ERROR,
  GET_USER_USAGE,
  GET_USER_USAGE_ERROR,
  GET_USER_USAGE_SEARCH,
  GET_USER_USAGE_SEARCH_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  START_LOADER,
  CLEAR_ERROR,
  DOWNLOAD_DOC,
  DOWNLOAD_DOC_ERROR,
  ENQUIRY_REQUEST_INFO,
  ENQUIRY_REQUEST_INFO_ERROR,
  IMAGE_ENQUIRY_REQUEST,
  IMAGE_ENQUIRY_REQUEST_ERROR,
  CLEAR_IMAGE_ENQUIRY_REQUEST,
  GET_PUBLICATION,
  GET_PUBLICATION_ERROR,
  UPDATE_PREFERENCE_LOADER
} from "../../config/constants/action-constants";

import axiosInstance from "./axiosResp";


const getUserProfile = (userid) => async (dispatch) => {


  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_USER_BASE_URL}/myProfile/${userid}`);


    dispatch({
      type: GET_USER_PROFILE,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_PROFILE_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
};

const getUserPreferences = (userid) => async (dispatch) => {


  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_USER_BASE_URL}/myPreference/${userid}`);
    if (res.data.errors) {
      dispatch({
        type: GET_USER_PREFERENCES_ERROR,
        payload: res.data.errors[0]
      });
    }

    dispatch({
      type: GET_USER_PREFERENCES,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_PREFERENCES_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
};

const getUserSubscription = (userid) => async (dispatch) => {


  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_USER_BASE_URL}/mySubscription/${userid}`);
    if (res.data.errors) {
      dispatch({
        type: GET_USER_SUBSCRIPTION_ERROR,
        payload: res.data.errors[0]
      });
    }

    dispatch({
      type: GET_USER_SUBSCRIPTION,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_SUBSCRIPTION_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
};

const getUserUsage = (userid) => async (dispatch) => {


  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_USER_BASE_URL}/myUsageSummary/${userid}`);
    if (res.data.errors) {
      dispatch({
        type: GET_USER_USAGE_ERROR,
        payload: res.data.errors[0]
      });
    }

    dispatch({
      type: GET_USER_USAGE,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_USAGE_ERROR,
      payload: err.response ? err.response.data.error : "API Failed"
    });
  }
};

const getUserUsageSearch = (data, page = 1) => async (dispatch) => {

  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_USER_BASE_URL}/myUsageDetailSearch?page=${page}`, data);
    if (res.data.errors) {
      dispatch({
        type: GET_USER_USAGE_SEARCH_ERROR,
        payload: res.data.errors[0]
      });
    } else {

      dispatch({
        type: GET_USER_USAGE_SEARCH,
        payload: { data: res.data, isLoading2: false },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USER_USAGE_SEARCH_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
}

const updateUserPreferences = (userPreference,close) => async (dispatch) => {


  try {
    if(close){
      dispatch({
        type: UPDATE_USER_PREFERENCES,
        payload: { data: {}, isLoading: false },
      });
    }else{


    const res = await axiosInstance.put(
      `${process.env.REACT_APP_USER_BASE_URL}/myPreference`,
      userPreference
    );

    if (res.data.errors) {
      dispatch({
        type: UPDATE_USER_PREFERENCES_ERROR,
        payload: res.data.errors[0]
      });
    }

    dispatch({
      type: UPDATE_USER_PREFERENCES,
      payload: { data: res.data, isLoading: false },
    });
  }

  } catch (err) {
    dispatch({
      type: UPDATE_USER_PREFERENCES_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
};

const updateUserProfile = (userProfile,close) => async (dispatch) => {


  try {
    if(close){
      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: { data:{}, isLoading: false },
      });
    }else{


    const res = await axiosInstance.put(
      `${process.env.REACT_APP_USER_BASE_URL}/myProfile`,
      userProfile
    );

    if (res.data.errors) {
      dispatch({
        type: UPDATE_USER_PROFILE_ERROR,
        payload: res.data.errors[0]
      });
    }

    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: { data: res.data, isLoading: false },
    });
  }
  } catch (err) {
    dispatch({
      type: UPDATE_USER_PROFILE_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
};

const startLoader = (option) => async (dispatch) => {
  if (option) {
    dispatch({
      type: START_LOADER,
      payload: { isLoading2: true, isLoading: false },
    });
  } else {
    dispatch({
      type: START_LOADER,
      payload: { isLoading: true, isLoading2: false },
    });
  }
};

const clearError = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
}

const clearEnquiryRequest = () => async (dispatch) => {
  dispatch({
    type: CLEAR_IMAGE_ENQUIRY_REQUEST,
  });
}
const downloaddoc = (data,emptydialogstring) => async (dispatch) => {
  if(emptydialogstring){
    dispatch({
      type: DOWNLOAD_DOC,
      payload: { data:"",msg:"" , isLoading: false },
    });
  }else{
  try {

    const res = await useAxios.post(`${process.env.REACT_APP_USER_BASE_URL}/download`, data);
    dispatch({
      type: DOWNLOAD_DOC,
      payload: { data: res.data,msg:"", isLoading: false },
    });
  } catch (err) {
    let actionOrNotice =err.response.data.errors[0];

    if (actionOrNotice.includes("Action:")) {
      let splitString=actionOrNotice.split("Action: ");
      dispatch({
        type: DOWNLOAD_DOC,
        payload: { data:"open alert",msg:splitString[1], isLoading: false },
      });
    }else if (actionOrNotice.includes("Notice:")) {
      let splitString=actionOrNotice.split("Notice:")
      dispatch({
        type: DOWNLOAD_DOC,
        payload: { data:"open dialbox",msg:splitString[1], isLoading: false },
      });
    }
    else {
      dispatch({
        type: DOWNLOAD_DOC_ERROR,
        payload: err.response ? err.response.data.errors : ""
      });
    }
  }
}
}

const getEnquiryRequestInfo = () => async (dispatch) => {


  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/pef_form`);
    if (res.data.errors) {
      dispatch({
        type: ENQUIRY_REQUEST_INFO,
        payload: res.data.errors[0]
      });
    }

    dispatch({
      type: ENQUIRY_REQUEST_INFO,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: ENQUIRY_REQUEST_INFO_ERROR,
      payload: err.response ? err.response.data.error : "API Failed"
    });
  }
};
const imageEnquiryRequest = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_HOME_BASE_URL}/imageEnquiry`, data);
    dispatch({
      type: IMAGE_ENQUIRY_REQUEST,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: IMAGE_ENQUIRY_REQUEST_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
}
const getPublicationName = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_INFO_BASE_URL}/getinfo/pub_code?language=${data}`);
    dispatch({
      type: GET_PUBLICATION,
      payload: { data: res.data, isLoading: false },
    });
  } catch (err) {
    dispatch({
      type: GET_PUBLICATION_ERROR,
      payload: err.response ? err.response.data.errors : ""
    });
  }
}

const updateLoader=()=>(dispatch)=>{
  dispatch({
    type: UPDATE_PREFERENCE_LOADER,
    payload:{isLoadingPreference:true}
  });
}
export {
  getUserProfile,
  getUserPreferences,
  getUserSubscription,
  getUserUsage,
  getUserUsageSearch,
  updateUserPreferences,
  updateUserProfile,
  startLoader,
  clearError,
  downloaddoc,
  getEnquiryRequestInfo,
  imageEnquiryRequest,
  clearEnquiryRequest,
  getPublicationName,
  updateLoader
};
