import React, { Component } from "react";
import { connect } from "react-redux";
import reactReferer from "react-referer";
import Badge from "react-bootstrap/Badge";
import { getContentData, getDate, getPageviewobj, reverseDate } from "../../helpers/common";
import { Modal, SelectElement, DatePickerElement, Spinner } from "../UI/index";
import {
  getAnnouncements,setDurations,getSubDigitalType,
  getContentTypes,setSearchObject,getsetadandAdvdata,
  setContentType, searchContent, startLoader, startTransLoader, getBadgeData, setDateObject
} from "../../redux/actions/applicationsAction";
import SearchContentDisplay from "./searchContent";
import "./css/searchData.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { decodeToken } from "react-jwt";
import { FilterIcon } from "../../assets/images";
import ScrollableTabsButtonAuto from "../UI/Tabs/ScrollableTabsButtonAuto";
import Alert from '@mui/material/Alert';
import { HERITAGE_DATE_INSTRUCTION } from "../../config/constants/generalConstants";

const Durations1 = [

  {
    key: "Date Descending",
    sortOrder: "desc",
    sortBy: "publicationdate",
    value: "0"
  },
  {
    key: "Date Ascending",
    sortOrder: "asc",
    sortBy: "publicationdate",
    value: "1"
  },
  {
    key: "Sort By Relevance",
    sortOrder: "desc",
    sortBy: "_score",
    value: "2"
  }

];
const Durations2 = [
  {
    key: "Date Descending",
    sortOrder: "desc",
    sortBy: "publicationdate",
    value: "0"
  },
  {
    key: "Date Ascending",
    sortOrder: "asc",
    sortBy: "publicationdate",
    value: "1"
  },
  {
    key: "Sort By Relevance",
    sortOrder: "desc",
    sortBy: "_score",
    value: "2"
  },
  {
    key: "Advertiser Descending",
    sortOrder: "desc",
    sortBy: "publicationdate",
    value: "2"
  },
  {
    key: "Advertiser Ascending",
    sortOrder: "asc",
    sortBy: "publicationdate",
    value: "2"
  },


];
class SearchData extends Component {
  state = {
    searchObject: {
      dateRange: {
        fromDate: "",
        toDate: "",
      },
      keywords: "",
      pageNo: 1,
      publication: [],
      pageSize: 10,
      digitalType: "",
      sortBy: 'publicationdate',
      sortOrder: "desc",
      sourceType: ""
    },
    maxDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    keywords: "",
    minDate: getDate(true, 2),
    referer: reactReferer.referer(),
    filter: false,
    dataObj: [],
    isActive: {},
    Duration: "",
    searchQueries: [],
    sectionDatalist: [],
    badgeData: {},
    defaultbadges:{},
  }

  async componentDidMount() {
    const { searchObject, sectionDatalist } = this.props;
    const {notvabarcall} = this.props.location;
    const { selectedData } = this.props.location;
    const { badgeData } = this.state;

    let myDecodedToken;
    for (let x in searchObject) {
      this.state.searchObject[x] = searchObject[x];
    }

    if (searchObject?.dateRange?.fromDate?.length > 0) {
      this.setState({
        searchObject,
        startDate: new Date(searchObject?.dateRange?.fromDate),
        endDate: new Date(searchObject?.dateRange?.toDate),
      })
    }
    if (sectionDatalist) {
      this.setState({
        sectionDatalist: sectionDatalist,
        // badgeData:badgeData
      })

    }
    window.addEventListener('resize', this.onCloseFilter);
    let d = [];
    Object.keys(this.props.searchContents?.aggregation).map((data) => {
      if (this.props.searchContents?.aggregation[data].length > 0) {
        d.push(data);
        if (!this.props.badgeData) {
          badgeData[data.toLowerCase()] = [];
        }
      }
    })
    let queries = [this.props.digitalType];
    if (searchObject?.keywords) {
      queries = [...queries, searchObject?.keywords]
    }
    let sortval=this.findSortorder();

    this.setState({
      dataObj: d,
      searchQueries: [...this.state?.searchQueries, queries],
      badgeData: this.props?.badgeData ? this.props.badgeData : badgeData,
      Duration: sortval,
      filter: false
    })

    if (this.props?.userTokens?.id_token) {
      myDecodedToken = decodeToken(this.props?.userTokens?.id_token);
      let pubdata = myDecodedToken?.user_info?.pub_list;
      // let publication;
      if(searchObject.publication.length > 0 ){
        searchObject.publication = searchObject?.publication.concat(pubdata);
        // publication=[...new Set(searchObject?.publication)];
      }
      this.setState({
        defaultbadges: pubdata
      })
    }
    if (this.props?.adandAdvbadges) {
      let aa = this.props?.adandAdvbadges.concat(this.props.searchObject?.publication);
      let publication = [...new Set(aa)];

      searchObject.publication = publication;

    }
    if(notvabarcall){

      this.setPageViewcall(searchObject,myDecodedToken);
    }
  }
  setPageViewcall=(searchObject,myDecodedToken)=>{
    let digconfig;
    if(this.props?.digitalType?.extraData){
      digconfig=this.props?.digitalType?.extraData[0]?.digitalType;
    }else{digconfig=this.props?.digitalType};
    let id=myDecodedToken?.user_info?.visitor_id ? myDecodedToken?.user_info?.visitor_id : ""
    let user_status=myDecodedToken && myDecodedToken?.user_info?.user_type ? myDecodedToken?.user_info?.user_type:"" ;
    let dig;
    if(this.props.setSearchtype){
      dig =digconfig && searchObject.digitalType ? digconfig.toLowerCase() :searchObject.digitalType ;
    }else{dig='search'}
    let obj= getPageviewobj("",dig,"",user_status,"","","",id,"Found Result");
    this.props.pageViewDatagtm(obj,searchObject);
  }
  onStartDateChange = debounce((date) => {
    this.props.setDateObject({ startDate: date, endDate: this.props?.dateObject?.endDate })
    this.onCloseFilter()
    const { searchObject } = this.props;
    let dt = new Date(date);
    let modifieddate = dt.toLocaleDateString('en-GB');
    searchObject.dateRange["fromDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = this.props?.dateObject?.endDate.toLocaleDateString('en-GB');
    searchObject.dateRange["toDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    searchObject.pageNo=1;
    searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.props.setDurations("0")
    this.setState({
      searchObject:searchObject,
      startDate: date
    });
    // this.props.searchContent(searchObject);

    this.props.searchfromchild(searchObject,this.props.badgeData.sectionDatalist, this.props.badgeData.badgeData);

  }, 500);

  onEndDateChange = debounce((date) => {
    this.onCloseFilter()
    this.props.setDateObject({ startDate: this.props?.dateObject?.startDate, endDate: date })
    const { searchObject } = this.props;
    let dt = new Date(date);
    // let modifieddate= `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
    // searchObject.dateRange["toDate"] = modifieddate;
    let modifieddate = dt.toLocaleDateString('en-GB');
    searchObject.dateRange["toDate"] = reverseDate(modifieddate.replaceAll("/", "-"));
    let modifieddate2 = this.props?.dateObject?.startDate.toLocaleDateString('en-GB');
    searchObject.dateRange["fromDate"] = reverseDate(modifieddate2.replaceAll("/", "-"));
    searchObject.pageNo=1;
    searchObject.sortBy= "publicationdate";
    searchObject.sortOrder="desc";
    this.props.setDurations("0")
    this.setState({
      searchObject:searchObject,
      endDate: date
    });
    // this.props.searchContent(searchObject);
    this.props.searchfromchild(searchObject,this.props.badgeData?.sectionDatalist, this.props.badgeData?.badgeData);

  }, 500);

  onChange = (value, key) => {
    const { searchObject } = this.state;
    searchObject[key] = value;
    this.setState({
      searchObject
    });
  }

  setContentType = async (contentType) => {
    await this.props.setContentType(contentType);
  }

  onSearch = async () => {
    const { searchObject } = this.state;
    searchObject.contentType = this.props.contentType;


    // this.props.location.resultfoundFor=""
    await this.props.searchContent(searchObject);
  }

  onShowFilter = (close = false) => {
    this.setState({
      filter: !this.state.filter
    })
  }

  onCloseFilter = () => {
    this.setState({
      filter: false
    })
  }
  updateSearchObj = async (badgeData) => {
    const { searchObject } = this.props;

    var badgeKeys = [];
    var refreshArray = {}
    for (let keys in badgeData) {
      badgeKeys.push(keys)
    }
    for (let x of badgeKeys) {
      var d = badgeData[x];
      refreshArray[x] = [];
      if (d) {
        for (let y of d) {
          if (y) {
            refreshArray[x].push(y)
          }
        }
      }
      }
      let data={};
    for(let x in searchObject){

      if(searchObject[x]){
          data[x]= searchObject[x]
      }

    }
    for (let x in refreshArray) {
      if (refreshArray[x]) {

        data[x] = refreshArray[x];

      }
    }

    // data.publication = [];
    data.company = [];
    data.section = searchObject.section? searchObject.section: [];
    data.sortBy= "publicationdate";
    data.sortOrder="desc";
    // data.publication = data?.publication.concat(refreshArray?.publication);
    data.pageNo = 1;
    this.state.searchObject.pageNo = 1;
    // this.setState({
    // searchObject
    //  });
    this.props.searchObject.pageNo = 1;
    this.props.searchObject.sortBy= "publicationdate";
    this.props.searchObject.sortOrder="desc";
    this.props.setDurations("0")
    await this.props.setSearchObject(this.props.searchObject);
    await this.props.searchfromchild(data, this.props.badgeData?.sectionDatalist, this.props.badgeData?.badgeData);
  }


  getSectionData = (type, key, i, index) => {
    this.onCloseFilter();
    const { sectionDatalist, badgeData } = this.props.badgeData;

    const {adandAdvbadges}=this.props;
    let vals = sectionDatalist.find(x => x.val === key);
    let defaultbadge=this.state?.defaultbadges && this.state?.defaultbadges.length > 0 && this.state.defaultbadges.find(x => x === key);
    let adandAdvbadgesdata=adandAdvbadges && adandAdvbadges.length > 0 && adandAdvbadges.find(h => h === key);
    let bdata = badgeData;
    let secdata = sectionDatalist;
    let subType=this.props.subDigitalType && this.props.subDigitalType!=="ALL" && type==='Section' ? true : false
    if (!vals && !defaultbadge && !adandAdvbadgesdata && !subType) {
      secdata.push({ val: key, index2: index, index1: i, type: type.toLowerCase() });
      if (!bdata[type.toLowerCase()]) {
        bdata[type.toLowerCase()] = [];
      }
      bdata[type.toLowerCase()][index] = (key);
      // this.setState({badgeData});
      this.props.getBadgeData({ sectionDatalist: secdata, badgeData: bdata });

      this.updateSearchObj(bdata);
    }
  }
  deleteBadge = async (badge, index) => {
    const { badgeData, sectionDatalist } = this.props.badgeData;

    if(badge.val=="HERITAGE"){
      await this.props.getSubDigitalType("ALL", 0);
    }

    var array = sectionDatalist;

    // array.splice(index,1);
    array[index] = {}

    var bagdearray = badgeData[badge.type];
    // bagdearray.splice(badge.index2,1);
    bagdearray[badge.index2] = null;

    badgeData[badge.type] = bagdearray;
    // this.setState({sectionDatalist:array,
    //   badgeData
    // });
    this.props.getBadgeData({ sectionDatalist: array, badgeData: badgeData })

    this.updateSearchObj(badgeData);

  }
  deleteBadgedynamic=async(badge,i)=>{
    const {adandAdvbadges}=this.props;
    var s=[...adandAdvbadges];
    s.splice(i,1);
    this.props.getsetadandAdvdata(s);
    let newpubobj=JSON.parse(JSON.stringify(this.props.searchObject));
    let p=[];
    for(let x of this.props.searchObject.publication){
    if(badge===x){}else{p.push(x)}
    }
    newpubobj.publication=p;

    newpubobj.sortBy= "publicationdate";
    newpubobj.sortOrder="desc";
    this.props.setDurations("0")
    await this.props.setSearchObject(newpubobj);
    //  await this.props.searchContent(newpubobj);
    await this.props.searchfromchild(newpubobj, this.props.badgeData?.sectionDatalist, this.props.badgeData?.badgeData);

  }
  sortvalFun=()=>{
    let sortval=this.findSortorder();
    this.props.setDurations(sortval);
    // this.setState({Duration:sortval})
  }

  onDurationChange = (val) => {
    const { searchObject } = this.props;
    this.onCloseFilter();
     this.props.startTransLoader();
    if (val) {
      let data = searchObject.advertiser ? Durations1[val] : Durations2[val];
      searchObject.sortBy = data.sortBy;
      searchObject.sortOrder = data.sortOrder;
      this.setState({
        searchObject,
        Duration: data.value
      })
      this.props.setDurations(data.value);
      this.props.searchContent(searchObject)
    }
  }
  findSortorder=()=>{
    let dropvals=this.state.searchObject.advertiser ? Durations2 : Durations1
        if(this.props.searchObject.sortOrder){
          for(let x of dropvals){
            if(this.props.searchObject.sortOrder===x.sortOrder){
              return x.value
            }
          }
        }
  }

  tabChange=async(index,value)=>{
    const {badgeData, sectionDatalist} = this.props.badgeData;
    await this.props.startTransLoader();
    const { searchObject } = this.props;

    await this.props.getSubDigitalType(value, index);
    let data = searchObject;
    data.pageNo = 1;


    for (let x in badgeData) {
      if (badgeData[x].length > 0) {
        data[x] = [];
        for (let y of badgeData[x]) {
          if (y) {
            data[x].push(y)
          }
        }
      }
    }

    if(value==="ALL"){
      data.section = []
      data.publication =[]
      if(this.state.defaultbadges && this.state.defaultbadges.length > 0){
        this.state.defaultbadges.forEach(defaultbadge => {
          if(data.publication.indexOf(defaultbadge) < 0){
            data.publication.push(defaultbadge)
          }
        });
      }
      data.company=[];
      await this.props.getBadgeData({ sectionDatalist: [], badgeData: {} });
    }
    else{
      data.section = [value]
      data.publication =[]
      data.company=[];
      const sectionDataList = []
      sectionDataList.push({ val: value, index2: 0, index1: 0, type: 'section' });
      this.props.getsetadandAdvdata([]);
      await this.props.getBadgeData({ sectionDatalist: sectionDataList, badgeData: {'section': data.section} });
    }

    this.setState({searchObject:data})
    await this.props.setSearchObject(data);

    await this.props.searchContent(data);


  }
  render() {
    // const searchQueries = [this.props.digitalType,this.state.searchObject.keywords];
    // const companies = ["Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news", "Top Stories", "Top of the news"]
    const {startDate, filter, dataObj, maxDate,defaultbadges} = this.state;
    const { searchContents, isLoading, resultfoundFor, badgeData, dateObject,adandAdvbadges,Duration} = this.props;


    return (
      <div className="col-12 col-md-12 pt-2 search-display-container p-0">
        {!isLoading && <>
          {filter ? <Modal
            customClass="filter-modal"
            closeModal={this.onCloseFilter}
            onClick={() => this.onCloseFilter()}
          >
            <div className="col-12 col-md-12 search-display-container p-0">
              <div className="filter align-top col-12 p-0" onClick={this.onFilterClick}>
                {/* { <img src={FilterIcon} alt="filter" />} */}
                <div className="ml-2  d-flex flex-nowrap">
                <img src={FilterIcon} className="filter" alt="filter" />
                  <h5 className="pl-2 popfilhead">Filter by</h5>
                </div>
              </div>
              <div className="col-12 sort-by-modal pt-2 p-0">
                {/* <h5 className="col-md-4 d-inline-block p-0">Sorts by</h5> */}
                <SelectElement
                  className="col-md-8 p-0 d-inline-block white p-2 "
                  defaultValue={this.state?.advertiser ? Durations2[Duration]?.value : Durations1[Duration]?.value}
                  options={this.state.advertiser ? Durations2 : Durations1}
                  onChange={(e) =>
                    this.onDurationChange(e.target.value, "sortvalue")
                  }
                />
              </div>
              <div className="date-filter pb-3">
                <span className="toLabel pb-4">FROM</span>
                <div className="pb-2">
                  <DatePickerElement id="startdate"
                    // minDate={minDate}
                    onChange={this.onStartDateChange}
                    className="w-100"
                    maxDate={maxDate}
                    value={dateObject?.startDate}
                  />
                </div>
                <span className="toLabel pb-4">TO</span>
                <div className="pb-2">
                  <DatePickerElement id="endDate"
                    minDate={startDate}
                    className="w-100"
                    onChange={this.onEndDateChange}
                    value={dateObject?.endDate}
                    maxDate={maxDate}
                  />
                </div>
              </div>
              {dataObj && dataObj.length > 0 && dataObj.map((data, i) => {
                return (
                  <>
                    {Object.keys(searchContents.aggregation[data][0]).length > 0 && <div className="filter-selection" key={i}>
                      <div className="pb-2">
                        <div className="filter-name">{data}</div>
                      </div>
                      <div className="filter-content">
                        {searchContents && Object.keys(searchContents.aggregation[data][0]).length > 0 && Object.keys(searchContents.aggregation[data][0]).map((company, index) => {
                          return (
                            <div onClick={() => this.getSectionData(data, company, i, index)}
                              className={this.state?.isActive[i] && this.state?.isActive[i][index] ? " option-data isActive" : "option-data"}
                              key={index}>{company}({searchContents.aggregation[data][0][company]})</div>
                          );
                        })}
                      </div>
                    </div>}
                  </>
                );
              })}
              {/* <div className="filter-selection">
              <div className="pb-2">
                <div className="filter-name">fdfdfdf</div>
              </div>
              <div className="filter-content">
                {companies.map((company, index) => (
                  <div className="option-data" key={index}>{company}({company.length})</div>
                ))}
              </div>
            </div>
            <div className="filter-selection">
              <div className="pb-2">
                <div className="filter-name">Company</div>
              </div>
              <div className="filter-content">
                {companies.map((company, index) => (
                  <div className="option-data" key={index}>{company}({company.length})</div>
                ))}
              </div>
            </div>
            <div className="filter-selection">
              <div className="pb-2">
                <div className="filter-name">Publication</div>
              </div>
              <div className="filter-content">
                {companies.map((company, index) => (
                  <div className="option-data" key={index}>{company}({company.length})</div>
                ))}
              </div>
            </div> */}
            </div>
          </Modal> : null}
          <div className="col-12 col-md-11 offset-lg-1">
            <div className="col-lg-12 col-md-12 col-12 p-0">
              <div className="pb-3">
                <span className="col-lg-2 col-md-2 d-inline-block col-12"> Results :&nbsp;{searchContents && searchContents.totalCount} </span>
                  <div className="col-lg-6 col-md-6 d-inline-block ">{<ScrollableTabsButtonAuto subDigitalTypeIndex={this.props.subDigitalTypeIndex} tabChange={this.tabChange} options={getContentData(this.props.contentTypes, this.props.digitalType)}></ScrollableTabsButtonAuto> }</div>
                <div className="col-lg-3 col-md-4 col-12 pr-2 sort-by">
                  <span className="col-md-4 d-inline-block p-0">Sort by</span>
                  <SelectElement
                    className="col-md-8 p-0 d-inline-block white p-2 border-2 fontcsss"
                    defaultValue={this.state?.advertiser ? Durations2[Duration]?.value : Durations1[Duration]?.value}
                    options={this.state?.advertiser ? Durations2 : Durations1}
                    onChange={(e) =>
                      this.onDurationChange(e.target.value, "sortvalue")
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="col-12">
            {/* <div className="col-md-11 col-12 p-0"> */}
              <div className='col-md-3 p-0 d-inline-block col-12'>

                <div className="col-11 filter-container">
                  <div className="col-12 p-0">
                  {(!this.props.subDigitalType || this.props.subDigitalType ==="ALL") && defaultbadges && defaultbadges.length > 0 && defaultbadges.map((badge, i) => {
                      return (<>
                        {badge && <div className="d-inline-block" key={i}>
                        &nbsp;<Badge className="">
                         {badge}&nbsp;</Badge>
                        </div>}</>)
                    })

                    }

                    {adandAdvbadges && adandAdvbadges.length > 0 && adandAdvbadges.map((badge, i) => {
                      return (<>
                         {badge && <div className="d-inline-block" key={i}>
                          <Badge className="" style={{cursor:"pointer"}} >
                            <FontAwesomeIcon icon={faTimes} className="search-icon" onClick={() => this.deleteBadgedynamic(badge, i)} />&nbsp; &nbsp; {badge}</Badge>
                        </div>}</>)
                      })
                    }
                    {badgeData?.sectionDatalist && badgeData.sectionDatalist.length > 0 && badgeData.sectionDatalist.map((badge, i) => {
                      return (<>
                        {badge.val && <div className="d-inline-block" key={i}>
                          <Badge className="">
                            <FontAwesomeIcon icon={faTimes} className="search-icon" onClick={() => this.deleteBadge(badge, i)} />&nbsp; &nbsp; {badge.val}</Badge>
                        </div>}</>)
                    })

                    }

                  </div>
                  <div className="filter-block">
                    <div className="date-filter pb-3">
                      <span className="toLabel pb-4">FROM</span>
                      <div className="pb-2">
                        <DatePickerElement
                          // minDate={minDate}
                          maxDate={maxDate}
                          onChange={this.onStartDateChange}
                          className="w-100"
                          value={dateObject?.startDate}
                        />
                      </div>
                      <span className="toLabel pb-4">TO</span>
                      <div className="pb-2">
                        <DatePickerElement
                          minDate={dateObject?.startDate}
                          className="w-100"
                          onChange={this.onEndDateChange}
                          value={dateObject?.endDate}
                          maxDate={maxDate}
                        />
                      </div>
                    </div>
                    {dataObj && dataObj.length > 0 && dataObj.map((data, i) => {
                      return (
                        <>
                          {Object.keys(searchContents.aggregation[data][0]).length > 0 && <div className="filter-selection" key={i}>
                            <div className="pb-2">
                              <div className="filter-name">{data}</div>
                            </div>
                            <div className="filter-content">
                              {searchContents && Object.keys(searchContents.aggregation[data][0]).length > 0 && Object.keys(searchContents.aggregation[data][0]).map((company, index) => {
                                return (
                                  <div

                                    key={index}>  <span className="option-data"  onClick={() => this.getSectionData(data, company, i, index)}>{company}({searchContents.aggregation[data][0][company]})</span></div>
                                );
                              })}
                            </div>
                          </div>}
                        </>
                      );
                    })}

                    {/* <div className="filter-selection">
                    <div className="pb-2">
                      <div className="filter-name">Publication</div>
                    </div>
                    <div className="filter-content">
                      {companies.map((company, index) => (
                        <div className="option-data" key={index}>{company}({company.length})</div>
                      ))}
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-12 d-inline-block align-top">
                <div>
                  {((resultfoundFor && resultfoundFor[0]) || this.props?.searchObject?.keywords) && <span className="col-md-9 d-inline-block col-12">{searchContents && searchContents.totalCount} Results found for :  &nbsp;<b>{this.props?.searchObject && this.props?.searchObject?.keywords && <span>{this.props?.searchObject?.keywords}

                  </span>
                  }   {resultfoundFor && resultfoundFor[0] && resultfoundFor[0].map((data, idx) => {
                    return (<span key={idx}>+&nbsp;{data}</span>)
                  })}

                  </b></span>}
                </div>

                {isLoading ? (
                  <div className="text-center pt-5 pb-5 tranparentscreen2">
                    <Spinner primary={true} transparent={true} />
                  </div>
                ) : (<>
                  {
                    (this.props.digitalType=="Images" && this.props.subDigitalTypeIndex==1) &&
                    <Alert severity="warning">{HERITAGE_DATE_INSTRUCTION}</Alert>
                  }

                  {searchContents && <SearchContentDisplay onShowFilter={this.onShowFilter} onPaginate={this.props.onPaginate} openArticle={this.props.openArticle} {...this.props} />}
                </>)}

              </div>
            {/* </div> */}
          </div>
        </>}
      </div>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  announcements: state.applicationReducer.announcements,
  contentTypes: state.applicationReducer.contentTypes,
  digitalType: state.applicationReducer.digitalType,
  searchContents: state.applicationReducer.searchContent,
  isLoading: state.applicationReducer.isLoading,
  badgeData: state.applicationReducer.badgeData,
  searchObject: state.applicationReducer.searchObject,
  dateObject: state.applicationReducer.dateObject,
  adandAdvbadges:state.applicationReducer.adandAdvbadges,
  setSearchtype: state.applicationReducer.setSearchtype,
  Duration: state.applicationReducer.Duration,
  subDigitalType:state.applicationReducer.subDigitalType,
  subDigitalTypeIndex:state.applicationReducer.subDigitalTypeIndex
});

export default connect(mapDispatchStatetoProps, {
  getAnnouncements,
  getContentTypes,
  setContentType,
  searchContent,
  startLoader,
  startTransLoader,
  getBadgeData,
  setDateObject,
  setSearchObject,
  getsetadandAdvdata,
  setDurations,
  getSubDigitalType
})(SearchData);
