import React, { Component } from "react";
import { connect } from "react-redux";
import { getIcon } from "../../../helpers/common";
import "./css/contentType.scss";

class PDF extends Component {



  onClick=(data,file) =>{
    if(this.props.passArticle){
      this.props.passArticle(data,file)
    }
  }
  render() {
    const {data,ispdf,iconflag}=this.props;
    let pgno;
    if (data?.pageno === data?.endpageno) {
      pgno = data.pageno;
    } else {
      pgno = `${data.pageno}-${data.endpageno}`;
    }

    return (
      <>
     {(this.props.digitalType !=="All" || !this.props.setSearchtype) ? ( <div className="pb-2 col-md-4 d-inline-block col-12" >
        {data && <div className="image-container">
         {data.thumbnailPath &&  <div className="d-flex image  flex-nowrap" style={{cursor:"pointer"}}>
            <img src={data.thumbnailPath} className="col-auto center"  alt="imageItem" />
          </div>}
           <p className="m-1 by ellipses">
                {data.byline_en && <span>  By: {data.byline_en} &nbsp;</span>}
                {data.publicationName && <span>{(data.publicationName)}</span>} <br className="hidethebr" />
                {data.pagename && <span>({data.pagename})</span>} </p>
            <p className="m-1 by ellipses"> {data?.date}/{data?.month}/{data?.year}</p>
            <p className="m-1 by ellipses">{data.advertiser && <span>Advertiser:&nbsp;{data.advertiser}</span>}</p>
          {ispdf && <div className="pageName pb-2" >
            <img height="20px"
              src={getIcon("PDF", true)}
              alt="logo"
              className="logo d-inline-block"
            /><span style={{cursor:"pointer"}} onClick={()=>this.onClick(data,"file")} data-documentid={data.documentid}>Page {pgno}</span>
          </div>}
        </div> }
        <hr />
      </div>):(
        <>
            <div className="pb-2 container article-container">
              <div className="row">
              <div className="col-md-1  ">
      <img  src={getIcon(iconflag, true)}  alt="logo" className="" />
      </div>
                <div className="col-md-3 col-12 " >
                  <img src={data.thumbnailPath} className=" imagess center" alt="pdf" />
                </div>
                <div className="col-md-8 ">
                <p className="m-1 by ellipses">
                {data.byline_en && <span>  By: {data.byline_en} &nbsp;</span>}
                {data.publicationName && <span>{(data.publicationName)} &nbsp;</span>}
                {data.pagename && <span>({data.pagename}) &nbsp;</span>}
               <span> {data?.date}/{data?.month}/{data?.year}</span>
                 </p>
            <p className="m-1 by ellipses">{data.advertiser && <span>Advertiser:&nbsp;{data.advertiser}</span>}</p>
          {ispdf && <div className="pageName pb-2" >
            <img height="20px"
              src={getIcon("PDF", false)}
              alt="logo"
              className="logo d-inline-block"
            /><span style={{cursor:"pointer"}} onClick={()=>this.onClick(data,"file")} data-documentid={data.documentid}>Page {pgno}</span>
          </div>}
                </div>
              </div>

            </div>
            <div className="pt-1">
        <hr />
        </div>
            </>
      )}
      </>
    );
  }
}

const mapDispatchStatetoProps = (state) => ({
  ...state,
  contentType: state.applicationReducer.contentType,
  downloaddata:state.userReducer.downloaddata,
  digitalType:state.applicationReducer.digitalType,
  setSearchtype: state.applicationReducer.setSearchtype
});

export default connect(mapDispatchStatetoProps, {

})(PDF);
